import React from 'react';

import {
    Modal, ModalHeader, ModalBody, ModalFooter, Button
} from 'reactstrap';

import FormBuilder from './forms/formBuilder';
import { required, emailAddress } from './forms/validation'
// import BankIDModal_v2 from './bankIDModal_v2';
import BankIDModal_v5 from './bankIDModal_v5';
import { API_ENDPOINT } from '../constants'
function parseJSON(response) {
    return response.json().then(json => {
        return {
            result: json,
            status: response.status
        }
    })
}
/**
* BankID modal for doctor login
* @author   Milan Stanojevic
*/
class BankIdModal extends React.Component {

    constructor(props) {
        super(props);
        this.state = {}
    }

    componentDidMount() {
        // console.log(this.props)
        // window.addEventListener('message', (e) => {
        //     if (e.data == 'loggedin') {
        //         localStorage.setItem('profile', 'doctor');
        //         localStorage.setItem('userLevel', 20);
        //         localStorage.setItem('logoutLandingPage', true);
        //         if (localStorage.getItem('groupAlias'))
        //             localStorage.setItem('loginGroupAlias', localStorage.getItem('groupAlias'));
        //         this.props.verifyUser(() => {
        //             this.props.toggle();
        //         });

        //     } else if (e.data == 'loggedin_newuser') {
        //         localStorage.setItem('profile', 'doctor');
        //         localStorage.setItem('userLevel', 20);
        //         localStorage.setItem('logoutLandingPage', true);
        //         if (localStorage.getItem('groupAlias'))
        //             localStorage.setItem('loginGroupAlias', localStorage.getItem('groupAlias'));
        //         this.props.verifyUser(null, null, true);
        //         this.setState({
        //             loggedIn: true
        //         })

        //     }
        // }, false)


        if (this.props.uData) {
            this.setState({
                loggedIn: true
            })
        }
    }


    update = (data) => {

        fetch(API_ENDPOINT + '/users/account/update', {
            method: 'POST',
            headers: {
                'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage.getItem('authToken')}` : null,
                'content-type': 'application/json'
            },
            body: JSON.stringify(data)
        }).then(parseJSON).then(({ result, status }) => {
            if (result.error) {
                this.setState({ error: result.error.translate(this.props.lang) })
            } else {
                this.setState({
                    message: result.message
                })
                this.props.verifyUser();
                this.props.toggle();
            }

        })


    }



    render() {
        return (
            <Modal isOpen={this.props.isOpen} centered>
                <ModalHeader toggle={!this.props.uData ? this.props.toggle : null} close={!this.props.uData ? <button type="button" className="close" style={{ position: 'absolute', top: '15px', right: '15px' }} onClick={this.props.uData ? null : this.props.toggle}>&times;</button> : null}>
                    {/* {'Login with BankId'.translate(this.props.lang)} */}
                </ModalHeader>
                <ModalBody className="bankid-modal">
                    {!this.state.loggedIn ?
                        // this.props.redirect_url === true ?
                        //     <div className="loader-wrap">
                        //         <div className="loader" />
                        //     </div>
                        //     :

                        // <iframe ref={(node) => this.bankIdIframe = node} onLoad={() => {
                        //     try {
                        //         if (this.bankIdIframe && this.bankIdIframe.contentWindow &&this.bankIdIframe.contentWindow.location  &&this.bankIdIframe.contentWindow.location.href && this.bankIdIframe.contentWindow.location.href.indexOf('/bankid/v2') !== -1 ){
                        //             this.setState({
                        //                 _hideIframe: true
                        //             })
                        //         }
                        //     } catch (e) { }
                        // }}  src={this.props.redirect_url} style={this.state._hideIframe ? {opacity: 0} : { width: '100%', height: 350, border: 0 }} />
                        // <BankIDModal_v2 {...this.props} doctorLogin={true} />
                        <BankIDModal_v5 {...this.props} doctorLogin={true} />
                        :

                        this.props.uData ?
                            <FormBuilder
                                selectedGroup={this.props.selectedGroup}
                                selectedClinic={this.props.selectedClinic}
                                country={this.props.country}
                                countryTwoLetter={this.props.countryTwoLetter}
                                dateFormat={this.props.dateFormat}
                                getStringDateTs={this.props.getStringDateTs}
                                lang={this.props.lang}
                                onSubmit={(data) => this.update(data)}
                                initialValues={this.props.uData}
                                toggle={() => this.setState({ form: null })}
                                buttonText={'Save'.translate(this.props.lang).translate(this.props.lang)}
                                additionalButton={<Button style={{ marginLeft: 10 }} color="danger" onClick={() => this.props.signOut()}>{'Logout'.translate(this.props.lang)}</Button>}
                                fields={[
                                    {
                                        type: 'row',
                                        children: [
                                            {
                                                type: 'col',
                                                width: {
                                                    lg: 12,
                                                    sm: 12,
                                                    xs: 12
                                                },
                                                children: [
                                                    {
                                                        type: 'socialSecurityNumber',
                                                        disabled: true,
                                                        name: 'socialSecurityNumber',
                                                        label: this.props.country === 'uk' ? "Date of birth".translate(this.props.lang) : 'Social security number'.translate(this.props.lang),
                                                        validate: [required('Social security number is required!'.translate(this.props.lang))]

                                                    },
                                                ]
                                            },

                                            /*{
                                                type: 'col',
                                                width: {
                                                    lg: 3,
                                                    sm: 12,
                                                    xs: 12
                                                },
                                                children: [
                                                    {
                                                        type: 'text',
                                                        name: 'userData.title',
                                                        label: 'Title'.translate(this.props.lang),
                                                        disabled: this.props.alias
                                                    },
                                                ]
                                            },*/
                                            {
                                                type: 'col',
                                                width: {
                                                    lg: 12,
                                                    sm: 12,
                                                    xs: 12
                                                },
                                                children: [
                                                    {
                                                        type: 'text',
                                                        name: 'userData.name',
                                                        label: 'First and last name'.translate(this.props.lang),
                                                        disabled: this.props.alias,
                                                        validate: [required('First and last name is required!'.translate(this.props.lang))]

                                                    },
                                                ]
                                            },
                                            {
                                                type: 'col',
                                                width: {
                                                    lg: 12,
                                                    sm: 12,
                                                    xs: 12
                                                },
                                                children: [
                                                    {
                                                        type: 'email',
                                                        name: 'email',
                                                        label: 'Email address'.translate(this.props.lang),
                                                        validate: [required('Email address is required!'.translate(this.props.lang)), emailAddress('Invalid email address!'.translate(this.props.lang))]
                                                    },
                                                ]
                                            },
                                            {
                                                type: 'col',
                                                width: {
                                                    lg: 12,
                                                    sm: 12,
                                                    xs: 12
                                                },
                                                children: [
                                                    {
                                                        type: 'phone',
                                                        name: 'phone',
                                                        label: 'Phone number'.translate(this.props.lang),
                                                        validate: [required('Phone number is required!'.translate(this.props.lang))]
                                                    },
                                                ]
                                            },
                                            {
                                                type: 'col',
                                                width: {
                                                    lg: 12,
                                                    sm: 12,
                                                    xs: 12
                                                },
                                                children: [
                                                    {
                                                        type: 'text',
                                                        name: 'street',
                                                        label: 'Street'.translate(this.props.lang),
                                                        validate: [required('Street is required!'.translate(this.props.lang))]
                                                    },
                                                ]
                                            },
                                            {
                                                type: 'col',
                                                width: {
                                                    lg: 3,
                                                    sm: 12,
                                                    xs: 12
                                                },
                                                children: [
                                                    {
                                                        type: 'text',
                                                        name: 'zip',
                                                        // pattern: new RegExp(/[0-9 ]+/i),
                                                        label: 'Postal code'.translate(this.props.lang),
                                                        validate: [required('Postal code is required!'.translate(this.props.lang))]
                                                    },
                                                ]
                                            },
                                            {
                                                type: 'col',
                                                width: {
                                                    lg: 9,
                                                    sm: 12,
                                                    xs: 12
                                                },
                                                children: [
                                                    {
                                                        type: 'text',
                                                        name: 'city',
                                                        // pattern: new RegExp(/^[a-zA-Z \u00c0-\u017e]$/i),
                                                        pattern: new RegExp(/^[a-zA-Z \u00c0-\u017e\u0370-\u03FF ]$/i),
                                                        label: 'City'.translate(this.props.lang),
                                                        validate: [required('City is required!'.translate(this.props.lang))]
                                                    },
                                                ]
                                            },

                                            // {
                                            //     type: 'col',
                                            //     width: {
                                            //         lg: 6,
                                            //         sm: 12,
                                            //         xs: 12
                                            //     },
                                            //     children: [
                                            //         {
                                            //             type: 'checkbox',
                                            //             name: 'notifications.email',
                                            //             afterText: 'E-mail notification'.translate(this.props.lang),
                                            //         },
                                            //         {
                                            //             type: 'checkbox',
                                            //             name: 'notifications.sms',
                                            //             afterText: 'SMS notification'.translate(this.props.lang),
                                            //         },

                                            //     ]
                                            // },




                                        ]
                                    }
                                ]}
                            ></FormBuilder>
                            :
                            null

                    }
                    <p className="error-message">{this.state.error}</p>
                </ModalBody>
            </Modal>
        )
    }
}

export default BankIdModal;