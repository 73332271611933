import React, { Component } from 'react';
import Page from '../../containers/page';

import {
    Container,
    Row,
    Col,
    Modal, ModalHeader, ModalBody, ModalFooter, Button, Input, UncontrolledDropdown, DropdownToggle, DropdownMenu, DropdownItem, FormGroup, Label
} from 'reactstrap';
import CreateReferralIntelligentModal from '../../components/createHealthCareIntelligent';
import PreviewAnsweredChatForm from '../forms/previewAnsweredChatForm';
// import PreviewAnsweredChatForm from '../../components/createHealthCareIntelligent';


import ChatForms from '../forms/chatForms';

import DatePicker from '../../components/forms/fields/datePicker';
import TimePicker from '../../components/forms/fields/timepicker';
import TimePickerV2 from '../../components/forms/fields/timepicker_v2';



import FormBuilder from '../../components/forms/modalFormBuilder';
import { required } from '../../components/forms/validation';
import InfoModal from '../../components/infoModal';

import ErrorModal from '../../components/errorModal';
import DeleteModal from '../../components/deleteModal';
import { getSearchParams, generateSearchLink, generateSearchLinkMultiple } from '../../helpers/linkHelper';
import { API_ENDPOINT } from '../../constants';
import { formValueSelector, change } from 'redux-form';  // ES6
import { connect } from 'react-redux';
import moment from 'moment';
import profilePicture from '../../assets/images/user.png';
import Isvg from 'react-inlinesvg';
import send from '../../assets/svg/send.svg';
import billInfoIcon from '../../assets/svg/bill_info.svg';
import attach from '../../assets/svg/attach.svg';
import image from '../../assets/svg/image.svg';
import notification from '../../assets/svg/notification.svg';
import moreIcon from '../../assets/svg/more.svg';
import docIcon from '../../assets/svg/doc.svg';
import newMessage from '../../assets/svg/new-message.svg'
import Search from '../../components/search'
import call from '../../assets/svg/call.svg';
import ringSound from '../../assets/sound/ring.mp3';
import incomingCall from '../../assets/sound/incoming_call.mp3';
import addIcon from '../../assets/svg/add-icon.svg';
import nowifiIcon from '../../assets/svg/nowifi.svg';
// import camera from '../../assets/svg/camera.svg';

import openIcon from '../../assets/svg/open.svg';
import readIcon from '../../assets/svg/read.svg';
import micIcon from '../../assets/svg/mic.svg';
import micMutedIcon from '../../assets/svg/mic_muted.svg';
import cameraIcon from '../../assets/svg/camera.svg';
import cameraOffIcon from '../../assets/svg/camera_off.svg';
import screenShareIcon from '../../assets/svg/screen_share.svg';
import translateIcon from '../../assets/svg/tr.svg';
import handIcon from '../../assets/svg/hand.svg';
import handSound from '../../assets/sound/raise.mp3';
import moveIcon from '../../assets/svg/move.svg';
import speakerIcon from '../../assets/svg/speaker.svg';
import shareIcon from '../../assets/svg/share.svg';
import settingsIcon from '../../assets/svg/settings.svg';
import secureIcon from '../../assets/svg/secure.svg';
import copyLinkIcon from '../../assets/svg/copy-link-icon.svg';
import videoChatIcon from '../../assets/svg/video.svg';
import userChatIcon from '../../assets/svg/user.svg';
import ChatIcon from '../../assets/svg/video-chat-icon.svg'
import infoIcon from '../../assets/svg/info-icon.svg';
import barsIcon from '../../assets/svg/bars.svg';
import openChat from '../../assets/svg/open_chat.svg';
import closeCaseIcon from '../../assets/svg/close_case.svg';
import pinIcon from '../../assets/svg/pin.svg';
import unpinIcon from '../../assets/svg/unpin.svg';
import diaryIcon from '../../assets/svg/diary.svg';
import resizeMessageBox from '../../assets/svg/resizeMessageBox.svg'

import xIcon from '../../assets/svg/x.svg';
import eyeIcon from '../../assets/svg/eye.svg'

import { Player, Controls } from "@lottiefiles/react-lottie-player";
import GridLayout from 'react-grid-layout';
import garabage from '../../assets/svg/garbage.svg'
import syncIcon from '../../assets/svg/sync.svg';
import flip from '../../assets/svg/flip.svg'

import 'react-grid-layout/css/styles.css'
import 'react-resizable/css/styles.css'
import warrning from '../../assets/svg/warrning.svg';
import peopleIcon from '../../assets/svg/people.svg';
import camIcon from '../../assets/svg/cam.svg';

import starIcon from '../../assets/svg/star.svg';
import fullStarIcon from '../../assets/svg/full-star.svg';
import photoIcon from '../../assets/svg/photo.svg';
import galleryIcon from '../../assets/svg/gallery.svg';
import newTab from '../../assets/svg/newtab.svg';
import phraseIcon from '../../assets/svg/frase_chat.svg';
import info from '../../assets/svg/info.svg'
import formIcon from '../../assets/svg/attach-form.svg';
import journalIcon from '../../assets/svg/journal-icon.svg';
import videoIcon from '../../assets/svg/video-icon.svg';

import PdfSaveV2 from '../../components/pdfSaveV2';
import PrintHelper from '../../components/printHelperV2';
import PdfSave from '../../components/saveAsPdf';

import striptags from 'striptags'

import { v4 as uuidv4 } from 'uuid';
import { Rnd } from 'react-rnd';
import WebRTCTest from '../../components/webRTCTest';
import SoundMeter from '../../helpers/soundMeter';
import BankdIDIdentity from '../../components/bankIDIdentity';
import PhoneField from '../../components/forms/fields/phone';
import { Waypoint } from 'react-waypoint';

import axios from 'axios'
// import FormPreview from '../../components/formPreview';
import FormPreview from '../forms/previewAnsweredForm';

import VideoGateway from './videoGateway';
import VideoCallLogger from '../../components/videoCallLogger';

import { supportedLanguages } from './supportedLanguages';

import ReferralContent from '../../components/referralContent';
import ChatFormFirstSection from '../../components/chatFormFirstSection';
import notificationSound from '../../assets/sound/notification.wav';
import dateIcon from '../../assets/svg/date_1.svg';
import timeIcon from '../../assets/svg/time_1.svg';
import soundTest from '../../assets/svg/test_sound.svg';
import consultationIcon from '../../assets/svg/consultation.svg';
import writeStatementIcon from '../../assets/svg/write_statement.svg';


import { UPLOAD_EXTENSIONS } from './uploadExtensions';
import ConsultationModal from '../../components/e-connect/ConsultationModal';
import WriteStatement from '../../components/chatWriteStatement';
import { patient } from '../../../../server/dev01-notifications';
import Checkbox from '../../components/forms/fields/checkbox';
import { getAvailableDocumentTypesForPatientDocuments } from '../patients/components/patientDocuments';
import { WithClickOutside } from '../../components/forms/fields/dateOfBirth';
import SyncPatientDocumentsModal from '../patients/components/syncPatientDocumentsModal';

const filterDelay = 500;

var browserInfo = require('browser-info');
function imageUrlToBase64(url) {
    return new Promise((resolve, reject) => {
        const img = new Image();
        img.crossOrigin = "anonymous";
        img.onload = () => {
            const canvas = document.createElement("canvas");
            canvas.width = img.width;
            canvas.height = img.height;
            const ctx = canvas.getContext("2d");
            ctx.drawImage(img, 0, 0);
            const base64 = canvas.toDataURL("image/png");
            resolve(base64);
        };
        img.onerror = reject;
        img.src = url;
    });
}


function iosDevice() {
    //return true;
    var userAgent = window.navigator.userAgent;
    if (userAgent.match(/iPad/i) || userAgent.match(/iPhone/i)) {
        return true;
    }
    else {
        return false;
    }
}

function parseJSONUpload(response) {
    if (response?.status === 500) {
        return { error: 'Wrong file type!' }
    } else {
        return response.json()
    }
}
function parseTextUpload(response) {
    if (response?.status === 500) {
        return { error: 'Wrong file type!' }
    } else {
        return response.text()
    }
}




function detectMob() {
    //return true;
    const toMatch = [
        /Android/i,
        /webOS/i,
        /iPhone/i,
        /iPad/i,
        /iPod/i,
        /BlackBerry/i,
        /Windows Phone/i
    ];

    return toMatch.some((toMatchItem) => {
        return navigator.userAgent.match(toMatchItem);
    });
}

function getOffset(el) {
    var _x = 0;
    var _y = 0;
    while (el && !isNaN(el.offsetLeft) && !isNaN(el.offsetTop)) {
        _x += el.offsetLeft - el.scrollLeft;
        _y += el.offsetTop - el.scrollTop;
        el = el.offsetParent;
    }
    return { top: _y, left: _x };
}


function getObjectFitSize(contains /* true = contain, false = cover */, containerWidth, containerHeight, width, height) {
    var doRatio = width / height;
    var cRatio = containerWidth / containerHeight;
    var targetWidth = 0;
    var targetHeight = 0;
    var test = contains ? (doRatio > cRatio) : (doRatio < cRatio);

    if (test) {
        targetWidth = containerWidth;
        targetHeight = targetWidth / doRatio;
    } else {
        targetHeight = containerHeight;
        targetWidth = targetHeight * doRatio;
    }

    return {
        width: targetWidth,
        height: targetHeight,
        x: (containerWidth - targetWidth) / 2,
        y: (containerHeight - targetHeight) / 2
    };
}


function generateAlias(str) {
    str = str.toLowerCase();
    str = str.replace(/ä/g, 'a');
    str = str.replace(/ö/g, 'o');
    str = str.replace(/ü/g, 'u');
    str = str.replace(/ß/g, 'b');

    str = str.replace(/[^a-zA-Z0-9]/gi, '-').toLowerCase()
    str = str.replace(/-+/g, '-');

    return str;
}

function isElementVisible(element) {
    let pos = element.getBoundingClientRect();
    console.log(pos);
    if (pos.top < window.innerHeight &&
        pos.top > -element.offsetHeight
        && pos.left > -element.offsetWidth
        && pos.left < window.innerWidth) {
        return true;
    } else {

        return false;
    }
}


class Timer extends Component {
    constructor(props) {
        super(props);

        this.state = {
            timer: null
        }
    }

    componentDidMount() {
        this.timerHandler = setInterval(() => {

            let diff = Math.floor(new Date().getTime() / 1000) - this.props.startTime;
            var hrs = ~~(diff / 3600);
            var mins = ~~((diff % 3600) / 60);
            var secs = ~~diff % 60;



            this.setState({
                timer: `${hrs <= 9 ? '0' : ''}${hrs}:${mins <= 9 ? '0' : ''}${mins}:${secs <= 9 ? '0' : ''}${secs}`
            })
        }, 1000);

    }

    componentWillUnmount() {
        clearInterval(this.timerHandler);
    }

    render() {
        if (!this.props.startTime) {
            return null;
        }

        return this.state.timer;
    }
}


/**
* Chat module
* @author   Milan Stanojevic
*/
class Chat extends Component {
    constructor(props) {
        super(props);

        this.dropDownRef = React.createRef();
        this.dropDownMenuRef = React.createRef();
        this.handleClickOutside = this.handleClickOutside.bind(this);

        this.sendMessageConatiner = React.createRef();
        this.sendMessageVideoConatiner = React.createRef();
        this.updateJournalNoteDict = {};

        this.remoteVideo = {};
        this.peerStreams = {};
        this.tmpStreams = [];
        this.peerStatus = {};

        this.newTabWindows = [];

        this.state = {
            ...props.initialData,
            form: false,
            useState: false,
            params: {
                entries: 10,
                page: 0
            },
            kbps: 3600,
            mutedParticipants: {},
            items: [],
            groups: [],
            subgroups: [],
            total: 0,
            loading: true,
            conversations: [],
            messages: [],
            numberOfPatients: 0,
            message: '',
            showSeen: {},
            conversationsType: 0,
            videoElements: [],
            remoteUids: {},
            tooltip: 0,
            localVideoX: 0,
            localVideoY: 0,
            hands: {},
            layout: [],
            linkCopied: false,
            connecting: {},
            guestNames: {},
            guestsVerificationStatus: {},
            speaking: {},
            cameraStatus: {},
            micStatus: {},
            chatHelpIcons: false,
            otherVideoElements: [],
            videoCallClients: [],
            mobileBtnsShow: false,
            searchHealthCare: '',
            questionaryModal: null,
            settedRef: false,
            activeQuestionary: null,
            questionaryAnswers: {},
            questionarySave: false,
            journalNote: false,
            countOFSections: 0,
            textClipboard: '',
            htmlJournal: '',
            openedJournalNotes: [],
            openedJournalNotesObjects: [],
            closedConversations: [],
            openedReferralPreview: [],
            draftMessages: [],
            referralSections: { header: '', footer: '', body: '' },
            journalNoteForSave: null,
            conversationPage: 0,
            journalNoteObj: {
                status: '',
                anamnes: '',
                bedomning: '',
                atgard: ''

            },
            membersHoverState: {},
            searchPhrases: '',
            shareModalFields: {
                input: ''
            },
            shareLinkDate: null,
            shareLinkTime: moment(new Date()),
            currentTime: moment(new Date()),
            activeRevisitForm: 1,
            loadingConversations: true,
            chatType: 'patient',
            phraseTab: 1,
            totalNumberOfConversations: 0,
            startNewClientAnimationColorChange: false,
            sendLaterForms: {},
            wrongTime: true,
            videoGatewayLogs: []
        };
        this.chatWrapRef = React.createRef();
        this.state.min = new Date();
        this.state.min.setHours(8, 30, 0)
        this.state.max = new Date();
        this.state.max.setHours(20, 30, 0)

        this.supportedLanguages = supportedLanguages;

        // this.state.currentTime = new Date();
        // this.state.currentTime.setHours(new Date().getHours(), new Date().getMinutes(), 0);

    }




    get = () => {

        this.props.updateMeta(this.props.generateSeoTags(this.state, this.props.lang));

        for (let i = 0; i < this.props.loadData.length; i++) {
            this.props.loadData[i](window.fetch, this.props.lang, this.props[0].match, this.state.useState ? this.state.params : getSearchParams(this.props[0].location.search, { groupId: this.props.selectedGroup, clinic: this.props.selectedClinic })).then((data) => {
                this.setState({
                    ...data,
                    loading: null
                }, () => {
                    this.props.updateMeta(this.props.generateSeoTags(this.state, this.props.lang));
                })
            })
        }

        for (let i = 0; i < this.props.loadDataWithQuery.length; i++) {
            this.props.loadDataWithQuery[i](window.fetch, this.props.lang, this.props[0].match, this.state.useState ? this.state.params : getSearchParams(this.props[0].location.search, { groupId: this.props.selectedGroup, clinic: this.props.selectedClinic, filter: this.state.healthCareModal ? this.state.searchHealthCare : null, filterPhrases: this.state.quickPhraseModal ? this.state.searchPhrases : null })).then((data) => {
                this.setState({
                    ...data,
                    loading: null
                }, () => {
                    this.props.updateMeta(this.props.generateSeoTags(this.state, this.props.lang));
                })
            })
        }

    }

    validateEmail = (email) => {
        const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return re.test(String(email).toLowerCase());
    }

    stopAnimation = () => {
        this.player.current.play();
    };
    indexOfIndexOf(arr, str) {
        for (let i = 0; i < arr.length; i++) {
            let idx = arr[i].indexOf(str);
            if (idx !== -1) {
                return i;
            }

        }

        return -1;
    }


    toggleHelpIcons = () => {
        this.setState({
            dropdownMore: false,
            chatHelpIcons: !this.state.chatHelpIcons
        })
    }

    /* fullSizeHandler = () => {
         try {
             console.log(this.state.fullScreen)
             if (!this.state.fullScreen) {
                 this.setState({
                     fullScreen: true
                 })
             } else {
                 this.setState({
                     fullScreen: false
                 })
 
             }
         } catch (e) { alert(e) }
     }
 */


    handleClickOutsideSendMessageContainer = (event) => {
        if (event && event.target && this.sendMessageConatiner && this.sendMessageConatiner.contains && !this.sendMessageConatiner.contains(event.target)) {
            if (this.state.activeWriteMessageField) {
                this.setState({ activeWriteMessageField: false })
            }
        }
    }
    handleClickOutsideSendMessageVideoContainer = (event) => {
        if (event && event.target && this.sendMessageVideoConatiner && this.sendMessageVideoConatiner.contains && !this.sendMessageVideoConatiner.contains(event.target)) {
            if (this.state.activeWriteMessageField) {
                this.setState({ activeWriteMessageField: false })
            }
        }
    }
    checkTabFocused = () => {
        /* if (document.visibilityState === 'visible') {
             if (this.state.conversation && this.state.conversation?._id && this.props.socket) {
                 this.props.socket.emit('messages', { conversation: this.state.conversation?._id, page: this.state.page, chatType: this.state.chatType })
 
             }
         }
         */
    }


    loadRaiseHandSound = () => {
        this.raiseHandSoundAudioCtx = new (window.AudioContext || window.webkitAudioContext)();

        let xhr = new XMLHttpRequest();
        xhr.open('GET', handSound);
        xhr.responseType = 'arraybuffer';
        xhr.addEventListener('load', () => {

            this.raiseHandSoundAudioCtx.decodeAudioData(xhr.response).then((audioBuffer) => {
                this.raiseHandSoundAudioBuffer = audioBuffer;
            });
        });
        xhr.send();

    }

    playRaiseHandSound = () => {
        if (!this.raiseHandSoundAudioBuffer) {
            return;
        }

        this.raiseHandSoundSource = this.raiseHandSoundAudioCtx.createBufferSource();
        this.raiseHandSoundSource.buffer = this.raiseHandSoundAudioBuffer;
        this.raiseHandSoundSource.connect(this.raiseHandSoundAudioCtx.destination);
        this.raiseHandSoundSource.loop = false;

        if (this.state.audioOutputDevice) {
            this.raiseHandSoundAudioCtx.setSinkId(this.state.audioOutputDevice);
        }

        this.raiseHandSoundSource.start();
    }


    componentDidMount() {
        // console.log('this.props[0].location.search', this.props[0].location.search)
        this.loadRaiseHandSound()
        document.addEventListener('visibilitychange', this.checkTabFocused);

        window.onbeforeunload = (e) => {
            if (this.state.call) {
                e = e || window.event;
                if (confirm('Do you really want to end this call?')) {
                    return e;//return event object, unaltered... page will unload
                }
                if (e.preventDefault) {
                    e.preventDefault();
                    e.stopPropagation();
                }
                else {
                    e.returnValue = false;
                    e.cancelBubble = true;
                }
                return false;
            }
        };


        document.body.style.overflowX = 'hidden';

        window.addEventListener("orientationchange", this.orientationChange, false);
        window.addEventListener('resize', this.resizeWindow);
        document.addEventListener('mousedown', this.handleClickOutsideSendMessageContainer);
        document.addEventListener('mousedown', this.handleClickOutsideSendMessageVideoContainer);

        /*if (document.addEventListener) {
            document.addEventListener('fullscreenchange', this.fullSizeHandler, false);
            document.addEventListener('mozfullscreenchange', this.fullSizeHandler, false);
            document.addEventListener('MSFullscreenChange', this.fullSizeHandler, false);
            document.addEventListener('webkitfullscreenchange', this.fullSizeHandler, false);
        }*/

        if (this.props.dateFormat) {
            this.setState({
                shareLinkDate: this.props.getStringDateTs(Math.floor(new Date().getTime() / 1000), this.props.dateFormat)/*moment.unix(Math.floor(new Date().getTime() / 1000)).format(this.props.dateFormat)*/
            })

        }

        if (typeof window !== 'undefined') { window.scrollTo(0, 0); }

        this.get();

        if (this.props.uData && !localStorage?.getItem('groupAlias') && (this.props.uData?.userLevel == 1 || this.props.uData?.userLevel == 2 || (this.props.uData?.userLevel == 20 && localStorage?.getItem?.('profile') == 'patient'))) {
            this.setState({
                groupError: true
            })
        }


        if (this.props[0].location.pathname.indexOf('video-call') !== -1) {
            // let browserName = browserInfo();

            // let userAgent = navigator.userAgent;
            // let browserNameUA = 'No browser detection'
            // if (userAgent.match(/chrome|chromium|crios/i)) {
            //     browserNameUA = "Chrome";
            // } else if (userAgent.match(/firefox|fxios/i)) {
            //     browserNameUA = "Firefox";
            // } else if (userAgent.match(/safari/i)) {
            //     browserNameUA = "Safari";
            // } else if (userAgent.match(/edg/i)) {
            //     browserNameUA = "Edge";
            // }

            // this.setState({
            //     browserName: browserName.name,
            //     browserNameUA: browserNameUA
            // })

            this.calculateKbps();
        }

        if (this.props[0].location.search.indexOf('callEnded') !== -1) {
            this.setState({
                hostEndedCall: true
            })
        }

        let params = getSearchParams(this.props[0].location.search);

        let videoCall;
        if (this.props && this.props[0] && this.props[0].location && this.props[0].location.pathname && this.props[0].location.pathname.indexOf('video-call') !== -1) {
            videoCall = true;
        }
        if (this.props[0].location.search.indexOf('closeConversation') !== -1) {
            // console.log(this.props[0].location.search)
            let conversation = this.props[0].location.search.split('?c')[1]
            if (conversation)
                conversation = conversation.split('onversation=')[1]
            //console.log(conversation)
            // console.log('ssssssssssssssssssssss');
            fetch(API_ENDPOINT + '/data/conversationRatings/check', {
                method: 'POST',
                headers: {
                    'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage?.getItem('authToken')}` : null,
                    'content-type': 'application/json'
                },
                body: JSON.stringify({ conversation: conversation })
            }).then(res => res.json()).then((result) => {
                if (!result) {
                    return;
                }
                // console.log(result)
                if (!result.check && result.user == this.props.uData?._id) {

                    this.setState({
                        rateConversationModal: {
                            conversation: conversation,
                            rating1: 0,
                            rating2: 0,
                            rating3: 0,
                            comment: ''
                        },
                    }, () => {


                    })

                } else if (result.check && result.user == this.props.uData?._id) {
                    this.setState({
                        rateConversationInfoModal: true
                    })
                }

            }).catch((err) => {

            })
        }
        if (params.roomId) {
            this.props.initSocketForVideoRoom(params.roomId, () => {
                this.setState({
                    _registerEvents: true
                }, () => {
                    this.registerEvents();
                    // this.props.socket.emit('conversations', { profile: typeof window != 'undefined' ? localStorage?.getItem?.('profile') : null, conversationsType: this.state.conversationsType, windowConversation: ((this.props[0].match.params && this.props[0].match.params.windowConversation) || (this.props[0].match.params && this.props[0].match.params.conversation)) });
                    this.setState({ conversationPage: 0 }, () => {
                        // console.log('EMIT_CONVERSATIONS 1');
                        this.emitConversations()
                    });
                    // this.emitConversations();

                });
            });
        } else {


            if (this.props.uData && this.props.socket && !this.state._registerEvents) {
                this.setState({
                    _registerEvents: true
                }, () => {
                    this.registerEvents();

                    // this.props.socket.emit('conversations', { profile: typeof window != 'undefined' ? localStorage?.getItem?.('profile') : null, conversationsType: this.state.conversationsType, windowConversation: (this.props[0].match.params && this.props[0].match.params.windowConversation) || params.conversation || (this.props[0].match.params && this.props[0].match.params.conversation), strictConversation: params.patientSearchModule ? true : false });
                    // console.log('EMIT_CONVERSATIONS 2');
                    this.emitConversations({ windowConversation: (this.props[0].match.params && this.props[0].match.params.windowConversation) || params.conversation || (this.props[0].match.params && this.props[0].match.params.conversation), strictConversation: params.patientSearchModule ? true : videoCall ? true : false });

                })
            } else {
                this.socketInterval = setInterval(() => {
                    if (this.props.uData && this.props.socket && !this.state._registerEvents) {
                        if (this.socketInterval) {
                            clearInterval(this.socketInterval)
                        }

                        this.setState({
                            _registerEvents: true
                        }, () => {
                            this.registerEvents();
                            // console.log('EMIT_CONVERSATIONS 3');
                            // this.props.socket.emit('conversations', { profile: typeof window != 'undefined' ? localStorage?.getItem?.('profile') : null, conversationsType: this.state.conversationsType, windowConversation: (this.props[0].match.params && this.props[0].match.params.windowConversation) || params.conversation || (this.props[0].match.params && this.props[0].match.params.conversation), strictConversation: params.patientSearchModule ? true : false });
                            this.emitConversations({ windowConversation: (this.props[0].match.params && this.props[0].match.params.windowConversation) || params.conversation || (this.props[0].match.params && this.props[0].match.params.conversation), strictConversation: params.patientSearchModule ? true : videoCall ? true : false });

                        })
                    }

                }, 150);
            }

        }
        let textClipboard = `Kontaktorsak:
Anamnes
Status
Bedömning
Åtgärd
`;
        let html = `
                    <div>
                        <div>
                            <h1>${'Journal note'.translate('se')}</h1>
                        </div>
                        <div style='margin-bottom: 10px'>
                            <h5 style='font-size: 24px; font-weight: 600'>Kontaktorsak:</h5>
                        </div>
                        <div style='margin-bottom: 10px'>
                            <h5 style='font-size: 24px; font-weight: 600'>Anamnes:</h5>
                        </div>
                        <div style='margin-bottom: 10px'>
                            <h5 style='font-size: 24px; font-weight: 600'>Status:</h5>
                        </div>
                        <div style='margin-bottom: 10px'>
                            <h5 style='font-size: 24px; font-weight: 600'>Bedömning:</h5>
                        </div>
                        <div style='margin-bottom: 10px'>
                            <h5 style='font-size: 24px; font-weight: 600'>Åtgärd:</h5>
                        </div>               
                    </div>
                `;

        this.setState({ htmlJournal: html, textClipboard })



        this.videoCallConnectionInterval = setInterval(async () => {

        }, 1000);

        document.addEventListener('mousedown', this.handleClickOutside);

    }
    generateSectionsForReferrals = (itemsArr) => {
        let sections = {};
        let items = [{ type: 0 }, ...itemsArr];
        let lastSectionId = -1;
        for (let i = 0; i < items.length; i++) {
            if (items[i].type == 0) {
                lastSectionId++;
                sections[lastSectionId] = {
                    name: items[i].name,
                    displayOnReferral: false,
                    questions: [],
                    conditions: items[i].conditions ? items[i].conditions : [],
                    displayOnReferral: items[i].displayOnReferral,
                    fontStyle: items[i].fontStyle,
                    fontSize: items[i].fontSize,
                    fontWeight: items[i].fontWeight,
                    required: items[i].required,

                }
            }
            if (lastSectionId != -1 && items[i].type != 0) {


                let wikiLinks = [];

                items[i].wikiLinks = wikiLinks;


                if (items[i].type == 1) {
                    let displayOnReferral = false;
                    if (items[i].answers) {
                        for (let j = 0; j < items[i].answers.length; j++) {
                            if (items[i].answers[j].text) {
                                displayOnReferral = true;
                            }
                        }
                    }

                    sections[lastSectionId].questions.push({
                        id: items[i].id,
                        type: items[i].type,
                        name: items[i].name,
                        answers: items[i].answers,
                        conditions: items[i].conditions ? items[i].conditions : [],
                        displayOnReferral: displayOnReferral,
                        wikiLinks: items[i].wikiLinks,
                        fontStyle: items[i].fontStyle,
                        fontSize: items[i].fontSize,
                        fontWeight: items[i].fontWeight,
                        required: items[i].required,
                    });

                } else if (items[i].type == 2) {
                    let displayOnReferral = false;
                    if (items[i].answers) {
                        for (let j = 0; j < items[i].answers.length; j++) {

                            if (items[i].answers[j].text) {
                                displayOnReferral = true;
                            }
                        }
                    }
                    sections[lastSectionId].questions.push({
                        id: items[i].id,
                        type: items[i].type,
                        name: items[i].name,
                        answers: items[i].answers,
                        conditions: items[i].conditions ? items[i].conditions : [],
                        answerPrefix: items[i].answerPrefix,
                        userInput: items[i].userInput,
                        displayOnReferral: displayOnReferral || items[i].answerPrefix ? true : false,
                        wikiLinks: items[i].wikiLinks,
                        fontStyle: items[i].fontStyle,
                        fontSize: items[i].fontSize,
                        fontWeight: items[i].fontWeight,
                        required: items[i].required,

                    })
                } else if (items[i].type == 3) {
                    sections[lastSectionId].questions.push({
                        id: items[i].id,
                        type: items[i].type,
                        name: items[i].name,
                        conditions: items[i].conditions ? items[i].conditions : [],
                        answerPrefix: items[i].answerPrefix,
                        wikiLinks: items[i].wikiLinks,
                        displayOnReferral: true,
                        fontStyle: items[i].fontStyle,
                        fontSize: items[i].fontSize,
                        fontWeight: items[i].fontWeight,
                        required: items[i].required,
                    })

                } else if (items[i].type == 4) {
                    sections[lastSectionId].questions.push({
                        id: items[i].id,
                        type: items[i].type,
                        name: items[i].name,
                        text: items[i].text,
                        userInput: items[i].userInput,
                        conditions: items[i].conditions ? items[i].conditions : [],
                        wikiLinks: items[i].wikiLinks,
                        fontStyle: items[i].fontStyle,
                        fontSize: items[i].fontSize,
                        fontWeight: items[i].fontWeight,
                        required: items[i].required,
                    })
                } else if (items[i].type == 15) {
                    sections[lastSectionId].questions.push({
                        id: items[i].id,
                        type: items[i].type,
                        name: items[i].name,
                        text: items[i].text,
                        userInput: items[i].userInput,
                        conditions: items[i].conditions ? items[i].conditions : [],
                        wikiLinks: items[i].wikiLinks,
                        fontStyle: items[i].fontStyle,
                        fontSize: items[i].fontSize,
                        fontWeight: items[i].fontWeight,
                        required: items[i].required,
                        hideTitle: items[i].hideTitle ? true : false,
                        titleFontStyle: items[i].titleFontStyle,
                        titleFontSize: items[i].titleFontSize,
                        titleFontWeight: items[i].titleFontWeight,
                    })

                } else if (items[i].type == 5) {
                    sections[lastSectionId].questions.push({
                        id: items[i].id,
                        type: items[i].type,
                        name: items[i].name,
                        conditions: items[i].conditions ? items[i].conditions : [],
                        wikiLinks: items[i].wikiLinks,
                        displayOnReferral: true,
                        fontStyle: items[i].fontStyle,
                        fontSize: items[i].fontSize,
                        fontWeight: items[i].fontWeight,
                        required: items[i].required,
                        score: items[i].score
                    })

                } else if (items[i].type == 6) {
                    sections[lastSectionId].questions.push({
                        id: items[i].id,
                        type: items[i].type,
                        name: items[i].name,
                        conditions: items[i].conditions ? items[i].conditions : [],
                        wikiLinks: items[i].wikiLinks,
                        displayOnReferral: true,
                        fontStyle: items[i].fontStyle,
                        fontSize: items[i].fontSize,
                        fontWeight: items[i].fontWeight,
                        required: items[i].required,
                        score: items[i].score
                    })

                } else if (items[i].type == 7) {
                    sections[lastSectionId].questions.push({
                        id: items[i].id,
                        type: items[i].type,
                        name: items[i].name,
                        conditions: items[i].conditions ? items[i].conditions : [],
                        wikiLinks: items[i].wikiLinks,
                        displayOnReferral: true,
                        fontStyle: items[i].fontStyle,
                        fontSize: items[i].fontSize,
                        fontWeight: items[i].fontWeight,
                        required: items[i].required,
                    })

                } else if (items[i].type == 8) {
                    sections[lastSectionId].questions.push({
                        id: items[i].id,
                        type: items[i].type,
                        name: items[i].name,
                        conditions: items[i].conditions ? items[i].conditions : [],
                        wikiLinks: items[i].wikiLinks,
                        displayOnReferral: true,
                        fontStyle: items[i].fontStyle,
                        fontSize: items[i].fontSize,
                        fontWeight: items[i].fontWeight,
                        required: items[i].required,
                    })

                } else if (items[i].type == 9) {
                    sections[lastSectionId].questions.push({
                        id: items[i].id,
                        type: items[i].type,
                        name: items[i].name,
                        conditions: items[i].conditions ? items[i].conditions : [],
                        wikiLinks: items[i].wikiLinks,
                        displayOnReferral: true,
                        fontStyle: items[i].fontStyle,
                        fontSize: items[i].fontSize,
                        fontWeight: items[i].fontWeight,
                        required: items[i].required,
                    })

                } else if (items[i].type == 10) {
                    sections[lastSectionId].questions.push({
                        id: items[i].id,
                        type: items[i].type,
                        name: items[i].name,
                        userInput: items[i].userInput,
                        conditions: items[i].conditions ? items[i].conditions : [],
                        wikiLinks: items[i].wikiLinks,
                        displayOnReferral: true,
                        fontStyle: items[i].fontStyle,
                        fontSize: items[i].fontSize,
                        fontWeight: items[i].fontWeight,
                        required: items[i].required,
                    })
                } else if (items[i].type == 12) {
                    sections[lastSectionId].questions.push({
                        id: items[i].id,
                        type: items[i].type,
                        name: items[i].name,
                        conditions: items[i].conditions ? items[i].conditions : [],
                        wikiLinks: items[i].wikiLinks,
                        displayOnReferral: true,
                        fontStyle: items[i].fontStyle,
                        fontSize: items[i].fontSize,
                        fontWeight: items[i].fontWeight,
                        required: items[i].required,
                        score: items[i].score
                    })
                } else if (items[i].type == 14) {
                    sections[lastSectionId].questions.push({
                        id: items[i].id,
                        type: items[i].type,
                        name: items[i].name,
                        conditions: items[i].conditions ? items[i].conditions : [],
                        wikiLinks: items[i].wikiLinks,
                        displayOnReferral: true,
                        fontStyle: items[i].fontStyle,
                        fontSize: items[i].fontSize,
                        fontWeight: items[i].fontWeight,
                        required: items[i].required,
                        score: items[i].score
                    })

                } else if (items[i].type == 13) {
                    sections[lastSectionId].questions.push({
                        id: items[i].id,
                        type: items[i].type,
                        name: items[i].name,
                        conditions: items[i].conditions ? items[i].conditions : [],
                        wikiLinks: items[i].wikiLinks,
                        displayOnReferral: true,
                        fontStyle: items[i].fontStyle,
                        fontSize: items[i].fontSize,
                        fontWeight: items[i].fontWeight,
                        required: items[i].required,
                        score: items[i].score
                    })

                } else if (items[i].type == 16) {
                    sections[lastSectionId].questions.push({
                        id: items[i].id,
                        type: items[i].type,
                        name: items[i].name,
                        conditions: items[i].conditions ? items[i].conditions : [],
                        wikiLinks: items[i].wikiLinks,
                        displayOnReferral: true,
                        fontStyle: items[i].fontStyle,
                        fontSize: items[i].fontSize,
                        fontWeight: items[i].fontWeight,
                        required: items[i].required,
                        blocks: items[i].blocks,
                    })

                } else if (items[i].type == 17) {
                    sections[lastSectionId].questions.push({
                        id: items[i].id,
                        type: items[i].type,
                        name: items[i].name,
                        conditions: items[i].conditions ? items[i].conditions : [],
                        wikiLinks: items[i].wikiLinks,
                        displayOnReferral: true,
                        fontStyle: items[i].fontStyle,
                        fontSize: items[i].fontSize,
                        fontWeight: items[i].fontWeight,
                        required: items[i].required,
                        blocks: items[i].blocks,

                    })

                }
            }
        }

        return Object.values(sections);
    }
    generateSections = () => {
        let sections = {};
        if (this.state.activeQuestionary) {
            let items = [{ type: 0 }, ...this.state.activeQuestionary.items];
            let lastSectionId = -1;
            for (let i = 0; i < items.length; i++) {
                if (items[i].type == 0) {
                    lastSectionId++;
                    sections[lastSectionId] = {
                        name: items[i].name,
                        displayOnReferral: false,
                        questions: [],
                        conditions: items[i].conditions ? items[i].conditions : [],
                        displayOnReferral: items[i].displayOnReferral,
                        fontStyle: items[i].fontStyle,
                        fontSize: items[i].fontSize,
                        fontWeight: items[i].fontWeight,
                        required: items[i].required,

                    }
                }
                if (lastSectionId != -1 && items[i].type != 0) {


                    let wikiLinks = [];

                    items[i].wikiLinks = wikiLinks;


                    if (items[i].type == 1) {
                        let displayOnReferral = false;
                        if (items[i].answers) {
                            for (let j = 0; j < items[i].answers.length; j++) {
                                if (items[i].answers[j].text) {
                                    displayOnReferral = true;
                                }
                            }
                        }

                        sections[lastSectionId].questions.push({
                            id: items[i].id,
                            type: items[i].type,
                            name: items[i].name,
                            answers: items[i].answers,
                            conditions: items[i].conditions ? items[i].conditions : [],
                            displayOnReferral: displayOnReferral,
                            wikiLinks: items[i].wikiLinks,
                            fontStyle: items[i].fontStyle,
                            fontSize: items[i].fontSize,
                            fontWeight: items[i].fontWeight,
                            required: items[i].required,
                        });

                    } else if (items[i].type == 2) {
                        let displayOnReferral = false;
                        if (items[i].answers) {
                            for (let j = 0; j < items[i].answers.length; j++) {

                                if (items[i].answers[j].text) {
                                    displayOnReferral = true;
                                }
                            }
                        }
                        sections[lastSectionId].questions.push({
                            id: items[i].id,
                            type: items[i].type,
                            name: items[i].name,
                            answers: items[i].answers,
                            conditions: items[i].conditions ? items[i].conditions : [],
                            answerPrefix: items[i].answerPrefix,
                            userInput: items[i].userInput,
                            displayOnReferral: displayOnReferral || items[i].answerPrefix ? true : false,
                            wikiLinks: items[i].wikiLinks,
                            fontStyle: items[i].fontStyle,
                            fontSize: items[i].fontSize,
                            fontWeight: items[i].fontWeight,
                            required: items[i].required,

                        })
                    } else if (items[i].type == 3) {
                        sections[lastSectionId].questions.push({
                            id: items[i].id,
                            type: items[i].type,
                            name: items[i].name,
                            conditions: items[i].conditions ? items[i].conditions : [],
                            answerPrefix: items[i].answerPrefix,
                            wikiLinks: items[i].wikiLinks,
                            displayOnReferral: true,
                            fontStyle: items[i].fontStyle,
                            fontSize: items[i].fontSize,
                            fontWeight: items[i].fontWeight,
                            required: items[i].required,
                        })

                    } else if (items[i].type == 4) {
                        sections[lastSectionId].questions.push({
                            id: items[i].id,
                            type: items[i].type,
                            name: items[i].name,
                            text: items[i].text,
                            conditions: items[i].conditions ? items[i].conditions : [],
                            wikiLinks: items[i].wikiLinks,
                            fontStyle: items[i].fontStyle,
                            fontSize: items[i].fontSize,
                            fontWeight: items[i].fontWeight,
                            required: items[i].required,
                        })

                    } else if (items[i].type == 5) {
                        sections[lastSectionId].questions.push({
                            id: items[i].id,
                            type: items[i].type,
                            name: items[i].name,
                            conditions: items[i].conditions ? items[i].conditions : [],
                            wikiLinks: items[i].wikiLinks,
                            displayOnReferral: true,
                            fontStyle: items[i].fontStyle,
                            fontSize: items[i].fontSize,
                            fontWeight: items[i].fontWeight,
                            required: items[i].required,
                        })

                    } else if (items[i].type == 6) {
                        sections[lastSectionId].questions.push({
                            id: items[i].id,
                            type: items[i].type,
                            name: items[i].name,
                            conditions: items[i].conditions ? items[i].conditions : [],
                            wikiLinks: items[i].wikiLinks,
                            displayOnReferral: true,
                            fontStyle: items[i].fontStyle,
                            fontSize: items[i].fontSize,
                            fontWeight: items[i].fontWeight,
                            required: items[i].required,
                        })

                    } else if (items[i].type == 7) {
                        sections[lastSectionId].questions.push({
                            id: items[i].id,
                            type: items[i].type,
                            name: items[i].name,
                            conditions: items[i].conditions ? items[i].conditions : [],
                            wikiLinks: items[i].wikiLinks,
                            displayOnReferral: true,
                            fontStyle: items[i].fontStyle,
                            fontSize: items[i].fontSize,
                            fontWeight: items[i].fontWeight,
                            required: items[i].required,
                        })

                    } else if (items[i].type == 8) {
                        sections[lastSectionId].questions.push({
                            id: items[i].id,
                            type: items[i].type,
                            name: items[i].name,
                            conditions: items[i].conditions ? items[i].conditions : [],
                            wikiLinks: items[i].wikiLinks,
                            displayOnReferral: true,
                            fontStyle: items[i].fontStyle,
                            fontSize: items[i].fontSize,
                            fontWeight: items[i].fontWeight,
                            required: items[i].required,
                        })

                    } else if (items[i].type == 9) {
                        sections[lastSectionId].questions.push({
                            id: items[i].id,
                            type: items[i].type,
                            name: items[i].name,
                            conditions: items[i].conditions ? items[i].conditions : [],
                            wikiLinks: items[i].wikiLinks,
                            displayOnReferral: true,
                            fontStyle: items[i].fontStyle,
                            fontSize: items[i].fontSize,
                            fontWeight: items[i].fontWeight,
                            required: items[i].required,
                        })

                    } else if (items[i].type == 10) {
                        sections[lastSectionId].questions.push({
                            id: items[i].id,
                            type: items[i].type,
                            name: items[i].name,
                            userInput: items[i].userInput,
                            conditions: items[i].conditions ? items[i].conditions : [],
                            wikiLinks: items[i].wikiLinks,
                            displayOnReferral: true,
                            fontStyle: items[i].fontStyle,
                            fontSize: items[i].fontSize,
                            fontWeight: items[i].fontWeight,
                            required: items[i].required,
                        })
                    } else if (items[i].type == 12) {
                        sections[lastSectionId].questions.push({
                            id: items[i].id,
                            type: items[i].type,
                            name: items[i].name,
                            conditions: items[i].conditions ? items[i].conditions : [],
                            wikiLinks: items[i].wikiLinks,
                            displayOnReferral: true,
                            fontStyle: items[i].fontStyle,
                            fontSize: items[i].fontSize,
                            fontWeight: items[i].fontWeight,
                            required: items[i].required,
                        })

                    } else if (items[i].type == 13) {
                        sections[lastSectionId].questions.push({
                            id: items[i].id,
                            type: items[i].type,
                            name: items[i].name,
                            conditions: items[i].conditions ? items[i].conditions : [],
                            wikiLinks: items[i].wikiLinks,
                            displayOnReferral: true,
                            fontStyle: items[i].fontStyle,
                            fontSize: items[i].fontSize,
                            fontWeight: items[i].fontWeight,
                            required: items[i].required,
                        })

                    } else if (items[i].type == 15) {
                        sections[lastSectionId].questions.push({
                            id: items[i].id,
                            type: items[i].type,
                            name: items[i].name,
                            text: items[i].text,
                            userInput: items[i].userInput,
                            conditions: items[i].conditions ? items[i].conditions : [],
                            wikiLinks: items[i].wikiLinks,
                            fontStyle: items[i].fontStyle,
                            fontSize: items[i].fontSize,
                            fontWeight: items[i].fontWeight,
                            required: items[i].required,
                            hideTitle: items[i].hideTitle ? true : false,
                            titleFontStyle: items[i].titleFontStyle,
                            titleFontSize: items[i].titleFontSize,
                            titleFontWeight: items[i].titleFontWeight,
                        })

                    } else if (items[i].type == 16) {
                        sections[lastSectionId].questions.push({
                            id: items[i].id,
                            type: items[i].type,
                            name: items[i].name,
                            conditions: items[i].conditions ? items[i].conditions : [],
                            wikiLinks: items[i].wikiLinks,
                            displayOnReferral: true,
                            fontStyle: items[i].fontStyle,
                            fontSize: items[i].fontSize,
                            fontWeight: items[i].fontWeight,
                            required: items[i].required,
                            blocks: items[i].blocks,
                        })

                    } else if (items[i].type == 17) {
                        sections[lastSectionId].questions.push({
                            id: items[i].id,
                            type: items[i].type,
                            name: items[i].name,
                            conditions: items[i].conditions ? items[i].conditions : [],
                            wikiLinks: items[i].wikiLinks,
                            displayOnReferral: true,
                            fontStyle: items[i].fontStyle,
                            fontSize: items[i].fontSize,
                            fontWeight: items[i].fontWeight,
                            required: items[i].required,
                            blocks: items[i].blocks,

                        })

                    }
                }
            }
            return Object.values(sections);
        }

    }
    checkPoints = () => {
        let sections = this.state.sections;
        let answers = this.state.questionaryAnswers;
        let totalSubtotal = [];
        if (sections && sections.length) {
            for (let i = 0; i < sections.length; i++) {
                if (sections[i].questions && sections[i].questions?.length) {
                    for (let j = 0; j < sections[i].questions?.length; j++) {
                        if (sections[i].questions[j].type == 16 || sections[i].questions[j].type == 17) {
                            totalSubtotal.push({
                                type: sections[i].questions[j].type,
                                id: sections[i].questions[j].id,
                                conditions: sections[i].questions[j].conditions,
                                sum: 0
                            })
                        }
                    }
                }
            }
        }
        if (totalSubtotal && totalSubtotal.length && answers) {
            let sumSubtotal = 0;
            let sumTotal = 0;
            for (let i = 0; i < totalSubtotal.length; i++) {
                totalSubtotal[i].sum = 0;
            }

            let questionsArr = [];
            for (let i = 0; i < sections.length; i++) {
                for (let j = 0; j < sections[i].questions?.length; j++) {
                    if (answers[sections[i].questions[j].id]) {
                        questionsArr.push(sections[i].questions[j].id)
                    }
                }
            }

            for (let questionIdx = 0; questionIdx < questionsArr.length; questionIdx++) {
                let key = questionsArr[questionIdx];
                let question = this.getQuestion(key)
                if (question) {
                    if (question.type == 16) {
                        let index = totalSubtotal.findIndex(el => el.id == question.id)
                        if (index > -1) {
                            totalSubtotal[index].sum = sumSubtotal;
                            if (question.blocks && question.blocks.length) {
                                let blocks = question.blocks;
                                for (let i = 0; i < blocks.length; i++) {
                                    let start = 0;
                                    let end = 0;
                                    if (blocks[i].start) {
                                        start = Number(blocks[i].start)
                                    }
                                    if (blocks[i].end) {
                                        end = Number(blocks[i].end)
                                    }


                                }
                            }
                            sumSubtotal = 0;
                        }

                    }
                    if (question.type == 17) {
                        let index = totalSubtotal.findIndex(el => el.id == question.id)
                        if (index > -1) {

                            totalSubtotal[index].sum = sumTotal;
                            if (question.blocks && question.blocks.length) {
                                let blocks = question.blocks;
                                for (let i = 0; i < blocks.length; i++) {
                                    let start = 0;
                                    let end = 0;
                                    if (blocks[i].start) {
                                        start = Number(blocks[i].start)
                                    }
                                    if (blocks[i].end) {
                                        end = Number(blocks[i].end)
                                    }


                                }
                            }
                            sumTotal = 0;
                        }
                    }

                    if (question.type == 1 && question.answers && question.answers.filter(el => el.id == answers[key]).length && question.answers.filter(el => el.id == answers[key])[0].score) {
                        let score = question.answers.filter(el => el.id == answers[key])[0].score;
                        sumSubtotal += score;
                        sumTotal += score;
                    }

                    if (question.type == 2 && question.answers && answers[key] && answers[key].length) {
                        for (let i = 0; i < answers[key].length; i++) {
                            if (question.answers.filter(el => el.id == answers[key][i]).length && question.answers.filter(el => el.id == answers[key][i])[0].score) {
                                let score = question.answers.filter(el => el.id == answers[key][i])[0].score;
                                sumSubtotal += score;
                                sumTotal += score;
                            }
                        }
                    }
                    if ((question.type == 5 || question.type == 6 || question.type == 12 || question.type == 13 || question.type == 14) && question.score) {
                        let score = question.score;
                        sumSubtotal += score;
                        sumTotal += score;
                    }
                }
            }



        }
        let response = {}
        if (totalSubtotal && totalSubtotal.length) {
            for (let i = 0; i < totalSubtotal.length; i++) {
                if (totalSubtotal[i].id) {
                    response[totalSubtotal[i].id] = totalSubtotal[i].sum ? totalSubtotal[i].sum : 0;
                }
            }
        }
        return response;
    }
    // getStream = (reopen = false) => {
    //     if (reopen) {
    //         if (this.audioVideoStream) {
    //             if (this.localVideo) {
    //                 this.localVideo.srcObject = null;
    //             }

    //             this.audioVideoStream.getAudioTracks()[0].stop();
    //             this.audioVideoStream.getVideoTracks()[0].stop();


    //             for (let i = 0; i < this.tmpStreams.length; i++) {
    //                 try {
    //                     if (this.tmpStreams[i]) {
    //                         this.tmpStreams[i].getTracks().forEach(track => track.stop());
    //                         this.tmpStreams[i] = null;
    //                     }
    //                 } catch (e) { }
    //             }


    //             this.audioVideoStream = null;

    //         }
    //     }

    //     return new Promise((resolve, reject) => {
    //         if (this.audioVideoStream) {
    //             resolve(this.audioVideoStream);
    //         } else {

    //             try {
    //                 if (!this.audioContext) {
    //                     window.AudioContext = window.AudioContext || window.webkitAudioContext;
    //                     this.audioContext = new AudioContext();
    //                 }
    //             }
    //             catch (e) {
    //                 alert('Web Audio API not supported.');
    //             }


    //             let resolutionDict = {
    //                 '480': {
    //                     width: 640,
    //                     height: 480
    //                 },
    //                 '720': {
    //                     width: 1280,
    //                     height: 720
    //                 },
    //                 '1080': {
    //                     width: 1920,
    //                     height: 1080
    //                 },
    //             }

    //             if (!this.state.fullHDSupport) {
    //                 resolutionDict['1080'] = {
    //                     width: 1280,
    //                     height: 720
    //                 }
    //             }


    //             let resolution = resolutionDict[this.state.videoResolution];

    //             navigator?.mediaDevices?.getUserMedia?.(
    //                 { video: this.state.videoInputDevice ? { deviceId: this.state.videoInputDevice, width: { ideal: resolution.width }, height: { ideal: resolution.height } } : this.state.camera === true ? { facingMode: { exact: "environment" }, width: { ideal: resolution.width }, height: { ideal: resolution.height } } : this.state.camera === false ? { facingMode: "user", width: { ideal: resolution.width }, height: { ideal: resolution.height } } : { width: { ideal: resolution.width }, height: { ideal: resolution.height } }, audio: this.state.audioInputDevice ? { deviceId: this.state.audioInputDevice } : true }).then(
    //                     stream => {

    //                         if (this.state.cameraOff) {
    //                             stream.getVideoTracks().forEach(track => track.enabled = false);
    //                         }

    //                         if (this.state.micOff) {
    //                             stream.getAudioTracks().forEach(track => track.enabled = false);
    //                         }

    //                         this.tmpStreams.push(stream);

    //                         this.audioVideoStream = stream;


    //                         VideoGateway.replaceStream(this.audioVideoStream);
    //                         setTimeout(() => {
    //                             if (this.state.micOff) {
    //                                 this.audioVideoStream.getAudioTracks().forEach(track => track.enabled = false);
    //                             }

    //                         }, 200)


    //                         const localVideo = this.localVideo;
    //                         if (localVideo) {
    //                             localVideo.srcObject = stream;
    //                             localVideo.autoplay = true;
    //                             localVideo.playsInline = true;
    //                             localVideo.muted = true;
    //                         }
    //                         const previewVideo = this.previewVideo;
    //                         if (previewVideo) {
    //                             previewVideo.srcObject = stream;
    //                             previewVideo.autoplay = true;
    //                             previewVideo.playsInline = true;
    //                             previewVideo.muted = true;
    //                         }





    //                         resolve(stream);
    //                     }
    //                 ).catch((err) => { console.log(err) });
    //         }




    //     })
    // }

    getStream = (reopen = false) => {
        if (reopen) {
            try {
                if (this.audioVideoStream) {
                    if (this.localVideo) {
                        this.localVideo.srcObject = null;
                    }

                    this.audioVideoStream.getAudioTracks().forEach(track => track.stop());
                    this.audioVideoStream.getVideoTracks().forEach(track => track.stop());


                    for (let i = 0; i < this.tmpStreams.length; i++) {
                        try {
                            if (this.tmpStreams[i]) {
                                this.tmpStreams[i].getTracks().forEach(track => track.stop());
                                this.tmpStreams[i] = null;
                            }
                        } catch (e) { }
                    }
                    this.audioVideoStream = null;

                }
            } catch (e) {
                console.log(e);
            }
        }

        return new Promise((resolve, reject) => {
            if (this.audioVideoStream) {
                resolve(this.audioVideoStream);
            } else {

                try {
                    if (!this.audioContext) {
                        window.AudioContext = window.AudioContext || window.webkitAudioContext;
                        this.audioContext = new AudioContext();
                    }
                }
                catch (e) {
                    alert('Web Audio API not supported.');
                }

                if (this.audioVideoStream) {


                    if (this.previewVideo) {
                        this.previewVideo.srcObject = null;
                    }

                    //this.audioVideoStream.getTracks().forEach(track => track.stop());
                    if (this.soundMeter)
                        this.soundMeter.stop();
                    if (this.meterRefresh) {
                        clearInterval(this.meterRefresh);
                    }

                    // this.audioVideoStream.getAudioTracks()[0].stop();
                    // this.audioVideoStream.getVideoTracks()[0].stop();
                    this.audioVideoStream.getTracks().forEach(track => track.stop());


                    for (let i = 0; i < this.tmpStreams.length; i++) {
                        try {
                            if (this.tmpStreams[i]) {
                                this.tmpStreams[i].getTracks().forEach(track => track.stop());
                                this.tmpStreams[i] = null;
                            }
                        } catch (e) { }
                    }

                }

                if (this.state.shareOn && this.state.micOff && this.state.cameraOff) {
                    navigator.mediaDevices.getDisplayMedia().then((stream) => {


                        this.screenStream = stream;

                        this.tmpStreams.push(stream);

                        this.audioVideoStream = stream;

                        VideoGateway.replaceStream(this.audioVideoStream);


                        const localVideo = this.localVideo;
                        if (localVideo) {
                            localVideo.srcObject = stream;
                            localVideo.autoplay = true;
                            localVideo.playsInline = true;
                            localVideo.muted = true;
                        }
                        const previewVideo = this.previewVideo;
                        if (previewVideo) {
                            previewVideo.srcObject = stream;
                            previewVideo.autoplay = true;
                            previewVideo.playsInline = true;
                            previewVideo.muted = true;
                        }




                        resolve(stream);


                    })

                    return;
                }

                let resolutionDict = {
                    '480': {
                        width: 640,
                        height: 480
                    },
                    '720': {
                        width: 1280,
                        height: 720
                    },
                    '1080': {
                        width: 1920,
                        height: 1080
                    },
                }
                if (!this.state.fullHDSupport) {
                    resolutionDict['1080'] = {
                        width: 1280,
                        height: 720
                    }
                }

                let resolution = resolutionDict[this.state.videoResolution];


                navigator.mediaDevices.getUserMedia(
                    { video: this.state.videoInputDevice ? { deviceId: this.state.videoInputDevice, width: { ideal: resolution.width }, height: { ideal: resolution.height } } : this.state.camera === true ? { facingMode: { exact: "environment" }, width: { ideal: resolution.width }, height: { ideal: resolution.height } } : this.state.camera === false ? { facingMode: "user", width: { ideal: resolution.width }, height: { ideal: resolution.height } } : { width: { ideal: resolution.width }, height: { ideal: resolution.height } }, audio: this.state.audioInputDevice ? { deviceId: this.state.audioInputDevice } : true }).then(
                        async stream => {


                            if (this.state.shareOn) {
                                await this.getScreenStream();
                            }

                            if (this.state.cameraOff) {
                                stream.getVideoTracks().forEach(track => track.enabled = false);

                            }

                            if (this.state.micOff) {
                                stream.getAudioTracks().forEach(track => track.enabled = false);
                            }

                            this.tmpStreams.push(stream);

                            this.audioVideoStream = stream;

                            if (this.state.micOff) {
                                this.audioVideoStream.getAudioTracks().forEach(track => track.enabled = false);
                            }


                            if (this.state.micOff) {
                                setTimeout(() => {
                                    if (this.state.micOff) {
                                        this.audioVideoStream.getAudioTracks().forEach(track => track.enabled = false);
                                    }

                                }, 100);
                            } else {
                                setTimeout(() => {
                                    if (!this.state.micOff) {
                                        this.audioVideoStream.getAudioTracks().forEach(track => track.enabled = true);
                                    }

                                }, 100);
                            }

                            VideoGateway.replaceStream(this.audioVideoStream);

                            if (this.state.micOff) {
                                this.audioVideoStream.getAudioTracks().forEach(track => track.enabled = false);
                            } else {
                                this.audioVideoStream.getAudioTracks().forEach(track => track.enabled = true);
                            }

                            setTimeout(() => {
                                if (this.state.micOff) {
                                    this.audioVideoStream.getAudioTracks().forEach(track => track.enabled = false);
                                } else {
                                    this.audioVideoStream.getAudioTracks().forEach(track => track.enabled = true);
                                }

                            }, 300);

                            const localVideo = this.localVideo;
                            if (localVideo) {
                                localVideo.srcObject = stream;
                                localVideo.autoplay = true;
                                localVideo.playsInline = true;
                                localVideo.muted = true;
                            }
                            const previewVideo = this.previewVideo;
                            if (previewVideo) {
                                previewVideo.srcObject = stream;
                                previewVideo.autoplay = true;
                                previewVideo.playsInline = true;
                                previewVideo.muted = true;
                            }

                            this.soundMeter = new SoundMeter(this.audioContext);
                            this.soundMeter.connectToSource(stream, (e) => {
                                if (e) {
                                    alert(e);
                                    return;
                                }
                                this.meterRefresh = setInterval(() => {
                                    this.setState({
                                        micMeterValue: this.soundMeter.instant.toFixed(2)
                                    })
                                }, 200);
                            });

                            resolve(stream);
                        }
                    ).catch((err) => { console.log(err); });
            }
        })
    }


    getScreenStream = async () => {
        if (this.screenStream) {
            return this.screenStream;
        } else {
            this.screenStream = await navigator.mediaDevices.getDisplayMedia();
            const localVideo = this.localVideo;
            if (localVideo) {
                localVideo.srcObject = this.screenStream;
                localVideo.autoplay = true;
                localVideo.playsInline = true;
                localVideo.muted = true;
            }
            return this.screenStream;

        }

    }

    shareScreenCallback = () => {
        return new Promise((resolve, reject) => {
            this.shareScreen(() => {
                resolve();
            })
        })
    }



    shareScreen = async (callback) => {

        this.setState({
            shareOn: !this.state.shareOn,
            screenSharing: !this.state.shareOn
        }, async () => {
            if (this.state.shareOn) {


                await this.getScreenStream();
                this.screenStream.getVideoTracks()[0].onended = () => {
                    this.shareScreen();

                }

                try {
                    if (screen.availWidth - window.innerWidth === 0 || !window.opener) {
                        this.setState({
                            resizeWindowAfter: true
                        })
                    } else {
                        window.resizeTo(200, window.innerHeight / 2);
                        // window.resizeTo(200, 640);

                        this.setState({
                            showScreenShareMode: true,
                            windowDimensions: {
                                width: 200,
                                height: window.innerHeight
                            }
                        })

                    }
                } catch (e) {
                    console.log(e);
                }

                try {
                    if (callback) {
                        callback();
                    }
                } catch (e) {
                    console.log(e);
                }
                VideoGateway.replaceVideoStream(this.screenStream, true);

                this.props.socket.emit('shareScreen', { conversation: this.state.conversation?._id, value: true });

            } else {

                if (this.state.windowDimensions) {
                    window.resizeTo(this.state.windowDimensions.width < 400 ? 800 : this.state.windowDimensions.width, this.state.windowDimensions.height < 600 ? 600 : this.state.windowDimensions.height);
                    this.setState({
                        windowDimensions: null
                    })
                }

                this.setState({
                    showScreenShareMode: false
                })
                await this.getStream(true);
                VideoGateway.replaceVideoStream(this.audioVideoStream, !this.state.cameraOff);


                if (this.screenStream) {
                    this.screenStream.getTracks().forEach(track => track.stop());
                    this.screenStream = null;
                }

                const localVideo = this.localVideo;
                if (localVideo) {
                    localVideo.srcObject = this.audioVideoStream;
                    localVideo.autoplay = true;
                    localVideo.playsInline = true;
                    localVideo.muted = true;
                }



                this.props.socket.emit('shareScreen', { conversation: this.state.conversation?._id, value: false });
                if (this.state.cameraOff) {
                    this.props.socket.emit('cameraStatus', { conversation: this.state.conversation?._id, value: false });
                }
            }
        })




    }
    addToReferralPreview = () => {
        if (this.state.conversation && this.state.conversation?._id) {
            let openedReferralPreview = this.state.openedReferralPreview;
            if (openedReferralPreview.indexOf(this.state.conversation?._id) == -1) {
                openedReferralPreview.push(this.state.conversation?._id);
                this.setState({ openedReferralPreview })
            }

        }
    }
    removeFromReferralPreview = () => {
        if (this.state.conversation && this.state.conversation?._id) {
            let openedReferralPreview = this.state.openedReferralPreview;
            let index = openedReferralPreview.indexOf(this.state.conversation?._id);

            if (index != -1) {
                openedReferralPreview.splice(index, 1);
                this.setState({ openedReferralPreview })
            }

        }
    }
    addToJorunalNotes = () => {
        if (this.state.conversation && this.state.conversation?._id) {
            let openedJournalNotes = this.state.openedJournalNotes;
            if (openedJournalNotes.indexOf(this.state.conversation?._id) == -1) {
                openedJournalNotes.push(this.state.conversation?._id);
                this.setState({ openedJournalNotes })
            }

        }
    }
    removeFromJorunalNotes = () => {
        if (this.state.conversation && this.state.conversation?._id) {
            let openedJournalNotes = this.state.openedJournalNotes;
            let index = openedJournalNotes.indexOf(this.state.conversation?._id);

            if (index != -1) {
                openedJournalNotes.splice(index, 1);
                this.setState({ openedJournalNotes })
            }

        }
    }


    getQuestionary = (data) => {


        fetch(API_ENDPOINT + '/data/chat-form-questionary/form-deleted/' + this.state.questionaryModal, {
            method: 'POST',
            headers: {
                'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage?.getItem('authToken')}` : null,
                'content-type': 'application/json'
            },
            body: JSON.stringify({ messageId: data })
        }).then(res => res.json()).then((result) => {
            // console.log(result)
            if (!result.error) {
                this.setState({
                    activeQuestionary: result,
                    sections: result.sections,
                    settedRef: false
                })

            } else {
                this.setState({
                    error: 'This form was deleted by doctor.'.translate(this.props.lang),
                    activeQuestionary: null,
                    questionaryModal: null
                })
            }

        })
    }

    flipCamera = () => {
        this.setState({
            camera: !this.state.camera
        }, async () => {
            if (this.state.camera) {
                this.setState({ videoInputDevice: null });
            }

            let stream = await this.getStream(true);

        })


    }

    toggleCamera = () => {
        this.setState({
            cameraOff: !this.state.cameraOff
        }, async () => {

            this.props.socket.emit('cameraStatus', { conversation: this.state.conversation?._id, value: !this.state.cameraOff })

            if (this.audioVideoStream) {
                this.audioVideoStream.getVideoTracks().forEach(track => track.enabled = !this.state.cameraOff);
            }
        })
    }

    toggleMic = () => {
        if (this.state.mutedByHost) {
            return;
        }


        this.setState({
            micOff: !this.state.micOff
        }, async () => {
            this.props.socket.emit('micStatus', { conversation: this.state.conversation?._id, value: !this.state.micOff })

            if (this.audioVideoStream) {
                this.audioVideoStream.getAudioTracks().forEach(track => track.enabled = !this.state.micOff);
            }
        })
    }

    muteParticipant = (value) => {
        this.setState({
            micOff: value
        }, async () => {
            this.props.socket.emit('micStatus', { conversation: this.state.conversation?._id, value: !this.state.micOff })

            if (this.audioVideoStream) {
                this.audioVideoStream.getAudioTracks().forEach(track => track.enabled = !this.state.micOff);
            }
        })
    }



    removePeer = (uuid) => {

        console.log('removePeer', uuid)

        if (this.peerStreams[uuid]) {

            if (this.remoteVideo && this.remoteVideo[uuid] && this.remoteVideo[uuid].srcObject) {
                this.remoteVideo[uuid].srcObject.getTracks().forEach(track => track.stop());
                this.remoteVideo[uuid].srcObject = null;

            }

            this.peerStreams[uuid] = null;


        }

        delete this.peerStreams[uuid];


        let videoElements = this.state.videoElements;
        if (videoElements.indexOf(uuid) !== -1)
            videoElements.splice(videoElements.indexOf(uuid), 1);

        this.setState({
            videoElements
        }, () => {
            this.generateLayout();

            setTimeout(() => {
                this.forceUpdate();
            }, 500)
        })
    }

    call = async () => {

        this.setState({
            call: true
        }, async () => {

            if (!this.logoutResetInterval) {
                this.logoutResetInterval = setInterval(() => {
                    this.props.resetLogoutInterval();
                }, 10000);
            }

            this.generateLayout()

            this.props.socket.emit('call', {
                conversation: this.state.conversation?._id,


                browser: browserInfo(),
            })

        })


    }



    hardReconnect = () => {
        console.log('hard reconnect')

        // this.props.socket.emit('hardReconnect', {
        //     conversation: this.state.conversation?._id,
        // })
    }



    endCall = (emit = true, disableClientCountCheck = false) => {
        if (this.props.uData && this.state.conversation && this.state.conversation?.members && this.state.conversation?.members[0] == this.props.uData?._id) {
            this.props.registerClinicGroupLogs({
                type: 'endCall',
                conversation: this.state.conversation?._id
            })
            if (!emit && this.state.call) {
                this.setState({
                    _hostEndCall: true
                }, () => {
                    this.updateParams('callEnded', 'callEnded')
                    // this.props[0].history.push('/call-ended/hostEndCall')
                })
            } else {
                try {
                    setTimeout(() => {
                        if (this.props[0]?.location?.pathname?.indexOf('video-call') !== -1)
                            window.open('', '_self').close();

                    }, 1000);
                } catch (e) {

                }


                if (window && window.top)
                    window.top.postMessage('end-video-call', '*')

            }
        } else {
            if (this.state.call) {
                this.setState({
                    _endCall: {}
                }, () => {
                    this.updateParams('callEnded', 'callEnded')
                    // this.props[0].history.push('/call-ended/endCall')
                })
            }
        }

        if (this.timerHandler)
            clearInterval(this.timerHandler);

        if (this.logoutResetInterval)
            clearInterval(this.logoutResetInterval);


        this.setState({
            startTime: null,
            offerData: null,
            hands: {},
            hand: false

        },
            () => {


                // if (this.audioIncomingCall) {
                //     this.audioIncomingCall.pause();
                //     this.audioIncomingCall = null;
                // }


                if (emit) {
                    this.props.socket.emit('endCall', {
                        conversation: this.state.conversation?._id,
                        disableClientCountCheck: disableClientCountCheck
                    })
                }



                for (var uuid in this.peerStreams) {
                    if (this.peerStreams[uuid]) {
                        if (this.remoteVideo && this.remoteVideo[uuid] && this.remoteVideo[uuid].srcObject) {
                            this.remoteVideo[uuid].srcObject.getTracks().forEach(track => track.stop());
                            this.remoteVideo[uuid].srcObject = null;
                        }

                        delete this.peerStreams[uuid];
                    }
                }

                if (this.audioVideoStream) {
                    if (this.localVideo) {
                        this.localVideo.srcObject = null;
                    }

                    this.audioVideoStream.getAudioTracks()[0].stop();
                    this.audioVideoStream.getVideoTracks()[0].stop();

                    this.audioVideoStream = null;

                    for (let i = 0; i < this.tmpStreams.length; i++) {
                        try {
                            if (this.tmpStreams[i]) {
                                this.tmpStreams[i].getTracks().forEach(track => track.stop());
                                this.tmpStreams[i] = null;
                            }
                        } catch (e) { }
                    }


                }


                this.setState({
                    videoElements: [],
                    call: null,
                    soundAllowed: null
                })

                try {
                    VideoGateway.clearState();
                } catch (e) {

                }


            })
    }

    answer = async () => {
        let initiator = this.state.offerData.initiator;
        // if (this.audioIncomingCall) {
        //     this.audioIncomingCall.pause();
        //     this.audioIncomingCall = null;
        // }


        this.setState({
            call: true,
            offerData: null
        }, async () => {
            if (!this.logoutResetInterval) {
                this.logoutResetInterval = setInterval(() => {
                    this.props.resetLogoutInterval();
                }, 10000);
            }



            this.props.socket.emit('answer', {
                conversation: this.state.conversation?._id,
                initiator: initiator,


                browser: browserInfo(),
            })
        });
    }


    configureStreamAndAnswer = (videoInputDevice, audioInputDevice, audioOutputDevice, cameraOff, micOff, shareScreenSource, videoResolution, fullHDSupport) => {

        let params = getSearchParams(this.props[0].location.search, {});

        let videoCallConfig = this.state.prepareVideoCall;

        this.setState({
            videoInputDevice,
            audioInputDevice,
            audioOutputDevice,
            shareScreenSource,
            cameraOff,
            micOff,
            prepareVideoCall: null,
            videoResolution,
            fullHDSupport,
            _devicesPrepared: videoCallConfig
        }, async () => {


            await this.getStream();

            if (this.state.shareScreenSource) {
                await this.shareScreenCallback();
            }




            if (!videoCallConfig.initiator && (this.state.conversation && !this.state.conversation?.activeCall)) {
                this.call();
            } else {
                this.setState({
                    call: true,
                    offerData: null,
                    peers: {},
                    videoElements: [],
                    hands: {},
                    hand: false,
                }, async () => {
                    if (!this.logoutResetInterval) {
                        this.logoutResetInterval = setInterval(() => {
                            this.props.resetLogoutInterval();
                        }, 10000);
                    }

                    this.generateLayout()

                    this.props.socket.emit('answer', {
                        conversation: videoCallConfig.conversation,
                        initiator: videoCallConfig.initiator,
                        initiatorRejoin: this.props.uData && this.state.conversation && this.state.conversation?.activeCall && this.state.conversation?.members[0] == this.props.uData?._id,
                        guestName: params.roomId ? this.state.guestName : null,
                        browser: browserInfo()
                    })

                    // if (this.props.uData && this.state.conversation && this.state.conversation?.activeCall && this.state.conversation?.members[0] == this.props.uData?._id) {
                    //     if (localStorage?.videoCallClients) {
                    //         this.setState({
                    //             videoCallClients: JSON.parse(localStorage?.videoCallClients)
                    //         })
                    //     }
                    // }


                });
            }
        })

    }


    reconnect = () => {
        for (var uuid in this.peerStreams) {
            if (this.peerStreams[uuid]) {
                if (this.remoteVideo && this.remoteVideo[uuid] && this.remoteVideo[uuid].srcObject) {
                    this.remoteVideo[uuid].srcObject.getTracks().forEach(track => track.stop());
                    this.remoteVideo[uuid].srcObject = null;
                }

                delete this.peerStreams[uuid];
            }
        }

        if (this.audioVideoStream) {
            if (this.localVideo) {
                this.localVideo.srcObject = null;
            }

            this.audioVideoStream.getAudioTracks()[0].stop();
            this.audioVideoStream.getVideoTracks()[0].stop();

            this.audioVideoStream = null;

            for (let i = 0; i < this.tmpStreams.length; i++) {
                try {
                    if (this.tmpStreams[i]) {
                        this.tmpStreams[i].getTracks().forEach(track => track.stop());
                        this.tmpStreams[i] = null;
                    }
                } catch (e) { }
            }


        }

        VideoGateway.clearState();

        this.setState({
            videoElements: [],
        }, () => {
            try {
                setTimeout(() => {
                    this.initVideoCall(this._initVideoCallData);
                }, 2000);
            } catch (e) {
                console.log(e)
            }
        })

    }

    initVideoCall = async (data) => {
        if (this.state._guestCallEnded || this.state._endCall) {
            return;
        }
        console.log('initVideoCall', data);
        this._initVideoCallData = data;
        let stream = await this.getStream();
        this.videoUID = data.uid;

        this.setState({
            videoGatewayLogs: []
        })



        VideoGateway.init(data.mediaServer, data.authToken, data.uid, stream, (consumer) => {
            console.log('consumer ----------------------', consumer)
            let videoElements = this.state.videoElements;


            if (consumer.status == 'failed' || consumer.status == 'closed') {
                this.removePeer(consumer.id);
                return;
            }

            if (consumer.status == 'connecting' || consumer.status == 'disconnected') {
                let connecting = this.state.connecting;
                connecting[consumer.id] = true;
                this.setState({
                    connecting
                })

                setTimeout(() => {

                    if (this.state.conversation && this.state.conversation._id) {
                        this.props.socket.emit('micStatus', { conversation: this.state.conversation?._id, value: !this.state.micOff })

                        if (!this.state.shareOn)
                            this.props.socket.emit('cameraStatus', { conversation: this.state.conversation?._id, value: !this.state.cameraOff })
                    }

                }, 1000);

            }


            if (consumer.status == 'connected') {
                let connecting = this.state.connecting;
                connecting[consumer.id] = null;
                this.setState({
                    connecting
                })
            }


            if (videoElements.length == 0) {
                if (!this.state.startTime) {
                    this.setState({
                        startTime: Math.floor(new Date().getTime() / 1000)
                    })
                }
            }

            console.log(this.state.videoElements)
            if (consumer._new && this.state.videoElements.indexOf(consumer.id) != -1) {
                console.log('replacing video element')


                this.peerStreams[consumer.id] = null;
                let videoElements = this.state.videoElements;
                videoElements.splice(this.state.videoElements.indexOf(consumer.id), 1);
                this.setState({
                    videoElements
                }, () => {
                    this.generateLayout(true);
                    let videoElements = this.state.videoElements;
                    videoElements.push(consumer.id);



                    this.setState({ videoElements }, () => {
                        this.generateLayout(true);
                    });
                })
            } else {
                if (videoElements.indexOf(consumer.id) == -1) {
                    console.log('not exists')
                    videoElements.push(consumer.id);
                    this.peerStreams[consumer.id] = null;
                    this.state.videoElements = videoElements;

                    let micStatus = this.state.micStatus;
                    let cameraStatus = this.state.cameraStatus;

                    micStatus[consumer.id] = true;
                    cameraStatus[consumer.id] = true;

                    this.setState({
                        videoElements,
                        cameraStatus,
                        micStatus,
                        _reconnecting: true
                    })
                }
            }

            if (consumer.track) {
                if (!this.peerStreams[consumer.id]) {
                    const newStream = new MediaStream();
                    this.peerStreams[consumer.id] = newStream;
                }



                console.warn('adding track', consumer.track.kind)
                this.peerStreams[consumer.id].addTrack(consumer.track);
                this.generateLayout(true);
            }


        }, (volumes) => {

            if (!volumes.length) {
                return;
            }



            if (this.state.videoElements && this.state.videoElements.length) {
                let oldVideoElements = [...this.state.videoElements];
                let newVideoElements = volumes.filter(item => item.id != this.videoUID).filter(item => oldVideoElements.indexOf(item.id) != -1).map(item => item.id);

                var isSame = (oldVideoElements.length == newVideoElements.length) && oldVideoElements.every(function (element, index) {
                    return element === newVideoElements[index];
                });

                if (!isSame) {
                    this.setState({
                        videoElements: newVideoElements
                    }, () => {
                        this.checkVideoElements();
                    })
                }
            }



            let speaking = JSON.parse(JSON.stringify(this.state.speaking));

            let check = false;
            for (var uid in speaking) {
                if (speaking[uid]) {
                    check = true;
                    break;
                }
            }

            let same = true;

            let idx = 0;
            let max = volumes[0].volume;


            for (let i = 0; i < volumes.length; i++) {

                if (speaking[volumes[i].id] != volumes[i].volume > -47) {
                    same = false;
                }


                speaking[volumes[i].id] = volumes[i].volume > -47;


                if (volumes[i].volume > max) {
                    max = volumes[i].volume;
                    idx = i;
                }

            }



            if (!same || this.state.activeClient != (!this.state.screenSharing && volumes[idx].volume > -47 ? volumes[idx].id : this.state.screenSharing && this.state.activeClient ? this.state.activeClient : this.state.activeClient ? this.state.activeClient : null)) {
                this.setState({
                    speaking: speaking,
                    activeClient: !this.state.screenSharing && volumes[idx].volume > -47 ? volumes[idx].id : this.state.screenSharing && this.state.activeClient ? this.state.activeClient : this.state.activeClient ? this.state.activeClient : null,
                }, this.generateLayout);
            }

        }, () => {
            console.warn('reconnect callback');

            // if (iosDevice())
            this.reconnect();
        }, this.state.shareScreenSource ? this.screenStream : null, {

            error: (log) => {
                console.error(log.message);
                log.id = uuidv4();
                let videoGatewayLogs = this.state.videoGatewayLogs;
                videoGatewayLogs.push(log);

                if (log) {

                    fetch(API_ENDPOINT + `/video/call/error/log/new`, {
                        method: "POST",
                        headers: {
                            "content-type": "application/json",
                        },
                        body: JSON.stringify({
                            timestamp: Date.now(),
                            conversationId: this.state.conversation?._id,
                            message: log?.message,
                            type: log?.type,
                            userId: log.data && log.data.client ? log.data.client : this.props.uData._id
                        })
                    })
                        .then((res) => res.json())
                        .then((result) => {
                            console.log(result);
                        });
                }
                this.setState({ videoGatewayLogs });

            },
            log: (log) => {
                console.warn(log);
            },
            warn: (log) => {
                console.warn(log);
            }

        }, () => {
            this.setState({
                videoGatewayLogs: []
            })
        });

    }
    checkIfPatientIsInWaitingRoom = () => {
        const isDoctor = typeof window !== 'undefined' && localStorage.profile !== 'patient' && this.props.uData?.userLevel === 20 ? true : false;
        if (!this.state.conversation || !isDoctor) {
            if (this.waitingRoomInterval) clearInterval(this.waitingRoomInterval)
            return;
        }
        let patientId = this.state.conversation?.users?.filter(item => item.patientInThisGroup)?.[0]?.uid;
        let data = {
            patientId: patientId,
            conversationId: this.state.conversation._id,
            groupId: this.props.selectedGroup
        }
        this.props.socket.emit('isPatientInWaitingRoom', data);
    }

    onCall = (data) => {
        let params = getSearchParams(this.props[0].location.search, {});

        if (this.props[0].match.params.conversation && params.roomId) {


            if (this.state._devicesPrepared && this.state._devicesPrepared.initiator) {
                if (this.state.call == true) {

                    this.setState({
                        call: true,
                    }, async () => {
                        if (!this.logoutResetInterval) {
                            this.logoutResetInterval = setInterval(() => {
                                this.props.resetLogoutInterval();
                            }, 10000);
                        }


                        this.props.socket.emit('answer', {
                            conversation: this.state._devicesPrepared.conversation,
                            initiator: this.state._devicesPrepared.initiator,
                            guestName: params.roomId ? this.state.guestName : null,


                            browser: browserInfo(),
                        })
                    });
                } else {

                    this.setState({
                        call: true,
                        offerData: null,
                        peers: {},
                        videoElements: [],
                        hands: {},
                        hand: false
                    }, async () => {
                        if (!this.logoutResetInterval) {
                            this.logoutResetInterval = setInterval(() => {
                                this.props.resetLogoutInterval();
                            }, 10000);
                        }


                        this.props.socket.emit('answer', {
                            conversation: this.state._devicesPrepared.conversation,
                            initiator: this.state._devicesPrepared.initiator,
                            guestName: params.roomId ? this.state.guestName : null,


                            browser: browserInfo(),

                        })
                    });
                }

            } else {
                this.setState({
                    prepareVideoCall: {
                        conversation: data.conversation,
                        initiator: data.initiator
                    }
                });
            }


        }

        let conversations = this.state.conversations;

        for (let i = 0; i < conversations.length; i++) {
            if (conversations[i]?._id == data.conversation) {
                if (this.props.uData && data.initiatorUid != this.props.uData?._id)
                    conversations[i].currentCall = data;
            }
        }

        this.setState({
            conversations
        })
    }
    onHostEndedCall = (data) => {
        if (data.conversation && this.state.conversation && String(data.conversation) === this.state.conversation?._id) {
            if (data.members && this.props.uData && data.members.findIndex(member => String(member) === String(this.props.uData?._id)) !== -1) {
                this.props.endCall()
            }
            if (this.state.prepareVideoCall) {
                let conversation = this.state.conversation
                conversation.currentCall = null
                this.setState({
                    hostEndedCall: true,
                    conversation
                }, () => {
                    this.updateParams('callEnded', 'callEnded')
                    // this.props[0].history.push('/call-ended/hostEndedCall')
                })
            }

        }


    }
    registerEvents = () => {
        this.props.socket?.on('isPatientInWaitingRoom', (data) => {
            if (data?.conversationId === this.state.conversation?._id && data?.patientId) {
                let waitingRoom = {
                    conversationId: data.conversationId,
                    patientId: data.patientId,
                    patientInWaitingRoom: data.patientInWaitingRoom,
                    patientHasActiveVideoCall: data.activeCall,
                    showInfoWaitingRoom: !data.activeCall && data.patientInWaitingRoom
                }
                this.setState({ waitingRoom })
            } else {
                if (this.state.waitingRoom) {
                    this.setState({ waitingRoom: null })
                }
            }

        })

        this.props.socket.on('RefreshConversations', ({ conversationId }) => {
            if (!conversationId) return;
            if (this.state.conversations && this.state.conversations.length && this.state.conversations.find(conv => String(conv?._id) === conversationId)) {
                this.setState({ conversationPage: 0 }, () => {
                    // console.log('EMIT_CONVERSATIONS 4');
                    this.emitConversations()
                });
            }

            if (this.state.conversation && (String(this.state.conversation?._id) === conversationId)) {
                this.setState({ conversation: null });
            }
        });

        this.props.socket.on('linkForNewMessage', async ({ conversationId, messageId, links }) => {
            if (!conversationId || !messageId || !links) return;
            if (this.state.conversation && (String(this.state.conversation?._id) === conversationId) && this.state.messages && this.state.messages.find(msg => String(msg?._id) === messageId)) {
                this.setState(prev => ({
                    ...prev,
                    messages: prev.messages.map(msg => String(msg?._id) === messageId ? { ...msg, links } : msg)
                }));
            } else {
                this.linkForMessageTimer = setTimeout(() => {
                    if (this.state.conversation && (String(this.state.conversation?._id) === conversationId) && this.state.messages && this.state.messages.find(msg => String(msg?._id) === messageId)) {
                        this.setState(prev => ({
                            ...prev,
                            messages: prev.messages.map(msg => String(msg?._id) === messageId ? { ...msg, links } : msg)
                        }));
                    }
                }, 1500);
            }
        });

        this.props.socket.on('mediaServerAuthToken', async (data) => {
            this.initVideoCall(data);

            if (data.muteAllParticipants) {
                this.setState({
                    oldMicOff: this.state.micOff,
                    mutedByHost: true
                }, () => {
                    this.muteParticipant(true);
                })
            }
        })

        this.props.socket.on('muteParticipant', (data) => {
            if (!data.value) {
                this.muteParticipant(this.state.oldMicOff);
                this.setState({
                    mutedByHost: data.value,
                    oldMicOff: false,
                })
            } else {
                let oldMicOff = this.state.micOff;
                this.muteParticipant(true);
                this.setState({
                    mutedByHost: data.value,
                    oldMicOff: oldMicOff,
                })

            }

        })


        this.props.socket.on('bankIdGenerateRedirectURL', (data) => {
            this.setState({
                _bankIdRedirectUrl: data.redirectUrl,
                bankIDkey: data.key,
                qrCodeLink: data.qrCodeLink
            })
        })
        this.props.socket.on('bankIdIdentificate', (data) => {
            this.setState({
                guestName: data.name
            })
        })



        this.props.socket.on('call', async (data) => this.onCall(data)
            //     {
            //     let params = getSearchParams(this.props[0].location.search, {});

            //     if (this.props[0].match.params.conversation && params.roomId) {


            //         if (this.state._devicesPrepared && this.state._devicesPrepared.initiator) {
            //             if (this.state.call == true) {

            //                 this.setState({
            //                     call: true,
            //                 }, async () => {
            //                     if (!this.logoutResetInterval) {
            //                         this.logoutResetInterval = setInterval(() => {
            //                             this.props.resetLogoutInterval();
            //                         }, 10000);
            //                     }


            //                     this.props.socket.emit('answer', {
            //                         conversation: this.state._devicesPrepared.conversation,
            //                         initiator: this.state._devicesPrepared.initiator,
            //                         guestName: params.roomId ? this.state.guestName : null,


            //                         browser: browserInfo(),
            //                     })
            //                 });
            //             } else {

            //                 this.setState({
            //                     call: true,
            //                     offerData: null,
            //                     peers: {},
            //                     videoElements: [],
            //                     hands: {},
            //                     hand: false
            //                 }, async () => {
            //                     if (!this.logoutResetInterval) {
            //                         this.logoutResetInterval = setInterval(() => {
            //                             this.props.resetLogoutInterval();
            //                         }, 10000);
            //                     }


            //                     this.props.socket.emit('answer', {
            //                         conversation: this.state._devicesPrepared.conversation,
            //                         initiator: this.state._devicesPrepared.initiator,
            //                         guestName: params.roomId ? this.state.guestName : null,


            //                         browser: browserInfo(),

            //                     })
            //                 });
            //             }

            //         } else {
            //             this.setState({
            //                 prepareVideoCall: {
            //                     conversation: data.conversation,
            //                     initiator: data.initiator
            //                 }
            //             });
            //         }


            //     }

            //     let conversations = this.state.conversations;

            //     for (let i = 0; i < conversations.length; i++) {
            //         if (conversations[i]?._id == data.conversation) {
            //             if (this.props.uData && data.initiatorUid != this.props.uData?._id)
            //                 conversations[i].currentCall = data;
            //         }
            //     }

            //     this.setState({
            //         conversations
            //     })
            // }
        )

        this.props.socket.on('endCall', async (data) => {
            if (this.state.conversation && this.state.conversation?._id == data.conversation) {

                this.endCall(false);
            }
        });


        this.props.socket.on('removePeers', async (data) => {
            console.log('remove peers', data);
            this.removePeer(data.uid);
        });


        this.props.socket.on('answer', async (data) => {

        });

        this.props.socket.on('softCloseMessage', ({ conversation, uid, message }) => {
            if (this.state.conversation && String(this.state.conversation?._id) === String(conversation)) {
                let messages = [...this.state.messages, ...message];
                let oldScrollHeight = 0;
                if (this.messagesRef) {
                    oldScrollHeight = this.messagesRef.scrollHeight;
                }

                this.setState({ messages: messages, loadingMessages: false }, () => {
                    if (this.messagesRef && this.state.page == 0) {
                        this.messagesRef.scrollTop = this.messagesRef.scrollHeight;
                    } else if (oldScrollHeight) {
                        this.messagesRef.scrollTop = this.messagesRef.scrollTop + (this.messagesRef.scrollHeight - oldScrollHeight);
                    }
                })
            }
        });

        this.props.socket.on('hardClosedConversation', ({ conversation, uid, message }) => {
            if (this.state.conversation && String(this.state.conversation?._id) === String(conversation)) {
                this.setState({ conversation: null });
            }
            if (this.state.conversations && this.state.conversations.find(conv => String(conv?._id === String(conversation)))) {
                // console.log('EMIT_CONVERSATIONS 5');
                this.emitConversations();
            }
        })

        this.props.socket.on('conversationTransferred', ({ conversationId }) => {
            if (this.props.uData && this.props.uData?.userLevel && ([1, 2].includes(this.props.uData?.userLevel) || (this.props.uData?.userLevel === 20 && typeof window !== 'undefined' && localStorage?.getItem?.('profile') && localStorage?.getItem('profile') === 'patient')) && ((this.state.conversation && this.state.conversation?._id && String(this.state.conversation?._id) === conversationId) || (this.state.conversations && this.state.conversations.find(convObj => String(convObj?._id) === conversationId)))) {
                // console.log('EMIT_CONVERSATIONS 6');
                this.emitConversations();
                if (this.state.conversation && this.state.conversation?._id && String(this.state.conversation?._id) === conversationId) {
                    this.setState({ _consultations: null }, () => {
                        this.props.socket.emit('messages', { conversation: this.state.conversation?._id, page: this.state.page, chatType: this.state.chatType })
                    })
                }
            }
        });

        this.props.socket.on('messageSuccessfullyReceivedOnBackend', ({ conversationId, checkId }) => {
            if (this.state.sendMessageCheckObj && this.state.sendMessageCheckObj.checkId && (this.state.sendMessageCheckObj.checkId === checkId)) {
                this.setState({
                    sendMessageCheckObj: null,
                    message: '',
                    document: null,
                    image: null,
                    video: null,
                    healthCareId: null
                })
            }
        })

        this.props.socket.on('conversations2', ({ conversations: data, total, addToExistingConversations, switchToHistory }) => {
            this.checkHistoryIndicator();

            let obj = {
                loadingConversations: null,
                totalNumberOfConversations: Number(total),
                searching: false
            }

            switchToHistory && (obj.conversationsType = 1);

            this.setState(obj);

            if (this.state.conversation && data.filter(item => item?._id == this.state.conversation?._id).length) {
                let conversation = data.filter(item => item?._id == this.state.conversation?._id)[0]
                this.setState({ conversation }, this.getReferralInfo)
            }

            let conversations = [];
            // Maybe now this check is not needed ?
            if (this.props.selectedClinic && typeof window != 'undefined' && localStorage?.getItem?.('profile') != 'patient') {
                for (let i = 0; i < data.length; i++) {
                    if (!data[i].clinic || (data[i].clinic && data[i].clinic == this.props.selectedClinic)) {
                        conversations.push(data[i])
                    }
                }
            } else {
                conversations = [...data]
            }

            if (addToExistingConversations) {
                this.setState(prev => ({ conversations: [...prev.conversations, ...conversations] }))
            } else {
                this.setState({ conversations });
            }

            if (!this.state.conversation && data.length) {

                let params = getSearchParams(this.props[0].location.search, {});

                if (this.props[0].match.params.windowConversation) {
                    let idx = 0;
                    for (let i = 0; i < data.length; i++) {
                        if (data[i]?._id == this.props[0].match.params.windowConversation) {
                            idx = i;
                            break;
                        }
                    }

                    //this.updateParams('conversation', null);
                    this.openConversation(data[idx], () => {
                    });
                    return;

                }

                if (this.props[0].match.params.conversation && params.roomId) {

                    let idx = 0;
                    for (let i = 0; i < data.length; i++) {
                        if (data[i]?._id == this.props[0].match.params.conversation) {
                            idx = i;
                            break;
                        }
                    }

                    this.updateParams('conversation', null);
                    this.openConversation(data[idx], () => {
                    });
                    return;

                }

                if (this.props[0].match.params.conversation && !params.initiator) {
                    let idx = 0;
                    for (let i = 0; i < data.length; i++) {
                        if (data[i]?._id == this.props[0].match.params.conversation) {
                            idx = i;
                            break;
                        }
                    }

                    this.updateParams('conversation', null);
                    this.updateParams('startVideoCall', null);
                    this.openConversation(data[idx], () => {
                        this.setState({
                            prepareVideoCall: {
                                conversation: data[idx]?._id,
                                initiator: null
                            }
                        });
                    });
                    return;
                }

                if (params.conversation) {
                    let idx = -1;
                    for (let i = 0; i < data.length; i++) {
                        if (data[i]?._id == params.conversation) {
                            idx = i;
                            break;
                        }
                    }
                    if (idx > -1) {
                        this.openConversation(data[idx]);
                    }

                    this.updateParams('conversation', null);
                    return;
                }

                if (this.props[0].match.params.conversation && params.initiator) {
                    let idx = -1;
                    for (let i = 0; i < data.length; i++) {
                        if (data[i]?._id == this.props[0].match.params.conversation) {
                            idx = i;
                            break;
                        }
                    }
                    if (idx > -1) {
                        this.openConversation(data[idx]);
                    }

                    //this.updateParams('initiator', null);
                    if (!this.state.call) {

                        // if (data[idx]?.bundleId) {
                        //     try {
                        //         window.location.replace(`${data[idx]?.bundleId}://?conversation=${data[idx]?._id}&initiator=${params?.initiator}&uid=${this.props.uData?._id}`);
                        //     } catch (err) { console.log(err) }
                        // }


                        this.setState({
                            prepareVideoCall: {
                                conversation: data[idx]?._id,
                                initiator: params.initiator
                            }
                        });
                    }





                } else {
                    //this.openConversation(data[0])
                }
            }
        });


        // this.props.socket.on('conversations', (data) => {
        //     this.checkHistoryIndicator()

        //     this.setState({ loadingConversations: null })
        //     if (this.state.conversation && data.filter(item => item?._id == this.state.conversation?._id).length) {
        //         let conversation = data.filter(item => item?._id == this.state.conversation?._id)[0]
        //         this.setState({ conversation }, this.getReferralInfo)
        //     }

        //     let conversations = [];
        //     if (this.props.selectedClinic && typeof window != 'undefined' && localStorage?.getItem?.('profile') != 'patient') {
        //         for (let i = 0; i < data.length; i++) {
        //             if (!data[i].clinic || (data[i].clinic && data[i].clinic == this.props.selectedClinic)) {
        //                 conversations.push(data[i])
        //             }
        //         }
        //     } else {
        //         conversations = [...data]
        //     }
        //     this.setState({ conversations: conversations });
        //     if (!this.state.conversation && data.length) {

        //         let params = getSearchParams(this.props[0].location.search, {});

        //         if (this.props[0].match.params.windowConversation) {
        //             let idx = 0;
        //             for (let i = 0; i < data.length; i++) {
        //                 if (data[i]?._id == this.props[0].match.params.windowConversation) {
        //                     idx = i;
        //                     break;
        //                 }
        //             }

        //             //this.updateParams('conversation', null);
        //             this.openConversation(data[idx], () => {
        //             });
        //             return;

        //         }

        //         if (this.props[0].match.params.conversation && params.roomId) {

        //             let idx = 0;
        //             for (let i = 0; i < data.length; i++) {
        //                 if (data[i]?._id == this.props[0].match.params.conversation) {
        //                     idx = i;
        //                     break;
        //                 }
        //             }

        //             this.updateParams('conversation', null);
        //             this.openConversation(data[idx], () => {
        //             });
        //             return;

        //         }

        //         if (this.props[0].match.params.conversation && !params.initiator) {
        //             let idx = 0;
        //             for (let i = 0; i < data.length; i++) {
        //                 if (data[i]?._id == this.props[0].match.params.conversation) {
        //                     idx = i;
        //                     break;
        //                 }
        //             }

        //             this.updateParams('conversation', null);
        //             this.updateParams('startVideoCall', null);
        //             this.openConversation(data[idx], () => {
        //                 this.setState({
        //                     prepareVideoCall: {
        //                         conversation: data[idx]?._id,
        //                         initiator: null
        //                     }
        //                 });
        //             });
        //             return;
        //         }

        //         if (params.conversation) {
        //             let idx = -1;
        //             for (let i = 0; i < data.length; i++) {
        //                 if (data[i]?._id == params.conversation) {
        //                     idx = i;
        //                     break;
        //                 }
        //             }
        //             if (idx > -1) {
        //                 this.openConversation(data[idx]);
        //             }

        //             this.updateParams('conversation', null);
        //             return;
        //         }

        //         if (this.props[0].match.params.conversation && params.initiator) {
        //             let idx = -1;
        //             for (let i = 0; i < data.length; i++) {
        //                 if (data[i]?._id == this.props[0].match.params.conversation) {
        //                     idx = i;
        //                     break;
        //                 }
        //             }
        //             if (idx > -1) {
        //                 this.openConversation(data[idx]);
        //             }

        //             //this.updateParams('initiator', null);
        //             if (!this.state.call) {
        //                 this.setState({
        //                     prepareVideoCall: {
        //                         conversation: data[idx]?._id,
        //                         initiator: params.initiator
        //                     }
        //                 });
        //             }





        //         } else {
        //             //this.openConversation(data[0])
        //         }
        //     }
        // })
        this.props.socket.on('historyIndicator', (data) => {
            let historyIndicator = false;
            if (data && data.historyIndicator) {
                historyIndicator = true;
            }

            if (this.state.historyIndicator != historyIndicator) {
                this.setState({ historyIndicator })
            }
        })




        this.props.socket.on('guestNames', (data) => {
            if (!this.state.call) {
                return;
            }

            let guestNames = this.state.guestNames;
            let guestsVerificationStatus = this.state.guestsVerificationStatus;

            if (this.state.conversation && this.state.conversation?._id && data.conversation == this.state.conversation?._id) {
                let conversation = this.state.conversation;
                for (var uid in data.guestNames) {

                    guestNames[uid] = data.guestNames[uid];
                    guestsVerificationStatus[uid] = data.guestsVerificationStatus ? data.guestsVerificationStatus[uid] : null;

                    let found = false;
                    for (let i = 0; i < conversation.users.length; i++) {
                        if (conversation.users[i].uid == uid) {
                            found = true;
                        }
                    }
                    if (!found) {
                        conversation.users.push({
                            uid: uid,
                            name: data.guestNames[uid],
                            bankIdVerified: data.guestsVerificationStatus ? data.guestsVerificationStatus[uid] : null
                        })
                    }
                }

                this.setState({
                    conversation,
                    guestNames,
                    guestsVerificationStatus
                })
            }


            if (this.fetchConversationsOnGuestNamesTimer) {
                clearTimeout(this.fetchConversationsOnGuestNamesTimer);
            }

            this.fetchConversationsOnGuestNamesTimer = setTimeout(() => {
                this.setState({ conversationPage: 0 }, () => {
                    // console.log('EMIT_CONVERSATIONS 7');
                    this.emitConversations()
                });
            }, 5000);
        })


        this.props.socket.on('closeConversation', (data) => {
            // console.log(data);
            // try {
            // let conversations = [];
            if (this.state.conversations && this.state.conversations.length > 0) {
                if (this.state.conversationsType === 0 && this.props.uData?.userLevel === 20 && (typeof window !== 'undefined' && localStorage?.getItem?.('profile') !== 'patient')) {
                    let conversations = this.state.conversations.filter(item => item?._id !== data.conversation);
                    this.setState({ conversations });
                }
                else if (this.state.conversationsType === 0 && ([1, 2].includes(this.props.uData?.userLevel) || (this.props.uData?.userLevel === 20 && (typeof window !== 'undefined' && localStorage?.getItem?.('profile') === 'patient'))) && !data.softCloseTimestamp) {
                    let conversations = this.state.conversations.filter(item => item?._id !== data.conversation);
                    this.setState({ conversations });
                }
            } else {
                this.setState({ conversationPage: 0 }, () => {
                    // console.log('EMIT_CONVERSATIONS 8');
                    this.emitConversations()
                });
            }



            if (this.state.conversation && this.state.conversation?._id && data.conversation == this.state.conversation?._id && !data.softCloseTimestamp) {
                let conversation = this.state.conversation;
                conversation.closed = true;
                this.setState({
                    conversation
                })
            }


            fetch(API_ENDPOINT + '/data/conversationRatings/check', {
                method: 'POST',
                headers: {
                    'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage?.getItem('authToken')}` : null,
                    'content-type': 'application/json'
                },
                body: JSON.stringify({ conversation: data.conversation })
            }).then(res => res.json()).then((result) => {
                if (!result) {
                    return;
                }
                if (!result.check && result.user == this.props.uData?._id) {
                    console.log('aaaaaaaaaaaaaaaaaaaaa');
                    this.setState({
                        rateConversationModal: {
                            conversation: data.conversation,
                            rating1: 0,
                            rating2: 0,
                            rating3: 0,
                            comment: ''
                        },
                    })

                } else if (result.check && result.user == this.props.uData?._id) {
                    console.log('peroooo');
                    // this.setState({
                    //     rateConversationInfoModal: true
                    // })
                }

            }).catch((err) => {

            })

            // this.props.socket.emit('conversations', { profile: typeof window != 'undefined' ? localStorage?.getItem?.('profile') : null, conversationsType: this.state.conversationsType, windowConversation: ((this.props[0].match.params && this.props[0].match.params.windowConversation) || (this.props[0].match.params && this.props[0].match.params.conversation)) });
            // this.emitConversations();

            // IF NEEDED EMIT THIS
            // this.setState({conversationPage: 0}, () => {this.emitConversations()});


            if (data.conversation && !data.softCloseTimestamp && this.state.conversation === data.conversation) {
                this.setState({
                    conversation: null
                })
            }

            // } catch (error) {
            //     console.log(error);

            // }
        })
        this.props.socket.on('openConversation', (data) => {
            if (this.state.conversation && data.conversation == this.state.conversation?._id) {
                let conversation = this.state.conversation;
                conversation.closed = false;
                if (conversation && conversation.referral && conversation.referral.status == 'closed') {
                    conversation.referral.status = 'in-progress';
                }
                this.setState({
                    conversation
                })
            }

            // this.props.socket.emit('conversations', { profile: typeof window != 'undefined' ? localStorage?.getItem?.('profile') : null, conversationsType: this.state.conversationsType, windowConversation: ((this.props[0].match.params && this.props[0].match.params.windowConversation) || (this.props[0].match.params && this.props[0].match.params.conversation)) });
            this.setState({ conversationPage: 0 }, () => {
                // console.log('EMIT_CONVERSATIONS 9');
                this.emitConversations()
            });
            // this.emitConversations();
        })
        this.props.socket.on('deleteMessage', (data) => {
            if (this.state.conversation && data.conversation == this.state.conversation?._id) {
                let conversation = this.state.conversation;
                this.setState({
                    conversation
                }, () => {
                })

            }
            // console.log('EMIT_CONVERSATIONS 10');
            // this.props.socket.emit('conversations', { profile: typeof window != 'undefined' ? localStorage?.getItem?.('profile') : null, conversationsType: this.state.conversationsType, windowConversation: ((this.props[0].match.params && this.props[0].match.params.windowConversation) || (this.props[0].match.params && this.props[0].match.params.conversation)) });
            this.emitConversations();
        })

        this.props.socket.on('deleteConversation', (data) => {
            if (this.state.conversation && data.conversation == this.state.conversation?._id) {
                this.setState({
                    conversation: null
                })
            }

            // this.props.socket.emit('conversations', { profile: typeof window != 'undefined' ? localStorage?.getItem?.('profile') : null, conversationsType: this.state.conversationsType, windowConversation: ((this.props[0].match.params && this.props[0].match.params.windowConversation) || (this.props[0].match.params && this.props[0].match.params.conversation)) });
            this.setState({ conversationPage: 0 }, () => {
                // console.log('EMIT_CONVERSATIONS 11');
                this.emitConversations()
            });
            // this.emitConversations();
        })


        this.props.socket.on('createConversation', (data) => {
            this.setState({
                _saving: null,

            }, () => {
                setTimeout(() => {
                    this.setState({
                        form: null
                    }, () => {
                        if (this.state.openInNewTab) {
                            window.open(`/chat/${data.conversation}`, 'Conversation'.translate(this.props.lang) + ' ' + data.conversation, "resizable=0,width=360,height=640");
                            this.setState({
                                openInNewTab: false
                            })
                        }
                    })
                }, 1000)
            })
            // this.props.socket.emit('conversations', { profile: typeof window != 'undefined' ? localStorage?.getItem?.('profile') : null, conversationsType: this.state.conversationsType, windowConversation: ((this.props[0].match.params && this.props[0].match.params.windowConversation) || (this.props[0].match.params && this.props[0].match.params.conversation)) });
            this.setState({ conversationPage: 0 }, () => {
                // console.log('EMIT_CONVERSATIONS 12');
                this.emitConversations()
            });
            // this.emitConversations();
        })




        this.props.socket.on('messages', (data) => {
            if (this.state.conversation && data.conversation == this.state.conversation?._id) {

                let messages = this.state.messages;

                if (this.state.page == 0) {
                    messages = [...data.messages];
                } else {
                    messages = [...data.messages, ...messages]
                }

                let oldScrollHeight = 0;
                if (this.messagesRef) {
                    oldScrollHeight = this.messagesRef.scrollHeight;
                }

                this.setState({ messages: messages, numberOfPatients: data.numberOfPatients, loadingMessages: false, _consultations: data._consultations }, () => {
                    if (this.messagesRef && this.state.page == 0) {
                        this.messagesRef.scrollTop = this.messagesRef.scrollHeight;
                    } else if (oldScrollHeight) {
                        this.messagesRef.scrollTop = this.messagesRef.scrollTop + (this.messagesRef.scrollHeight - oldScrollHeight);
                    }
                });


                if (this.state.conversation && this.state.conversation?.lastMessageObj) {
                    let lastMessageObj = { ...this.state.conversation?.lastMessageObj }
                    if (lastMessageObj.uid != this.props.uData?._id && (!lastMessageObj.seen || (lastMessageObj.seen && lastMessageObj.seen.filter(el => el.uid == this.props.uData?._id).length == 0))) {
                        // this.props.socket.emit('conversations', { profile: typeof window != 'undefined' ? localStorage?.getItem?.('profile') : null, conversationsType: this.state.conversationsType, windowConversation: ((this.props[0].match.params && this.props[0].match.params.windowConversation) || (this.props[0].match.params && this.props[0].match.params.conversation)) });
                        // console.log('EMIT_CONVERSATIONS 13');
                        // this.emitConversations(); // commented this line if the page number is greater than 0 and we open conversations with unseen messages, the conversations list becomes disordered.
                    }
                }
                this.props.socket.emit('triggerFetch', { conversation: this.state.conversation?._id })

            } else {
                this.setState({ loadingMessages: false })
            }

        })
        this.props.socket.on('errorConversationIsClosed', ({ error }) => {
            if (error) {
                this.setState({ error: error.translate(this.props.lang) });
            }
        })
        this.props.socket.on('newMessage', (data) => {
            // alert('test')
            // console.log(this.state.conversation?._id);

            if (this.state.conversation && data.conversation == this.state.conversation?._id) {
                // conversation sort 
                let conversations = [...this.state.conversations];
                for (let i = 0; i < conversations.length; i++) {
                    if (conversations[i]?._id == data.conversation) {
                        conversations[i].lastMessage = Math.floor(new Date().getTime() / 1000)
                    }
                }
                // console.log(this.state.conversations, 'pero')
                conversations.sort((a, b) => {
                    return (a.lastMessage == null) - (b.lastMessage == null) || -(a.lastMessage > b.lastMessage) || +(a.lastMessage < b.lastMessage);
                })
                // console.log(this.state.conversations)
                this.setState({
                    conversations
                }, () => this.forceUpdate())
                // conversation sort 

                if (!this.state.call || this.state.showChat) {
                    this.setState({
                        page: 0,
                        _consultations: null
                    }, () => {
                        this.props.socket.emit('messages', { conversation: this.state.conversation?._id, page: this.state.page, chatType: this.state.chatType })
                    })
                }

                if (this.state.call && !this.state.showChat && !this.state.videoCallNewMessage) {
                    if (!data.chatType) {
                        this.setState({ videoCallNewMessage: true });
                    }

                }

            } else {
                let conversations = [...this.state.conversations];
                for (let i = 0; i < conversations.length; i++) {
                    if (conversations[i]?._id == data.conversation) {
                        if (this.props.uData?._id != data.uid) {
                            if (data.chatType == 'team' && this.props.uData?._id != data.dontEmitToUser) {
                                conversations[i]._newMessage = true;
                                conversations[i]._newMessageTeam = true;
                            } else if (!data.chatType) {
                                conversations[i]._newMessage = true;
                                conversations[i]._newMessagePatient = true;
                            }

                        }

                        conversations[i].lastMessage = Math.floor(new Date().getTime() / 1000)
                    }
                }

                conversations.sort((a, b) => {
                    return (a.lastMessage == null) - (b.lastMessage == null) || -(a.lastMessage > b.lastMessage) || +(a.lastMessage < b.lastMessage);
                })


                this.setState({
                    conversations
                }, () => this.forceUpdate())
            }

            if (this.props.uData && this.props.uData?.userLevel == 20 && typeof window != 'undefined' && localStorage?.getItem?.('profile') != 'patient') {
                // this.props.socket.emit('conversations', { profile: typeof window != 'undefined' ? localStorage?.getItem?.('profile') : null, conversationsType: this.state.conversationsType, windowConversation: ((this.props[0].match.params && this.props[0].match.params.windowConversation) || (this.props[0].match.params && this.props[0].match.params.conversation)) });
                // this.emitConversations();

                // this.setState({conversationPage: 0}, () => {this.emitConversations()});

            }

            if (!this.state.conversation) {
                this.setState({ conversationPage: 0 }, () => {
                    // console.log('EMIT_CONVERSATIONS 14');
                    this.emitConversations()
                });
            }

        })
        this.props.socket.on('setTranslationLanguage', (data) => {
            if (this.state.conversation && data.conversation == this.state.conversation?._id) {
                let conversation = this.state.conversation;
                conversation.memberLanguages = data.memberLanguages;
                this.setState({
                    conversation
                })

                this.setState({
                    page: 0,
                    _consultations: null
                }, () => {
                    this.props.socket.emit('messages', { conversation: this.state.conversation?._id, page: this.state.page, chatType: this.state.chatType })
                })
            }
        })



        this.props.socket.on('seenUpdate', (data) => {
            console.log('seenUpdate')
            if (this.state.conversation && data.conversation == this.state.conversation?._id) {
                if (this.state.messages) {
                    let messages = this.state.messages;
                    for (let i = 0; i < messages.length; i++) {
                        if (messages[i]?._id == data.message) {


                            if (this.state.chatType == 'team' && data.patientUsersArray.length) {
                                messages[i].seen = data.seen;
                                for (let j = 0; j < data.patientUsersArray.length; j++) {
                                    let index = messages[i].seen.findIndex(member => member == data.patientUsersArray[j])
                                    if (messages[i].seen[index]) {
                                        messages[i].seen.splice(index, 1)
                                    }
                                }


                            } else {
                                messages[i].seen = data.seen;

                            }
                        }
                    }
                    this.setState({
                        messages
                    })
                }

            }
        })


        // this.props.socket.on('searchMembers', (data) => {
        //     if (data && this.state.conversation && data.conversation == this.state.conversation?._id) {
        //         if (this.state.searchMembersText == data.search) {
        //             this.setState({
        //                 searchUsers: data.users,
        //                 loadingSearchUsers: false
        //             })
        //         } else if (!this.state.searchMembersText) {
        //             this.setState({ loadingSearchUsers: false })
        //         }

        //     }
        // })
        this.props.socket.on('addMember', (data) => {
            if (data?.conversation && data.error) {
                this.setState({ errorTooManyPatients: data.error })
                return;
            }
            this.setState({ searching: true });
            if (this.state.conversation && data.conversation == this.state.conversation?._id) {
                let conversation = this.state.conversation;
                conversation.members = data.members;
                conversation.users = data.users;
                conversation.patientId = data.patientId;
                conversation.patient2Id = data.patient2Id;
                conversation.childId = data.childId;
                //console.log(conversation.users)
                this.setState({
                    conversation: conversation,
                    numberOfPatients: data.numberOfPatients

                }, this.forceUpdate)
            }
            // this.props.socket.emit('conversations', { profile: typeof window != 'undefined' ? localStorage?.getItem?.('profile') : null, conversationsType: this.state.conversationsType, windowConversation: ((this.props[0].match.params && this.props[0].match.params.windowConversation) || (this.props[0].match.params && this.props[0].match.params.conversation)) });
            this.setState({ conversationPage: 0 }, () => {
                // console.log('EMIT_CONVERSATIONS 15');
                this.emitConversations()
            });
            // this.emitConversations();
        })


        this.props.socket.on('removeMember', (data) => {
            this.setState({ searching: true });
            if (this.state.conversation && data.conversation == this.state.conversation?._id) {
                let conversation = this.state.conversation;
                conversation.members = data.members;
                conversation.users = data.users;

                this.setState({
                    conversation: conversation,
                    numberOfPatients: data.numberOfPatients

                }, this.forceUpdate)
            }
            // this.props.socket.emit('conversations', { profile: typeof window != 'undefined' ? localStorage?.getItem?.('profile') : null, conversationsType: this.state.conversationsType, windowConversation: ((this.props[0].match.params && this.props[0].match.params.windowConversation) || (this.props[0].match.params && this.props[0].match.params.conversation)) });
            this.setState({ conversationPage: 0 }, () => {
                // console.log('EMIT_CONVERSATIONS 16');
                this.emitConversations()
            });
            // this.emitConversations();
        })
        this.props.socket.on('removeInvitedContacts', (data) => {
            if (this.state.conversation && data.conversation == this.state.conversation?._id) {
                let conversation = this.state.conversation;
                conversation.invitedContacts = data.invitedContacts;
                if (this.state.sentInvintations) {
                    this.setState({ sentInvintations: data.invitedContacts })
                }
                this.setState({
                    conversation: conversation,

                }, this.forceUpdate)
            }
            // this.props.socket.emit('conversations', { profile: typeof window != 'undefined' ? localStorage?.getItem?.('profile') : null, conversationsType: this.state.conversationsType, windowConversation: ((this.props[0].match.params && this.props[0].match.params.windowConversation) || (this.props[0].match.params && this.props[0].match.params.conversation)) });
            // console.log('EMIT_CONVERSATIONS 17');
            this.emitConversations();
        })

        this.props.socket.on('updateJournalNote', (data) => {
            if (this.state.conversation && data.conversation == this.state.conversation?._id) {

                let conversation = this.state.conversation;
                conversation.members = data.members;
                conversation.users = data.users;
                conversation.journalNote = data.journalNote;

                if (data && data.exportToJournalSystem && data.exportToJournalSystem == this.props.uData?._id) {

                    if (!this.updateJournalNoteDict[data.uuid]) {
                        this.updateJournalNoteDict[data.uuid] = true;
                        this.exportToJournalSystem(data.uuid)
                    }
                }

                this.setState({
                    conversation: conversation,
                    numberOfPatients: data.numberOfPatients,
                }, this.forceUpdate)
            } else if (this.state.conversation && this.state.openedJournalNotes.filter(item => item == this.state.conversation?._id).length > 0) {
                //console.log('sssss')
                this.setState({ journalNote: null }, () => {
                    this.findHealthCareFormular()
                })

            }

            let conversations = this.state.conversations;
            if (conversations && data && data.conversation && conversations.length && conversations.filter(el => el?._id == data.conversation).length) {
                let index = conversations.findIndex(el => el?._id == data.conversation);
                if (index > -1) {
                    conversations[index].journalNote = data.journalNote;
                    this.setState({ conversations })
                }
            }
            // THIS WAS COMENTED BEFORE CONVERSATIONS 2 CHANGES
            // this.props.socket.emit('conversations', { profile: typeof window != 'undefined' ? localStorage?.getItem?.('profile') : null, conversationsType: this.state.conversationsType, windowConversation: ((this.props[0].match.params && this.props[0].match.params.windowConversation) || (this.props[0].match.params && this.props[0].match.params.conversation)) });
        })


        this.props.socket.on('raiseHand', (data) => {
            if (this.state.call && this.state.conversation && data.conversation == this.state.conversation?._id) {
                let hands = this.state.hands;
                hands[data.uid] = true;

                this.setState({
                    //activeClient: data.uid,
                    activeClient: !this.state.screenSharing ? data.uid : this.state.screenSharing && this.state.activeClient ? this.state.activeClient : null,

                }, this.generateLayout);

                try {
                    // let raiseHandSound = new Audio(handSound);
                    // if (this.state.audioOutputDevice)
                    //     this.attachSinkId(raiseHandSound, this.state.audioOutputDevice);

                    // raiseHandSound.play();
                    this.playRaiseHandSound();
                } catch (e) {
                    console.error(e);
                }



                this.setState({
                    hands: hands
                })
            }
        });

        this.props.socket.on('shareScreen', (data) => {
            if (this.state.conversation && data.conversation == this.state.conversation?._id) {
                let cameraStatus = this.state.cameraStatus;

                if (data.value)
                    cameraStatus[data.uid] = true;

                if (data.value && data.uid) {
                    VideoGateway.changeLayer(data.uid, 1);
                }
                this.setState({
                    screenSharingUID: data.value ? data.uid : null,
                    screenSharing: data.value,
                    activeClient: data.uid,
                    cameraStatus
                }, () => {
                    this.generateLayout(true);
                })
            }
        });


        this.props.socket.on('handDown', (data) => {
            if (this.state.conversation && data.conversation == this.state.conversation?._id) {
                let hands = this.state.hands;
                hands[data.uid] = false;
                this.setState({
                    hands: hands
                })
            }
        });

        this.props.socket.on('callEnded', (data) => {
            if (this.state.conversation && data.conversation == this.state.conversation?._id) {
                let conversation = this.state.conversation;
                conversation.currentCall = null;

                this.setState({
                    conversation
                })
            }

            let conversations = this.state.conversations;

            for (let i = 0; i < conversations.length; i++) {
                if (conversations[i]?._id == data.conversation) {
                    conversations[i].currentCall = null;
                }
            }

            this.setState({
                conversations
            })


        });

        this.props.socket.on('hostEndedCall', (data) => this.onHostEndedCall(data)
            //     {
            //     if (data.conversation && this.state.conversation && String(data.conversation) === this.state.conversation?._id) {
            //         if (data.members && this.props.uData && data.members.findIndex(member => String(member) === String(this.props.uData?._id)) !== -1) {
            //             this.props.endCall()
            //         }
            //         if (this.state.prepareVideoCall) {
            //             let conversation = this.state.conversation
            //             conversation.currentCall = null
            //             this.setState({
            //                 hostEndedCall: true,
            //                 conversation
            //             }, () => {
            //                 this.updateParams('callEnded', 'callEnded')
            //                 // this.props[0].history.push('/call-ended/hostEndedCall')
            //             })
            //         }

            //     }


            // }
        );

        this.props.socket.on('rejoinData', (data) => {
            //console.log('rejoinData');
            if (this.state.conversation && data.conversation == this.state.conversation?._id) {
                let conversation = this.state.conversation;
                conversation.currentCall = data;

                this.setState({
                    conversation
                })
            }

            let conversations = this.state.conversations;

            for (let i = 0; i < conversations.length; i++) {
                if (conversations[i]?._id == data.conversation) {
                    conversations[i].currentCall = data;
                }
            }

            this.setState({
                conversations
            })

        })


        this.props.socket.on('answerCallback', (data) => {
            //alert('answerCallback')
            if (this.state.conversation && data.conversation == this.state.conversation?._id && this.state.conversation?.currentCall !== null) {
                let conversation = this.state.conversation;
                conversation.currentCall = null;

                this.setState({
                    conversation
                })
            }

            let conversations = this.state.conversations;
            let updateNeeded = false;
            for (let i = 0; i < conversations.length; i++) {
                if (conversations[i]?._id == data.conversation && conversations[i].currentCall !== null) {
                    conversations[i].currentCall = null;
                    updateNeeded = true;
                }
            }
            if (updateNeeded) {
                this.setState({
                    conversations
                })
            }
        })



        this.props.socket.on('cameraStatus', (data) => {
            if (this.state.conversation && data.conversation == this.state.conversation?._id) {
                let cameraStatus = this.state.cameraStatus;
                cameraStatus[data.uid] = data.value;
                this.setState({
                    cameraStatus: cameraStatus
                })
            }
        });
        this.props.socket.on('micStatus', (data) => {
            if (this.state.conversation && data.conversation == this.state.conversation?._id && this.state.micStatus && this.state.micStatus[data.uid] !== data.value) {
                let micStatus = this.state.micStatus;
                micStatus[data.uid] = data.value;
                this.setState({
                    micStatus: micStatus
                })
            }
        });



        this.props.socket.on('userTyping', (data) => {
            if (this.state.conversation && data.conversation == this.state.conversation?._id) {
                let check = false;
                if (this.state.conversation && this.state.conversation?.members && data.user && data.user.id && this.state.conversation?.members.filter(el => el == data.user.id).length) {
                    check = true;
                }
                // console.log(data);
                if (check) {
                    if (data.chatType && data.chatType == this.state.chatType) {
                        this.setState({
                            userTyping: data.user,
                            userTypingConversation: data.conversation
                        })
                    } else if (!data.chatType) {
                        this.setState({
                            userTyping: data.user,
                            userTypingConversation: data.conversation
                        })
                    }

                }

            }
        });
        this.props.socket.on('userTyping2', (data) => {
            if (this.state.conversation && data.conversation == this.state.conversation?._id) {
                let check = false;
                if (this.state.conversation && this.state.conversation?.members && data.user && data.user.id && this.state.conversation?.members.filter(el => el == data.user.id).length) {
                    check = true;
                }
                if (check) {
                    let userTyping = this.state.userTyping;
                    if (!userTyping) { userTyping = {} }
                    if (!userTyping[data.conversation]) userTyping[data.conversation] = {};
                    if (!userTyping[data.conversation][data.chatType]) userTyping[data.conversation][data.chatType] = {};

                    userTyping[data.conversation][data.chatType] = { ...userTyping[data.conversation][data.chatType], ...data.user }
                    this.setState({ userTyping, userTypingConversation: data.conversation })

                }

            }
        });
        this.props.socket.on('userEndTyping2', (data) => {
            if (this.state.conversation && data.conversation == this.state.conversation?._id && data.chatType) {
                let userTyping = this.state.userTyping;
                if (userTyping && userTyping?.[data.conversation] && userTyping?.[data.conversation]) {
                    delete userTyping[data.conversation];

                    this.setState({
                        userTyping,
                        userTypingConversation: null
                    })
                }

            }
        });

        this.props.socket.on('userEndTyping', (data) => {
            if (this.state.conversation && data.conversation == this.state.conversation?._id) {
                this.setState({
                    userTyping: null,
                    userTypingConversation: null
                })
            }
        });


        this.props.socket.on('userOnline', (data) => {
            if (this.state.conversation) {
                let conversation = this.state.conversation;
                if (conversation.users) {
                    for (let i = 0; i < conversation.users.length; i++) {
                        if (conversation.users[i].uid == data.uid) {
                            conversation.users[i].online = true;
                        }
                    }
                }
                this.setState({
                    conversation
                })
            }

            if (this.state.conversations) {
                let newConversations = []
                for (let i = 0; i < this.state.conversations.length; i++) {
                    let conversation = this.state.conversations[i]
                    if (conversation.users) {
                        for (let i = 0; i < conversation.users.length; i++) {
                            if (conversation.users[i].uid == data.uid) {
                                conversation.users[i].online = true;
                            }
                        }
                    }

                    newConversations.push(conversation)
                }

                this.setState({
                    conversations: newConversations
                })
            }
        });
        this.props.socket.on('userOffline', (data) => {
            if (this.state.conversation) {
                let conversation = this.state.conversation;
                if (conversation.users) {
                    for (let i = 0; i < conversation.users.length; i++) {
                        if (conversation.users[i].uid == data.uid) {
                            conversation.users[i].online = false;
                        }
                    }
                }
                this.setState({
                    conversation
                })
            }

            if (this.state.conversations) {
                let newConversations = []
                for (let i = 0; i < this.state.conversations.length; i++) {
                    let conversation = this.state.conversations[i]
                    if (conversation.users) {
                        for (let i = 0; i < conversation.users.length; i++) {
                            if (conversation.users[i].uid == data.uid) {
                                conversation.users[i].online = false;
                            }
                        }
                    }

                    newConversations.push(conversation)
                }

                this.setState({
                    conversations: newConversations
                })
            }
        });

        this.props.socket.on('videoCallLimit', (data) => {
            if (this.state.conversation && this.state.conversation?._id == data.conversation) {
                let videoCallClients = this.state.videoCallClients;
                for (let i = 0; i < this.state.videoCallClients.length; i++) {
                    if (this.state.videoCallClients[i].socketId == data.socketId) {
                        this.state.videoCallClients[i].allowed = false;
                    }
                }

                this.setState({
                    videoCallLimit: true,
                    videoCallClients: videoCallClients
                }, () => {
                    // localStorage?.setItem('videoCallClients', JSON.stringify(this.state.videoCallClients));
                })
            }
        });

        this.props.socket.on('newClientConnected', (data) => {
            if (this.state.conversation && this.state.conversation?._id == data.conversation) {
                let videoCallClients = this.state.videoCallClients;

                let found = false;
                for (let i = 0; i < videoCallClients.length; i++) {
                    if (videoCallClients[i].uid == data.uid) {
                        videoCallClients[i] = data;
                        videoCallClients[i].allowed = false;
                        found = true;
                    }
                }

                if (!found)
                    videoCallClients.push(data);

                this.setState({
                    videoCallClients: videoCallClients,
                    newClientConnectedNotification: true
                }, () => {
                    // localStorage?.setItem('videoCallClients', JSON.stringify(this.state.videoCallClients));

                    if (this.startNewClientAnimationInterval) {
                        clearInterval(this.startNewClientAnimationInterval);
                    }


                    let _notificationSound = new Audio(notificationSound);

                    _notificationSound.play();


                    this.startNewClientAnimationInterval = setInterval(() => {
                        this.setState({
                            startNewClientAnimationColorChange: !this.state.startNewClientAnimationColorChange
                        })
                    }, 500);

                })
            }
        });

        this.props.socket.on('updateConnectedClient', (data) => {
            if (this.state.conversation && this.state.conversation?._id == data.conversation) {
                let videoCallClients = this.state.videoCallClients;
                for (let i = 0; i < this.state.videoCallClients.length; i++) {
                    if (this.state.videoCallClients[i].socketId == data.oldSocketId) {
                        this.state.videoCallClients[i].socketId = data.socketId;
                    }
                }

                this.setState({
                    videoCallClients: videoCallClients
                }, () => {
                    // localStorage?.setItem('videoCallClients', JSON.stringify(this.state.videoCallClients));
                })
            }
        });

        this.props.socket.on('removeConnectedClient', (data) => {
            if (this.state.conversation && this.state.conversation?._id == data.conversation) {
                let index = -1;
                let videoCallClients = this.state.videoCallClients;
                for (let i = 0; i < this.state.videoCallClients.length; i++) {
                    if (this.state.videoCallClients[i].socketId == data.socketId) {
                        index = i;
                    }
                }

                if (index != -1) {
                    videoCallClients.splice(index, 1);
                }

                this.setState({
                    videoCallClients: videoCallClients
                }, () => {
                    // localStorage?.setItem('videoCallClients', JSON.stringify(this.state.videoCallClients));
                })
            }
        });


        this.props.socket.on('removeClient', (data) => {
            if (this.state.conversation && data.conversation == this.state.conversation?._id) {
                this.endCall(true, true);
            }
        })

        this.props.socket.on('statementEvent', (data) => {
            if (this.state.conversation)
                this.checkStatement()
        })



        this.props.socket.on('connect', this.socketConnect);


    }

    socketConnect = () => {

        let params = getSearchParams(this.props[0].location.search, {});
        if (params.roomId && !this.state.call) {
            this.props.socket.emit('guestConnected', {});
        }
    }

    orientationChange = () => {
        if (iosDevice()) {
            if (this.callArea) {
                setTimeout(() => {
                    this.callArea.style.width = '100vw';
                    this.callArea.style.height = '100vh';
                }, 500);
            }
        }
    }


    resizeWindow = () => {
        this.generateLayout();


        var maxHeight = window.screen.height,
            maxWidth = window.screen.width,
            curHeight = window.innerHeight,
            curWidth = window.innerWidth;

        let maximised = screen.availWidth - window.innerWidth === 0;
        if (this.state.shareOn && this.state.resizeWindowAfter && !maximised && window.opener) {
            try {

                window.resizeTo(200, window.innerHeight / 2);
                // window.resizeTo(200, 640);

                this.setState({
                    resizeWindowAfter: false,
                    showScreenShareMode: true,
                    windowDimensions: {
                        width: 200,
                        height: window.innerHeight
                    }
                })


            } catch (e) {
                console.log(e);
            }




        } else {

            if (this.state.showScreenShareMode) {
                if (curWidth > 200) {
                    this.setState({
                        showScreenShareMode: false,
                        windowDimensions: null
                    })
                }
            }

            if (!this.state.showScreenShareMode && this.state.shareOn && curWidth < 300) {
                this.setState({
                    showScreenShareMode: true,
                    windowDimensions: {
                        width: curWidth,
                        height: curHeight
                    }
                }, () => this.generateLayout(true))
            }
        }


        if (window.webkitCurrentFullScreenElement !== 'undefined') {
            if (window.document && window.document.webkitCurrentFullScreenElement) {
                this.setState({
                    fullScreen: true
                }, () => this.generateLayout(true))

            } else {
                this.setState({
                    fullScreen: false
                }, () => this.generateLayout(true))

            }
        } else {
            if (maxWidth == curWidth && maxHeight == curHeight) {
                this.setState({ fullScreen: true }, () => this.generateLayout(true))
                // do something
            } else {
                this.setState({ fullScreen: false }, () => this.generateLayout(true));
            }
        }

    }

    componentWillUnmount() {
        document.body.style.overflowX = 'auto';

        if (this.checkMessageTimer) clearTimeout(this.checkMessageTimer);
        if (this.linkForMessageTimer) clearTimeout(this.linkForMessageTimer);

        if (this.waitingRoomInterval) clearInterval(this.waitingRoomInterval)

        if (this.logoutResetInterval) {
            clearInterval(this.logoutResetInterval);
        }

        window.removeEventListener('resize', this.resizeWindow);

        window.removeEventListener('visibilitychange', this.checkTabFocused);
        window.removeEventListener('mousedown', this.handleClickOutsideSendMessageContainer);
        window.removeEventListener('mousedown', this.handleClickOutsideSendMessageVideoContainer);

        if (this.props.socket) {
            this.props.socket.removeListener('hardClosedConversation');
            this.props.socket.removeListener('RefreshConversations');
            this.props.socket.removeListener('messageSuccessfullyReceivedOnBackend');
            this.props.socket.removeListener('linkForNewMessage');
            this.props.socket.removeListener('bankIdGenerateRedirectURL');
            this.props.socket.removeListener('bankIdIdentificate');
            this.props.socket.removeListener('initPeer');
            this.props.socket.removeListener('endCall');
            this.props.socket.removeListener('answer');
            this.props.socket.removeListener('softCloseMessage')
            this.props.socket.removeListener('conversations2');
            this.props.socket.removeListener('conversations');
            this.props.socket.removeListener('messages');
            this.props.socket.removeListener('newMessage');
            this.props.socket.removeListener('setTranslationLanguage');
            this.props.socket.removeListener('seenUpdate');
            // this.props.socket.removeListener('searchMembers');
            this.props.socket.removeListener('raiseHand');
            this.props.socket.removeListener('handDown');
            this.props.socket.removeListener('userTyping');
            this.props.socket.removeListener('userEndTyping');
            this.props.socket.removeListener('userTyping2');
            this.props.socket.removeListener('userEndTyping2');
            this.props.socket.removeListener('userOnline');
            this.props.socket.removeListener('userOffline');
            this.props.socket.removeListener('createConversation');
            this.props.socket.removeListener('removePeers');
            this.props.socket.removeListener('shareScreen');
            this.props.socket.removeListener('guestNames');
            this.props.socket.removeListener('cameraStatus');
            this.props.socket.removeListener('micStatus');
            this.props.socket.removeListener('callEnded');
            // this.props.socket.removeListener('hostEndedCall');
            this.props.socket.removeListener('hostEndedCall', this.onHostEndedCall)
            this.props.socket.removeListener('videoCallLimit');
            this.props.socket.removeListener('newClientConnected');
            this.props.socket.removeListener('removeClient');
            this.props.socket.removeListener('updateConnectedClient');
            this.props.socket.removeListener('removeConnectedClient');
            this.props.socket.removeListener('answerCallback');
            this.props.socket.removeListener('rejoinData');
            this.props.socket.removeListener('removeInvitedContacts');
            this.props.socket.removeListener('removeMember');
            this.props.socket.removeListener('addMember');
            this.props.socket.removeListener('historyIndicator');
            this.props.socket.removeListener('errorConversationIsClosed');
            this.props.socket.removeListener('updateJournalNote');

            this.props.socket.removeListener('mediaServerAuthToken');
            this.props.socket.removeListener('muteParticipant');
            // this.props.socket.removeListener('call');
            this.props.socket.removeListener('call', this.onCall)
            this.props.socket.removeListener('conversationTransferred');
            this.props.socket.removeListener('closeConversation');
            this.props.socket.removeListener('openConversation');
            this.props.socket.removeListener('deleteMessage');
            this.props.socket.removeListener('deleteConversation');
            this.props.socket.removeListener('statementEvent');
            this.props.socket.removeListener('isPatientInWaitingRoom');





            this.props.socket.removeListener('connect', this.socketConnect);

            //this.props.socket.disconnect();
        }

        if (this.videoCallConnectionInterval) {
            clearInterval(this.videoCallConnectionInterval);
        }

        document.removeEventListener('mousedown', this.handleClickOutside);
    }

    calculateKbps = () => {
        let ts = new Date().getTime();
        let contentLength = 5245329;
        let image = new Image();
        image.onload = () => {
            let duration = (new Date().getTime() - ts) / 1000;
            let kB = contentLength / 1024;
            console.log('duration: ', duration);
            console.log('kB: ', kB);
            console.log('kbps: ', (kB * 8) / duration);
            console.log('mbps: ', ((kB / 1024) * 8) / duration);
            this.setState({
                kbps: (kB * 8) / duration
            })
        }

        image.src = `https://dashboard.curoflow.se/5mb.jpeg?nnn=${ts}`
    }


    componentDidUpdate(prevProps, prevState) {

        if (!this.state.call && this.logoutResetInterval) {
            clearInterval(this.logoutResetInterval);
            this.logoutResetInterval = null;
        }
        if (prevProps.dateFormat != this.props.dateFormat) {
            this.setState({
                shareLinkDate: this.props.getStringDateTs(Math.floor(new Date().getTime() / 1000), this.props.dateFormat)/*moment.unix(Math.floor(new Date().getTime() / 1000)).format(this.props.dateFormat)*/
            })
        }


        if (prevProps[0].location.search != this.props[0].location.search) {
            this.setState({
                loading: true
            }, () => {
                this.get();

            })

        }
        if ((prevProps.selectedClinic != this.props.selectedClinic && !prevProps.selectedClinic) || (prevProps.selectedGroup != this.props.selectedGroup && !prevProps.selectedGroup)) {
            this.get()
        }

        // if (this.props.socket && (prevProps.selectedClinic && prevProps.selectedClinic != this.props.selectedClinic || prevProps.selectedGroup != this.props.selectedGroup) && this.props.selectedClinic) {
        //     this.get()
        //     this.props.socket.emit('conversations', { profile: typeof window != 'undefined' ? localStorage?.getItem?.('profile') : null, conversationsType: this.state.conversationsType, windowConversation: ((this.props[0].match.params && this.props[0].match.params.windowConversation) || (this.props[0].match.params && this.props[0].match.params.conversation)) });
        // }

        if (this.props.socket && ((!prevProps.selectedGroup && this.props.selectedGroup) || (prevProps.selectedGroup && this.props.selectedGroup && prevProps.selectedGroup !== this.props.selectedGroup) || (!prevProps.selectedClinic && this.props.selectedClinic) || (prevProps.selectedClinic && this.props.selectedClinic && prevProps.selectedClinic !== this.props.selectedClinic))) {
            this.get()
            this.setState({ conversationPage: 0 }, () => {
                // console.log('EMIT_CONVERSATIONS 18');
                this.emitConversations();
            });
        }

        if (prevProps.selectedGroup != this.props.selectedGroup && !this.state.call) {
            this.setState({ conversation: null })
        }


        if (prevState.mainVideoElement != this.state.mainVideoElement) {
            if (this.state.mainVideoElement == 'local-video') {

                for (var uuid in this.peerStreams) {
                    if (this.state.screenSharingUID !== uuid)
                        VideoGateway.changeLayer(uuid, 0);
                    else
                        VideoGateway.changeLayer(uuid, 1);

                }

                return;
            }


            if (this.peerStreams[this.state.mainVideoElement])
                VideoGateway.changeLayer(this.state.mainVideoElement, 1);

            for (var uuid in this.peerStreams) {
                if (uuid == this.state.mainVideoElement) {
                    continue;
                }
                if (this.state.screenSharingUID !== uuid)
                    VideoGateway.changeLayer(uuid, 0);
                else
                    VideoGateway.changeLayer(uuid, 1);

            }

        }

        if (prevState.referral != this.state.referral && !this.state.referral && this.state.activeRevisitForm !== 1) {
            this.setState({ activeRevisitForm: 1 })
        }

        if (this.state.conversation && prevState.conversation && this.state.conversation != prevState.conversation && this.state.userTyping) {
            this.setState({
                userTyping: null
            })
        }
        if (prevState.quickPhraseModal != this.state.quickPhraseModal && !this.state.quickPhraseModal && this.state.phraseTab != 1) {
            this.setState({ phraseTab: 1 })
        }

        if (prevState.conversation?._id !== this.state.conversation?._id) {
            this.checkStatement()

            if (this.waitingRoomInterval) clearInterval(this.waitingRoomInterval)
            this.checkIfPatientIsInWaitingRoom()
            this.waitingRoomInterval = setInterval(() => {
                this.checkIfPatientIsInWaitingRoom()
            }, 5 * 1000);

            //return groupObj
            this.getGroupId()
        }


    }
    getGroupId = () => {
        if (this.state.conversation && this.state.conversation.clinicGroupObj && this.state.conversation.clinicGroupObj.alias) {


            fetch(API_ENDPOINT + "/data/clinic-group/info", {
                method: "POST",
                headers: {
                    "content-type": "application/json",
                },
                body: JSON.stringify({ alias: this.state.conversation.clinicGroupObj.alias }),
            }).then(res => res.json()).then((result) => {
                if (result && result._id) {
                    this.setState({ groupObj: result }, () => {
                        console.log('groupObj', this.state.groupObj);
                    })
                }
            });
        }

    }
    checkStatement = (preview = false) => {
        if (!this.state.conversation) {
            if (this.state.statementData)
                this.setState({ statementData: null })
        } else {
            let conversationId = this.state.conversation._id;

            fetch(API_ENDPOINT + `/data/statements/${conversationId}`, {
                method: 'GET',
                headers: {
                    'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage?.getItem('authToken')}` : null,
                    'content-type': 'application/json'
                }
            }).then(res => res.json()).then((result) => {
                if (result) {
                    this.setState({ statementData: result?.statement }, () => {
                        if (preview) {
                            this.setState({ previewStatement: { ... this.state.statementData } })
                        }
                    })
                }
            })

        }
    }
    exportAsPdf = (conversationId, statementId) => {

        if (conversationId && statementId) {
            this.setState({ loadingPdf: true }, () => {
                let data = {
                    conversationId: conversationId,
                    statementId: statementId,
                    lang: this.props.lang
                }
                fetch(API_ENDPOINT + '/statement/export-as-pdf', {
                    method: 'POST',
                    headers: {
                        'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage?.getItem('authToken')}` : null,
                        'content-type': 'application/json'
                    },
                    body: JSON.stringify(data)
                }).then(res => res.json()).then((result) => {
                    if (result?.pdf) {
                        setTimeout(() => {
                            let path = result.pdf;
                            let name = result?.pdfName ?? 'statement.pdf';
                            let pdfUrl = API_ENDPOINT + path;
                            var link = document.createElement("a");
                            link.download = name ? name : 'test';
                            link.target = "_blank";

                            // Construct the URI
                            link.href = pdfUrl;
                            document.body.appendChild(link);
                            link.click();
                            this.setState({ loadingPdf: false })
                        }, 1000);
                    } else {
                        this.setState({ loadingPdf: false })
                    }




                })
            })
        }


    }

    updateSort = (field, type) => {
        if (this.state.useState) {
            let state = this.state.params;
            state.sortField = field;
            state.sortType = type;
            this.setState({
                params: state
            })
        } else {
            this.props[0].history.push(generateSearchLinkMultiple(this.props[0].location.search, {}, [{ name: 'sortField', value: field }, { name: 'sortType', value: type }], false));
        }
    }

    updateParams = (name, value, restart = false) => {
        if (this.state.useState) {
            if (restart) {
                let obj = {
                    entries: 10,
                    page: 0
                };
                obj[name] = value;
                this.setState({ params: obj });
            } else {
                let obj = this.state.params;
                obj[name] = value;
                this.setState({ params: obj })
            }
        } else {
            this.props[0].history.push(generateSearchLink(this.props[0].location.search, {}, name, value, restart));
        }
    }
    updateMultipleParams = (fields, restart = false) => {
        if (this.state.useState) {
            if (restart) {
                let obj = {
                    entries: 10,
                    page: 0
                };
                for (let i = 0; i < fields.length; i++) {
                    obj[fields[i].name] = fields[i].value;
                }
                this.setState({ params: obj });

            } else {
                let obj = this.state.params;
                for (let i = 0; i < fields.length; i++) {
                    obj[fields[i].name] = fields[i].value;
                }
                this.setState({ params: obj })
            }
        } else {
            this.props[0].history.push(generateSearchLinkMultiple(this.props[0].location.search, {}, fields, restart));
        }
    }



    openConversation = (conversation, callback) => {
        console.log('open conversation');
        if (((localStorage?.getItem?.('profile') && localStorage?.getItem('profile') == 'patient') || (this.props.uData && (this.props.uData?.userLevel == 1 || this.props.uData?.userLevel == 2))) && this.props[0].location.pathname.indexOf('video-call') == -1) {
            if (!(!conversation.chatOneOnOne || (conversation.chatOneOnOne && conversation.messagesExist))) {
                this.setState({ messages: [], loadingMessages: true })

                return;
            }
        }

        this.setState({ messages: [], loadingMessages: true })
        if (this.state.conversation && this.state.conversation?._id) {
            this.props.registerClinicGroupLogs({
                type: 'closeConversation',
                conversation: this.state.conversation?._id
            })
        }
        this.props.registerClinicGroupLogs({
            type: 'openConversation',
            conversation: conversation?._id
        })

        fetch(API_ENDPOINT + '/notification/seen/update-type', {
            method: 'POST',
            headers: {
                'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage?.getItem('authToken')}` : null,
                'content-type': 'application/json'
            },
            body: JSON.stringify({ type: conversation?._id, user: this.props.uData ? this.props.uData?._id : null })
        }).then(res => res.json()).then((result) => {

            this.props.verifyUser()
        })

        let message = ''
        if (this.state.draftMessages && this.state.draftMessages.length && this.state.draftMessages.filter(item => item.conversation == conversation?._id).length) {
            let draftMessages = this.state.draftMessages;
            for (let i = 0; i < draftMessages.length; i++) {
                if (draftMessages[i].conversation == conversation?._id && draftMessages[i].message) {
                    message = draftMessages[i].message;
                    draftMessages.splice(i, 1);
                }
            }
            this.setState({ draftMessages })
        }


        this.setState({
            conversation: conversation,
            conversationsType: conversation.closed && conversation.softCloseTimestamp && localStorage?.getItem?.('profile') && (this.props.uData?.userLevel == 1 || this.props.uData?.userLevel == 2 || localStorage?.getItem?.('profile') == 'patient') ? 0 : conversation.closed ? 1 : 0,
            journalNote: false,
            writeStatementModal: false,
            referral: null,
            journalNoteIntelligentSections: null,
            loseUploadedFiles: null,
            message,
            journalNoteObj: {
                status: '',
                anamnes: '',
                bedomning: '',
                atgard: ''

            },


        }, () => {


            if (conversation.closed) {
                // this.props.socket.emit('conversations', { profile: typeof window != 'undefined' ? localStorage?.getItem?.('profile') : null, conversationsType: this.state.conversationsType, windowConversation: ((this.props[0].match.params && this.props[0].match.params.windowConversation) || (this.props[0].match.params && this.props[0].match.params.conversation)) });

                // NOT NEEDED HERE ?
                // this.emitConversations();
            }

            this.getReferralInfo(() => {
                if (this.state.conversation && this.state.conversation?._id && this.state.openedReferralPreview.indexOf(this.state.conversation?._id) != -1) {
                    this.setState({ referral: this.state.conversation?.referral })
                }
                if (this.state.conversation && this.state.conversation?._id && this.state.openedJournalNotes.indexOf(this.state.conversation?._id) != -1) {
                    this.findHealthCareFormular()
                }
            })



        });
        let conversations = this.state.conversations;
        for (let i = 0; i < conversations.length; i++) {
            if (conversations[i]?._id == conversation?._id) {
                conversations[i]._newMessage = null;
            }
        }
        this.setState({
            conversations
        }, callback)

        this.setState({
            page: 0,
            _consultations: null
        }, () => {
            this.props.socket.emit('messages', { conversation: this.state.conversation?._id, page: this.state.page, chatType: this.state.chatType })
        })


    }
    sendHealthCare = (item) => {
        let message = item.name;
        let additionalText;
        if (this.state.message) {
            additionalText = this.state.message;
        }
        this.setState({ message: message, healthCareId: item?._id }, () => this.sendMessage(additionalText))
    }

    sendLaterFormsHealthCare = (data) => {

        if (data) {
            if (!data.date) {
                data.date = moment(new Date()).format(this.props.dateFormat)
            }
            data.conversation = this.state.conversation?._id
            data.sendUser = this.props.uData?._id
            data.clinicGroup = this.props.selectedGroup
        }

        fetch(API_ENDPOINT + '/data/send/forms/later', {
            method: 'POST',
            headers: {
                'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage.getItem('authToken')}` : null,
                'content-type': 'application/json'
            },
            body: JSON.stringify({ data })
        }).then(res => res.json()).then((result) => {
            console.log(result, 'result');
            if (result) {
                this.setState({
                    sendLaterModal: null
                })
                this.get()
            }

        })

        console.log(data, 'data send later forms');
    }


    deleteSendForms = (item) => {
        console.log(item);
        fetch(API_ENDPOINT + '/data/delete/send/forms/later/' + item, {
            method: 'DELETE',
            headers: {
                'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage.getItem('authToken')}` : null,
                'content-type': 'application/json'
            },

        }).then(res => res.json()).then((result) => {
            console.log(result, 'result');
            if (result) {

                this.get()
            }

        })
    }



    checkUploadedFiles = (item) => {
        this.setState({
            chatType: 'patient'
        })
        if (this.state.document || this.state.image || this.state.video) {
            this.setState({ loseUploadedFiles: item })
        } else {
            if (this.state.message) {
                let obj = {
                    conversation: this.state.conversation?._id,
                    message: this.state.message
                };
                let draftMessages = this.state.draftMessages;
                draftMessages.push(obj);
                this.setState({
                    draftMessages,
                    message: ''
                }, () => {
                    this.openConversation(item)
                })

            } else {
                this.openConversation(item)

            }
        }

    }

    updateJournalNote = (journalNote) => {

        this.setState({ journalNoteForSave: journalNote })
    }
    updateJournalNoteOnExit = (callback, exportToJournalSystem = false) => {
        let obj = {
            conversation: this.state.conversation?._id,
            journalNote: this.state.journalNoteForSave,
            exportToJournalSystem: exportToJournalSystem ? this.props.uData?._id : false
        }
        if (exportToJournalSystem) {
            this.setState({ loadingJournalNoteExport: true })
        }
        // console.log(obj, 'obj');
        this.props.socket.emit('updateJournalNote', obj);

        if (this.state.openedJournalNotes && this.state.openedJournalNotes.length && this.state.openedJournalNotes.filter(item => item == this.state.conversation?._id).length) {
            let openedJournalNotesObjects = this.state.openedJournalNotesObjects;
            if (openedJournalNotesObjects && openedJournalNotesObjects.length) {
                for (let i = 0; i < openedJournalNotesObjects.length; i++) {
                    if (openedJournalNotesObjects[i].conversation == this.state.conversation?._id) {
                        openedJournalNotesObjects.splice(i, 1);
                    }
                }
            }

            openedJournalNotesObjects.push(obj);
            this.setState({ openedJournalNotesObjects })
        }

        if (callback) {
            callback()
        }
    }
    sendMessage = (additionalText = null) => {
        if (this.state.sendMessageCheckObj) return;

        if (!this.state.message && !this.state.document && !this.state.image && !this.state.video) {
            return;
        }

        if (!this.state.message.trim() && !this.state.document && !this.state.image && !this.state.video) {
            return;
        }

        if (!this.state.conversation) {
            return;
        }

        let obj = { conversation: this.state.conversation?._id, message: this.state.message, checkId: uuidv4() };
        if (this.state.document) {
            obj.document = this.state.document;
        }
        if (this.state.image) {
            obj.image = this.state.image;
        }
        if (this.state.video) {
            obj.video = this.state.video;
        }
        if (this.state.healthCareId) {
            obj.healthCareId = this.state.healthCareId;
        }
        if (this.state.chatType == 'team') {
            obj.chatType = 'team'
        }
        if (additionalText && typeof additionalText === 'string') {
            obj.additionalText = additionalText;
        }
        this.setState({ sendMessageCheckObj: obj });

        this.props.socket.emit('sendMessage', obj);

        if (this.checkMessageTimer) clearTimeout(this.checkMessageTimer);

        this.checkMessageTimer = setTimeout(() => {
            this.sendMessageCheck()
        }, 60 * 1000)

        // this.setState({
        //     message: '',
        //     document: null,
        //     image: null,
        //     video: null,
        //     healthCareId: null
        // })
    }

    sendMessageCheck = () => {
        if (this.state.sendMessageCheckObj && this.state.sendMessageCheckObj.checkId) {
            this.setState({ error: 'Something went wrong! Your message could not be sent. Please try again later.'.translate(this.props.lang), sendMessageCheckObj: null });
        }
        if (this.checkMessageTimer) {
            clearTimeout(this.checkMessageTimer);
            this.checkMessageTimer = null;
        };
    }

    setTranslationLanguage = (language) => {
        this.props.socket.emit('setTranslationLanguage', { conversation: this.state.conversation?._id, language: language });
    }

    emitUserTyping = () => {
        if (!this.state.conversation) {
            return;
        }

        if (this.typingTimer) {
            clearTimeout(this.typingTimer);
        }
        let conversation = this.state.conversation;
        let userTyping = this.state.userTyping;
        if (!userTyping) {
            userTyping = {}
        };

        if (userTyping && userTyping[conversation._id] && userTyping[conversation._id][this.state.chatType] && Math.floor(new Date().getTime() / 1000) - userTyping[conversation._id][this.state.chatType].time < 400) {
            userTyping[conversation._id][this.state.chatType].time = Math.floor(new Date().getTime() / 1000)
            this.setState({ userTyping }, () => {
                this.typingTimer = setTimeout(() => {
                    if (conversation && conversation?._id) {
                        this.props.socket.emit('userEndTyping2', { conversation: conversation?._id, chatType: this.state.chatType })
                        let userTypeUpdate = { ...this.state.userTyping };
                        delete userTypeUpdate[conversation._id][this.state.chatType]
                        this.setState({ userTyping: userTypeUpdate })
                    }
                }, 500);
            })
        } else {
            let obj = {
                conversation: conversation?._id,
                chatType: this.state.chatType,
                patients: []
            }
            let users = {};
            if (conversation?.users?.length) {
                for (let i = 0; i < conversation.users.length; i++) {
                    if (conversation.users[i]?.uid) {
                        users[conversation.users[i].uid] = conversation.users[i].name;
                        if (conversation.users[i]?.patientInThisGroup) {
                            obj.patients.push(conversation.users[i].uid)
                        }
                    }

                }
            }
            if (conversation?.patientId && obj.patients.indexOf(conversation.patientId) === -1) {
                obj.patients.push(conversation.patientId);
            }
            if (conversation?.patient2Id && obj.patients.indexOf(conversation.patient2Id) === -1) {
                obj.patients.push(conversation.patient2Id);
            }
            obj.users = users;
            this.props.socket.emit('userTyping2', obj)

            if (conversation?._id && this.props.uData._id && this.state.chatType) {
                if (!userTyping[conversation._id]) userTyping[conversation._id] = {};
                if (!userTyping[conversation._id][this.state.chatType]) userTyping[conversation._id][this.state.chatType] = {};
                userTyping[conversation._id][this.state.chatType].time = Math.floor(new Date().getTime() / 1000);
                this.setState({ userTyping }, () => {
                    this.typingTimer = setTimeout(() => {
                        if (conversation && conversation?._id) {
                            this.props.socket.emit('userEndTyping2', { conversation: conversation?._id, chatType: this.state.chatType })
                            let userTypeUpdate = { ...this.state.userTyping };
                            delete userTypeUpdate[conversation._id][this.state.chatType]
                            this.setState({ userTyping: userTypeUpdate })
                        }
                    }, 500);
                })
            }



        }




        // this.typingTimer = setTimeout(() => {
        //     if (this.state.conversation && this.state.conversation?._id) this.props.socket.emit('userEndTyping', { conversation: this.state.conversation?._id })
        // }, 500);

        // if (this.state.chatType == 'team') {
        //     this.props.socket.emit('userTyping', { conversation: this.state.conversation?._id, chatType: 'team' })

        // } else {
        //     this.props.socket.emit('userTyping', { conversation: this.state.conversation?._id })

        // }
    }


    close = (conversation, softClose = false) => {
        let closedConversations = this.state.closedConversations;
        closedConversations.push(conversation)
        this.setState({ closedConversations, conversation: null }, () => {
            if (softClose) {
                this.props.socket.emit('closeConversation', { conversation: conversation, softClose: true, clinicGroup: this.props.selectedGroup });

            } else {
                this.props.socket.emit('closeConversation', { conversation: conversation, clinicGroup: this.props.selectedGroup });

            }
        })

    }
    openCase = (conversation) => {
        let closedConversations = this.state.closedConversations;
        let index = closedConversations.indexOf(conversation?._id);
        if (index > -1) {
            closedConversations.splice(index, 1);
        }
        this.setState({ closedConversations })


        this.props.socket.emit('openConversation', { conversation: conversation?._id });

        if (conversation.referral && conversation.referral?._id) {
            this.setState({
                loading: true
            }, () => {
                fetch(API_ENDPOINT + '/referrals/open/' + conversation.referral?._id, {
                    method: 'POST',
                    headers: {
                        'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage?.getItem('authToken')}` : null,
                        'content-type': 'application/json'
                    }
                }).then(res => res.json()).then((result) => {


                    this.get();
                    this.setState({
                        loading: false
                    })
                })
            })
        }
    }
    closeCase = (conversation, softClose = false) => {

        let closedConversations = this.state.closedConversations;
        closedConversations.push(conversation?._id)
        this.setState({ closedConversations })
        if (softClose) {
            this.props.socket.emit('closeConversation', { conversation: conversation?._id, softClose: true, clinicGroup: this.props.selectedGroup });

        } else {
            this.props.socket.emit('closeConversation', { conversation: conversation?._id, clinicGroup: this.props.selectedGroup });

        }

        if (conversation.referral && conversation.referral?._id) {
            this.setState({
                loading: true
            }, () => {
                fetch(API_ENDPOINT + '/referrals/close/' + conversation.referral?._id, {
                    method: 'POST',
                    headers: {
                        'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage?.getItem('authToken')}` : null,
                        'content-type': 'application/json'
                    }
                }).then(res => res.json()).then((result) => {
                    if (result?.error) {
                        this.setState({
                            error: result.error?.translate(this.props.lang)
                        })
                    }

                    this.get();
                    this.setState({
                        loading: false
                    })
                })
            })
        }
        this.setState({ conversation: null, referral: null })

    }
    open = (conversation) => {
        let closedConversations = this.state.closedConversations;
        let index = closedConversations.indexOf(conversation);
        if (index > -1) {
            closedConversations.splice(index, 1);
        }
        this.setState({ closedConversations })

        this.props.socket.emit('openConversation', { conversation: conversation });
    }

    search = () => {
        // THIS WAS COMMENTED OUT BEFORE CONVERSATIONS 2 CHANGES
        // this.props.socket.emit('conversations', { search: this.state.search });
        // let conversations = this.state.conversations
        // for (let i = 0; i < conversations.length; i++) {
        //     if(conversations[i].name.indexOf(`${this.state.search}`) != -1){
        //         conversations[i].searchFilter = true

        //     }else{
        //         conversations[i].searchFilter = false
        //     }

        // }
        // this.setState({
        //     conversations
        // })


    }
    checkSearch = (conversation) => {
        if (!this.state.search) {
            return true;
        }

        if (!this.state.search.trim()) {
            return true;
        }
        let check = false;
        for (let j = 0; j < conversation.users.length; j++) {
            if (conversation.users[j]) {
                if (conversation.users[j].socialSecurityNumber && conversation.users[j].socialSecurityNumber.toLowerCase().indexOf(this.state.search.toLowerCase()) !== -1) {
                    check = true;
                    break;
                }

                if (conversation.users[j].name && conversation.users[j].name.toLowerCase().indexOf(this.state.search.toLowerCase()) !== -1) {
                    check = true;
                    break;
                }
                if (conversation.name && conversation.name.toLowerCase().indexOf(this.state.search.toLowerCase()) !== -1) {
                    check = true;
                    break;
                }
                if (conversation.name1 && conversation.name1.toLowerCase().indexOf(this.state.search.toLowerCase()) !== -1) {
                    check = true;
                    break;
                }
                if (conversation.name2 && conversation.name2.toLowerCase().indexOf(this.state.search.toLowerCase()) !== -1) {
                    check = true;
                    break;
                }
                if (this.state.search.toLowerCase().indexOf('direkt') !== -1) {
                    let searchDirekt = this.state.search.toLowerCase();
                    searchDirekt = searchDirekt.replace('direkt', 'direct')
                    if (conversation.name1 && conversation.name1.toLowerCase().indexOf(searchDirekt) !== -1) {
                        check = true;
                        break;
                    }
                    if (conversation.name2 && conversation.name2.toLowerCase().indexOf(searchDirekt) !== -1) {
                        check = true;
                        break;
                    }
                }
            }

        }

        return check;

    }

    /**
    * Function for file upload
    * @author   Milan Stanojevic
    */
    uploadDocument = (e, returnUrl = false) => new Promise((resolve, reject) => {
        let input = e.target;
        if (input.files && input.files[0]) {

            let fsize = input.files[0].size;
            let file = Math.round((fsize / 1024))
            this.setState({
                uploadTimestampDoc: Math.floor(new Date().getTime() / 1000)
            })
            if (file > 5120) {
                this.setState({ error: "File size limit has been reached! (max 5 MB)".translate(this.props.lang) })
            } else {

                if (!returnUrl) this.setState({
                    _documentUploading: this.state.conversation
                })

                let formData = new FormData();
                formData.append('file', input.files[0]);
                formData.append('group', this.props.selectedGroup)
                formData.append('fileType', 'clinicChat')

                fetch(API_ENDPOINT + '/chat/upload/document', {
                    method: 'POST',
                    headers: {
                        Accept: 'application/json',
                        'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage?.getItem('authToken') ? localStorage?.getItem("authToken") : localStorage?.getItem('guestToken')}` : null,

                    },
                    body: formData
                }).then(parseJSONUpload).then((img) => {
                    if (returnUrl) {
                        if (img) return resolve(img);
                        return reject({ error: 'Error while uploading document!' })
                    }
                    if (img?.error) {
                        this.setState({ error: img.error, _documentUploading: null })
                    } else {
                        this.setState({
                            document: img
                        })

                        if (this.state._documentUploading._id != this.state.conversation._id) {
                            this.setState({ succesUpload: 1 })
                        }
                        this.setState({ conversation: this.state._documentUploading }, () => {
                            this.sendMessage()
                            this.setState({
                                _documentUploading: null
                            })
                        })
                        return resolve(true);
                    }






                });
            }
        }
    })

    /**
    * Function for image upload
    * @author   Milan Stanojevic
    */
    uploadImage = (e, returnUrl = false) => new Promise((resolve, reject) => {
        let input = e.target;
        if (input.files && input.files[0]) {

            let fsize = input.files[0].size;
            let file = Math.round((fsize / 1024))
            this.setState({
                uploadTimestamp: Math.floor(new Date().getTime() / 1000)
            })
            if (file > 10240) {
                this.setState({ error: "File size limit has been reached! (max 10 MB)".translate(this.props.lang) })
            } else {

                if (!returnUrl) this.setState({
                    _imageUploading: this.state.conversation
                })

                let formData = new FormData();
                formData.append('file', input.files[0]);
                formData.append('group', this.props.selectedGroup)
                formData.append('fileType', 'clinicChat')

                fetch(API_ENDPOINT + '/chat/upload/image', {
                    method: 'POST',
                    headers: {
                        Accept: 'application/json',
                        'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage?.getItem('authToken') ? localStorage?.getItem("authToken") : localStorage?.getItem('guestToken')}` : null,

                    },
                    body: formData
                }).then(parseTextUpload).then((img) => {
                    if (returnUrl) {
                        if (img) return resolve(img);
                        return reject({ error: 'Error while uploading image!' });
                    }
                    if (img?.error) {
                        this.setState({ error: img.error, _imageUploading: null })
                    } else {
                        this.setState({
                            image: img
                        })
                        if (this.state._imageUploading._id != this.state.conversation._id) {
                            this.setState({ succesUpload: 2 })
                        }
                        this.setState({ conversation: this.state._imageUploading }, () => {
                            this.sendMessage()
                            this.setState({
                                _imageUploading: null,

                            })
                        })

                        return resolve(true);
                    }

                });
            }
        }
    })
    /**
    * Function for video upload
    * @author   Aleksandar Dabic
    */
    uploadVideo = (e, returnUrl = false) => new Promise((resolve, reject) => {
        let input = e.target;
        if (input.files && input.files[0]) {

            let fsize = input.files[0].size;
            let file = Math.round((fsize / 1024))
            this.setState({
                uploadTimestampVideo: Math.floor(new Date().getTime() / 1000)
            })
            if (file > 524288) {
                this.setState({ error: "File size limit has been reached! (max 512 MB)".translate(this.props.lang) })
            } else {
                if (!returnUrl) this.setState({
                    _videoUploading: this.state.conversation

                })

                let formData = new FormData();
                formData.append('file', input.files[0]);
                formData.append('group', this.props.selectedGroup)
                formData.append('fileType', 'clinicChat')
                //video progres
                this.props.handleUploadFileProgress('Processing file...'.translate(this.props.lang));


                axios.post(API_ENDPOINT + '/chat/upload/video', formData, {
                    headers: {
                        Accept: 'application/json',
                        'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage?.getItem('authToken')}` : null,
                    },
                    onUploadProgress: (progressEvent) => {
                        this.props.handleUploadFileProgress('Uploading file...'.translate(this.props.lang), Math.round((progressEvent.loaded * 100) / progressEvent.total));

                    }
                }).then((response) => {
                    if (returnUrl) {
                        if (response?.data) return resolve(response.data);
                        return reject({ error: 'Error while uploading video!' })
                    }
                    this.setState({
                        video: response.data
                    })
                    if (this.state._videoUploading?._id != this.state.conversation?._id) {
                        this.setState({ succesUpload: 3 })
                    }
                    this.setState({ conversation: this.state._videoUploading }, () => {
                        this.sendMessage()
                        this.setState({
                            _videoUploading: null
                        })
                    });

                    return resolve(true);
                }).catch((error) => {
                    if (error) {
                        let error = 'Wrong file type!';
                        if (error.response && error.response.status === 500) {
                            if (error.response && error.response.data) {
                                error = error.response.data;
                            }
                        }
                        this.setState({ error: error, _videoUploading: null })
                    }

                })


            }
        }
    })
    searchDelay = () => {
        if (!this.state.searchMembersText || !this.state.searchMembersText.trim()) {
            this.__searchMembers()
            return;
        }

        if (this.lastChangeFilter && filterDelay) {
            let delay = new Date().getTime() - this.lastChangeFilter;
            let delayTime = 100;
            if (filterDelay > 100) {
                delayTime = filterDelay - 100;
            }
            if (delay > delayTime) {
                this.__searchMembers()
                this.lastChangeFilter = null

            }
        }
    }


    // searchMembers = () => {
    //     if (!this.state.searchMembersText || !this.state.searchMembersText.trim()) {
    //         this.setState({ searchUsers: [] });
    //         return;
    //     }
    //     this.setState({ loadingSearchUsers: true })

    //     this.props.socket.emit('searchMembers', { group: this.props.selectedGroup, conversation: this.state.conversation?._id, search: this.state.searchMembersText });

    // }
    __searchMembers = () => {
        if (!this.state.searchMembersText || !this.state.searchMembersText.trim()) {
            this.setState({ searchUsers: [], loadingSearchUsers: false });
            return;
        }

        this.setState({ loadingSearchUsers: true }, () => {
            fetch(API_ENDPOINT + '/users/conversation/members', {
                method: 'POST',
                headers: {
                    'content-type': 'application/json',
                    'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage?.getItem('authToken')}` : null,
                },
                body: JSON.stringify({
                    group: this.props.selectedGroup,
                    conversation: this.state.conversation?._id,
                    search: this.state.searchMembersText
                })
            }).then(res => res.json()).then((result) => {
                if (result) {
                    if (result.error) {
                        this.setState({ loadingSearchUsers: false })
                    } else {
                        if (this.state.conversation && result.conversation == this.state.conversation?._id) {
                            if (this.state.searchMembersText == result.search) {
                                this.setState({
                                    searchUsers: result.users,
                                    loadingSearchUsers: false
                                })
                            }

                        }

                    }
                }
            });

        })




    }


    addMember = (user, dontCheckNumberOfPatients = false) => {
        let doctorAsPatient = false;
        if (this.state.conversation && this.state.conversation?.referral) {
            if (this.state.conversation?.referral && this.state.conversation?.referral.patient && this.state.conversation?.referral.patient.socialSecurityNumber == user.socialSecurityNumber)
                doctorAsPatient = true
        }
        if (this.state.conversation && this.state.conversation?.control && this.state.conversation?.socialSecurityNumber && this.state.conversation?.socialSecurityNumber == user.socialSecurityNumber) {
            doctorAsPatient = true
        }

        if (user.userLevel == 1 || user.userLevel == 2 || doctorAsPatient) {
            if (this.state.numberOfPatients == 0 || dontCheckNumberOfPatients) {
                this.props.socket.emit('addMember', { conversation: this.state.conversation?._id, member: user?._id, memberSSN: user?.socialSecurityNumber });
                this.setState({ errorTooManyPatients: false })
            } else {
                this.setState({ errorTooManyPatients: 'There can be only one patient in the conversation' })
            }

        } else {
            this.props.socket.emit('addMember', { conversation: this.state.conversation?._id, member: user?._id, memberSSN: user?.socialSecurityNumber });
        }

    }

    removeMember = (user) => {
        this.props.socket.emit('removeMember', { conversation: this.state.conversation?._id, member: user });
        this.setState({ errorTooManyPatients: false })
    }

    raiseHand = () => {
        this.setState({
            hand: true
        }, () => {
            this.props.socket.emit('raiseHand', { conversation: this.state.conversation?._id });

        })
    }




    handDown = () => {
        this.setState({
            hand: false
        }, () => {
            this.props.socket.emit('handDown', { conversation: this.state.conversation?._id });
        })
    }


    attachSinkId = (element, deviceId) => {

        if (typeof element.sinkId !== 'undefined') {

            element.setSinkId(deviceId).then(() => {
                console.log(`Success, audio output device attached: ${deviceId}`);
            })
                .catch(error => {
                    let errorMessage = error;
                    if (error.name === 'SecurityError') {
                        errorMessage = `You need to use HTTPS for selecting audio output device: ${error}`;
                    }
                    console.error(errorMessage);
                    // Jump back to first output device in the list as it's the default.
                    //this.setState({ selectedAudioOutputIndex: 0 })
                });

        } else {
            console.warn('Browser does not support output device selection.');
        }


    }

    attachSinkIdTestSound = (deviceId) => {

        if (typeof this.audioRef.sinkId !== 'undefined') {

            this.audioRef.setSinkId(deviceId).then(() => {
                console.log(`Success, audio output device attached: ${deviceId}`);
                // this.setState({ audioOutputDevice: deviceId })

            })
                .catch(error => {
                    let errorMessage = error;
                    if (error.name === 'SecurityError') {
                        errorMessage = `You need to use HTTPS for selecting audio output device: ${error}`;
                    }
                    console.error(errorMessage);
                    // Jump back to first output device in the list as it's the default.
                    //this.setState({ selectedAudioOutputIndex: 0 })
                });

        } else {
            console.warn('Browser does not support output device selection.');
        }

    }


    createConversation = (data) => {
        data.group = this.props.selectedGroup;
        data.clinic = this.props.selectedClinic;

        this.setState({
            _saving: true,
            openInNewTab: data.openInNewTab
        }, () => {
            this.props.socket.emit('createConversation', data);
        })
    }
    checkVideoElements = () => {
        let pausedVideos = {};

        for (var uuid in this.peerStreams) {
            let remoteVideo = this.remoteVideo[uuid]

            if (remoteVideo) {
                let visibility = isElementVisible(remoteVideo);
                if (visibility) {
                    VideoGateway.playVideo(uuid);
                    pausedVideos[uuid] = false;
                } else {
                    VideoGateway.pauseVideo(uuid);
                    pausedVideos[uuid] = true;
                }

            }

        }

        if (JSON.stringify(this.state.pausedVideos) != JSON.stringify(pausedVideos)) {
            this.setState({
                pausedVideos
            })
        }


    }


    generateLayout = (force = false) => {
        let mainVideoElement = 'local-video';

        if (!this.state.pinnedVideo) {

            if (this.state.videoElements.length) {
                if (this.state.activeClient && !this.state.shareOn) {
                    let uuid = null;

                    for (var key in this.state.videoElements) {
                        if (this.state.videoElements[key] && this.state.videoElements[key] == this.state.activeClient) {
                            uuid = this.state.videoElements[key];
                            break;
                        }
                    }

                    if (uuid !== null) {
                        mainVideoElement = uuid;
                    } else {
                        if (this.state.videoElements.length == 1) {
                            mainVideoElement = this.state.videoElements[0];
                        } else {
                            mainVideoElement = 'local-video';
                        }
                    }


                } else {
                    mainVideoElement = this.state.videoElements[0];
                }
            }

        } else {
            mainVideoElement = this.state.pinnedVideo;
        }

        if (mainVideoElement == this.state.mainVideoElement && !force) {
            return;
        }

        this.setState({
            mainVideoElement,
        }, () => {

            for (var uuid in this.peerStreams) {
                let remoteVideo = this.remoteVideo[uuid]
                if (remoteVideo && remoteVideo.srcObject != this.peerStreams[uuid]) {
                    remoteVideo.srcObject = this.peerStreams[uuid];
                    remoteVideo.autoplay = true;
                    remoteVideo.playsInline = true;
                    remoteVideo.playsinline = true;
                    remoteVideo.muted = false;
                    if (this.state.audioOutputDevice)
                        this.attachSinkId(remoteVideo, this.state.audioOutputDevice);
                }

            }

            this.checkVideoElements();
            console.log('setting local video src object', this.audioVideoStream)

            let localVideo = this.localVideo;
            if (localVideo && ((this.state.shareOn && this.screenStream != localVideo.srcObject) || (!this.state.shareOn && localVideo.srcObject != this.audioVideoStream))) {

                if (this.state.shareOn)
                    localVideo.srcObject = this.screenStream;
                else
                    localVideo.srcObject = this.audioVideoStream;

                localVideo.autoplay = true;
                localVideo.playsInline = true;
                localVideo.muted = true;

            }
        });
    }


    showCopiedLink = () => {
        this.setState({
            linkCopied: true
        })
        setTimeout(() => {
            this.setState({
                linkCopied: false
            })
        }, 1000)
    }

    toggleDropdownMore = () => {
        this.setState({
            dropdownMore: !this.state.dropdownMore
        })
    }

    handleClickOutside(event) {
        if (this.dropDownRef && this.dropDownRef.contains && !this.dropDownRef.contains(event.target) && this.dropDownMenuRef && this.dropDownMenuRef.contains && !this.dropDownMenuRef.contains(event.target)) {
            this.setState({ dropdownMore: false })
        }
    }

    renderOtherVideoElements = (renderMainVideoElement = true, renderOtherElements = true) => {
        let numberOfRows = 1;
        let numberOfColumnsOnScreen = typeof window != 'undefined' && window.innerWidth < 768 ? 6 : 14;

        // let max = numberOfColumnsOnScreen;
        // let elements = (this.state.kbps - 2000) / 400;
        // numberOfColumnsOnScreen = elements > max ? max : elements;

        let videoElementHeight = (this.callArea && this.callArea.offsetWidth) / numberOfColumnsOnScreen;
        let videoElementWidth = videoElementHeight;
        let otherVideosContainerHeight = (100 * videoElementHeight * numberOfRows) / (this.callArea && this.callArea.offsetHeight - 80);


        let localVideoPosition = null;

        if (this.localVideo && this.callArea) {
            localVideoPosition = getObjectFitSize(true, this.callArea.offsetWidth, (this.callArea.offsetHeight - 80 - videoElementHeight), this.localVideo.videoWidth, this.localVideo.videoHeight);
        }

        return (
            <>
                {
                    this.state.videoElements.map((item, idx) => {
                        if (!renderMainVideoElement && this.state.mainVideoElement == item) {
                            return null;
                        }
                        if (!renderOtherElements && this.state.mainVideoElement != item) {
                            return null;
                        }

                        let user = this.state.conversation?.users?.filter?.(member => member.uid == item);
                        if (user && user.length) {
                            user = user[0];
                        } else {
                            if (this.state.guestNames?.[item]) {
                                user = {
                                    uid: item,
                                    name: this.state.guestNames[item],
                                    bankIdVerified: this.state.guestsVerificationStatus ? this.state.guestsVerificationStatus[item] : null
                                }
                            } else {
                                user = null;
                            }
                        }


                        let videoPosition = null;

                        if (this.remoteVideo[item] && this.callArea) {
                            videoPosition = getObjectFitSize(true, this.callArea.offsetWidth, (this.callArea.offsetHeight - 80 - videoElementHeight), this.remoteVideo[item].videoWidth, this.remoteVideo[item].videoHeight);
                        }


                        return (
                            <div
                                key={item}
                                style={
                                    this.state.speaking &&
                                        this.state.speaking[item] &&
                                        this.state.activeClient != item ?
                                        { opacity: this.state.fullScreen ? this.state.mainVideoElement == item ? 1 : 0 : 1, border: this.state.mainVideoElement == item ? '4px solid transparent' : '4px solid #60BEBB', width: this.state.mainVideoElement == item ? this.state.fullScreen ? '100vw' : '100%' : `${videoElementWidth}px`, height: this.state.mainVideoElement == item ? this.state.fullScreen ? '100vh' : `${100 - otherVideosContainerHeight}%` : `${videoElementHeight}px`, }
                                        :
                                        { opacity: this.state.fullScreen ? this.state.mainVideoElement == item ? 1 : 0 : 1, border: '4px solid transparent', width: this.state.mainVideoElement == item ? this.state.fullScreen ? '100vw' : '100%' : `${videoElementWidth}px`, height: this.state.mainVideoElement == item ? this.state.fullScreen ? '100vh' : `${100 - otherVideosContainerHeight}%` : `${videoElementHeight}px`, objectFit: this.state.mainVideoElement == item ? 'contain' : 'cover' }
                                }
                                className={!this.state.cameraStatus[item] || this.state.pausedVideos[item] ? `video-container camera-off ${this.state.mainVideoElement == item ? 'active-element' : ''}` : `video-container ${this.state.mainVideoElement == item ? 'active-element' : ''}`}
                                // key={item}
                                onClick={() => this.setState({ activeClient: item }, this.generateLayout)}
                            >
                                <video /*controls={typeof window != 'undefined' && iosDevice() ? true : false}*/ style={{ objectFit: this.state.mainVideoElement == item ? 'contain' : 'cover' }} class="remote-video" ref={(node) => this.remoteVideo[item] = node}></video>
                                {user ?
                                    <div className="user-info"
                                    >
                                        <img src={user.profilePicture ? API_ENDPOINT + user.profilePicture : profilePicture} />
                                        {this.state.mainVideoElement == item ?
                                            <p style={{ display: 'flex', alignItems: 'center' }}>{user.bankIdVerified === true ? <Isvg src={secureIcon} style={{ width: 32, minWidth: 32 }} /> : ''} {user ? user.name : ''} {user.bankIdVerified === true ? <>({'BankID verified'.translate(this.props.lang)})</> : ''}</p>
                                            :
                                            <p onMouseEnter={(e) => {
                                                if (this.state.mainVideoElement == item) {
                                                    return;
                                                }

                                                this.setState({
                                                    memberTooltip: {
                                                        client: item,
                                                        top: e.clientY - 50,
                                                        left: getOffset(e.target).left - (e.target.offsetWidth / 2) + 20,
                                                        name: user ? user.name : '',

                                                    }
                                                })

                                            }}

                                                onMouseLeave={(e) => {
                                                    if (this.state.mainVideoElement == 'local-video') {
                                                        return;
                                                    }

                                                    this.setState({
                                                        memberTooltip: null
                                                    })

                                                }}
                                                style={{ display: 'flex', alignItems: 'center' }}
                                            >
                                                {
                                                    user.bankIdVerified === true ?
                                                        <Isvg src={secureIcon} style={{ width: 16, minWidth: 16 }} />
                                                        :
                                                        ''
                                                }
                                                {
                                                    user.name ?
                                                        (user.name + (user.bankIdVerified === true ? ('(' + 'BankID verified'.translate(this.props.lang) + ')') : '')).length > 7 ?
                                                            (user.name + (user.bankIdVerified === true ? ('(' + 'BankID verified'.translate(this.props.lang) + ')') : '')).substring(0, 7) + '...'
                                                            : (user.name + (user.bankIdVerified === true ? ('(' + 'BankID verified'.translate(this.props.lang) + ')') : ''))
                                                        : ''}

                                            </p>
                                        }
                                    </div>
                                    :
                                    null
                                }
                                {
                                    this.state.hands[item] ?

                                        <div className="hand">
                                            <Player
                                                onEvent={(event) => {
                                                    if (event === "load" && this.player && this.player.current) this.player.current.play();
                                                }}
                                                ref={this.player} // set the ref to your class instance
                                                autoplay={true}
                                                loop={true}
                                                controls={true}
                                                src="/12738-wave.json"
                                                renderer={'svg'}
                                                style={this.state.mainVideoElement == item ? { height: "256px", width: "256px" } : { height: "48px", width: "48px" }}
                                            ></Player>
                                        </div>

                                        :

                                        null
                                }

                                {
                                    this.state.connecting[item] ?
                                        <div className="connecting" style={{ marginLeft: `-${(videoElementWidth - 20) / 2}px`, marginTop: `-${(videoElementWidth - 20) / 2}px` }}>
                                            {
                                                this.state.connecting[item] ?
                                                    <>

                                                        <Isvg src={nowifiIcon} className="icon" />
                                                        <h6>{'No connection'.translate(this.props.lang)}</h6>
                                                        <p>{'The call is resumed automatically when the connection is improved.'.translate(this.props.lang)}</p>
                                                        {/* <button className='reconnect-btn' onClick={() => this.reconnect()}>{'Reconnect manually'.translate(this.props.lang)}</button> */}
                                                    </>
                                                    :
                                                    null
                                            }


                                            <Player
                                                autoplay={true}
                                                loop={true}
                                                src="/lf30_editor_l5cxzdyf.json"
                                                renderer={'svg'}
                                                style={{ height: `${videoElementWidth - 20}px`, width: `${videoElementWidth - 20}px` }}
                                            ></Player>
                                        </div>
                                        :
                                        null
                                }

                                {
                                    !this.state.micStatus[item] ?
                                        <div className="microphone-muted" style={this.remoteVideo[item] && this.state.mainVideoElement == item ? { top: videoPosition.y + videoPosition.height - 40, left: videoPosition.x + videoPosition.width - 40 } : {}}>
                                            <Isvg src={micMutedIcon} />
                                        </div>
                                        :
                                        null
                                }
                                {
                                    this.state.pinnedVideo == item ?
                                        <button className="pin-video" onClick={() => this.setState({ pinnedVideo: null }, this.generateLayout)}>
                                            <Isvg src={unpinIcon} />
                                        </button>

                                        :
                                        <button className="pin-video" onClick={() => this.setState({ pinnedVideo: item }, this.generateLayout)}>
                                            <Isvg src={pinIcon} />
                                        </button>
                                }

                                {
                                    this.remoteVideo[item] && this.state.mainVideoElement == item && this.state.cameraStatus[item] && this.props.uData ?
                                        <button className="take-picture" style={{ top: this.state.fullScreen ? '95vh' : videoPosition.y + videoPosition.height - 40, left: videoPosition.x + 5 }} onClick={() => {

                                            var canvas = document.createElement('canvas');
                                            canvas.width = this.remoteVideo[item].videoWidth;
                                            canvas.height = this.remoteVideo[item].videoHeight;
                                            document.body.appendChild(canvas);

                                            var context = canvas.getContext('2d');
                                            context.drawImage(this.remoteVideo[item], 0, 0, this.remoteVideo[item].videoWidth, this.remoteVideo[item].videoHeight);

                                            //var data = canvas.toDataURL('image/png');
                                            //console.log(data);

                                            canvas.toBlob((blob) => {
                                                let file = new File([blob], "screenshot.png", { type: "image/png" })
                                                this.setState({
                                                    takePhotoModal: {
                                                        message: '',
                                                        previewImage: canvas.toDataURL('image/png'),
                                                        image: file
                                                    }
                                                })
                                                canvas.remove();

                                            }, 'image/png');



                                        }}>
                                            <Isvg src={photoIcon} /><div className="chat-icons-tooltip">{'Take photo'.translate(this.props.lang)}</div>
                                        </button>
                                        :

                                        null
                                }


                            </div>
                        )
                    })
                }

                {(renderOtherElements && this.state.mainVideoElement != 'local-video') || (renderMainVideoElement && this.state.mainVideoElement == 'local-video') ?

                    <div className={this.state.cameraOff || this.state.shareOn ? `video-container camera-off ${this.state.mainVideoElement == 'local-video' ? 'active-element' : ''}` : `video-container ${this.state.mainVideoElement == 'local-video' ? 'active-element' : ''}`}
                        style={{ opacity: this.state.fullScreen ? this.state.mainVideoElement == 'local-video' ? 1 : 0 : 1, border: '4px solid transparent', width: this.state.mainVideoElement == 'local-video' ? this.state.fullScreen ? '100vw' : '100%' : `${videoElementWidth}px`, height: this.state.mainVideoElement == 'local-video' ? this.state.fullScreen ? '100vh' : `${100 - otherVideosContainerHeight}%` : `${videoElementHeight}px` }} key="local-video" onClick={() => this.setState({ activeClient: 'local-video' }, this.generateLayout)}>
                        <video controls={false} style={{ objectFit: this.state.mainVideoElement == 'local-video' ? 'contain' : 'cover' }} class="remote-video" ref={(node) => this.localVideo = node}></video>

                        <div className="user-info"

                        >
                            <img src={this.props.uData && this.props.uData.profilePicture ? API_ENDPOINT + this.props.uData.profilePicture : profilePicture} />
                            {this.state.mainVideoElement == 'local-video' ?

                                <p>{this.props.uData ? this.props.uData.name : this.state.guestName}</p>
                                :
                                <p onMouseEnter={(e) => {
                                    if (this.state.mainVideoElement == 'local-video') {
                                        return;
                                    }

                                    this.setState({
                                        memberTooltip: {
                                            client: 'local-video',
                                            top: e.clientY - 50,
                                            left: getOffset(e.target).left - (e.target.offsetWidth / 2) + 20,
                                            name: this.props.uData ? this.props.uData.name : this.state.guestName
                                        }
                                    })

                                }}

                                    onMouseLeave={(e) => {
                                        if (this.state.mainVideoElement == 'local-video') {
                                            return;
                                        }

                                        this.setState({
                                            memberTooltip: null
                                        })

                                    }}
                                >{this.props.uData ? this.props.uData.name && this.props.uData.name.length > 7 ? this.props.uData.name.substring(0, 7) + '...' : this.props.uData.name : this.state.guestName && this.state.guestName.length > 7 ? this.state.guestName.substring(0, 7) + '...' : this.state.guestName}</p>
                            }
                        </div>
                        {
                            this.state.micOff ?
                                <div className="microphone-muted" style={this.localVideo && this.state.mainVideoElement == 'local-video' ? { top: localVideoPosition.y + localVideoPosition.height - 40, left: localVideoPosition.x + localVideoPosition.width - 40 } : {}}>
                                    <Isvg src={micMutedIcon} />
                                </div>
                                :
                                null
                        }
                        {
                            this.state.pinnedVideo == 'local-video' ?
                                <button className="pin-video" onClick={() => this.setState({ pinnedVideo: null }, this.generateLayout)}>
                                    <Isvg src={unpinIcon} />
                                </button>

                                :
                                <button className="pin-video" onClick={() => this.setState({ pinnedVideo: 'local-video' }, this.generateLayout)}>
                                    <Isvg src={pinIcon} />
                                </button>
                        }





                    </div>
                    :
                    null

                }

            </>
        )
    }


    getDevices = () => {
        if (navigator) {
            navigator.mediaDevices.enumerateDevices().then((devices) => {

                let videoInput = [];
                let audioInput = [];
                let audioOutput = [];
                let audioInputFilter = []
                let audioOutputFilter = []


                for (let i = 0; i < devices.length; i++) {
                    console.log(devices[i]);
                    if (devices[i].kind == "audioinput") {
                        if (devices[i].deviceId !== 'default' && devices[i].deviceId !== 'communications') {
                            audioInputFilter.push(devices[i])
                        } else {
                            audioInput.push(devices[i]);
                        }
                    } else if (devices[i].kind == "audiooutput") {
                        if (devices[i].deviceId !== 'default' && devices[i].deviceId !== 'communications') {
                            audioOutputFilter.push(devices[i])
                        } else {
                            audioOutput.push(devices[i]);
                        }
                    } else if (devices[i].kind == "videoinput") {
                        videoInput.push(devices[i]);
                    }
                }



                this.setState({
                    videoInput,
                    audioOutput,
                    audioInput,
                    audioInputFilter,
                    audioOutputFilter
                })
            })
        }
    }


    savePhoto = () => {
        /*var blobBin = atob(this.state.takePhotoModal.image.split(',')[1]);
        var array = [];
        for(var i = 0; i < blobBin.length; i++) {
            array.push(blobBin.charCodeAt(i));
        }
        var file=new Blob([new Uint8Array(array)],   {type: 'image/png'});*/




        let formData = new FormData();
        formData.append('file', this.state.takePhotoModal.image);

        fetch(API_ENDPOINT + '/chat/upload/image', {
            method: 'POST',
            headers: {
                Accept: 'application/json',
                'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage?.getItem('authToken')}` : null,

            },
            body: formData
        }).then((res) => res.text()).then((img) => {
            if (this.state.chatType == 'team') {
                this.props.socket.emit('sendMessage', { image: img, message: this.state.takePhotoModal.message, conversation: this.state.conversation?._id, chatType: 'team' });

            } else {
                this.props.socket.emit('sendMessage', { image: img, message: this.state.takePhotoModal.message, conversation: this.state.conversation?._id });

            }
            this.setState({
                takePhotoModal: null
            })
        });
    }

    checkQuestionCondition(questionId) {
        let question = this.getQuestion(questionId);
        if (!question) {
            return;
        }

        if (!question.conditions) {
            return true;
        }

        if (!question.conditions.length) {
            return true;
        }

        for (let i = 0; i < question.conditions.length; i++) {
            if (!question.conditions[i].conditionQuestion) {
                continue;
            }

            let conditionQuestion = this.getQuestion(question.conditions[i].conditionQuestion);
            if (!conditionQuestion) {
                continue;
            }

            if (conditionQuestion.type == 1) {
                if (this.state.questionaryAnswers[conditionQuestion.id] == question.conditions[i].conditionAnswer) {
                    return true;
                }
            } else if (conditionQuestion.type == 2) {
                if (question.conditions[i].conditionAnswer == '__user-input:' && this.state.questionaryAnswers[conditionQuestion.id]) {
                    let index = this.indexOfIndexOf(this.state.questionaryAnswers[conditionQuestion.id], '__user-input:');
                    if (index != -1 && this.state.questionaryAnswers[conditionQuestion.id][index] && this.state.questionaryAnswers[conditionQuestion.id][index].replace('__user-input:', '')) {
                        return true;
                    }
                } else {

                    if (this.state.questionaryAnswers[conditionQuestion.id] && this.state.questionaryAnswers[conditionQuestion.id].indexOf(question.conditions[i].conditionAnswer) !== -1) {
                        return true;
                    }
                }

            } else if (conditionQuestion.type == 3 || conditionQuestion.type == 5 || conditionQuestion.type == 6 || conditionQuestion.type == 9 || conditionQuestion.type == 12 || conditionQuestion.type == 13 || conditionQuestion.type == 14) {
                if (this.state.questionaryAnswers[conditionQuestion.id]) {
                    return true;
                }

            } else if (conditionQuestion.type == 10) {
                return true;

            } else if (conditionQuestion.type == 15) {
                return true;
            } else if (conditionQuestion.type == 16 || conditionQuestion.type == 17) {
                if (this.state.questionaryAnswers[conditionQuestion.id] == question.conditions[i].conditionAnswer) {
                    return true;
                }
            }
        }
        // for (let i = 0; i < question.conditions.length; i++) {
        //     if (!question.conditions[i].conditionQuestion) {
        //         continue;
        //     }

        //     let conditionQuestion = this.getQuestion(question.conditions[i].conditionQuestion);
        //     if (!conditionQuestion) {
        //         continue;
        //     }

        //     if (conditionQuestion.type == 1) {
        //         if (this.state.questionaryAnswers[conditionQuestion.id] == question.conditions[i].conditionAnswer) {
        //             return true;
        //         }
        //     } else if (conditionQuestion.type == 2) {
        //         if (this.state.questionaryAnswers[conditionQuestion.id] && (this.state.questionaryAnswers[conditionQuestion.id].indexOf(question.conditions[i].conditionAnswer) !== -1 || this.state.questionaryAnswers[conditionQuestion.id].filter(ans => ans.match(question.conditions[i].conditionAnswer)).length)) {
        //             return true;
        //         }

        //     } else if (conditionQuestion.type == 6) {
        //         if (this.state.questionaryAnswers[conditionQuestion.id] && this.state.questionaryAnswers[conditionQuestion.id][0] && this.state.questionaryAnswers[conditionQuestion.id][1]) {
        //             return true;
        //         }


        //     } else if (conditionQuestion.type == 7) {
        //         if (this.state.questionaryAnswers[conditionQuestion.id] && this.state.questionaryAnswers[conditionQuestion.id][0] && this.state.questionaryAnswers[conditionQuestion.id][1] && this.state.questionaryAnswers[conditionQuestion.id][2] && this.state.questionaryAnswers[conditionQuestion.id][3]) {
        //             return true;
        //         }


        //     } else if (conditionQuestion.type == 3 || conditionQuestion.type == 5 || conditionQuestion.type == 8 || conditionQuestion.type == 9) {
        //         if (this.state.questionaryAnswers[conditionQuestion.id]) {
        //             return true;
        //         }

        //     } else if (conditionQuestion.type == 10) {
        //         return true;
        //     } else if (conditionQuestion.type == 12) {
        //         if (this.state.questionaryAnswers[conditionQuestion.id] && this.state.questionaryAnswers[conditionQuestion.id][0] && this.state.questionaryAnswers[conditionQuestion.id][1] && this.state.questionaryAnswers[conditionQuestion.id][2]) {
        //             return true;
        //         }


        //     } else if (conditionQuestion.type == 13) {
        //         if (this.state.questionaryAnswers[conditionQuestion.id] && this.state.questionaryAnswers[conditionQuestion.id][0]) {
        //             return true;
        //         }


        //     }
        // }
        return false;
    }
    checkSectionAnswers = (sectionIdx) => {
        let lastQuestionType = -1;
        let i = sectionIdx;
        for (let j = 0; j < this.state.sections[i].questions.length; j++) {
            if (this.checkQuestionCondition(this.state.sections[i].questions[j].id) && (this.state.sections[i].questions[j].type == 1 || this.state.sections[i].questions[j].type == 2 || this.state.sections[i].questions[j].type == 3 || this.state.sections[i].questions[j].type == 5 || this.state.sections[i].questions[j].type == 6 || this.state.sections[i].questions[j].type == 7 || this.state.sections[i].questions[j].type == 8 || this.state.sections[i].questions[j].type == 9 || this.state.sections[i].questions[j].type == 10 || this.state.sections[i].questions[j].type == 12 || this.state.sections[i].questions[j].type == 13)) {
                lastQuestionType = this.state.sections[i].questions[j].type;

                if (this.state.sections[i].questions[j].type == 10) {
                    continue;
                }


                if (!this.state.questionaryAnswers[this.state.sections[i].questions[j].id] /*&& !this.state.skippedQuestions[this.state.sections[i].questions[j].id] */) {
                    return false;
                }
            }

            if (this.checkQuestionCondition(this.state.sections[i].questions[j].id) && this.state.sections[i].questions[j].type == 4) {
                lastQuestionType = this.state.sections[i].questions[j].type;
            }
        }



        return lastQuestionType != 4 ? true : false;
    }
    getQuestion = (id) => {
        for (let i = 0; i < this.state.sections.length; i++) {
            if (this.state.sections[i].questions) {
                for (let j = 0; j < this.state.sections[i].questions.length; j++) {
                    if (this.state.sections[i].questions[j].id == id) {
                        return this.state.sections[i].questions[j];
                    }
                }
            }
        }


        return {};
    }
    checkSectionCondition = (sectionIdx) => {
        let section = this.state.sections[sectionIdx];
        if (!section) {
            return;
        }

        if (!section.conditions) {
            if (sectionIdx == 0) {
                return true;
            } else {
                if (this.checkSectionAnswers(sectionIdx - 1)) {
                    return true;
                } else {

                    return false;

                }
            }

            return true;
        }
        if (!section.conditions.length) {
            if (sectionIdx == 0) {
                return true;
            } else {
                if (this.checkSectionAnswers(sectionIdx - 1)) {
                    return true;
                } else {

                    return false;
                }
            }
        }

        for (let i = 0; i < section.conditions.length; i++) {
            if (!section.conditions[i].conditionQuestion) {
                continue;
            }

            let question = this.getQuestion(section.conditions[i].conditionQuestion);
            if (!question) {
                continue;
            }

            if (question.type == 1) {
                if (this.state.questionaryAnswers[question.id] == section.conditions[i].conditionAnswer) {
                    return true;
                }
            } else if (question.type == 2) {
                if (this.state.questionaryAnswers[question.id] && this.state.questionaryAnswers[question.id].indexOf(section.conditions[i].conditionAnswer) !== -1) {

                    return true;
                }

            } else if (question.type == 3) {
                if (this.state.questionaryAnswers[question.id]) {
                    return true;
                }

            } else if (question.type == 5) {
                if (this.state.questionaryAnswers[question.id]) {
                    return true;
                }

            } else if (question.type == 6) {
                if (this.state.questionaryAnswers[question.id]) {
                    return true;
                }

            } else if (question.type == 7) {
                if (this.state.questionaryAnswers[question.id]) {
                    return true;
                }
            } else if (question.type == 8) {
                if (this.state.questionaryAnswers[question.id]) {
                    return true;
                }
            } else if (question.type == 9) {
                if (this.state.questionaryAnswers[question.id]) {
                    return true;
                }

            } else if (question.type == 10) {
                return true;
            } else if (question.type == 12) {
                if (this.state.questionaryAnswers[question.id]) {
                    return true;
                }

            } else if (question.type == 13) {
                if (this.state.questionaryAnswers[question.id]) {
                    return true;
                }

            }
        }

        return false;
    }



    getAnswer = (id) => {
        for (let i = 0; i < this.state.sections.length; i++) {
            if (this.state.sections[i].questions) {
                for (let j = 0; j < this.state.sections[i].questions.length; j++) {
                    if (this.state.sections[i].questions[j].answers) {
                        for (let k = 0; k < this.state.sections[i].questions[j].answers.length; k++) {
                            if (this.state.sections[i].questions[j].answers[k].id == id) {
                                return this.state.sections[i].questions[j].answers[k];
                            }
                        }
                    }
                    if (this.state.sections[i].questions[j].blocks) {
                        for (let k = 0; k < this.state.sections[i].questions[j].blocks.length; k++) {
                            if (this.state.sections[i].questions[j].blocks[k].id == id) {
                                return this.state.sections[i].questions[j].blocks[k];
                            }
                        }
                    }
                }
            }
        }


        return {};
    }





    generateReferral = () => {



        let selectedClinic = this.state.activeClinic ? this.state.activeClinic : {};
        let selectedGroup = this.state.activeGroup ? this.state.activeGroup : {};

        let patient = {
            referralNumber: '111',
            name: this.props.uData.userData && this.props.uData.userData.name,
            socialSecurityNumber: this.props.uData.socialSecurityNumber,
            street: this.props.uData.street,
            zip: this.props.uData.zip,
            city: this.props.uData.city,
            phone: this.props.uData.phone,
            email: this.props.uData.email
        }



        let clinicData = {
            ...selectedClinic,
            logo: selectedGroup ? selectedGroup.logo : null
        }


        let referralData = '';


        if (!this.state.activeQuestionary?.intelligentReferral) {
            for (let i = 0; i < this.state.sections.length; i++) {
                if (this.checkSectionCondition(i)) {
                    if (this.state.sections[i].displayOnReferral) {
                        if (i > 0) {
                            referralData += '<br/><br/>'
                        }
                        referralData += `<p><strong>${this.state.sections[i].name}</strong></p>\n`;
                    }
                    referralData += '<p>\n';
                    for (let j = 0; j < this.state.sections[i].questions.length; j++) {
                        if (this.checkQuestionCondition(this.state.sections[i].questions[j].id) && (this.state.sections[i].questions[j].type == 10 || this.state.questionaryAnswers[this.state.sections[i].questions[j].id])) {
                            if (this.state.sections[i].questions[j].displayOnReferral || true) {
                                if (this.state.sections[i].questions[j].type == 1) {
                                    if (this.getAnswer(this.state.questionaryAnswers[this.state.sections[i].questions[j].id]).name)
                                        referralData += this.state.sections[i].questions[j].name + ' - <strong>' + this.getAnswer(this.state.questionaryAnswers[this.state.sections[i].questions[j].id]).name + '</strong> <br/>';
                                } else if (this.state.sections[i].questions[j].type == 2) {
                                    if (this.state.questionaryAnswers[this.state.sections[i].questions[j].id]) {

                                        let arr = [];

                                        //for (let k = 0; k < this.state.questionaryAnswers[this.state.sections[i].questions[j].id].length; k++) {
                                        for (let k = 0; k < this.state.sections[i].questions[j].answers.length; k++) {
                                            let check = -1;
                                            for (let l = 0; l < this.state.questionaryAnswers[this.state.sections[i].questions[j].id].length; l++) {
                                                if (this.state.sections[i].questions[j].answers[k] && this.state.questionaryAnswers[this.state.sections[i].questions[j].id][l] == this.state.sections[i].questions[j].answers[k].id) {
                                                    check = l;
                                                    break;
                                                }
                                            }
                                            if (check != -1) {
                                                //if (this.state.sections[i].questions[j].answerPrefix) {
                                                /*if (this.state.questionaryAnswers[this.state.sections[i].questions[j].id][k].indexOf('__user-input:') == 0){
                                                    arr.push(this.state.questionaryAnswers[this.state.sections[i].questions[j].id][k].replace('__user-input:', ''));
                                                }else{*/
                                                if (this.getAnswer(this.state.questionaryAnswers[this.state.sections[i].questions[j].id][check]).name)
                                                    arr.push(this.getAnswer(this.state.questionaryAnswers[this.state.sections[i].questions[j].id][check]).name);
                                                // }
                                                /* } else {
                                                     if (this.getAnswer(this.state.questionaryAnswers[this.state.sections[i].questions[j].id][check]).text)
                                                         referralData += this.getAnswer(this.state.questionaryAnswers[this.state.sections[i].questions[j].id][check]).text + ' ';
                                                 }*/
                                            }
                                        }

                                        if (this.state.sections[i].questions[j].userInput && this.indexOfIndexOf(this.state.questionaryAnswers[this.state.sections[i].questions[j].id], '__user-input:') !== -1) {
                                            arr.push(this.state.questionaryAnswers[this.state.sections[i].questions[j].id][this.indexOfIndexOf(this.state.questionaryAnswers[this.state.sections[i].questions[j].id], '__user-input:')].replace('__user-input:', ''))
                                        }


                                        //if (arr.length && this.state.sections[i].questions[j].answerPrefix) {
                                        if (arr.length > 1) {
                                            let last = arr.pop();
                                            referralData += this.state.sections[i].questions[j].name + ' - <strong>' + arr.join(', ') + ' och '.translate(this.props.lang) + last + '. </strong><br/>';
                                        } else {
                                            referralData += this.state.sections[i].questions[j].name + ' - <strong>' + arr.join(', ') + '.</strong><br/> ';
                                        }
                                        //}
                                    }
                                } else if (this.state.sections[i].questions[j].type == 3) {
                                    referralData += this.state.sections[i].questions[j].name + ' - <strong>' + this.state.questionaryAnswers[this.state.sections[i].questions[j].id] + '.</strong><br/> ';
                                } else if (this.state.sections[i].questions[j].type == 5) {
                                    //referralData += this.state.questionaryAnswers[this.state.sections[i].questions[j].id] + '. ';
                                    let newArr = JSON.parse(JSON.stringify(this.state.questionaryAnswers[this.state.sections[i].questions[j].id]));
                                    let arr = [];
                                    for (let k = 0; k < newArr.length; k++) {
                                        if (newArr[k]) {
                                            arr.push(newArr[k]);
                                        }
                                    }
                                    if (arr.length > 1) {
                                        let last = arr.pop();
                                        referralData += arr.join(', ') + ' och '.translate(this.props.lang) + last + '. ';
                                    } else {
                                        referralData += arr.join(', ') + '. ';
                                    }

                                } else if (this.state.sections[i].questions[j].type == 6) {
                                    if (this.state.questionaryAnswers[this.state.sections[i].questions[j].id])
                                        referralData += 'Patientens längd: ' + this.state.questionaryAnswers[this.state.sections[i].questions[j].id][1] + ', ' + 'vikt: ' + this.state.questionaryAnswers[this.state.sections[i].questions[j].id][0] + ', ' + 'BMI: ' + parseFloat(this.state.questionaryAnswers[this.state.sections[i].questions[j].id] && this.state.questionaryAnswers[this.state.sections[i].questions[j].id].length ? (this.state.questionaryAnswers[this.state.sections[i].questions[j].id][0] / ((this.state.questionaryAnswers[this.state.sections[i].questions[j].id][1] / 100) * (this.state.questionaryAnswers[this.state.sections[i].questions[j].id][1] / 100))) : 0).toFixed(1) + '. ';
                                } else if (this.state.sections[i].questions[j].type == 7) {
                                    referralData += `<br/><br/>
                                Frikortsnummer: ${this.state.questionaryAnswers[this.state.sections[i].questions[j].id] ? this.state.questionaryAnswers[this.state.sections[i].questions[j].id][0] : ''}<br/>
                                Giltig från: ${this.state.questionaryAnswers[this.state.sections[i].questions[j].id] ? this.state.questionaryAnswers[this.state.sections[i].questions[j].id][1] : ''}<br/>
                                Giltig till: ${this.state.questionaryAnswers[this.state.sections[i].questions[j].id] ? this.state.questionaryAnswers[this.state.sections[i].questions[j].id][2] : ''}<br/>
                                Utfärdad av: ${this.state.questionaryAnswers[this.state.sections[i].questions[j].id] ? this.state.questionaryAnswers[this.state.sections[i].questions[j].id][3] : ''}<br/><br/>
                                `
                                } else if (this.state.sections[i].questions[j].type == 8) {
                                    referralData += `<a href='${API_ENDPOINT + this.state.questionaryAnswers[this.state.sections[i].questions[j].id]}' target="_blank"> ${this.state.sections[i].questions[j].name}</a><br/>` + ' ';
                                } else if (this.state.sections[i].questions[j].type == 9) {
                                    if (this.state.questionaryAnswers[this.state.sections[i].questions[j].id] && this.state.questionaryAnswers[this.state.sections[i].questions[j].id].length) {
                                        for (let f = 0; f < this.state.questionaryAnswers[this.state.sections[i].questions[j].id].length; f++) {
                                            if (this.state.questionaryAnswers[this.state.sections[i].questions[j].id][f] && this.state.questionaryAnswers[this.state.sections[i].questions[j].id][f].file)
                                                referralData += `<a href='${API_ENDPOINT + this.state.questionaryAnswers[this.state.sections[i].questions[j].id][f].file}' target="_blank">File/photo</a><br/>` + ' ';
                                        }
                                    }
                                    // referralData += `<a href='${API_ENDPOINT + this.state.questionaryAnswers[this.state.sections[i].questions[j].id].file}' target="_blank">File/photo</a><br/>` + ' ';
                                } else if (this.state.sections[i].questions[j].type == 10) {
                                    if (this.state.sections[i].questions[j].userInput) {
                                        referralData += "<br/><br/>"
                                    } else {
                                        referralData += "<br/>"
                                    }

                                } else if (this.state.sections[i].questions[j].type == 12) {
                                    referralData += 'Patientens blodtryck uppmätt till: <br/>';
                                    referralData += this.state.questionaryAnswers[this.state.sections[i].questions[j].id] && this.state.questionaryAnswers[this.state.sections[i].questions[j].id][0] ? this.state.questionaryAnswers[this.state.sections[i].questions[j].id][0] + '/' : '';
                                    referralData += this.state.questionaryAnswers[this.state.sections[i].questions[j].id] && this.state.questionaryAnswers[this.state.sections[i].questions[j].id][1] ? this.state.questionaryAnswers[this.state.sections[i].questions[j].id][1] + '<br/>' : '';
                                    referralData += this.state.questionaryAnswers[this.state.sections[i].questions[j].id] && this.state.questionaryAnswers[this.state.sections[i].questions[j].id][2] ? 'Puls: ' + this.state.questionaryAnswers[this.state.sections[i].questions[j].id][2] + '<br/>' : '';
                                } else if (this.state.sections[i].questions[j].type == 13) {
                                    referralData += 'Patientens VAS-skalvärde är:';
                                    referralData += this.state.questionaryAnswers[this.state.sections[i].questions[j].id] && this.state.questionaryAnswers[this.state.sections[i].questions[j].id][0] ? this.state.questionaryAnswers[this.state.sections[i].questions[j].id][0] + '<br/>' : '';
                                }
                            }
                        }
                    }

                    referralData += '</p>\n';
                }
            }
        } else {
            // for (let i = 0; i < this.state.sections.length; i++) {

            //     if (this.checkSectionCondition(i)) {
            //         if (this.state.sections[i].displayOnReferral) {
            //             referralData += `<p><strong>Patient svar</strong></p>\n\n`
            //             referralData += `<p><strong>${this.state.sections[i].name}</strong></p>\n`;
            //         }
            //         referralData += '<p>\n';
            //         for (let j = 0; j < this.state.sections[i].questions.length; j++) {
            //             if (this.checkQuestionCondition(this.state.sections[i].questions[j].id) && this.state.questionaryAnswers[this.state.sections[i].questions[j].id]) {
            //                 if (this.state.sections[i].questions[j].displayOnReferral) {
            //                     if (this.state.sections[i].questions[j].type == 1) {
            //                         if (this.getAnswer(this.state.questionaryAnswers[this.state.sections[i].questions[j].id]).text)
            //                             referralData += this.getAnswer(this.state.questionaryAnswers[this.state.sections[i].questions[j].id]).text + ' ';
            //                     } else if (this.state.sections[i].questions[j].type == 2) {
            //                         if (this.state.questionaryAnswers[this.state.sections[i].questions[j].id]) {
            //                             let arr = [];
            //                             //for (let k = 0; k < this.state.questionaryAnswers[this.state.sections[i].questions[j].id].length; k++) {
            //                             for (let k = 0; k < this.state.sections[i].questions[j].answers.length; k++) {
            //                                 let check = -1;

            //                                 for (let l = 0; l < this.state.questionaryAnswers[this.state.sections[i].questions[j].id].length; l++) {
            //                                     if (this.state.sections[i].questions[j].answers[k] && this.state.questionaryAnswers[this.state.sections[i].questions[j].id][l] == this.state.sections[i].questions[j].answers[k].id) {
            //                                         check = l;
            //                                         break;
            //                                     }
            //                                 }
            //                                 // console.log('check',check);

            //                                 if (check != -1) {
            //                                     if (this.state.sections[i].questions[j].answerPrefix) {

            //                                         // if (this.state.questionaryAnswers[this.state.sections[i].questions[j].id][k].indexOf('__user-input:') == 0){
            //                                         //     arr.push(this.state.questionaryAnswers[this.state.sections[i].questions[j].id][k].replace('__user-input:', ''));
            //                                         // }else{
            //                                         if (this.getAnswer(this.state.questionaryAnswers[this.state.sections[i].questions[j].id][check]).text)
            //                                             arr.push(this.getAnswer(this.state.questionaryAnswers[this.state.sections[i].questions[j].id][check]).text);
            //                                         // }
            //                                     } else {
            //                                         if (this.getAnswer(this.state.questionaryAnswers[this.state.sections[i].questions[j].id][check]).text)
            //                                             referralData += this.getAnswer(this.state.questionaryAnswers[this.state.sections[i].questions[j].id][check]).text + ' ';
            //                                     }
            //                                 }
            //                             }

            //                             //pucalo na chat formularima
            //                             // if (this.state.sections[i].questions[j].userInput && this.indexOfIndexOf(this.state.questionaryAnswers[this.state.sections[i].questions[j].id], '__user-input:') !== -1) {
            //                             //     arr.push(this.state.questionaryAnswers[this.state.sections[i].questions[j].id][this.indexOfIndexOf(this.state.questionaryAnswers[this.state.sections[i].questions[j].id], '__user-input:')].replace('__user-input:', ''))
            //                             // }


            //                             if (arr.length && this.state.sections[i].quesitons[j].answerPrefix) {
            //                                 if (arr.length > 1) {
            //                                     let last = arr.pop();
            //                                     referralData += this.state.sections[i].questions[j].answerPrefix + ' ' + arr.join(', ') + ' och ' + last + '. ';
            //                                 } else {
            //                                     referralData += this.state.sections[i].questions[j].answerPrefix + ' ' + arr.join(', ') + '. ';
            //                                 }
            //                             }
            //                         }
            //                     } else if (this.state.sections[i].questions[j].type == 3) {
            //                         referralData += this.state.questionaryAnswers[this.state.sections[i].questions[j].id] + '. ';
            //                     } else if (this.state.sections[i].questions[j].type == 5) {
            //                         //referralData += this.state.questionaryAnswers[this.state.sections[i].questions[j].id] + '. ';
            //                         let newArr = JSON.parse(JSON.stringify(this.state.questionaryAnswers[this.state.sections[i].questions[j].id]));
            //                         let arr = [];
            //                         for (let k = 0; k < newArr.length; k++) {
            //                             if (newArr[k]) {
            //                                 arr.push(newArr[k]);
            //                             }
            //                         }
            //                         if (arr.length > 1) {
            //                             let last = arr.pop();
            //                             referralData += arr.join(', ') + ' och ' + last + '. ';
            //                         } else {
            //                             referralData += arr.join(', ') + '. ';
            //                         }

            //                     } else if (this.state.sections[i].questions[j].type == 6) {
            //                         referralData += 'Patientens längd: ' + this.state.questionaryAnswers[this.state.sections[i].questions[j].id][1] + ', ' + 'vikt: ' + this.state.questionaryAnswers[this.state.sections[i].questions[j].id][0] + ', ' + 'BMI: ' + parseFloat(this.state.questionaryAnswers[this.state.sections[i].questions[j].id] && this.state.questionaryAnswers[this.state.sections[i].questions[j].id].length ? (this.state.questionaryAnswers[this.state.sections[i].questions[j].id][0] / ((this.state.questionaryAnswers[this.state.sections[i].questions[j].id][1] / 100) * (this.state.questionaryAnswers[this.state.sections[i].questions[j].id][1] / 100))) : 0).toFixed(1) + '. ';
            //                     } else if (this.state.sections[i].questions[j].type == 7) {
            //                         referralData += `<br/><br/>
            //                         Frikortsnummer: ${this.state.questionaryAnswers[this.state.sections[i].questions[j].id] ? this.state.questionaryAnswers[this.state.sections[i].questions[j].id][0] : ''}<br/>
            //                     Giltig från: ${this.state.questionaryAnswers[this.state.sections[i].questions[j].id] ? this.state.questionaryAnswers[this.state.sections[i].questions[j].id][1] : ''}<br/>
            //                     Giltig till: ${this.state.questionaryAnswers[this.state.sections[i].questions[j].id] ? this.state.questionaryAnswers[this.state.sections[i].questions[j].id][2] : ''}<br/>
            //                     Utfärdad av: ${this.state.questionaryAnswers[this.state.sections[i].questions[j].id] ? this.state.questionaryAnswers[this.state.sections[i].questions[j].id][3] : ''}<br/><br/>
            //                     `
            //                     } else if (this.state.sections[i].questions[j].type == 8) {
            //                         referralData += `<a href='${API_ENDPOINT + this.state.questionaryAnswers[this.state.sections[i].questions[j].id]}' target="_blank"> ${this.state.sections[i].questions[j].name}</a><br/>` + ' ';
            //                     } else if (this.state.sections[i].questions[j].type == 9) {
            //                         if (this.state.questionaryAnswers[this.state.sections[i].questions[j].id] && this.state.questionaryAnswers[this.state.sections[i].questions[j].id].length) {
            //                             for (let f = 0; f < this.state.questionaryAnswers[this.state.sections[i].questions[j].id].length; f++) {
            //                                 if (this.state.questionaryAnswers[this.state.sections[i].questions[j].id][f] && this.state.questionaryAnswers[this.state.sections[i].questions[j].id][f].file)
            //                                     referralData += `<a href='${API_ENDPOINT + this.state.questionaryAnswers[this.state.sections[i].questions[j].id][f].file}' target="_blank">File/photo</a><br/>` + ' ';
            //                             }
            //                         }
            //                         // referralData += `<a href='${API_ENDPOINT + this.state.questionaryAnswers[this.state.sections[i].questions[j].id].file}' target="_blank">Bild bifogad</a><br/>` + ' ';
            //                     } else if (this.state.sections[i].questions[j].type == 12) {
            //                         referralData += 'Patientens blodtryck uppmätt till: <br/>';
            //                         referralData += this.state.questionaryAnswers[this.state.sections[i].questions[j].id] && this.state.questionaryAnswers[this.state.sections[i].questions[j].id][0] ? this.state.questionaryAnswers[this.state.sections[i].questions[j].id][0] + '/' : '';
            //                         referralData += this.state.questionaryAnswers[this.state.sections[i].questions[j].id] && this.state.questionaryAnswers[this.state.sections[i].questions[j].id][1] ? this.state.questionaryAnswers[this.state.sections[i].questions[j].id][1] + '<br/>' : '';
            //                         referralData += this.state.questionaryAnswers[this.state.sections[i].questions[j].id] && this.state.questionaryAnswers[this.state.sections[i].questions[j].id][2] ? 'Puls: ' + this.state.questionaryAnswers[this.state.sections[i].questions[j].id][2] + '<br/>' : '';
            //                     } else if (this.state.sections[i].questions[j].type == 13) {
            //                         referralData += 'Patientens VAS-skalvärde är:';
            //                         referralData += this.state.questionaryAnswers[this.state.sections[i].questions[j].id] && this.state.questionaryAnswers[this.state.sections[i].questions[j].id][0] ? this.state.questionaryAnswers[this.state.sections[i].questions[j].id][0] + '<br/>' : '';
            //                     }
            //                 }
            //             }
            //         }

            //         referralData += '</p>\n';
            //     }
            // }

        }

        let html = `
        <div style="padding: 0 30px;">
        <div style="display: flex; justify-content: space-between; align-items: center;">
        ${clinicData.logo ? `<p><img style="float: left; max-height: 80px; object-fit: contain; max-width: 220px;" src="${API_ENDPOINT + clinicData.logo}"  /></p>` : ''}
        <p style="text-align: right; float: right;"><strong>Svarsdatum:</strong> ${moment().format(this.props.dateFormat)}</p>
        </div>
<div style="clear: both; margin-top: 10px;">
    <div>${clinicData.name ? clinicData.name : ''}</div>
    <div>${clinicData.street ? clinicData.street : ''}, ${clinicData.zip ? clinicData.zip : ''} ${clinicData.city ? clinicData.city : ''}</div>
    <div>${clinicData.phone ? clinicData.phone : ''} ${clinicData.email ? clinicData.email : ''}</div>
</div>
<hr />

<div>
    <p><strong>Personuppgifter</strong></p>
</div>
<div>
    <p>${patient.socialSecurityNumber ? patient.socialSecurityNumber : ''}<br />${patient.name ? patient.name : ''}<br />${patient.street ? patient.street : ''}<br />${patient.zip ? patient.zip : ''}
        ${patient.city ? patient.city : ''}<br />${patient.phone ? patient.phone : ''}<br />${patient.email ? patient.email : ''}</p>
</div>


        </div>
        `


        return html;

    }


    sendHealthCareQuestionary = () => {




        let validationQuestionary = false;
        // if (this.state.activeQuestionary && this.state.activeQuestionary.items && !this.state.activeQuestionary?.intelligentReferral) {
        //     for (let i = 0; i < this.state.activeQuestionary.items.length; i++) {
        //         // console.log(this.state.activeQuestionary.items[i]);
        //         if (!this.state.questionaryAnswers[this.state.activeQuestionary.items[i].id] && this.state.activeQuestionary.items[i].type != 15) {
        //             // console.log("1 " + !this.state.questionaryAnswers[this.state.activeQuestionary.items[i].id])
        //             validationQuestionary = true;
        //         }
        //     }
        // } else if (this.state.activeQuestionary && this.state.activeQuestionary.items && this.state.activeQuestionary?.intelligentReferral) {
        //     validationQuestionary = this.state.validationIntelligentQuestionary;
        // }

        // this.setState({ validationQuestionary })




        if (!validationQuestionary) {
            if (typeof window != 'undefined' && localStorage?.groupAlias) {
                fetch(API_ENDPOINT + '/clinic/group/information', {
                    method: 'POST',
                    headers: {
                        'content-type': 'application/json'
                    },
                    body: JSON.stringify({ alias: localStorage?.groupAlias, clinic: this.state.conversation && this.state.conversation?.referral && this.state.conversation?.referral.clinic ? this.state.conversation?.referral.clinic : null })
                }).then(res => res.json()).then((result) => {
                    if (!result.error) {
                        this.setState({
                            activeGroup: result.clinicGroup,
                            activeClinic: result.clinic
                        }, () => {
                            let obj = {
                                messageId: this.state.questionaryMessageActive,
                                answers: this.state.questionaryAnswers,
                                healthCareId: this.state.questionaryModal,
                                referralSections: this.state.referralSections,
                                groupAlias: typeof window != 'undefined' && localStorage?.groupAlias ? localStorage?.groupAlias : null,
                                healthCareContent: this.generateReferral(),

                            }
                            // console.log(obj)

                            this.setState({ loading: true }, () => {
                                fetch(API_ENDPOINT + '/data/general-health-care/referral/new', {
                                    method: 'POST',
                                    headers: {
                                        'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage?.getItem('authToken')}` : null,
                                        'content-type': 'application/json'
                                    },
                                    body: JSON.stringify(obj)
                                }).then(res => res.json()).then((result) => {

                                    this.setState({ questionaryModal: null, settedRef: false, activeQuestionary: null, questionaryMessageActive: null, activeReferralContent: null, activeHealthCareReferralObject: null, activeClinicChatForm: null, sections: null })
                                    this.props.socket.emit('emitNewMessage', { conversation: this.state.conversation?._id })
                                })
                            })

                        })
                    }

                });
            }



        }

    }

    checkQuestionAnswered = (answer, questionType, questionId) => {

        let answerExist = false;
        if (questionType === 10) {
            return true;
        }
        if (!answer || !questionType) {
            return answerExist;
        }
        if (answer && (questionType === 1 || questionType === 3 || questionType === 14 || questionType === 16 || questionType === 17)) {
            answerExist = true;
        } else if (answer && answer.length && answer[0] && (questionType === 2 || questionType === 5 || questionType === 8 || questionType === 9 || questionType === 13)) {
            answerExist = true;
        } else if (answer && answer[0] && answer[1] && questionType === 6) {
            answerExist = true;
        } else if (answer && answer[0] && answer[1] && answer[2] && (questionType === 12)) {
            answerExist = true;
        }

        return answerExist;
    }

    findHealthCareFormular = () => {
        this.setState({ journalNote: !this.state.journalNote }, () => {
            if (this.state.journalNote) {
                this.addToJorunalNotes()
            } else {
                this.removeFromJorunalNotes()
            }
            if (this.state.journalNote) {
                if (this.state.conversation && this.state.openedJournalNotesObjects && this.state.openedJournalNotesObjects.length && this.state.openedJournalNotesObjects.filter(item => item.conversation == this.state.conversation?._id).length) {
                    let journalNoteObj;
                    for (let i = 0; i < this.state.openedJournalNotesObjects.length; i++) {
                        if (this.state.openedJournalNotesObjects[i].conversation == this.state.conversation?._id) {
                            journalNoteObj = this.state.openedJournalNotesObjects[i].journalNote;
                            this.setState({ journalNoteObj }, () => this.generatePdf())
                        }
                    }
                } else if (this.state.conversation && this.state.conversation?.journalNote && this.state.conversation?.referral && !this.state.conversation?.referral.intelligentReferral) {
                    this.setState({
                        journalNoteObj: {
                            status: this.state.conversation?.journalNote.status && this.state.conversation?.journalNote.status.replace(/&gt;/g, '>').replace(/&lt;/g, '<'),
                            anamnes: this.state.conversation?.journalNote.anamnes && this.state.conversation?.journalNote.anamnes.replace(/&gt;/g, '>').replace(/&lt;/g, '<'),
                            bedomning: this.state.conversation?.journalNote.bedomning && this.state.conversation?.journalNote.bedomning.replace(/&gt;/g, '>').replace(/&lt;/g, '<'),
                            atgard: this.state.conversation?.journalNote.atgard && this.state.conversation?.journalNote.atgard.replace(/&gt;/g, '>').replace(/&lt;/g, '<'),
                            kontaktorsak: this.state.conversation?.journalNote.kontaktorsak

                        }
                    }, () => {
                        this.generatePdf()
                    })
                } else if (this.state.conversation && this.state.conversation?.referral && !this.state.conversation?.referral.intelligentReferral) {
                    this.setState({
                        journalNoteObj: {
                            status: '',
                            anamnes: '',
                            bedomning: '',
                            atgard: '',
                            kontaktorsak: ''

                        }
                    }, () => {
                        this.generatePdf()
                    })
                }
                else if (this.state.conversation && this.state.conversation?.journalNote && !this.state.conversation?.referral) {
                    this.setState({
                        journalNoteObj: {
                            status: this.state.conversation?.journalNote.status && this.state.conversation?.journalNote.status.replace(/&gt;/g, '>').replace(/&lt;/g, '<'),
                            anamnes: this.state.conversation?.journalNote.anamnes && this.state.conversation?.journalNote.anamnes.replace(/&gt;/g, '>').replace(/&lt;/g, '<'),
                            bedomning: this.state.conversation?.journalNote.bedomning && this.state.conversation?.journalNote.bedomning.replace(/&gt;/g, '>').replace(/&lt;/g, '<'),
                            atgard: this.state.conversation?.journalNote.atgard && this.state.conversation?.journalNote.atgard.replace(/&gt;/g, '>').replace(/&lt;/g, '<'),
                            kontaktorsak: this.state.conversation?.journalNote.kontaktorsak

                        }
                    }, () => {
                        this.generatePdf()
                    })
                }

                if (this.state.conversation && this.state.conversation?.referral && this.state.conversation?.referral.intelligentReferral) {
                    if (this.state.conversation?.journalNote) {
                        let arraySections = [];
                        let journalNote = this.state.conversation?.journalNote;
                        let conversation = this.state.conversation;
                        Object.keys(journalNote).map(function (key, index) {
                            if (key != 'Kontaktorsak') {
                                let obj = {
                                    name: key,
                                    answer: journalNote[key]
                                }
                                arraySections.push(obj)
                            } else {

                                if (conversation && conversation.referral) {
                                    conversation.referral.questionaryName = journalNote[key];
                                }
                            }

                        });

                        let countOFSections = arraySections.length;
                        this.setState({ journalNoteIntelligentSections: arraySections, countOFSections, conversation }, () => {
                            if (this.state.countOFSections > 0) {
                                this.generateIntelligentPdf()
                            } else {
                                this.generatePdf()
                            }
                        })
                    } else {
                        fetch(API_ENDPOINT + '/data/personal-questionary/' + this.state.conversation?.referral.questionaryId, {
                            method: 'GET',
                            headers: {
                                'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage?.getItem('authToken')}` : null,
                                'content-type': 'application/json'
                            }
                        }).then(res => res.json()).then((result) => {

                            this.setState({
                                personalQuestionary: result,
                                sections: this.state.conversation && this.state.conversation?.referral && this.state.conversation?.referral.questionarySections,
                                questionaryAnswers: this.state.conversation && this.state.conversation?.referral && this.state.conversation?.referral.answers
                            }, () => {

                                let arraySections = [];
                                if (this.state.sections && this.state.questionaryAnswers) {
                                    let totalSubtotal = this.checkPoints();
                                    for (let i = 0; i < this.state.sections.length; i++) {
                                        let referralData = '';
                                        let obj = {};
                                        obj.name = this.state.sections[i].name;
                                        let skip = false;
                                        if (obj.name?.indexOf('Referral for ') != -1 && i == 0) {
                                            skip = true;
                                        }
                                        for (let j = 0; j < this.state.sections[i].questions.length; j++) {
                                            let answer = this.state.questionaryAnswers[this.state.sections[i].questions[j].id];
                                            let question = this.state.sections[i].questions[j];
                                            if (this.checkQuestionAnswered(answer, question.type, question.id) && !skip) {

                                                if (question.displayOnReferral || true) {
                                                    if (question.type == 1) {
                                                        if (this.getAnswer(answer).text)
                                                            referralData += this.getAnswer(answer).text + ' ';
                                                    } else if (question.type == 2) {
                                                        if (answer) {

                                                            let arr = [];

                                                            for (let k = 0; k < question.answers.length; k++) {
                                                                let check = -1;
                                                                for (let l = 0; l < answer.length; l++) {
                                                                    if (question.answers[k] && answer[l] == question.answers[k].id) {
                                                                        check = l;
                                                                        break;
                                                                    }
                                                                }
                                                                if (check != -1) {
                                                                    if (question.answerPrefix) {

                                                                        if (this.getAnswer(answer[check]).text)
                                                                            arr.push(this.getAnswer(answer[check]).text);
                                                                    } else {
                                                                        if (this.getAnswer(answer[check]).text)
                                                                            referralData += this.getAnswer(answer[check]).text + ' ';
                                                                    }
                                                                }
                                                            }

                                                            if (question.userInput && this.indexOfIndexOf(answer, '__user-input:') !== -1) {
                                                                arr.push(answer[this.indexOfIndexOf(answer, '__user-input:')].replace('__user-input:', ''))
                                                            }


                                                            if (arr.length && question.answerPrefix) {
                                                                if (arr.length > 1) {
                                                                    let last = arr.pop();
                                                                    referralData += question.answerPrefix + ' ' + arr.join(', ') + ' och '.translate(this.props.lang) + last + '. ';
                                                                } else {
                                                                    referralData += question.answerPrefix + ' ' + arr.join(', ') + '. ';
                                                                }
                                                            }
                                                        }
                                                    } else if (question.type == 3) {
                                                        referralData += question.answerPrefix ? `${question.answerPrefix} ${answer}. ` : `${answer}. `;
                                                    } else if (question.type == 5) {
                                                        let newArr = JSON.parse(JSON.stringify(answer));
                                                        let arr = [];
                                                        for (let k = 0; k < newArr.length; k++) {
                                                            if (newArr[k]) {
                                                                arr.push(newArr[k]);
                                                            }
                                                        }

                                                        if (arr.length > 1) {
                                                            let last = arr.pop();
                                                            referralData += arr.join(', ') + ' och '.translate(this.props.lang) + last + '. ';
                                                        } else {
                                                            referralData += arr.join(', ') + '. ';
                                                        }

                                                    } else if (question.type == 6) {
                                                        if (answer)
                                                            referralData += 'Patientens längd: ' + answer[1] + ', ' + 'vikt: ' + answer[0] + ', ' + 'BMI: ' + parseFloat(answer && answer.length ? (answer[0] / ((answer[1] / 100) * (answer[1] / 100))) : 0).toFixed(1) + '. ';
                                                    } else if (question.type == 9) {
                                                        if (answer && answer.length) {
                                                            for (let f = 0; f < answer.length; f++) {
                                                                if (answer[f] && answer[f].file)
                                                                    referralData += `File/photo` + ' ';
                                                            }
                                                        }
                                                    } else if (question.type == 10) {
                                                        if (question.userInput) {
                                                            referralData += "\n\n"
                                                        } else {
                                                            referralData += "\n"
                                                        }

                                                    } else if (question.type == 12) {
                                                        referralData += 'Patientens blodtryck uppmätt till: \n';
                                                        referralData += answer && answer[0] ? answer[0] + '/' : ''
                                                        referralData += answer && answer[1] ? answer[1] + '\n' : ''
                                                        referralData += answer && answer[2] ? 'Puls: ' + answer[2] + '\n' : ''
                                                    } else if (question.type == 13) {
                                                        referralData += 'Patientens VAS-skalvärde är:';
                                                        referralData += answer && answer[0] ? answer[0] + '\n' : ''
                                                    } else if (question.type == 14) {
                                                        referralData += 'Aktuell andningsfrekvens/minut:';
                                                        referralData += answer ? answer + '\n' : ''
                                                    } else if (question.type == 16) {
                                                        if (this.getAnswer(answer).response) {
                                                            referralData += '\n' + question.name + ' ' + this.getAnswer(answer).response + ` - ${(totalSubtotal && totalSubtotal[question.id] ? totalSubtotal[question.id] : 0)} ` + '\n';
                                                        }
                                                    } else if (question.type == 17) {
                                                        if (this.getAnswer(answer).response) {
                                                            referralData += '\n' + question.name + ' ' + this.getAnswer(answer).response + ` - ${(totalSubtotal && totalSubtotal[question.id] ? totalSubtotal[question.id] : 0)} ` + '\n';
                                                        }
                                                    }

                                                }
                                            }
                                        }

                                        obj.answer = this.translateReferralContent(referralData);

                                        if (!skip) {
                                            arraySections.push(obj)
                                        }

                                    }


                                }
                                let countOFSections = arraySections.length;
                                let bedomningExists = false;
                                let atgardExists = false;
                                for (let i = 0; i < arraySections.length; i++) {
                                    if (arraySections[i] && arraySections[i].name && arraySections[i].name.toLowerCase() == 'Åtgärd'.toLowerCase()) {
                                        atgardExists = true;
                                    }
                                    if (arraySections[i] && arraySections[i].name && arraySections[i].name.toLowerCase() == 'Bedömning'.toLowerCase()) {
                                        bedomningExists = true;
                                    }
                                }
                                if (!bedomningExists) {
                                    let obj = {
                                        name: 'Bedömning',
                                        answer: ''
                                    }
                                    arraySections.push(obj);
                                }
                                if (!atgardExists) {
                                    let obj = {
                                        name: 'Åtgärd',
                                        answer: ''
                                    }
                                    arraySections.push(obj);
                                }

                                this.setState({ journalNoteIntelligentSections: arraySections, countOFSections }, () => {
                                    if (this.state.countOFSections > 0) {
                                        this.generateIntelligentPdf()
                                    } else {

                                        this.generatePdf()
                                    }
                                })
                            })
                        })
                    }


                }
                let checkEventIntelligent = false;
                if (this.state.conversation && this.state.conversation?.referral && this.state.conversation?.referral.event && this.state.conversation?.referral.revisitFormsArray && this.state.conversation?.referral.event.journalNoteFormSelected) {
                    if (this.state.conversation?.referral.revisitFormsArray.filter(el => el.revisitQuestionaryId == this.state.conversation?.referral.event.journalNoteFormSelected).length) {
                        checkEventIntelligent = this.state.conversation?.referral.revisitFormsArray.filter(el => el.revisitQuestionaryId == this.state.conversation?.referral.event.journalNoteFormSelected)[0];
                    }
                }
                if (checkEventIntelligent) {
                    if (this.state.conversation?.journalNote) {
                        let arraySections = [];
                        let journalNote = this.state.conversation?.journalNote;
                        let conversation = this.state.conversation;
                        Object.keys(journalNote).map(function (key, index) {
                            if (key != 'Kontaktorsak') {
                                let obj = {
                                    name: key,
                                    answer: journalNote[key]
                                }
                                arraySections.push(obj)
                            } else {

                                if (conversation && conversation.referral) {
                                    conversation.referral.questionaryName = journalNote[key];
                                }
                            }

                        });

                        let countOFSections = arraySections.length;
                        this.setState({ journalNoteIntelligentSections: arraySections, countOFSections, conversation }, () => {
                            if (this.state.countOFSections > 0) {
                                this.generateIntelligentPdf()
                            } else {
                                this.generatePdf()
                            }
                        })
                    } else {
                        fetch(API_ENDPOINT + '/data/revisit-questionary/' + checkEventIntelligent.revisitQuestionaryId, {
                            method: 'GET',
                            headers: {
                                'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage?.getItem('authToken')}` : null,
                                'content-type': 'application/json'
                            }
                        }).then(res => res.json()).then((result) => {
                            this.setState({
                                personalQuestionary: result,
                                sections: checkEventIntelligent.questionarySections,
                                questionaryAnswers: checkEventIntelligent.answers
                            }, () => {
                                let arraySections = [];
                                if (this.state.sections && this.state.questionaryAnswers) {
                                    let totalSubtotal = this.checkPoints();
                                    for (let i = 0; i < this.state.sections.length; i++) {
                                        let referralData = '';
                                        let obj = {};
                                        obj.name = this.state.sections[i].name;
                                        let skip = false;
                                        if (obj.name?.indexOf('Referral for ') != -1 && i == 0) {
                                            skip = true;
                                        }
                                        referralData += '';
                                        for (let j = 0; j < this.state.sections[i].questions.length; j++) {
                                            let answer = this.state.questionaryAnswers[this.state.sections[i].questions[j].id];
                                            let question = this.state.sections[i].questions[j];

                                            if (this.checkQuestionAnswered(answer, question.type, question.id) && !skip) {

                                                if (question.displayOnReferral || true) {
                                                    if (question.type == 1) {
                                                        if (this.getAnswer(answer).text)
                                                            referralData += this.getAnswer(answer).text + ' ';
                                                    } else if (question.type == 2) {
                                                        if (answer) {

                                                            let arr = [];

                                                            for (let k = 0; k < question.answers.length; k++) {
                                                                let check = -1;
                                                                for (let l = 0; l < answer.length; l++) {
                                                                    if (question.answers[k] && answer[l] == question.answers[k].id) {
                                                                        check = l;
                                                                        break;
                                                                    }
                                                                }
                                                                if (check != -1) {
                                                                    if (question.answerPrefix) {

                                                                        if (this.getAnswer(answer[check]).text)
                                                                            arr.push(this.getAnswer(answer[check]).text);
                                                                    } else {
                                                                        if (this.getAnswer(answer[check]).text)
                                                                            referralData += this.getAnswer(answer[check]).text + ' ';
                                                                    }
                                                                }
                                                            }

                                                            if (question.userInput && this.indexOfIndexOf(answer, '__user-input:') !== -1) {
                                                                arr.push(answer[this.indexOfIndexOf(answer, '__user-input:')].replace('__user-input:', ''))
                                                            }


                                                            if (arr.length && question.answerPrefix) {
                                                                if (arr.length > 1) {
                                                                    let last = arr.pop();
                                                                    referralData += question.answerPrefix + ' ' + arr.join(', ') + ' och '.translate(this.props.lang) + last + '. ';
                                                                } else {
                                                                    referralData += question.answerPrefix + ' ' + arr.join(', ') + '. ';
                                                                }
                                                            }
                                                        }
                                                    } else if (question.type == 3) {
                                                        // referralData += answer + '. ';
                                                        referralData += question.answerPrefix ? `${question.answerPrefix} ${answer}. ` : `${answer}. `;
                                                    } else if (question.type == 5) {
                                                        let newArr = JSON.parse(JSON.stringify(answer));
                                                        let arr = [];
                                                        for (let k = 0; k < newArr.length; k++) {
                                                            if (newArr[k]) {
                                                                arr.push(newArr[k]);
                                                            }
                                                        }

                                                        if (arr.length > 1) {
                                                            let last = arr.pop();
                                                            referralData += arr.join(', ') + ' och '.translate(this.props.lang) + last + '. ';
                                                        } else {
                                                            referralData += arr.join(', ') + '. ';
                                                        }

                                                    } else if (question.type == 6) {
                                                        if (answer)
                                                            referralData += 'Patientens längd: ' + answer[1] + ', ' + 'vikt: ' + answer[0] + ', ' + 'BMI: ' + parseFloat(answer && answer.length ? (answer[0] / ((answer[1] / 100) * (answer[1] / 100))) : 0).toFixed(1) + '. ';
                                                    } else if (question.type == 9) {
                                                        if (answer && answer.length) {
                                                            for (let f = 0; f < answer.length; f++) {
                                                                if (answer[f] && answer[f].file)
                                                                    // referralData += `<a href='${API_ENDPOINT + answer[f].file}' target="_blank">File/photo</a><br/>` + ' ';
                                                                    referralData += `File/photo`;
                                                            }
                                                        }
                                                    } else if (question.type == 10) {
                                                        if (question.userInput) {
                                                            referralData += "\n\n"
                                                        } else {
                                                            referralData += "\n"
                                                        }

                                                    } else if (question.type == 12) {
                                                        referralData += 'Patientens blodtryck uppmätt till: \n';
                                                        referralData += answer && answer[0] ? answer[0] + '/' : ''
                                                        referralData += answer && answer[1] ? answer[1] + '\n' : ''
                                                        referralData += answer && answer[2] ? 'Puls: ' + answer[2] + '\n' : ''
                                                    } else if (question.type == 13) {
                                                        referralData += 'Patientens VAS-skalvärde är:';
                                                        referralData += answer && answer[0] ? answer[0] + '\n' : ''
                                                    } else if (question.type == 14) {
                                                        referralData += 'Aktuell andningsfrekvens/minut:';
                                                        referralData += answer ? answer + '\n' : ''
                                                    } else if (question.type == 16) {
                                                        if (this.getAnswer(answer).response) {
                                                            referralData += '\n' + question.name + ' ' + this.getAnswer(answer).response + ` - ${(totalSubtotal && totalSubtotal[question.id] ? totalSubtotal[question.id] : 0)} ` + '\n';
                                                        }
                                                    } else if (question.type == 17) {
                                                        if (this.getAnswer(answer).response) {
                                                            referralData += '\n' + question.name + ' ' + this.getAnswer(answer).response + ` - ${(totalSubtotal && totalSubtotal[question.id] ? totalSubtotal[question.id] : 0)} ` + '\n';
                                                        }
                                                    }

                                                }
                                            }
                                            // referralData += '</p>';
                                        }


                                        // obj.answer = striptags(referralData);
                                        obj.answer = this.translateReferralContent(referralData);

                                        if (!skip) {
                                            arraySections.push(obj)
                                        }

                                    }


                                }
                                let countOFSections = arraySections.length;
                                let bedomningExists = false;
                                let atgardExists = false;
                                for (let i = 0; i < arraySections.length; i++) {
                                    if (arraySections[i] && arraySections[i].name && arraySections[i].name.toLowerCase() == 'Åtgärd'.toLowerCase()) {
                                        atgardExists = true;
                                    }
                                    if (arraySections[i] && arraySections[i].name && arraySections[i].name.toLowerCase() == 'Bedömning'.toLowerCase()) {
                                        bedomningExists = true;
                                    }
                                }
                                if (!bedomningExists) {
                                    let obj = {
                                        name: 'Bedömning',
                                        answer: ''
                                    }
                                    arraySections.push(obj);
                                }
                                if (!atgardExists) {
                                    let obj = {
                                        name: 'Åtgärd',
                                        answer: ''
                                    }
                                    arraySections.push(obj);
                                }
                                if (!countOFSections) {
                                    arraySections = [
                                        { name: 'Status', answer: '' },
                                        { name: 'Anamnes', answer: '' },
                                        { name: 'Bedömning', answer: '' },
                                        { name: 'Åtgärd', answer: '' },
                                    ];
                                    countOFSections = arraySections.length;
                                }

                                this.setState({ journalNoteIntelligentSections: arraySections, countOFSections }, () => {

                                    if (this.state.countOFSections > 0) {
                                        this.generateIntelligentPdf()
                                    } else {

                                        this.generatePdf()
                                    }
                                })
                            })
                        })
                    }


                }

            }
        })

    }
    translateReferralContent = (referralContent) => {
        let lang = this.props.lang;
        let returnValue = referralContent;
        returnValue = referralContent
            .replace(/Sökdatum/g, 'Sökdatum'.translate(lang))
            .replace(/Ärende-ID/g, 'Ärende-ID'.translate(lang))
            .replace('Personuppgifter', 'Personuppgifter'.translate(lang))
            .replace('Patientens längd', 'Patientens längd'.translate(lang))
            .replace('vikt', 'vikt'.translate(lang))
            .replace('BMI', 'BMI'.translate(lang))
            .replace('Frikortsnummer', 'Frikortsnummer'.translate(lang))
            .replace('Giltig från', 'Giltig från'.translate(lang))
            .replace('Giltig till', 'Giltig till'.translate(lang))
            .replace('Utfärdad av', 'Utfärdad av'.translate(lang))
            .replace(/File\/photo/g, 'File/photo'.translate(lang))
            .replace('Patientens blodtryck uppmätt till', 'Patientens blodtryck uppmätt till'.translate(lang))
            .replace(/Patientens VAS-skalvärde är/g, 'Patientens VAS-skalvärde är'.translate(lang))
            .replace('Aktuell andningsfrekvens/minut', 'Aktuell andningsfrekvens/minut'.translate(lang))
            .replace('Vårdnadshavare', 'Vårdnadshavare'.translate(lang))
            .replace('Samtycke', 'Samtycke'.translate(lang))
            .replace('Free kort number', 'Free kort number'.translate(lang))
            .replace('Valid from', 'Valid from'.translate(lang))
            .replace('Valid to', 'Valid to'.translate(lang))
            .replace('Issued at', 'Issued at'.translate(lang))
            .replace('Paid on', 'Paid on'.translate(lang))
            .replace('Visit date', 'Visit date'.translate(lang))
            .replace('Kvitto nr', 'Kvitto nr'.translate(lang))
            .replace('Referens', 'Referens'.translate(lang))
            .replace('Betalningssätt', 'Betalningssätt'.translate(lang))
            .replace('Specifikation', 'Specifikation'.translate(lang))
            .replace('Anledning till besök', 'Anledning till besök'.translate(lang))
            .replace('Patient svar', 'Patient svar'.translate(lang))
        return returnValue;
    }
    generateIntelligentPdf = () => {
        let journalNote = {
            Kontaktorsak: this.state.conversation && this.state.conversation?.referral && this.state.conversation?.referral.questionaryName,
        }
        let textClipboard = `Kontaktorsak: `
        textClipboard += `${this.state.conversation && this.state.conversation?.referral && this.state.conversation?.referral.questionaryName}`;

        for (let i = 0; i < this.state.journalNoteIntelligentSections.length; i++) {
            textClipboard += `\n\n`;
            if (this.state.journalNoteIntelligentSections[i].name) {
                textClipboard += `${this.state.journalNoteIntelligentSections[i].name}:`;
            }
            if (this.state.journalNoteIntelligentSections[i].answer) {
                let answer = this.state.journalNoteIntelligentSections[i].answer;
                textClipboard += `\n${answer}`;
            } else {
                // textClipboard += `\n`;
            }
            if (this.state.journalNoteIntelligentSections[i].name) {
                journalNote[this.state.journalNoteIntelligentSections[i].name] = '';
                if (this.state.journalNoteIntelligentSections[i].answer) {
                    journalNote[this.state.journalNoteIntelligentSections[i].name] = (this.state.journalNoteIntelligentSections[i].answer);
                }
            }


        }

        // textClipboard = striptags(textClipboard)


        let html = `
                    <div>
                        <div>
                            <h1>${'Journal note'.translate('se')}</h1>
                        </div>
                        <div style='margin-bottom: 10px'>
                            <h5 style='font-size: 24px; font-weight: 600'>Kontaktorsak:</h5>
                            <h6 style='font-size: 20px; font-weight: 400'>${this.state.conversation && this.state.conversation?.referral && this.state.conversation?.referral.questionaryName}</h6>
                        </div>
                       
    `;

        for (let i = 0; i < this.state.journalNoteIntelligentSections.length; i++) {
            if (this.state.journalNoteIntelligentSections[i].name) {
                html += `<div style='margin-bottom: 10px'><h5 style='font-size: 24px; font-weight: 600'>${this.state.journalNoteIntelligentSections[i].name.replace(/>/g, '&gt;').replace(/</g, '&lt;')}:</h5>`;
            }
            if (this.state.journalNoteIntelligentSections[i].answer) {
                let answer = this.state.journalNoteIntelligentSections[i].answer.replace(/>/g, '&gt;').replace(/</g, '&lt;').replace(/\n/g, '<br/>');
                html += `<h6 style='font-size: 20px; font-weight: 400'>${answer}</h6></div>`;
            }

        }
        html += `</div>`;



        this.updateJournalNote(journalNote)

        this.setState({ htmlJournal: html, textClipboard })
    }
    generatePdf = () => {
        let kontaktorsak = this.state.journalNoteObj && this.state.journalNoteObj.kontaktorsak ? this.state.journalNoteObj.kontaktorsak : this.state.conversation && this.state.conversation?.referral && this.state.conversation?.referral.questionaryName ? this.state.conversation?.referral.questionaryName : '';
        if (this.state.conversation && this.state.conversation?.referral && this.state.conversation?.referral.event && this.state.conversation?.referral.event.journalNoteFormSelected && this.state.conversation?.referral.event.revisitFormsArray && !(this.state.journalNoteObj && this.state.journalNoteObj.kontaktorsak)) {
            let event = this.state.conversation?.referral.event;
            if (event.revisitFormsArray && event.revisitFormsArray.length && event.revisitFormsArray.filter(el => el.revisitQuestionaryId == event.journalNoteFormSelected && el.name).length) {
                kontaktorsak = event.revisitFormsArray.filter(el => el.revisitQuestionaryId == event.journalNoteFormSelected && el.name)[0].name;
            }
        }

        let textClipboard = `Kontaktorsak:
${kontaktorsak}
Anamnes
${this.state.journalNoteObj && this.state.journalNoteObj.anamnes}
Status
${this.state.journalNoteObj && this.state.journalNoteObj.status}
Bedömning
${this.state.journalNoteObj && this.state.journalNoteObj.bedomning}
Åtgärd
${this.state.journalNoteObj && this.state.journalNoteObj.atgard}
`;
        let html = `
            <div>
                <div>
                    <h1>${'Journal note'.translate('se')}</h1>
                </div>
                <div style='margin-bottom: 10px'>
                    <h5 style='font-size: 24px; font-weight: 600'>Kontaktorsak:</h5>
                    <h6 style='font-size: 20px; font-weight: 400'>${kontaktorsak.replace(/>/g, '&gt;').replace(/</g, '&lt;')}</h6>
                </div>
                <div style='margin-bottom: 10px'>
                    <h5 style='font-size: 24px; font-weight: 600'>Anamnes:</h5>
                    <h6 style='font-size: 20px; font-weight: 400'>${this.state.journalNoteObj && this.state.journalNoteObj.anamnes && this.state.journalNoteObj.anamnes.replace(/>/g, '&gt;').replace(/</g, '&lt;').replace(/\n/g, '<br/>')}</h6>
                </div>
                <div style='margin-bottom: 10px'>
                    <h5 style='font-size: 24px; font-weight: 600'>Status:</h5>
                    <h6 style='font-size: 20px; font-weight: 400'>${this.state.journalNoteObj && this.state.journalNoteObj.status && this.state.journalNoteObj.status.replace(/>/g, '&gt;').replace(/</g, '&lt;').replace(/\n/g, '<br/>')}</h6>
                </div>
                <div style='margin-bottom: 10px'>
                    <h5 style='font-size: 24px; font-weight: 600'>Bedömning:</h5>
                    <h6 style='font-size: 20px; font-weight: 400'>${this.state.journalNoteObj && this.state.journalNoteObj.bedomning && this.state.journalNoteObj.bedomning.replace(/>/g, '&gt;').replace(/</g, '&lt;').replace(/\n/g, '<br/>')}</h6>
                </div>
                <div style='margin-bottom: 10px'>
                    <h5 style='font-size: 24px; font-weight: 600'>Åtgärd:</h5>
                    <h6 style='font-size: 20px; font-weight: 400'>${this.state.journalNoteObj && this.state.journalNoteObj.atgard && this.state.journalNoteObj.atgard.replace(/>/g, '&gt;').replace(/</g, '&lt;').replace(/\n/g, '<br/>')}</h6>
                </div>               
            </div>
        `;

        let journalNote = {
            kontaktorsak: kontaktorsak,
            status: this.state.journalNoteObj && this.state.journalNoteObj.status,
            anamnes: this.state.journalNoteObj && this.state.journalNoteObj.anamnes,
            bedomning: this.state.journalNoteObj && this.state.journalNoteObj.bedomning,
            atgard: this.state.journalNoteObj && this.state.journalNoteObj.atgard
        }
        if (this.state.conversation && this.state.conversation?.referral && this.state.conversation?.referral.intelligentReferral) {
            journalNote = {
                Kontaktorsak: kontaktorsak,
                Status: this.state.journalNoteObj && this.state.journalNoteObj.status,
                Anamnes: this.state.journalNoteObj && this.state.journalNoteObj.anamnes,
                Bedömning: this.state.journalNoteObj && this.state.journalNoteObj.bedomning,
                Åtgärd: this.state.journalNoteObj && this.state.journalNoteObj.atgard
            }
        }

        this.updateJournalNote(journalNote)

        this.setState({ htmlJournal: html, textClipboard })
    }
    generatePdfName = () => {
        let name = 'journal_note';

        if (this.state.conversation && this.state.conversation?.referral && this.state.conversation?.referral.questionaryName) {
            name += `_${this.state.conversation?.referral.questionaryName.toLowerCase()}`;
        }
        if (this.state.conversation && this.state.conversation?.referral && this.state.conversation?.referral.patient && this.state.conversation?.referral.patient.name) {
            let patientName = this.state.conversation?.referral.patient.name.toLowerCase().replace(' ', '_');
            name += `_${patientName}`;
        }
        name += '.pdf';

        return name;
    }


    leaveChat = (conversation) => {

        this.props.socket.emit('removeMember', { conversation: conversation?._id, member: this.props.uData?._id });
        if (this.props[0].match.path == '/chat/:windowConversation') {
            if (typeof window != 'undefined') {
                if (window && window.top) {
                    window.top.postMessage('chat-closed', '*')
                }

            }
        }
        this.setState({ conversation: null, referral: null })

    }
    deleteMessage = (message) => {
        const userId = this.props.uData?._id;

        this.props.socket.emit('deleteMessage', { id: message?._id, conversation: this.state.conversation?._id, userId: userId });

        this.setState({ deleteMessageModal: null })
    }
    deleteConversation = (conversation) => {
        const userId = this.props.uData?._id;
        this.props.socket.emit('deleteConversation', { conversation: conversation?._id, userId: userId });
        this.setState({ conversation: null, referral: null })
    }
    sendInvitations = () => {

        let timeInvationHours = this.state.shareLinkTime.getHours() < 10 ? '0' + this.state.shareLinkTime.getHours() : this.state.shareLinkTime.getHours()
        let timeInvationMinutes = this.state.shareLinkTime.getMinutes() < 10 ? '0' + this.state.shareLinkTime.getMinutes() : this.state.shareLinkTime.getMinutes()
        let timeInvation = timeInvationHours + ':' + timeInvationMinutes
        let scheduleDate = new Date(this.state.shareLinkDate + ' ' + timeInvation);
        let scheduleDateTs = Math.floor(scheduleDate.getTime() / 1000)
        let timeNow = new Date();
        let timeNowTs = Math.floor(timeNow.getTime() / 1000);
        if (scheduleDateTs < timeNowTs) {
            this.setState({
                error: "You can not pick date/time form the past.".translate(this.props.lang)
            })

        } else {
            let listOfInvitaions = [];
            if (this.state.shareModalFields && this.state.shareModalFields.invitedUsers && this.state.shareModalFields.invitedUsers.length) {
                for (let i = 0; i < this.state.shareModalFields.invitedUsers.length; i++) {
                    // if (this.state.shareModalFields.input && this.state.shareModalFields[i].input != '') {
                    listOfInvitaions.push(this.state.shareModalFields.invitedUsers[i])
                    // }
                }
            }

            fetch(API_ENDPOINT + '/users/chat/send/invitations', {
                method: 'POST',
                headers: {
                    'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage?.getItem('authToken')}` : null,
                    'content-type': 'application/json'
                },
                body: JSON.stringify({
                    listOfInvitaions: listOfInvitaions,
                    link: this.state.shareModal,
                    group: this.props.selectedGroup,
                    lang: this.props.lang,
                    conversation: this.state.conversation && this.state.conversation?._id,
                    date: this.state.shareLinkDate,
                    time: timeInvation,
                })
            }).then(res => res.json()).then((result) => {
                let shareModalFields = [{ input: '' }];
                // this.props.socket.emit('conversations', { profile: typeof window != 'undefined' ? localStorage?.getItem?.('profile') : null, conversationsType: this.state.conversationsType, windowConversation: ((this.props[0].match.params && this.props[0].match.params.windowConversation) || (this.props[0].match.params && this.props[0].match.params.conversation)) });
                // console.log('EMIT_CONVERSATIONS 19');
                this.emitConversations();
                let successMessage = '';
                if (result && result.invitedContacts) {
                    for (let i = 0; i < result.invitedContacts.length; i++) {
                        successMessage += result.invitedContacts[i].contact + ' ';
                        // if (result.invitedContacts[i].type == 'sms') {
                        //     successMessage += `${'SMS'.translate(this.props.lang)}: ${result.invitedContacts[i].contact}\n`;
                        // } else if (result.invitedContacts[i].type == 'email') {
                        //     successMessage += `${'Email'.translate(this.props.lang)}: ${result.invitedContacts[i].contact}\n`
                        // }
                    }
                }
                this.setState({
                    shareModal: null,
                    inviteError: null,
                    shareModalFields,
                    invitedContactsSucces: result.invitedContacts && result.invitedContacts.length ? result.invitedContacts : null,
                    successMessage: result.invitedContacts && result.invitedContacts.length ? successMessage : null,
                    shareLinkDate: this.props.getStringDateTs(Math.floor(new Date().getTime() / 1000), this.props.dateFormat)/*moment.unix(Math.floor(new Date().getTime() / 1000)).format(this.props.dateFormat)*/,
                    shareLinkTime: moment(new Date())
                })

            })
        }



    }
    updateSentInvitationsList = (idx) => {
        if (this.state.conversation && this.state.conversation?.invitedContacts && this.state.conversation?.invitedContacts.length) {
            let newList = [];
            for (let i = 0; i < this.state.conversation?.invitedContacts.length; i++) {
                if (i != idx) {
                    newList.push(this.state.conversation?.invitedContacts[i])
                }
            }

            this.props.socket.emit('removeInvitedContacts', { conversation: this.state.conversation?._id, newList: newList });
        }

    }
    closeReferral = (data) => {
        if (!data) data = {};
        data.selectedGroup = this.props.selectedGroup;
        data.closeConversation = true;

        this.setState({
            loading: true
        }, () => {
            fetch(API_ENDPOINT + '/referrals/close/' + this.state.referral._id, {
                method: 'POST',
                headers: {
                    'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage.getItem('authToken')}` : null,
                    'content-type': 'application/json'
                },
                body: JSON.stringify(data)
            }).then(res => res.json()).then((result) => {
                // this.props.socket.emit('conversations', { profile: typeof window != 'undefined' ? localStorage?.getItem?.('profile') : null, conversationsType: this.state.conversationsType, windowConversation: ((this.props[0].match.params && this.props[0].match.params.windowConversation) || (this.props[0].match.params && this.props[0].match.params.conversation)) });
                if (result?.error) {
                    this.setState({
                        error: result.error?.translate(this.props.lang),
                        loading: false
                    })
                } else {
                    // console.log('EMIT_CONVERSATIONS 20');
                    this.emitConversations();

                    if (this.state.referral?._id && this.state.conversations) {
                        let conversations = this.state.conversations;
                        let conversation = this.state.conversation;
                        if (conversation && conversation.referral && conversation.referral?._id == this.state.referral?._id) {
                            conversation.referral.status = 'closed';
                        }
                        let index = this.state.conversations.findIndex(el => el.referral && el.referral?._id == this.state.referral?._id)
                        if (index > -1) {
                            conversations[index].referral.status = 'closed';
                        }
                        this.setState({ conversation, conversations })
                    }

                    // this.get();
                    this.setState({
                        referral: null,

                    })
                }


            })
        })
    }
    // closeReferral = () => {
    //     this.setState({
    //         loading: true
    //     }, () => {
    //         fetch(API_ENDPOINT + '/referrals/close/' + this.state.referral?._id, {
    //             method: 'POST',
    //             headers: {
    //                 'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage?.getItem('authToken')}` : null,
    //                 'content-type': 'application/json'
    //             },
    //             body: JSON.stringify({})
    //         }).then(res => res.json()).then((result) => {
    //             // this.props.socket.emit('conversations', { profile: typeof window != 'undefined' ? localStorage?.getItem?.('profile') : null, conversationsType: this.state.conversationsType, windowConversation: ((this.props[0].match.params && this.props[0].match.params.windowConversation) || (this.props[0].match.params && this.props[0].match.params.conversation)) });
    //             this.emitConversations();

    //             if (this.state.referral?._id && this.state.conversations) {
    //                 let conversations = this.state.conversations;
    //                 let conversation = this.state.conversation;
    //                 if (conversation && conversation.referral && conversation.referral?._id == this.state.referral?._id) {
    //                     conversation.referral.status = 'closed';
    //                 }
    //                 let index = this.state.conversations.findIndex(el => el.referral && el.referral?._id == this.state.referral?._id)
    //                 if (index > -1) {
    //                     conversations[index].referral.status = 'closed';
    //                 }
    //                 this.setState({ conversation, conversations })
    //             }

    //             // this.get();
    //             this.setState({
    //                 referral: null,

    //             })
    //         })
    //     })
    // }
    exportToJournalSystem = async (uuid) => {
        if (this.state.conversation && this.state.conversation?._id) {

            let response = await fetch(API_ENDPOINT + '/referrals/journal-note/export/journal-system', {
                method: 'POST',
                headers: {
                    'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage?.getItem('authToken')}` : null,
                    'content-type': 'application/json'
                },
                body: JSON.stringify({
                    conversation: this.state.conversation?._id,
                    uuid: uuid
                })
            })
            let result = await response.json();
            if (result && result.error) {
                this.setState({ exportToJournalSystemError: result.error, loadingJournalNoteExport: null })
            } else if (result) {
                this.setState({ exportToJournalSystemSuccessfully: 'You have successfully exported the journal note!', loadingJournalNoteExport: null })
            }

        } else {
            if (this.state.loadingJournalNoteExport)
                this.setState({ loadingJournalNoteExport: false })
        }


    }



    checkJournalNoteSections = () => {
        let returnValue = {
            exists: false,
            obj: {}
        };
        if (this.state.conversation && this.state.conversation?.referral && this.state.conversation?.referral.intelligentReferral && this.state.journalNote) {
            let obj = {
                status: '',
                anamnes: '',
                bedomning: '',
                atgard: ''
            }
            if (this.state.journalNoteIntelligentSections && this.state.journalNoteIntelligentSections.length) {
                for (let i = 0; i < this.state.journalNoteIntelligentSections.length; i++) {
                    if (this.state.journalNoteIntelligentSections[i].name && this.state.journalNoteIntelligentSections[i].name.toLowerCase() == 'status' && this.state.journalNoteIntelligentSections[i].answer) {
                        obj.status = this.state.journalNoteIntelligentSections[i].answer;
                        if (obj.status && !returnValue.exists) {
                            returnValue.exists = true;
                        }
                    }
                    if (this.state.journalNoteIntelligentSections[i].name && this.state.journalNoteIntelligentSections[i].name.toLowerCase() == 'anamnes' && this.state.journalNoteIntelligentSections[i].answer) {
                        obj.anamnes = this.state.journalNoteIntelligentSections[i].answer;
                        if (obj.anamnes && !returnValue.exists) {
                            returnValue.exists = true;
                        }
                    }
                    if (this.state.journalNoteIntelligentSections[i].name && this.state.journalNoteIntelligentSections[i].name.toLowerCase() == 'bedömning' && this.state.journalNoteIntelligentSections[i].answer) {
                        obj.bedomning = this.state.journalNoteIntelligentSections[i].answer;
                        if (obj.bedomning && !returnValue.exists) {
                            returnValue.exists = true;
                        }
                    }
                    if (this.state.journalNoteIntelligentSections[i].name && this.state.journalNoteIntelligentSections[i].name.toLowerCase() == 'åtgärd' && this.state.journalNoteIntelligentSections[i].answer) {
                        obj.atgard = this.state.journalNoteIntelligentSections[i].answer;
                        if (obj.atgard && !returnValue.exists) {
                            returnValue.exists = true;
                        }
                    }
                }
            }
            returnValue.obj = obj;
        } else if (this.state.conversation && this.state.conversation?.referral && this.state.journalNote) {
            let checkEventIntelligent = false;
            if (this.state.conversation && this.state.conversation?.referral && this.state.conversation?.referral.event && this.state.conversation?.referral.revisitFormsArray && this.state.conversation?.referral.event.journalNoteFormSelected) {
                if (this.state.conversation?.referral.revisitFormsArray.filter(el => el.revisitQuestionaryId == this.state.conversation?.referral.event.journalNoteFormSelected).length) {
                    checkEventIntelligent = this.state.conversation?.referral.revisitFormsArray.filter(el => el.revisitQuestionaryId == this.state.conversation?.referral.event.journalNoteFormSelected)[0];
                }
            }
            if (checkEventIntelligent) {
                let obj = {
                    status: '',
                    anamnes: '',
                    bedomning: '',
                    atgard: ''
                }
                if (this.state.journalNoteIntelligentSections && this.state.journalNoteIntelligentSections.length) {
                    for (let i = 0; i < this.state.journalNoteIntelligentSections.length; i++) {
                        if (this.state.journalNoteIntelligentSections[i].name && this.state.journalNoteIntelligentSections[i].name.toLowerCase() == 'status' && this.state.journalNoteIntelligentSections[i].answer) {
                            obj.status = this.state.journalNoteIntelligentSections[i].answer;
                            if (obj.status && !returnValue.exists) {
                                returnValue.exists = true;
                            }
                        }
                        if (this.state.journalNoteIntelligentSections[i].name && this.state.journalNoteIntelligentSections[i].name.toLowerCase() == 'anamnes' && this.state.journalNoteIntelligentSections[i].answer) {
                            obj.anamnes = this.state.journalNoteIntelligentSections[i].answer;
                            if (obj.anamnes && !returnValue.exists) {
                                returnValue.exists = true;
                            }
                        }
                        if (this.state.journalNoteIntelligentSections[i].name && this.state.journalNoteIntelligentSections[i].name.toLowerCase() == 'bedömning' && this.state.journalNoteIntelligentSections[i].answer) {
                            obj.bedomning = this.state.journalNoteIntelligentSections[i].answer;
                            if (obj.bedomning && !returnValue.exists) {
                                returnValue.exists = true;
                            }
                        }
                        if (this.state.journalNoteIntelligentSections[i].name && this.state.journalNoteIntelligentSections[i].name.toLowerCase() == 'åtgärd' && this.state.journalNoteIntelligentSections[i].answer) {
                            obj.atgard = this.state.journalNoteIntelligentSections[i].answer;
                            if (obj.atgard && !returnValue.exists) {
                                returnValue.exists = true;
                            }
                        }
                    }
                }
                returnValue.obj = obj;
            }


        }

        return returnValue.exists;
    }

    checkHistoryIndicator = () => {
        let group;
        let clinic;
        if (this.props.uData && typeof window != 'undefined') {
            if (this.props.uData?.userLevel == 1 || this.props.uData?.userLevel == 2) {
                group = localStorage?.groupAlias;
            } else if (this.props.uData?.userLevel == 20 && localStorage?.getItem?.('profile') == 'patient') {
                group = localStorage?.groupAlias;
            } else if (this.props.uData?.userLevel == 20 && localStorage?.getItem?.('profile') == 'doctor') {
                group = this.props.selectedGroup;
                clinic = this.props.selectedClinic;
            }
        }

        this.props.socket.emit('historyIndicator', { profile: typeof window != 'undefined' ? localStorage?.getItem?.('profile') : null, group: group, clinic: clinic });
    }
    checkConversations = () => {
        let arr = [];
        if (this.state.conversations && this.state.conversations.length) {
            arr = this.state.conversations.filter((item, idx) => {

                // if (this.checkSearch(item)) {

                // if (!this.state.closedConversations || (this.state.closedConversations.filter(conv => conv == item?._id).length == 0 && this.state.conversationsType == 0) || this.state.conversationsType == 1)
                if (localStorage?.getItem?.('profile') && localStorage?.getItem('profile') == 'patient' && item.groupAlias && localStorage?.groupAlias && item.groupAlias.indexOf(localStorage?.getItem('groupAlias')) != -1) {

                    if (((item.groupAlias && localStorage?.groupAlias && item.groupAlias.indexOf(localStorage?.getItem('groupAlias')) != -1) || (this.props.selectedGroup && item.groupIds && item.groupIds.indexOf(this.props.selectedGroup) !== -1)))
                        if (!item.chatOneOnOne || (item.chatOneOnOne && item.messagesExist))
                            return item;
                } else if (localStorage?.getItem('profile') != 'patient')
                    if (((!this.props.selectedGroup && item.groupAlias && localStorage?.groupAlias && item.groupAlias.indexOf(localStorage?.getItem('groupAlias')) != -1) || (this.props.selectedGroup && item.groupIds && item.groupIds.indexOf(this.props.selectedGroup) !== -1))) {
                        if (!item.chatOneOnOne || (item.chatOneOnOne && item.messagesExist))
                            return item;
                    }
                // }

            })
        }


        if (arr && arr.length) {
            let items = [];
            let page = this.state.conversationPage ? this.state.conversationPage : 0;
            let entries = 20;
            items = arr.slice(0, (page * entries + entries))
            return {
                items: items,
                total: arr.length
            };
        } else {
            return {
                items: [],
                total: 0
            };
        }

    }

    searchPatientWithRedirect = (SSN, enterprise) => {
        let obj = {
            socialSecurityNumber: SSN,
            group: this.props.selectedGroup,
            clinic: this.props.selectedClinic,
        }
        enterprise && (obj.openOnWebDoc = true);

        fetch(API_ENDPOINT + '/users/find/patient', {
            method: 'POST',
            headers: {
                'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage?.getItem('authToken')}` : null,
                'content-type': 'application/json'
            },
            body: JSON.stringify(obj)
        }).then(res => res.json()).then((result) => {

            if (result && result?._id) {
                const path = enterprise ? '/patients/search-enterprise' : '/patients/search';
                this.props.registerClinicGroupLogs({
                    type: 'patientProfile',
                    patient: SSN,
                });

                let state = {
                    TYPE: 'redirect',
                    redirectPatient: result,
                    redirectTabIndex: 1
                }
                enterprise && (state.redirectTabActive = 1);

                this.props[0].history.push(path, state);

            } else {
                this.setState({ error: 'User not found in this clinic group' })
            }
        })
    }

    getConversations = () => {
        let memebersDict = {};

        if (this.state.conversation && this.state.conversation?.users) {
            for (let i = 0; i < this.state.conversation?.users.length; i++)
                memebersDict[this.state.conversation?.users[i].uid] = this.state.conversation?.users[i]
        }




        if (this.state.conversations && this.state.conversations.length) {
            return this.state.conversations.filter((item, idx) => {
                if (/*this.checkSearch(item) &&*/ ((item.groupAlias && item.groupAlias.indexOf(localStorage?.getItem('groupAlias')) != -1) || (item.groupIds && ((localStorage?.group && item.groupIds.indexOf(localStorage?.getItem('group')) !== -1) || (item.groupIds.indexOf(String(this.props.selectedGroup)) !== -1)))) /*&& ((!item.closed && this.state.conversationsType == 0) || (item.closed && this.state.conversationsType == 1))*/) {
                    if (!item.chatOneOnOne || (item.chatOneOnOne && item.messagesExist))
                        return true;
                    else
                        return false;
                } else {
                    return false;
                }
            })

        }

        else {
            return [];
        }

    }
    getReferralInfo = (callback) => {

        if (this.state.conversation && this.state.conversation?.referral) {
            let referral = { ...this.state.conversation?.referral }
            fetch(API_ENDPOINT + '/chat/referrals-cases/referral/projection/data', {
                method: 'POST',
                headers: {
                    'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage?.getItem('authToken')}` : null,
                    'content-type': 'application/json'
                },
                body: JSON.stringify({ _id: this.state.conversation?.referral?._id, checkIfPatient: this.state.conversation?.checkIfPatient ? this.state.conversation?.checkIfPatient : false })
            }).then(res => res.json()).then((result) => {
                if (result) {
                    referral = { ...referral, ...result }
                }
                let conversation = { ...this.state.conversation }
                conversation.referral = referral;



                this.setState({ conversation }, () => {
                    if (callback) {
                        callback()
                    }
                })
            })
        } else {
            if (callback) {
                callback()
            }
        }

    }

    usersInvitedEnter = (invitedEmails) => {
        let emails = invitedEmails.split(/[;,]+/)
        for (let i = 0; i < emails.length; i++) {
            let shareModalFields = this.state.shareModalFields;
            var storedUsers = [];
            if (shareModalFields && shareModalFields.invitedUsers) {
                storedUsers = [...shareModalFields.invitedUsers]
            }
            if (storedUsers && storedUsers.length >= 1) {
                if (storedUsers.findIndex(item => item == emails[i].trim()) == -1)
                    if (this.validateEmail(emails[i].trim())) {
                        storedUsers.push(emails[i].trim())
                        this.setState({
                            inviteEmailError: ''
                        })
                    } else {
                        this.setState({
                            inviteEmailError: 'Email address is incorrect!'.translate(this.props.lang)
                        })
                    }

            } else {
                if (this.validateEmail(emails[i].trim())) {
                    storedUsers.push(emails[i].trim())
                    this.setState({
                        inviteEmailError: ''
                    })
                } else {
                    this.setState({
                        inviteEmailError: 'Email address is incorrect!'.translate(this.props.lang)
                    })
                }

            }
            shareModalFields.invitedUsers = [...storedUsers]
            shareModalFields.invitedEmails = ''
            this.setState({
                shareModalFields
            })

        }
    }

    storeUsersInvitiation = (invitedEmails) => {
        let emails = invitedEmails.split(/[;,]+/)
        for (let i = 0; i < emails.length; i++) {
            let shareModalFields = this.state.shareModalFields;
            var storedUsers = [];
            if (shareModalFields && shareModalFields.invitedUsers) {
                storedUsers = [...shareModalFields.invitedUsers]
            }
            if (storedUsers && storedUsers.length >= 1) {
                if (storedUsers.findIndex(item => item == emails[i].trim()) == -1)
                    if (this.validateEmail(emails[i].trim())) {
                        storedUsers.push(emails[i].trim())
                        this.setState({
                            inviteEmailError: ''
                        })
                    } else {
                        this.setState({
                            inviteEmailError: 'Email address is incorrect!'.translate(this.props.lang)
                        })
                    }

            } else {
                if (this.validateEmail(emails[i].trim())) {
                    storedUsers.push(emails[i].trim())
                    this.setState({
                        inviteEmailError: ''
                    })
                } else {
                    this.setState({
                        inviteEmailError: 'Email address is incorrect!'.translate(this.props.lang)
                    })
                }

            }
            shareModalFields.invitedUsers = [...storedUsers]
            shareModalFields.invitedEmails = ''
            this.setState({
                shareModalFields
            })

        }
    }

    _handleKeyDown = (e) => {
        if (e.key === 'Enter') {
            this.usersInvitedEnter(e.target.value)

        }
    }

    usersInvited = (e) => {
        console.log(this.state.shareModalFields, e);
        let shareModalFields = this.state.shareModalFields;
        shareModalFields.invitedEmails = e

        this.setState({
            shareModalFields
        })

    }

    storeUsersInvitiationPhone = () => {
        let shareModalFields = this.state.shareModalFields;
        if (shareModalFields) {
            var storedUsers = [];
            if (shareModalFields.input && shareModalFields.input != '') {
                if (shareModalFields && shareModalFields.invitedUsers) {
                    storedUsers = [...shareModalFields.invitedUsers]
                }
                if (!shareModalFields.input.startsWith('00')) {
                    shareModalFields.input = '+' + shareModalFields.input;
                }
                if (storedUsers.findIndex(item => item == shareModalFields.input) == -1)
                    if (((shareModalFields.input.startsWith('+') || shareModalFields.input.startsWith('00')) && shareModalFields.input.length >= 11)) {
                        storedUsers.push(shareModalFields.input)
                        this.setState({
                            invitePhoneError: ''
                        })
                    } else {
                        this.setState({
                            invitePhoneError: 'Mobile number is incorrect!'.translate(this.props.lang)
                        })
                    }
                shareModalFields.invitedUsers = [...storedUsers]
                shareModalFields.input = '+46'
                this.setState({
                    shareModalFields,
                    // invitePhoneError: ''
                })
            }
        }
    }

    handleDelete = i => {
        if (this.state.shareModalFields && this.state.shareModalFields.invitedUsers.length && this.state.shareModalFields.invitedUsers[i]) {

            let shareModalFields = this.state.shareModalFields
            shareModalFields.invitedUsers.splice(i, 1)
            this.setState({
                shareModalFields
            })
        }

    };


    emitConversations = (addedObj = {}) => {
        console.log('emitConversations', addedObj)
        let initObj = { profile: typeof window !== 'undefined' ? localStorage?.getItem?.('profile') : null, conversationsType: this.state.conversationsType, windowConversation: ((this.props[0].match.params && this.props[0].match.params.windowConversation) || (this.props[0].match.params && this.props[0].match.params.conversation)), search: this.state.search, pageNumber: this.state.conversationPage }

        if (this.props.uData && this.props.uData?.userLevel && this.props.uData?.userLevel === 20 && (typeof window !== 'undefined' && localStorage?.getItem?.('profile') && localStorage?.getItem?.('profile') !== 'patient') && this.props.selectedGroup) {
            initObj.selectedGroup = this.props.selectedGroup;
            if (this.props.selectedClinic) {
                initObj.selectedClinic = this.props.selectedClinic;
            }
        } else if (this.props.uData && this.props.uData?.userLevel && this.props.uData?.userLevel === 1 || this.props.uData?.userLevel === 2 || (this.props.uData?.userLevel === 20 && typeof window !== 'undefined' && localStorage?.getItem?.('profile') && localStorage?.getItem?.('profile') === 'patient')) {
            initObj.groupAlias = localStorage?.groupAlias;
        }

        let obj = {}

        if (!addedObj || Object.keys(addedObj).length === 0) {
            obj = initObj
        } else {
            obj = { ...initObj, ...addedObj }
        }

        this.props.socket.emit('conversations2', obj);
    }

    handleDiaryClick = (conversation) => {
        if (!conversation) return;
        let SSN;
        if (conversation.referral?.patient?.socialSecurityNumber) {
            SSN = conversation.referral?.patient?.socialSecurityNumber
        } else {
            if (conversation.users && conversation.users.filter(user => user.level < 20).length > 0) {
                let patient = conversation.users.filter(user => user.level < 20)[0];
                if (patient && patient.socialSecurityNumber) SSN = patient.socialSecurityNumber
            }
        }
        if (!SSN) return;
        if (typeof window !== 'undefined') window.open(`/refresh/page?diary=${SSN}`, '_blank').focus();
    }

    setClinicLogo = () => {
        if (this.state.activeClinicChatForm?.logo) {
            let img = API_ENDPOINT + this.state.activeClinicChatForm?.logo;
            imageUrlToBase64(img)
                .then(base64 => {
                    let activeClinicChatForm = this.state.activeClinicChatForm;
                    activeClinicChatForm.clinicLogo = base64;
                    this.setState({ activeClinicChatForm })
                    // Do something with the converted JPEG image
                })
                .catch(error => {
                    console.error(error);
                    // Handle the error
                });

        }
    }

    getClinicData = (clinic) => {
        if (clinic) {
            fetch(API_ENDPOINT + '/data/clinics/' + clinic, {
                method: 'GET',
                headers: {
                    'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage?.getItem('authToken')}` : null,
                    'content-type': 'application/json'
                },
            }).then(res => res.json()).then((result) => {
                this.setState({
                    activeClinicChatForm: result
                }, () => {
                    this.setClinicLogo()
                })
            })
        }

    }

    handleDrop = async (e) => {
        e?.preventDefault?.();
        this.setState({ isDragging: false }, () => {
            let oldScrollHeight = 0;
            if (this.messagesRef) {
                oldScrollHeight = this.messagesRef.scrollHeight;
            }

            if (this.messagesRef && this.state.page == 0) {
                this.messagesRef.scrollTop = this.messagesRef.scrollHeight;
            } else if (oldScrollHeight) {
                this.messagesRef.scrollTop = this.messagesRef.scrollTop + (this.messagesRef.scrollHeight - oldScrollHeight);
            }
        });

        if (!this.state.conversation) return;

        const droppedFiles = e?.dataTransfer?.files;
        if (!droppedFiles) return;

        if (Array.from(droppedFiles) && Array.from(droppedFiles)?.length) {
            const fileArray = Array.from(droppedFiles);

            if (fileArray.length > 10) return this.setState({ error: 'You can only select up to 10 files to upload, at a time.' });

            this.setState({ _dragNDropUpload: true }, async () => {
                // console.log('drag n drop start: ', this.state._dragNDropUpload);

                for (let i = 0; i < fileArray.length; i++) {
                    let file = fileArray[i];

                    const obj = {
                        target: {
                            files: [file]
                        }
                    }

                    if (file && file.type && ['image/png', 'image/jpeg', 'image/jpg', 'image/gif', 'image/svg'].includes(file.type)) {
                        const url = await this.uploadImage(obj, true);
                        // console.log('url" , ', url);
                        if (url) await this.sendMessageFromDragAndDrop(this.state.conversation?._id, 'image', url);
                    } else if (file && file.type && ['application/msword', 'application/vnd.openxmlformats-officedocument.wordprocessingml.document', 'application/pdf'].includes(file.type)) {
                        const url = await this.uploadDocument(obj, true);
                        if (url) await this.sendMessageFromDragAndDrop(this.state.conversation?._id, 'document', url);
                    } else if (file && file.type && ['video/mp4', 'video/x-msvideo'].includes(file.type)) {
                        const url = await this.uploadVideo(obj, true);
                        if (url) await this.sendMessageFromDragAndDrop(this.state.conversation?._id, 'video', url);
                    }
                }

                this.setState({ _dragNDropUpload: false })

                // fileArray.forEach(async (file, i) => {
                //     const obj = {
                //         target: {
                //             files: [file]
                //         }
                //     }

                //     if (i >= 10) return this.setState({ _dragNDropUpload: false });

                //     if (file && file.type && ['image/png', 'image/jpeg', 'image/jpg', 'image/gif', 'image/svg'].includes(file.type)) {
                //         const url = await this.uploadImage(obj, true);
                //         console.log('url" , ', url);
                //         if (url) await this.sendMessageFromDragAndDrop(this.state.conversation?._id, 'image', url);
                //     } else if (file && file.type && ['application/msword', 'application/vnd.openxmlformats-officedocument.wordprocessingml.document', 'application/pdf'].includes(file.type)) {
                //         const url = await this.uploadDocument(obj, true);
                //         if (url) await this.sendMessageFromDragAndDrop(this.state.conversation?._id, 'document', url);
                //     } else if (file && file.type && ['video/mp4', 'video/x-msvideo'].includes(file.type)) {
                //         const url = await this.uploadVideo(obj, true);
                //         if (url) await this.sendMessageFromDragAndDrop(this.state.conversation?._id, 'video', url);
                //     }
                // });


            })
        }
    }

    sendMessageFromDragAndDrop = async (conversationId, type, url) => new Promise((resolve, reject) => {
        if (!conversationId || !type || !url) return reject({ error: 'asdf' });
        let obj = {
            conversation: conversationId,
            message: this.state.message || '',
        }

        switch (type) {
            case 'document': { obj.document = url; break; }
            case 'image': { obj.image = url; break; }
            case 'video': { obj.video = url; break; }
        }

        if (this.state.chatType == 'team') {
            obj.chatType = 'team'
        }

        this.props.socket.emit('sendMessage', obj);

        this.setState({
            message: '',
            document: null,
            image: null,
            video: null,
            healthCareId: null
        }, () => {
            return resolve(true);
        })
    })

    genareteChatFormPdf = (id) => {
        if (id) {
            this.setState({ loadingChatPdf: true }, () => {
                fetch(API_ENDPOINT + '/data/chat-form-questionary/generate-pdf', {
                    method: 'POST',
                    headers: {
                        'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage?.getItem('authToken')}` : null,
                        'content-type': 'application/json'
                    },
                    body: JSON.stringify({
                        healthCareReferral: id,
                        lang: this.props.lang,
                        isDoctor: typeof window !== 'undefined' && localStorage.profile !== 'patient' && this.props.uData?.userLevel === 20 ? true : false
                    })
                }).then(res => res.json()).then((result) => {
                    this.setState({
                        loadingChatPdf: false
                    })
                    if (result && result.pdf) {
                        setTimeout(() => {
                            let pdfUrl = API_ENDPOINT + result.pdf;
                            var link = document.createElement("a");
                            link.download = result.pdfName ? result.pdfName : 'test';
                            link.target = "_blank";

                            // Construct the URI
                            link.href = pdfUrl;
                            document.body.appendChild(link);
                            link.click();
                        }, 1000);
                    }
                })
            })

        }

    }

    journalObjExists = () => {
        // let journalNoteIntelligentSections = this.state.journalNoteIntelligentSections;
        let journalNoteObj = this.state.journalNoteObj;

        let exists = false;
        // for (var key in journalNoteIntelligentSections) {
        //     if (journalNoteIntelligentSections[key]?.answer) exists = true;
        // }
        for (var key in journalNoteObj) {
            if (journalNoteObj[key]) exists = true;
        }

        return exists;
    }

    checkDisabledSendLater = () => {
        let sendLaterModal = this.state.sendLaterModal;
        if (!sendLaterModal) return true;
        let date = sendLaterModal?.date ?? moment.unix(Math.floor(new Date().getTime() / 1000)).format(this.props.dateFormat);
        let time = sendLaterModal?.time ?? '00:00';

        let tsNow = Math.floor(new Date().getTime() / 1000);
        let tsSelectedTime = Math.floor(new Date(`${date} ${time}`).getTime() / 1000);

        if (tsSelectedTime > tsNow) {
            return false;
        } else {
            return true;
        }

    }

    render() {
        const isDisabledSendLater = this.checkDisabledSendLater();


        if (this.state.referral) console.log('this.state.referral: ', this.state.referral);
        const isPatient = (typeof window != 'undefined' && localStorage?.getItem?.('profile') === 'patient' && this.props.uData?.userLevel === 20) || this.props.uData?.userLevel === 1 || this.props.uData?.userLevel === 2;
        const showPreviewStatementButton = this.state.statementData?._id && isPatient ? true : false;
        const previewStatement = this.state.previewStatement;

        let isAddNewConsultationButtonDisabled = false;
        if (this.state.activeConsultations && Array.isArray(this.state.activeConsultations) && this.state.activeConsultations.length > 0) {
            for (const consultation of this.state.activeConsultations) {
                let tab;
                if (consultation?.sender?.groupId === this.props.selectedGroup && consultation?.sender?.clinicId === this.props.selectedClinic && consultation?.sender?.doctorId === this.props.uData?._id) {
                    if (consultation?.sender?.status !== 'closed') {
                        isAddNewConsultationButtonDisabled = true;
                        break;
                    }
                } else if (consultation?.receiver?.groupId === this.props.selectedGroup && consultation?.receiver?.clinicId === this.props.selectedClinic && consultation?.receiver?.doctorId === this.props.uData?._id) {
                    if (consultation?.receiver?.status !== 'closed') {
                        isAddNewConsultationButtonDisabled = true;
                        break;
                    }
                };
            }
        }

        let selectedGroupIdx = -1;
        if (this.props.uData && this.props.selectedGroup && this.props.uData.clinicGroups?.length) {
            for (let i = 0; i < this.props.uData.clinicGroups.length; i++) {
                if (this.props.uData.clinicGroups[i]?._id == this.props.selectedGroup) {
                    selectedGroupIdx = i;
                    break;
                }
            }
        }


        let conversationNotPatientNumber = this.state.conversation?.members?.filter(item => item !== this.state.conversation?.patientId && item !== this.state.conversation?.patient2Id)?.length;
        let showJournalNoteButton = true;

        if (this.state.conversation && this.state.conversation?.referral && this.state.conversation?.referral.control && this.state.conversation?.referral.event && this.state.conversation?.referral.revisitFormsArray && this.state.conversation?.referral.revisitFormsArray.length) {
            let conversationEvent = this.state.conversation?.referral.event;
            let conversationReferral = this.state.conversation?.referral;
            if (conversationEvent && conversationReferral && conversationEvent.journalNoteFormSelected && conversationReferral.revisitFormsArray.filter(el => el.revisitQuestionaryId == conversationEvent.journalNoteFormSelected).length) {
                let revisitForm = conversationReferral.revisitFormsArray.filter(el => el.revisitQuestionaryId == conversationEvent.journalNoteFormSelected)[0];
                if (!revisitForm.finishedForm) {
                    showJournalNoteButton = false;
                }
            }
        }
        const writeStatementEnabled = (this.props.uData?.clinicGroups?.[selectedGroupIdx]?.enterprise || this.props.uData?.clinicGroups?.[selectedGroupIdx]?.sarchPatients) && this.props.uData?.clinicGroups?.[selectedGroupIdx]?.medicalStatments;

        let conversations = [];
        let conversationsLength = 0;
        let checkConversations = this.checkConversations();
        if (checkConversations && checkConversations.items) {
            conversations = checkConversations.items;
        }
        if (checkConversations && checkConversations.total) {
            conversationsLength = checkConversations.total;
        }

        // let conversations = this.state.conversations ? this.state.conversations : [];
        // let conversationsLength = this.state.totalNumberOfConversations ? this.state.totalNumberOfConversations : 0;

        //-------------------
        let kontaktorsak = this.state.journalNoteObj && this.state.journalNoteObj.kontaktorsak ? this.state.journalNoteObj.kontaktorsak : this.state.conversation && this.state.conversation?.referral && this.state.conversation?.referral.questionaryName ? this.state.conversation?.referral.questionaryName : '';
        if (this.state.conversation && this.state.conversation?.referral && this.state.conversation?.referral.event && this.state.conversation?.referral.event.journalNoteFormSelected && this.state.conversation?.referral.event.revisitFormsArray && !(this.state.journalNoteObj && this.state.journalNoteObj.kontaktorsak)) {
            let event = this.state.conversation?.referral.event;
            if (event.revisitFormsArray && event.revisitFormsArray.length && event.revisitFormsArray.filter(el => el.revisitQuestionaryId == event.journalNoteFormSelected && el.name).length) {
                kontaktorsak = event.revisitFormsArray.filter(el => el.revisitQuestionaryId == event.journalNoteFormSelected && el.name)[0].name;
            }
        }

        //------------


        let params = {};
        if (this.state.useState) {
            params = this.state.params;
        } else {
            params = getSearchParams(this.props[0].location.search, {})
        }
        let checkJournalNoteSections = false;
        if (this.state.journalNote) {
            checkJournalNoteSections = this.checkJournalNoteSections()
        }


        const isExportToJournalSystemDisabled = this.state.loadingJournalNoteExport ? true : this.journalObjExists() ? false : this.state.conversation && (this.state.conversation?.journalNote || (!this.state.conversation?.journalNote && checkJournalNoteSections)) && this.state.conversation?.referral && (!this.state.conversation?.referral.intelligentReferral || (checkJournalNoteSections && this.state.conversation?.referral.intelligentReferral)) && !this.state.loadingJournalNoteExport ? false : true;

        let memebersDict = {};

        if (this.state.conversation && this.state.conversation?.users) {
            for (let i = 0; i < this.state.conversation?.users.length; i++)
                memebersDict[this.state.conversation?.users[i].uid] = this.state.conversation?.users[i]
        }
        const humanReadable = {
            'waiting-for-approval': 'Waiting for approval',
            'waiting': 'Waiting',
            'in-progress': 'In progress',
            'disallowed': 'Disallowed',
            'allowed': 'Allowed',
            'return-for-update': 'Return for update',
            'patient-requested-another-time': 'Patient requested anoother time',
            'patient-rejected': 'Rejected by patient',
            'patient-accepted': 'Patient accepted',
            'clinic-accepted-requested-time': 'Clinic accepted requested time',
            'clinic-rejected-requested-time': 'Clinic rejected requested time',
            'approved-by-region-manager': 'Approved by region manager',
            'rejected-by-region-manager': 'Rejected by region manager',
            'scheduled': 'Scheduled',
            'not-booked': 'Not booked',
            'closed': 'Closed',
            'revisit-scheduled': 'Revisit scheduled',
            'canceled-by-clinic': 'Canceled by clinic',
            'requested-new-appointment': 'Requested new appointment'.translate(this.props.lang),
            'visit-rescheduled': 'Visit rescheduled'.translate(this.props.lang),
            'external': 'External transfer'.translate(this.props.lang),

        }

        const styleTextarea = {
            maxHeight: '75px',
            minHeight: '38px',
            resize: 'none',
            padding: '9px',
            boxSizing: 'border-box',
            fontSize: '15px'
        };

        if (this.props[0].location.search.split('=')[0] == '?roomId' && this.state.hostEndedCall) {
            return (
                <div className='guest-end-call-message'>
                    <p>{'Thank you for using our services.'.translate(this.props.lang)}</p>
                </div>
            );
        }

        // if (this.state.browserName && this.state.browserName !== 'Chrome' && this.state.browserName !== 'Safari' && this.state.browserName !== 'Firefox' && this.state.browserName !== 'Edge') {
        //     return (
        //         <div className='guest-end-call-message'>
        //             <p>{'This browser is not supported.'.translate(this.state.lang)}</p>
        //         </div>
        //     )
        // }

        if (this.state._hostEndCall || this.state.hostEndedCall) {
            return (
                <Modal isOpen={this.state._hostEndCall || this.state.hostEndedCall} centered toggle={() => this.setState({ _hostEndCall: !this.state._hostEndCall, hostEndedCall: !this.state.hostEndedCall }, () => {
                    try {
                        setTimeout(() => {
                            if (this.props[0]?.location?.pathname?.indexOf('video-call') !== -1)
                                window.open('', '_self').close();

                        }, 1000);
                    } catch (e) {

                    }


                    if (window && window.top)
                        window.top.postMessage('end-video-call', '*')



                })} className="end-video-call-modal">
                    <ModalHeader style={{ margin: 'auto' }}

                    >{'Info'.translate(this.props.lang)} </ModalHeader>

                    <ModalBody className="video-call-limit delete-modal">
                        <Isvg src={info} />

                        <p>{'All participants left the video call.'.translate(this.props.lang)}</p>
                    </ModalBody>
                    <ModalFooter className="buttons-right-blue">
                        <Button onClick={() => this.setState({ _hostEndCall: !this.state._hostEndCall, hostEndedCall: !this.state.hostEndedCall }, () => {
                            try {
                                setTimeout(() => {
                                    if (this.props[0]?.location?.pathname?.indexOf('video-call') !== -1)
                                        window.open('', '_self').close();

                                }, 1000);

                            } catch (e) {

                            }

                            if (window && window.top)
                                window.top.postMessage('end-video-call', '*')


                        })} color="primary">{'Close video call'.translate(this.props.lang)}</Button>
                    </ModalFooter>

                </Modal>
            )

        }
        if (this.state._endCall) {
            return (
                <Modal isOpen={this.state._endCall} className="end-video-call-modal" centered>
                    <ModalHeader>{'Video call ended.'.translate(this.props.lang)}</ModalHeader>

                    <ModalBody className="video-call-limit ">
                        <p>{'Rate the quality of video call.'.translate(this.props.lang)}</p>
                        <div className="rating">
                            {this.state._endCall.rating && this.state._endCall.rating >= 1 ? <button onClick={() => { let _endCall = this.state._endCall; _endCall.rating = 1; this.setState({ _endCall }) }}><Isvg src={fullStarIcon} /></button> : <button onClick={() => { let _endCall = this.state._endCall; _endCall.rating = 1; this.setState({ _endCall }) }}><Isvg src={starIcon} /></button>}
                            {this.state._endCall.rating && this.state._endCall.rating >= 2 ? <button onClick={() => { let _endCall = this.state._endCall; _endCall.rating = 2; this.setState({ _endCall }) }}><Isvg src={fullStarIcon} /></button> : <button onClick={() => { let _endCall = this.state._endCall; _endCall.rating = 2; this.setState({ _endCall }) }}><Isvg src={starIcon} /></button>}
                            {this.state._endCall.rating && this.state._endCall.rating >= 3 ? <button onClick={() => { let _endCall = this.state._endCall; _endCall.rating = 3; this.setState({ _endCall }) }}><Isvg src={fullStarIcon} /></button> : <button onClick={() => { let _endCall = this.state._endCall; _endCall.rating = 3; this.setState({ _endCall }) }}><Isvg src={starIcon} /></button>}
                            {this.state._endCall.rating && this.state._endCall.rating >= 4 ? <button onClick={() => { let _endCall = this.state._endCall; _endCall.rating = 4; this.setState({ _endCall }) }}><Isvg src={fullStarIcon} /></button> : <button onClick={() => { let _endCall = this.state._endCall; _endCall.rating = 4; this.setState({ _endCall }) }}><Isvg src={starIcon} /></button>}
                            {this.state._endCall.rating && this.state._endCall.rating >= 5 ? <button onClick={() => { let _endCall = this.state._endCall; _endCall.rating = 5; this.setState({ _endCall }) }}><Isvg src={fullStarIcon} /></button> : <button onClick={() => { let _endCall = this.state._endCall; _endCall.rating = 5; this.setState({ _endCall }) }}><Isvg src={starIcon} /></button>}
                        </div>
                        {this.state._endCall.rating ?
                            <FormGroup>
                                <Input type="textarea" placeholder={'Comment'.translate(this.props.lang)} value={this.state._endCall.comment} onChange={(e) => { let _endCall = this.state._endCall; _endCall.comment = e.target.value; this.setState({ _endCall }) }} />
                            </FormGroup>
                            :
                            null
                        }
                    </ModalBody>
                    <ModalFooter>
                        <Button onClick={() => {
                            if (this.state._endCall.rating) {
                                this.props.socket.emit('rateVideoCall', { conversation: this.state.conversation?._id, rating: this.state._endCall.rating, comment: this.state._endCall.comment, browser: browserInfo() })
                            }
                            if (window && window.top)
                                window.top.postMessage('end-video-call', '*')
                            try {
                                setTimeout(() => {
                                    if (this.props[0]?.location?.pathname?.indexOf('video-call') !== -1)
                                        window.open('', '_self').close();

                                }, 1000);

                            } catch (e) {

                            }


                            this.setState({ _endCall: !this.state._endCall, _guestCallEnded: true }, () => {
                                try {
                                    if (this.props.uData && detectMob() && this.state.conversation && this.state.conversation.clinicGroupObj && this.state.conversation.clinicGroupObj.alias) {
                                        setTimeout(() => {
                                            this.props[0].history.push(`/${this.state.conversation.clinicGroupObj.alias}`)
                                        }, 1000);
                                    }
                                } catch (e) {

                                }
                            });
                        }}
                            color="primary">{this.state._endCall.rating ? 'Rate and close'.translate(this.props.lang) : 'Close'.translate(this.props.lang)}</Button>
                    </ModalFooter>

                </Modal>
            )

        }


        if (this.state._guestCallEnded) {
            if (this.props[0].location.search.split('=')[0] == '?roomId')
                return (
                    <div className='guest-end-call-message'>
                        <p>{'Thank you for using our services.'.translate(this.props.lang)}</p>
                    </div>
                );
            else
                return null
        }

        if (params.roomId && !this.state.call && !this.state.prepareVideoCall) {
            return (
                <div className="waiting-room" /*style={typeof window != 'undefined' && iosDevice() ? { maxWidth: `${window.innerWidth}px` } : {}}*/>
                    <div>
                        <div className="loader-wrap">
                            <Player
                                autoplay={true}
                                loop={true}
                                renderer={'svg'}
                                src="/lf30_editor_l5cxzdyf.json"
                                style={{ height: typeof window != 'undefined' && window.innerWidth < 768 ? "128px" : "256px", width: typeof window != 'undefined' && window.innerWidth < 768 ? "128px" : "256px" }}
                            ></Player>

                        </div>

                        <p>{'Please Wait for the Host to Start this Meeting'.translate(this.props.lang)}</p>
                        {/* <p>{`${this.state.browserName}`}</p>
                        <p>{`${this.state.browserNameUA}`}</p> */}
                    </div>
                </div>
            )
        }

        if (this.state.prepareVideoCall) {
            if ((!params.roomId || (params.roomId && this.state.guestName))) {
                return (
                    <WebRTCTest registerLog={this.props.registerLog} isPatient={isPatient} participantsInVideoCall={this.state.conversation ? this.state.conversation?.participantsInVideoCall : 0} callback={this.configureStreamAndAnswer} lang={this.props.lang}></WebRTCTest>
                )
            } else {
                return (<div className="guest-name-modal" /*style={typeof window != 'undefined' && iosDevice() ? { maxWidth: `${window.innerWidth}px` } : {}}*/>
                    <div>
                        {
                            (this.props.privacyPolicyModal || !this.props.uData) && this.state.groupObj && this.state.groupObj.videoCallTermsOfUse ?
                                <div style={{ margin: 5 }}>
                                    <h6>{'To use this service you must agree to '.translate(this.props.lang) + `${this.state.conversation?.clinicGroupObj?.name}` + "'s".translate(this.props.lang) + " " + "Terms of service.".translate(this.props.lang)}</h6>
                                    <div style={{ display: 'flex', marginBottom: 15, marginTop: 15 }}>
                                        <Checkbox checked={this.state.termsOfUseChecked} label={'I agree to the'.translate(this.props.lang)} onChange={() => {
                                            this.setState({ termsOfUseChecked: !this.state.termsOfUseChecked })
                                        }} />
                                        &nbsp;
                                        <a href={`${API_ENDPOINT}${this.state.groupObj?.termsOfUse?.file}`} target="_blank">{'Terms of service'.translate(this.props.lang)}</a>
                                    </div>
                                </div>
                                :
                                null
                        }
                        {!this.state._bankIdStarted && (this.state.conversation && (this.state.conversation?.videoLink == '0' || this.state.conversation?.videoLink == '1')) ?
                            <div>
                                <h6>{'Enter your name'.translate(this.props.lang)}</h6>
                                <FormGroup className="guest-name-form-group">
                                    <Input type="text" onKeyDown={(e) => {
                                        if (e.key == 'Enter' && this.state._guestName && this.state._guestName.trim()) {
                                            this.setState({ guestName: this.state._guestName })
                                        }
                                    }} onChange={(e) => this.setState({ _guestName: e.target.value })} value={this.state._guestName} />
                                    <Button color="primary" disabled={this.state._guestName && this.state._guestName.trim() && !(!this.state.termsOfUseChecked && this.state.groupObj && this.state.groupObj.videoCallTermsOfUse) ? false : true} onClick={() => this.setState({ guestName: this.state._guestName })}>{'Save'.translate(this.props.lang)}</Button>
                                </FormGroup>
                                {
                                    this.state.conversation && this.state.conversation?.videoLink == '0' ?
                                        <div className="or-spacer">{'or'.translate(this.props.lang)}</div>
                                        :
                                        null
                                }
                            </div>
                            :
                            null
                        }

                        {
                            (this.state.conversation && (this.state.conversation?.videoLink == '0' || this.state.conversation?.videoLink == '2')) ?
                                <BankdIDIdentity
                                    redirectUrl={this.state._bankIdRedirectUrl}
                                    disabled={!this.state.termsOfUseChecked && this.state.groupObj && this.state.groupObj.videoCallTermsOfUse}
                                    qrCodeLink={this.state.qrCodeLink}
                                    bankIDkey={this.state.bankIDkey}
                                    conversation={this.state.conversation}
                                    generateBankIdRedirectUrl={() => {
                                        this.setState({ _bankIdStarted: true })
                                        this.props.socket.emit('bankIdGenerateRedirectURL', { conversation: this.state.conversation?._id });
                                    }}
                                    handleBankIdIdentity={(key) => {
                                        this.props.socket.emit('bankIdIdentificate', { conversation: this.state.conversation?._id, loginStorageKey: key })
                                    }}
                                    handleLoading={(value) => this.setState({ _bankIdLoading: value })}
                                    loading={this.state._bankIdLoading}
                                    lang={this.props.lang}
                                ></BankdIDIdentity>

                                :
                                null
                        }

                        {
                            (this.props.privacyPolicyModal || !this.props.uData) && this.state.groupObj && this.state.groupObj.videoCallTermsOfUse ?
                                <div style={{ marginTop: 15 }}>
                                    <h6>{'You can read about how we handle your personal data: '.translate(this.props.lang)}</h6>

                                    <a href={`${API_ENDPOINT}${this.state.groupObj?.privacyPolicy?.file}`} target="_blank">{'Privacy Policy'.translate(this.props.lang)}</a>
                                </div>
                                :
                                null
                        }

                    </div>
                </div>
                )
            }
        }

        if (!this.state.call && this.props[0].match.params.conversation) {
            return (
                <div className="waiting-room" /*style={typeof window != 'undefined' && iosDevice() ? { maxWidth: `${window.innerWidth}px` } : {}}*/>
                    <div>
                        <div className="loader-wrap">
                            <Player
                                autoplay={true}
                                loop={true}
                                renderer={'svg'}
                                src="/lf30_editor_l5cxzdyf.json"
                                style={{ height: typeof window != 'undefined' && window.innerWidth < 768 ? "128px" : "256px", width: typeof window != 'undefined' && window.innerWidth < 768 ? "128px" : "256px" }}
                            ></Player>

                        </div>
                    </div>
                </div>

            )
        }






        if (this.state.call) {
            let numberOfColumns = /*typeof window != 'undefined' && window.innerWidth < 768 ? 6 : 16*/this.state.videoElements.length;
            let numberOfRows = /*Math.ceil(this.state.videoElements.length / numberOfColumns)*/1;
            let numberOfColumnsOnScreen = typeof window != 'undefined' && window.innerWidth < 768 ? 6 : 14;

            let max = numberOfColumnsOnScreen;
            let elements = (this.state.kbps - 2000) / 400;
            let visibleNumberOfColumnsOnScreen = elements > max ? max : elements;

            let videoElementHeight = (this.callArea && this.callArea.offsetWidth) / numberOfColumnsOnScreen;

            let videoElementWidth = videoElementHeight;
            let scrollAreaPaddingLeft = (numberOfColumnsOnScreen - visibleNumberOfColumnsOnScreen) * videoElementWidth;

            let otherVideosContainerHeight = (100 * videoElementHeight * numberOfRows) / (this.callArea && this.callArea.offsetHeight - 80);


            let videoPosition = null;

            if (this.state.mainVideoElement == 'local-video') {
                if (this.localVideo && this.callArea) {
                    videoPosition = getObjectFitSize(true, this.callArea.offsetWidth, (this.callArea.offsetHeight - 80 - videoElementHeight), this.localVideo.videoWidth, this.localVideo.videoHeight);
                }

            } else {
                if (this.remoteVideo[this.state.mainVideoElement] && this.callArea) {
                    videoPosition = getObjectFitSize(true, this.callArea.offsetWidth, (this.callArea.offsetHeight - 80 - videoElementHeight), this.remoteVideo[this.state.mainVideoElement].videoWidth, this.remoteVideo[this.state.mainVideoElement].videoHeight);
                }

            }


            return (
                <div className={`mobile-fix ${this.state.showScreenShareMode ? 'share-screen-active' : ''}`} >
                    <div className={`call-area `} ref={(node) => { this.callArea = node }}>

                        <div className="remote-container" >
                            {this.callArea ?
                                <div className="video-grid" style={typeof window != 'undefined' && iosDevice() ? { maxWidth: `${window.innerWidth}px` } : {}}>
                                    {/*<div className="main-video" style={{ height: `${100 - otherVideosContainerHeight}%` }}>
                                    {
                                        this.renderMainVideoElement()
                                    }
                                </div>*/}
                                    <div className="other-videos" /*style={{ height: '100%', paddingTop: `${100- otherVideosContainerHeight}%` }}*/>
                                        <div className={`scroll-area ${this.state.videoElements.length == 1 && !this.state.showScreenShareMode ? 'video-call-two-elements' : ''}`} style={{ paddingLeft: scrollAreaPaddingLeft }} onScroll={() => {
                                            // console.log('scroll');
                                            this.checkVideoElements()
                                        }} ref={(node) => this.scrollArea = node}
                                            onMouseDown={(e) => {
                                                if (this.scrollArea) {
                                                    this.scrollArea.style.cursor = 'grabbing';
                                                    //this.scrollArea.style.userSelect = 'none';


                                                    this.setState({
                                                        _dragging: {
                                                            x: e.clientX,
                                                            y: e.clientY,
                                                            left: this.scrollArea.scrollLeft,
                                                        }
                                                    })
                                                }
                                            }}
                                            onMouseMove={(e) => {
                                                if (this.state._dragging && this.scrollArea) {
                                                    // How far the mouse has been moved
                                                    const dx = e.clientX - this.state._dragging.x;

                                                    this.scrollArea.scrollLeft = this.state._dragging.left - dx;
                                                }
                                            }}
                                            onMouseUp={(e) => {
                                                this.scrollArea.style.cursor = 'grab';
                                                //this.scrollArea.style.userSelect = 'none';

                                                this.setState({
                                                    _dragging: null
                                                })
                                            }}


                                        >
                                            {
                                                this.renderOtherVideoElements(true, this.state.fullScreen ? false : true)
                                            }
                                            {this.state.startTime && videoPosition ?
                                                <div className="timer" style={{ top: this.state.fullScreen ? '90vh' : (videoPosition.y + videoPosition.height - 40), right: videoPosition.x + 10 }}><Timer startTime={this.state.startTime} /></div>
                                                :
                                                null
                                            }
                                        </div>
                                        {this.state.fullScreen ?


                                            <div className="full-screen-other-elements">
                                                <button><Isvg src={camIcon} /></button>
                                                <div>
                                                    <div className="scroll-area"
                                                    >
                                                        {
                                                            this.renderOtherVideoElements(false, true)
                                                        }
                                                    </div>
                                                </div>
                                            </div>
                                            :
                                            null
                                        }
                                    </div>
                                </div>
                                :
                                null
                            }


                            {this.state.videoElements.length == 0 ?
                                <div className="waiting-room">
                                    <div>
                                        <div className="loader-wrap">
                                            <Player
                                                autoplay={true}
                                                loop={true}
                                                renderer={'svg'}
                                                src="/lf30_editor_l5cxzdyf.json"
                                                style={{ height: typeof window != 'undefined' && window.innerWidth < 768 ? "128px" : "256px", width: typeof window != 'undefined' && window.innerWidth < 768 ? "128px" : "256px" }}
                                            ></Player>

                                        </div>
                                        {this.props.uData && this.state.conversation && this.state.conversation?.members && this.state.conversation?.members[0] == this.props.uData?._id ?
                                            <p>{'Please wait for others to join the call.'.translate(this.props.lang)}</p>
                                            :
                                            this.state._reconnecting ?
                                                <p>{'Reconnection in progress, please wait.'.translate(this.props.lang)}</p>
                                                :
                                                <p>{'Please wait for host to let you in.'.translate(this.props.lang)}</p>
                                        }
                                    </div>
                                </div>
                                :
                                null

                            }

                        </div>

                        <VideoCallLogger items={this.state.videoGatewayLogs} lang={this.props.lang}></VideoCallLogger>

                        <button onClick={() => {
                            this.getDevices();
                            this.setState({ settings: !this.state.settings }, () => {
                                if (this.audioVideoStream) {
                                    const previewVideo = this.previewVideo;
                                    if (previewVideo) {
                                        previewVideo.srcObject = this.audioVideoStream;
                                        previewVideo.autoplay = true;
                                        previewVideo.playsInline = true;
                                        previewVideo.muted = true;
                                    }

                                }

                                if (this.state.audioOutputDevice)
                                    this.attachSinkIdTestSound(this.state.audioOutputDevice);
                            })
                        }} className={"settings-button"}>{<Isvg src={settingsIcon} />} <div className="chat-icons-tooltip">{'Settings'.translate(this.props.lang)}</div> </button>


                        {typeof window !== 'undefined' && detectMob() == true ?
                            <button onClick={this.flipCamera} className={this.state.camera ? "flip-camera" : "flip-camera off"}>{<Isvg src={flip} />} <div className="chat-icons-tooltip">{'Flip camera'.translate(this.props.lang)}</div> </button>
                            :
                            null
                        }


                        <button onClick={this.state.hand ? this.handDown : this.raiseHand} className={this.state.hand ? `raise-hand ${typeof window !== 'undefined' && detectMob() ? 'mobile-raise-hand' : ''}` : `raise-hand off ${typeof window !== 'undefined' && detectMob() ? 'mobile-raise-hand' : ''}`}>{<Isvg src={handIcon} />} <div className="chat-icons-tooltip">{this.state.hand ? 'Hand down'.translate(this.props.lang) : 'Raise hand'.translate(this.props.lang)}</div> </button>
                        {typeof window !== 'undefined' && detectMob() == false ?
                            <button onClick={this.shareScreen} className={!this.state.shareOn ? "share-button off" : "share-button"}>{!this.state.shareOn ? <Isvg src={screenShareIcon} /> : <Isvg src={screenShareIcon} />} <div className="chat-icons-tooltip-off">{'Stop sharing'.translate(this.props.lang)}</div> <div className="chat-icons-tooltip-on">{'Share video'.translate(this.props.lang)}</div> </button>
                            :
                            null
                        }

                        {
                            typeof window !== 'undefined' && detectMob() == false ?

                                <button className="full-screen-button" onClick={() => {
                                    this.setState({
                                        memberTooltip: null
                                    })
                                    /*this.setState({
                                        fullScreen: !this.state.fullScreen
                                    }, () => {*/
                                    try {

                                        var elem = document.documentElement;

                                        if (!this.state.fullScreen) {


                                            if (this.state.showScreenShareMode) {
                                                this.setState({
                                                    showScreenShareMode: false
                                                })
                                            }

                                            if (elem.requestFullscreen) {
                                                elem.requestFullscreen();
                                            } else if (elem.webkitRequestFullscreen) { /* Safari */
                                                elem.webkitRequestFullscreen();
                                            } else if (elem.msRequestFullscreen) { /* IE11 */
                                                elem.msRequestFullscreen();
                                            }


                                        } else {
                                            if (!this.state.showScreenShareMode && this.state.shareOn) {
                                                this.setState({
                                                    showScreenShareMode: true
                                                })
                                            }


                                            if (document.exitFullscreen) {
                                                document.exitFullscreen();
                                            } else if (document.webkitExitFullscreen) { /* Safari */
                                                document.webkitExitFullscreen();
                                            } else if (document.msExitFullscreen) { /* IE11 */
                                                document.msExitFullscreen();
                                            }


                                        }


                                    } catch (e) { }
                                    //})
                                }}><Isvg src={moveIcon} /> <div className="chat-icons-tooltip">{this.state.fullScreen ? 'Exit from full screen'.translate(this.props.lang) : 'Switch to full screen'.translate(this.props.lang)}</div>  </button>
                                :

                                null
                        }


                        {
                            !this.state.showChat && this.props.uData ?
                                <button className={this.state.videoCallNewMessage ? "open-chat-button open-chat-button-new-message" : "open-chat-button"} onClick={() => this.setState({ showChat: true, videoCallNewMessage: false }, () => {

                                    this.setState({
                                        page: 0,
                                        _consultations: null
                                    }, () => {
                                        this.props.socket.emit('messages', { conversation: this.state.conversation?._id, page: this.state.page, chatType: this.state.chatType })
                                    })


                                    // setTimeout(() => {
                                    //     if (this.messagesRef) {
                                    //         this.messagesRef.scrollTop = this.messagesRef.scrollHeight;
                                    //     }

                                    // }, 100)
                                })}>
                                    <Isvg src={ChatIcon} className={'video-call-chat-icon'} />
                                    <div className='chat-icons-tooltip'>
                                        {this.state.videoCallNewMessage ? 'New message'.translate(this.props.lang) : 'Open chat'.translate(this.props.lang)}
                                    </div>

                                    {
                                        this.state.videoCallNewMessage ?
                                            <div className="new-message-circle"> </div>
                                            :

                                            null
                                    }
                                </button>
                                :
                                null
                        }

                        <div className="buttons">
                            <button onClick={this.toggleCamera} className={this.state.cameraOff ? "camera-button off" : "camera-button"}>{this.state.cameraOff ? <Isvg src={cameraOffIcon} /> : <Isvg src={cameraIcon} />}  <div className="chat-icons-tooltip-off">{'Turn off camera'.translate(this.props.lang)}</div> <div className="chat-icons-tooltip-on">{'Turn on camera'.translate(this.props.lang)}</div> </button>
                            <button onClick={() => {
                                this.setState({
                                    endCallModal: true
                                })


                            }} className="end-call-button"><Isvg src={call} /> <div className="chat-icons-tooltip">{'End call'.translate(this.props.lang)}</div> </button>
                            <button style={this.state.mutedByHost ? { cursor: 'not-allowed' } : {}} onClick={this.toggleMic} className={this.state.micOff ? "mic-button off" : "mic-button on"}>{this.state.micOff ? <Isvg src={micMutedIcon} /> : <Isvg src={micIcon} />} <div className="chat-icons-tooltip-off">{'Turn off microphone'.translate(this.props.lang)}</div> <div className="chat-icons-tooltip-on">{'Turn on microphone'.translate(this.props.lang)}</div></button>

                        </div>

                        {
                            this.props.uData ?
                                <div className={this.state.showChat ? `messages-wrap show-chat` : 'messages-wrap'}
                                    onDrop={this.handleDrop}

                                    onDragEnter={(e) => {

                                        if (!e.dataTransfer.types.includes('Files')) {
                                            e?.preventDefault();
                                            e?.stopPropagation();
                                            return
                                        }

                                        e?.preventDefault();
                                        e?.stopPropagation();
                                        this.setState({ isDragging: true })
                                    }}

                                    onDragLeave={(e) => {
                                        e?.preventDefault();
                                        e?.stopPropagation();
                                        this.setState({ isDragging: false }, () => {
                                            let oldScrollHeight = 0;
                                            if (this.messagesRef) {
                                                oldScrollHeight = this.messagesRef.scrollHeight;
                                            }

                                            if (this.messagesRef && this.state.page == 0) {
                                                this.messagesRef.scrollTop = this.messagesRef.scrollHeight;
                                            } else if (oldScrollHeight) {
                                                this.messagesRef.scrollTop = this.messagesRef.scrollTop + (this.messagesRef.scrollHeight - oldScrollHeight);
                                            }
                                        })
                                    }}

                                    onDragOver={e => {
                                        e?.preventDefault();
                                        e?.stopPropagation();
                                    }}
                                >{this.state.isDragging ? <div className='drag-drop-container-video'>
                                    {'Drop to send'.translate?.(this.props.lang)}</div>
                                    :
                                    <><div className="messages-wrapper"
                                        onMouseMove={(e) => {
                                            if (this.state.messageRisaze) {
                                                const element = document.getElementById(this.state.conversation._id);
                                                if (element) {

                                                    element.style.height = Number(element.style.height.replace('px', '')) - e.movementY + 'px'
                                                }
                                            }
                                        }} onMouseUp={() => {
                                            this.setState({ messageRisaze: false })
                                        }}>

                                        {
                                            this.state.showChat ?
                                                <button className="hide-chat" onClick={() => this.setState({ showChat: null })}>&times;</button>
                                                :

                                                null
                                        }

                                        {/* <div className="messages" ref={(node) => this.messagesRef = node} style={{ paddingBottom: this.state.messageHeight && this.messagesRef ? (this.state.messageHeight + 66) : 130 }}> */}
                                        <div className="messages" ref={(node) => this.messagesRef = node}>
                                            {
                                                this.state.messages.map((item, idx) => {
                                                    // let contextMenu;
                                                    // if (item.uid == this.props.uData?._id && !item.deletedMessage && !this.state.conversation?.closed && this.props.uData?.userLevel !== 1 && this.props.uData?.userLevel !== 2 && (localStorage && localStorage?.getItem('profile') !== 'patient')) {
                                                    //     contextMenu = {
                                                    //         'delete': {
                                                    //             isEnabled: true,
                                                    //             onDelete: (e) => {
                                                    //                 e.stopPropagation();
                                                    //                 this.setState({ deleteMessageModal: item }, () => {
                                                    //                     this.setState({ openContextMenu: null });
                                                    //                 })
                                                    //             },
                                                    //         },
                                                    //     }
                                                    // };
                                                    // try {
                                                    //     if (enterprise && !item.deletedMessage && this.props.uData?.userLevel !== 1 && this.props.uData?.userLevel !== 2 && (localStorage && localStorage?.getItem('profile') !== 'patient')) {
                                                    //         if (item.document || item.video || item.image) {
                                                    //             const patientId = this.state.conversation?.users?.find?.(obj => obj.uid && obj.patientInThisGroup) ? this.state.conversation?.users?.find?.(obj => obj.uid && obj.patientInThisGroup).uid : null;
                                                    //             if (patientId) {
                                                    //                 const link = item.document ? item.document.file : item.video ? item.video : item.image ? item.image : null;
                                                    //                 if (link) {
                                                    //                     const extension = '.' + link.split('.').pop().toLowerCase();
                                                    //                     if (extension && getAvailableDocumentTypesForPatientDocuments(true).includes(extension)) {
                                                    //                         const groupId = this.props.selectedGroup;
                                                    //                         if (groupId) {
                                                    //                             if (!item.patientDocumentsSync || !Array.isArray(item.patientDocumentsSync) || !item.patientDocumentsSync.find(obj => obj.groupId === groupId && obj.patientId === patientId)) {
                                                    //                                 if (!contextMenu) contextMenu = {};
                                                    //                                 contextMenu['sync'] = {
                                                    //                                     isEnabled: true,
                                                    //                                     onSync: (e) => {
                                                    //                                         e.stopPropagation();
                                                    //                                         this.setState({ successMessage: null, error: null, openContextMenu: null }, async () => {
                                                    //                                             try {
                                                    //                                                 const url = `${API_ENDPOINT}/users/users/patient/documents/add`;
                                                    //                                                 const body = JSON.stringify({
                                                    //                                                     view: 'doctor',
                                                    //                                                     groupId,
                                                    //                                                     patientId,
                                                    //                                                     document: { id: 'new' },
                                                    //                                                     src: 'chat',
                                                    //                                                     messageId: item._id,
                                                    //                                                 });

                                                    //                                                 const response = await fetch(url, {
                                                    //                                                     method: 'POST',
                                                    //                                                     headers: {
                                                    //                                                         'Content-Type': 'application/json',
                                                    //                                                         'Authorization': `Bearer ${localStorage?.getItem?.('authToken')}`,
                                                    //                                                     },
                                                    //                                                     body,
                                                    //                                                 });

                                                    //                                                 if (!response) return this.setState({ error: 'Something went wrong! Please refresh your browser and try again!' });
                                                    //                                                 const data = await response.json();
                                                    //                                                 if (!data || data.error || !data.documents) return this.setState({ error: data?.error || 'Something went wrong! Please refresh your browser and try again!' });
                                                    //                                                 this.setState({
                                                    //                                                     successMessage: 'Document has been successfully synced to patient profile!',
                                                    //                                                 }, () => {
                                                    //                                                     this.setState(prev => ({
                                                    //                                                         ...prev,
                                                    //                                                         messages: prev.messages.map(msg => msg._id === item._id ? {
                                                    //                                                             ...msg,
                                                    //                                                             patientDocumentsSync: [
                                                    //                                                                 ...(msg.patientDocumentsSync || []),
                                                    //                                                                 {
                                                    //                                                                     groupId,
                                                    //                                                                     patientId,
                                                    //                                                                 }
                                                    //                                                             ]
                                                    //                                                         } : msg)
                                                    //                                                     }))
                                                    //                                                 })
                                                    //                                             } catch (err) {
                                                    //                                                 console.log('--- Error in context menu ---', err);
                                                    //                                             }
                                                    //                                         })

                                                    //                                     }
                                                    //                                 };
                                                    //                             }
                                                    //                         }
                                                    //                     }
                                                    //                 }

                                                    //             }

                                                    //         }
                                                    //     }
                                                    // } catch (err) {
                                                    //     console.log('--- Error in context menu ---', err);
                                                    // }
                                                    if (item.type == 'special') {
                                                        return (
                                                            <>
                                                                {item.videoStarted ? <h6 className='video-chat-notify'>
                                                                    <Isvg src={videoChatIcon} />
                                                                    {/* <span className='time-started'>{moment.unix(item.tsCreated).format(this.props.dateFormat + ' HH:mm')}</span> */}
                                                                    <span className='time-started'>{this.props.getStringDateTs(item.tsCreated, this.props.dateFormat + ' HH:mm')}</span>
                                                                    <span className='user-name'>{item.user.name}</span>
                                                                    <span className='notify'> {'Started video meeting'.translate(this.props.lang)}</span>
                                                                </h6> : null}

                                                                {item.videoJoined ? <h6 className='video-chat-notify'>
                                                                    <Isvg src={userChatIcon} />
                                                                    {/* <span className='time-started'>{moment.unix(item.tsCreated).format(this.props.dateFormat + ' HH:mm')}</span> */}
                                                                    <span className='time-started'>{this.props.getStringDateTs(item.tsCreated, this.props.dateFormat + ' HH:mm')}</span>
                                                                    <span className='user-name'>{item.user.name}</span>
                                                                    <span className='notify'> {'Joined video meeting'.translate(this.props.lang)}</span>
                                                                </h6> : null}

                                                                {item.videoLeft ? <h6 className='video-chat-notify'>
                                                                    <Isvg src={userChatIcon} />
                                                                    {/* <span className='time-started'>{moment.unix(item.tsCreated).format(this.props.dateFormat + ' HH:mm')}</span> */}
                                                                    <span className='time-started'>{this.props.getStringDateTs(item.tsCreated, this.props.dateFormat + ' HH:mm')}</span>
                                                                    <span className='user-name'>{item.user.name}</span>
                                                                    <span className='notify'> {'Left video meeting'.translate(this.props.lang)}</span>
                                                                </h6> : null}

                                                                {item.videoEnded ? <h6 className='video-chat-notify'>
                                                                    <Isvg src={userChatIcon} />
                                                                    {/* <span className='time-started'>{moment.unix(item.tsCreated).format(this.props.dateFormat + ' HH:mm')}</span> */}
                                                                    <span className='time-started'>{this.props.getStringDateTs(item.tsCreated, this.props.dateFormat + ' HH:mm')}</span>
                                                                    <span className='user-name'>{item.user.name}</span>
                                                                    <span className='notify'> {'Ended video meeting'.translate(this.props.lang)}</span>
                                                                </h6> : null}
                                                            </>
                                                        )

                                                    } else
                                                        return (
                                                            <div className={item.uid == this.props.uData?._id ? 'right' : ''} /*style={{ wordBreak: 'break-all' }}*/>
                                                                {this.state.messages.length > 15 && idx == 1 ?
                                                                    <div ><Waypoint
                                                                        onEnter={() => {
                                                                            this.setState({
                                                                                page: this.state.page + 1,
                                                                                _consultations: null
                                                                            }, () => {
                                                                                this.props.socket.emit('messages', { conversation: this.state.conversation?._id, page: this.state.page, chatType: this.state.chatType })
                                                                            })
                                                                        }}
                                                                    />
                                                                    </div>
                                                                    :
                                                                    null
                                                                }

                                                                {
                                                                    item.uid != this.props.uData?._id && item.closeConversationMessage ?
                                                                        // <img src={item.cliniclogoChat ? item.cliniclogoChat : profilePicture} />
                                                                        null
                                                                        : item.uid != this.props.uData?._id ?
                                                                            <img src={item.user && item.user.profilePicture ? API_ENDPOINT + item.user.profilePicture : profilePicture} />
                                                                            :
                                                                            null
                                                                }
                                                                <div>
                                                                    <div className='break-word' onClick={() => {
                                                                        let showSeen = this.state.showSeen;
                                                                        showSeen[item?._id] = !showSeen[item?._id];
                                                                        this.setState({
                                                                            showSeen
                                                                        })
                                                                    }}>
                                                                        {
                                                                            item.uid == this.props.uData?._id && !this.state.conversation?.closed && this.props.uData?.userLevel !== 1 && this.props.uData?.userLevel !== 2 && (localStorage && localStorage?.getItem('profile') !== 'patient') ?
                                                                                <div className="delete-message-icon" onClick={(e) => {
                                                                                    e.stopPropagation();
                                                                                    this.setState({ deleteMessageModal: item })
                                                                                }}>&times;</div>
                                                                                :
                                                                                null
                                                                        }
                                                                        {/* <div className='username' style={{ position: 'relative' }}>
                                                                            {contextMenu ? <WithClickOutside className={`chat-context-menu ${item.uid == this.props.uData?._id ? 'chat-context-menu-right' : ''}`} onClickOutside={() => {
                                                                                if (this.state.openContextMenu && this.state.openContextMenu === item?._id) return this.setState({
                                                                                    openContextMenu: null
                                                                                });
                                                                            }}>
                                                                                <button
                                                                                    onClick={(e) => {
                                                                                        e.stopPropagation();
                                                                                        if (this.state.openContextMenu && this.state.openContextMenu === item?._id) return this.setState({
                                                                                            openContextMenu: null
                                                                                        });

                                                                                        this.setState({
                                                                                            openContextMenu: item?._id
                                                                                        })
                                                                                    }}
                                                                                >
                                                                                    <Isvg src={moreIcon} />
                                                                                </button>
                                                                                {this.state.openContextMenu && this.state.openContextMenu === item?._id ? <ul>
                                                                                    {contextMenu?.sync?.isEnabled ? <li key='sync'>
                                                                                        <button type='button' onClick={contextMenu.sync.onSync}>
                                                                                            {'Sync to patient profile'.translate(this.props.lang)}
                                                                                        </button>
                                                                                    </li> : null}
                                                                                    {contextMenu?.delete?.isEnabled ? <li key='delete'>
                                                                                        <button type='button' onClick={contextMenu.delete.onDelete}>
                                                                                            {'Delete'.translate(this.props.lang)}
                                                                                        </button>
                                                                                    </li> : null}
                                                                                </ul> : null}
                                                                            </WithClickOutside> : null}
                                                                            {item.closeConversationMessage ? null : <p>{item.user?.name}</p>}
                                                                        </div> */}
                                                                        {
                                                                            item.closeConversationMessage ?
                                                                                <div className="username">
                                                                                    {/* {item.clinicName} */}
                                                                                </div>
                                                                                :
                                                                                <div className="username">
                                                                                    <p>{item.user && item.user.name}</p>
                                                                                </div>
                                                                        }

                                                                        {
                                                                            item.deletedMessage && (this.state.conversation && (!this.state.conversation?.memberLanguages || (this.state.conversation?.memberLanguages && !this.state.conversation?.memberLanguages[this.props.uData?._id]))) ?
                                                                                <p style={{ marginBottom: 0, fontStyle: 'italic', fontWeight: 400, fontSize: 14 }}>{item.message + ' ' + 'deleted a message'.translate(this.props.lang)}</p>
                                                                                :
                                                                                item.deletedMessage && (this.state.conversation && this.state.conversation?.memberLanguages && this.state.conversation?.memberLanguages[this.props.uData?._id]) ?
                                                                                    <p style={{ marginBottom: 0, fontStyle: 'italic', fontWeight: 400, fontSize: 14 }}>{item.message}</p>
                                                                                    :
                                                                                    null
                                                                        }
                                                                        {/* {item.image ? <img className="message-image" src={API_ENDPOINT + item.image} /> : null} */}
                                                                        {item.image ? <a target='_blank' href={`/media-gateway?path=${item.image}&mid=${item?._id}&cid=${item.conversation}`}><img className="message-image" src={API_ENDPOINT + item.image + `?mt=${this.props.uData ? this.props.uData.mediaToken : 'undefined'}&cid=${this.state.conversation?._id}&mid=${item?._id}`} /></a> : null}
                                                                        {
                                                                            item.video ?
                                                                                <div className="video-in-chat-wrap">
                                                                                    <video width="320" height="240" controls>
                                                                                        <source src={API_ENDPOINT + `${item.video}?mt=${this.props.uData ? this.props.uData.mediaToken : ''}&cid=${item.conversation}&mid=${item?._id}`} type="video/mp4" />
                                                                                        <source src={API_ENDPOINT + `${item.video}?mt=${this.props.uData ? this.props.uData.mediaToken : ''}&cid=${item.conversation}&mid=${item?._id}`} type="video/ogg" />
                                                                                    </video>
                                                                                </div>
                                                                                :
                                                                                null
                                                                        }
                                                                        {
                                                                            item.additionalText && item.additionalText.split('\n').length ?
                                                                                item.additionalText.split('\n').map((text, t) => {
                                                                                    if (t != item.additionalText.split('\n').length - 1)
                                                                                        return (
                                                                                            <>{text}<br /></>
                                                                                        )
                                                                                    else
                                                                                        return (
                                                                                            text
                                                                                        )
                                                                                })
                                                                                :
                                                                                item.additionalText
                                                                        }
                                                                        {
                                                                            item.additionalText ?
                                                                                <><br /><br /></>
                                                                                :
                                                                                null
                                                                        }

                                                                        {
                                                                            item.healthCareId ?
                                                                                <div className="health-care-form-message" onClick={() => {
                                                                                    if (!this.state.conversation.closed) {
                                                                                        this.setState({
                                                                                            questionaryModal: item.healthCareId,
                                                                                            questionaryMessageActive: item?._id,
                                                                                            questionarySave: item.healthCareReferral ? true : false,
                                                                                            activeReferralContent: item.healthCareReferral && item.healthCareReferralObject && item.healthCareReferralObject.healthCareContent ? item.healthCareReferralObject.healthCareContent : null,
                                                                                            activeHealthCareReferralObject: item?.healthCareReferralObject,
                                                                                            questionaryAnswers: item.healthCareReferral && item.healthCareReferralObject ? item.healthCareReferralObject.answers : {}
                                                                                        },
                                                                                            () => {
                                                                                                if (this.state.activeHealthCareReferralObject?.clinic)
                                                                                                    this.getClinicData(this.state.activeHealthCareReferralObject.clinic)
                                                                                                this.getQuestionary(item?._id)
                                                                                            })
                                                                                    }
                                                                                }}>

                                                                                    {
                                                                                        !item.hideIcon ?
                                                                                            <Isvg src={formIcon} />
                                                                                            :
                                                                                            null

                                                                                    }

                                                                                    {
                                                                                        item.message && item.message.indexOf('The patient filled out the form successfully') > -1 ?
                                                                                            <>
                                                                                                {'The patient filled out the form successfully'.translate(this.props.lang) + ' - '} &nbsp;
                                                                                                <Isvg src={formIcon} />
                                                                                                {item.message.split('The patient filled out the form successfully - ')[1]}

                                                                                            </>

                                                                                            :
                                                                                            <>
                                                                                                {
                                                                                                    item.message && item.message.split('\n').length ?
                                                                                                        item.message.split('\n').map((text, t) => {
                                                                                                            if (t != item.message.split('\n').length - 1)
                                                                                                                return (
                                                                                                                    <>{text}<br /></>
                                                                                                                )
                                                                                                            else
                                                                                                                return (
                                                                                                                    text
                                                                                                                )
                                                                                                        })
                                                                                                        :
                                                                                                        item.message
                                                                                                }


                                                                                            </>


                                                                                    }
                                                                                </div>
                                                                                : null}
                                                                        {
                                                                            !item.healthCareId && !item.deletedMessage ? item.message == 'The patient filled out the form successfully' ? item.message.translate(this.props.lang) :
                                                                                <>
                                                                                    {
                                                                                        item.message && item.message.split('\n').length ?
                                                                                            item.message.split('\n').map((text, t) => {
                                                                                                if (t != item.message.split('\n').length - 1)
                                                                                                    return (
                                                                                                        <>{text}<br /></>
                                                                                                    )
                                                                                                else
                                                                                                    return (
                                                                                                        text
                                                                                                    )
                                                                                            })
                                                                                            : item.message && item.message.indexOf('The chat is now over. If you have any questions, you can send us a message in this chat for another 3 days.') > -1 ?
                                                                                                <>
                                                                                                    {'The chat is now over. If you have any questions, you can send us a message in this chat for another 3 days.'.translate(this.props.lang)}
                                                                                                </>
                                                                                                :
                                                                                                item.message && item.message.indexOf('This chat has ended and cannot be resumed.') > -1 ?
                                                                                                    <>
                                                                                                        {'This chat has ended and cannot be resumed.'.translate(this.props.lang)}
                                                                                                    </>
                                                                                                    :
                                                                                                    item.transferedToDoctor && item.message && item.message.indexOf('Case transferred - consultation') !== -1 ?
                                                                                                        <>
                                                                                                            {`${'Case was transferred to'.translate(this.props.lang)} ${item.transferedToDoctor} ${'in'.translate(this.props.lang)} ${this.state.conversation?.clinicGroupObj?.name}.`}
                                                                                                        </>
                                                                                                        :

                                                                                                        item.message && item.message.indexOf('The medical statement regarding your case has been created. You can preview it by using the View statement button above.') > -1 ?
                                                                                                            <>
                                                                                                                {'The medical statement regarding your case has been created. You can preview it by using the View statement button above.'.translate(this.props.lang)}
                                                                                                            </>
                                                                                                            :

                                                                                                            item.message && item.message.indexOf('The medical statement regarding your case has been updated. You can preview it by using the View statement button above.') > -1 ?
                                                                                                                <>
                                                                                                                    {'The medical statement regarding your case has been updated. You can preview it by using the View statement button above.'.translate(this.props.lang)}
                                                                                                                </>
                                                                                                                :

                                                                                                                item.message
                                                                                    }
                                                                                </>
                                                                                :
                                                                                null
                                                                        }
                                                                        {
                                                                            item.document ?
                                                                                <div className={item.uid != this.props.uData?._id ? "document incomming-document" : "document"}><a target="_blank" download href={API_ENDPOINT + `${item.document.file}?mt=${this.props.uData ? this.props.uData.mediaToken : ''}&cid=${item.conversation}&mid=${item?._id}`}><Isvg src={attach} />{item.document.name}</a></div>
                                                                                :
                                                                                null
                                                                        }

                                                                        {item.links && item.links.length ? item.links.map(link => <a href={link.link} target='_blank' className={`message-link-container ${this.props.uData?._id === item.uid ? 'message-link-container-right' : ''} ${item.links.length > 1 ? 'message-link-adjust-width' : ''}`} onClick={(e) => e.stopPropagation()}>
                                                                            <div className='message-link-image'>
                                                                                <img src={link.image} />
                                                                            </div>
                                                                            <div className='message-link-title-and-link'>
                                                                                <h6>{link.title}</h6>
                                                                                <p>{link.link}</p>
                                                                            </div>
                                                                        </a>) : null}

                                                                        {item.link ?
                                                                            <a href={item.link.link} target='_blank' className={`message-link-container ${this.props.uData?._id === item.uid ? 'message-link-container-right' : ''}`} onClick={(e) => e.stopPropagation()}>
                                                                                <div className='message-link-image'>
                                                                                    <img src={item.link.image} />
                                                                                </div>
                                                                                <div className='message-link-title-and-link'>
                                                                                    <h6>{item.link.title}</h6>
                                                                                    <p>{item.link.link}</p>
                                                                                </div>
                                                                            </a>
                                                                            : null}

                                                                    </div>
                                                                    <div className="date date-out date-white">{this.props.getStringDateTs(item.timestamp, this.props.dateFormat + ' HH:mm')/*moment.unix(item.timestamp).format(`${this.props.dateFormat} HH:mm`)*/}</div>

                                                                    {
                                                                        (item.seen && item.seen.filter(seenMember => seenMember.uid != this.props.uData?._id).length) && (idx == this.state.messages.length - 1 || this.state.showSeen[item?._id]) ?
                                                                            <p>
                                                                                {'Seen by: '.translate(this.props.lang)} {item.seen && item.seen.filter(seenMember => seenMember.uid != this.props.uData?._id && seenMember.uid && seenMember.uid.indexOf('guest') == -1).map((seenMember) => { return memebersDict[seenMember.uid] ? memebersDict[seenMember.uid].name : '' }).join(', ')}
                                                                            </p>
                                                                            :
                                                                            null
                                                                    }

                                                                </div>
                                                            </div>
                                                        )
                                                })
                                            }
                                            {/* {
                                                this.state.userTyping && this.state.conversation && this.state.conversation?._id == this.state.userTypingConversation ?
                                                    <span className="user-typing">{this.state.userTyping.name} {'is typing...'.translate(this.props.lang)}</span>
                                                    :
                                                    null
                                            } */}
                                            {
                                                this.state.userTyping && this.state.conversation && this.state.conversation?._id == this.state.userTypingConversation && this.state.userTyping[this.state.conversation._id] && this.state.userTyping[this.state.conversation._id][this.state.chatType] ?
                                                    <span className="user-typing">{this.state.userTyping[this.state.conversation._id][this.state.chatType].name} {'is typing...'.translate(this.props.lang)}</span>
                                                    :
                                                    null
                                            }
                                            <div className={this.state.writeStatementModal ? 'journal-note-wrap journal-note-in-video show-journal-note' : 'journal-note-wrap journal-note-in-video'}>

                                                <div className="journal-note-header" >
                                                    <div className='title'>
                                                        <h4>{'Write statement'.translate(this.props.lang)}</h4>
                                                    </div>
                                                    <div className="exit-icon" onClick={() => this.setState({ writeStatementModal: false })}>&times;</div>


                                                </div>
                                                {
                                                    this.state.writeStatementModal ?
                                                        <WriteStatement
                                                            {...this.props}
                                                            clinic={this.props.selectedClinic}
                                                            conversation={this.state.conversation}
                                                            updateStatementInConversation={(statement) => {
                                                                let conversation = this.state.conversation;
                                                                conversation.statement = statement;
                                                                this.setState({ conversation })
                                                            }}
                                                            socket={this.props.socket}
                                                            updateStatementLastCreatedPdfInConversation={(statementLastCreatedPdf) => {
                                                                let conversation = this.state.conversation;
                                                                conversation.statementLastCreatedPdf = statementLastCreatedPdf;
                                                                this.setState({ conversation })
                                                            }}
                                                        />
                                                        :
                                                        null
                                                }

                                            </div>
                                            <div className={this.state.journalNote ? 'journal-note-wrap journal-note-in-video show-journal-note' : 'journal-note-wrap journal-note-in-video'}>
                                                <div className="journal-note-header" >
                                                    <div className='title'>
                                                        <h4>{'Journal notes'.translate(this.props.lang)}</h4>
                                                    </div>
                                                    <div className="exit-icon" onClick={() => this.setState({ journalNote: false }, this.removeFromJorunalNotes)}>&times;</div>
                                                </div>
                                                {
                                                    this.state.conversation && this.state.conversation?.referral &&
                                                        (this.state.conversation?.referral.intelligentReferral || (this.state.conversation?.referral.event && this.state.conversation?.referral.event.revisitFormsArray && this.state.conversation?.referral.event.revisitFormsArray.length && this.state.conversation?.referral.event.journalNoteFormSelected && this.state.conversation?.referral.event.revisitFormsArray.filter(el => el.revisitQuestionaryId == this.state.conversation?.referral.event.journalNoteFormSelected).length && this.state.conversation?.referral.event.revisitFormsArray.filter(el => el.revisitQuestionaryId == this.state.conversation?.referral.event.journalNoteFormSelected)[0].intelligentReferral)) && this.state.countOFSections ?
                                                        <div className="journal-note-content">
                                                            <FormGroup>
                                                                <Label>{'Kontaktorsak'.translate(this.props.lang)}</Label>
                                                                <Input type='text'

                                                                    value={kontaktorsak}
                                                                    onChange={(e) => {
                                                                        let conversation = this.state.conversation;
                                                                        conversation.referral.questionaryName = e.target.value;
                                                                        this.setState({ conversation }, () => this.generateIntelligentPdf())
                                                                    }}
                                                                    onBlur={() => this.updateJournalNoteOnExit()}
                                                                />
                                                            </FormGroup>
                                                            {
                                                                this.state.journalNoteIntelligentSections && this.state.journalNoteIntelligentSections.map((questionary, i) => {
                                                                    return (
                                                                        <FormGroup>
                                                                            <Label>{questionary.name ? questionary.name === 'Bedömning' || questionary.name === 'Åtgärd' ? questionary.name.translate(this.props.lang) : questionary.name : ''}</Label>
                                                                            <Input type='textarea'
                                                                                // value={striptags(questionary && questionary.answer ? questionary.answer.replace(/&gt;/g, '>').replace(/&lt;/g, '<') : '')}
                                                                                value={questionary && questionary.answer ? questionary.answer : ''}
                                                                                onChange={(e) => {
                                                                                    let journalNoteIntelligentSections = this.state.journalNoteIntelligentSections;
                                                                                    // journalNoteIntelligentSections[i].answer = striptags(e.target.value);
                                                                                    journalNoteIntelligentSections[i].answer = e.target.value;
                                                                                    this.setState({ journalNoteIntelligentSections }, () => this.generateIntelligentPdf())
                                                                                }}
                                                                                style={{ height: 150 }}
                                                                                onBlur={() => this.updateJournalNoteOnExit()}
                                                                            />
                                                                        </FormGroup>
                                                                    )
                                                                })

                                                            }


                                                            <div className="journal-note-buttons">


                                                                {
                                                                    this.state.htmlJournal ?
                                                                        <PdfSave lang={this.props.lang} html={this.state.htmlJournal} pdfName={this.generatePdfName()} />

                                                                        : null
                                                                }
                                                                <Button color='primary' onClick={() => {
                                                                    navigator.clipboard.writeText(this.state.textClipboard)
                                                                }}>{'Copy text'.translate(this.props.lang)}</Button>
                                                                <Button
                                                                    color='primary'
                                                                    onClick={() => this.updateJournalNoteOnExit(null, true)}
                                                                    disabled={isExportToJournalSystemDisabled}
                                                                >
                                                                    {this.state.loadingJournalNoteExport ? /*'Processing...'.translate(this.props.lang)*/

                                                                        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                                                            <Player
                                                                                onEvent={() => {
                                                                                    if (!this.state.loadingJournalNoteExport) this.stopAnimation();
                                                                                }}
                                                                                onStateChange={this.toggleVisibility}
                                                                                ref={this.player} // set the ref to your class instance
                                                                                autoplay={true}
                                                                                loop={true}
                                                                                controls={true}
                                                                                src="https://assets8.lottiefiles.com/packages/lf20_ZSRKCF.json"
                                                                                style={{ height: "20px", width: "30px" }}
                                                                            ></Player>
                                                                            {'Processing...'.translate(this.props.lang)}
                                                                        </div>
                                                                        :
                                                                        'Export to journal system'.translate(this.props.lang)}
                                                                </Button>
                                                            </div>

                                                        </div>
                                                        :
                                                        this.state.conversation && this.state.conversation?.referral && (!this.state.conversation?.referral.intelligentReferral || ((this.state.conversation?.referral.intelligentReferral || (this.state.conversation?.referral.event && this.state.conversation?.referral.event.revisitFormsArray && this.state.conversation?.referral.event.revisitFormsArray.length && this.state.conversation?.referral.event.journalNoteFormSelected && this.state.conversation?.referral.event.revisitFormsArray.filter(el => el.revisitQuestionaryId == this.state.conversation?.referral.event.journalNoteFormSelected).length && this.state.conversation?.referral.event.revisitFormsArray.filter(el => el.revisitQuestionaryId == this.state.conversation?.referral.event.journalNoteFormSelected)[0].intelligentReferral)) && !this.state.countOFSections)) ?
                                                            <div className="journal-note-content">
                                                                <FormGroup>
                                                                    <Label>{'Kontaktorsak'.translate(this.props.lang)}</Label>
                                                                    <Input type='text' value={kontaktorsak}
                                                                        onChange={(e) => {
                                                                            let journalNoteObj = this.state.journalNoteObj;
                                                                            // journalNoteObj.kontaktorsak = striptags(e.target.value);
                                                                            journalNoteObj.kontaktorsak = e.target.value;
                                                                            this.setState({ journalNoteObj }, () => this.generatePdf())
                                                                        }}
                                                                        onBlur={() => this.updateJournalNoteOnExit()}
                                                                    />
                                                                </FormGroup>

                                                                <FormGroup>
                                                                    <Label>{'Anamnes'.translate(this.props.lang)}</Label>
                                                                    <Input type='textarea'
                                                                        // value={this.state.journalNoteObj.anamnes}
                                                                        // value={this.state.journalNoteObj.anamnes ? this.state.journalNoteObj.anamnes.replace(/&gt;/g, '>').replace(/&lt;/g, '<') : ''}
                                                                        value={this.state.journalNoteObj.anamnes ? this.state.journalNoteObj.anamnes : ''}

                                                                        style={{ height: 100 }} onChange={(e) => {
                                                                            let journalNoteObj = this.state.journalNoteObj;
                                                                            // journalNoteObj.anamnes = striptags(e.target.value);
                                                                            journalNoteObj.anamnes = e.target.value;

                                                                            this.setState({ journalNoteObj }, () => this.generatePdf())
                                                                        }}
                                                                        onBlur={() => this.updateJournalNoteOnExit()}

                                                                    />
                                                                </FormGroup>
                                                                <FormGroup>
                                                                    <Label>{'Status'.translate(this.props.lang)}</Label>
                                                                    <Input type='textarea'
                                                                        // value={this.state.journalNoteObj.status ? this.state.journalNoteObj.status.replace(/&gt;/g, '>').replace(/&lt;/g, '<') : ''}
                                                                        value={this.state.journalNoteObj.status ? this.state.journalNoteObj.status : ''}
                                                                        style={{ height: 100 }} onChange={(e) => {
                                                                            let journalNoteObj = this.state.journalNoteObj;
                                                                            // journalNoteObj.status = striptags(e.target.value);
                                                                            journalNoteObj.status = e.target.value;

                                                                            this.setState({ journalNoteObj }, () => this.generatePdf())
                                                                        }}
                                                                        onBlur={() => this.updateJournalNoteOnExit()}
                                                                    />
                                                                </FormGroup>
                                                                <FormGroup>
                                                                    <Label>{'Bedömning'.translate(this.props.lang)}</Label>
                                                                    <Input type='textarea'
                                                                        // value={this.state.journalNoteObj.bedomning ? this.state.journalNoteObj.bedomning.replace(/&gt;/g, '>').replace(/&lt;/g, '<') : ''}
                                                                        value={this.state.journalNoteObj.bedomning ? this.state.journalNoteObj.bedomning : ''}
                                                                        style={{ height: 100 }} onChange={(e) => {
                                                                            let journalNoteObj = this.state.journalNoteObj;
                                                                            // journalNoteObj.bedomning = striptags(e.target.value);
                                                                            journalNoteObj.bedomning = e.target.value;
                                                                            this.setState({ journalNoteObj }, () => this.generatePdf())
                                                                        }}
                                                                        onBlur={() => this.updateJournalNoteOnExit()}
                                                                    />
                                                                </FormGroup>
                                                                <FormGroup>
                                                                    <Label>{'Åtgärd'.translate(this.props.lang)}</Label>
                                                                    <Input type='textarea'
                                                                        // value={this.state.journalNoteObj.atgard ? this.state.journalNoteObj.atgard.replace(/&gt;/g, '>').replace(/&lt;/g, '<') : ''}
                                                                        value={this.state.journalNoteObj.atgard ? this.state.journalNoteObj.atgard : ''}
                                                                        style={{ height: 100 }} onChange={(e) => {
                                                                            let journalNoteObj = this.state.journalNoteObj;
                                                                            // journalNoteObj.atgard = striptags(e.target.value);
                                                                            journalNoteObj.atgard = e.target.value;
                                                                            this.setState({ journalNoteObj }, () => this.generatePdf())
                                                                        }}
                                                                        onBlur={() => this.updateJournalNoteOnExit()}
                                                                    />
                                                                </FormGroup>

                                                                <div className="journal-note-buttons">

                                                                    {
                                                                        this.state.htmlJournal ?
                                                                            <PdfSave lang={this.props.lang} html={this.state.htmlJournal} pdfName={this.generatePdfName()} />

                                                                            : null
                                                                    }
                                                                    <Button color='primary' onClick={() => {
                                                                        navigator.clipboard.writeText(this.state.textClipboard)
                                                                    }}>{'Copy text'.translate(this.props.lang)}</Button>
                                                                    <Button
                                                                        color='primary'
                                                                        onClick={() => this.updateJournalNoteOnExit(null, true)}
                                                                        disabled={isExportToJournalSystemDisabled}
                                                                    >
                                                                        {this.state.loadingJournalNoteExport ? /*'Processing...'.translate(this.props.lang)*/

                                                                            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                                                                <Player
                                                                                    onEvent={() => {
                                                                                        if (!this.state.loadingJournalNoteExport) this.stopAnimation();
                                                                                    }}
                                                                                    onStateChange={this.toggleVisibility}
                                                                                    ref={this.player} // set the ref to your class instance
                                                                                    autoplay={true}
                                                                                    loop={true}
                                                                                    controls={true}
                                                                                    src="https://assets8.lottiefiles.com/packages/lf20_ZSRKCF.json"
                                                                                    style={{ height: "20px", width: "30px" }}
                                                                                ></Player>
                                                                                {'Processing...'.translate(this.props.lang)}
                                                                            </div>
                                                                            :
                                                                            'Export to journal system'.translate(this.props.lang)}
                                                                    </Button>
                                                                </div>


                                                            </div>
                                                            :
                                                            <div className="journal-note-content">
                                                                <FormGroup>
                                                                    <Label>{'Kontaktorsak'.translate(this.props.lang)}</Label>
                                                                    <Input type='text' value={kontaktorsak}
                                                                        onChange={(e) => {
                                                                            let journalNoteObj = this.state.journalNoteObj;
                                                                            // journalNoteObj.kontaktorsak = striptags(e.target.value);
                                                                            journalNoteObj.kontaktorsak = e.target.value;

                                                                            this.setState({ journalNoteObj }, () => this.generatePdf())
                                                                        }}
                                                                        onBlur={() => this.updateJournalNoteOnExit()}
                                                                    />
                                                                </FormGroup>

                                                                <FormGroup>
                                                                    <Label>{'Anamnes'.translate(this.props.lang)}</Label>
                                                                    <Input type='textarea'
                                                                        // value={this.state.journalNoteObj.anamnes}
                                                                        value={this.state.journalNoteObj.anamnes ? this.state.journalNoteObj.anamnes : ''}
                                                                        style={{ height: 100 }} onChange={(e) => {
                                                                            let journalNoteObj = this.state.journalNoteObj;
                                                                            // journalNoteObj.anamnes = striptags(e.target.value);
                                                                            journalNoteObj.anamnes = e.target.value;
                                                                            this.setState({ journalNoteObj }, () => this.generatePdf())
                                                                        }}
                                                                        onBlur={() => this.updateJournalNoteOnExit()}
                                                                    />
                                                                </FormGroup>
                                                                <FormGroup>
                                                                    <Label>{'Status'.translate(this.props.lang)}</Label>
                                                                    <Input type='textarea'
                                                                        // value={this.state.journalNoteObj.status ? this.state.journalNoteObj.status.replace(/&gt;/g, '>').replace(/&lt;/g, '<') : ''}
                                                                        value={this.state.journalNoteObj.status ? this.state.journalNoteObj.status : ''}
                                                                        style={{ height: 100 }} onChange={(e) => {
                                                                            let journalNoteObj = this.state.journalNoteObj;
                                                                            // journalNoteObj.status = striptags(e.target.value);
                                                                            journalNoteObj.status = e.target.value;
                                                                            this.setState({ journalNoteObj }, () => this.generatePdf())
                                                                        }}
                                                                        onBlur={() => this.updateJournalNoteOnExit()}
                                                                    />
                                                                </FormGroup>
                                                                <FormGroup>
                                                                    <Label>{'Bedömning'.translate(this.props.lang)}</Label>
                                                                    <Input type='textarea'
                                                                        // value={this.state.journalNoteObj.bedomning}
                                                                        value={this.state.journalNoteObj.bedomning ? this.state.journalNoteObj.bedomning : ''}
                                                                        style={{ height: 100 }} onChange={(e) => {
                                                                            let journalNoteObj = this.state.journalNoteObj;
                                                                            // journalNoteObj.bedomning = striptags(e.target.value);
                                                                            journalNoteObj.bedomning = e.target.value;
                                                                            this.setState({ journalNoteObj }, () => this.generatePdf())
                                                                        }}
                                                                        onBlur={() => this.updateJournalNoteOnExit()}
                                                                    />
                                                                </FormGroup>
                                                                <FormGroup>
                                                                    <Label>{'Åtgärd'.translate(this.props.lang)}</Label>
                                                                    <Input type='textarea'
                                                                        // value={this.state.journalNoteObj.atgard}
                                                                        value={this.state.journalNoteObj.atgard ? this.state.journalNoteObj.atgard : ''}
                                                                        style={{ height: 100 }} onChange={(e) => {
                                                                            let journalNoteObj = this.state.journalNoteObj;
                                                                            // journalNoteObj.atgard = striptags(e.target.value);
                                                                            journalNoteObj.atgard = e.target.value;
                                                                            this.setState({ journalNoteObj }, () => this.generatePdf())
                                                                        }}
                                                                        onBlur={() => this.updateJournalNoteOnExit()}
                                                                    />
                                                                </FormGroup>

                                                                <div className="journal-note-buttons">

                                                                    {
                                                                        this.state.htmlJournal ?
                                                                            <PdfSave lang={this.props.lang} html={this.state.htmlJournal} pdfName={this.generatePdfName()} />

                                                                            : null
                                                                    }
                                                                    <Button color='primary' onClick={() => {
                                                                        navigator.clipboard.writeText(this.state.textClipboard)
                                                                    }}>{'Copy text'.translate(this.props.lang)}</Button>
                                                                    <Button
                                                                        color='primary'
                                                                        onClick={() => this.updateJournalNoteOnExit(null, true)}
                                                                        disabled={isExportToJournalSystemDisabled}
                                                                    >
                                                                        {this.state.loadingJournalNoteExport ? /*'Processing...'.translate(this.props.lang)*/

                                                                            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                                                                <Player
                                                                                    onEvent={() => {
                                                                                        if (!this.state.loadingJournalNoteExport) this.stopAnimation();
                                                                                    }}
                                                                                    onStateChange={this.toggleVisibility}
                                                                                    ref={this.player} // set the ref to your class instance
                                                                                    autoplay={true}
                                                                                    loop={true}
                                                                                    controls={true}
                                                                                    src="https://assets8.lottiefiles.com/packages/lf20_ZSRKCF.json"
                                                                                    style={{ height: "20px", width: "30px" }}
                                                                                ></Player>
                                                                                {'Processing...'.translate(this.props.lang)}
                                                                            </div>
                                                                            :
                                                                            'Export to journal system'.translate(this.props.lang)}
                                                                    </Button>
                                                                </div>


                                                            </div>
                                                }
                                            </div>
                                        </div>
                                        {(this.state.conversation && !this.state.conversation?.closed) || (this.state.conversation && this.state.conversation?.closed && this.state.conversation?.softCloseTimestamp && this.state.conversation?.softCloseTimestamp > Math.floor(new Date().getTime() / 1000)) ?
                                            <div className="send-message" ref={(node) => this.sendMessageVideoConatiner = node}>
                                                {/* <button className={this.state._documentUploading ? 'attach uploading' : this.state.document ? 'attach uploaded' : "attach"} onClick={() => { if (this.documentInput) this.documentInput.click() }}><Isvg src={attach} />
                                                    <div className='chat-icons-tooltip'>
                                                        {'Attach file'.translate(this.props.lang)}
                                                    </div>
                                                </button>
                                                <button className={this.state._imageUploading ? 'image uploading' : this.state.image ? 'image uploaded' : "image"} onClick={() => { if (this.imageInput) this.imageInput.click() }}><Isvg src={image} />
                                                    <div className='chat-icons-tooltip'>
                                                        {'Attach image'.translate(this.props.lang)}
                                                    </div>
                                                </button> */}
                                                {
                                                    <div>

                                                        <button className='more-icon' onClick={() => { this.setState({ mobileBtnsShow: !this.state.mobileBtnsShow }) }}><Isvg src={moreIcon} /></button>
                                                        <div className={this.state.mobileBtnsShow ? 'mobile-btns-upload-phrase show-mobile-btns-upload-phrase' : 'mobile-btns-upload-phrase'}>
                                                            <div className="overlay-test" onClick={() => this.setState({ mobileBtnsShow: !this.state.mobileBtnsShow })} />
                                                            <button className={this.state._documentUploading ? 'attach-menu uploading' : this.state.document ? 'attach-menu uploaded' : "attach-menu"} onClick={() => {
                                                                this.setState({ mobileBtnsShow: !this.state.mobileBtnsShow })
                                                                if (this.documentInput) this.documentInput.click()
                                                            }}>
                                                                <div className="button-menu-icon-wrap">
                                                                    <Isvg src={attach} />
                                                                </div>
                                                                <div className='chat-icons-text'>
                                                                    {'Attach file'.translate(this.props.lang)}
                                                                </div>
                                                            </button>


                                                            <button className={this.state._imageUploading ? 'image-menu uploading' : this.state.image ? 'image-menu uploaded' : "image-menu"} onClick={() => {
                                                                this.setState({ mobileBtnsShow: !this.state.mobileBtnsShow })
                                                                if (this.imageInput) this.imageInput.click()
                                                            }}>
                                                                <div className="button-menu-icon-wrap">
                                                                    <Isvg src={image} />
                                                                </div>
                                                                <div className='chat-icons-text'>
                                                                    {'Attach image'.translate(this.props.lang)}
                                                                </div>
                                                            </button>
                                                            <button className={this.state._videoUploading ? 'video-menu uploading' : this.state.video ? 'video-menu uploaded' : "video-menu"} onClick={() => {
                                                                this.setState({ mobileBtnsShow: !this.state.mobileBtnsShow })
                                                                if (this.videoContentInput) this.videoContentInput.click()
                                                            }}>
                                                                <div className="button-menu-icon-wrap">
                                                                    <Isvg src={videoIcon} />
                                                                </div>
                                                                <div className='chat-icons-text'>
                                                                    {'Attach video'.translate(this.props.lang)}
                                                                </div>
                                                            </button>

                                                            {
                                                                this.props.uData && this.props.uData?.userLevel == 20 && localStorage?.getItem('profile') != 'patient' ?
                                                                    <button className={"phrase-menu"} onClick={() => { this.setState({ quickPhraseModal: true, mobileBtnsShow: !this.state.mobileBtnsShow }, this.get) }}>
                                                                        <div className="button-menu-icon-wrap"><Isvg src={phraseIcon} /></div>

                                                                        <div className='chat-icons-text'>
                                                                            {'Quick phrase'.translate(this.props.lang)}
                                                                        </div>
                                                                    </button>
                                                                    :
                                                                    null
                                                            }

                                                            {
                                                                this.props.uData && this.props.uData?.userLevel == 20 && localStorage?.getItem('profile') != 'patient' ?
                                                                    <button className={"health-care-forms-menu"} onClick={() => { this.setState({ healthCareModal: true, mobileBtnsShow: !this.state.mobileBtnsShow }) }}>
                                                                        <div className="button-menu-icon-wrap"><Isvg src={formIcon} /></div>

                                                                        <div className='chat-icons-text'>
                                                                            {'Attach form'.translate(this.props.lang)}
                                                                        </div>
                                                                    </button>
                                                                    :
                                                                    null
                                                            }
                                                            {
                                                                this.props.uData && this.props.uData?.userLevel == 20 && this.state.conversation && (this.state.conversation?.users?.filter?.(item => item.level < 20)?.length || this.state.conversation?.referral) && localStorage?.getItem('profile') != 'patient' ?
                                                                    <button className={"journal-menu"} style={!showJournalNoteButton ? { opacity: 0.8 } : {}} onClick={() => {
                                                                        if (showJournalNoteButton) {
                                                                            this.setState({ mobileBtnsShow: !this.state.mobileBtnsShow })
                                                                            this.findHealthCareFormular()
                                                                        }

                                                                    }}>
                                                                        <div className="button-menu-icon-wrap">
                                                                            <Isvg src={journalIcon} />
                                                                        </div>
                                                                        {
                                                                            showJournalNoteButton ?
                                                                                <div className='chat-icons-text'>
                                                                                    {'Journal note'.translate(this.props.lang)}
                                                                                </div>
                                                                                :
                                                                                <div className='chat-icons-text'>
                                                                                    {'The journal note is not available'.translate(this.props.lang)}
                                                                                </div>
                                                                        }

                                                                    </button>
                                                                    :
                                                                    null
                                                            }
                                                            {
                                                                this.props.uData && this.props.uData?.userLevel >= 20 && (typeof window !== 'undefined' && localStorage?.getItem('profile') !== 'patient') && this.state.conversation && ((this.state.conversation?.users && this.state.conversation?.users?.filter(user => user.level < 20).length > 0) || this.state.conversation?.referral) && enterprise
                                                                    ?
                                                                    <button className='open-diary' onClick={() => this.handleDiaryClick(this.state.conversation)}>
                                                                        <Isvg src={diaryIcon} />
                                                                        <div className='chat-icons-tooltip'>
                                                                            {'Open diary'.translate(this.props.lang)}
                                                                        </div>
                                                                    </button>
                                                                    :
                                                                    null
                                                            }
                                                            {
                                                                writeStatementEnabled ?
                                                                    <button className='write-statement-menu' onClick={() => {
                                                                        if (this.state.writeStatementModal) {
                                                                            this.setState({ mobileBtnsShow: !this.state.mobileBtnsShow, writeStatementModal: false })
                                                                        } else {
                                                                            this.setState({ mobileBtnsShow: !this.state.mobileBtnsShow, writeStatementModal: true, journalNote: false }, () => {
                                                                                this.removeFromJorunalNotes();
                                                                            })
                                                                        }

                                                                    }}>
                                                                        <div className="button-menu-icon-wrap">
                                                                            <Isvg src={writeStatementIcon} />
                                                                        </div>
                                                                        <div className='chat-icons-text'>
                                                                            {'Write statement'.translate(this.props.lang)}
                                                                        </div>

                                                                    </button>
                                                                    :
                                                                    null
                                                            }
                                                        </div>
                                                    </div>

                                                }



                                                <Input className='send-message-textarea'
                                                    onKeyDown={(e) => {
                                                        if (e.keyCode == 13 && !e.shiftKey) {
                                                            e.preventDefault();
                                                            this.sendMessage();
                                                            return false;
                                                        }
                                                        this.emitUserTyping();
                                                    }}
                                                    placeholder={"Write a message...".translate(this.props.lang)}
                                                    type="textarea"
                                                    id={this.state.conversation?._id}
                                                    onChange={(e) => {
                                                        let element = e.target;
                                                        this.setState({
                                                            // messageHeight: 64, 
                                                            message: e.target.value
                                                        }, () => {
                                                            const messageBox = document.getElementById(this.state.conversation._id);

                                                            if (messageBox && messageBox.style.height && Number(messageBox.style.height.replace('px', '')) > 64 && element.scrollHeight <= Number(messageBox.style.height.replace('px', ''))) {
                                                                this.setState({
                                                                    messageHeight: Number(messageBox.style.height.replace('px', ''))
                                                                })
                                                            } else {
                                                                this.setState({ messageHeight: element.scrollHeight < this.messagesRef.clientHeight / 2 ? element.scrollHeight : this.messagesRef.clientHeight / 2 })

                                                            }
                                                        })
                                                    }}
                                                    value={this.state.message}
                                                    style={{ maxHeight: this.messagesRef && this.messagesRef.clientHeight, minHeight: 64, height: this.state.messageHeight && this.state.message && this.state.activeWriteMessageField ? this.state.messageHeight : 64 }}
                                                    onFocus={() => {
                                                        if (!this.state.activeWriteMessageField) {
                                                            this.setState({ activeWriteMessageField: true })
                                                        }
                                                    }}
                                                // onBlur={() => {
                                                //     if (this.state.activeWriteMessageField) {
                                                //         this.setState({ activeWriteMessageField: false })
                                                //     }
                                                // }}
                                                />
                                                {this.state.conversation && this.supportedLanguages && this.supportedLanguages.length && ((this.props.uData?.clinicGroups?.[selectedGroupIdx] && this.props.uData?.clinicGroups?.[selectedGroupIdx]?.haveTranslation) || (this.props.uData?.patientGroups?.[selectedGroupIdxPatient] && this.props.uData?.patientGroups?.[selectedGroupIdxPatient]?.haveTranslation)) ?
                                                    <UncontrolledDropdown className={this.state.conversation && this.state.conversation?.memberLanguages && this.state.conversation?.memberLanguages[this.props.uData?._id] ? "translate translate-active" : "translate"}>
                                                        <DropdownToggle>
                                                            <Isvg src={translateIcon} />
                                                            <div className='chat-icons-tooltip google-translate-tooltip'>
                                                                {'Translation is done by Google and may not work properly!'.translate(this.props.lang)}
                                                            </div>
                                                        </DropdownToggle>
                                                        <DropdownMenu>
                                                            <DropdownItem className={this.state.conversation && this.state.conversation?.memberLanguages && !this.state.conversation?.memberLanguages[this.props.uData?._id] ? 'active' : ''} onClick={() => this.setTranslationLanguage(null)} >{'No translate'.translate(this.props.lang)}</DropdownItem>

                                                            {


                                                                this.supportedLanguages.map((item, idx) => {
                                                                    return (

                                                                        <DropdownItem className={this.state.conversation && this.state.conversation?.memberLanguages && this.state.conversation?.memberLanguages[this.props.uData?._id] == item.code ? 'active' : ''} onClick={() => this.setTranslationLanguage(item.code)} key={idx}>{item.name}</DropdownItem>

                                                                    )
                                                                })

                                                            }
                                                        </DropdownMenu>
                                                    </UncontrolledDropdown>
                                                    :
                                                    null
                                                }
                                                <div className='main-chat-video-resize-icon' onMouseDown={() => {
                                                    this.setState({
                                                        messageRisaze: true
                                                    })
                                                }} onMouseUp={() => {
                                                    this.setState({
                                                        messageRisaze: false
                                                    })
                                                }}>
                                                    <Isvg src={resizeMessageBox} />
                                                </div>
                                                <button disabled={this.state._imageUploading || this.state.sendMessageCheckObj} className="send send-z-index" onClick={this.sendMessage}>
                                                    <Isvg src={send} />
                                                    <div className='chat-icons-tooltip'>
                                                        {'Send'.translate(this.props.lang)}
                                                    </div>
                                                </button>
                                            </div>
                                            :
                                            null
                                        }


                                    </div></>}
                                </div>

                                :

                                null
                        }

                        {
                            this.state.quickPhraseModal ?
                                <Modal isOpen={this.state.quickPhraseModal} size="lg" centered zIndex={9999999}>
                                    <ModalHeader toggle={() => this.setState({ quickPhraseModal: !this.state.quickPhraseModal, searchPhrases: '' })} close={<button type="button" className="close" style={{ position: 'absolute', top: '15px', right: '15px' }} onClick={() => this.setState({ quickPhraseModal: !this.state.quickPhraseModal, searchPhrases: '' })}>&times;</button>}>{'Quick phrases'.translate(this.props.lang)}</ModalHeader>

                                    <ModalBody style={{ paddingTop: 0 }}>
                                        {
                                            !this.state.myPhraseExists ?
                                                <div className="search-box-wrap">
                                                    <FormGroup>
                                                        <Label>{'Search'.translate(this.props.lang)}</Label>
                                                        <Input value={this.state.searchPhrases}
                                                            onChange={(e) => {
                                                                this.setState({ searchPhrases: striptags(e.target.value) }, () => this.get())
                                                            }}
                                                        />
                                                    </FormGroup>
                                                </div>
                                                :
                                                null
                                        }
                                        {
                                            this.state.myPhraseExists ?
                                                <div className="flex-space-between tablet-none notifications-tabs-wrap quick-phrases-tabs" style={{ position: 'relative', marginBottom: '50px', width: "100%", borderBottom: "1px solid #9cafbf", alignItems: 'flex-end' }}>
                                                    <div className="notifications-tabs tabs-buttons-wrap" style={{ marginBottom: 0, borderBottom: 0 }}>
                                                        <Button className={this.state.phraseTab == 1 ? "tab-button acitve-tab-button" : "tab-button"}
                                                            onClick={() => this.setState({ phraseTab: 1 })}
                                                        >{"My phrases".translate(this.props.lang)}</Button>
                                                        <Button className={this.state.phraseTab == 2 ? "tab-button acitve-tab-button" : "tab-button"}
                                                            onClick={() => this.setState({ phraseTab: 2 })}
                                                        >{"Clinic phrases".translate(this.props.lang)}</Button>
                                                    </div>
                                                    <div className="search-box-wrap">
                                                        <FormGroup>
                                                            <Label>{'Search'.translate(this.props.lang)}</Label>
                                                            <Input value={this.state.searchPhrases}
                                                                onChange={(e) => {
                                                                    this.setState({ searchPhrases: striptags(e.target.value) }, () => this.get())
                                                                }}
                                                            />
                                                        </FormGroup>
                                                    </div>
                                                </div>
                                                :
                                                null

                                        }
                                        {
                                            !this.state.myPhraseExists || (this.state.myPhraseExists && this.state.phraseTab == 2) ?
                                                this.state.phrases && this.state.phrases.map((item, idx) => {
                                                    return (
                                                        <div className="quick-phrase-wrap">
                                                            <div className="content">
                                                                <div className="title">{item.title}</div>
                                                                <div className="phrase">
                                                                    {
                                                                        item.phrase && item.phrase.split('\n').length ?
                                                                            item.phrase.split('\n').map((text, t) => {
                                                                                if (t != item.phrase.split('\n').length - 1)
                                                                                    return (
                                                                                        <>{text}<br /></>
                                                                                    )
                                                                                else
                                                                                    return (
                                                                                        text
                                                                                    )
                                                                            })
                                                                            :
                                                                            item.phrase
                                                                    }
                                                                </div>
                                                            </div>
                                                            <Button color='primary' onClick={() => {
                                                                this.setState({ message: item.phrase, quickPhraseModal: false, searchPhrases: '' })
                                                            }}>{'Use'.translate(this.props.lang)}</Button>

                                                        </div>
                                                    )
                                                })
                                                :
                                                null
                                        }
                                        {
                                            this.state.myPhraseExists && this.state.phraseTab == 1 ?
                                                this.state.myPhrases && this.state.myPhrases.map((item, idx) => {
                                                    return (
                                                        <div className="quick-phrase-wrap">
                                                            <div className="content">
                                                                <div className="title">{item.title}</div>
                                                                <div className="phrase">
                                                                    {
                                                                        item.phrase && item.phrase.split('\n').length ?
                                                                            item.phrase.split('\n').map((text, t) => {
                                                                                if (t != item.phrase.split('\n').length - 1)
                                                                                    return (
                                                                                        <>{text}<br /></>
                                                                                    )
                                                                                else
                                                                                    return (
                                                                                        text
                                                                                    )
                                                                            })
                                                                            :
                                                                            item.phrase
                                                                    }
                                                                </div>
                                                            </div>
                                                            <Button color='primary' onClick={() => {
                                                                this.setState({ message: item.phrase, quickPhraseModal: false, searchPhrases: '' })
                                                            }}>{'Use'.translate(this.props.lang)}</Button>

                                                        </div>
                                                    )
                                                })
                                                :
                                                null
                                        }


                                    </ModalBody>

                                    <ModalFooter style={{ display: 'flex', justifyContent: 'flex-end' }}>

                                        <Button color="primary" onClick={() => this.setState({ quickPhraseModal: null, searchPhrases: '' })}> {'Close'.translate(this.props.lang)} </Button>

                                    </ModalFooter>



                                </Modal>
                                :
                                null
                        }
                        {
                            this.state.healthCareModal ?
                                <Modal isOpen={this.state.healthCareModal} size="lg" centered >
                                    <ModalHeader toggle={() => this.setState({ healthCareModal: !this.state.healthCareModal })} close={<button type="button" className="close" style={{ position: 'absolute', top: '15px', right: '15px' }} onClick={() => this.setState({ healthCareModal: !this.state.healthCareModal })}>&times;</button>}>{'Chat forms'.translate(this.props.lang)}</ModalHeader>

                                    <ModalBody>
                                        <div className="search-box-wrap">
                                            <FormGroup>
                                                <Label>{'Search'.translate(this.props.lang)}</Label>
                                                <Input value={this.state.searchHealthCare}
                                                    onChange={(e) => {
                                                        this.setState({ searchHealthCare: striptags(e.target.value) }, () => this.get())
                                                    }}
                                                />
                                            </FormGroup>
                                        </div>
                                        <div className="general-health-care-wrap">
                                            {
                                                this.state.generalHealthCare && this.state.generalHealthCare.items && this.state.generalHealthCare.items.map((item, idx) => {
                                                    return (
                                                        <div className="health-care-wrap">
                                                            <div className="content">
                                                                <div className="title">
                                                                    {item.name}
                                                                    <span>
                                                                        &nbsp;&nbsp;/&nbsp;&nbsp;
                                                                        {item.description}</span>
                                                                </div>

                                                            </div>
                                                            <Button color='primary' onClick={() => {
                                                                this.setState({ healthCareModal: false }, () => this.sendHealthCare(item))
                                                            }}>{'Send'.translate(this.props.lang)}</Button>

                                                        </div>
                                                    )
                                                })
                                            }
                                        </div>


                                    </ModalBody>

                                    <ModalFooter style={{ display: 'flex', justifyContent: 'flex-end' }}>

                                        <Button color="primary" onClick={() => this.setState({ healthCareModal: null })}> {'Close'.translate(this.props.lang)} </Button>

                                    </ModalFooter>



                                </Modal>
                                :
                                null
                        }
                        {
                            this.state.questionaryModal ?
                                <Modal isOpen={this.state.questionaryModal} size="lg" centered >
                                    <ModalHeader toggle={() => this.setState({ questionaryModal: null, settedRef: false, referralContentRef: null, activeQuestionary: null, questionaryMessageActive: null, activeReferralContent: null, activeHealthCareReferralObject: null, activeClinicChatForm: null, sections: null, validationQuestionary: false, validationIntelligentQuestionary: false })} close={<button type="button" className="close" style={{ position: 'absolute', top: '15px', right: '15px' }} onClick={() => this.setState({ questionaryModal: null, settedRef: false, activeQuestionary: null, questionaryMessageActive: null, activeReferralContent: null, activeHealthCareReferralObject: null, activeClinicChatForm: null, sections: null, validationQuestionary: false, validationIntelligentQuestionary: false })}>&times;</button>}>{this.state.activeReferralContent ? ''.translate(this.props.lang) : 'Run questionary'.translate(this.props.lang)}</ModalHeader>

                                    <ModalBody style={this.state.activeReferralContent ? { maxHeight: '80vh', overflowY: 'auto' } : {}}>
                                        {
                                            this.state.activeHealthCareReferralObject && this.state.activeClinicChatForm?.clinicLogo ?
                                                <div ref={(node) => { if (!this.state.referralContentRef) this.setState({ referralContentRef: node }, this.forceUpdate) }}>

                                                    <ChatFormFirstSection
                                                        {...this.props}
                                                        clinic={this.state.activeHealthCareReferralObject?.clinic}
                                                        clinicData={this.state.activeClinicChatForm}
                                                        tsCreated={this.state.activeHealthCareReferralObject?.tsCreated}
                                                        patient={this.state.activeHealthCareReferralObject?.patient}
                                                    />
                                                </div>

                                                :
                                                null
                                        }


                                        {/* {
                                            // this.state.activeReferralContent ?
                                            <div ref={(node) => { if (!this.state.referralContentRef) this.setState({ referralContentRef: node }, this.forceUpdate) }}>
                                                <div dangerouslySetInnerHTML={{ __html: this.state.activeReferralContent }} onChange={() => { }}></div>

                                                <br />
                                            </div>

                                            // :
                                            // null
                                        } */}
                                        {
                                            this.state.activeQuestionary ?
                                                <>
                                                    {
                                                        !this.state.activeQuestionary?.intelligentReferral ?
                                                            this.state.activeReferralContent ?
                                                                <PreviewAnsweredChatForm {...this.props} answer={(item) => {
                                                                    this.setState({ questionaryAnswers: item })
                                                                }}
                                                                    referral={{
                                                                        name: this.state.activeQuestionary.name,
                                                                        description: this.state.activeQuestionary.description,
                                                                        intelligentReferral: this.state.activeQuestionary?.intelligentReferral,
                                                                        items: this.state.activeQuestionary.items
                                                                    }}
                                                                    referralContentRef={this.state.referralContentRef}
                                                                    alias={typeof window != 'undefined' && localStorage?.groupAlias ? localStorage?.groupAlias : null}
                                                                    disabledForm={typeof window != 'undefined' && localStorage?.getItem?.('profile') == 'doctor' && this.props.uData && this.props.uData?.userLevel == 20 ? true : false}
                                                                    answers={this.state.questionaryAnswers}
                                                                    forPrint={this.state.activeReferralContent ? true : false}
                                                                    sections={this.generateSections()}></PreviewAnsweredChatForm>
                                                                :
                                                                <ChatForms {...this.props} answer={(item, isEnd) => {
                                                                    this.setState({ questionaryAnswers: item, submitEnabled: isEnd })
                                                                }}
                                                                    referral={{
                                                                        name: this.state.activeQuestionary.name,
                                                                        description: this.state.activeQuestionary.description,
                                                                        intelligentReferral: this.state.activeQuestionary?.intelligentReferral,
                                                                        items: this.state.activeQuestionary.items
                                                                    }}
                                                                    referralContentRef={this.state.referralContentRef}
                                                                    alias={typeof window != 'undefined' && localStorage?.groupAlias ? localStorage?.groupAlias : null}
                                                                    disabledForm={typeof window != 'undefined' && localStorage?.getItem?.('profile') == 'doctor' && this.props.uData && this.props.uData?.userLevel == 20 ? true : false}
                                                                    answers={this.state.questionaryAnswers}
                                                                    forPrint={this.state.activeReferralContent ? true : false}
                                                                    sections={this.generateSections()}></ChatForms>
                                                            :
                                                            this.state.activeReferralContent ?
                                                                <PreviewAnsweredChatForm {...this.props}
                                                                    referral={{
                                                                        name: this.state.activeQuestionary.name,
                                                                        description: this.state.activeQuestionary.description,
                                                                        intelligentReferral: this.state.activeQuestionary?.intelligentReferral,
                                                                        items: this.state.activeQuestionary.items
                                                                    }}
                                                                    answer={(item, isEnd) => {
                                                                        this.setState({ questionaryAnswers: item, validationIntelligentQuestionary: !isEnd, validationQuestionary: false })
                                                                    }}
                                                                    referralContentRef={this.state.referralContentRef}
                                                                    alias={typeof window != 'undefined' && localStorage?.groupAlias ? localStorage?.groupAlias : null}
                                                                    disabledForm={typeof window != 'undefined' && localStorage?.getItem?.('profile') == 'doctor' && this.props.uData && this.props.uData?.userLevel == 20 ? true : false}
                                                                    answers={this.state.questionaryAnswers}
                                                                    forPrint={this.state.activeReferralContent ? true : false}
                                                                    sections={this.generateSections()}></PreviewAnsweredChatForm>
                                                                :
                                                                <ChatForms {...this.props}
                                                                    referral={{
                                                                        name: this.state.activeQuestionary.name,
                                                                        description: this.state.activeQuestionary.description,
                                                                        intelligentReferral: this.state.activeQuestionary?.intelligentReferral,
                                                                        items: this.state.activeQuestionary.items
                                                                    }}
                                                                    alias={typeof window != 'undefined' && localStorage?.groupAlias ? localStorage?.groupAlias : null}
                                                                    answer={(item, isEnd) => {
                                                                        this.setState({ questionaryAnswers: item, validationIntelligentQuestionary: !isEnd, validationQuestionary: false, submitEnabled: isEnd })
                                                                    }}
                                                                    referralContentRef={this.state.referralContentRef}

                                                                    disabledForm={typeof window != 'undefined' && localStorage?.getItem?.('profile') == 'doctor' && this.props.uData && this.props.uData?.userLevel == 20 ? true : false}
                                                                    answers={this.state.questionaryAnswers}
                                                                    forPrint={this.state.activeReferralContent ? true : false}
                                                                    sections={this.generateSections()}></ChatForms>
                                                    }
                                                </>

                                                :
                                                null
                                        }

                                        {
                                            this.state.validationQuestionary ?
                                                <p style={{ color: 'red' }}>{'Please fill in all fields'.translate(this.props.lang)}</p>
                                                :
                                                null
                                        }
                                        {
                                            this.state.activeQuestionary && this.state.activeReferralContent && this.state.questionaryAnswers && this.state.activeHealthCareReferralObject && this.state.activeClinicChatForm?.clinicLogo ?

                                                <div style={{ position: 'fixed', right: '100%' }}>
                                                    <div ref={(node) => { this.referralRef = node; if (!this.state.settedRef) this.setState({ settedRef: true }) }} className="print-referral-ref">

                                                        {
                                                            this.state.activeHealthCareReferralObject && this.state.activeClinicChatForm?.clinicLogo ?
                                                                <div ref={(node) => { if (!this.state.referralContentRef) this.setState({ referralContentRef: node }, this.forceUpdate) }} style={{ paddingTop: 30 }}>

                                                                    <ChatFormFirstSection
                                                                        {...this.props}
                                                                        clinic={this.state.activeHealthCareReferralObject?.clinic}
                                                                        clinicData={this.state.activeClinicChatForm}
                                                                        tsCreated={this.state.activeHealthCareReferralObject?.tsCreated}
                                                                        patient={this.state.activeHealthCareReferralObject?.patient}
                                                                    />
                                                                </div>

                                                                :
                                                                null
                                                        }
                                                        {/* {
                                                            this.state.activeReferralContent ?
                                                                <div ref={(node) => { if (!this.state.referralContentRef) this.setState({ referralContentRef: node }, this.forceUpdate) }}>

                                                                    <div dangerouslySetInnerHTML={{ __html: this.state.activeReferralContent }} onChange={() => { }}></div>

                                                                    <br />

                                                                </div>

                                                                :
                                                                null
                                                        } */}
                                                        {
                                                            !this.state.activeQuestionary?.intelligentReferral ?

                                                                this.state.activeReferralContent && this.state.referralContentRef ?
                                                                    this.state.activeReferralContent ?
                                                                        <PreviewAnsweredChatForm
                                                                            {...this.props}
                                                                            answer={(item) => {
                                                                                this.setState({ questionaryAnswers: item })
                                                                            }}
                                                                            referralContentRef={this.state.referralContentRef}
                                                                            referral={{
                                                                                name: this.state.activeQuestionary.name,
                                                                                description: this.state.activeQuestionary.description,
                                                                                intelligentReferral: this.state.activeQuestionary?.intelligentReferral,
                                                                                items: this.state.activeQuestionary.items
                                                                            }}
                                                                            alias={typeof window != 'undefined' && localStorage?.groupAlias ? localStorage?.groupAlias : null}
                                                                            disabledForm={typeof window != 'undefined' && localStorage?.getItem?.('profile') == 'doctor' && this.props.uData && this.props.uData?.userLevel == 20 ? true : false}
                                                                            answers={this.state.questionaryAnswers}
                                                                            forPrint={this.state.activeReferralContent ? true : false}
                                                                            forPrintHtml={true}
                                                                            sections={this.generateSections()}></PreviewAnsweredChatForm>
                                                                        :
                                                                        <ChatForms
                                                                            {...this.props}
                                                                            answer={(item, isEnd) => {
                                                                                this.setState({ questionaryAnswers: item, submitEnabled: isEnd })
                                                                            }}
                                                                            alias={typeof window != 'undefined' && localStorage?.groupAlias ? localStorage?.groupAlias : null}
                                                                            referralContentRef={this.state.referralContentRef}
                                                                            referral={{ name: this.state.activeQuestionary.name, description: this.state.activeQuestionary.description, intelligentReferral: this.state.activeQuestionary?.intelligentReferral, items: this.state.activeQuestionary.items }}
                                                                            disabledForm={typeof window != 'undefined' && localStorage?.getItem?.('profile') == 'doctor' && this.props.uData && this.props.uData?.userLevel == 20 ? true : false}
                                                                            answers={this.state.questionaryAnswers}
                                                                            forPrint={this.state.activeReferralContent ? true : false}
                                                                            forPrintHtml={true}
                                                                            sections={this.generateSections()}></ChatForms>
                                                                    :
                                                                    null

                                                                :
                                                                this.state.referralContentRef ?
                                                                    <PreviewAnsweredChatForm
                                                                        {...this.props}
                                                                        answer={(item, isEnd) => {
                                                                            this.setState({ questionaryAnswers: item, validationIntelligentQuestionary: !isEnd, validationQuestionary: false })
                                                                        }}
                                                                        referralContentRef={this.state.referralContentRef}
                                                                        referral={{
                                                                            name: this.state.activeQuestionary.name,
                                                                            description: this.state.activeQuestionary.description,
                                                                            intelligentReferral: this.state.activeQuestionary?.intelligentReferral,
                                                                            items: this.state.activeQuestionary.items
                                                                        }}
                                                                        alias={typeof window != 'undefined' && localStorage?.groupAlias ? localStorage?.groupAlias : null}
                                                                        disabledForm={true}
                                                                        answers={this.state.questionaryAnswers}
                                                                        forPrint={true}
                                                                        sections={this.generateSections()}></PreviewAnsweredChatForm>
                                                                    :
                                                                    null

                                                        }

                                                    </div>

                                                </div>
                                                :
                                                null
                                        }
                                    </ModalBody>

                                    <ModalFooter style={{ display: 'flex', justifyContent: 'flex-end' }} className={`buttons-right-blue ${!this.state.questionarySave && ((this.props.uData && this.props.uData?.userLevel == 1) || (this.props.uData && this.props.uData?.userLevel == 2) || ((typeof window != 'undefined' && localStorage?.getItem?.('profile') == 'patient') && (this.props.uData && this.props.uData?.userLevel == 20))) ? 'modal-footer-last-button-green' : ''}`}>
                                        <button style={{ visibility: 'hidden', display: 'none' }} aria-hidden='true'></button>
                                        {this.state.activeHealthCareReferralObject?._id ?
                                            <Button disabled={!this.state.activeHealthCareReferralObject._id} color="primary" className='green-button' onClick={() => this.genareteChatFormPdf(this.state.activeHealthCareReferralObject._id)}> {'Save as pdf'.translate(this.props.lang)} </Button>
                                            :
                                            null
                                        }
                                        {/* {
                                            this.state.activeReferralContent && this.referralRef && this.state.settedRef ?
                                                <PrintHelper lang={this.props.lang} element={this.referralRef} />
                                                :
                                                null
                                        }
                                        {
                                            this.state.activeReferralContent && this.referralRef && this.state.settedRef ?
                                                <PdfSaveV2 lang={this.props.lang} element={this.referralRef} pdfName={this.state.activeQuestionary && this.state.activeQuestionary.name && generateAlias(this.state.activeQuestionary.name) + '_' + this.props.getStringDateTs(Math.floor(new Date().getTime() / 1000), this.props.dateFormat)} />
                                                :
                                                null
                                        } */}
                                        <Button color="primary" onClick={() => this.setState({ questionaryModal: null, settedRef: false, referralContentRef: null, activeQuestionary: null, questionaryMessageActive: null, activeReferralContent: null, activeHealthCareReferralObject: null, activeClinicChatForm: null, sections: null, validationQuestionary: false, validationIntelligentQuestionary: false })}> {'Close'.translate(this.props.lang)} </Button>
                                        {
                                            !this.state.questionarySave && ((this.props.uData && this.props.uData?.userLevel == 1) || (this.props.uData && this.props.uData?.userLevel == 2) || ((typeof window != 'undefined' && localStorage?.getItem?.('profile') == 'patient') && (this.props.uData && this.props.uData?.userLevel == 20))) && this.state.submitEnabled ?
                                                <Button color="primary" className='green-button' onClick={() => this.sendHealthCareQuestionary()}> {'Send'.translate(this.props.lang)} </Button>
                                                :
                                                null
                                        }
                                        <button style={{ visibility: 'hidden', display: 'none' }} aria-hidden='true'></button>
                                    </ModalFooter>



                                </Modal>
                                :
                                null
                        }

                        {this.state.exportToJournalSystemError ?

                            <ErrorModal lang={this.props.lang}
                                isOpen={this.state.exportToJournalSystemError}
                                toggle={() => this.setState({ exportToJournalSystemError: null })}

                            >
                                {this.state.exportToJournalSystemError.translate(this.props.lang)}
                            </ErrorModal>

                            :
                            null
                        }
                        {this.state.exportToJournalSystemSuccessfully ?
                            <InfoModal
                                isOpen={this.state.exportToJournalSystemSuccessfully}

                                toggle={() => this.setState({ exportToJournalSystemSuccessfully: null })}
                                header={'Info'.translate(this.props.lang)}
                                info={this.state.exportToJournalSystemSuccessfully.translate(this.props.lang)}
                                buttons={[
                                    <Button color='primary' onClick={() => {
                                        this.setState({
                                            exportToJournalSystemSuccessfully: null
                                        })

                                    }}>{'Ok'.translate(this.props.lang)}</Button>
                                ]}
                            />

                            :

                            null}

                        {this.state.error ?

                            <ErrorModal lang={this.props.lang}
                                isOpen={this.state.error}
                                toggle={() => this.setState({ error: null })}

                            >
                                {this.state.error.translate(this.props.lang)}
                            </ErrorModal>

                            :
                            null
                        }
                        {this.state.endCallModal ?
                            <Modal isOpen={this.state.endCallModal} style={{ marginTop: '50px' }} centered zIndex={99999999}>
                                {/* <ModalHeader style={{ margin: 'auto' }}>{'Warning'.translate(this.props.lang)}</ModalHeader> */}
                                <ModalBody className="delete-modal">
                                    {/* <img src={warrning} /> */}
                                    <div style={{ marginTop: 10 }}>{'Do you really want to end this call?'.translate(this.props.lang)}</div>
                                </ModalBody>
                                <ModalFooter className='buttons-right-blue'>
                                    <Button color='primary' style={{ margin: 5, backgroundColor: "#3A3F56", minWidth: 100 }} onClick={() => {
                                        if (this.props.uData && this.state.conversation && this.state.conversation?.members && this.state.conversation?.members[0] == this.props.uData?._id) {
                                            this.endCall();
                                        } else {
                                            if (this.state.videoElements.length == 0) {
                                                if (!this.props.uData) {
                                                    this.endCall(true);
                                                } else {
                                                    this.endCall(false);
                                                }
                                            } else {
                                                this.endCall();
                                            }
                                        }
                                    }}>{'Yes'.translate(this.props.lang)}</Button>
                                    <Button color='primary' style={{ margin: 5, minWidth: 100 }} onClick={() => this.setState({ endCallModal: !this.state.endCallModal })}>{'No'.translate(this.props.lang)}</Button>
                                </ModalFooter>
                            </Modal>
                            : null}


                        <input
                            ref={(node) => this.documentInput = node}
                            type="file" accept=".doc, .docx, .pdf"
                            key={this.state.uploadTimestampDoc}
                            onChange={(e) => {
                                let input = e?.target;
                                let fileName = input?.files && input?.files[0] && input?.files[0].name ? input?.files[0].name : null;
                                let extension = fileName && fileName.indexOf('.') !== -1 && fileName.split('.')[1] ? fileName.split('.')[1] : null;
                                extension = extension?.toLowerCase();
                                let type = 'document';
                                for (var key in UPLOAD_EXTENSIONS) {
                                    if (UPLOAD_EXTENSIONS[key] && UPLOAD_EXTENSIONS[key].indexOf(extension) !== -1) {
                                        type = key;
                                    }
                                }
                                if (type === 'video') this.uploadVideo(e);
                                else if (type === 'image') this.uploadImage(e);
                                else this.uploadDocument(e)

                            }}
                            style={{ pointerEvents: 'none', opacity: 0 }}
                        />
                        <input
                            ref={(node) => this.imageInput = node}
                            type="file"
                            accept="image/png, image/jpeg, image/jpg, image/gif, image/svg"
                            key={this.state.uploadTimestamp}
                            onChange={(e) => {
                                let input = e?.target;
                                let fileName = input?.files && input?.files[0] && input?.files[0].name ? input?.files[0].name : null;
                                let extension = fileName && fileName.indexOf('.') !== -1 && fileName.split('.')[1] ? fileName.split('.')[1] : null;
                                extension = extension?.toLowerCase();
                                let type = 'image';
                                for (var key in UPLOAD_EXTENSIONS) {
                                    if (UPLOAD_EXTENSIONS[key] && UPLOAD_EXTENSIONS[key].indexOf(extension) !== -1) {
                                        type = key;
                                    }
                                }
                                if (type === 'video') this.uploadVideo(e);
                                else if (type === 'document') this.uploadDocument(e);
                                else this.uploadImage(e)

                            }}
                            style={{ pointerEvents: 'none', opacity: 0 }}
                        />
                        <input
                            ref={(node) => this.videoContentInput = node}
                            type="file"
                            accept=".mp4, .avi, .mov"
                            key={this.state.uploadTimestampVideo}
                            onChange={(e) => {
                                let input = e?.target;
                                let fileName = input?.files && input?.files[0] && input?.files[0].name ? input?.files[0].name : null;
                                let extension = fileName && fileName.indexOf('.') !== -1 && fileName.split('.')[1] ? fileName.split('.')[1] : null;
                                extension = extension?.toLowerCase();
                                let type = 'video';
                                for (var key in UPLOAD_EXTENSIONS) {
                                    if (UPLOAD_EXTENSIONS[key] && UPLOAD_EXTENSIONS[key].indexOf(extension) !== -1) {
                                        type = key;
                                    }
                                }
                                if (type === 'image') this.uploadImage(e);
                                else if (type === 'document') this.uploadDocument(e);
                                else this.uploadVideo(e)

                            }}
                            style={{ pointerEvents: 'none', opacity: 0 }}
                        />
                        {
                            this.state.memberTooltip && this.state.mainVideoElement != this.state.memberTooltip.client ?
                                <div className="member-name-tooltop" style={{ top: this.state.memberTooltip.top, left: this.state.memberTooltip.left, }}>
                                    {this.state.memberTooltip.name}
                                </div>
                                :

                                null
                        }

                        {/*
                            typeof window != 'undefined' && iosDevice() && !this.state.soundAllowed && this.state.videoElements && this.state.videoElements.length && this.state.haveRemoteTrack ?

                                <div className="allow-sound-modal">
                                    <div>
                                        <h6>{'Allow video call sound'.translate(this.props.lang)}</h6>
                                        <Button color="primary" onClick={() => this.allowIOSSound()}>{'Allow'.translate(this.props.lang)}</Button>
                                    </div>
                                </div>


                                :

                                null*/
                        }

                    </div>
                    {
                        <div>
                            <audio ref={(node) => this.audioRef = node} >
                                <source src={notificationSound} type="audio/ogg"></source>
                                <source src={notificationSound} type="audio/mpeg"></source>
                            </audio>
                        </div>
                    }
                    {this.state.settings ?
                        <div className="web-rtc-test">
                            <div>
                                <Row>
                                    <Col lg="8">
                                        {this.state.videoInput && this.state.videoInput.length ?

                                            <FormGroup>
                                                <Label>{'Video source'.translate(this.props.lang)}</Label>
                                                <div>
                                                    <Input type="select" value={this.state.videoInputDevice} onChange={(e) => this.setState({ videoInputDevice: e.target.value }, () => this.getStream(true))}>
                                                        {
                                                            this.state.videoInput ?
                                                                this.state.videoInput.map((item) => {
                                                                    return <option value={item.deviceId}>{item.label}</option>
                                                                })
                                                                :

                                                                null
                                                        }
                                                    </Input>
                                                    <Isvg src={cameraIcon} />
                                                </div>
                                            </FormGroup>
                                            :
                                            null
                                        }

                                        {this.state.videoInput && this.state.videoInput.length ?
                                            <FormGroup>
                                                <Label>{'Video resolution (maximum)'.translate(this.props.lang)}</Label>
                                                <div>
                                                    <Input type="select" value={this.state.videoResolution} onChange={(e) => this.setState({ videoResolution: e.target.value }, () => this.getStream(true))}>
                                                        <option value={'480'}>{'Standard definition'.translate(this.props.lang)}</option>
                                                        <option value={'720'}>{'High definition'.translate(this.props.lang)}</option>
                                                        {/* <option disabled={this.state.videoElements.length >= 10 || !this.state.fullHDSupport} value={'1080'}>1080p</option> */}
                                                    </Input>
                                                    <Isvg src={cameraIcon} />
                                                </div>
                                            </FormGroup>
                                            :
                                            null
                                        }


                                        {this.state.audioInput && this.state.audioInput.length ?

                                            <FormGroup>
                                                <Label>{'Audio source'.translate(this.props.lang)}</Label>
                                                <div>
                                                    <Input type="select" value={this.state.audioInputDevice} onChange={(e) => this.setState({ audioInputDevice: e.target.value }, () => this.getStream(true))}>
                                                        {
                                                            this.state.audioInputFilter && this.state.audioInputFilter.length > 0 ?
                                                                this.state.audioInputFilter.map((item, idx) => {
                                                                    return <option value={item.deviceId}>{item.label}</option>
                                                                })
                                                                :
                                                                this.state.audioInput ?
                                                                    this.state.audioInput.map((item) => {
                                                                        return <option value={item.deviceId}>{item.label}</option>
                                                                    })
                                                                    :

                                                                    null
                                                        }
                                                    </Input>
                                                    <Isvg src={micIcon} />

                                                </div>
                                            </FormGroup>
                                            :
                                            null
                                        }
                                        {this.state.audioOutput && this.state.audioOutput.length ?

                                            <FormGroup>
                                                <Label>{'Audio output'.translate(this.props.lang)}</Label>
                                                <div>
                                                    <Input type="select" onChange={(e) => {
                                                        this.setState({
                                                            audioOutputDevice: e.target.value
                                                        }, () => {
                                                            for (var uuid in this.peerStreams) {
                                                                let remoteVideo = this.remoteVideo[uuid]
                                                                if (remoteVideo) {
                                                                    if (this.state.audioOutputDevice)
                                                                        this.attachSinkId(remoteVideo, this.state.audioOutputDevice);
                                                                }
                                                            }
                                                            if (this.state.audioOutputDevice)
                                                                this.attachSinkIdTestSound(this.state.audioOutputDevice);
                                                        })



                                                    }} value={this.state.audioOutputDevice}>
                                                        {
                                                            this.state.audioOutputFilter && this.state.audioOutputFilter.length > 0 ?
                                                                this.state.audioOutputFilter.map((item, idx) => {
                                                                    return <option value={item.deviceId}>{item.label}</option>
                                                                })
                                                                :
                                                                this.state.audioOutput ?
                                                                    this.state.audioOutput.map((item, idx) => {
                                                                        return <option value={item.deviceId}>{item.label}</option>
                                                                    })
                                                                    :

                                                                    null
                                                        }
                                                    </Input>
                                                    <Isvg src={speakerIcon} />

                                                </div>
                                            </FormGroup>
                                            :
                                            null
                                        }

                                    </Col>
                                    <Col lg="4" className="preview-content">
                                        <video ref={(node) => this.previewVideo = node} autoPlay playsInline />
                                        <div className="audio-meter-wrap">
                                            <Isvg src={micIcon} />

                                            <div className="audio-meter"><div style={{ width: (this.state.micMeterValue * 100) + '%' }}></div></div>

                                        </div>
                                        <div className="control-buttons test-sound-button">
                                            {
                                                <button onClick={() => {
                                                    this.audioRef.play()
                                                }} className={"camera-button off"}><Isvg src={soundTest} />   {'Test sound'.translate(this.props.lang)}</button>

                                            }
                                        </div>
                                        <div className="control-buttons">
                                            <button onClick={this.toggleCamera} className={this.state.cameraOff ? "camera-button off" : "camera-button"}>{this.state.cameraOff ? <Isvg src={cameraOffIcon} /> : <Isvg src={cameraIcon} />}  <div className="chat-icons-tooltip-off">{'Turn off camera'.translate(this.props.lang)}</div> <div className="chat-icons-tooltip-on">{'Turn on camera'.translate(this.props.lang)}</div> </button>
                                            <button style={this.state.mutedByHost ? { cursor: 'not-allowed' } : {}} onClick={this.toggleMic} className={this.state.micOff ? "mic-button off" : "mic-button on"}>{this.state.micOff ? <Isvg src={micMutedIcon} /> : <Isvg src={micIcon} />} <div className="chat-icons-tooltip-off">{'Turn off microphone'.translate(this.props.lang)}</div> <div className="chat-icons-tooltip-on">{'Turn on microphone'.translate(this.props.lang)}</div></button>
                                        </div>
                                    </Col>
                                    <Col lg="12">
                                        <Button color="primary" onClick={() => {
                                            this.setState({
                                                settings: null
                                            })
                                        }
                                        }>{'Close'.translate(this.props.lang)}</Button>
                                    </Col>
                                </Row>

                            </div>

                        </div>
                        :
                        null

                    }
                    {this.props.uData && this.state.conversation && this.state.conversation?.members && this.state.conversation?.members[0] == this.props.uData?._id && this.state.videoCallClients.length ?
                        <div className={this.state.newClientConnectedNotification && this.state.startNewClientAnimationColorChange ? `members-card new-client-notif ${this.state.showMembers ? 'show-members' : ''}` : `members-card ${this.state.showMembers ? 'show-members' : ''}`}
                            onMouseLeave={() => this.setState({ newClientConnectedNotification: false, showMembers: false }, () => {
                                clearInterval(this.startNewClientAnimationInterval)
                            })} >

                            <div>
                                <button onMouseEnter={() => this.setState({
                                    showMembers: true
                                })} onClick={() => this.setState({
                                    showMembers: true
                                })}><Isvg src={peopleIcon} /></button>
                                <div className="list">
                                    {
                                        this.state.videoCallClients.filter((item) => item.allowed).length ?
                                            <>
                                                <h6>{'Clients'.translate(this.props.lang)}</h6>
                                                <div className='members-list'>
                                                    {
                                                        this.state.videoCallClients.map((item, idx) => {
                                                            if (item.allowed)
                                                                return (
                                                                    <div>
                                                                        <div>
                                                                            <img src={item.user.profilePicture ? API_ENDPOINT + item.user.profilePicture : profilePicture} />
                                                                            {/* <p>{item.user.name}</p> */}
                                                                            <p style={{ display: 'flex', alignItems: 'center' }}>
                                                                                {
                                                                                    item.user.bankIdVerified === true ?
                                                                                        <Isvg src={secureIcon} style={{ width: 22, minWidth: 22 }} />
                                                                                        :
                                                                                        ''
                                                                                }
                                                                                {item.user.name + (item.user.bankIdVerified === true ? ('(' + 'BankID verified'.translate(this.props.lang) + ')') : '')}
                                                                            </p>
                                                                        </div>
                                                                        <div>

                                                                            {!item.allowed ?
                                                                                <Button color="success" onClick={() => {
                                                                                    this.props.socket.emit('allowClient', { socketId: item.socketId, ...item.answerData })
                                                                                    let videoCallClients = this.state.videoCallClients;
                                                                                    videoCallClients[idx].allowed = true;
                                                                                    this.setState({
                                                                                        videoCallClients
                                                                                    }, () => {
                                                                                        // localStorage?.setItem('videoCallClients', JSON.stringify(this.state.videoCallClients));

                                                                                    })

                                                                                }}>{'Let in'.translate(this.props.lang)}</Button>
                                                                                :
                                                                                <Button color="danger" onClick={() => {
                                                                                    this.setState({
                                                                                        removeClientModal: item,
                                                                                        removeClientModalIdx: idx
                                                                                    })
                                                                                }}>{'Remove'.translate(this.props.lang)}</Button>
                                                                            }
                                                                            {item.allowed ?
                                                                                <Button className='mute-client' onClick={() => {
                                                                                    console.log(this.state.mutedParticipants, item.uid);
                                                                                    let mutedParticipants = this.state.mutedParticipants;
                                                                                    mutedParticipants[item.uid] = !mutedParticipants[item.uid];
                                                                                    this.setState({
                                                                                        mutedParticipants
                                                                                    }, () => {
                                                                                        this.props.socket.emit('muteParticipant', { conversation: this.state.conversation?._id, uid: item.uid, value: mutedParticipants[item.uid] })
                                                                                    })

                                                                                }}>{!this.state.mutedParticipants[item.uid] ? 'Mute'.translate(this.props.lang) : 'Unmute'.translate(this.props.lang)}</Button>
                                                                                :
                                                                                null
                                                                            }
                                                                        </div>


                                                                    </div>
                                                                )
                                                        })
                                                    }
                                                </div>
                                            </>

                                            :
                                            null
                                    }

                                    {
                                        this.state.videoCallClients.filter((item) => item.allowed).length && this.state.videoCallClients.filter((item) => !item.allowed).length ?
                                            <div className='members-list-spacer'></div>
                                            :
                                            null

                                    }

                                    {this.state.videoCallClients.filter((item) => !item.allowed).length ?
                                        <>

                                            <h6>{'Waiting room'.translate(this.props.lang)} {this.state.videoCallClients && this.state.videoCallClients.filter(item => !item.allowed).length > 1 ? <Button onClick={() => {
                                                let videoCallClients = this.state.videoCallClients;

                                                for (let i = 0; i < videoCallClients.length; i++) {
                                                    if (!videoCallClients[i].allowed) {
                                                        this.props.socket.emit('allowClient', { socketId: videoCallClients[i].socketId, ...videoCallClients[i].answerData })
                                                        videoCallClients[i].allowed = true;
                                                    }
                                                }

                                                this.setState({
                                                    videoCallClients
                                                }, () => {
                                                    // localStorage?.setItem('videoCallClients', JSON.stringify(this.state.videoCallClients));
                                                })


                                            }} color="primary">{'Let in all'.translate(this.props.lang)} </Button> : null} </h6>
                                            <div className='members-list'>

                                                {
                                                    this.state.videoCallClients.map((item, idx) => {
                                                        if (!item.allowed)
                                                            return (
                                                                <div>
                                                                    <div>
                                                                        <img src={item.user.profilePicture ? API_ENDPOINT + item.user.profilePicture : profilePicture} />
                                                                        {/* <p>{item.user.name}</p> */}
                                                                        <p style={{ display: 'flex', alignItems: 'center' }}>
                                                                            {
                                                                                item.user.bankIdVerified === true ?
                                                                                    <Isvg src={secureIcon} style={{ width: 22, minWidth: 22 }} />
                                                                                    :
                                                                                    ''
                                                                            }
                                                                            {item.user.name + (item.user.bankIdVerified === true ? ('(' + 'BankID verified'.translate(this.props.lang) + ')') : '')}
                                                                        </p>
                                                                    </div>
                                                                    {!item.allowed ?
                                                                        <Button color="success" onClick={() => {
                                                                            this.props.socket.emit('allowClient', { socketId: item.socketId, ...item.answerData })
                                                                            let videoCallClients = this.state.videoCallClients;
                                                                            videoCallClients[idx].allowed = true;
                                                                            this.setState({
                                                                                videoCallClients
                                                                            }, () => {
                                                                                // localStorage?.setItem('videoCallClients', JSON.stringify(this.state.videoCallClients));
                                                                            })

                                                                        }}>{'Let in'.translate(this.props.lang)}</Button>
                                                                        :
                                                                        <Button color="danger" onClick={() => {
                                                                            this.setState({
                                                                                removeClientModal: item,
                                                                                removeClientModalIdx: idx
                                                                            })
                                                                        }}>{'Remove'.translate(this.props.lang)}</Button>
                                                                    }
                                                                </div>
                                                            )
                                                    })
                                                }
                                            </div>
                                        </>
                                        :
                                        null
                                    }
                                    <div style={{ justifyContent: 'flex-end', display: 'flex' }} className="mute-buttons">
                                        {this.state.videoCallClients && this.state.videoCallClients.filter(item => item.allowed).length > 1 ?
                                            <Button onClick={() => {

                                                let mutedParticipants = this.state.mutedParticipants;

                                                let videoCallClients = this.state.videoCallClients;

                                                for (let i = 0; i < videoCallClients.length; i++) {
                                                    if (mutedParticipants[videoCallClients[i].uid]) {
                                                        continue;
                                                    }
                                                    mutedParticipants[videoCallClients[i].uid] = true;
                                                    this.props.socket.emit('muteParticipant', { conversation: this.state.conversation?._id, uid: videoCallClients[i].uid, value: mutedParticipants[videoCallClients[i].uid] })
                                                }


                                            }} color="primary">{'Mute all'.translate(this.props.lang)} </Button>
                                            :
                                            null
                                        }
                                        {this.state.videoCallClients && this.state.videoCallClients.filter(item => item.allowed).length > 1 ?
                                            <Button style={{ marginLeft: 10 }} onClick={() => {

                                                let mutedParticipants = this.state.mutedParticipants;

                                                let videoCallClients = this.state.videoCallClients;

                                                for (let i = 0; i < videoCallClients.length; i++) {
                                                    if (!mutedParticipants[videoCallClients[i].uid]) {
                                                        continue;
                                                    }
                                                    mutedParticipants[videoCallClients[i].uid] = false;
                                                    this.props.socket.emit('muteParticipant', { conversation: this.state.conversation?._id, uid: videoCallClients[i].uid, value: mutedParticipants[videoCallClients[i].uid] })
                                                }

                                                this.setState({
                                                    mutedParticipants
                                                })




                                            }} color="primary">{'Unmute all'.translate(this.props.lang)} </Button>
                                            :
                                            null
                                        }
                                    </div>

                                </div>

                            </div>
                        </div>
                        :
                        null
                    }

                    <DeleteModal
                        zIndex={9999999}
                        lang={this.props.lang}
                        isOpen={this.state.removeClientModal}
                        toggle={() => this.setState({ removeClientModal: null })}
                        handler={() => {
                            this.props.socket.emit('removeClient', { socketId: this.state.removeClientModal.socketId, ...this.state.removeClientModal.answerData })
                            let videoCallClients = this.state.videoCallClients;
                            videoCallClients.splice(this.state.removeClientModalIdx, 1);
                            this.setState({
                                videoCallClients
                            })

                            this.setState({ removeClientModal: null, removeClientModalIdx: null })
                        }}
                    >
                        {'Remove client: '.translate(this.props.lang)} <strong>{this.state.removeClientModal && this.state.removeClientModal.user && this.state.removeClientModal.user.name}</strong>?
                    </DeleteModal>

                    {this.state.videoCallLimit ?
                        <Modal zIndex={9999999} wrapClassName="video-call-limit-wrapper" isOpen={this.state.videoCallLimit} toggle={() => this.setState({ videoCallLimit: !this.state.videoCallLimit })}>
                            <ModalHeader toggle={() => this.setState({ videoCallLimit: !this.state.videoCallLimit })} close={<button type="button" className="close" style={{ position: 'absolute', top: '15px', right: '15px' }} onClick={() => this.setState({ videoCallLimit: !this.state.videoCallLimit })}>&times;</button>}></ModalHeader>

                            <ModalBody className="video-call-limit">
                                <div className="loader-wrap">
                                    <Player
                                        autoplay={true}
                                        loop={true}
                                        renderer={'svg'}
                                        src="https://assets1.lottiefiles.com/packages/lf20_slGFhN.json"
                                        style={{ height: typeof window != 'undefined' && window.innerWidth < 768 ? "128px" : "256px", width: typeof window != 'undefined' && window.innerWidth < 768 ? "128px" : "256px" }}
                                    ></Player>

                                </div>

                                <p>{'You have reached the maximum number of users that can be included in a video call!'.translate(this.props.lang)}</p>

                            </ModalBody>

                        </Modal>

                        :
                        null
                    }
                    {this.state.takePhotoModal ?
                        <Modal zIndex={9999999} wrapClassName="video-call-limit-wrapper" isOpen={this.state.takePhotoModal} toggle={() => this.setState({ takePhotoModal: !this.state.takePhotoModal })}>
                            <ModalHeader toggle={() => this.setState({ takePhotoModal: !this.state.takePhotoModal })} close={<button type="button" className="close" style={{ position: 'absolute', top: '15px', right: '15px' }} onClick={() => this.setState({ takePhotoModal: !this.state.takePhotoModal })}>&times;</button>}>{'Save photo'.translate(this.props.lang)}</ModalHeader>

                            <ModalBody className="video-call-limit take-photo-modal">
                                <img src={this.state.takePhotoModal.previewImage} />
                                <Input type="textarea" placeholder={'Enter photo comment...'.translate(this.props.lang)} value={this.state.takePhotoModal.message} onChange={(e) => {
                                    let takePhotoModal = this.state.takePhotoModal;
                                    takePhotoModal.message = e.target.value;
                                    this.setState({
                                        takePhotoModal
                                    })
                                }} />

                                <p>{'Saved photo will be visible in conversation messages.'.translate(this.props.lang)}</p>
                            </ModalBody>

                            <ModalFooter>
                                <Button color="primary" onClick={() => this.setState({ takePhotoModal: null })}>{'Cancel'.translate(this.props.lang)}</Button>
                                <Button color="primary" onClick={this.savePhoto}>{'Save'.translate(this.props.lang)}</Button>
                            </ModalFooter>
                        </Modal>

                        :
                        null
                    }




                </div>
            )

        }




        let selectedGroupIdxPatient = -1;
        if (this.props.uData && localStorage?.getItem('groupAlias') && this.props.uData.patientGroups) {
            for (let i = 0; i < this.props.uData.patientGroups.length; i++) {
                if (this.props.uData.patientGroups[i].alias == localStorage?.getItem('groupAlias')) {
                    selectedGroupIdxPatient = i;
                    break;
                }
            }
        }

        let enterprise = this.props.uData && this.props.uData.clinicGroups && this.props.uData.clinicGroups[selectedGroupIdx] && this.props.uData.clinicGroups[selectedGroupIdx].enterprise;
        let canSearchPatients = this.props.uData && this.props.uData.clinicGroups && this.props.uData.clinicGroups[selectedGroupIdx] && this.props.uData.clinicGroups[selectedGroupIdx].sarchPatients;

        let dashboardClassname = this.props.autoHideSidebar ? "dashboard dashboard-auto-hide" : "dashboard";

        let eConnectEnabled = this.props.uData && this.props.uData.clinicGroups && this.props.uData.clinicGroups[selectedGroupIdx] && this.props.uData.clinicGroups[selectedGroupIdx].eConnect && !this.props.uData.clinicGroups[selectedGroupIdx].eConnectTempDisabled;
        const consultationButton = eConnectEnabled && this.state.conversation?.referral?._id ? true : false;
        const caseStatus = this.state.conversation?.referral?.status ?? null;
        const caseHistoryStatuses = ['closed', 'patient-rejected', 'disallowed'];
        const consultationButtonDisabled = consultationButton && caseStatus && caseHistoryStatuses.indexOf(caseStatus) === -1 ? false : true;
        return (
            <div className={this.props[0].match.params.windowConversation ? `${dashboardClassname} chat-new-window` : dashboardClassname}>
                <div className={this.state.chatHelpIcons ? 'chat-layer chat-layer-show' : 'chat-layer'} onClick={() => { this.setState({ chatHelpIcons: false }) }}></div>
                <Container fluid className='container-chat-mobile'>
                    <Row>
                        <Col lg="12">
                            <div className={this.state.chatType == 'patient' ? 'chat-area chat-area-patient' : 'chat-area chat-area-doctors'}>
                                {
                                    this.state.loadingConversations ?
                                        <div className="loader-wrap">
                                            <Player
                                                autoplay={true}
                                                loop={true}
                                                src="/lf30_editor_l5cxzdyf.json"
                                                style={{
                                                    height:
                                                        typeof window != "undefined" &&
                                                            window.innerWidth < 768
                                                            ? "128px"
                                                            : "256px",
                                                    width:
                                                        typeof window != "undefined" &&
                                                            window.innerWidth < 768
                                                            ? "128px"
                                                            : "256px",
                                                }}
                                            ></Player>

                                        </div>
                                        :
                                        null
                                }

                                {(typeof window !== 'undefined' && window.innerWidth < 767 && !this.state.conversation) || (typeof window !== 'undefined' && window.innerWidth >= 767) ?
                                    <div className="conversations">
                                        <div className="conversations-container">
                                            <div>
                                                <div className="head">
                                                    <h6>
                                                        {'Chat'.translate(this.props.lang)} {this.props.uData && this.props.uData?.userLevel == 20 && localStorage?.getItem('profile') == 'doctor' ? <button className="new-conversation-button" onClick={() => this.setState({ form: {} })}><Isvg src={addIcon} style={{ position: 'absolute' }} /> <div className="chat-icons-tooltip"> {'New conversation'.translate(this.props.lang)} </div> </button> : null}
                                                    </h6>
                                                </div>
                                                <p>{this.props.uData && (this.props.uData?.userLevel == 1 || this.props.uData?.userLevel == 2 || (localStorage?.getItem?.('profile') && localStorage?.getItem('profile') == 'patient')) && /*this.state.conversations.filter(item => !item.closed && item.groupAlias && localStorage?.groupAlias && item.groupAlias.indexOf(localStorage?.getItem('groupAlias')) != -1).length == 0*/ this.state.conversations && this.state.conversationsType === 0 ? 'Only healthcare professionals can initiate chat. You will be notified when a chat opens.'.translate(this.props.lang) : null}</p>

                                                <Input type="text" value={this.state.search} onChange={(e) => this.setState({ search: striptags(e.target.value), conversationPage: 0, searching: true }, () => {
                                                    // this.search()
                                                    // if (this.searchTimer) {
                                                    //     clearTimeout(this.searchTimer);
                                                    // }
                                                    // this.searchTimer = setTimeout(() => {
                                                    //     this.emitConversations({pageNumber: 0});
                                                    // }, 300);
                                                    // if (this.conversationsRef) {
                                                    //     this.conversationsRef.scrollTop = 0;
                                                    // }
                                                    // console.log('EMIT_CONVERSATIONS 21');
                                                    this.emitConversations();
                                                })} placeholder={'Search...'.translate(this.props.lang)} />
                                                {!this.state.loadingConversations && this.getConversations().length === 0 ? <p className='no-conversations-p'>{'No conversations'.translate(this.props.lang)}</p> : null}
                                                <ul ref={node => this.conversationsRef = node}>
                                                    {
                                                        // this.state.conversations.map((item, idx) => {
                                                        // if (item._newMessage) {
                                                        //     if (!this.state.closedConversations || (this.state.closedConversations.filter(conv => conv == item?._id).length == 0 && this.state.conversationsType == 0) || this.state.conversationsType == 1)
                                                        //         if (localStorage?.getItem?.('profile') && localStorage?.getItem('profile') == 'patient' && item.groupAlias && localStorage?.groupAlias && item.groupAlias.indexOf(localStorage?.getItem('groupAlias')) != -1) {
                                                        //             // console.log('patient doctor')

                                                        //             if (((item.groupAlias && localStorage?.groupAlias && item.groupAlias.indexOf(localStorage?.getItem('groupAlias')) != -1) || (this.props.selectedGroup && item.groupIds && item.groupIds.indexOf(this.props.selectedGroup) !== -1)) && ((!item.closed && this.state.conversationsType == 0) || (item.closed && this.state.conversationsType == 1)))
                                                        //                 return <li
                                                        //                     className={this.state.conversation && this.state.conversation?._id == item?._id ? 'active' : item._newMessage ? '' : ''} onClick={(e) => this.checkUploadedFiles(item)}>
                                                        //                     {item.name}<br />
                                                        //                     <span> {item.referral && item.referral.patient ? item.referral.patient.socialSecurityNumber : item.socialSecurityNumber ? item.socialSecurityNumber : null} </span>
                                                        //                     <h5 className="chat-members">
                                                        //                         {item.users && item.users.map((user, idx) => {
                                                        //                             return (

                                                        //                                 <img key={idx} src={user.profilePicture ? (API_ENDPOINT + user.profilePicture) : profilePicture} />

                                                        //                             )
                                                        //                         })}
                                                        //                     </h5>
                                                        //                     {
                                                        //                         this.state.draftMessages && this.state.draftMessages.length && this.state.draftMessages.filter(draft => draft.conversation == item?._id).length ?
                                                        //                             <div style={{ marginTop: 5 }} className="hover-draft-text">
                                                        //                                 <p style={{ color: '#dc3545', marginBottom: 0, fontSize: 12, fontWeight: 500 }}>{'Draft:'.translate(this.props.lang)}
                                                        //                                     <span style={{ color: 'black', marginLeft: 5, fontWeight: 400 }}>{this.state.draftMessages.map(mess => {
                                                        //                                         if (mess.conversation == item?._id) {
                                                        //                                             if (mess.message.length > 30) {
                                                        //                                                 return (mess.message.substring(0, 30) + '...')
                                                        //                                             } else {
                                                        //                                                 return mess.message
                                                        //                                             }

                                                        //                                         }

                                                        //                                     })}</span>
                                                        //                                 </p>
                                                        //                             </div>
                                                        //                             :
                                                        //                             null
                                                        //                     }
                                                        //                     {
                                                        //                         item._newMessage ?

                                                        //                             <Isvg src={newMessage} className="new-message-icon" />

                                                        //                             :

                                                        //                             null

                                                        //                     }


                                                        //                     {typeof window != 'undefined' && window.innerWidth > 768 && this.props[0].match.path != '/chat/:windowConversation' ?
                                                        //                         <button onClick={(e) => {
                                                        //                             e.stopPropagation();
                                                        //                             let idx = -1;
                                                        //                             for (let i = 0; i < this.newTabWindows.length; i++) {
                                                        //                                 if (this.newTabWindows[i] && this.newTabWindows[i].closed) {
                                                        //                                     idx = i;
                                                        //                                     break;
                                                        //                                 }
                                                        //                             }

                                                        //                             if (idx == -1) {
                                                        //                                 this.newTabWindows.push(window.open(`/chat/${item?._id}`, 'Conversation'.translate(this.props.lang) + ' ' + item?._id, `resizable=0,width=360,height=640,top=0,left=${parseInt(this.newTabWindows.length * 360)}`));
                                                        //                             } else {
                                                        //                                 this.newTabWindows[idx] = window.open(`/chat/${item?._id}`, 'Conversation'.translate(this.props.lang) + ' ' + item?._id, `resizable=0,width=360,height=640,top=0,left=${parseInt(360 * idx)}`);
                                                        //                             }
                                                        //                         }} className="search-members-button"><Isvg src={newTab} /> <div className="chat-icons-tooltip">{'Open in new tab'.translate(this.props.lang)}</div></button>
                                                        //                         :
                                                        //                         null
                                                        //                     }
                                                        //                 </li>
                                                        //         } else if (localStorage?.getItem('profile') != 'patient')
                                                        //             if (((!this.props.selectedGroup && item.groupAlias && localStorage?.groupAlias && item.groupAlias.indexOf(localStorage?.getItem('groupAlias')) != -1) || (this.props.selectedGroup && item.groupIds && item.groupIds.indexOf(this.props.selectedGroup) !== -1)) && ((!item.closed && this.state.conversationsType == 0) || (item.closed && this.state.conversationsType == 1))) {
                                                        //                 if (!item.chatOneOnOne || (item.chatOneOnOne && item.messagesExist))
                                                        //                     return <li
                                                        //                         className={this.state.conversation && this.state.conversation?._id == item?._id ? 'active' : item._newMessage ? '' : ''} onClick={(e) => this.checkUploadedFiles(item)}>
                                                        //                         {this.props.lang == 'se' && item.chatOneOnOne ? item.name.replace('Direct', 'Direkt') : item.name}<br />
                                                        //                         <span> {item.referral && item.referral.patient ? item.referral.patient.socialSecurityNumber : item.socialSecurityNumber ? item.socialSecurityNumber : null} </span>
                                                        //                         <h5 className="chat-members">
                                                        //                             {item.users && item.users.map((user, idx) => {
                                                        //                                 return (

                                                        //                                     <img src={user.profilePicture ? (API_ENDPOINT + user.profilePicture) : profilePicture} />

                                                        //                                 )
                                                        //                             })}
                                                        //                         </h5>
                                                        //                         {
                                                        //                             this.state.draftMessages && this.state.draftMessages.length && this.state.draftMessages.filter(draft => draft.conversation == item?._id).length ?
                                                        //                                 <div style={{ marginTop: 5 }} className="hover-draft-text">
                                                        //                                     <p style={{ color: '#dc3545', marginBottom: 0, fontSize: 12, fontWeight: 500 }}>{'Draft:'.translate(this.props.lang)}
                                                        //                                         <span style={{ color: 'black', marginLeft: 5, fontWeight: 400 }}>{this.state.draftMessages.map(mess => {
                                                        //                                             if (mess.conversation == item?._id) {
                                                        //                                                 if (mess.message.length > 30) {
                                                        //                                                     return (mess.message.substring(0, 30) + '...')
                                                        //                                                 } else {
                                                        //                                                     return mess.message
                                                        //                                                 }

                                                        //                                             }

                                                        //                                         })}</span>
                                                        //                                     </p>
                                                        //                                 </div>
                                                        //                                 :
                                                        //                                 null
                                                        //                         }
                                                        //                         {
                                                        //                             item._newMessage ?

                                                        //                                 <Isvg src={newMessage} className="new-message-icon" />

                                                        //                                 :

                                                        //                                 null

                                                        //                         }

                                                        //                         {typeof window != 'undefined' && window.innerWidth > 768 && this.props[0].match.path != '/chat/:windowConversation' ?
                                                        //                             <button onClick={(e) => {
                                                        //                                 e.stopPropagation();
                                                        //                                 let idx = -1;
                                                        //                                 for (let i = 0; i < this.newTabWindows.length; i++) {
                                                        //                                     if (this.newTabWindows[i] && this.newTabWindows[i].closed) {
                                                        //                                         idx = i;
                                                        //                                         break;
                                                        //                                     }
                                                        //                                 }

                                                        //                                 if (idx == -1) {
                                                        //                                     this.newTabWindows.push(window.open(`/chat/${item?._id}`, 'Conversation'.translate(this.props.lang) + ' ' + item?._id, `resizable=0,width=360,height=640,top=0,left=${parseInt(this.newTabWindows.length * 360)}`));
                                                        //                                 } else {
                                                        //                                     this.newTabWindows[idx] = window.open(`/chat/${item?._id}`, 'Conversation'.translate(this.props.lang) + ' ' + item?._id, `resizable=0,width=360,height=640,top=0,left=${parseInt(360 * idx)}`);
                                                        //                                 }
                                                        //                             }} className="search-members-button"><Isvg src={newTab} /> <div className="chat-icons-tooltip">{'Open in new tab'.translate(this.props.lang)}</div></button>
                                                        //                             :
                                                        //                             null
                                                        //                         }
                                                        //                     </li>
                                                        //             }
                                                        // }

                                                        // })
                                                    }
                                                    {
                                                        conversations.map((item, idx) => {
                                                            return (
                                                                <li
                                                                    key={item?._id + item.lastMessage}
                                                                    className={this.state.conversation && this.state.conversation?._id == item?._id ? 'active' : item._newMessage ? '' : ''}
                                                                    onClick={(e) => {
                                                                        this.setState({
                                                                            messageHeight: 64
                                                                        })
                                                                        this.checkUploadedFiles(item)
                                                                    }}>
                                                                    <div className='conversation-name'>
                                                                        {item.name.replace('Direct', 'Direct'.translate(this.props.lang))}
                                                                    </div>
                                                                    <span>
                                                                        {!item?.checkIfPatient && item.referral && item.referral.patient ? item.referral.patient.socialSecurityNumber : !item?.checkIfPatient && item.socialSecurityNumber ? item.socialSecurityNumber : null}
                                                                        {
                                                                            !item?.checkIfPatient && item?.referral?.patient?.socialSecurityNumber && item?._2faMembers && Array.isArray(item._2faMembers) && item._2faMembers.find(obj => obj.ssn === item.referral.patient.socialSecurityNumber) ?
                                                                                <span> (<span className='_2fa-chat-span'>{'2FA'.translate(this.props.lang)}</span>) </span>
                                                                                :
                                                                                null
                                                                        }
                                                                    </span>
                                                                    {item.description || item.searchReason ? <div className={!item?.checkIfPatient && ((item.referral && item.referral.patient) || item.socialSecurityNumber) ? 'conversations-desc-with-span conversations-desc' : 'conversations-desc'}>
                                                                        {item.description || item.searchReason}
                                                                    </div> : null}
                                                                    <h5 className="chat-members">
                                                                        {item.users && item.users.map((user, idx) => {
                                                                            return (

                                                                                <img key={idx} src={user.profilePicture ? (API_ENDPOINT + user.profilePicture) : profilePicture} />

                                                                            )
                                                                        })}
                                                                    </h5>

                                                                    {
                                                                        this.state.draftMessages && this.state.draftMessages.length && this.state.draftMessages.filter(draft => draft.conversation == item?._id).length ?
                                                                            <div style={{ marginTop: 5 }} className="hover-draft-text">
                                                                                <p style={{ color: '#dc3545', marginBottom: 0, fontSize: 12, fontWeight: 500 }}>{'Draft:'.translate(this.props.lang)}
                                                                                    <span style={{ color: 'black', marginLeft: 5, fontWeight: 400 }}>{this.state.draftMessages.map(mess => {
                                                                                        if (mess.conversation == item?._id) {
                                                                                            if (mess.message.length > 15) {
                                                                                                return (mess.message.substring(0, 15) + '...')
                                                                                            } else {
                                                                                                return mess.message
                                                                                            }

                                                                                        }

                                                                                    })}</span>
                                                                                </p>
                                                                            </div>
                                                                            :
                                                                            null
                                                                    }

                                                                    {typeof window != 'undefined' && window.innerWidth > 768 && this.props[0].match.path != '/chat/:windowConversation' ?
                                                                        <button onClick={(e) => {
                                                                            e.stopPropagation();
                                                                            let idx = -1;
                                                                            for (let i = 0; i < this.newTabWindows.length; i++) {
                                                                                if (this.newTabWindows[i] && this.newTabWindows[i].closed) {
                                                                                    idx = i;
                                                                                    break;
                                                                                }
                                                                            }

                                                                            if (idx == -1) {
                                                                                this.newTabWindows.push(window.open(`/chat/${item?._id}`, 'Conversation'.translate(this.props.lang) + ' ' + item?._id, `resizable=0,width=360,height=640,top=0,left=${parseInt(this.newTabWindows.length * 360)}`));
                                                                            } else {
                                                                                this.newTabWindows[idx] = window.open(`/chat/${item?._id}`, 'Conversation'.translate(this.props.lang) + ' ' + item?._id, `resizable=0,width=360,height=640,top=0,left=${parseInt(360 * idx)}`);
                                                                            }
                                                                        }} className="search-members-button"><Isvg src={newTab} /> <div className="chat-icons-tooltip">{'Open in new tab'.translate(this.props.lang)}</div></button>
                                                                        :
                                                                        null
                                                                    }
                                                                    {
                                                                        item.referral && item.referral.priority && this.props.uData && this.props.uData?.userLevel && this.props.uData?.userLevel >= 20 && typeof window !== 'undefined' && localStorage?.getItem?.('profile') && localStorage?.getItem('profile') !== 'patient' ?
                                                                            <div className={
                                                                                item.referral.priority == 1 ? 'priority-circle-chat priority-circle pc-chat p-red nohover' :
                                                                                    item.referral.priority == 2 ? 'priority-circle-chat priority-circle pc-chat p-orange nohover' :
                                                                                        item.referral.priority == 3 ? 'priority-circle-chat priority-circle pc-chat p-yellow nohover' :
                                                                                            item.referral.priority == 4 ? 'priority-circle-chat priority-circle pc-chat p-black nohover' :
                                                                                                'priority-circle-chat priority-circle pc-chat p-green nohover'
                                                                            }>
                                                                                {item.referral.priority}
                                                                            </div>
                                                                            :
                                                                            null
                                                                    }
                                                                    <div className='conversation-left-bottom-wrap' style={{ bottom: item.lastPatientMessage ? 5 : 10 }}>
                                                                        {
                                                                            item._newMessage ?
                                                                                <Isvg src={newMessage} className="new-message-icon-v2" />
                                                                                :
                                                                                null
                                                                        }
                                                                        {
                                                                            item.lastPatientMessage ?
                                                                                <div className='last-patient-message'>{this.props.getStringDateTs(item.lastPatientMessage, this.props.dateFormat + ' HH:mm')/*moment.unix(item.lastPatientMessage).format(`${this.props.dateFormat} HH:mm`)*/}</div>
                                                                                :
                                                                                null
                                                                        }
                                                                    </div>

                                                                </li>
                                                            )

                                                        })
                                                    }
                                                    {/* {conversationsLength > (20 * this.state.conversationPage + 20) ?
                                                        <div ><Waypoint
                                                            onEnter={() => {
                                                                this.setState({
                                                                    conversationPage: this.state.conversationPage + 1
                                                                })
                                                            }}
                                                        />
                                                        </div>
                                                        :
                                                        null
                                                    } */}
                                                    {this.state.totalNumberOfConversations && this.state.totalNumberOfConversations > ((this.state.conversationPage + 1) * 20) ?
                                                        <div ><span className='waypoint-hide-letter'>a</span><Waypoint
                                                            onEnter={() => {
                                                                if (!this.state.searching) {
                                                                    this.setState(prev => ({ conversationPage: prev.conversationPage + 1 }), () => {
                                                                        // console.log('EMIT_CONVERSATIONS 22');
                                                                        this.emitConversations({ addToExistingConversations: true });
                                                                    });
                                                                }
                                                            }}
                                                        />
                                                        </div>
                                                        :
                                                        null
                                                    }



                                                </ul>
                                            </div>
                                            <div className="conversations-buttons">

                                                <div>
                                                    <Button onClick={() => this.setState({ conversationsType: 0, conversation: null, messages: [], referral: null, loadingConversations: true, conversationPage: 0 }, () => {
                                                        // this.props.socket.emit('conversations', { profile: typeof window != 'undefined' ? localStorage?.getItem?.('profile') : null, conversationsType: this.state.conversationsType, windowConversation: ((this.props[0].match.params && this.props[0].match.params.windowConversation) || (this.props[0].match.params && this.props[0].match.params.conversation)) });
                                                        // console.log('EMIT_CONVERSATIONS 23');
                                                        this.emitConversations();
                                                        if (this.conversationsRef) {
                                                            this.conversationsRef.scrollTop = 0;
                                                        }
                                                    })} className={this.state.conversationsType == 0 ? 'active' : null}>{'Active'.translate(this.props.lang)}</Button>
                                                    <Button onClick={() => this.setState({ conversationsType: 1, conversation: null, messages: [], referral: null, loadingConversations: true, conversationPage: 0 }, () => {
                                                        // this.props.socket.emit('conversations', { profile: typeof window != 'undefined' ? localStorage?.getItem?.('profile') : null, conversationsType: this.state.conversationsType, windowConversation: ((this.props[0].match.params && this.props[0].match.params.windowConversation) || (this.props[0].match.params && this.props[0].match.params.conversation)) });
                                                        // console.log('EMIT_CONVERSATIONS 24');
                                                        this.emitConversations();
                                                        if (this.conversationsRef) {
                                                            this.conversationsRef.scrollTop = 0;
                                                        }
                                                    })} className={this.state.conversationsType == 1 ? 'active' : null} style={{ position: 'relative' }}>
                                                        {'History'.translate(this.props.lang)}
                                                        {
                                                            /*this.state.conversations.findIndex(item => item.closed && item._newMessage) != -1 ||*/ this.state.historyIndicator ?
                                                                <div className="red-circle"></div>
                                                                :
                                                                null
                                                        }

                                                    </Button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    :
                                    null
                                }
                                {
                                    this.state.loseUploadedFiles ?
                                        <Modal isOpen={this.state.loseUploadedFiles} centered size={this.props.size ? this.props.size : null}>
                                            {/* <ModalHeader style={{ margin: 'auto' }}>{'Error'.translate(this.props.lang)}</ModalHeader> */}
                                            <ModalBody className="delete-modal">
                                                {/* <img src={warrning} /> */}
                                                <div className="message">
                                                    {"If you change the conversation before sending the uploaded files, they will be lost. Do you want to continue?".translate(this.props.lang)}
                                                </div>
                                            </ModalBody>
                                            <ModalFooter style={{ display: 'flex', justifyContent: 'center' }}>
                                                <Button style={{ minWidth: 100 }} type="submit" color="primary" onClick={() => this.setState({ video: null, image: null, document: null }, () => this.openConversation(this.state.loseUploadedFiles))} >{'Yes'.translate(this.props.lang)}</Button>{' '}
                                                <Button style={{ minWidth: 100 }} type="submit" color="primary" onClick={() => this.setState({ loseUploadedFiles: null })} >{'No'.translate(this.props.lang)}</Button>{' '}
                                            </ModalFooter>
                                        </Modal>
                                        :
                                        null
                                }



                                {
                                    this.state.conversation ?

                                        <div className={`chat-wrap ${this.state.call ? 'video-call' : ''} ${this.state.showChat ? 'show-chat' : ''} ${this.state.offerData ? 'incoming-offer' : ''} ${this.state.searchMembers || this.state.showGallery ? 'search-members-active' : ''} ${params.patientSearchModule ? 'iframe-width ' : ''}`} ref={(node) => this.chatWrapRef = node}

                                            onDrop={this.handleDrop}

                                            onDragEnter={(e) => {

                                                if (!e.dataTransfer.types.includes('Files')) {
                                                    e?.preventDefault();
                                                    e?.stopPropagation();
                                                    return
                                                }


                                                e?.preventDefault();
                                                e?.stopPropagation();
                                                this.setState({ isDragging: true })
                                            }}

                                            onDragLeave={(e) => {
                                                e?.preventDefault();
                                                e?.stopPropagation();
                                                this.setState({ isDragging: false }, () => {
                                                    let oldScrollHeight = 0;
                                                    if (this.messagesRef) {
                                                        oldScrollHeight = this.messagesRef.scrollHeight;
                                                    }

                                                    if (this.messagesRef && this.state.page == 0) {
                                                        this.messagesRef.scrollTop = this.messagesRef.scrollHeight;
                                                    } else if (oldScrollHeight) {
                                                        this.messagesRef.scrollTop = this.messagesRef.scrollTop + (this.messagesRef.scrollHeight - oldScrollHeight);
                                                    }
                                                })
                                            }}

                                            onDragOver={e => {
                                                e?.preventDefault();
                                                e?.stopPropagation();
                                            }}


                                        >{this.state.isDragging ? <div className='drag-drop-container'>
                                            {'Drop to send'.translate?.(this.props.lang)}</div> :
                                            <>
                                                <div className={'head'}>

                                                    <div className='head-flex'>
                                                        <h6>

                                                            {this.state.conversation && this.state.conversation?.referral ?
                                                                <div className="referral">
                                                                    {/*
                                                                    this.props.uData && this.props.uData?.userLevel && this.props.uData?.userLevel == 20 && typeof window != 'undefined' && localStorage?.getItem?.('profile') != 'patient' ?
                                                                        <button className="chat-header-icon" onClick={() => this.setState({ referral: this.state.conversation?.referral }, () => this.addToReferralPreview())}>{'Read patient answers'.translate(this.props.lang)}</button>
                                                                        :
                                                                        <button className="chat-header-icon" onClick={() => this.setState({ referral: this.state.conversation?.referral }, () => this.addToReferralPreview())}>{"My answers".translate(this.props.lang)}</button>

                                                                */}
                                                                </div>
                                                                :
                                                                null
                                                            }


                                                            <p>{this.state.conversation && this.state.conversation?.name && this.state.conversation?.chatOneOnOne ? this.state.conversation?.name.replace('Direct', 'Direct'.translate(this.props.lang)) : this.state.conversation?.name}</p> &nbsp;

                                                        </h6>
                                                        {/* <div id='chat-scroll-container-parent' className="members-container">
                                                            {
                                                                this.state.conversation && this.state.conversation?.members && this.state.conversation?.members?.length >= 2 ?
                                                                    <div className="overlay"></div>
                                                                    :
                                                                    null
                                                            }


                                                            <p>{this.state.conversation && this.state.conversation?.name && this.state.conversation?.chatOneOnOne && this.props.lang == 'se' ? this.state.conversation?.name.replace('Direct', 'Direkt') : this.state.conversation?.name}</p> &nbsp;

                                                        </div> */}
                                                        <div id='chat-scroll-container-parent' className="members-container">
                                                            {
                                                                this.state.conversation && this.state.conversation?.members && this.state.conversation?.members?.length >= 2 ?
                                                                    <div className="overlay"></div>
                                                                    :
                                                                    null
                                                            }
                                                            <div ref={(node) => this.membersScrollArea = node}
                                                                onMouseDown={(e) => {
                                                                    if (this.membersScrollArea) {
                                                                        this.membersScrollArea.style.cursor = 'grabbing';
                                                                        //this.scrollArea.style.userSelect = 'none';


                                                                        this.setState({
                                                                            _membersDragging: {
                                                                                x: e.clientX,
                                                                                y: e.clientY,
                                                                                left: this.membersScrollArea.scrollLeft,
                                                                            }
                                                                        })
                                                                    }
                                                                }}
                                                                onMouseMove={(e) => {
                                                                    if (this.state._membersDragging && this.membersScrollArea) {
                                                                        // How far the mouse has been moved
                                                                        const dx = e.clientX - this.state._membersDragging.x;

                                                                        this.membersScrollArea.scrollLeft = this.state._membersDragging.left - dx;

                                                                    }
                                                                }}
                                                                onMouseUp={(e) => {
                                                                    this.membersScrollArea.style.cursor = 'grab';
                                                                    //this.scrollArea.style.userSelect = 'none';

                                                                    this.setState({
                                                                        _membersDragging: null
                                                                    })
                                                                }}
                                                                className={
                                                                    this.state.conversation && this.state.conversation?.members?.length >= 2 && window.innerWidth > 768 && typeof window !== 'undefined' ? 'members members-scroll' : ((this.state.conversation && this.state.conversation?.members?.length >= 4 && window.innerWidth < 768 && typeof window !== 'undefined' ? 'members members-scroll-mobile' : 'members') || (this.state.conversation && this.state.conversation?.members?.length > 2 && typeof window != 'undefined' && window.innerWidth > 768 ? 'members members-scroll' : 'members'))} id='chat-scroll-container-child'>
                                                                {
                                                                    this.state.conversation && this.state.conversation?.users?.map?.((item, idx) => {

                                                                        if (item.uid && item.uid.indexOf('guest') == -1)
                                                                            return (<>
                                                                                <div className={this.state.memeberHoverState == item.uid ? "active-member" : ""}
                                                                                    onMouseEnter={(e) => {
                                                                                        e.stopPropagation();
                                                                                        // console.log(item);
                                                                                        this.setState({
                                                                                            memeberHoverState: item.uid
                                                                                        })
                                                                                    }}

                                                                                    onMouseLeave={(e) => {
                                                                                        e.stopPropagation();

                                                                                        this.setState({
                                                                                            memeberHoverState: null
                                                                                        })
                                                                                    }}

                                                                                    onClick={() => {
                                                                                        if (this.state.tooltip == 0 || this.state.tooltip != item.uid) {
                                                                                            this.setState({
                                                                                                tooltip: item.uid
                                                                                            })
                                                                                        } else if (this.state.tooltip == item.uid) {
                                                                                            this.setState({
                                                                                                tooltip: 0
                                                                                            })
                                                                                        }
                                                                                        if (this.state.memeberHoverState == item.uid && !params.doNotOpenPatientProfile) {

                                                                                            if ((this.props.uData?.userLevel == 20 && typeof window != 'undefined' && localStorage?.getItem?.('profile') != 'patient') && (item.level && item.level < 20 || (item.socialSecurityNumber && this.state.conversation && this.state.conversation?.referral && this.state.conversation?.referral.patient && this.state.conversation?.referral.patient.socialSecurityNumber && this.state.conversation?.referral.patient.name && this.state.conversation?.referral.patient.socialSecurityNumber == item.socialSecurityNumber) || (item.socialSecurityNumber && this.state.conversation && this.state.conversation?.control && this.state.conversation?.socialSecurityNumber && this.state.conversation?.socialSecurityNumber == item.socialSecurityNumber) || item.patientInThisGroup)) {
                                                                                                if (enterprise || canSearchPatients) {
                                                                                                    this.searchPatientWithRedirect(item.socialSecurityNumber, enterprise);
                                                                                                } else {
                                                                                                    fetch(API_ENDPOINT + '/users/users/' + item.uid.toString(), {
                                                                                                        method: 'GET',
                                                                                                        headers: {
                                                                                                            'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage?.getItem('authToken')}` : null,
                                                                                                            'content-type': 'application/json'
                                                                                                        },
                                                                                                    }).then(res => res.json()).then((result) => {

                                                                                                        this.setState({
                                                                                                            profileModal: result
                                                                                                        }, () => {
                                                                                                            this.props.registerClinicGroupLogs({
                                                                                                                type: 'patientProfile',
                                                                                                                patient: result?._id
                                                                                                            })
                                                                                                        })
                                                                                                    })
                                                                                                }
                                                                                                this.setState({
                                                                                                    memeberHoverState: null
                                                                                                })
                                                                                            }
                                                                                        } else {
                                                                                            this.setState({
                                                                                                memeberHoverState: item.uid
                                                                                            })
                                                                                        }
                                                                                    }}>
                                                                                    <img onClick={() => {/**
                                                                                    if (this.props.uData?.userLevel == 20 && item.level < 20 && item.level) {
                                                                                        fetch(API_ENDPOINT + '/users/users/' + item.uid.toString(), {
                                                                                            method: 'GET',
                                                                                            headers: {
                                                                                                'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage?.getItem('authToken')}` : null,
                                                                                                'content-type': 'application/json'
                                                                                            },
                                                                                        }).then(res => res.json()).then((result) => {

                                                                                            this.setState({
                                                                                                profileModal: result
                                                                                            })
                                                                                        })
                                                                                    }
                                                                                    */

                                                                                    }} src={item.profilePicture ? API_ENDPOINT + item.profilePicture : profilePicture} />
                                                                                    <div className={item.online ? 'status online' : 'status offline'}></div>
                                                                                    <p onClick={() => {/**

                                                                                    if ((this.props.uData?.userLevel == 20 && typeof window != 'undefined' && localStorage?.getItem?.('profile') != 'patient') && (item.level && item.level < 20 || (item.socialSecurityNumber && this.state.conversation && this.state.conversation?.referral && this.state.conversation?.referral.patient && this.state.conversation?.referral.patient.socialSecurityNumber && this.state.conversation?.referral.patient.name && this.state.conversation?.referral.patient.socialSecurityNumber == item.socialSecurityNumber) || (item.socialSecurityNumber && this.state.conversation && this.state.conversation?.control && this.state.conversation?.socialSecurityNumber && this.state.conversation?.socialSecurityNumber == item.socialSecurityNumber))) {
                                                                                        fetch(API_ENDPOINT + '/users/users/' + item.uid.toString(), {
                                                                                            method: 'GET',
                                                                                            headers: {
                                                                                                'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage?.getItem('authToken')}` : null,
                                                                                                'content-type': 'application/json'
                                                                                            },
                                                                                        }).then(res => res.json()).then((result) => {

                                                                                            this.setState({
                                                                                                profileModal: result
                                                                                            })
                                                                                        })
                                                                                    } */
                                                                                    }} className={typeof window != 'undefined' && window.innerWidth > 768 ? this.state.tooltip != 0 && this.state.tooltip == item.uid ? 'name-visible' : 'name-none' : ''}>
                                                                                        {
                                                                                            item && item.socialSecurityNumber && this.state.conversation && this.state.conversation?.referral && this.state.conversation?.referral.patient && this.state.conversation?.referral.patient.socialSecurityNumber && this.state.conversation?.referral.patient.name && this.state.conversation?.referral.patient.socialSecurityNumber == item.socialSecurityNumber ?
                                                                                                this.state.conversation?.referral.patient.name
                                                                                                :
                                                                                                (item.socialSecurityNumber && this.state.conversation && this.state.conversation?.control && this.state.conversation?.socialSecurityNumber && this.state.conversation?.socialSecurityNumber == item.socialSecurityNumber) && this.state.conversation?.name ?
                                                                                                    this.state.conversation?.name
                                                                                                    :
                                                                                                    item.name
                                                                                        }
                                                                                        {
                                                                                            item && item.socialSecurityNumber ?
                                                                                                <><br />{item.socialSecurityNumber} </>
                                                                                                :
                                                                                                null
                                                                                        }
                                                                                        {
                                                                                            item && item.socialSecurityNumber && this.state.conversation?._id && this.state.conversations?.length && this.state.conversations?.find?.(obj => String(obj._id) === String(this.state.conversation?._id))?._2faMembers?.find?.(obj => obj.ssn === item.socialSecurityNumber) ? <>
                                                                                                <br /> <span style={{ color: 'red' }}>{'2FA'.translate(this.props.lang)}</span>
                                                                                            </> : null
                                                                                        }
                                                                                    </p>

                                                                                </div>
                                                                                {this.state.memeberHoverState == item.uid && this.props.uData?.userLevel >= 20 && typeof window !== 'undefined' && localStorage?.getItem('profile') === 'doctor' && (item.level === 1 || item.level === 2) ? <div className='click-on-patient'>{'Click on patient to see his profile'.translate(this.props.lang)}</div> : null}
                                                                            </>
                                                                            )
                                                                    })
                                                                }
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <Isvg src={moreIcon} onClick={this.toggleHelpIcons} className="chat-more-icon" />

                                                    <div className='chat-helper-container'>
                                                        <div className={this.state.chatHelpIcons ? 'chat-helper chat-helper-show' : 'chat-helper'}>
                                                            <input ref={(node) => this.roomIdInput = node} style={{ width: 10, height: 10, background: 'transparent', border: 0, opacity: 0 }} value={this.state.conversation && typeof window != 'undefined' ? `${window.location.origin}/video-call/${this.state.conversation?._id}?roomId=${this.state.conversation?.roomId}` : null} />


                                                            {/* <button onClick={() => this.setState({ showGallery: true, chatHelpIcons: false })} className="search-members-button">
                                                            <Isvg src={galleryIcon} />
                                                            <div className="chat-icons-tooltip">{'Media'.translate(this.props.lang)}</div>
                                                        </button> */}
                                                            {
                                                                showPreviewStatementButton ?
                                                                    <button className="search-members-button" onClick={() => {
                                                                        this.checkStatement(true)
                                                                        // this.setState({ previewStatement: { ... this.state.statementData } })
                                                                    }}>
                                                                        <Isvg src={writeStatementIcon} className={'preview-statement-icon'} style={{ position: 'absolute' }} />
                                                                        <div className="chat-icons-tooltip" style={{ width: 150 }}>{'Preview statement'.translate(this.props.lang)}</div>
                                                                    </button>
                                                                    :
                                                                    null

                                                            }

                                                            {
                                                                this.props.uData && this.props.uData?.userLevel && this.props.uData?.userLevel == 20 && typeof window != 'undefined' && localStorage?.getItem?.('profile') != 'patient' && consultationButton && this.props.uData?._id && this.state.conversation && this.state.conversation.members && Array.isArray(this.state.conversation.members) && this.state.conversation.members.length && String(this.state.conversation.members[0]) === String(this.props.uData?._id) ?
                                                                    this.state._consultations && Array.isArray(this.state._consultations) && this.state._consultations.length && this.state._consultations.find(consultation => consultation.conversationId === this.state.conversation?._id) ? <button className="search-members-button" style={{ backgroundColor: '#60BEBB' }} onClick={() => {

                                                                        const consultations = this.state._consultations?.filter?.(consultation => consultation.conversationId === this.state.conversation?._id);

                                                                        this.setState({ activeConsultations: consultations });
                                                                        return;


                                                                    }}>
                                                                        <Isvg src={consultationIcon} className={'consultation-icon'} style={{ position: 'absolute' }} />
                                                                        <div className="chat-icons-tooltip" style={{ width: 150 }}>{'Active Consultations'.translate(this.props.lang)}</div>
                                                                    </button> :
                                                                        <button className="search-members-button" onClick={() => {
                                                                            if (consultationButtonDisabled) {
                                                                                this.setState({ error: 'You cannot create a consultation for case with this status.'.translate(this.props.lang) })
                                                                            } else {
                                                                                this.setState({ consultationModal: true })
                                                                            }

                                                                        }}>
                                                                            <Isvg src={consultationIcon} className={'consultation-icon'} style={{ position: 'absolute' }} />
                                                                            <div className="chat-icons-tooltip" style={{ width: 150 }}>{'Consultation/transfer'.translate(this.props.lang)}</div>
                                                                        </button>
                                                                    :
                                                                    null
                                                            }


                                                            {this.props.uData && this.props.uData?.userLevel && this.props.uData?.userLevel == 20 && typeof window != 'undefined' && localStorage?.getItem?.('profile') != 'patient' && !this.state.conversation?.consultationId && !this.state.conversation?.eConnectId
                                                                ? <button className="search-members-button" onClick={() => {
                                                                    this.setState({ referral: this.state.conversation?.referral }, () => {
                                                                        this.addToReferralPreview()
                                                                        // this.setState({ refresh: new Date().getTime() })
                                                                    })
                                                                }}>
                                                                    <Isvg src={eyeIcon} className={'chat-preview-icon'} style={{ position: 'absolute' }} />
                                                                    <div className="chat-icons-tooltip" style={{ width: 150 }}>{'Read patient answers'.translate(this.props.lang)}</div>
                                                                </button>
                                                                :
                                                                !this.state.conversation?.consultationId && !this.state.conversation?.eConnectId ?
                                                                    <button className="search-members-button" onClick={() => {
                                                                        this.setState({ referral: this.state.conversation?.referral }, () => {
                                                                            this.addToReferralPreview()
                                                                            // this.setState({ refresh: new Date().getTime() })
                                                                        })
                                                                    }}>
                                                                        <Isvg src={eyeIcon} className={'chat-preview-icon'} style={{ position: 'absolute' }} />
                                                                        <div className="chat-icons-tooltip" style={{ width: 100 }}>{'My answers'.translate(this.props.lang)}</div>
                                                                    </button> : null}


                                                            {this.props.uData && this.props.uData?.userLevel >= 20 && localStorage?.getItem('profile') == 'doctor' && this.state.conversation && this.state.conversation?.members && this.state.conversation?.members[0] == this.props.uData?._id && this.state.conversation?.closed
                                                                ? <button className='search-members-button openConversationButton' onClick={() => this.setState({ openModal: this.state.conversation })}>
                                                                    <Isvg style={{ position: 'absolute' }} src={openChat} />
                                                                    <div className="chat-icons-tooltip">{'Open conversation'.translate(this.props.lang)}</div>
                                                                </button>
                                                                : null}

                                                            {this.props.uData && this.props.uData?.userLevel >= 20 && localStorage?.getItem('profile') !== 'patient' && this.state.conversation && this.state.conversation?.members && this.state.conversation?.members[0] == this.props.uData?._id && (!this.state.conversation?.closed || (this.state.conversation?.closed && this.state.conversation?.softCloseTimestamp)) && !this.state.conversation?.consultationId && !this.state.conversation?.eConnectId
                                                                ? this.state.conversation?.softCloseTimestamp
                                                                    ? this.state.conversation?.referral && this.state.conversation?.referral.dropIn
                                                                        ? <button className='search-members-button closeCaseButton' onClick={() => { this.closeCase(this.state.conversation) }}>
                                                                            <Isvg src={closeCaseIcon} style={{ position: 'absolute' }} />
                                                                            <div className="chat-icons-tooltip">{'Hard close case'.translate(this.props.lang)}</div>
                                                                        </button>
                                                                        : <button className='search-members-button closeCaseButton' onClick={() => { this.close(this.state.conversation?._id) }}>
                                                                            <Isvg src={closeCaseIcon} style={{ position: 'absolute' }} />
                                                                            <div className="chat-icons-tooltip" style={{ width: 190 }}>{'Hard close conversation'.translate(this.props.lang)}</div>
                                                                        </button>
                                                                    : this.state.conversation?.referral && this.state.conversation?.referral.dropIn
                                                                        ? <button className='search-members-button closeCaseButton' onClick={() => this.setState({ closeCaseModal: this.state.conversation })}>
                                                                            <Isvg src={closeCaseIcon} style={{ position: 'absolute' }} />
                                                                            <div className="chat-icons-tooltip">{'Close case'.translate(this.props.lang)}</div>
                                                                        </button>
                                                                        : <button className='search-members-button closeCaseButton' onClick={() => this.setState({ closeModal: this.state.conversation })}>
                                                                            <Isvg src={closeCaseIcon} style={{ position: 'absolute' }} />
                                                                            <div className="chat-icons-tooltip">{'Close conversation'.translate(this.props.lang)}</div>
                                                                        </button>
                                                                : null}

                                                            {this.props.uData && this.props.uData?.userLevel >= 20 && localStorage?.getItem('profile') == 'doctor' && this.state.conversation && this.state.conversation?.members && this.state.conversation?.members[0] == this.props.uData?._id && !this.state.conversation?.chatOneOnOne ?
                                                                <button onClick={() => this.setState({ searchMembers: true, chatHelpIcons: false })} className="search-members-button"><Isvg src={addIcon} style={{ position: 'absolute' }} /> <div className="chat-icons-tooltip" style={{ width: 200 }}>{'Add chat participants'.translate(this.props.lang)}</div></button>
                                                                :
                                                                null
                                                            }
                                                            {!this.props.videoCallUrl && this.props.uData && this.props.uData?.userLevel >= 20 && localStorage?.getItem('profile') == 'doctor' && selectedGroupIdx != -1 && this.state.conversation?.groupIds && this.state.conversation?.groupIds.indexOf(this.props.selectedGroup) != -1 && this.state.conversation && this.state.conversation?.members && ((this.state.conversation?.members[0] == this.props.uData?._id && !this.state.conversation?.consultationId && !this.state.conversation?.eConnectId) || (this.state.conversation?.members.indexOf(this.props.uData?._id) !== -1 && (this.state.conversation?.consultationId || this.state.conversation?.eConnectId))) ?
                                                                this.state.chatType == 'patient' ? <button onClick={/*this.call*/() => {
                                                                    this.setState({ chatHelpIcons: false });
                                                                    this.props.startVideoCall(this.state.conversation?._id);
                                                                    this.props.registerClinicGroupLogs({
                                                                        type: 'startCall',
                                                                        conversation: this.state.conversation?._id
                                                                    })
                                                                }} disabled={this.props.uData?.clinicGroups?.[selectedGroupIdx]?.videoLicence <= 0} className="call-button"><Isvg src={cameraIcon} /> <div className="chat-icons-tooltip">{'Start video chat'.translate(this.props.lang)}</div>
                                                                </button> : null
                                                                :
                                                                null
                                                            }


                                                            {/* {typeof window !== 'undefined' && window.innerWidth < 768 && this.props.uData && this.props.uData?.userLevel >= 20 && localStorage?.getItem('profile') == 'doctor' && this.state.conversation && this.state.conversation?.members && this.state.conversation?.members[0] == this.props.uData?._id && (!this.state.conversation?.closed || (this.state.conversation?.closed && this.state.conversation?.softCloseTimestamp)) ?
                                                            <>
                                                                {
                                                                    this.state.conversation?.softCloseTimestamp ?
                                                                        this.state.conversation?.referral && this.state.conversation?.referral.dropIn ?
                                                                            <Button className="close-conversation-button-mobile" onClick={() => { this.closeCase(this.state.conversation) }} color="danger" size="sm">&times; <div class="chat-icons-tooltip">{'Hard close case'.translate(this.props.lang)}</div> </Button>
                                                                            :
                                                                            <Button className="close-conversation-button-mobile" onClick={() => { this.close(this.state.conversation?._id) }} color="danger" size="sm"> &times; <div class="chat-icons-tooltip">{'Hard close conversation'.translate(this.props.lang)}</div> </Button>
                                                                        :
                                                                        this.state.conversation?.referral && this.state.conversation?.referral.dropIn ?
                                                                            <Button className="close-conversation-button-mobile" onClick={() => this.setState({ closeCaseModal: this.state.conversation, chatHelpIcons: false })} color="danger" size="sm">&times; <div class="chat-icons-tooltip">{'Close case'.translate(this.props.lang)}</div> </Button>
                                                                            :
                                                                            <Button className="close-conversation-button-mobile" onClick={() => this.setState({ closeModal: this.state.conversation, chatHelpIcons: false })} color="danger" size="sm"> &times; <div class="chat-icons-tooltip">{'Close conversation'.translate(this.props.lang)}</div> </Button>
                                                                }
                                                            </>
                                                            : */}
                                                            {typeof window !== 'undefined' && window.innerWidth < 768 && this.props.uData && this.props.uData?.userLevel >= 20 && localStorage?.getItem('profile') == 'doctor' && this.state.conversation && this.state.conversation?.members && this.state.conversation?.members[0] != this.props.uData?._id && !this.state.conversation?.closed && !this.state.conversation?.consultationId && !this.state.conversation?.eConnectId ?
                                                                // <Button className="close-conversation-button" onClick={() => this.setState({ leaveChatModal: this.state.conversation })} color="danger" size="sm">{'Leave chat'.translate(this.props.lang)} </Button>
                                                                <Button className="close-conversation-button-mobile" onClick={() => this.setState({ leaveChatModal: this.state.conversation, chatHelpIcons: false })} color="danger" size="sm"> &times; <div class="chat-icons-tooltip">{'Leave chat'.translate(this.props.lang)}</div> </Button>
                                                                :
                                                                null
                                                            }
                                                            {/* {
                                                            this.props.uData && this.props.uData?.userLevel >= 20 && localStorage?.getItem('profile') == 'doctor' && this.state.conversation && this.state.conversation?.members && this.state.conversation?.members[0] == this.props.uData?._id && this.state.conversation?.closed ?
                                                                <button className='open-button' onClick={() => this.setState({ openModal: this.state.conversation })}>
                                                                    <Isvg src={openIcon} />
                                                                    <div className="chat-icons-tooltip">
                                                                        {'Open conversation asdf'.translate(this.props.lang)}
                                                                    </div>
                                                                </button>
                                                                :
                                                                null
                                                        } */}


                                                            {typeof window !== 'undefined' && window.innerWidth < 768 && this.props.uData && this.props.uData?.userLevel >= 20 && localStorage?.getItem('profile') == 'doctor' && selectedGroupIdx != -1 && this.state.conversation?.groupIds && this.state.conversation?.groupIds.indexOf(this.props.selectedGroup) != -1 && this.state.conversation && this.state.conversation?.members && this.state.conversation?.members[0] == this.props.uData?._id && !this.state.conversation?.consultationId && !this.state.conversation?.eConnectId ?
                                                                <button disabled={this.props.uData?.clinicGroups?.[selectedGroupIdx]?.videoLicence <= 0} onClick={() => {
                                                                    let datePlus5Min = Math.floor(new Date().getTime() / 1000) + 60 * 5
                                                                    // let date = new Date(datePlus5Min * 1000)
                                                                    this.setState({ shareModal: `${window.location.origin}/${this.state.conversation?.smallLink}`, shareLinkTime: new Date(datePlus5Min * 1000) })
                                                                    console.log(this.roomIdInput)
                                                                    // this.setState({ chatHelpIcons: false });
                                                                    // this.showCopiedLink();
                                                                    // const copyText = this.roomIdInput
                                                                    // copyText.select();
                                                                    // document.execCommand('copy');


                                                                }} className={this.state.linkCopied && false ? "share-button link-copy-progress" : "share-button"}>
                                                                    <Isvg src={shareIcon} />
                                                                    <div className={this.state.linkCopied && false ? 'link-copied yes' : 'link-copied'}>
                                                                        {'The link is copied to clipboard'.translate(this.props.lang)}
                                                                    </div>
                                                                    <div className="chat-icons-tooltip">{'Invite external to video calls'.translate(this.props.lang)}</div> </button>
                                                                :
                                                                null
                                                            }
                                                            {(!params.patientSearchModule && this.state.conversation && this.state.conversation?.closed && this.props.uData && this.props.uData?.userLevel >= 20 && localStorage?.getItem('profile') == 'doctor' && this.state.conversation && this.state.conversation?.members && this.state.conversation?.members[0] == this.props.uData?._id && !this.state.conversation?.softCloseTimestamp) ||
                                                                (!params.patientSearchModule && this.state.conversation && this.state.conversation?.closed && this.props.uData && this.props.uData?.userLevel >= 20 && localStorage?.getItem('profile') == 'doctor' && this.state.conversation && this.state.conversation?.members && this.state.conversation?.members[0] == this.props.uData?._id && this.state.conversation?.softCloseTimestamp && this.state.conversation?.softCloseTimestamp < Math.floor(new Date().getTime() / 1000)) ?
                                                                <button className='delete-button' onClick={() => this.setState({ deleteConversationModal: this.state.conversation })}>
                                                                    <Isvg src={garabage} />
                                                                    <div className="chat-icons-tooltip">
                                                                        {'Delete conversation'.translate(this.props.lang)}
                                                                    </div>
                                                                </button>
                                                                :
                                                                null
                                                            }
                                                        </div>
                                                        {
                                                            (this.props.uData?.userLevel < 20 && this.state.conversation?.referral) || this.props.uData?.userLevel >= 20 ?
                                                                <div className={this.state.dropdownMore ? 'menu-more-container open' : 'menu-more-container'} onClick={() => this.toggleDropdownMore()} ref={node => this.dropDownMenuRef = node}>
                                                                    <Isvg src={barsIcon} className='menu-more' />
                                                                    <ul className={`menu-more-dropdown ${this.state.dropdownMore ? 'open' : ''}  ${this.state.chatType === 'team' ? 'team-chat-shadow' : ''}`} ref={node => this.dropDownRef = node}>
                                                                        <li>
                                                                            <button className="chat-header-icon" onClick={() => this.setState({ showGallery: true, chatHelpIcons: false })}>
                                                                                {'Media'.translate(this.props.lang)}
                                                                            </button>
                                                                        </li>
                                                                        <li>
                                                                            {this.props.uData && this.props.uData?.userLevel >= 20 && localStorage?.getItem('profile') == 'doctor' && selectedGroupIdx != -1 && this.state.conversation?.groupIds && this.state.conversation?.groupIds.indexOf(this.props.selectedGroup) != -1 && this.state.conversation && this.state.conversation?.members && this.state.conversation?.members[0] == this.props.uData?._id && !this.state.conversation?.consultationId && !this.state.conversation?.eConnectId ?
                                                                                <button disabled={this.props.uData?.clinicGroups?.[selectedGroupIdx]?.videoLicence <= 0} onClick={() => {
                                                                                    let datePlus5Min = Math.floor(new Date().getTime() / 1000) + 60 * 5
                                                                                    // let date = new Date(datePlus5Min * 1000)
                                                                                    this.setState({ shareModal: `${window.location.origin}/${this.state.conversation?.smallLink}`, shareLinkTime: new Date(datePlus5Min * 1000) })
                                                                                    console.log(this.roomIdInput)
                                                                                    // this.setState({ chatHelpIcons: false });
                                                                                    // this.showCopiedLink();
                                                                                    // const copyText = this.roomIdInput
                                                                                    // copyText.select();
                                                                                    // document.execCommand('copy');


                                                                                }} className="chat-header-icon">
                                                                                    {/* <Isvg src={shareIcon} /> */}
                                                                                    {/* <div className={this.state.linkCopied && false ? 'link-copied yes' : 'link-copied'}>
                                                                                    {'The link is copied to clipboard'.translate(this.props.lang)}
                                                                                </div> */}
                                                                                    {'Invite external to video calls'.translate(this.props.lang)}</button>
                                                                                :
                                                                                null
                                                                            }
                                                                        </li>
                                                                        {/* <li>
                                                                        {
                                                                            this.props.uData && this.props.uData?.userLevel && this.props.uData?.userLevel == 20 && typeof window != 'undefined' && localStorage?.getItem?.('profile') != 'patient' ?
                                                                                <button className="chat-header-icon" onClick={() => this.setState({ referral: this.state.conversation?.referral }, () => this.addToReferralPreview())}>{'Read patient answers'.translate(this.props.lang)}</button>
                                                                                :
                                                                                <button className="chat-header-icon" onClick={() => this.setState({ referral: this.state.conversation?.referral }, () => this.addToReferralPreview())}>{"My answers".translate(this.props.lang)}</button>

                                                                        }
                                                                    </li> */}
                                                                        {/* {
                                                                        this.props.uData && this.props.uData?.userLevel >= 20 && localStorage?.getItem('profile') == 'doctor' && this.state.conversation && this.state.conversation?.members && this.state.conversation?.members[0] == this.props.uData?._id && this.state.conversation?.closed ?
                                                                            <li>
                                                                                <button onClick={() => this.setState({ openModal: this.state.conversation })}>
                                                                                    {'Open conversation'.translate(this.props.lang)}
                                                                                </button>
                                                                            </li>
                                                                            :
                                                                            null
                                                                    } */}
                                                                        {(!params.patientSearchModule && this.state.conversation && this.state.conversation?.closed && this.props.uData && this.props.uData?.userLevel >= 20 && localStorage?.getItem('profile') == 'doctor' && this.state.conversation && this.state.conversation?.members && this.state.conversation?.members[0] == this.props.uData?._id && !this.state.conversation?.softCloseTimestamp) ||
                                                                            (!params.patientSearchModule && this.state.conversation && this.state.conversation?.closed && this.props.uData && this.props.uData?.userLevel >= 20 && localStorage?.getItem('profile') == 'doctor' && this.state.conversation && this.state.conversation?.members && this.state.conversation?.members[0] == this.props.uData?._id && this.state.conversation?.softCloseTimestamp && this.state.conversation?.softCloseTimestamp < Math.floor(new Date().getTime() / 1000)) ?
                                                                            <li>
                                                                                <button onClick={() => this.setState({ deleteConversationModal: this.state.conversation })}>
                                                                                    {'Delete conversation'.translate(this.props.lang)}
                                                                                </button>
                                                                            </li>
                                                                            :
                                                                            null
                                                                        }



                                                                        {/* {
                                                                        this.props.uData && this.props.uData?.userLevel >= 20 && localStorage?.getItem('profile') == 'doctor' && this.state.conversation && this.state.conversation?.members && this.state.conversation?.members[0] == this.props.uData?._id && this.state.conversation?.closed ?
                                                                            <li>
                                                                                <button onClick={() => this.setState({ openModal: this.state.conversation })}>
                                                                                    {'Open conversation'.translate(this.props.lang)}
                                                                                </button>
                                                                            </li>
                                                                            :
                                                                            null
                                                                    } */}
                                                                        {/* {(!params.patientSearchModule && this.state.conversation && this.state.conversation?.closed && this.props.uData && this.props.uData?.userLevel >= 20 && localStorage?.getItem('profile') == 'doctor' && this.state.conversation && this.state.conversation?.members && this.state.conversation?.members[0] == this.props.uData?._id && !this.state.conversation?.softCloseTimestamp) ||
                                                                        (!params.patientSearchModule && this.state.conversation && this.state.conversation?.closed && this.props.uData && this.props.uData?.userLevel >= 20 && localStorage?.getItem('profile') == 'doctor' && this.state.conversation && this.state.conversation?.members && this.state.conversation?.members[0] == this.props.uData?._id && this.state.conversation?.softCloseTimestamp && this.state.conversation?.softCloseTimestamp < Math.floor(new Date().getTime() / 1000)) ?
                                                                        <li>
                                                                            <button onClick={() => this.setState({ deleteConversationModal: this.state.conversation })}>
                                                                                {'Delete conversation'.translate(this.props.lang)}
                                                                            </button>
                                                                        </li>
                                                                        :
                                                                        null
                                                                    } */}

                                                                        {
                                                                            // this.props.uData && this.props.uData?.userLevel >= 20 && localStorage?.getItem('profile') == 'doctor' && this.state.conversation && this.state.conversation?.members && this.state.conversation?.members[0] == this.props.uData?._id && (!this.state.conversation?.closed || (this.state.conversation?.closed && this.state.conversation?.softCloseTimestamp)) ?
                                                                            //     <li>
                                                                            //         {
                                                                            //             this.state.conversation?.softCloseTimestamp ?
                                                                            //                 this.state.conversation?.referral && this.state.conversation?.referral.dropIn ?
                                                                            //                     <Button className="close-conversation-button" onClick={() => { this.closeCase(this.state.conversation) }} color="danger" size="sm">{'Hard close case'.translate(this.props.lang)} </Button>
                                                                            //                     :
                                                                            //                     <Button className="close-conversation-button" onClick={() => { this.close(this.state.conversation?._id) }} color="danger" size="sm"> {'Hard close conversation'.translate(this.props.lang)} </Button>
                                                                            //                 :
                                                                            //                 this.state.conversation?.referral && this.state.conversation?.referral.dropIn ?
                                                                            //                     <Button className="close-conversation-button" onClick={() => this.setState({ closeCaseModal: this.state.conversation })} color="danger" size="sm">{'Close case'.translate(this.props.lang)} </Button>
                                                                            //                     :
                                                                            //                     <Button className="close-conversation-button" onClick={() => this.setState({ closeModal: this.state.conversation })} color="danger" size="sm">{'Close conversation'.translate(this.props.lang)} </Button>
                                                                            //         }
                                                                            //     </li>
                                                                            //     :
                                                                            this.props.uData && this.props.uData?.userLevel >= 20 && localStorage?.getItem('profile') == 'doctor' && this.state.conversation && this.state.conversation?.members && this.state.conversation?.members[0] != this.props.uData?._id && !this.state.conversation?.closed && !this.state.conversation?.consultationId && !this.state.conversation?.eConnectId ?
                                                                                <li>
                                                                                    <Button className="close-conversation-button" onClick={() => this.setState({ leaveChatModal: this.state.conversation })} color="danger" size="sm">{'Leave chat'.translate(this.props.lang)} </Button>
                                                                                </li>
                                                                                :
                                                                                null
                                                                        }
                                                                    </ul>
                                                                </div>
                                                                :
                                                                null
                                                        }

                                                    </div>
                                                    {
                                                        !params.patientSearchModule ?
                                                            <div className="close-conversation-mob" onClick={() => this.setState({ conversation: null }, () => {
                                                                if (typeof window != 'undefined' && window && window.top)
                                                                    window.top.postMessage('chat-closed', '*')
                                                            })}>&times;</div>
                                                            :
                                                            null
                                                    }


                                                </div>

                                                {typeof localStorage != 'undefined' && localStorage?.getItem('profile') != 'patient' && this.props.uData?.userLevel != 1 && this.props.uData?.userLevel != 2 && ((this.state.conversation?.members && this.state.conversation?.members?.length > 2 && this.state.numberOfPatients > 0 && conversationNotPatientNumber > 1) || (this.state.conversation?.teamTabWasActive)) ?
                                                    <div className={this.state.chatType == 'team' ? 'head-tabs team-content' : 'head-tabs'}>

                                                        <Button className={this.state.chatType == 'patient' ? 'patient-button active ' : 'patient-button'} onClick={() => {
                                                            let conversation = this.state.conversation
                                                            conversation._newMessagePatient = false
                                                            this.setState({
                                                                chatType: 'patient',
                                                                conversation
                                                            }, () => {
                                                                this.setState({
                                                                    page: 0,
                                                                    _consultations: null
                                                                }, () => {
                                                                    this.props.socket.emit('messages', { conversation: this.state.conversation?._id, page: this.state.page, chatType: this.state.chatType })
                                                                })
                                                            })
                                                        }}> {'Patient chat'.translate(this.props.lang)} {
                                                                this.state.conversation?._newMessagePatient ?

                                                                    <Isvg src={newMessage} className="new-message-icon-tab" />

                                                                    :

                                                                    null

                                                            }</Button>

                                                        <Button className={this.state.chatType == 'team' ? 'darken' : null} onClick={() => {
                                                            let conversation = this.state.conversation
                                                            conversation._newMessageTeam = false
                                                            this.setState({
                                                                chatType: 'team',
                                                                conversation
                                                            }, () => {
                                                                this.setState({
                                                                    page: 0,
                                                                    _consultations: null
                                                                }, () => {
                                                                    this.props.socket.emit('messages', { conversation: this.state.conversation?._id, page: this.state.page, chatType: this.state.chatType })
                                                                })
                                                            })
                                                        }}> {'Team chat'.translate(this.props.lang)}{
                                                                this.state.conversation?._newMessageTeam ?

                                                                    <Isvg src={newMessage} className="new-message-icon-tab" />

                                                                    :

                                                                    null

                                                            }</Button>
                                                    </div>
                                                    :
                                                    null}


                                                {
                                                    this.state.searchMembers && this.state.conversation ?
                                                        <div className="search-members">

                                                            <div className="search-area">
                                                                <div className="search-header-wrap" style={{ flexDirection: 'column' }}>
                                                                    <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                                                        <h4>{'Search patients'.translate(this.props.lang)}</h4>
                                                                        <button className="close" onClick={() => this.setState({ searchMembers: null, searchUsers: null, searchMembersText: '', errorTooManyPatients: false })}>&times;</button>
                                                                    </div>
                                                                    <h6 style={{ fontSize: '15px' }}>{'Note that added participants will see the conversation history.'.translate(this.props.lang)}</h6>
                                                                </div>
                                                                <Input type="text" placeholder={'Search'.translate(this.props.lang)} value={this.state.searchMembersText} onChange={(e) => {
                                                                    this.setState({ searchMembersText: striptags(e.target.value), errorTooManyPatients: false }, () => {
                                                                        this.lastChangeFilter = new Date().getTime();
                                                                        setTimeout(() => {
                                                                            this.searchDelay()
                                                                        }, filterDelay);
                                                                    })


                                                                }} />
                                                                {
                                                                    this.state.loadingSearchUsers ?
                                                                        <div className="loader-wrap">
                                                                            <Player
                                                                                autoplay={true}
                                                                                loop={true}
                                                                                src="/lf30_editor_l5cxzdyf.json"
                                                                                style={{
                                                                                    height: typeof window != "undefined" && window.innerWidth < 768 ? "128px" : "256px",
                                                                                    width: typeof window != "undefined" && window.innerWidth < 768 ? "128px" : "256px",
                                                                                }}
                                                                            ></Player>

                                                                        </div>
                                                                        :
                                                                        null
                                                                }


                                                                <ul>
                                                                    {
                                                                        !this.state.loadingSearchUsers && this.state.searchUsers && this.state.searchUsers.map((item, idx) => {
                                                                            let members = this.state.conversation?.members ?? [];
                                                                            let referralData;
                                                                            let childId = this.state.conversation?.childId ?? null;
                                                                            let numberOfParents = 0;
                                                                            let checkNumberOfParentsInConversation = 0;
                                                                            let parents = item?.parents ?? [];

                                                                            if (this.state.conversation?.referral?.patient?.socialSecurityNumber) {
                                                                                let patientObj = JSON.parse(JSON.stringify(this.state.conversation.referral.patient))
                                                                                referralData = {
                                                                                    patientSSN: patientObj.socialSecurityNumber,
                                                                                    parent1SSN: patientObj?.parents?.[0]?.socialSecurityNumber ?? null,
                                                                                    parent2SSN: patientObj?.parents?.[1]?.socialSecurityNumber ?? null
                                                                                }
                                                                            }
                                                                            let showRemoveBtn = false;
                                                                            let showAddBtn = false;
                                                                            let showEditBtn = false;
                                                                            let useUserdataName = false;


                                                                            if (item?._id) {
                                                                                if (members?.indexOf(item._id) !== -1) {
                                                                                    showRemoveBtn = true;
                                                                                } else {
                                                                                    showAddBtn = true;
                                                                                }

                                                                                if (item.childUser) {
                                                                                    numberOfParents = parents.length ?? 0;

                                                                                    if (numberOfParents > 0) {
                                                                                        for (let i = 0; i < parents.length; i++) {
                                                                                            let parent = parents[i];
                                                                                            if (members?.indexOf(parent._id) !== -1) {
                                                                                                checkNumberOfParentsInConversation++;
                                                                                            }
                                                                                        }
                                                                                    }
                                                                                    if (childId === item._id && checkNumberOfParentsInConversation > 0) {
                                                                                        showEditBtn = true;
                                                                                        showAddBtn = false;
                                                                                    }
                                                                                }

                                                                                if (item.childUser && showRemoveBtn) {
                                                                                    showRemoveBtn = false;
                                                                                }
                                                                                if (item.socialSecurityNumber === referralData?.patientSSN || item.socialSecurityNumber === referralData?.parent1SSN || item.socialSecurityNumber === referralData?.parent2SSN) {
                                                                                    showRemoveBtn = false;
                                                                                    showAddBtn = false;
                                                                                    showEditBtn = false;
                                                                                    useUserdataName = true;
                                                                                }
                                                                            }
                                                                            let memberName = item.name;

                                                                            if (!useUserdataName) {
                                                                                if (item.userLevel == 1 || item.userLevel == 2) {
                                                                                    useUserdataName = true;
                                                                                } else if (item.userLevel == 20 && ((item.socialSecurityNumber && this.state.conversation && this.state.conversation?.control && this.state.conversation?.socialSecurityNumber && this.state.conversation?.socialSecurityNumber == item.socialSecurityNumber) || item.patientInThisGroup)) {
                                                                                    useUserdataName = true;
                                                                                }
                                                                            }

                                                                            if (useUserdataName && item?.userData?.name) {
                                                                                memberName = item.userData.name
                                                                            }

                                                                            if (item?._id != this.props.uData?._id) {
                                                                                return (
                                                                                    <li key={item?._id + idx + '-' + this.state.conversation?.members.indexOf(item?._id)}>
                                                                                        <div className="user-image">
                                                                                            <img src={item.profilePicture ? API_ENDPOINT + item.profilePicture : profilePicture} />
                                                                                            <div className={item.online ? 'status online' : 'status offline'}></div>
                                                                                        </div>
                                                                                        <p>
                                                                                            {memberName}

                                                                                            {
                                                                                                item.userData && item.socialSecurityNumber && (item.userLevel == 1 || item.userLevel == 2 || (item.userLevel == 20 && ((item.socialSecurityNumber && this.state.conversation && this.state.conversation?.referral && this.state.conversation?.referral.patient && this.state.conversation?.referral.patient.socialSecurityNumber && this.state.conversation?.referral.patient.name && this.state.conversation?.referral.patient.socialSecurityNumber == item.socialSecurityNumber) || (item.socialSecurityNumber && this.state.conversation && this.state.conversation?.control && this.state.conversation?.socialSecurityNumber && this.state.conversation?.socialSecurityNumber == item.socialSecurityNumber) || item.patientInThisGroup))) ?
                                                                                                    <><br />{item.socialSecurityNumber}</>
                                                                                                    :
                                                                                                    null
                                                                                            }
                                                                                        </p>
                                                                                        {
                                                                                            showRemoveBtn ?
                                                                                                <button onClick={() => this.removeMember(item?._id)} className="delete-member"><Isvg src={garabage} /></button>
                                                                                                :
                                                                                                showAddBtn ?
                                                                                                    <Button color="primary" onClick={() => {
                                                                                                        if (numberOfParents === 0) {
                                                                                                            this.addMember(item)
                                                                                                        } else if (numberOfParents === 1 && parents[0]) {
                                                                                                            this.addMember({
                                                                                                                ...item,
                                                                                                                _id: parents[0]._id
                                                                                                            })
                                                                                                        } else {
                                                                                                            if (!this.state.conversation?.childId || this.state.conversation?.childId === item._id) {
                                                                                                                let editConversationChild = {
                                                                                                                    item: item,
                                                                                                                    parents: parents
                                                                                                                }
                                                                                                                this.setState({ editConversationChild })
                                                                                                            } else {
                                                                                                                this.setState({ errorTooManyPatients: 'There can be only one patient in the conversation' })
                                                                                                            }

                                                                                                        }
                                                                                                    }}>{'Add'.translate(this.props.lang)}</Button>
                                                                                                    :
                                                                                                    showEditBtn ?
                                                                                                        <Button color="primary" className='edit-btn-conversation-child' onClick={() => {

                                                                                                            let editConversationChild = {
                                                                                                                item: item,
                                                                                                                parents: parents
                                                                                                            }
                                                                                                            this.setState({ editConversationChild })
                                                                                                        }}>{'Edit'.translate(this.props.lang)}</Button>
                                                                                                        :
                                                                                                        null

                                                                                        }
                                                                                        {/* {(this.state.conversation?.members.indexOf(item?._id) !== -1 && !item.childUser) && (!this.state.conversation?.referral ||
(this.state.conversation?.referral && this.state.conversation?.referral.patient && item.socialSecurityNumber &&
this.state.conversation?.referral.patient.socialSecurityNumber != item.socialSecurityNumber &&
(!this.state.conversation?.referral.patient.parents || (this.state.conversation?.referral.patient.parents &&
(!this.state.conversation?.referral.patient.parents[0] || (this.state.conversation?.referral.patient.parents[0] && !this.state.conversation?.referral.patient.parents[0].socialSecurityNumber) || (this.state.conversation?.referral.patient.parents[0] && this.state.conversation?.referral.patient.parents[0].socialSecurityNumber && this.state.conversation?.referral.patient.parents[0].socialSecurityNumber != item.socialSecurityNumber)) &&
(!this.state.conversation?.referral.patient.parents[1] || (this.state.conversation?.referral.patient.parents[1] && !this.state.conversation?.referral.patient.parents[1].socialSecurityNumber) || (this.state.conversation?.referral.patient.parents[1] && this.state.conversation?.referral.patient.parents[1].socialSecurityNumber && this.state.conversation?.referral.patient.parents[1].socialSecurityNumber != item.socialSecurityNumber))
))
))
?
<button onClick={() => this.removeMember(item?._id)} className="delete-member"><Isvg src={garabage} /></button>
:
this.state.conversation?.members.indexOf(item?._id) === -1 ?
<Button color="primary" onClick={() => this.addMember(item)}>{'Add'.translate(this.props.lang)}</Button>
:
null
} */}
                                                                                    </li>
                                                                                )
                                                                            }

                                                                        })
                                                                    }

                                                                </ul>
                                                                {
                                                                    this.state.editConversationChild ?
                                                                        <Modal isOpen={this.state.editConversationChild} centered className="edit-conversations-child-members">
                                                                            <ModalHeader style={{ margin: 'auto' }}>
                                                                                {'Parents'.translate(this.props.lang)}
                                                                            </ModalHeader>
                                                                            <ModalBody className="search-members">
                                                                                <ul>
                                                                                    {
                                                                                        this.state.editConversationChild?.parents?.map((item, idx) => {
                                                                                            let members = this.state.conversation?.members ?? [];

                                                                                            let showRemoveBtn = false;
                                                                                            let showAddBtn = false;

                                                                                            if (item?._id) {
                                                                                                if (members?.indexOf(item._id) !== -1) {
                                                                                                    showRemoveBtn = true;
                                                                                                } else {
                                                                                                    showAddBtn = true;
                                                                                                }
                                                                                            }


                                                                                            if (item?._id != this.props.uData?._id) {
                                                                                                return (
                                                                                                    <li key={item?._id + idx + '-' + this.state.conversation?.members.indexOf(item?._id)}>
                                                                                                        <div className="user-image">
                                                                                                            <img src={item.profilePicture ? API_ENDPOINT + item.profilePicture : profilePicture} />
                                                                                                            <div className={item.online ? 'status online' : 'status offline'}></div>
                                                                                                        </div>
                                                                                                        <p>
                                                                                                            {
                                                                                                                item.userData && item.userData.name && (item.userLevel == 1 || item.userLevel == 2 || (item.userLevel == 20 && ((item.socialSecurityNumber && this.state.conversation && this.state.conversation?.referral && this.state.conversation?.referral.patient && this.state.conversation?.referral.patient.socialSecurityNumber && this.state.conversation?.referral.patient.name && this.state.conversation?.referral.patient.socialSecurityNumber == item.socialSecurityNumber) || (item.socialSecurityNumber && this.state.conversation && this.state.conversation?.control && this.state.conversation?.socialSecurityNumber && this.state.conversation?.socialSecurityNumber == item.socialSecurityNumber) || item.patientInThisGroup))) ?
                                                                                                                    item.userData.name
                                                                                                                    :
                                                                                                                    item.name
                                                                                                            }
                                                                                                            {
                                                                                                                item.userData && item.socialSecurityNumber && (item.userLevel == 1 || item.userLevel == 2 || (item.userLevel == 20 && ((item.socialSecurityNumber && this.state.conversation && this.state.conversation?.referral && this.state.conversation?.referral.patient && this.state.conversation?.referral.patient.socialSecurityNumber && this.state.conversation?.referral.patient.name && this.state.conversation?.referral.patient.socialSecurityNumber == item.socialSecurityNumber) || (item.socialSecurityNumber && this.state.conversation && this.state.conversation?.control && this.state.conversation?.socialSecurityNumber && this.state.conversation?.socialSecurityNumber == item.socialSecurityNumber) || item.patientInThisGroup))) ?
                                                                                                                    <><br />{item.socialSecurityNumber}</>
                                                                                                                    :
                                                                                                                    null
                                                                                                            }
                                                                                                        </p>
                                                                                                        {
                                                                                                            showRemoveBtn ?
                                                                                                                <button onClick={() => this.removeMember(item?._id)} className="delete-member"><Isvg src={garabage} /></button>
                                                                                                                :
                                                                                                                showAddBtn ?
                                                                                                                    <Button color="primary" onClick={() => {
                                                                                                                        if (this.state.editConversationChild?.item) {
                                                                                                                            if (!this.state.conversation?.childId || this.state.editConversationChild?.item?._id === this.state.conversation?.childId) {

                                                                                                                                this.addMember({
                                                                                                                                    ...this.state.editConversationChild.item,
                                                                                                                                    _id: item._id
                                                                                                                                }, true)
                                                                                                                            } else {
                                                                                                                                this.setState({ errorTooManyPatients: 'There can be only one patient in the conversation' })
                                                                                                                            }
                                                                                                                        }


                                                                                                                    }}>{'Add'.translate(this.props.lang)}</Button>
                                                                                                                    :

                                                                                                                    null

                                                                                                        }

                                                                                                    </li>
                                                                                                )
                                                                                            }

                                                                                        })
                                                                                    }

                                                                                </ul>

                                                                            </ModalBody>
                                                                            <ModalFooter style={{ position: 'relative', marginTop: '20px' }} className="buttons-right-blue">
                                                                                <Button className="button-blue" onClick={() => this.setState({ editConversationChild: null })} >{'Close'.translate(this.props.lang)}</Button>{' '}

                                                                            </ModalFooter>
                                                                        </Modal>
                                                                        :
                                                                        null
                                                                }
                                                                {this.state.errorTooManyPatients ?

                                                                    <ErrorModal lang={this.props.lang}
                                                                        isOpen={this.state.errorTooManyPatients}
                                                                        toggle={() => this.setState({ errorTooManyPatients: null })}

                                                                    >
                                                                        {this.state.errorTooManyPatients.translate(this.props.lang)}
                                                                    </ErrorModal>

                                                                    :
                                                                    null
                                                                }

                                                            </div>
                                                        </div>

                                                        :

                                                        null
                                                }

                                                {this.state.showGallery ?
                                                    <div className="conversation-media">
                                                        <button className="close" onClick={() => this.setState({ showGallery: null })}>&times;</button>
                                                        <h6>{'Conversation media'.translate(this.props.lang)}</h6>
                                                        <div className="items">
                                                            {
                                                                this.state.messages.filter(item => item.image || (item.healthCareReferral && !item.hideIcon) || item.video).map((message) => {
                                                                    let _sync;
                                                                    try {
                                                                        if (enterprise && !message.deletedMessage && this.props.uData?.userLevel !== 1 && this.props.uData?.userLevel !== 2 && (localStorage && localStorage?.getItem('profile') !== 'patient') && (message.video || message.image)) {
                                                                            const patientId = this.state.conversation?.users?.find?.(obj => obj.uid && obj.patientInThisGroup) ? this.state.conversation?.users?.find?.(obj => obj.uid && obj.patientInThisGroup).uid : null;
                                                                            if (patientId) {
                                                                                const link = message.image || message.video;
                                                                                if (link) {
                                                                                    const extension = '.' + link.split('.').pop().toLowerCase();
                                                                                    const groupId = this.props.selectedGroup;
                                                                                    if (extension && getAvailableDocumentTypesForPatientDocuments(true).includes(extension) && groupId) {
                                                                                        if (!message.patientDocumentsSync || !Array.isArray(message.patientDocumentsSync) || !message.patientDocumentsSync.find(obj => obj.groupId === groupId && obj.patientId === patientId)) {
                                                                                            _sync = {
                                                                                                isEnabled: true,
                                                                                                onSync: (e) => {
                                                                                                    e.stopPropagation();
                                                                                                    this.setState({
                                                                                                        _syncPatientDocumentsModal: {
                                                                                                            data: {
                                                                                                                view: 'doctor',
                                                                                                                groupId,
                                                                                                                patientId,
                                                                                                                document: { id: 'new', isSharedWithPatient: false },
                                                                                                                src: 'chat',
                                                                                                                messageId: message._id,
                                                                                                            },
                                                                                                            lang: this.props.lang,
                                                                                                            onSync: (bodyData, isSharedWithPatient) => {
                                                                                                                this.setState({ successMessage: null, error: null }, async () => {
                                                                                                                    try {
                                                                                                                        const url = `${API_ENDPOINT}/users/users/patient/documents/add`;
                                                                                                                        const body = JSON.stringify({
                                                                                                                            ...bodyData,
                                                                                                                            document: {
                                                                                                                                ...bodyData.document,
                                                                                                                                isSharedWithPatient
                                                                                                                            }
                                                                                                                        });

                                                                                                                        const response = await fetch(url, {
                                                                                                                            method: 'POST',
                                                                                                                            headers: {
                                                                                                                                'Content-Type': 'application/json',
                                                                                                                                'Authorization': `Bearer ${localStorage?.getItem?.('authToken')}`
                                                                                                                            },
                                                                                                                            body,
                                                                                                                        });

                                                                                                                        if (!response) return this.setState({ error: 'Something went wrong! Please refresh your browser and try again!', _syncPatientDocumentsModal: null });
                                                                                                                        const data = await response.json();
                                                                                                                        if (!data || data.error || !data.documents) return this.setState({ error: data?.error || 'Something went wrong! Please refresh your browser and try again!', _syncPatientDocumentsModal: null });
                                                                                                                        this.setState({
                                                                                                                            successMessage: 'Document has been successfully synced to patient profile!',
                                                                                                                            _syncPatientDocumentsModal: null
                                                                                                                        }, () => {
                                                                                                                            this.setState(prev => ({
                                                                                                                                ...prev,
                                                                                                                                messages: prev.messages.map(msg => msg._id === message._id ? {
                                                                                                                                    ...msg,
                                                                                                                                    patientDocumentsSync: [
                                                                                                                                        ...(msg.patientDocumentsSync || []),
                                                                                                                                        {
                                                                                                                                            groupId,
                                                                                                                                            patientId,
                                                                                                                                        }
                                                                                                                                    ]
                                                                                                                                } : msg)
                                                                                                                            }))
                                                                                                                        })
                                                                                                                    } catch (err) {
                                                                                                                        console.log('media gallery _sync err: ', err);
                                                                                                                    }
                                                                                                                })
                                                                                                            },
                                                                                                            onCancel: () => {
                                                                                                                this.setState({
                                                                                                                    _syncPatientDocumentsModal: null
                                                                                                                })
                                                                                                            }
                                                                                                        }
                                                                                                    })
                                                                                                }
                                                                                            }
                                                                                        }
                                                                                    }
                                                                                }
                                                                            }
                                                                        }
                                                                    } catch (err) {
                                                                        console.log('media gallery _sync a err: ', err);
                                                                    }
                                                                    return (
                                                                        <div className="item">
                                                                            {
                                                                                message.uid == this.props.uData?._id && !this.state.conversation?.closed && this.props.uData?.userLevel !== 1 && this.props.uData?.userLevel !== 2 && (localStorage && localStorage?.getItem('profile') !== 'patient') ?
                                                                                    <div className="delete-message-icon" style={{ zIndex: 111 }} onClick={(e) => {
                                                                                        e.stopPropagation()
                                                                                        this.setState({ deleteMessageModal: message })
                                                                                    }}><Isvg src={garabage} /></div>
                                                                                    :
                                                                                    null
                                                                            }
                                                                            {
                                                                                _sync?.isEnabled ? <div className={`delete-message-icon delete-message-icon-sync`} style={{ zIndex: 111 }} onClick={_sync.onSync}><Isvg src={syncIcon} /></div>
                                                                                    :
                                                                                    null
                                                                            }
                                                                            {
                                                                                message.image ?
                                                                                    <a href={`/media-gateway?path=${message.image}&cid=${message.conversation}&mid=${message?._id}`} target="_blank">
                                                                                        <img src={API_ENDPOINT + `${message.image}?mt=${this.props.uData ? this.props.uData.mediaToken : ''}&cid=${message.conversation}&mid=${message?._id}`} />
                                                                                    </a>
                                                                                    :
                                                                                    message.healthCareReferral && !message.hideIcon ?
                                                                                        <div className="conversation-media-questionary"
                                                                                            onClick={() => this.setState({
                                                                                                questionaryModal: message.healthCareId,
                                                                                                questionaryMessageActive: message?._id,
                                                                                                questionarySave: true,
                                                                                                activeReferralContent: message.healthCareReferral && message.healthCareReferralObject && message.healthCareReferralObject.healthCareContent ? message.healthCareReferralObject.healthCareContent : null,
                                                                                                activeHealthCareReferralObject: message?.healthCareReferralObject,
                                                                                                questionaryAnswers: message.healthCareReferral && message.healthCareReferralObject ? message.healthCareReferralObject.answers : {}
                                                                                            }, () => {
                                                                                                if (this.state.activeHealthCareReferralObject?.clinic)
                                                                                                    this.getClinicData(this.state.activeHealthCareReferralObject.clinic)
                                                                                                this.getQuestionary(message?._id)
                                                                                            })}>
                                                                                            <div className="conversation-media-questionary-svg">
                                                                                                <Isvg src={formIcon} />
                                                                                            </div>
                                                                                            <h6>{message.message}</h6>
                                                                                        </div>
                                                                                        :
                                                                                        message.video ?
                                                                                            <div className="conversation-media-video">
                                                                                                <video width="320" height="240" controls>
                                                                                                    <source src={API_ENDPOINT + `${message.video}?mt=${this.props.uData ? this.props.uData.mediaToken : ''}&cid=${message.conversation}&mid=${message?._id}`} type="video/mp4" />
                                                                                                    <source src={API_ENDPOINT + `${message.video}?mt=${this.props.uData ? this.props.uData.mediaToken : ''}&cid=${message.conversation}&mid=${message?._id}`} type="video/ogg" />
                                                                                                </video>
                                                                                            </div>

                                                                                            :

                                                                                            null
                                                                            }

                                                                        </div>
                                                                    )
                                                                })
                                                            }

                                                        </div>
                                                    </div>
                                                    :
                                                    null}




                                                {this.state.offerData ?
                                                    <div className="call-offer">
                                                        <div class="caller">
                                                            <img src={this.state.offerData.user && this.state.offerData.user.profilePicture ? API_ENDPOINT + this.state.offerData.user.profilePicture : profilePicture} />
                                                            <p>{this.state.offerData && this.state.offerData.user ? this.state.offerData.user.name : ''}</p>
                                                        </div>
                                                        <div className="buttons">
                                                            <button onClick={this.answer} className="answer-button"><Isvg src={call} /></button>
                                                            <button onClick={this.endCall} className="decline-button"><Isvg src={call} /></button>
                                                        </div>

                                                    </div>
                                                    :
                                                    null
                                                }


                                                <div className={this.state.journalNote || this.state.writeStatementModal ? 'messages-wrapper journal-note-active-modal' : 'messages-wrapper'}
                                                    onMouseMove={(e) => {
                                                        if (this.state.messageRisaze) {
                                                            const element = document.getElementById(this.state.conversation._id);
                                                            if (element) {

                                                                element.style.height = Number(element.style.height.replace('px', '')) - e.movementY + 'px'
                                                            }
                                                        }
                                                    }} onMouseUp={() => {
                                                        this.setState({ messageRisaze: false })
                                                    }}>
                                                    {
                                                        this.state.conversation?._id === this.state.waitingRoom?.conversationId && this.state.waitingRoom?.showInfoWaitingRoom ?
                                                            <div className='patient-waiting-room-indicator-chat'>
                                                                {'Patient is in waiting room.'.translate(this.props.lang)}
                                                            </div>
                                                            :
                                                            null
                                                    }
                                                    {/*
                                                    typeof localStorage != 'undefined' && localStorage?.getItem('profile') != 'patient' && this.props.uData?.userLevel != 1 && this.props.uData?.userLevel != 2 && this.state.conversation?.members && this.state.conversation?.members?.length > 2 && this.state.conversation?.users?.filter(member => member.level < 20).length ?
                                                        <div>
                                                            <Button onClick={() => {
                                                                this.setState({
                                                                    chatType: 'patient'
                                                                }, () => {
                                                                    this.setState({
                                                                        page: 0
                                                                    }, () => {
                                                                        this.props.socket.emit('messages', { conversation: this.state.conversation?._id, page: this.state.page, chatType: this.state.chatType })
                                                                    })
                                                                })
                                                            }}>{'Patient chat'.translate(this.props.lang)}</Button>
                                                            <Button onClick={() => {
                                                                this.setState({
                                                                    chatType: 'team'
                                                                }, () => {
                                                                    this.setState({
                                                                        page: 0
                                                                    }, () => {
                                                                        this.props.socket.emit('messages', { conversation: this.state.conversation?._id, page: this.state.page, chatType: this.state.chatType })
                                                                    })
                                                                })
                                                            }}>{'Team chat'.translate(this.props.lang)}</Button>
                                                        </div>
                                                        :
                                                        null
                                                  */}
                                                    {
                                                        this.state.showChat ?
                                                            <button className="hide-chat" onClick={() => this.setState({ showChat: null })}>&times;</button>
                                                            :

                                                            null
                                                    }

                                                    {
                                                        !isPatient && this.state.conversation && this.state.conversation?.currentCall && (this.props.uData && this.props.uData?._id != this.state.conversation?.members[0]) ?
                                                            <button onClick={() => this.setState({ chatHelpIcons: false }, () => {
                                                                var strWindowFeatures = `height=${window.innerHeight - 1},width=${window.innerWidth - 1},resizable=yes,scrollbars=yes,toolbar=yes,menubar=no,location=no,directories=no, status=yes`;
                                                                this.videoCallWindow = window.open(`/video-call/${this.state.conversation?._id}?initiator=${this.state.conversation?.currentCall.initiator}`, "_blank", strWindowFeatures);
                                                                this.videoCallWindow.addEventListener('message',
                                                                    (e) => {
                                                                        if (e.data == 'end-video-call') {
                                                                            this.videoCallWindow.close();
                                                                        }

                                                                    }

                                                                    , false)
                                                            })} className="join-call-button"><Isvg src={cameraIcon} /> <span>{'Rejoin call'.translate(this.props.lang)}</span></button>

                                                            :
                                                            null

                                                    }

                                                    <div className={this.state.chatType == 'team' ? 'messages-chat-team messages-team' : typeof localStorage != 'undefined' && localStorage?.getItem('profile') != 'patient' && this.props.uData?.userLevel != 1 && this.props.uData?.userLevel != 2 && ((this.state.conversation?.members && this.state.conversation?.members?.length > 2 && this.state.numberOfPatients > 0) || (this.state.conversation?.teamTabWasActive)) ? 'messages-chat-team' : 'messages'} ref={(node) => this.messagesRef = node} style={{ paddingBottom: this.state.messageHeight && this.messagesRef ? (this.state.messageHeight + 66) : 130 }}>

                                                        {
                                                            this.state.loadingMessages ?
                                                                <div className="loader-wrap">
                                                                    <Player
                                                                        autoplay={true}
                                                                        loop={true}
                                                                        src="/lf30_editor_l5cxzdyf.json"
                                                                        style={{
                                                                            height:
                                                                                typeof window != "undefined" &&
                                                                                    window.innerWidth < 768
                                                                                    ? "128px"
                                                                                    : "256px",
                                                                            width:
                                                                                typeof window != "undefined" &&
                                                                                    window.innerWidth < 768
                                                                                    ? "128px"
                                                                                    : "256px",
                                                                        }}
                                                                    ></Player>

                                                                </div>
                                                                :
                                                                null
                                                        }
                                                        {
                                                            this.state.messages.map((item, idx) => {
                                                                let contextMenu;
                                                                if (item.uid == this.props.uData?._id && !item.deletedMessage && !this.state.conversation?.closed && this.props.uData?.userLevel !== 1 && this.props.uData?.userLevel !== 2 && (localStorage && localStorage?.getItem('profile') !== 'patient')) {
                                                                    contextMenu = {
                                                                        'delete': {
                                                                            isEnabled: true,
                                                                            onDelete: (e) => {
                                                                                e.stopPropagation();
                                                                                this.setState({ deleteMessageModal: item }, () => {
                                                                                    this.setState({ openContextMenu: null });
                                                                                })
                                                                            },
                                                                        },
                                                                    }
                                                                };
                                                                try {
                                                                    if (enterprise && !item.deletedMessage && this.props.uData?.userLevel !== 1 && this.props.uData?.userLevel !== 2 && (localStorage && localStorage?.getItem('profile') !== 'patient')) {
                                                                        if (item.document || item.video || item.image) {
                                                                            const patientId = this.state.conversation?.users?.find?.(obj => obj.uid && obj.patientInThisGroup) ? this.state.conversation?.users?.find?.(obj => obj.uid && obj.patientInThisGroup).uid : null;
                                                                            if (patientId) {
                                                                                const link = item.document ? item.document.file : item.video ? item.video : item.image ? item.image : null;
                                                                                if (link) {
                                                                                    const extension = '.' + link.split('.').pop().toLowerCase();
                                                                                    if (extension && getAvailableDocumentTypesForPatientDocuments(true).includes(extension)) {
                                                                                        const groupId = this.props.selectedGroup;
                                                                                        if (groupId) {
                                                                                            if (!item.patientDocumentsSync || !Array.isArray(item.patientDocumentsSync) || !item.patientDocumentsSync.find(obj => obj.groupId === groupId && obj.patientId === patientId)) {
                                                                                                if (!contextMenu) contextMenu = {};
                                                                                                contextMenu['sync'] = {
                                                                                                    isEnabled: true,
                                                                                                    onSync: (e) => {
                                                                                                        e.stopPropagation();
                                                                                                        this.setState({
                                                                                                            _syncPatientDocumentsModal: {
                                                                                                                data: {
                                                                                                                    view: 'doctor',
                                                                                                                    groupId,
                                                                                                                    patientId,
                                                                                                                    document: { id: 'new', isSharedWithPatient: false },
                                                                                                                    src: 'chat',
                                                                                                                    messageId: item._id,
                                                                                                                },
                                                                                                                lang: this.props.lang,
                                                                                                                onSync: (bodyData, isSharedWithPatient = false) => {
                                                                                                                    this.setState({ successMessage: null, error: null, openContextMenu: null }, async () => {
                                                                                                                        try {
                                                                                                                            const url = `${API_ENDPOINT}/users/users/patient/documents/add`;
                                                                                                                            const body = JSON.stringify({
                                                                                                                                ...bodyData,
                                                                                                                                document: {
                                                                                                                                    ...bodyData.document,
                                                                                                                                    isSharedWithPatient,
                                                                                                                                }
                                                                                                                            });

                                                                                                                            const response = await fetch(url, {
                                                                                                                                method: 'POST',
                                                                                                                                headers: {
                                                                                                                                    'Content-Type': 'application/json',
                                                                                                                                    'Authorization': `Bearer ${localStorage?.getItem?.('authToken')}`,
                                                                                                                                },
                                                                                                                                body,
                                                                                                                            });

                                                                                                                            if (!response) return this.setState({ error: 'Something went wrong! Please refresh your browser and try again!', _syncPatientDocumentsModal: null });
                                                                                                                            const data = await response.json();
                                                                                                                            if (!data || data.error || !data.documents) return this.setState({ error: data?.error || 'Something went wrong! Please refresh your browser and try again!', _syncPatientDocumentsModal: null });
                                                                                                                            this.setState({
                                                                                                                                successMessage: 'Document has been successfully synced to patient profile!',
                                                                                                                                _syncPatientDocumentsModal: null
                                                                                                                            }, () => {
                                                                                                                                this.setState(prev => ({
                                                                                                                                    ...prev,
                                                                                                                                    messages: prev.messages.map(msg => msg._id === item._id ? {
                                                                                                                                        ...msg,
                                                                                                                                        patientDocumentsSync: [
                                                                                                                                            ...(msg.patientDocumentsSync || []),
                                                                                                                                            {
                                                                                                                                                groupId,
                                                                                                                                                patientId,
                                                                                                                                            }
                                                                                                                                        ]
                                                                                                                                    } : msg)
                                                                                                                                }))
                                                                                                                            })
                                                                                                                        } catch (err) {
                                                                                                                            console.log('--- Error in context menu ---', err);
                                                                                                                        }
                                                                                                                    });
                                                                                                                },
                                                                                                                onCancel: () => { this.setState({ _syncPatientDocumentsModal: null }) }
                                                                                                            }
                                                                                                        }, () => {
                                                                                                            this.setState({ openContextMenu: null });
                                                                                                        })
                                                                                                        // this.setState({ successMessage: null, error: null, openContextMenu: null }, async () => {
                                                                                                        //     try {
                                                                                                        //         const url = `${API_ENDPOINT}/users/users/patient/documents/add`;
                                                                                                        //         const body = JSON.stringify({
                                                                                                        //             view: 'doctor',
                                                                                                        //             groupId,
                                                                                                        //             patientId,
                                                                                                        //             document: { id: 'new' },
                                                                                                        //             src: 'chat',
                                                                                                        //             messageId: item._id,
                                                                                                        //         });

                                                                                                        //         const response = await fetch(url, {
                                                                                                        //             method: 'POST',
                                                                                                        //             headers: {
                                                                                                        //                 'Content-Type': 'application/json',
                                                                                                        //                 'Authorization': `Bearer ${localStorage?.getItem?.('authToken')}`,
                                                                                                        //             },
                                                                                                        //             body,
                                                                                                        //         });

                                                                                                        //         if (!response) return this.setState({ error: 'Something went wrong! Please refresh your browser and try again!' });
                                                                                                        //         const data = await response.json();
                                                                                                        //         if (!data || data.error || !data.documents) return this.setState({ error: data?.error || 'Something went wrong! Please refresh your browser and try again!' });
                                                                                                        //         this.setState({
                                                                                                        //             successMessage: 'Document has been successfully synced to patient profile!',
                                                                                                        //         }, () => {
                                                                                                        //             this.setState(prev => ({
                                                                                                        //                 ...prev,
                                                                                                        //                 messages: prev.messages.map(msg => msg._id === item._id ? {
                                                                                                        //                     ...msg,
                                                                                                        //                     patientDocumentsSync: [
                                                                                                        //                         ...(msg.patientDocumentsSync || []),
                                                                                                        //                         {
                                                                                                        //                             groupId,
                                                                                                        //                             patientId,
                                                                                                        //                         }
                                                                                                        //                     ]
                                                                                                        //                 } : msg)
                                                                                                        //             }))
                                                                                                        //         })
                                                                                                        //     } catch (err) {
                                                                                                        //         console.log('--- Error in context menu ---', err);
                                                                                                        //     }
                                                                                                        // })

                                                                                                    }
                                                                                                };
                                                                                            }
                                                                                        }
                                                                                    }
                                                                                }

                                                                            }

                                                                        }
                                                                    }
                                                                } catch (err) {
                                                                    console.log('--- Error in context menu ---', err);
                                                                }


                                                                if ((this.state.chatType == 'team' && item.chatType == 'team') || (this.state.chatType == 'patient' && !item.chatType)) {
                                                                    if (item.type == 'special') {
                                                                        return (
                                                                            <>
                                                                                {this.state.messages.length > 15 && idx == 1 ?
                                                                                    <div ><Waypoint
                                                                                        onEnter={() => {
                                                                                            this.setState({
                                                                                                page: this.state.page + 1,
                                                                                                _consultations: null
                                                                                            }, () => {
                                                                                                this.props.socket.emit('messages', { conversation: this.state.conversation?._id, page: this.state.page, chatType: this.state.chatType })
                                                                                            })
                                                                                        }}
                                                                                    />
                                                                                    </div>
                                                                                    :
                                                                                    null
                                                                                }
                                                                                {item.videoStarted ? <h6 className='video-chat-notify'>
                                                                                    <Isvg src={videoChatIcon} />
                                                                                    {/* <span className='time-started'>{moment.unix(item.tsCreated).format(this.props.dateFormat + ' HH:mm')}</span> */}
                                                                                    <span className='time-started'>{this.props.getStringDateTs(item.tsCreated, this.props.dateFormat + ' HH:mm')}</span>
                                                                                    <span className='user-name'>{item.user.name}</span>
                                                                                    <span className='notify'> {'Started video meeting'.translate(this.props.lang)}</span>
                                                                                </h6> : null}

                                                                                {item.videoJoined ? <h6 className='video-chat-notify'>
                                                                                    <Isvg src={userChatIcon} />
                                                                                    {/* <span className='time-started'>{moment.unix(item.tsCreated).format(this.props.dateFormat + ' HH:mm')}</span> */}
                                                                                    <span className='time-started'>{this.props.getStringDateTs(item.tsCreated, this.props.dateFormat + ' HH:mm')}</span>
                                                                                    <span className='user-name'>{item.user.name}</span>
                                                                                    <span className='notify'> {'Joined video meeting'.translate(this.props.lang)}</span>
                                                                                </h6> : null}

                                                                                {item.videoLeft ? <h6 className='video-chat-notify'>
                                                                                    <Isvg src={userChatIcon} />
                                                                                    {/* <span className='time-started'>{moment.unix(item.tsCreated).format(this.props.dateFormat + ' HH:mm')}</span> */}
                                                                                    <span className='time-started'>{this.props.getStringDateTs(item.tsCreated, this.props.dateFormat + ' HH:mm')}</span>
                                                                                    <span className='user-name'>{item.user.name}</span>
                                                                                    <span className='notify'> {'Left video meeting'.translate(this.props.lang)}</span>
                                                                                </h6> : null}

                                                                                {item.videoEnded ? <h6 className='video-chat-notify'>
                                                                                    <Isvg src={userChatIcon} />
                                                                                    {/* <span className='time-started'>{moment.unix(item.tsCreated).format(this.props.dateFormat + ' HH:mm')}</span> */}
                                                                                    <span className='time-started'>{this.props.getStringDateTs(item.tsCreated, this.props.dateFormat + ' HH:mm')}</span>
                                                                                    <span className='user-name'>{item.user.name}</span>
                                                                                    <span className='notify'> {'Ended video meeting'.translate(this.props.lang)}</span>
                                                                                </h6> : null}
                                                                            </>
                                                                        )

                                                                    } else
                                                                        return (
                                                                            <div className={item.uid == this.props.uData?._id ? 'right' : ''} /*style={{ wordBreak: 'break-all' }}*/>

                                                                                {this.state.messages.length > 15 && idx == 1 ?
                                                                                    <div ><Waypoint
                                                                                        onEnter={() => {
                                                                                            this.setState({
                                                                                                page: this.state.page + 1,
                                                                                                _consultations: null
                                                                                            }, () => {
                                                                                                this.props.socket.emit('messages', { conversation: this.state.conversation?._id, page: this.state.page, chatType: this.state.chatType })
                                                                                            })
                                                                                        }}
                                                                                    />
                                                                                    </div>
                                                                                    :
                                                                                    null
                                                                                }

                                                                                {
                                                                                    item.uid != this.props.uData?._id && item.closeConversationMessage ?
                                                                                        // <img src={item.cliniclogoChat ? item.cliniclogoChat : profilePicture} />
                                                                                        null
                                                                                        : item.uid != this.props.uData?._id ?
                                                                                            <img src={item.user && item.user.profilePicture ? API_ENDPOINT + item.user.profilePicture : profilePicture} />
                                                                                            :
                                                                                            null
                                                                                }
                                                                                <div>

                                                                                    <div className='break-word' onClick={() => {
                                                                                        let showSeen = this.state.showSeen;
                                                                                        showSeen[item?._id] = !showSeen[item?._id];
                                                                                        this.setState({
                                                                                            showSeen
                                                                                        })
                                                                                    }}>

                                                                                        {/* {
                                                                                            item.uid == this.props.uData?._id && !item.deletedMessage && !this.state.conversation?.closed && this.props.uData?.userLevel !== 1 && this.props.uData?.userLevel !== 2 && (localStorage && localStorage?.getItem('profile') !== 'patient') ?
                                                                                                <div className="delete-message-icon" onClick={() => this.setState({ deleteMessageModal: item })}>&times;3</div>
                                                                                                :
                                                                                                null
                                                                                        } */}
                                                                                        <div className='username' style={{ position: 'relative' }}>
                                                                                            {contextMenu ? <WithClickOutside className={`chat-context-menu ${item.uid == this.props.uData?._id ? 'chat-context-menu-right' : ''} ${this.state.chatType == 'team' && item.uid == this.props.uData?._id ? 'chat-context-menu-team' : ''}`} onClickOutside={() => {
                                                                                                if (this.state.openContextMenu && this.state.openContextMenu === item?._id) return this.setState({
                                                                                                    openContextMenu: null
                                                                                                });
                                                                                            }}>
                                                                                                <button
                                                                                                    onClick={(e) => {
                                                                                                        e.stopPropagation();
                                                                                                        if (this.state.openContextMenu && this.state.openContextMenu === item?._id) return this.setState({
                                                                                                            openContextMenu: null
                                                                                                        });

                                                                                                        this.setState({
                                                                                                            openContextMenu: item?._id
                                                                                                        })
                                                                                                    }}
                                                                                                >
                                                                                                    <Isvg src={moreIcon} />
                                                                                                </button>
                                                                                                {this.state.openContextMenu && this.state.openContextMenu === item?._id ? <ul>
                                                                                                    {contextMenu?.sync?.isEnabled ? <li key='sync'>
                                                                                                        <button type='button' onClick={contextMenu.sync.onSync}>
                                                                                                            {'Sync to patient profile'.translate(this.props.lang)}
                                                                                                        </button>
                                                                                                    </li> : null}
                                                                                                    {contextMenu?.delete?.isEnabled ? <li key='delete'>
                                                                                                        <button type='button' onClick={contextMenu.delete.onDelete}>
                                                                                                            {'Delete'.translate(this.props.lang)}
                                                                                                        </button>
                                                                                                    </li> : null}
                                                                                                </ul> : null}
                                                                                            </WithClickOutside> : null}
                                                                                            {item.closeConversationMessage ? null : <p>{item.user?.name}</p>}
                                                                                        </div>
                                                                                        {
                                                                                            // item.closeConversationMessage ?
                                                                                            //     <div className="username">
                                                                                            //         {/* {item.clinicName} */}
                                                                                            //     </div>
                                                                                            //     :
                                                                                            //     <div className="username">
                                                                                            //         {item.user && item.user.name}
                                                                                            //     </div>
                                                                                        }
                                                                                        {
                                                                                            item.deletedMessage && (item.uid == this.props.uData?._id || (this.state.conversation && (!this.state.conversation?.memberLanguages || (this.state.conversation?.memberLanguages && !this.state.conversation?.memberLanguages[this.props.uData?._id])))) ?
                                                                                                <p style={{ marginBottom: 0, fontStyle: 'italic', fontWeight: 400, fontSize: 14 }}>{item.message + ' ' + 'deleted a message'.translate(this.props.lang)}</p>
                                                                                                :
                                                                                                item.deletedMessage && (item.uid != this.props.uData?._id && this.state.conversation && this.state.conversation?.memberLanguages && this.state.conversation?.memberLanguages[this.props.uData?._id]) ?
                                                                                                    <p style={{ marginBottom: 0, fontStyle: 'italic', fontWeight: 400, fontSize: 14 }}>{item.message}</p>
                                                                                                    :
                                                                                                    null
                                                                                        }
                                                                                        {item.image ? <a target='_blank' href={`/media-gateway?path=${item.image}&mid=${item?._id}&cid=${item.conversation}`}><img className="message-image" src={API_ENDPOINT + item.image + `?mt=${this.props.uData ? this.props.uData.mediaToken : 'undefined'}&cid=${this.state.conversation?._id}&mid=${item?._id}`} /></a> : null}
                                                                                        {
                                                                                            item.video ?
                                                                                                <div className="video-in-chat-wrap">
                                                                                                    <video width="320" height="240" controls>
                                                                                                        <source src={API_ENDPOINT + `${item.video}?mt=${this.props.uData ? this.props.uData.mediaToken : ''}&cid=${item.conversation}&mid=${item?._id}`} type="video/mp4" />
                                                                                                        <source src={API_ENDPOINT + `${item.video}?mt=${this.props.uData ? this.props.uData.mediaToken : ''}&cid=${item.conversation}&mid=${item?._id}`} type="video/ogg" />
                                                                                                    </video>
                                                                                                </div>
                                                                                                :
                                                                                                null
                                                                                        }
                                                                                        {
                                                                                            item.additionalText && item.additionalText.split('\n').length ?
                                                                                                item.additionalText.split('\n').map((text, t) => {
                                                                                                    if (t != item.additionalText.split('\n').length - 1)
                                                                                                        return (
                                                                                                            <>{text}<br /></>
                                                                                                        )
                                                                                                    else
                                                                                                        return (
                                                                                                            text
                                                                                                        )
                                                                                                })
                                                                                                :
                                                                                                item.additionalText
                                                                                        }
                                                                                        {
                                                                                            item.additionalText ?
                                                                                                <><br /><br /></>
                                                                                                :
                                                                                                null
                                                                                        }

                                                                                        {
                                                                                            item.healthCareId ?
                                                                                                <div className="health-care-form-message" onClick={() => {
                                                                                                    if (!this.state.conversation.closed) {
                                                                                                        this.setState({
                                                                                                            questionaryModal: item.healthCareId,
                                                                                                            questionaryMessageActive: item?._id,
                                                                                                            questionarySave: item.healthCareReferral ? true : false,
                                                                                                            activeReferralContent: item.healthCareReferral && item.healthCareReferralObject && item.healthCareReferralObject.healthCareContent ? item.healthCareReferralObject.healthCareContent : null,
                                                                                                            activeHealthCareReferralObject: item?.healthCareReferralObject,
                                                                                                            questionaryAnswers: item.healthCareReferral && item.healthCareReferralObject ? item.healthCareReferralObject.answers : {}
                                                                                                        },
                                                                                                            () => {
                                                                                                                if (this.state.activeHealthCareReferralObject?.clinic)
                                                                                                                    this.getClinicData(this.state.activeHealthCareReferralObject.clinic)
                                                                                                                this.getQuestionary(item?._id)
                                                                                                            })
                                                                                                    }
                                                                                                }}>


                                                                                                    {
                                                                                                        !item.hideIcon ?
                                                                                                            <Isvg src={formIcon} />
                                                                                                            :
                                                                                                            null
                                                                                                    }



                                                                                                    {
                                                                                                        item.message && item.message.indexOf('The patient filled out the form successfully') > -1 ?
                                                                                                            <>
                                                                                                                {'The patient filled out the form successfully'.translate(this.props.lang) + ' - '} &nbsp;
                                                                                                                <Isvg src={formIcon} />
                                                                                                                {item.message.split('The patient filled out the form successfully - ')[1]}

                                                                                                            </>
                                                                                                            :
                                                                                                            <>

                                                                                                                {
                                                                                                                    item.message && item.message.split('\n').length ?
                                                                                                                        item.message.split('\n').map((text, t) => {
                                                                                                                            if (t != item.message.split('\n').length - 1)
                                                                                                                                return (
                                                                                                                                    <>{text}<br /></>
                                                                                                                                )
                                                                                                                            else
                                                                                                                                return (
                                                                                                                                    text
                                                                                                                                )
                                                                                                                        })
                                                                                                                        :
                                                                                                                        item.message
                                                                                                                }

                                                                                                            </>


                                                                                                    }

                                                                                                </div>
                                                                                                : null}
                                                                                        {
                                                                                            !item.healthCareId && !item.deletedMessage ?
                                                                                                item.message == 'The patient filled out the form successfully' ?
                                                                                                    item.message.translate(this.props.lang)
                                                                                                    : item.message && item.message.indexOf('The chat is now over. If you have any questions, you can send us a message in this chat for another 3 days.') > -1 ?
                                                                                                        <>
                                                                                                            {'The chat is now over. If you have any questions, you can send us a message in this chat for another 3 days.'.translate(this.props.lang)}
                                                                                                        </>
                                                                                                        :
                                                                                                        item.message && item.message.indexOf('This chat has ended and cannot be resumed.') > -1 ?
                                                                                                            <>
                                                                                                                {'This chat has ended and cannot be resumed.'.translate(this.props.lang)}
                                                                                                            </>
                                                                                                            :
                                                                                                            item.transferedToDoctor && item.message && item.message.indexOf('Case transferred - consultation') !== -1 ?
                                                                                                                <>
                                                                                                                    {`${'Case was transferred to'.translate(this.props.lang)} ${item.transferedToDoctor} ${'in'.translate(this.props.lang)} ${this.state.conversation?.clinicGroupObj?.name}.`}
                                                                                                                </>
                                                                                                                :
                                                                                                                item.message && item.message.indexOf('The medical statement regarding your case has been created. You can preview it by using the View statement button above.') > -1 ?
                                                                                                                    <>
                                                                                                                        {'The medical statement regarding your case has been created. You can preview it by using the View statement button above.'.translate(this.props.lang)}
                                                                                                                    </>
                                                                                                                    :

                                                                                                                    item.message && item.message.indexOf('The medical statement regarding your case has been updated. You can preview it by using the View statement button above.') > -1 ?
                                                                                                                        <>
                                                                                                                            {'The medical statement regarding your case has been updated. You can preview it by using the View statement button above.'.translate(this.props.lang)}
                                                                                                                        </>
                                                                                                                        :
                                                                                                                        <>
                                                                                                                            {
                                                                                                                                item.message && item.message.split('\n').length ?
                                                                                                                                    item.message.split('\n').map((text, t) => {
                                                                                                                                        if (t != item.message.split('\n').length - 1)
                                                                                                                                            return (
                                                                                                                                                <>{text}<br /></>
                                                                                                                                            )
                                                                                                                                        else
                                                                                                                                            return (
                                                                                                                                                text
                                                                                                                                            )
                                                                                                                                    })
                                                                                                                                    :

                                                                                                                                    item.message
                                                                                                                            }
                                                                                                                        </>
                                                                                                :
                                                                                                null
                                                                                        }
                                                                                        {
                                                                                            item.document ?
                                                                                                <div className={item.uid != this.props.uData?._id ? "document incomming-document" : "document"}><a target="_blank" download href={API_ENDPOINT + `${item.document.file}?mt=${this.props.uData ? this.props.uData.mediaToken : ''}&mid=${item?._id}&cid=${item.conversation}`}><Isvg src={attach} />{item.document.name}</a></div>
                                                                                                :
                                                                                                null
                                                                                        }

                                                                                        {item.links && item.links.length ? item.links.map(link => <a href={link.link} target='_blank' className={`message-link-container ${this.props.uData?._id === item.uid ? 'message-link-container-right' : ''} ${item.links.length > 1 ? 'message-link-adjust-width' : ''}`} onClick={(e) => e.stopPropagation()}>
                                                                                            <div className='message-link-image'>
                                                                                                <img src={link.image} />
                                                                                            </div>
                                                                                            <div className='message-link-title-and-link'>
                                                                                                <h6>{link.title}</h6>
                                                                                                <p>{link.link}</p>
                                                                                            </div>
                                                                                        </a>) : null}

                                                                                        {item.link ?
                                                                                            <a href={item.link.link} target='_blank' className={`message-link-container ${this.props.uData?._id === item.uid ? 'message-link-container-right' : ''}`} onClick={(e) => e.stopPropagation()}>
                                                                                                <div className='message-link-image'>
                                                                                                    <img src={item.link.image} />
                                                                                                </div>
                                                                                                <div className='message-link-title-and-link'>
                                                                                                    <h6>{item.link.title}</h6>
                                                                                                    <p>{item.link.link}</p>
                                                                                                </div>
                                                                                            </a>
                                                                                            : null}

                                                                                    </div>
                                                                                    <div className="date date-out date-white">{this.props.getStringDateTs(item.timestamp, this.props.dateFormat + ' HH:mm')/*moment.unix(item.timestamp).format(`${this.props.dateFormat} HH:mm`)*/}</div>
                                                                                    {
                                                                                        (item.seen && item.seen.filter(seenMember => seenMember.uid != this.props.uData?._id).length) && (idx == this.state.messages.length - 1 || this.state.showSeen[item?._id]) ?
                                                                                            <p>
                                                                                                {'Seen by: '.translate(this.props.lang)} {item.seen && item.seen.filter(seenMember => seenMember.uid != this.props.uData?._id && seenMember.uid && seenMember.uid.indexOf('guest') == -1).map((seenMember) => { return memebersDict[seenMember.uid] ? memebersDict[seenMember.uid].name : '' }).join(', ')}
                                                                                            </p>
                                                                                            :
                                                                                            null
                                                                                    }


                                                                                </div>

                                                                            </div>
                                                                        )
                                                                }

                                                            })
                                                        }

                                                        {
                                                            this.state.userTyping && this.state.conversation && this.state.conversation?._id == this.state.userTypingConversation && this.state.userTyping[this.state.conversation._id] && this.state.userTyping[this.state.conversation._id][this.state.chatType] ?
                                                                <span className="user-typing">{this.state.userTyping[this.state.conversation._id][this.state.chatType].name} {'is typing...'.translate(this.props.lang)}</span>
                                                                :
                                                                null
                                                        }
                                                        {/* / */}
                                                        <div className={this.state.writeStatementModal ? 'journal-note-wrap show-journal-note' : 'journal-note-wrap'}>

                                                            <div className="journal-note-header" >
                                                                <div className='title'>
                                                                    <h4>{'Write statement'.translate(this.props.lang)}</h4>
                                                                </div>
                                                                <div className="exit-icon" onClick={() => this.setState({ writeStatementModal: false })}>&times;</div>


                                                            </div>
                                                            {
                                                                this.state.writeStatementModal ?
                                                                    <WriteStatement
                                                                        {...this.props}
                                                                        clinic={this.props.selectedClinic}
                                                                        conversation={this.state.conversation}
                                                                        updateStatementInConversation={(statement) => {
                                                                            let conversation = this.state.conversation;
                                                                            conversation.statement = statement;
                                                                            this.setState({ conversation })
                                                                        }}
                                                                        socket={this.props.socket}
                                                                        updateStatementLastCreatedPdfInConversation={(statementLastCreatedPdf) => {
                                                                            let conversation = this.state.conversation;
                                                                            conversation.statementLastCreatedPdf = statementLastCreatedPdf;
                                                                            this.setState({ conversation })
                                                                        }}
                                                                    />
                                                                    :
                                                                    null
                                                            }

                                                        </div>


                                                        <div className={this.state.journalNote ? 'journal-note-wrap show-journal-note' : 'journal-note-wrap'}>

                                                            <div className="journal-note-header" >
                                                                <div className='title'>
                                                                    <h4>{'Journal notes'.translate(this.props.lang)}</h4>
                                                                </div>
                                                                <div className="exit-icon" onClick={() => this.setState({ journalNote: false }, this.removeFromJorunalNotes)}>&times;</div>
                                                            </div>
                                                            {
                                                                this.state.conversation && this.state.conversation?.referral && (this.state.conversation?.referral.intelligentReferral || (this.state.conversation?.referral.event && this.state.conversation?.referral.event.revisitFormsArray && this.state.conversation?.referral.event.revisitFormsArray.length && this.state.conversation?.referral.event.journalNoteFormSelected && this.state.conversation?.referral.event.revisitFormsArray.filter(el => el.revisitQuestionaryId == this.state.conversation?.referral.event.journalNoteFormSelected).length && this.state.conversation?.referral.event.revisitFormsArray.filter(el => el.revisitQuestionaryId == this.state.conversation?.referral.event.journalNoteFormSelected)[0].intelligentReferral)) && this.state.countOFSections ?
                                                                    <div className="journal-note-content">
                                                                        <FormGroup>
                                                                            <Label>{'Kontaktorsak'.translate(this.props.lang)}</Label>
                                                                            <Input type='text'
                                                                                onBlur={() => this.updateJournalNoteOnExit()}
                                                                                value={kontaktorsak}
                                                                                onChange={(e) => {
                                                                                    let conversation = this.state.conversation;
                                                                                    // conversation.referral.questionaryName = striptags(e.target.value);
                                                                                    conversation.referral.questionaryName = e.target.value;
                                                                                    this.setState({ conversation }, () => this.generateIntelligentPdf())
                                                                                }}
                                                                            />
                                                                        </FormGroup>
                                                                        {
                                                                            this.state.journalNoteIntelligentSections && this.state.journalNoteIntelligentSections.map((questionary, i) => {
                                                                                // console.log('questionary', questionary);
                                                                                return (
                                                                                    <FormGroup>
                                                                                        <Label>{questionary.name ? questionary.name === 'Bedömning' || questionary.name === 'Åtgärd' ? questionary.name.translate(this.props.lang) : questionary.name : ''}</Label>
                                                                                        <Input type='textarea'
                                                                                            // value={striptags(questionary && questionary.answer ? questionary.answer.replace(/&gt;/g, '>').replace(/&lt;/g, '<') : '')}
                                                                                            value={questionary && questionary.answer ? questionary.answer : ''}
                                                                                            onChange={(e) => {
                                                                                                let journalNoteIntelligentSections = this.state.journalNoteIntelligentSections;
                                                                                                // journalNoteIntelligentSections[i].answer = striptags(e.target.value);
                                                                                                journalNoteIntelligentSections[i].answer = e.target.value;
                                                                                                this.setState({ journalNoteIntelligentSections }, () => this.generateIntelligentPdf())
                                                                                            }}
                                                                                            onBlur={() => this.updateJournalNoteOnExit()}
                                                                                            style={{ height: 150 }} />
                                                                                    </FormGroup>
                                                                                )
                                                                            })

                                                                        }


                                                                        <div className="journal-note-buttons">

                                                                            {
                                                                                this.state.htmlJournal ?
                                                                                    <PdfSave lang={this.props.lang} html={this.state.htmlJournal} pdfName={this.generatePdfName()} />

                                                                                    : null
                                                                            }
                                                                            <Button color='primary' onClick={() => {
                                                                                navigator.clipboard.writeText(this.state.textClipboard)
                                                                            }}>{'Copy text'.translate(this.props.lang)}</Button>
                                                                            <Button
                                                                                color='primary'
                                                                                onClick={() => this.updateJournalNoteOnExit(null, true)}
                                                                                disabled={isExportToJournalSystemDisabled}
                                                                            >
                                                                                {this.state.loadingJournalNoteExport ? /*'Processing...'.translate(this.props.lang)*/

                                                                                    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                                                                        <Player
                                                                                            onEvent={() => {
                                                                                                if (!this.state.loadingJournalNoteExport) this.stopAnimation();
                                                                                            }}
                                                                                            onStateChange={this.toggleVisibility}
                                                                                            ref={this.player} // set the ref to your class instance
                                                                                            autoplay={true}
                                                                                            loop={true}
                                                                                            controls={true}
                                                                                            src="https://assets8.lottiefiles.com/packages/lf20_ZSRKCF.json"
                                                                                            style={{ height: "20px", width: "30px" }}
                                                                                        ></Player>
                                                                                        {'Processing...'.translate(this.props.lang)}
                                                                                    </div>
                                                                                    :
                                                                                    'Export to journal system'.translate(this.props.lang)}
                                                                            </Button>
                                                                        </div>

                                                                    </div>
                                                                    :
                                                                    this.state.conversation && this.state.conversation?.referral && (!this.state.conversation?.referral.intelligentReferral || ((this.state.conversation?.referral.intelligentReferral || (this.state.conversation?.referral.event && this.state.conversation?.referral.event.revisitFormsArray && this.state.conversation?.referral.event.revisitFormsArray.length && this.state.conversation?.referral.event.journalNoteFormSelected && this.state.conversation?.referral.event.revisitFormsArray.filter(el => el.revisitQuestionaryId == this.state.conversation?.referral.event.journalNoteFormSelected).length && this.state.conversation?.referral.event.revisitFormsArray.filter(el => el.revisitQuestionaryId == this.state.conversation?.referral.event.journalNoteFormSelected)[0].intelligentReferral)) && !this.state.countOFSections)) ?
                                                                        <div className="journal-note-content">
                                                                            {/* --------------------------------  */}
                                                                            <FormGroup>
                                                                                <Label>{'Kontaktorsak'.translate(this.props.lang)}</Label>
                                                                                <Input type='text' value={kontaktorsak}
                                                                                    onChange={(e) => {
                                                                                        let journalNoteObj = this.state.journalNoteObj;
                                                                                        // journalNoteObj.kontaktorsak = striptags(e.target.value);
                                                                                        journalNoteObj.kontaktorsak = e.target.value;
                                                                                        this.setState({ journalNoteObj }, () => this.generatePdf())
                                                                                    }}
                                                                                    onBlur={() => this.updateJournalNoteOnExit()}
                                                                                />
                                                                            </FormGroup>

                                                                            <FormGroup>
                                                                                <Label>{'Anamnes'.translate(this.props.lang)}</Label>
                                                                                <Input type='textarea'
                                                                                    // value={this.state.journalNoteObj.anamnes}
                                                                                    value={this.state.journalNoteObj.anamnes ? this.state.journalNoteObj.anamnes : ''}
                                                                                    style={{ height: 100 }} onChange={(e) => {
                                                                                        let journalNoteObj = this.state.journalNoteObj;
                                                                                        journalNoteObj.anamnes = e.target.value;
                                                                                        this.setState({ journalNoteObj }, () => this.generatePdf())
                                                                                    }}
                                                                                    onBlur={() => this.updateJournalNoteOnExit()}
                                                                                />
                                                                            </FormGroup>
                                                                            <FormGroup>
                                                                                <Label>{'Status'.translate(this.props.lang)}</Label>
                                                                                <Input type='textarea'
                                                                                    value={this.state.journalNoteObj.status ? this.state.journalNoteObj.status : ''}
                                                                                    style={{ height: 100 }} onChange={(e) => {
                                                                                        let journalNoteObj = this.state.journalNoteObj;
                                                                                        // journalNoteObj.status = striptags(e.target.value);
                                                                                        journalNoteObj.status = e.target.value;
                                                                                        this.setState({ journalNoteObj }, () => this.generatePdf())
                                                                                    }}
                                                                                    onBlur={() => this.updateJournalNoteOnExit()}
                                                                                />
                                                                            </FormGroup>
                                                                            <FormGroup>
                                                                                <Label>{'Bedömning'.translate(this.props.lang)}</Label>
                                                                                <Input type='textarea'
                                                                                    // value={this.state.journalNoteObj.bedomning}
                                                                                    value={this.state.journalNoteObj.bedomning ? this.state.journalNoteObj.bedomning : ''}
                                                                                    style={{ height: 100 }} onChange={(e) => {
                                                                                        let journalNoteObj = this.state.journalNoteObj;
                                                                                        journalNoteObj.bedomning = e.target.value;
                                                                                        this.setState({ journalNoteObj }, () => this.generatePdf())
                                                                                    }}
                                                                                    onBlur={() => this.updateJournalNoteOnExit()}
                                                                                />
                                                                            </FormGroup>
                                                                            <FormGroup>
                                                                                <Label>{'Åtgärd'.translate(this.props.lang)}</Label>
                                                                                <Input type='textarea'
                                                                                    // value={this.state.journalNoteObj.atgard}
                                                                                    value={this.state.journalNoteObj.atgard ? this.state.journalNoteObj.atgard : ''}
                                                                                    style={{ height: 100 }} onChange={(e) => {
                                                                                        let journalNoteObj = this.state.journalNoteObj;
                                                                                        journalNoteObj.atgard = e.target.value;
                                                                                        this.setState({ journalNoteObj }, () => this.generatePdf())
                                                                                    }}
                                                                                    onBlur={() => this.updateJournalNoteOnExit()}
                                                                                />
                                                                            </FormGroup>

                                                                            <div className="journal-note-buttons">

                                                                                {
                                                                                    this.state.htmlJournal ?
                                                                                        <PdfSave lang={this.props.lang} html={this.state.htmlJournal} pdfName={this.generatePdfName()} />

                                                                                        : null
                                                                                }
                                                                                <Button color='primary' onClick={() => {
                                                                                    navigator.clipboard.writeText(this.state.textClipboard)
                                                                                }}>{'Copy text'.translate(this.props.lang)}</Button>
                                                                                <Button
                                                                                    color='primary'
                                                                                    onClick={() => this.updateJournalNoteOnExit(null, true)}
                                                                                    disabled={isExportToJournalSystemDisabled}
                                                                                >
                                                                                    {this.state.loadingJournalNoteExport ? /*'Processing...'.translate(this.props.lang)*/

                                                                                        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                                                                            <Player
                                                                                                onEvent={() => {
                                                                                                    if (!this.state.loadingJournalNoteExport) this.stopAnimation();
                                                                                                }}
                                                                                                onStateChange={this.toggleVisibility}
                                                                                                ref={this.player} // set the ref to your class instance
                                                                                                autoplay={true}
                                                                                                loop={true}
                                                                                                controls={true}
                                                                                                src="https://assets8.lottiefiles.com/packages/lf20_ZSRKCF.json"
                                                                                                style={{ height: "20px", width: "30px" }}
                                                                                            ></Player>
                                                                                            {'Processing...'.translate(this.props.lang)}
                                                                                        </div>
                                                                                        :
                                                                                        'Export to journal system'.translate(this.props.lang)}
                                                                                </Button>
                                                                            </div>


                                                                        </div>
                                                                        :
                                                                        <div className="journal-note-content">
                                                                            <FormGroup>
                                                                                <Label>{'Kontaktorsak'.translate(this.props.lang)}</Label>
                                                                                <Input type='text' value={kontaktorsak}
                                                                                    onChange={(e) => {
                                                                                        let journalNoteObj = this.state.journalNoteObj;
                                                                                        journalNoteObj.kontaktorsak = e.target.value;
                                                                                        this.setState({ journalNoteObj }, () => this.generatePdf())
                                                                                    }}
                                                                                    onBlur={() => this.updateJournalNoteOnExit()}
                                                                                />
                                                                            </FormGroup>

                                                                            <FormGroup>
                                                                                <Label>{'Anamnes'.translate(this.props.lang)}</Label>
                                                                                <Input type='textarea'
                                                                                    //  value={this.state.journalNoteObj.anamnes}
                                                                                    value={this.state.journalNoteObj.anamnes ? this.state.journalNoteObj.anamnes : ''}
                                                                                    style={{ height: 100 }} onChange={(e) => {
                                                                                        let journalNoteObj = this.state.journalNoteObj;
                                                                                        journalNoteObj.anamnes = e.target.value;
                                                                                        this.setState({ journalNoteObj }, () => this.generatePdf())
                                                                                    }}
                                                                                    onBlur={() => this.updateJournalNoteOnExit()}
                                                                                />
                                                                            </FormGroup>
                                                                            <FormGroup>
                                                                                <Label>{'Status'.translate(this.props.lang)}</Label>
                                                                                <Input type='textarea'
                                                                                    //  value={this.state.journalNoteObj.status} 
                                                                                    value={this.state.journalNoteObj.status ? this.state.journalNoteObj.status : ''}
                                                                                    style={{ height: 100 }} onChange={(e) => {
                                                                                        let journalNoteObj = this.state.journalNoteObj;
                                                                                        journalNoteObj.status = e.target.value;
                                                                                        this.setState({ journalNoteObj }, () => this.generatePdf())
                                                                                    }}
                                                                                    onBlur={() => this.updateJournalNoteOnExit()}
                                                                                />
                                                                            </FormGroup>
                                                                            <FormGroup>
                                                                                <Label>{'Bedömning'.translate(this.props.lang)}</Label>
                                                                                <Input type='textarea'
                                                                                    // value={this.state.journalNoteObj.bedomning}
                                                                                    value={this.state.journalNoteObj.bedomning ? this.state.journalNoteObj.bedomning : ''}
                                                                                    style={{ height: 100 }} onChange={(e) => {
                                                                                        let journalNoteObj = this.state.journalNoteObj;
                                                                                        journalNoteObj.bedomning = e.target.value;
                                                                                        this.setState({ journalNoteObj }, () => this.generatePdf())
                                                                                    }}
                                                                                    onBlur={() => this.updateJournalNoteOnExit()}
                                                                                />
                                                                            </FormGroup>
                                                                            <FormGroup>
                                                                                <Label>{'Åtgärd'.translate(this.props.lang)}</Label>
                                                                                <Input type='textarea'
                                                                                    // value={this.state.journalNoteObj.atgard}
                                                                                    value={this.state.journalNoteObj.atgard ? this.state.journalNoteObj.atgard : ''}
                                                                                    style={{ height: 100 }} onChange={(e) => {
                                                                                        let journalNoteObj = this.state.journalNoteObj;
                                                                                        journalNoteObj.atgard = e.target.value;
                                                                                        this.setState({ journalNoteObj }, () => this.generatePdf())
                                                                                    }}
                                                                                    onBlur={() => this.updateJournalNoteOnExit()}
                                                                                />
                                                                            </FormGroup>

                                                                            <div className="journal-note-buttons">

                                                                                {
                                                                                    this.state.htmlJournal ?
                                                                                        <PdfSave lang={this.props.lang} html={this.state.htmlJournal} pdfName={this.generatePdfName()} />

                                                                                        : null
                                                                                }
                                                                                <Button color='primary' onClick={() => {
                                                                                    navigator.clipboard.writeText(this.state.textClipboard)
                                                                                }}>{'Copy text'.translate(this.props.lang)}</Button>
                                                                                <Button
                                                                                    color='primary'
                                                                                    onClick={() => this.updateJournalNoteOnExit(null, true)}
                                                                                    disabled={isExportToJournalSystemDisabled}
                                                                                >
                                                                                    {this.state.loadingJournalNoteExport ? /*'Processing...'.translate(this.props.lang)*/

                                                                                        <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                                                                            <Player
                                                                                                onEvent={() => {
                                                                                                    if (!this.state.loadingJournalNoteExport) this.stopAnimation();
                                                                                                }}
                                                                                                onStateChange={this.toggleVisibility}
                                                                                                ref={this.player} // set the ref to your class instance
                                                                                                autoplay={true}
                                                                                                loop={true}
                                                                                                controls={true}
                                                                                                src="https://assets8.lottiefiles.com/packages/lf20_ZSRKCF.json"
                                                                                                style={{ height: "20px", width: "30px" }}
                                                                                            ></Player>
                                                                                            {'Processing...'.translate(this.props.lang)}
                                                                                        </div>
                                                                                        :
                                                                                        'Export to journal system'.translate(this.props.lang)}
                                                                                </Button>
                                                                            </div>


                                                                        </div>
                                                            }
                                                        </div>
                                                    </div>
                                                    {(this.state.conversation && !this.state.conversation?.closed) || (this.state.conversation && this.state.conversation?.closed && this.state.conversation?.softCloseTimestamp && this.state.conversation?.softCloseTimestamp > Math.floor(new Date().getTime() / 1000)) ?

                                                        <div
                                                            style={this.state.chatType == 'team' ? { backgroundImage: 'linear-gradient(to top, #3A3F56, #3A3F56, rgba(255, 255, 255, 0))', borderRadius: '0 0 10px 10px' } : null}
                                                            id={this.state.chatType == 'team' ? 'send-message-dark' : 'send-message-light'}
                                                            className={
                                                                this.props.uData && this.props.uData?.userLevel == 20 && localStorage?.getItem('profile') != 'patient' && this.state.conversation /*&& this.state.conversation?.referral*/ && !this.state.journalNote && !this.state.writeStatementModal && (this.state.conversation?.users?.filter?.(item => item.level < 20)?.length || this.state.conversation?.referral || this.state.conversation?.journalNote) && this.chatWrapRef && this.chatWrapRef.offsetWidth > 760 ?
                                                                    "send-message with-phrase with-journal"
                                                                    :
                                                                    this.props.uData && this.props.uData?.userLevel == 20 && localStorage?.getItem('profile') != 'patient' && this.state.conversation /*&& this.state.conversation?.referral*/ && (this.state.journalNote || this.state.writeStatementModal) ?
                                                                        "send-message with-phrase with-journal with-journal-open"
                                                                        :
                                                                        this.props.uData && this.props.uData?.userLevel == 20 && localStorage?.getItem('profile') != 'patient' && this.state.conversation /*&& this.state.conversation?.referral*/ && (this.chatWrapRef && this.chatWrapRef.offsetWidth <= 760) && (this.state.conversation?.users?.filter?.(item => item.level < 20)?.length || this.state.conversation?.referral) ?
                                                                            "send-message with-phrase with-journal chat-wrap-small"
                                                                            :
                                                                            this.props.uData && this.props.uData?.userLevel == 20 && localStorage?.getItem('profile') != 'patient' ?
                                                                                "send-message with-phrase" :
                                                                                "send-message"
                                                            }
                                                        >
                                                            <div className='send-message-container' ref={(node) => this.sendMessageConatiner = node}>
                                                                {/*
                                                            typeof window != 'undefined' && window.innerWidth > 768 && !this.state.journalNote && this.chatWrapRef && this.chatWrapRef.offsetWidth > 760 ?
                                                                <div className="chat-attach-menu-wrap">
                                                                    <button className={this.state._documentUploading && this.state.conversation?._id == this.state._documentUploading?._id ? 'attach uploading' : this.state.document ? 'attach uploaded' : "attach"} onClick={() => { if (this.documentInput) this.documentInput.click() }}>
                                                                        <Isvg src={attach} />
                                                                        <div className='chat-icons-tooltip'>
                                                                            {'Attach file'.translate(this.props.lang)}
                                                                        </div>
                                                                    </button>
                                                                    <button className={this.state._imageUploading && this.state.conversation?._id == this.state._imageUploading?._id ? 'image uploading' : this.state.image ? 'image uploaded' : "image"} onClick={() => { if (this.imageInput) this.imageInput.click() }}
                                                                    ><Isvg src={image} />
                                                                        <div className='chat-icons-tooltip'>
                                                                            {'Attach image'.translate(this.props.lang)}
                                                                        </div>
                                                                    </button>
                                                                    <button className={this.state._videoUploading && this.state.conversation?._id == this.state._videoUploading?._id ? 'video uploading' : this.state.video ? 'video uploaded' : "video"} onClick={() => { if (this.videoContentInput) this.videoContentInput.click() }}><Isvg src={videoIcon} />
                                                                        <div className='chat-icons-tooltip'>
                                                                            {'Attach video'.translate(this.props.lang)}
                                                                        </div>
                                                                    </button>


                                                                    {
                                                                        this.props.uData && this.props.uData?.userLevel == 20 && localStorage?.getItem('profile') != 'patient' ?
                                                                            <button className={"phrase"} onClick={() => { this.setState({ quickPhraseModal: true }, this.get) }}><Isvg src={phraseIcon} />
                                                                                <div className='chat-icons-tooltip'>
                                                                                    {'Quick phrase'.translate(this.props.lang)}
                                                                                </div>
                                                                            </button>
                                                                            :
                                                                            null
                                                                    }


                                                                    {
                                                                        this.props.uData && this.props.uData?.userLevel == 20 && localStorage?.getItem('profile') != 'patient' ?
                                                                            <button className={"health-care-forms"} onClick={() => { this.setState({ healthCareModal: true }) }}><Isvg src={formIcon} />
                                                                                <div className='chat-icons-tooltip'>
                                                                                    {'Attach form'.translate(this.props.lang)}
                                                                                </div>
                                                                            </button>
                                                                            :
                                                                            null
                                                                    }
                                                                    {
                                                                        this.props.uData && this.props.uData?.userLevel == 20 && (this.state.conversation && (this.state.conversation?.users?.filter(item => item.level < 20).length || this.state.conversation?.referral || this.state.conversation?.journalNote)) && localStorage?.getItem('profile') != 'patient' ?
                                                                            <button className={"journal"} onClick={() => this.findHealthCareFormular()}><Isvg src={journalIcon} />
                                                                                <div className='chat-icons-tooltip'>
                                                                                    {'Journal note'.translate(this.props.lang)}
                                                                                </div>
                                                                            </button>
                                                                            :
                                                                            null
                                                                    }
                                                                </div>
                                                                :
                                                                <div className="chat-attach-menu-wrap">

                                                                    <button className='more-icon' onClick={() => { this.setState({ mobileBtnsShow: !this.state.mobileBtnsShow }) }}><Isvg src={moreIcon} style={{ position: 'absolute', width: 13, height: 13 }} /></button>
                                                                    <div className={this.state.mobileBtnsShow ? 'mobile-btns-upload-phrase show-mobile-btns-upload-phrase' : 'mobile-btns-upload-phrase'}>
                                                                        <div className="overlay-test" onClick={() => this.setState({ mobileBtnsShow: !this.state.mobileBtnsShow })} />
                                                                        <button className={this.state._documentUploading ? 'attach-menu uploading' : this.state.document ? 'attach-menu uploaded' : "attach-menu"} onClick={() => {
                                                                            this.setState({ mobileBtnsShow: !this.state.mobileBtnsShow })
                                                                            if (this.documentInput) this.documentInput.click()
                                                                        }}>
                                                                            <div className="button-menu-icon-wrap">
                                                                                <Isvg src={attach} style={{ position: 'absolute', width: 17, height: 17 }} />
                                                                            </div>
                                                                            <div className='chat-icons-text'>
                                                                                {'Attach file'.translate(this.props.lang)}
                                                                            </div>
                                                                        </button>


                                                                        <button className={this.state._imageUploading ? 'image-menu uploading' : this.state.image ? 'image-menu uploaded' : "image-menu"} onClick={() => {
                                                                            this.setState({ mobileBtnsShow: !this.state.mobileBtnsShow })
                                                                            if (this.imageInput) this.imageInput.click()
                                                                        }}>
                                                                            <div className="button-menu-icon-wrap">
                                                                                <Isvg src={image} style={{ position: 'absolute', width: 17, height: 17 }} />
                                                                            </div>
                                                                            <div className='chat-icons-text'>
                                                                                {'Attach image'.translate(this.props.lang)}
                                                                            </div>
                                                                        </button>
                                                                        <button className={this.state._videoUploading ? 'video-menu uploading' : this.state.video ? 'video-menu uploaded' : "video-menu"} onClick={() => {
                                                                            this.setState({ mobileBtnsShow: !this.state.mobileBtnsShow })
                                                                            if (this.videoContentInput) this.videoContentInput.click()
                                                                        }}>
                                                                            <div className="button-menu-icon-wrap">
                                                                                <Isvg src={videoIcon} style={{ position: 'absolute', width: 17, height: 17 }} />
                                                                            </div>
                                                                            <div className='chat-icons-text'>
                                                                                {'Attach video'.translate(this.props.lang)}
                                                                            </div>
                                                                        </button>

                                                                        {
                                                                            this.props.uData && this.props.uData?.userLevel == 20 && localStorage?.getItem('profile') != 'patient' ?
                                                                                <button className={"phrase-menu"} onClick={() => { this.setState({ quickPhraseModal: true, mobileBtnsShow: !this.state.mobileBtnsShow }, this.get) }}>
                                                                                    <div className="button-menu-icon-wrap"><Isvg src={phraseIcon} style={{ position: 'absolute', width: 17, height: 17 }} /></div>

                                                                                    <div className='chat-icons-text'>
                                                                                        {'Quick phrase'.translate(this.props.lang)}
                                                                                    </div>
                                                                                </button>
                                                                                :
                                                                                null
                                                                        }
                                                                        {
                                                                            this.props.uData && this.props.uData?.userLevel == 20 && localStorage?.getItem('profile') != 'patient' ?
                                                                                <button className={"health-care-forms-menu"} onClick={() => { this.setState({ healthCareModal: true, mobileBtnsShow: !this.state.mobileBtnsShow }) }}>
                                                                                    <div className="button-menu-icon-wrap"><Isvg src={formIcon} style={{ position: 'absolute', width: 17, height: 17 }} /></div>

                                                                                    <div className='chat-icons-text'>
                                                                                        {'Attach form'.translate(this.props.lang)}
                                                                                    </div>
                                                                                </button>
                                                                                :
                                                                                null
                                                                        }
                                                                        {
                                                                            this.props.uData && this.props.uData?.userLevel == 20 && this.state.conversation && (this.state.conversation?.users?.filter(item => item.level < 20).length || this.state.conversation?.referral || this.state.conversation?.journalNote) && localStorage?.getItem('profile') != 'patient' ?
                                                                                <button className={"journal-menu"} onClick={() => {
                                                                                    this.setState({ mobileBtnsShow: !this.state.mobileBtnsShow })
                                                                                    this.findHealthCareFormular()
                                                                                }}>
                                                                                    <div className="button-menu-icon-wrap">
                                                                                        <Isvg src={journalIcon} style={{ position: 'absolute', width: 17, height: 17 }} />
                                                                                    </div>
                                                                                    <div className='chat-icons-text'>
                                                                                        {'Journal note'.translate(this.props.lang)}
                                                                                    </div>
                                                                                </button>
                                                                                :
                                                                                null
                                                                        }
                                                                    </div>
                                                                </div>
                                                        */}




                                                                <Input className='send-message-textarea'
                                                                    onKeyDown={(e) => {
                                                                        if (e.keyCode == 13 && !e.shiftKey) {
                                                                            e.preventDefault();
                                                                            this.sendMessage();
                                                                            return false;
                                                                        }
                                                                        this.emitUserTyping()
                                                                    }}
                                                                    // onKeyDown={this.emitUserTyping}
                                                                    placeholder={"Write a message...".translate(this.props.lang)}
                                                                    type="textarea"
                                                                    id={this.state.conversation._id}
                                                                    onChange={(e) => {
                                                                        let element = e.target;
                                                                        this.setState({
                                                                            // messageHeight: 64,
                                                                            message: e.target.value
                                                                        }, () => {
                                                                            const messageBox = document.getElementById(this.state.conversation._id);

                                                                            if (messageBox && messageBox.style.height && Number(messageBox.style.height.replace('px', '')) > 64 && element.scrollHeight <= Number(messageBox.style.height.replace('px', ''))) {
                                                                                this.setState({
                                                                                    messageHeight: Number(messageBox.style.height.replace('px', ''))
                                                                                })
                                                                            } else {
                                                                                this.setState({ messageHeight: element.scrollHeight < this.messagesRef.clientHeight / 2 ? element.scrollHeight : this.messagesRef.clientHeight / 2 })

                                                                            }
                                                                        })
                                                                    }}
                                                                    onFocus={() => {
                                                                        if (!this.state.activeWriteMessageField) {
                                                                            this.setState({ activeWriteMessageField: true })
                                                                        }
                                                                    }}
                                                                    // onBlur={() => {
                                                                    //     if (this.state.activeWriteMessageField) {
                                                                    //         this.setState({ activeWriteMessageField: false })
                                                                    //     }
                                                                    // }}
                                                                    value={this.state.message}
                                                                    style={{ maxHeight: this.messagesRef && this.messagesRef.clientHeight, minHeight: 64, height: this.state.messageHeight && this.state.message && this.state.activeWriteMessageField ? this.state.messageHeight : 64 }}
                                                                />
                                                                {this.state.conversation && this.supportedLanguages && this.supportedLanguages.length && ((this.props.uData?.clinicGroups?.[selectedGroupIdx] && this.props.uData.clinicGroups[selectedGroupIdx].haveTranslation) || (this.props.uData?.patientGroups?.[selectedGroupIdxPatient] && this.props.uData.patientGroups[selectedGroupIdxPatient].haveTranslation)) ?
                                                                    <UncontrolledDropdown className={this.state.conversation && this.state.conversation?.memberLanguages && this.state.conversation?.memberLanguages[this.props.uData?._id] ? "translate translate-active" : "translate"}>
                                                                        <DropdownToggle>
                                                                            <Isvg src={translateIcon} />
                                                                            <div className='chat-icons-tooltip google-translate-tooltip'>
                                                                                {'Translation is done by Google and may not work properly!'.translate(this.props.lang)}
                                                                            </div>
                                                                        </DropdownToggle>
                                                                        <DropdownMenu>
                                                                            <DropdownItem className={this.state.conversation && this.state.conversation?.memberLanguages && !this.state.conversation?.memberLanguages[this.props.uData?._id] ? 'active' : ''} onClick={() => this.setTranslationLanguage(null)} >{'No translate'.translate(this.props.lang)}</DropdownItem>

                                                                            {


                                                                                this.supportedLanguages.map((item, idx) => {
                                                                                    return (

                                                                                        <DropdownItem className={this.state.conversation && this.state.conversation?.memberLanguages && this.state.conversation?.memberLanguages[this.props.uData?._id] == item.code ? 'active' : ''} onClick={() => this.setTranslationLanguage(item.code)} key={idx}>{item.name}</DropdownItem>

                                                                                    )
                                                                                })

                                                                            }
                                                                        </DropdownMenu>
                                                                    </UncontrolledDropdown>
                                                                    :
                                                                    null
                                                                }
                                                                <div className={params.doNotOpenPatientProfile ? 'main-mini-chat-resize-icon' : 'main-chat-resize-icon'} onMouseDown={() => {
                                                                    this.setState({
                                                                        messageRisaze: true
                                                                    })
                                                                }} onMouseUp={() => {
                                                                    this.setState({
                                                                        messageRisaze: false
                                                                    })
                                                                }}>
                                                                    <Isvg src={resizeMessageBox} />
                                                                </div>
                                                                <button disabled={this.state._imageUploading || this.state.sendMessageCheckObj} className="send send-z-index" onClick={this.sendMessage}>
                                                                    <Isvg src={send} />
                                                                    <div className='chat-icons-tooltip'>
                                                                        {'Send'.translate(this.props.lang)}
                                                                    </div>
                                                                </button>


                                                            </div>

                                                            {typeof window != 'undefined' && window.innerWidth > 768 && !this.state.journalNote && !this.state.writeStatementModal && this.chatWrapRef && this.chatWrapRef.offsetWidth > 760 ?
                                                                <div className="chat-attach-menu-wrap">
                                                                    <button className={this.state._documentUploading && this.state.conversation?._id == this.state._documentUploading?._id ? 'attach uploading' : this.state.document ? 'attach uploaded' : "attach"} onClick={() => { if (this.documentInput) this.documentInput.click() }}>
                                                                        <Isvg src={attach} />
                                                                        <div className='chat-icons-tooltip'>
                                                                            {'Attach file'.translate(this.props.lang)}
                                                                        </div>
                                                                    </button>
                                                                    <button className={this.state._imageUploading && this.state.conversation?._id == this.state._imageUploading?._id ? 'image uploading' : this.state.image ? 'image uploaded' : "image"} onClick={() => { if (this.imageInput) this.imageInput.click() }}
                                                                    ><Isvg src={image} />
                                                                        <div className='chat-icons-tooltip'>
                                                                            {'Attach image'.translate(this.props.lang)}
                                                                        </div>
                                                                    </button>
                                                                    <button className={this.state._videoUploading && this.state.conversation?._id == this.state._videoUploading?._id ? 'video uploading' : this.state.video ? 'video uploaded' : "video"} onClick={() => { if (this.videoContentInput) this.videoContentInput.click() }}><Isvg src={videoIcon} />
                                                                        <div className='chat-icons-tooltip'>
                                                                            {'Attach video'.translate(this.props.lang)}
                                                                        </div>
                                                                    </button>


                                                                    {
                                                                        this.props.uData && this.props.uData?.userLevel == 20 && localStorage?.getItem('profile') != 'patient' ?
                                                                            <button className={"phrase"} onClick={() => { this.setState({ quickPhraseModal: true }, this.get) }}><Isvg src={phraseIcon} />
                                                                                <div className='chat-icons-tooltip'>
                                                                                    {'Quick phrase'.translate(this.props.lang)}
                                                                                </div>
                                                                            </button>
                                                                            :
                                                                            null
                                                                    }


                                                                    {
                                                                        this.props.uData && this.props.uData?.userLevel == 20 && localStorage?.getItem('profile') != 'patient' ?
                                                                            <button className={"health-care-forms"} onClick={() => { this.setState({ healthCareModal: true }) }}><Isvg src={formIcon} />
                                                                                <div className='chat-icons-tooltip'>
                                                                                    {'Attach form'.translate(this.props.lang)}
                                                                                </div>
                                                                            </button>
                                                                            :
                                                                            null
                                                                    }
                                                                    {
                                                                        this.props.uData && this.props.uData?.userLevel == 20 && (this.state.conversation && (this.state.conversation?.users?.filter?.(item => item.level < 20)?.length || this.state.conversation?.referral || this.state.conversation?.journalNote)) && localStorage?.getItem('profile') != 'patient' ?
                                                                            <button className={"journal"} style={!showJournalNoteButton ? { opacity: 0.8 } : {}} onClick={() => {
                                                                                this.setState({ writeStatementModal: false })
                                                                                if (showJournalNoteButton)
                                                                                    this.findHealthCareFormular()
                                                                            }}><Isvg src={journalIcon} />

                                                                                {
                                                                                    showJournalNoteButton ?
                                                                                        <div className='chat-icons-tooltip'>
                                                                                            {'Journal note'.translate(this.props.lang)}
                                                                                        </div>
                                                                                        :
                                                                                        <div className='chat-icons-tooltip tooltip-position'>
                                                                                            {'The journal note is not available because the patient did not fill out the form'.translate(this.props.lang)}
                                                                                        </div>
                                                                                }
                                                                            </button>
                                                                            :
                                                                            null
                                                                    }
                                                                    {
                                                                        this.props.uData && this.props.uData?.userLevel >= 20 && (typeof window !== 'undefined' && localStorage?.getItem('profile') !== 'patient') && this.state.conversation && ((this.state.conversation?.users && this.state.conversation?.users?.filter(user => user.level < 20).length > 0) || this.state.conversation?.referral) && enterprise
                                                                            ?
                                                                            <button className='open-diary' onClick={() => this.handleDiaryClick(this.state.conversation)}>
                                                                                <Isvg src={diaryIcon} />
                                                                                <div className='chat-icons-tooltip'>
                                                                                    {'Open diary'.translate(this.props.lang)}
                                                                                </div>
                                                                            </button>
                                                                            :
                                                                            null
                                                                    }
                                                                    {
                                                                        writeStatementEnabled ?
                                                                            <button className={(this.props.uData && this.props.uData?.userLevel == 20 && (this.state.conversation && (this.state.conversation?.users?.filter?.(item => item.level < 20)?.length || this.state.conversation?.referral || this.state.conversation?.journalNote)) && localStorage?.getItem('profile') != 'patient') ? (this.props.uData && this.props.uData?.userLevel >= 20 && (typeof window !== 'undefined' && localStorage?.getItem('profile') !== 'patient') && this.state.conversation && ((this.state.conversation?.users && this.state.conversation?.users?.filter(user => user.level < 20).length > 0) || this.state.conversation?.referral) && enterprise) ? 'open-diary write-statement-chat-btn' : 'open-diary' : 'open-diary open-diary-without-journal'} onClick={() => {
                                                                                if (this.state.writeStatementModal) {
                                                                                    this.setState({ writeStatementModal: false })
                                                                                } else {
                                                                                    this.setState({ writeStatementModal: true, journalNote: false }, () => {
                                                                                        this.removeFromJorunalNotes();
                                                                                    })
                                                                                }

                                                                            }}>
                                                                                <Isvg src={writeStatementIcon} />
                                                                                <div className='chat-icons-tooltip'>
                                                                                    {'Write statement'.translate(this.props.lang)}
                                                                                </div>
                                                                            </button>
                                                                            :
                                                                            null
                                                                    }
                                                                </div>
                                                                :
                                                                <div className="chat-attach-menu-wrap">

                                                                    <button className='more-icon' onClick={() => { this.setState({ dropdownMore: false, mobileBtnsShow: !this.state.mobileBtnsShow }) }}><Isvg src={moreIcon} style={{ position: 'absolute', width: 13, height: 13 }} /></button>
                                                                    <div className={this.state.mobileBtnsShow ? 'mobile-btns-upload-phrase show-mobile-btns-upload-phrase mobile-menu-chat' : 'mobile-btns-upload-phrase mobile-menu-chat'}>
                                                                        <div className="overlay-test" onClick={() => this.setState({ mobileBtnsShow: !this.state.mobileBtnsShow })} />
                                                                        <button className={this.state._documentUploading ? 'attach-menu uploading' : this.state.document ? 'attach-menu uploaded' : "attach-menu"} onClick={() => {
                                                                            this.setState({ mobileBtnsShow: !this.state.mobileBtnsShow })
                                                                            if (this.documentInput) this.documentInput.click()
                                                                        }}>
                                                                            <div className="button-menu-icon-wrap">
                                                                                <Isvg src={attach} style={{ position: 'absolute', width: 17, height: 17 }} />
                                                                            </div>
                                                                            <div className='chat-icons-text'>
                                                                                {'Attach file'.translate(this.props.lang)}
                                                                            </div>
                                                                        </button>


                                                                        <button className={this.state._imageUploading ? 'image-menu uploading' : this.state.image ? 'image-menu uploaded' : "image-menu"} onClick={() => {
                                                                            this.setState({ mobileBtnsShow: !this.state.mobileBtnsShow })
                                                                            if (this.imageInput) this.imageInput.click()
                                                                        }}>
                                                                            <div className="button-menu-icon-wrap">
                                                                                <Isvg src={image} style={{ position: 'absolute', width: 17, height: 17 }} />
                                                                            </div>
                                                                            <div className='chat-icons-text'>
                                                                                {'Attach image'.translate(this.props.lang)}
                                                                            </div>
                                                                        </button>
                                                                        <button className={this.state._videoUploading ? 'video-menu uploading' : this.state.video ? 'video-menu uploaded' : "video-menu"} onClick={() => {
                                                                            this.setState({ mobileBtnsShow: !this.state.mobileBtnsShow })
                                                                            if (this.videoContentInput) this.videoContentInput.click()
                                                                        }}>
                                                                            <div className="button-menu-icon-wrap">
                                                                                <Isvg src={videoIcon} style={{ position: 'absolute', width: 17, height: 17 }} />
                                                                            </div>
                                                                            <div className='chat-icons-text'>
                                                                                {'Attach video'.translate(this.props.lang)}
                                                                            </div>
                                                                        </button>

                                                                        {
                                                                            this.props.uData && this.props.uData?.userLevel == 20 && localStorage?.getItem('profile') != 'patient' ?
                                                                                <button className={"phrase-menu"} onClick={() => { this.setState({ quickPhraseModal: true, mobileBtnsShow: !this.state.mobileBtnsShow }, this.get) }}>
                                                                                    <div className="button-menu-icon-wrap"><Isvg src={phraseIcon} style={{ position: 'absolute', width: 17, height: 17 }} /></div>

                                                                                    <div className='chat-icons-text'>
                                                                                        {'Quick phrase'.translate(this.props.lang)}
                                                                                    </div>
                                                                                </button>
                                                                                :
                                                                                null
                                                                        }
                                                                        {
                                                                            this.props.uData && this.props.uData?.userLevel == 20 && localStorage?.getItem('profile') != 'patient' ?
                                                                                <button className={"health-care-forms-menu"} onClick={() => { this.setState({ healthCareModal: true, mobileBtnsShow: !this.state.mobileBtnsShow }) }}>
                                                                                    <div className="button-menu-icon-wrap"><Isvg src={formIcon} style={{ position: 'absolute', width: 17, height: 17 }} /></div>

                                                                                    <div className='chat-icons-text'>
                                                                                        {'Attach form'.translate(this.props.lang)}
                                                                                    </div>
                                                                                </button>
                                                                                :
                                                                                null
                                                                        }
                                                                        {
                                                                            this.props.uData && this.props.uData?.userLevel == 20 && this.state.conversation && (this.state.conversation?.users?.filter?.(item => item.level < 20)?.length || this.state.conversation?.referral || this.state.conversation?.journalNote) && localStorage?.getItem('profile') != 'patient' ?
                                                                                <button className={"journal-menu"} style={!showJournalNoteButton ? { opacity: 0.8 } : {}} onClick={() => {
                                                                                    if (showJournalNoteButton) {
                                                                                        this.setState({ mobileBtnsShow: !this.state.mobileBtnsShow })
                                                                                        this.findHealthCareFormular()
                                                                                    }

                                                                                }}>
                                                                                    <div className="button-menu-icon-wrap">
                                                                                        <Isvg src={journalIcon} style={{ position: 'absolute', width: 17, height: 17 }} />
                                                                                    </div>
                                                                                    {
                                                                                        showJournalNoteButton ?
                                                                                            <div className='chat-icons-text'>
                                                                                                {'Journal note'.translate(this.props.lang)}
                                                                                            </div>
                                                                                            :
                                                                                            <div className='chat-icons-text'>
                                                                                                {'The journal note is not available'.translate(this.props.lang)}
                                                                                            </div>
                                                                                    }

                                                                                </button>
                                                                                :
                                                                                null
                                                                        }
                                                                        {
                                                                            this.props.uData && this.props.uData?.userLevel >= 20 && (typeof window !== 'undefined' && localStorage?.getItem('profile') !== 'patient') && this.state.conversation && ((this.state.conversation?.users && this.state.conversation?.users?.filter(user => user.level < 20).length > 0) || this.state.conversation?.referral) && enterprise
                                                                                ?
                                                                                <button className='open-diary-menu' onClick={() => this.handleDiaryClick(this.state.conversation)}>
                                                                                    <div className="button-menu-icon-wrap"><Isvg src={diaryIcon} style={{ position: 'absolute', width: 17, height: 17 }} /></div>

                                                                                    <div className='chat-icons-text'>
                                                                                        {'Open diary'.translate(this.props.lang)}
                                                                                    </div>
                                                                                </button>
                                                                                :
                                                                                null
                                                                        }
                                                                        {
                                                                            writeStatementEnabled ?
                                                                                <button className='write-statement-menu' onClick={() => {
                                                                                    if (this.state.writeStatementModal) {
                                                                                        this.setState({ writeStatementModal: false, mobileBtnsShow: !this.state.mobileBtnsShow })
                                                                                    } else {
                                                                                        this.setState({ writeStatementModal: true, journalNote: false, mobileBtnsShow: !this.state.mobileBtnsShow }, () => {
                                                                                            this.removeFromJorunalNotes();
                                                                                        })
                                                                                    }

                                                                                }}>
                                                                                    <div className="button-menu-icon-wrap"><Isvg src={writeStatementIcon} style={{ position: 'absolute', width: 17, height: 17 }} /></div>

                                                                                    <div className='chat-icons-text'>
                                                                                        {'Write statement'.translate(this.props.lang)}
                                                                                    </div>
                                                                                </button>


                                                                                :
                                                                                null
                                                                        }
                                                                    </div>
                                                                </div>}

                                                        </div>
                                                        :
                                                        null
                                                    }
                                                    {/*
                                                {(!params.patientSearchModule && this.state.conversation && this.state.conversation?.closed && this.props.uData && this.props.uData?.userLevel >= 20 && localStorage?.getItem('profile') == 'doctor' && this.state.conversation && this.state.conversation?.members && this.state.conversation?.members[0] == this.props.uData?._id && !this.state.conversation?.softCloseTimestamp) ||
                                                    (!params.patientSearchModule && this.state.conversation && this.state.conversation?.closed && this.props.uData && this.props.uData?.userLevel >= 20 && localStorage?.getItem('profile') == 'doctor' && this.state.conversation && this.state.conversation?.members && this.state.conversation?.members[0] == this.props.uData?._id && this.state.conversation?.softCloseTimestamp && this.state.conversation?.softCloseTimestamp < Math.floor(new Date().getTime() / 1000)) ?
                                                    <div className='delete-conversation-wrap'>
                                                        <Button color='danger' onClick={() => this.setState({ deleteConversationModal: this.state.conversation })}>{'Delete conversation'.translate(this.props.lang)}</Button>
                                                    </div>
                                                    :
                                                    null
                                                }
                                                */}
                                                </div></>}
                                        </div>

                                        :

                                        null

                                }
                            </div>
                        </Col>
                    </Row>

                </Container>

                {this.state._syncPatientDocumentsModal ? <SyncPatientDocumentsModal {...this.state._syncPatientDocumentsModal} /> : null}


                {
                    this.state.succesUpload ?
                        <InfoModal
                            isOpen={this.state.succesUpload}

                            toggle={() => this.setState({ succesUpload: null })}
                            header={'Info'.translate(this.props.lang)}
                            info={this.state.succesUpload == 1 ? 'Document is successfully uploaded'.translate(this.props.lang) : this.state.succesUpload == 2 ? 'Image is successfully uploaded'.translate(this.props.lang) : 'Video is successfully uploaded'.translate(this.props.lang)}
                            buttons={[
                                <Button color='primary' onClick={() => {
                                    this.setState({
                                        succesUpload: null
                                    })

                                }}>{'Ok'.translate(this.props.lang)}</Button>
                            ]}
                        />

                        :

                        null
                }

                <DeleteModal
                    lang={this.props.lang}
                    isOpen={this.state.deleteMessageModal}
                    toggle={() => this.setState({ deleteMessageModal: null })}
                    handler={() => { this.deleteMessage(this.state.deleteMessageModal); this.setState({ deleteMessageModal: null }) }}
                >
                    {'Are you sure you want to delete the selected message?'.translate(this.props.lang)}
                </DeleteModal>
                <DeleteModal
                    lang={this.props.lang}
                    isOpen={this.state.deleteConversationModal}
                    toggle={() => this.setState({ deleteConversationModal: null })}
                    handler={() => { this.deleteConversation(this.state.deleteConversationModal); this.setState({ deleteConversationModal: null }) }}
                >
                    {'Are you sure you want to delete this conversation?'.translate(this.props.lang)}
                </DeleteModal>


                {/* <DeleteModal
                    lang={this.props.lang}
                    isOpen={this.state.closeCaseModal}
                    toggle={() => this.setState({ closeCaseModal: null })}
                    handler={() => { this.closeCase(this.state.closeCaseModal); this.setState({ closeCaseModal: null }) }}
                >
                    {'Do you want to close the case and conversation'.translate(this.props.lang)} <strong>{this.state.closeCaseModal && this.state.closeCaseModal.name}</strong>?
                </DeleteModal> */}
                {
                    this.state.closeCaseModal ?
                        <Modal isOpen={this.state.closeCaseModal} centered >
                            {/* <ModalHeader style={{ margin: 'auto' }}>{'Warning'.translate(this.props.lang)}</ModalHeader> */}
                            <ModalBody className="delete-modal">
                                {/* <img src={warrning} /> */}
                                <div className='message'>
                                    {'Do you want to close the case and conversation'.translate(this.props.lang)} <strong>{this.state.closeCaseModal && this.state.closeCaseModal.name}</strong>?

                                </div>
                            </ModalBody>
                            <ModalFooter style={{ position: 'relative', marginTop: '20px' }} className="buttons-right-blue">
                                <Button style={{ minWidth: 100 }} type="submit" className="button-blue soft-close-button" onClick={() => { this.closeCase(this.state.closeCaseModal, true); this.setState({ closeCaseModal: null }) }}>
                                    {/* <Isvg  src={info} /> */}
                                    {this.state.numberOfPatients > 0 ? <div className='button-hover-tooltip soft-close'>{'The patient can send messages for another 3 days in this chat before it is closed.'.translate(this.props.lang)}</div> : null}
                                    {'Soft close'.translate(this.props.lang)}
                                </Button>{' '}
                                <Button style={{ minWidth: 100 }} type="submit" className="button-blue hard-close-button" onClick={() => { this.closeCase(this.state.closeCaseModal); this.setState({ closeCaseModal: null }) }}>
                                    {this.state.numberOfPatients > 0 ? <div className='button-hover-tooltip hard-close'>{'The patient will not be able to send any more messages. The chat can be reopened.'.translate(this.props.lang)}</div> : null}
                                    {'Hard close'.translate(this.props.lang)}
                                </Button>{' '}
                                <Button style={{ minWidth: 100 }} type="submit" className="button-blue" onClick={() => this.setState({ closeCaseModal: null })} >{'Cancel'.translate(this.props.lang)}</Button>{' '}
                            </ModalFooter>
                        </Modal>
                        :
                        null
                }
                <DeleteModal
                    lang={this.props.lang}
                    isOpen={this.state.leaveChatModal}
                    toggle={() => this.setState({ leaveChatModal: null })}
                    handler={() => { this.leaveChat(this.state.leaveChatModal); this.setState({ leaveChatModal: null }) }}
                >
                    {'Do you really want to leave this conversation'.translate(this.props.lang)} <strong>{this.state.leaveChatModal && this.state.leaveChatModal.name}</strong>?
                </DeleteModal>

                {
                    this.state.closeModal ?
                        <Modal isOpen={this.state.closeModal} centered >
                            {/* <ModalHeader style={{ margin: 'auto' }}>{'Warning'.translate(this.props.lang)}</ModalHeader> */}
                            <ModalBody className="delete-modal">
                                {/* <img src={warrning} /> */}
                                <div className='message'>
                                    {'Close conversation'.translate(this.props.lang)} <strong>{
                                        this.state.closeModal ?
                                            this.state.closeModal.name && this.state.closeModal.name.replace('Direct', 'Direct'.translate(this.props.lang))
                                            :
                                            null
                                    }</strong>?
                                </div>
                            </ModalBody>
                            <ModalFooter style={{ position: 'relative', marginTop: '20px' }} className="buttons-right-blue">
                                <Button style={{ minWidth: 100 }} type="submit" className="button-blue soft-close-button" disabled={this.state.conversation?.referral ? false : true} onClick={() => { this.close(this.state.closeModal?._id, true); this.setState({ closeModal: null }) }} >
                                    {this.state.numberOfPatients > 0 ? <div className='button-hover-tooltip soft-close'>{'The patient can send messages for another 3 days in this chat before it is closed.'.translate(this.props.lang)}</div> : null}
                                    {'Soft close'.translate(this.props.lang)}
                                </Button>{' '}

                                <Button style={{ minWidth: 100 }} type="submit" className="button-blue hard-close-button" onClick={() => { this.close(this.state.closeModal?._id); this.setState({ closeModal: null }) }}>
                                    {this.state.numberOfPatients > 0 ? <div className='button-hover-tooltip hard-close'>{'The patient will not be able to send any more messages. The chat can be reopened.'.translate(this.props.lang)}</div> : null}
                                    {'Hard close'.translate(this.props.lang)}
                                </Button>{' '}
                                <Button style={{ minWidth: 100 }} type="submit" className="button-blue" onClick={() => this.setState({ closeModal: null })} >{'Cancel'.translate(this.props.lang)}</Button>{' '}
                            </ModalFooter>
                        </Modal>
                        :
                        null
                }

                <DeleteModal
                    lang={this.props.lang}
                    isOpen={this.state.openModal}
                    toggle={() => this.setState({ openModal: null })}
                    handler={() => {
                        if (this.state.openModal && this.state.openModal.referral && this.state.openModal.referral.dropIn) {
                            this.openCase(this.state.openModal);
                        } else {
                            this.open(this.state.openModal?._id);
                        }

                        this.setState({ openModal: null, conversationsType: 0, conversationPage: 0 })
                    }}
                >
                    {'Open conversation'.translate(this.props.lang)} <strong>{this.state.openModal && this.state.openModal.name}</strong>?
                </DeleteModal>

                {
                    this.state.error ?

                        <ErrorModal lang={this.props.lang}
                            isOpen={this.state.error}
                            toggle={() => this.setState({ error: null })}

                        >
                            {this.state.error.translate(this.props.lang)}
                        </ErrorModal>

                        :
                        null
                }
                {
                    this.state.exportToJournalSystemError ?

                        <ErrorModal lang={this.props.lang}
                            isOpen={this.state.exportToJournalSystemError}
                            toggle={() => this.setState({ exportToJournalSystemError: null })}

                        >
                            {this.state.exportToJournalSystemError.translate(this.props.lang)}
                        </ErrorModal>

                        :
                        null
                }
                {
                    this.state.exportToJournalSystemSuccessfully ?
                        <InfoModal
                            isOpen={this.state.exportToJournalSystemSuccessfully}

                            toggle={() => this.setState({ exportToJournalSystemSuccessfully: null })}
                            header={'Info'.translate(this.props.lang)}
                            info={this.state.exportToJournalSystemSuccessfully.translate(this.props.lang)}
                            buttons={[
                                <Button color='primary' onClick={() => {
                                    this.setState({
                                        exportToJournalSystemSuccessfully: null
                                    })

                                }}>{'Ok'.translate(this.props.lang)}</Button>
                            ]}
                        />

                        :

                        null
                }

                {
                    this.state.invitedContactsSucces ?
                        <InfoModal
                            isOpen={this.state.invitedContactsSucces}

                            toggle={() => this.setState({ invitedContactsSucces: null, successMessage: null })}
                            header={'Info'.translate(this.props.lang)}
                            // info={'Invitations sent successfully'.translate(this.props.lang) + ': ' + this.state.successMessage}
                            info={
                                <>
                                    <span style={{ fontWeight: 600 }}>{'Invitations sent successfully'.translate(this.props.lang)}</span>
                                    <div className='table-error-dropin'>
                                        {
                                            this.state.invitedContactsSucces && this.state.invitedContactsSucces.map((item, idx) => {
                                                return (
                                                    <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                                        {item.contact}
                                                    </div>
                                                )
                                            })


                                        }
                                    </div>
                                </>

                            }
                            buttons={[
                                <Button color='primary' onClick={() => {
                                    this.setState({
                                        invitedContactsSucces: null,
                                        successMessage: null
                                    })

                                }}>{'Close'.translate(this.props.lang)}</Button>
                            ]}
                        />
                        :
                        null

                }

                <Modal isOpen={this.state.sentInvintations} size='xl' centered>
                    <ModalHeader>
                        {'Sent invitations list'.translate(this.props.lang)}
                    </ModalHeader>
                    <ModalBody>
                        <div className='sent-invitations-table-wrap'>
                            <table className="sent-invitations-table">
                                <tr>
                                    <th>{'Contact'.translate(this.props.lang)}</th>
                                    <th>{'Type'.translate(this.props.lang)}</th>
                                    <th>{'Sent'.translate(this.props.lang)}</th>
                                    <th>{'Reserved'.translate(this.props.lang)}</th>
                                    <th>{'Actions'.translate(this.props.lang)}</th>
                                </tr>
                                {
                                    this.state.sentInvintations && this.state.sentInvintations.map((item, idx) => {
                                        return (
                                            <tr>
                                                <td>{item.contact}</td>
                                                <td className="uppercase-text">{item.type}</td>
                                                <td>{this.props.getStringDateTs(item.timestamp, this.props.dateTimeFormat)/*moment.unix(item.timestamp).format(this.props.dateTimeFormat)*/}</td>
                                                <td>{this.props.getStringDateTs(item.scheduleTimestamp, this.props.dateTimeFormat)/*moment.unix(item.scheduleTimestamp).format(this.props.dateTimeFormat)*/}</td>
                                                <td><Button color="primary" onClick={() => this.updateSentInvitationsList(idx)}>{'Remove'.translate(this.props.lang)}</Button></td>
                                            </tr>
                                        )
                                    })
                                }
                            </table>
                        </div>

                    </ModalBody>

                    <ModalFooter>
                        <div style={{ display: 'flex', alignItems: 'center', width: '100%', justifyContent: 'center' }}>

                            <Button color='primary' onClick={() => {
                                this.setState({ sentInvintations: null })
                            }}>{'Close'.translate(this.props.lang)}</Button>
                        </div>
                    </ModalFooter>
                </Modal>
                <Modal isOpen={this.state.shareModal} size='lg' centered className="share-modal-wrap">
                    <ModalHeader>
                        {'Invite external participants to video calls'.translate(this.props.lang)}
                        <h6 style={{ marginTop: '5px' }}>{'Invited external participants cannot see the chat history.'.translate(this.props.lang)}</h6>
                    </ModalHeader>

                    <ModalBody>
                        <div className="">
                            <div className='invite-time-wrap' style={{ display: 'flex', alignItems: 'center' }}>

                                <FormGroup>
                                    <DatePicker
                                        label={'Date'.translate(this.props.lang)}
                                        value={this.state.shareLinkDate}
                                        heightInput={'48px'}

                                        onChange={(e) => {
                                            console.log(e)
                                            this.setState({ shareLinkDate: e })
                                        }}
                                        marginBottom={'5px'}
                                    />
                                </FormGroup>
                                <FormGroup style={{ marginLeft: 10 }}>
                                    <Label>{'Time'.translate(this.props.lang)}</Label>

                                    <TimePickerV2
                                        getStringDateTs={this.props.getStringDateTs}
                                        format={'HH:mm'}
                                        value={this.state.shareLinkTime ? this.props.getStringDateTs(Math.floor(new Date(this.state.shareLinkTime).getTime() / 1000), 'HH:mm') : this.props.getStringDateTs(Math.floor(new Date().getTime() / 1000), 'HH:mm')}
                                        minValue={'00:00'}
                                        maxValue={'23:59'}
                                        flag={0}
                                        onChange={(time1, timeString) => {
                                            let shareLinkTime = new Date()
                                            let time = timeString.split(':')
                                            shareLinkTime.setHours(time[0], time[1], 0)
                                            this.setState({ shareLinkTime: shareLinkTime })
                                        }}
                                    />
                                    {/* <TimePicker
                                        getStringDateTs={this.props.getStringDateTs}
                                        format={'HH:mm'}
                                        value={this.state.shareLinkTime ? moment(new Date(this.state.shareLinkTime)) : moment(new Date())}
                                        minValue={'00:00'}
                                        maxValue={'23:59'}
                                        flag={0}
                                        onChange={(time1, timeString) => {
                                            console.log(timeString)
                                            let shareLinkTime = new Date()
                                            let time = timeString.split(':')
                                            shareLinkTime.setHours(time[0], time[1], 0)
                                            this.setState({ shareLinkTime: shareLinkTime })
                                        }}
                                        allowClear={false}
                                    /> */}

                                </FormGroup>
                            </div>
                            <FormGroup>
                                <Label style={{ display: 'flex', alignItems: 'center', marginBottom: 10 }}>
                                    {'Fill in mobile number or e-mail address'.translate(this.props.lang)}
                                    <div className="share-modal-info">
                                        <Isvg src={infoIcon} style={{ width: 20, minWidth: 20, height: 20 }} />
                                        <div className="tooltip-on-element">{'SMS or e-mail is sent with an invitation link to the video call.'.translate(this.props.lang)}</div>
                                    </div>
                                </Label>
                                {
                                    <div className="input-mobile-number-or-email-wrap">
                                        <Row>
                                            <Col lg='12'>
                                                {
                                                    <div className='email-form'>
                                                        <div className='email-form-row'>
                                                            <div style={{ width: '100%' }}>
                                                                <PhoneField
                                                                    countryTwoLetter={this.props.countryTwoLetter}
                                                                    country={this.props.country}
                                                                    value={this.state.shareModalFields.input}
                                                                    onChange={(e) => {
                                                                        this.setState({
                                                                            inviteError: null
                                                                        })
                                                                        let shareModalFields = this.state.shareModalFields;
                                                                        shareModalFields.input = e;
                                                                        this.setState({ shareModalFields })

                                                                    }}
                                                                    onKey={() => this.storeUsersInvitiationPhone()}
                                                                />


                                                            </div>
                                                            <button className='add-phone-button' style={this.state.shareModalFields.input && this.state.shareModalFields.input.length >= 4 ? { backgroundColor: 'red' } : { background: 'primary' }} onClick={() => this.storeUsersInvitiationPhone()}>{'Add'.translate(this.props.lang)}</button>
                                                        </div>
                                                        <p style={{ color: 'red' }}>{this.state.invitePhoneError}</p>
                                                    </div>
                                                }

                                            </Col>
                                        </Row>
                                        <Row>
                                            <Col lg='12'>
                                                <div className='email-form'>
                                                    <div className='email-form-row'>

                                                        <Input type='text' onKeyPress={this._handleKeyDown} placeholder={'Email address'.translate(this.props.lang)} value={this.state.shareModalFields.invitedEmails} onChange={(e) => this.usersInvited(e.target.value)} />
                                                        <button className='add-emails-button' style={this.state.shareModalFields.invitedEmails ? { backgroundColor: 'red' } : { background: 'primary' }} onClick={() => this.storeUsersInvitiation(this.state.shareModalFields.invitedEmails)}>{'Add'.translate(this.props.lang)}</button>
                                                    </div>
                                                    <p style={{ color: 'red' }}>{this.state.inviteEmailError}</p>
                                                    {this.state.shareModalFields.invitedUsers && this.state.shareModalFields.invitedUsers && this.state.shareModalFields.invitedUsers.length ?
                                                        <h6>{'Send invitations to'.translate(this.props.lang)}</h6>
                                                        : null}
                                                    {<div className={this.state.shareModalFields.invitedUsers && this.state.shareModalFields.invitedUsers.length ? 'email-list' : 'email-none-list'}>
                                                        {this.state.shareModalFields.invitedUsers && this.state.shareModalFields.invitedUsers.map((mail, idx) => {
                                                            // console.log(mail)
                                                            return (
                                                                <div className='schedule-tag' key={idx}>
                                                                    <button>
                                                                        {mail}
                                                                        <span onClick={() => this.handleDelete(idx)}>x</span>
                                                                    </button>
                                                                </div>
                                                            )
                                                        })}
                                                    </div>}
                                                </div>

                                            </Col>
                                        </Row>

                                    </div>
                                    //     )
                                    // })
                                }

                            </FormGroup>

                            <div style={{ display: 'flex', alignItems: 'center', marginTop: 20, width: '100%' }}>
                                <div className="link-input-wrap" style={{ width: '50%' }}>
                                    <Input type="text" value={this.state.shareModal} disabled />
                                    <div className="tooltip-on-input">{'Copy direct link to the video call'.translate(this.props.lang)}</div>
                                </div>
                                <div style={{ marginLeft: 20, display: 'flex', alignItems: 'center', cursor: 'pointer' }} className="copy-link-text" onClick={() => {
                                    this.setState({ chatHelpIcons: false });
                                    this.showCopiedLink();
                                    // const copyText = this.roomIdInput
                                    // copyText.select();
                                    // document.execCommand('copy');
                                    navigator.clipboard.writeText(this.state.shareModal)
                                }}>
                                    <Isvg src={copyLinkIcon} style={{ width: 20, minWidth: 20, height: 20 }} />
                                    <div style={{ marginLeft: 8 }} >
                                        {
                                            this.state.linkCopied ?
                                                'The link is copied to clipboard'.translate(this.props.lang)
                                                :
                                                'Copy link'.translate(this.props.lang)
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                    </ModalBody>
                    <ModalFooter className="share-modal-footer">
                        <div style={{ display: 'flex', alignItems: 'center', width: '100%', justifyContent: 'space-between' }}>

                            <Button color='primary' disabled={this.state.conversation && this.state.conversation?.invitedContacts && this.state.conversation?.invitedContacts.length ? false : true} onClick={() => {
                                this.setState({
                                    sentInvintations: this.state.conversation && this.state.conversation?.invitedContacts ? this.state.conversation?.invitedContacts : []
                                })
                            }} >{'Sent invitations'.translate(this.props.lang)}</Button>
                            <div style={{ display: 'flex', alignItems: 'center' }}>

                                <Button color='primary' onClick={() => {
                                    this.sendInvitations()
                                }}
                                    disabled={this.state.shareModalFields && this.state.shareModalFields.invitedUsers && this.state.shareModalFields.invitedUsers.length ? false : true}

                                >{'Send invitation'.translate(this.props.lang)}</Button>
                                <Button color='primary' style={{ marginLeft: 10 }} onClick={() => {
                                    let shareModalFields = [{ input: '' }];

                                    this.setState({ shareModal: null, inviteError: null, shareModalFields, shareLinkTime: moment(new Date()), shareLinkDate: this.props.getStringDateTs(Math.floor(new Date().getTime() / 1000), this.props.dateFormat)/*moment.unix(Math.floor(new Date().getTime() / 1000)).format(this.props.dateFormat)*/ })
                                }}>{'Close'.translate(this.props.lang)}</Button>
                            </div>
                        </div>

                    </ModalFooter>
                </Modal>

                {/*  */}
                {
                    this.state.questionaryModal ?
                        <Modal isOpen={this.state.questionaryModal} size="lg" centered >
                            <ModalHeader toggle={() => this.setState({ questionaryModal: null, settedRef: false, referralContentRef: null, activeQuestionary: null, questionaryMessageActive: null, activeReferralContent: null, activeHealthCareReferralObject: null, activeClinicChatForm: null, sections: null, validationQuestionary: false, validationIntelligentQuestionary: false })} close={<button type="button" className="close" style={{ position: 'absolute', top: '15px', right: '15px' }} onClick={() => this.setState({ questionaryModal: null, settedRef: false, activeQuestionary: null, questionaryMessageActive: null, activeReferralContent: null, activeHealthCareReferralObject: null, activeClinicChatForm: null, sections: null, validationQuestionary: false, validationIntelligentQuestionary: false })}>&times;</button>}>{this.state.activeReferralContent ? ''.translate(this.props.lang) : 'Run questionary'.translate(this.props.lang)}</ModalHeader>

                            <ModalBody style={this.state.activeReferralContent ? { maxHeight: '80vh', overflowY: 'auto' } : {}}>
                                {
                                    this.state.activeHealthCareReferralObject && this.state.activeClinicChatForm?.clinicLogo ?
                                        <div ref={(node) => { if (!this.state.referralContentRef) this.setState({ referralContentRef: node }, this.forceUpdate) }}>

                                            <ChatFormFirstSection
                                                {...this.props}
                                                clinic={this.state.activeHealthCareReferralObject?.clinic}
                                                clinicData={this.state.activeClinicChatForm}
                                                tsCreated={this.state.activeHealthCareReferralObject?.tsCreated}
                                                patient={this.state.activeHealthCareReferralObject?.patient}
                                            />
                                        </div>

                                        :
                                        null
                                }

                                {/* {
                                    // this.state.activeReferralContent ?
                                    <div ref={(node) => { if (!this.state.referralContentRef) this.setState({ referralContentRef: node }, this.forceUpdate) }}>
                                        <div dangerouslySetInnerHTML={{ __html: this.state.activeReferralContent }} onChange={() => { }}></div>

                                        <br />
                                    </div>

                                    // :
                                    // null
                                } */}
                                {
                                    this.state.activeQuestionary ?
                                        <>
                                            {
                                                !this.state.activeQuestionary?.intelligentReferral ?
                                                    this.state.activeReferralContent ?
                                                        <PreviewAnsweredChatForm
                                                            {...this.props}
                                                            answer={(item) => {
                                                                this.setState({ questionaryAnswers: item })
                                                            }}
                                                            referral={{
                                                                name: this.state.activeQuestionary.name,
                                                                description: this.state.activeQuestionary.description,
                                                                intelligentReferral: this.state.activeQuestionary?.intelligentReferral,
                                                                items: this.state.activeQuestionary.items
                                                            }}
                                                            referralContentRef={this.state.referralContentRef}
                                                            alias={typeof window != 'undefined' && localStorage?.groupAlias ? localStorage?.groupAlias : null}
                                                            disabledForm={typeof window != 'undefined' && localStorage?.getItem?.('profile') == 'doctor' && this.props.uData && this.props.uData?.userLevel == 20 ? true : false}
                                                            answers={this.state.questionaryAnswers}
                                                            forPrint={this.state.activeReferralContent ? true : false}
                                                            sections={this.generateSections()}></PreviewAnsweredChatForm>
                                                        :
                                                        <ChatForms {...this.props} answer={(item, isEnd) => {
                                                            this.setState({ questionaryAnswers: item, submitEnabled: isEnd })
                                                        }} referral={{ name: this.state.activeQuestionary.name, description: this.state.activeQuestionary.description, intelligentReferral: this.state.activeQuestionary?.intelligentReferral, items: this.state.activeQuestionary.items }}
                                                            referralContentRef={this.state.referralContentRef}

                                                            disabledForm={typeof window != 'undefined' && localStorage?.getItem?.('profile') == 'doctor' && this.props.uData && this.props.uData?.userLevel == 20 ? true : false}
                                                            alias={typeof window != 'undefined' && localStorage?.groupAlias ? localStorage?.groupAlias : null}
                                                            answers={this.state.questionaryAnswers}
                                                            forPrint={this.state.activeReferralContent ? true : false}
                                                            sections={this.generateSections()}></ChatForms>

                                                    :
                                                    this.state.activeReferralContent ?
                                                        <PreviewAnsweredChatForm {...this.props}
                                                            referral={{
                                                                name: this.state.activeQuestionary.name,
                                                                description: this.state.activeQuestionary.description,
                                                                intelligentReferral: this.state.activeQuestionary?.intelligentReferral,
                                                                items: this.state.activeQuestionary.items
                                                            }}
                                                            answer={(item, isEnd) => {
                                                                this.setState({ questionaryAnswers: item, validationIntelligentQuestionary: !isEnd, validationQuestionary: false })
                                                            }}
                                                            referralContentRef={this.state.referralContentRef}
                                                            alias={typeof window != 'undefined' && localStorage?.groupAlias ? localStorage?.groupAlias : null}
                                                            disabledForm={typeof window != 'undefined' && localStorage?.getItem?.('profile') == 'doctor' && this.props.uData && this.props.uData?.userLevel == 20 ? true : false}
                                                            answers={this.state.questionaryAnswers}
                                                            forPrint={this.state.activeReferralContent ? true : false}
                                                            sections={this.generateSections()}></PreviewAnsweredChatForm>
                                                        :
                                                        <ChatForms {...this.props}
                                                            referral={{ name: this.state.activeQuestionary.name, description: this.state.activeQuestionary.description, intelligentReferral: this.state.activeQuestionary?.intelligentReferral, items: this.state.activeQuestionary.items }}
                                                            answer={(item, isEnd) => {
                                                                this.setState({ questionaryAnswers: item, validationIntelligentQuestionary: !isEnd, validationQuestionary: false, submitEnabled: isEnd })
                                                            }}
                                                            referralContentRef={this.state.referralContentRef}
                                                            alias={typeof window != 'undefined' && localStorage?.groupAlias ? localStorage?.groupAlias : null}
                                                            disabledForm={typeof window != 'undefined' && localStorage?.getItem?.('profile') == 'doctor' && this.props.uData && this.props.uData?.userLevel == 20 ? true : false}
                                                            answers={this.state.questionaryAnswers}
                                                            forPrint={this.state.activeReferralContent ? true : false}
                                                            sections={this.generateSections()}></ChatForms>

                                            }
                                        </>

                                        :
                                        null
                                }
                                {
                                    this.state.validationQuestionary ?
                                        <p style={{ color: 'red' }}>{'Please fill in all fields'.translate(this.props.lang)}</p>
                                        :
                                        null
                                }
                                {
                                    this.state.activeQuestionary && this.state.activeReferralContent && this.state.questionaryAnswers && this.state.activeHealthCareReferralObject && this.state.activeClinicChatForm?.clinicLogo ?

                                        <div style={{ position: 'fixed', right: '100%' }}>
                                            <div ref={(node) => { this.referralRef = node; if (!this.state.settedRef) this.setState({ settedRef: true }) }} className="print-referral-ref" >
                                                {
                                                    this.state.activeHealthCareReferralObject && this.state.activeClinicChatForm?.clinicLogo ?
                                                        <div ref={(node) => { if (!this.state.referralContentRef) this.setState({ referralContentRef: node }, this.forceUpdate) }} style={{ paddingTop: 30 }}>

                                                            <ChatFormFirstSection
                                                                {...this.props}
                                                                clinic={this.state.activeHealthCareReferralObject?.clinic}
                                                                clinicData={this.state.activeClinicChatForm}
                                                                tsCreated={this.state.activeHealthCareReferralObject?.tsCreated}
                                                                patient={this.state.activeHealthCareReferralObject?.patient}
                                                            />
                                                        </div>

                                                        :
                                                        null
                                                }

                                                {/* {
                                                    this.state.activeReferralContent ?
                                                        <div ref={(node) => { if (!this.state.referralContentRef) this.setState({ referralContentRef: node }, this.forceUpdate) }}>

                                                            <div dangerouslySetInnerHTML={{ __html: this.state.activeReferralContent }} onChange={() => { }}></div>

                                                            <br />

                                                        </div>

                                                        :
                                                        null
                                                } */}
                                                {
                                                    !this.state.activeQuestionary?.intelligentReferral ?

                                                        this.state.activeReferralContent && this.state.referralContentRef ?
                                                            this.state.activeReferralContent ?
                                                                <PreviewAnsweredChatForm
                                                                    {...this.props}
                                                                    answer={(item) => {
                                                                        this.setState({ questionaryAnswers: item })
                                                                    }}
                                                                    referralContentRef={this.state.referralContentRef}
                                                                    referral={{
                                                                        name: this.state.activeQuestionary.name,
                                                                        description: this.state.activeQuestionary.description,
                                                                        intelligentReferral: this.state.activeQuestionary?.intelligentReferral,
                                                                        items: this.state.activeQuestionary.items
                                                                    }}
                                                                    alias={typeof window != 'undefined' && localStorage?.groupAlias ? localStorage?.groupAlias : null}
                                                                    disabledForm={typeof window != 'undefined' && localStorage?.getItem?.('profile') == 'doctor' && this.props.uData && this.props.uData?.userLevel == 20 ? true : false}
                                                                    answers={this.state.questionaryAnswers}
                                                                    forPrint={this.state.activeReferralContent ? true : false}
                                                                    forPrintHtml={true}
                                                                    sections={this.generateSections()}></PreviewAnsweredChatForm>
                                                                :
                                                                <ChatForms
                                                                    {...this.props}
                                                                    answer={(item, isEnd) => {
                                                                        this.setState({ questionaryAnswers: item, submitEnabled: isEnd })
                                                                    }}
                                                                    alias={typeof window != 'undefined' && localStorage?.groupAlias ? localStorage?.groupAlias : null}
                                                                    referralContentRef={this.state.referralContentRef}
                                                                    referral={{ name: this.state.activeQuestionary.name, description: this.state.activeQuestionary.description, intelligentReferral: this.state.activeQuestionary?.intelligentReferral }}
                                                                    disabledForm={typeof window != 'undefined' && localStorage?.getItem?.('profile') == 'doctor' && this.props.uData && this.props.uData?.userLevel == 20 ? true : false}
                                                                    answers={this.state.questionaryAnswers}
                                                                    forPrint={this.state.activeReferralContent ? true : false}
                                                                    forPrintHtml={true}
                                                                    sections={this.generateSections()}></ChatForms>
                                                            :
                                                            null

                                                        :
                                                        this.state.referralContentRef ?
                                                            <PreviewAnsweredChatForm
                                                                {...this.props}
                                                                answer={(item, isEnd) => {
                                                                    this.setState({ questionaryAnswers: item, validationIntelligentQuestionary: !isEnd, validationQuestionary: false })
                                                                }}
                                                                referralContentRef={this.state.referralContentRef}
                                                                alias={typeof window != 'undefined' && localStorage?.groupAlias ? localStorage?.groupAlias : null}
                                                                referral={{
                                                                    name: this.state.activeQuestionary.name,
                                                                    description: this.state.activeQuestionary.description,
                                                                    intelligentReferral: this.state.activeQuestionary?.intelligentReferral,
                                                                    items: this.state.activeQuestionary.items
                                                                }}
                                                                disabledForm={true}
                                                                answers={this.state.questionaryAnswers}
                                                                forPrint={true}
                                                                sections={this.generateSections()}></PreviewAnsweredChatForm>
                                                            :
                                                            null

                                                }

                                            </div>

                                        </div>
                                        :
                                        null
                                }
                            </ModalBody>

                            <ModalFooter style={{ display: 'flex', justifyContent: 'flex-end' }} className={`buttons-right-blue ${!this.state.questionarySave && ((this.props.uData && this.props.uData?.userLevel == 1) || (this.props.uData && this.props.uData?.userLevel == 2) || ((typeof window != 'undefined' && localStorage?.getItem?.('profile') == 'patient') && (this.props.uData && this.props.uData?.userLevel == 20))) ? 'modal-footer-last-button-green' : ''}`} >
                                <button style={{ visibility: 'hidden', display: 'none' }} aria-hidden='true'></button>
                                {this.state.activeHealthCareReferralObject?._id ?
                                    <Button disabled={!this.state.activeHealthCareReferralObject._id} color="primary" className='green-button' onClick={() => this.genareteChatFormPdf(this.state.activeHealthCareReferralObject._id)}> {'Save as pdf'.translate(this.props.lang)} </Button>
                                    :
                                    null
                                }
                                {/* {
                                    this.state.activeReferralContent && this.referralRef && this.state.settedRef ?
                                        <PrintHelper lang={this.props.lang} element={this.referralRef} />
                                        :
                                        null
                                }
                                {
                                    this.state.activeReferralContent && this.referralRef && this.state.settedRef ?
                                        <PdfSaveV2 lang={this.props.lang} element={this.referralRef} pdfName={this.state.activeQuestionary && this.state.activeQuestionary.name && generateAlias(this.state.activeQuestionary.name) + '_' + this.props.getStringDateTs(Math.floor(new Date().getTime() / 1000), this.props.dateFormat)} />
                                        :
                                        null
                                } */}
                                <Button color="primary" onClick={() => this.setState({ questionaryModal: null, settedRef: false, referralContentRef: null, activeQuestionary: null, questionaryMessageActive: null, activeReferralContent: null, activeHealthCareReferralObject: null, activeClinicChatForm: null, sections: null, validationQuestionary: false, validationIntelligentQuestionary: false })}> {'Close'.translate(this.props.lang)} </Button>
                                {
                                    !this.state.questionarySave && ((this.props.uData && this.props.uData?.userLevel == 1) || (this.props.uData && this.props.uData?.userLevel == 2) || ((typeof window != 'undefined' && localStorage?.getItem?.('profile') == 'patient') && (this.props.uData && this.props.uData?.userLevel == 20))) && this.state.submitEnabled ?
                                        <Button color="primary" className='green-button' onClick={() => this.sendHealthCareQuestionary()}> {'Send'.translate(this.props.lang)} </Button>
                                        :
                                        null
                                }
                                <button style={{ visibility: 'hidden', display: 'none' }} aria-hidden='true'></button>
                            </ModalFooter>



                        </Modal>
                        :
                        null
                }
                {
                    this.state.healthCareModal ?
                        <Modal isOpen={this.state.healthCareModal} size="lg" centered >
                            <ModalHeader toggle={() => this.setState({ healthCareModal: !this.state.healthCareModal })} close={<button type="button" className="close" style={{ position: 'absolute', top: '15px', right: '15px' }} onClick={() => this.setState({ healthCareModal: !this.state.healthCareModal })}>&times;</button>}>{'Chat forms'.translate(this.props.lang)}</ModalHeader>

                            <ModalBody>
                                <div className="search-box-wrap">
                                    <FormGroup>
                                        <Label>{'Search'.translate(this.props.lang)}</Label>
                                        <Input value={this.state.searchHealthCare}
                                            onChange={(e) => {
                                                this.setState({ searchHealthCare: striptags(e.target.value) }, () => this.get())
                                            }}
                                        />
                                    </FormGroup>
                                </div>
                                <div className="general-health-care-wrap">
                                    {
                                        this.state.generalHealthCare && this.state.generalHealthCare.items && this.state.generalHealthCare.items.map((item, idx) => {
                                            return (
                                                <div className="health-care-wrap health-care-wrap1">
                                                    <div className="content">
                                                        <div className="title">
                                                            {item.name}
                                                            <span>
                                                                &nbsp;&nbsp; | &nbsp;&nbsp;
                                                                {item.description}</span>

                                                        </div>

                                                        <div>
                                                            <Button style={{ backgroundColor: '#3ea9a5', color: 'white', }} onClick={() => {
                                                                this.setState({ healthCareModal: false }, () => this.sendHealthCare(item))
                                                            }}>{'Send'.translate(this.props.lang)}</Button>


                                                            <Button style={{ backgroundColor: '#818FA5', color: 'white', marginLeft: '10px' }} onClick={() => {
                                                                this.setState({ sendLaterModal: item })
                                                            }}>{'Send later'.translate(this.props.lang)}</Button>
                                                        </div>
                                                    </div>
                                                    {item.sendChatForms?.map((itemForm) => {
                                                        // console.log('item form: ', itemForm);
                                                        // console.log('conv: ', this.state.conversation);
                                                        if (itemForm && itemForm.conversation && this.state.conversation && this.state.conversation._id && String(itemForm.conversation) === String(this.state.conversation._id)) {
                                                            return (
                                                                <div className='content-date-time'>
                                                                    <div className='date-time'>
                                                                        {itemForm.date + ' - ' + itemForm.time}
                                                                    </div>
                                                                    <div>
                                                                        <button onClick={() => { this.deleteSendForms(itemForm._id) }}>{'Cancel'.translate(this.props.lang)}</button>
                                                                    </div>
                                                                </div>
                                                            )
                                                        }
                                                        else return null;

                                                    })

                                                    }
                                                </div>
                                            )
                                        })
                                    }
                                </div>


                            </ModalBody>

                            <ModalFooter style={{ display: 'flex', justifyContent: 'flex-end' }}>

                                <Button color="primary" onClick={() => this.setState({ healthCareModal: null })}> {'Close'.translate(this.props.lang)} </Button>

                            </ModalFooter>



                        </Modal>
                        :
                        null
                }
                {
                    this.state.sendLaterModal ?
                        <Modal isOpen={this.state.sendLaterModal} size="lg" centered >
                            <ModalHeader toggle={() => this.setState({ sendLaterModal: !this.state.sendLaterModal })} close={<button type="button" className="close" style={{ position: 'absolute', top: '15px', right: '15px' }} onClick={() => this.setState({ sendLaterModal: !this.state.sendLaterModal })}>&times;</button>}>{'Send later'.translate(this.props.lang)}</ModalHeader>

                            <ModalBody>
                                <div className="search-box-wrap">
                                    <FormGroup>
                                        <div style={{ display: 'flex', width: '100%' }} className='form-name-content'>
                                            <Label style={{ paddingTop: '5px', marginRight: '10px', width: '120px' }}>{'Form name'.translate(this.props.lang)}</Label>
                                            <Input style={{ width: '100%', height: '40px' }} value={this.state.sendLaterModal.name.translate(this.props.lang)}></Input>

                                        </div>
                                    </FormGroup>
                                </div>
                                <FormGroup>
                                    <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: '30px' }} className='main-send-later'>
                                        <div style={{ display: 'flex' }} className='date-pick'>
                                            <Label style={{ alignSelf: 'center', marginRight: '62px' }}>{'Date'.translate(this.props.lang) + ' *'}</Label>
                                            <DatePicker
                                                value={this.state.sendLaterModal && this.state.sendLaterModal.date ? this.state.sendLaterModal.date : moment(new Date()).format(this.props.dateFormat)}
                                                heightInput={'40px'}
                                                minDate={new Date()}
                                                dateFormat={this.props.dateFormat}
                                                onChange={(e) => {
                                                    let sendLaterModal = this.state.sendLaterModal;
                                                    sendLaterModal.date = e;
                                                    this.setState({
                                                        sendLaterModal
                                                    })
                                                }}
                                                marginBottom={'5px'}
                                                marginRight={'100px'}
                                                icon={dateIcon}
                                            />

                                        </div>

                                        <div style={{ display: 'flex' }} className='time-pick'>
                                            <Label style={{ alignSelf: 'center', marginRight: '30px' }}>{'Time'.translate(this.props.lang) + '*'}</Label>
                                            <TimePickerV2
                                                getStringDateTs={this.props.getStringDateTs}
                                                format={'HH:mm'}
                                                minValue={'00:00'}
                                                maxValue={'23:59'}
                                                flag={0}
                                                onChange={(time1, timeString) => {
                                                    let sendLaterModal = this.state.sendLaterModal;
                                                    sendLaterModal.time = timeString;
                                                    // let currentTimeHour = new Date().getHours() < 10 ? '0' + new Date().getHours() : new Date().getHours();
                                                    // let currentTimeMinutes = new Date().getMinutes() < 10 ? '0' + new Date().getMinutes() : new Date().getMinutes();
                                                    // let currentTime = currentTimeHour + ':' + currentTimeMinutes;
                                                    // let startTimeSplit = currentTime.split(":");
                                                    // let endTimeSplit = sendLaterModal.time.split(":");

                                                    // let startTimeHourMinute = Number(startTimeSplit[0]) * 60 + Number(startTimeSplit[1]);
                                                    // let endTimeHourMinute = Number(endTimeSplit[0]) * 60 + Number(endTimeSplit[1]);

                                                    this.setState({
                                                        sendLaterModal
                                                    })
                                                }}
                                                icon={timeIcon}
                                                heightInput={'40px'}
                                            />

                                        </div>
                                    </div>
                                </FormGroup>

                            </ModalBody>

                            <ModalFooter style={{ display: 'flex', justifyContent: 'flex-end' }}>
                                <Button color="primary" disabled={isDisabledSendLater} onClick={() => this.sendLaterFormsHealthCare(this.state.sendLaterModal)}> {'Schedule'.translate(this.props.lang)} </Button>
                                <Button color="primary" onClick={() => this.setState({ sendLaterModal: null })}> {'Cancel'.translate(this.props.lang)} </Button>

                            </ModalFooter>



                        </Modal>
                        :
                        null
                }
                {/*  */}
                {
                    this.state.quickPhraseModal ?
                        <Modal isOpen={this.state.quickPhraseModal} size="lg" centered >
                            <ModalHeader toggle={() => this.setState({ quickPhraseModal: !this.state.quickPhraseModal, searchPhrases: '' })} close={<button type="button" className="close" style={{ position: 'absolute', top: '15px', right: '15px' }} onClick={() => this.setState({ quickPhraseModal: !this.state.quickPhraseModal, searchPhrases: '' })}>&times;</button>}>{'Quick phrases'.translate(this.props.lang)}</ModalHeader>

                            <ModalBody style={{ paddingTop: 0 }}>
                                {
                                    !this.state.myPhraseExists ?
                                        <div className="search-box-wrap">
                                            <FormGroup>
                                                <Label>{'Search'.translate(this.props.lang)}</Label>
                                                <Input value={this.state.searchPhrases}
                                                    onChange={(e) => {
                                                        this.setState({ searchPhrases: striptags(e.target.value) }, () => this.get())
                                                    }}
                                                />
                                            </FormGroup>
                                        </div>
                                        :
                                        null
                                }
                                {
                                    this.state.myPhraseExists ?
                                        <div className="flex-space-between tablet-none notifications-tabs-wrap quick-phrases-tabs" style={{ position: 'relative', marginBottom: '50px', width: "100%", borderBottom: "1px solid #9cafbf", alignItems: 'flex-end' }}>
                                            <div className="notifications-tabs tabs-buttons-wrap" style={{ marginBottom: 0, borderBottom: 0 }}>
                                                <Button className={this.state.phraseTab == 1 ? "tab-button acitve-tab-button" : "tab-button"}
                                                    onClick={() => this.setState({ phraseTab: 1 })}
                                                >{"My phrases".translate(this.props.lang)}</Button>
                                                <Button className={this.state.phraseTab == 2 ? "tab-button acitve-tab-button" : "tab-button"}
                                                    onClick={() => this.setState({ phraseTab: 2 })}
                                                >{"Clinic phrases".translate(this.props.lang)}</Button>
                                            </div>
                                            <div className="search-box-wrap">
                                                <FormGroup>
                                                    <Label>{'Search'.translate(this.props.lang)}</Label>
                                                    <Input value={this.state.searchPhrases}
                                                        onChange={(e) => {
                                                            this.setState({ searchPhrases: striptags(e.target.value) }, () => this.get())
                                                        }}
                                                    />
                                                </FormGroup>
                                            </div>
                                        </div>
                                        :
                                        null

                                }
                                {
                                    !this.state.myPhraseExists || (this.state.myPhraseExists && this.state.phraseTab == 2) ?
                                        this.state.phrases && this.state.phrases.map((item, idx) => {
                                            return (
                                                <div className="quick-phrase-wrap">
                                                    <div className="content">
                                                        <div className="title">{item.title}</div>
                                                        <div className="phrase">
                                                            {
                                                                item.phrase && item.phrase.split('\n').length ?
                                                                    item.phrase.split('\n').map((text, t) => {
                                                                        if (t != item.phrase.split('\n').length - 1)
                                                                            return (
                                                                                <>{text}<br /></>
                                                                            )
                                                                        else
                                                                            return (
                                                                                text
                                                                            )
                                                                    })
                                                                    :
                                                                    item.phrase
                                                            }
                                                        </div>
                                                    </div>
                                                    <Button color='primary' onClick={() => {
                                                        this.setState({ message: item.phrase, quickPhraseModal: false, searchPhrases: '' })
                                                    }}>{'Use'.translate(this.props.lang)}</Button>

                                                </div>
                                            )
                                        })
                                        :
                                        null
                                }
                                {
                                    this.state.myPhraseExists && this.state.phraseTab == 1 ?
                                        this.state.myPhrases && this.state.myPhrases.map((item, idx) => {
                                            return (
                                                <div className="quick-phrase-wrap">
                                                    <div className="content">
                                                        <div className="title">{item.title}</div>
                                                        <div className="phrase">
                                                            {
                                                                item.phrase && item.phrase.split('\n').length ?
                                                                    item.phrase.split('\n').map((text, t) => {
                                                                        if (t != item.phrase.split('\n').length - 1)
                                                                            return (
                                                                                <>{text}<br /></>
                                                                            )
                                                                        else
                                                                            return (
                                                                                text
                                                                            )
                                                                    })
                                                                    :
                                                                    item.phrase
                                                            }
                                                        </div>
                                                    </div>
                                                    <Button color='primary' onClick={() => {
                                                        this.setState({ message: item.phrase, quickPhraseModal: false, searchPhrases: '' })
                                                    }}>{'Use'.translate(this.props.lang)}</Button>

                                                </div>
                                            )
                                        })
                                        :
                                        null
                                }


                            </ModalBody>

                            <ModalFooter style={{ display: 'flex', justifyContent: 'flex-end' }}>

                                <Button color="primary" onClick={() => this.setState({ quickPhraseModal: null, searchPhrases: '' })}> {'Close'.translate(this.props.lang)} </Button>

                            </ModalFooter>



                        </Modal>
                        :
                        null
                }
                {
                    this.state.previewAnswersReferralModal ?
                        <Modal isOpen={this.state.previewAnswersReferralModal} centered toggle={() => this.setState({ previewAnswersReferralModal: !this.state.previewAnswersReferralModal })} size="lg">
                            <ModalHeader toggle={() => this.setState({ previewAnswersReferralModal: !this.state.previewAnswersReferralModal })} close={<button type="button" className="close" style={{ position: 'absolute', top: '15px', right: '15px' }} onClick={() => this.setState({ previewAnswersReferralModal: !this.state.previewAnswersReferralModal })}><Isvg src={xIcon} /></button>}>{'Form preview'.translate(this.props.lang)}</ModalHeader>

                            <ModalBody>

                                <FormPreview
                                    {...this.props}
                                    referral={{
                                        _id: this.state.previewAnswersReferralModal._id,
                                        name: this.state.previewAnswersReferralModal.name,
                                        description: this.state.previewAnswersReferralModal.description,
                                        intelligentReferral: this.state.previewAnswersReferralModal.intelligentReferral,
                                        items: this.state.previewAnswersReferralModal.questionaryData.items,
                                        group: this.state.previewAnswersReferralModal.questionaryData?.group
                                    }}
                                    sections={this.generateSectionsForReferrals(this.state.previewAnswersReferralModal.questionaryData.items)}
                                    answers={this.state.previewAnswersReferralModal.answers}
                                    disabledForm={true}
                                    hidePreview={true}
                                ></FormPreview>

                            </ModalBody>
                        </Modal>
                        :
                        null
                }
                {
                    this.state.previewAnswersModal ?
                        <Modal isOpen={this.state.previewAnswersModal} centered toggle={() => this.setState({ previewAnswersModal: !this.state.previewAnswersModal })} size="lg">
                            <ModalHeader toggle={() => this.setState({ previewAnswersModal: !this.state.previewAnswersModal })} close={<button type="button" className="close" style={{ position: 'absolute', top: '15px', right: '15px' }} onClick={() => this.setState({ previewAnswersModal: !this.state.previewAnswersModal })}><Isvg src={xIcon} /></button>}>{'Form preview'.translate(this.props.lang)}</ModalHeader>

                            <ModalBody>

                                <FormPreview
                                    {...this.props}
                                    referral={{
                                        _id: this.state.previewAnswersModal._id,
                                        name: this.state.previewAnswersModal.name,
                                        description: this.state.previewAnswersModal.description,
                                        intelligentReferral: this.state.previewAnswersModal.intelligentReferral,
                                        items: this.state.previewAnswersModal.items
                                    }}
                                    sections={this.generateSectionsForReferrals(this.state.previewAnswersModal.items)}
                                    answers={this.state.previewAnswersModal.answers}
                                    disabledForm={true}
                                    hidePreview={true}
                                    hideTotalAndSubtotal={this.props.uData && this.props.uData?.userLevel && this.props.uData?.userLevel === 20 && typeof window !== 'undefined' && localStorage?.getItem?.('profile') && localStorage?.getItem('profile') !== 'patient' ? false : true}
                                ></FormPreview>

                            </ModalBody>
                        </Modal>
                        :
                        null
                }
                {
                    previewStatement ?
                        <Modal
                            isOpen={previewStatement}
                            centered
                            size="lg"
                        >
                            <ModalHeader
                                close={<button type="button" className="close" style={{ position: 'absolute', top: '15px', right: '15px' }} onClick={() => this.setState({ previewStatement: !previewStatement })} ><Isvg src={xIcon} /></button>}
                            >
                                {'Preview statement'.translate(this.props.lang)}
                            </ModalHeader>
                            <ModalBody>
                                <div className='preview-statement-body-wrap'>
                                    <h6>{previewStatement?.statement?.title} <span>({previewStatement.caseNumber ?? previewStatement?.conversationName})</span></h6>
                                    <div dangerouslySetInnerHTML={{ __html: previewStatement?.statement?.notes }} />
                                    <hr />
                                    <div className='preview-info'>
                                        <p>
                                            {
                                                previewStatement.savedByUser ?
                                                    <>
                                                        {'Created by'.translate(this.props.lang)} {previewStatement.savedByUser}
                                                    </>
                                                    :
                                                    null
                                            }
                                        </p>
                                        <p>
                                            {
                                                previewStatement.timestamp ?
                                                    <>
                                                        {this.props.getStringDateTs(previewStatement.timestamp, this.props.dateFormat)} {this.props.getStringDateTs(previewStatement.timestamp, 'HH:mm:ss')}
                                                    </>
                                                    :
                                                    null
                                            }
                                        </p>
                                    </div>

                                </div>
                            </ModalBody>
                            <ModalFooter className='buttons-right-blue'>
                                <Button disabled={this.state.loadingPdf} color="primary" onClick={() => {
                                    this.exportAsPdf(previewStatement?.conversationId, previewStatement?._id)
                                }}>{'Export as PDF'.translate(this.props.lang)}</Button>

                                <Button color="primary" onClick={() => this.setState({ previewStatement: !previewStatement })}>{'Close'.translate(this.props.lang)}</Button>

                            </ModalFooter>

                        </Modal>
                        :
                        null
                }
                {
                    this.state.activeConsultations ? <Modal
                        isOpen={this.state.activeConsultations}
                        centered
                        size='md'
                    >
                        <ModalHeader
                            close={<button type="button" className="close" style={{ position: 'absolute', top: '15px', right: '15px' }} onClick={() => this.setState({ activeConsultations: null })} ><Isvg src={xIcon} /></button>}
                        >
                            {'Active Consultations'.translate(this.props.lang)}
                        </ModalHeader>
                        <ModalBody>
                            <Row>
                                <Col>
                                    {this.state.activeConsultations && Array.isArray(this.state.activeConsultations) && this.state.activeConsultations.length ? this.state.activeConsultations.map((consultation, idx) => {
                                        let tab;
                                        if (consultation?.sender?.groupId === this.props.selectedGroup && consultation?.sender?.clinicId === this.props.selectedClinic && consultation?.sender?.doctorId === this.props.uData?._id) {
                                            tab = 'sender';
                                        } else if (consultation?.receiver?.groupId === this.props.selectedGroup && consultation?.receiver?.clinicId === this.props.selectedClinic && consultation?.receiver?.doctorId === this.props.uData?._id) {
                                            tab = 'receiver';
                                        };

                                        if (!tab) return null;

                                        const caseNumber = consultation?.referralData?.caseNumber ?? 'N/A';
                                        const patient = {
                                            name: consultation?.referralData?.patientName ?? 'N/A',
                                            ssn: consultation?.patientSSN ?? 'N/A',
                                        }

                                        const otherClinic = tab === 'sender' ? {
                                            group: consultation?.receiver?.groupName,
                                            clinic: consultation?.receiver?.clinicName,
                                        } : {
                                            group: consultation?.sender?.groupName,
                                            clinic: consultation?.sender?.clinicName,
                                        }

                                        const status = tab === 'sender' ? (
                                            consultation?.sender?.status === 'closed' ? (
                                                'Closed'.translate(this.props.lang)
                                            ) : (
                                                'Active'.translate(this.props.lang)
                                            )
                                        ) : (
                                            consultation?.receiver?.status === 'closed' ? (
                                                'Closed'.translate(this.props.lang)
                                            ) : (
                                                'Active'.translate(this.props.lang)
                                            )
                                        );

                                        return (
                                            <Button
                                                key={consultation?._id ?? idx}
                                                className='consultations-active-cons-button-wrapper'
                                                onClick={() => {
                                                    const status = consultation?.[tab]?.status;
                                                    this.props[0]?.history?.push?.('/e-connect/consultation', {
                                                        _chatRedirect: true,
                                                        consultationId: consultation?._id,
                                                        tab,
                                                        status,
                                                    })
                                                }}
                                            >
                                                {/* <div>{caseNumber}</div>
                                                <div>
                                                    <div>{patient?.name}</div>
                                                    <div>{patient?.ssn}</div>
                                                </div> */}
                                                <div>
                                                    <div>{otherClinic?.group}</div>
                                                    <div>{otherClinic?.clinic}</div>
                                                </div>
                                                <div>{status}</div>
                                            </Button>
                                        )
                                    }) : null}
                                </Col>
                            </Row>
                        </ModalBody>
                        <ModalFooter style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end' }}>
                            <button hidden style={{ display: 'none', visibility: 'hidden' }} aria-hidden='true'></button>
                            <Button disabled={isAddNewConsultationButtonDisabled} style={{ minWidth: 100 }} color='info' onClick={() => {
                                if (isAddNewConsultationButtonDisabled) return;
                                if (consultationButtonDisabled) {
                                    this.setState({ error: 'You cannot create a consultation for case with this status.'.translate(this.props.lang) })
                                } else {
                                    this.setState({
                                        activeConsultations: null,
                                        consultationModal: true
                                    })
                                }

                            }}>{'Create New Consultation'.translate(this.props.lang)}</Button>
                            <Button style={{ minWidth: 100 }} color="primary" onClick={() => this.setState({ activeConsultations: null })}>{'Close'.translate(this.props.lang)}</Button>
                            <button hidden style={{ display: 'none', visibility: 'hidden' }} aria-hidden='true'></button>
                        </ModalFooter>
                    </Modal> : null
                }
                {
                    this.state.consultationModal ?
                        <ConsultationModal
                            {...this.props}
                            isOpen={this.state.consultationModal}
                            conversation={this.state.conversation}
                            closeModal={(fetchConversations) => this.setState({ consultationModal: null }, () => {
                                let isDropIn = this.state.conversation?.referral?.dropIn;
                                if (fetchConversations) {
                                    this.setState({ conversation: null }, () => {
                                        // console.log('EMIT_CONVERSATIONS 25');
                                        this.emitConversations()
                                        if (isDropIn) {
                                            setTimeout(() => {
                                                // console.log('EMIT_CONVERSATIONS 26');
                                                this.emitConversations()
                                            }, 2000);
                                        }

                                    })
                                }
                            })}
                        />
                        :
                        null
                }
                {
                    this.state.referral ?
                        <div className="chat-referral-preview-wrap modal-header-unpaid">
                            <ReferralContent
                                {...this.props}
                                data={this.state.referral}
                                members={this.state.conversation?.members ?? []}
                                screen={'chat'}
                                doctorPage={this.props.uData && this.props.uData?.userLevel && this.props.uData?.userLevel === 20 && typeof window !== 'undefined' && localStorage?.getItem?.('profile') && localStorage?.getItem('profile') !== 'patient' ? true : false}
                                enterprise={enterprise}
                                // adminInThisGroup={this.state.adminInThisGroup}
                                closeCase={() => { this.setState({ closeCase: true }) }}

                                previewAnswersReferralModal={(form) => {
                                    this.setState({ previewAnswersReferralModal: form })
                                }}
                                previewAnswersRevisit={(form) => {
                                    this.setState({ previewAnswersModal: form })
                                }}
                                closeReferralContent={(data) => {
                                    if (this.state.referral) {


                                        this.setState({ referral: null }, () => this.removeFromReferralPreview())
                                    }

                                }}

                                _consultation={this.state._consultations && Array.isArray(this.state._consultations) && this.state._consultations.find?.(obj => obj?.referralId === this.state.referral?._id) ? true : false}
                            />
                        </div>

                        :
                        null
                }
                {
                    this.state.referral && false ?

                        <div className="chat-referral-preview-wrap modal-header-unpaid">
                            <div className="chat-referral-preview-title">
                                <h4>{'Referral preview'.translate(this.props.lang)}</h4>

                            </div>
                            <div className="chat-referral-preview-body modal-body">
                                <div className='unpaid-button-container'>
                                    {
                                        this.state.referral.referralIsPaid || (this.state.referral.event && this.state.referral.event.appointmentPaid) || (this.state.referral.event && this.state.referral.event.visitReasonPrice == 0) || this.state.referral.questionaryPrice == 0 || this.props.uData?.userLevel == 1 || this.props.uData?.userLevel == 2 || localStorage?.getItem('profile') == 'patient' ?
                                            null
                                            :
                                            <button>
                                                <Isvg src={billInfoIcon} />
                                                {'UNPAID'.translate(this.props.lang)}
                                            </button>
                                    }
                                </div>
                                {
                                    this.state.referral.priority && this.props.uData && this.props.uData?.userLevel && this.props.uData?.userLevel === 20 && typeof window !== 'undefined' && localStorage?.getItem?.('profile') && localStorage?.getItem('profile') !== 'patient' ?
                                        <div className={
                                            this.state.referral.priority == 1 ? 'priority-circle p-red nohover' :
                                                this.state.referral.priority == 2 ? 'priority-circle p-orange nohover' :
                                                    this.state.referral.priority == 3 ? 'priority-circle p-yellow nohover' :
                                                        this.state.referral.priority == 4 ? 'priority-circle p-black nohover' :
                                                            'priority-circle p-green nohover'
                                        }>
                                            {this.state.referral.priority}
                                        </div>
                                        :
                                        null
                                }
                                {
                                    this.state.referral.referralContent ?
                                        <div dangerouslySetInnerHTML={{ __html: this.props.lang == 'se' ? this.state.referral.referralContent.protectReferralFiles(this.props.uData ? this.props.uData.mediaToken : '', this.state.referral?._id) : this.state.referral.referralContentEn ? this.state.referral.referralContentEn.protectReferralFiles(this.props.uData ? this.props.uData.mediaToken : '', this.state.referral?._id) : this.state.referral.referralContent.protectReferralFiles(this.props.uData ? this.props.uData.mediaToken : '', this.state.referral?._id) }} onChange={() => { }}></div>

                                        :
                                        null
                                }
                                {
                                    enterprise && this.state.referral.revisitFormsArray && this.state.referral.revisitFormsArray.length && this.state.referral.revisitFormsArray[this.state.activeRevisitForm - 1] ?
                                        this.state.referral.revisitFormsArray[this.state.activeRevisitForm - 1].referralData ?
                                            <div>
                                                <h6>{'Patient answers'.translate(this.props.lang)} <span style={{ marginLeft: 20, fontWeight: 600 }}>{this.state.referral.revisitFormsArray[this.state.activeRevisitForm - 1].name}</span> </h6>
                                                {/* <div dangerouslySetInnerHTML={{ __html: this.state.referral.revisitFormsArray[this.state.activeRevisitForm - 1].referralData.protectReferralFiles(this.props.uData ? this.props.uData.mediaToken : '', this.state.referral?._id) }}></div> */}
                                                <div dangerouslySetInnerHTML={{ __html: this.props.lang == 'se' ? this.state.referral.revisitFormsArray[this.state.activeRevisitForm - 1].referralData.protectReferralFiles(this.props.uData ? this.props.uData.mediaToken : '', this.state.referral?._id) : this.state.referral.revisitFormsArray[this.state.activeRevisitForm - 1].referralDataEn ? this.state.referral.revisitFormsArray[this.state.activeRevisitForm - 1].referralDataEn.protectReferralFiles(this.props.uData ? this.props.uData.mediaToken : '', this.state.referral?._id) : this.state.referral.revisitFormsArray[this.state.activeRevisitForm - 1].referralData.protectReferralFiles(this.props.uData ? this.props.uData.mediaToken : '', this.state.referral?._id) }}></div>
                                                {
                                                    this.state.referral.revisitFormsArray[this.state.activeRevisitForm - 1].referralTotalData ?
                                                        // <div dangerouslySetInnerHTML={{ __html: this.state.referral.revisitFormsArray[this.state.activeRevisitForm - 1].referralTotalData.protectReferralFiles(this.props.uData ? this.props.uData.mediaToken : '', this.state.referral?._id) }}></div>
                                                        <div dangerouslySetInnerHTML={{ __html: this.props.lang == 'se' ? this.state.referral.revisitFormsArray[this.state.activeRevisitForm - 1].referralTotalData.protectReferralFiles(this.props.uData ? this.props.uData.mediaToken : '', this.state.referral?._id) : this.state.referral.revisitFormsArray[this.state.activeRevisitForm - 1].referralTotalDataEn ? this.state.referral.revisitFormsArray[this.state.activeRevisitForm - 1].referralTotalDataEn.protectReferralFiles(this.props.uData ? this.props.uData.mediaToken : '', this.state.referral?._id) : this.state.referral.revisitFormsArray[this.state.activeRevisitForm - 1].referralTotalData.protectReferralFiles(this.props.uData ? this.props.uData.mediaToken : '', this.state.referral?._id) }}></div>
                                                        :
                                                        null
                                                }

                                                {/* <div dangerouslySetInnerHTML={{ __html: this.state.referral.revisitFormsArray[this.state.activeRevisitForm - 1].referralData }}></div>
                                            <div dangerouslySetInnerHTML={{ __html: this.state.referral.revisitFormsArray[this.state.activeRevisitForm - 1].referralTotalData }}></div> */}
                                                <Button color='primary' onClick={() => { this.setState({ previewAnswersModal: this.state.referral.revisitFormsArray[this.state.activeRevisitForm - 1] }) }}>{'Preview answers'.translate(this.props.lang)}</Button>
                                                <hr />
                                            </div>
                                            :
                                            <div>
                                                <h6>{'Patient answers'.translate(this.props.lang)} <span style={{ marginLeft: 20, fontWeight: 600 }}>{this.state.referral.revisitFormsArray[this.state.activeRevisitForm - 1].name}</span> </h6>
                                                <p style={{ fontStyle: 'italic', fontSize: 14 }}>{'The patient did not fill out the form'.translate(this.props.lang)}</p>
                                                <hr />
                                            </div>

                                        :
                                        null
                                }
                                {
                                    this.state.referral && this.state.referral.answers && this.state.referral.questionaryData && this.state.referral.questionaryData.items ?
                                        <div style={{ marginBottom: 10 }}>
                                            <Button color='primary' onClick={() => { this.setState({ previewAnswersReferralModal: this.state.referral }) }}>{'Preview answers'.translate(this.props.lang)}</Button>
                                        </div>
                                        :
                                        null
                                }
                                <div className='patient-referral-preview-status-wrap'>
                                    <div>
                                        <p> <strong>{'Status:'.translate(this.props.lang)}</strong> {humanReadable[this.state.referral.status] && humanReadable[this.state.referral.status].translate(this.props.lang)}</p>
                                        {this.state.referral.reason ?
                                            <p> <strong>{'Reason:'.translate(this.props.lang)}</strong> {this.state.referral.reason ? this.state.referral.reason : ''}</p>
                                            :
                                            null}
                                        {this.state.referral.patientReservedTimestamp ?
                                            <p> <strong>{'Scheduled time:'.translate(this.props.lang)}</strong> {this.props.getStringDateTs(this.state.referral.patientReservedTimestamp, `${this.props.dateFormat}${!this.state.referral.hideTimeFromThePatient || this.state.conversation && this.state.conversation?.referral && this.state.conversation?.referral.patient && this.state.conversation?.referral.patient.socialSecurityNumber != this.props.uData.socialSecurityNumber ? ' HH:mm' : ''}`)/*moment.unix(this.state.referral.patientReservedTimestamp).format(`${this.props.dateFormat}${!this.state.referral.hideTimeFromThePatient || this.state.conversation && this.state.conversation.referral && this.state.conversation.referral.patient && this.state.conversation.referral.patient.socialSecurityNumber != this.props.uData.socialSecurityNumber ? ' HH:mm' : ''}`)*/}</p>
                                            :
                                            null}

                                        {
                                            this.state.referral.status == 'patient-requested-another-time' ?
                                                <p> <strong>{'Requested time:'.translate(this.props.lang)}</strong> {this.props.getStringDateTs(this.state.referral.patientRequestedTimestamp, `${this.props.dateFormat} HH:mm`)/*moment.unix(this.state.referral.patientRequestedTimestamp).format(`${this.props.dateFormat} HH:mm`)*/}</p>
                                                :
                                                null
                                        }

                                    </div>
                                    {
                                        enterprise && this.state.referral.revisitFormsArray && this.state.referral.revisitFormsArray.length ?
                                            <div className='doctor-preview-revisit-forms'>
                                                <div className='doctor-preview-revisit-forms-header'>
                                                    {
                                                        this.state.activeRevisitForm ?
                                                            <span>{this.state.referral.revisitFormsArray[this.state.activeRevisitForm - 1].name} <span>({this.state.activeRevisitForm}/{this.state.referral.revisitFormsArray.length})</span></span>
                                                            :
                                                            <span>{this.state.referral.revisitFormsArray[0].name} <span>(1/{this.state.referral.revisitFormsArray.length})</span></span>
                                                    }
                                                </div>
                                                <div className='buttons-next-prev-wrap'>
                                                    <Button color='primary' disabled={this.state.activeRevisitForm && this.state.activeRevisitForm > 1 ? false : true} onClick={() => {
                                                        let activeRevisitForm = this.state.activeRevisitForm;
                                                        if (!activeRevisitForm) {
                                                            activeRevisitForm = 1;
                                                        }
                                                        if (activeRevisitForm > 1) {
                                                            activeRevisitForm--;
                                                        }
                                                        this.setState({ activeRevisitForm })
                                                    }}>{'Previous'.translate(this.props.lang)}</Button>
                                                    <Button color='primary' disabled={this.state.activeRevisitForm && this.state.activeRevisitForm < this.state.referral.revisitFormsArray.length ? false : true} onClick={() => {
                                                        let activeRevisitForm = this.state.activeRevisitForm;
                                                        if (!activeRevisitForm) {
                                                            activeRevisitForm = 1;
                                                        }
                                                        if (activeRevisitForm < this.state.referral.revisitFormsArray.length) {
                                                            activeRevisitForm++;
                                                        }
                                                        this.setState({ activeRevisitForm })
                                                    }}>{'Next'.translate(this.props.lang)}</Button>
                                                </div>
                                            </div>
                                            :
                                            null

                                    }
                                </div>
                                <div style={{ display: 'flex', justifyContent: 'flex-end', width: '100%', paddingTop: 10 }}>
                                    {
                                        (this.state.referral.status == 'revisit-scheduled' || this.state.referral.status === 'visit-rescheduled') && this.state.referral.closeCaseManually && enterprise && this.state.referral.recipientUser == this.props.uData?._id ?
                                            <div className='red-button-wrap' style={{ marginRight: 10 }}>
                                                <Button color='danger' onClick={() => this.setState({ closeCase: true })} >{'Close case'.translate(this.props.lang)}</Button>
                                            </div>
                                            :
                                            null
                                    }
                                    <Button color="primary" onClick={() => this.setState({ referral: null }, () => this.removeFromReferralPreview())}> {'Close'.translate(this.props.lang)} </Button>
                                </div>

                            </div>
                            {/* <div className="chat-referral-preview-footer">
                            <Button color="primary" onClick={() => this.setState({ referral: null }, () => this.removeFromReferralPreview())}> {'Close'.translate(this.props.lang)} </Button>
                        </div> */}
                        </div>

                        :
                        null
                }
                {
                    this.state.closeCase ?
                        <Modal isOpen={this.state.closeCase} centered>
                            {/* <ModalHeader style={{ margin: 'auto' }}>{'Warning'.translate(this.props.lang)}</ModalHeader> */}
                            <ModalBody className="delete-modal">
                                {/* <img src={warrning} /> */}
                                <p className="message">{`Are you sure you want to close the case?`.translate(this.props.lang)}</p>
                            </ModalBody>
                            <ModalFooter className='buttons-right-blue'>
                                <Button color="primary" style={{ margin: 10, minWidth: 100 }} onClick={() => this.setState({ closeCase: false }, () => this.closeReferral())}>{'Yes'.translate(this.props.lang)}</Button>
                                <Button color="primary" style={{ margin: 10, minWidth: 100 }} onClick={() => this.setState({ closeCase: false })}>{'No'.translate(this.props.lang)}</Button>

                            </ModalFooter>
                        </Modal>
                        : null
                }

                {
                    this.state.profileModal ?
                        <Modal isOpen={this.state.profileModal} centered>
                            <ModalHeader toggle={() => this.setState({ profileModal: !this.state.profileModal })} close={<button type="button" className="close" style={{ position: 'absolute', top: '15px', right: '15px' }} onClick={() => this.setState({ profileModal: !this.state.profileModal })}>&times;</button>}>
                                <h4>{'Patient profile'.translate(this.props.lang)}</h4>
                            </ModalHeader>
                            <ModalBody>
                                <FormGroup>
                                    <Label>{'Social security number'.translate(this.props.lang)}</Label>
                                    <Input type='text' disabled
                                        value={this.state.profileModal.socialSecurityNumber} />
                                </FormGroup>
                                <FormGroup>
                                    <Label>{'Name'.translate(this.props.lang)}</Label>
                                    <Input type='text' disabled
                                        value={this.state.profileModal.userData && this.state.profileModal.userData.name} />
                                </FormGroup>
                                <FormGroup>
                                    <Label>{'Email'.translate(this.props.lang)}</Label>
                                    <Input type='text' disabled
                                        value={this.state.profileModal.parents ? this.state.profileModal.parents[0].email : this.state.profileModal.email} />
                                </FormGroup>
                                <FormGroup>
                                    <Label>{'Phone'.translate(this.props.lang)}</Label>
                                    <Input type='text' disabled
                                        value={this.state.profileModal.parents ? this.state.profileModal.parents[0].phone : this.state.profileModal.phone} />
                                </FormGroup>
                                <FormGroup>
                                    <Label>{'Address'.translate(this.props.lang)}</Label>
                                    <Input type='text' disabled
                                        value={this.state.profileModal.parents ? this.state.profileModal.parents[0].address : this.state.profileModal.street + ',' + ' ' + this.state.profileModal.zip + ' ' + this.state.profileModal.city} />
                                </FormGroup>


                            </ModalBody>
                            <ModalFooter className='buttons-right-blue'>
                                <Button color="primary" onClick={() => this.setState({ profileModal: !this.state.profileModal })}>{'Close'.translate(this.props.lang)}</Button>

                            </ModalFooter>
                        </Modal>

                        : null
                }

                <div className='input-fields-below-chat'>
                    <input
                        ref={(node) => this.documentInput = node}
                        type="file" accept=".doc, .docx, .pdf"
                        key={this.state.uploadTimestampDoc}
                        onChange={(e) => {
                            let input = e?.target;
                            let fileName = input?.files && input?.files[0] && input?.files[0].name ? input?.files[0].name : null;
                            let extension = fileName && fileName.indexOf('.') !== -1 && fileName.split('.')[1] ? fileName.split('.')[1] : null;
                            extension = extension?.toLowerCase();
                            let type = 'document';
                            for (var key in UPLOAD_EXTENSIONS) {
                                if (UPLOAD_EXTENSIONS[key] && UPLOAD_EXTENSIONS[key].indexOf(extension) !== -1) {
                                    type = key;
                                }
                            }
                            if (type === 'video') this.uploadVideo(e);
                            else if (type === 'image') this.uploadImage(e);
                            else this.uploadDocument(e)

                        }}
                        style={{ pointerEvents: 'none', opacity: 0 }}
                    />
                    <input
                        ref={(node) => this.imageInput = node}
                        type="file"
                        accept="image/png, image/jpeg, image/jpg, image/gif, image/svg"
                        key={this.state.uploadTimestamp}
                        onChange={(e) => {
                            let input = e?.target;
                            let fileName = input?.files && input?.files[0] && input?.files[0].name ? input?.files[0].name : null;
                            let extension = fileName && fileName.indexOf('.') !== -1 && fileName.split('.')[1] ? fileName.split('.')[1] : null;
                            extension = extension?.toLowerCase();
                            let type = 'image';
                            for (var key in UPLOAD_EXTENSIONS) {
                                if (UPLOAD_EXTENSIONS[key] && UPLOAD_EXTENSIONS[key].indexOf(extension) !== -1) {
                                    type = key;
                                }
                            }
                            if (type === 'video') this.uploadVideo(e);
                            else if (type === 'document') this.uploadDocument(e);
                            else this.uploadImage(e)

                        }}
                        style={{ pointerEvents: 'none', opacity: 0 }}
                    />
                    <input
                        ref={(node) => this.videoContentInput = node}
                        type="file"
                        accept=".mp4, .avi, .mov"
                        key={this.state.uploadTimestampVideo}
                        onChange={(e) => {
                            let input = e?.target;
                            let fileName = input?.files && input?.files[0] && input?.files[0].name ? input?.files[0].name : null;
                            let extension = fileName && fileName.indexOf('.') !== -1 && fileName.split('.')[1] ? fileName.split('.')[1] : null;
                            extension = extension?.toLowerCase();
                            let type = 'video';
                            for (var key in UPLOAD_EXTENSIONS) {
                                if (UPLOAD_EXTENSIONS[key] && UPLOAD_EXTENSIONS[key].indexOf(extension) !== -1) {
                                    type = key;
                                }
                            }
                            if (type === 'image') this.uploadImage(e);
                            else if (type === 'document') this.uploadDocument(e);
                            else this.uploadVideo(e)

                        }}
                        style={{ pointerEvents: 'none', opacity: 0 }}
                    />
                </div>



                {
                    this.state.form ?
                        <FormBuilder
                            dateFormat={this.props.dateFormat}
                            countryTwoLetter={this.props.countryTwoLetter}
                            getStringDateTs={this.props.getStringDateTs}
                            lang={this.props.lang}
                            onSubmit={(data) => this.createConversation(data)}
                            initialValues={this.state.form}
                            isOpen={this.state.form}
                            //size={'xl'}
                            toggle={() => this.setState({ form: null })}
                            title={'New conversation'.translate(this.props.lang)}
                            _saving={this.state._saving}
                            buttonConfirmText={'Created'.translate(this.props.lang)}
                            buttonText={'Create'.translate(this.props.lang)}
                            fields={[
                                {
                                    type: 'row',
                                    children: [
                                        {
                                            type: 'col',
                                            width: {
                                                lg: 12,
                                                sm: 12,
                                                xs: 12
                                            },
                                            children: [
                                                {
                                                    type: 'text',
                                                    name: 'name',
                                                    label: 'Conversation name'.translate(this.props.lang),
                                                    validate: [required('Conversation name is required!'.translate(this.props.lang))]
                                                },
                                                {
                                                    type: 'textarea',
                                                    name: 'description',
                                                    label: 'Conversation Description (optional)'.translate(this.props.lang),
                                                },
                                                {
                                                    type: 'checkbox',
                                                    name: 'openInNewTab',
                                                    label: 'Open in new tab'.translate(this.props.lang),
                                                },

                                            ],

                                        },
                                    ]
                                }
                            ]}
                        ></FormBuilder>
                        :
                        null
                }
                {
                    this.state.groupError ? (
                        <Modal isOpen={this.state.groupError} centered>
                            {/* <ModalHeader style={{ margin: 'auto' }}>{'Error'.translate(this.props.lang)}</ModalHeader> */}
                            <ModalBody className="delete-modal">
                                {/* <img src={warrning} /> */}
                                <p className="message">{'You have to select the group!'.translate(this.props.lang)}</p>
                            </ModalBody>

                            <ModalFooter className='buttons-right-blue'>
                                <Button style={{ minWidth: 100 }} onClick={() => {
                                    this.props[0].history.push(`/home-patient-level`);
                                }}>{'Close'.translate(this.props.lang)}</Button>
                            </ModalFooter>
                        </Modal>
                    ) : null
                }

                {
                    this.state._dragNDropUpload || this.state._imageUploading || this.state._videoUploading || this.state._documentUploading ? (
                        <div className="file-progress-loader">
                            <div>
                                <Player
                                    onEvent={(event) => {
                                        if (event === "load" && this.player && this.player.current) this.player.current.play();
                                    }}
                                    ref={this.player} // set the ref to your class instance
                                    autoplay={true}
                                    loop={true}
                                    controls={true}
                                    src="/lf30_editor_l5cxzdyf.json"
                                    renderer={'svg'}
                                    style={{ height: "128px", width: "128px", }}
                                ></Player>
                                <h6>{this.props.uploadingFileProgress ? ('Uploading file...'.translate(this.props.lang) + this.props.uploadingFileProgress + '%') : 'Processing file...'.translate(this.props.lang)}</h6>
                            </div>

                        </div>
                    ) : null
                }

                {
                    this.state.rateConversationModal ?
                        <Modal isOpen={this.state.rateConversationModal} className="end-video-call-modal" centered>
                            <ModalHeader>{"Rate your visit".translate(this.props.lang)}</ModalHeader>

                            <ModalBody className="video-call-limit ">
                                <p>{'How did you experience your visit?'.translate(this.props.lang)}</p>
                                <div className="rating">
                                    {this.state.rateConversationModal.rating1 && this.state.rateConversationModal.rating1 >= 1 ? <button onClick={() => { let rateConversationModal = this.state.rateConversationModal; rateConversationModal.rating1 = 1; this.setState({ rateConversationModal }) }}><Isvg src={fullStarIcon} /></button> : <button onClick={() => { let rateConversationModal = this.state.rateConversationModal; rateConversationModal.rating1 = 1; this.setState({ rateConversationModal }) }}><Isvg src={starIcon} /></button>}
                                    {this.state.rateConversationModal.rating1 && this.state.rateConversationModal.rating1 >= 2 ? <button onClick={() => { let rateConversationModal = this.state.rateConversationModal; rateConversationModal.rating1 = 2; this.setState({ rateConversationModal }) }}><Isvg src={fullStarIcon} /></button> : <button onClick={() => { let rateConversationModal = this.state.rateConversationModal; rateConversationModal.rating1 = 2; this.setState({ rateConversationModal }) }}><Isvg src={starIcon} /></button>}
                                    {this.state.rateConversationModal.rating1 && this.state.rateConversationModal.rating1 >= 3 ? <button onClick={() => { let rateConversationModal = this.state.rateConversationModal; rateConversationModal.rating1 = 3; this.setState({ rateConversationModal }) }}><Isvg src={fullStarIcon} /></button> : <button onClick={() => { let rateConversationModal = this.state.rateConversationModal; rateConversationModal.rating1 = 3; this.setState({ rateConversationModal }) }}><Isvg src={starIcon} /></button>}
                                    {this.state.rateConversationModal.rating1 && this.state.rateConversationModal.rating1 >= 4 ? <button onClick={() => { let rateConversationModal = this.state.rateConversationModal; rateConversationModal.rating1 = 4; this.setState({ rateConversationModal }) }}><Isvg src={fullStarIcon} /></button> : <button onClick={() => { let rateConversationModal = this.state.rateConversationModal; rateConversationModal.rating1 = 4; this.setState({ rateConversationModal }) }}><Isvg src={starIcon} /></button>}
                                    {this.state.rateConversationModal.rating1 && this.state.rateConversationModal.rating1 >= 5 ? <button onClick={() => { let rateConversationModal = this.state.rateConversationModal; rateConversationModal.rating1 = 5; this.setState({ rateConversationModal }) }}><Isvg src={fullStarIcon} /></button> : <button onClick={() => { let rateConversationModal = this.state.rateConversationModal; rateConversationModal.rating1 = 5; this.setState({ rateConversationModal }) }}><Isvg src={starIcon} /></button>}
                                </div>
                                <p>{"How did you experience the staff's competence?".translate(this.props.lang)}</p>
                                <div className="rating">
                                    {this.state.rateConversationModal.rating2 && this.state.rateConversationModal.rating2 >= 1 ? <button onClick={() => { let rateConversationModal = this.state.rateConversationModal; rateConversationModal.rating2 = 1; this.setState({ rateConversationModal }) }}><Isvg src={fullStarIcon} /></button> : <button onClick={() => { let rateConversationModal = this.state.rateConversationModal; rateConversationModal.rating2 = 1; this.setState({ rateConversationModal }) }}><Isvg src={starIcon} /></button>}
                                    {this.state.rateConversationModal.rating2 && this.state.rateConversationModal.rating2 >= 2 ? <button onClick={() => { let rateConversationModal = this.state.rateConversationModal; rateConversationModal.rating2 = 2; this.setState({ rateConversationModal }) }}><Isvg src={fullStarIcon} /></button> : <button onClick={() => { let rateConversationModal = this.state.rateConversationModal; rateConversationModal.rating2 = 2; this.setState({ rateConversationModal }) }}><Isvg src={starIcon} /></button>}
                                    {this.state.rateConversationModal.rating2 && this.state.rateConversationModal.rating2 >= 3 ? <button onClick={() => { let rateConversationModal = this.state.rateConversationModal; rateConversationModal.rating2 = 3; this.setState({ rateConversationModal }) }}><Isvg src={fullStarIcon} /></button> : <button onClick={() => { let rateConversationModal = this.state.rateConversationModal; rateConversationModal.rating2 = 3; this.setState({ rateConversationModal }) }}><Isvg src={starIcon} /></button>}
                                    {this.state.rateConversationModal.rating2 && this.state.rateConversationModal.rating2 >= 4 ? <button onClick={() => { let rateConversationModal = this.state.rateConversationModal; rateConversationModal.rating2 = 4; this.setState({ rateConversationModal }) }}><Isvg src={fullStarIcon} /></button> : <button onClick={() => { let rateConversationModal = this.state.rateConversationModal; rateConversationModal.rating2 = 4; this.setState({ rateConversationModal }) }}><Isvg src={starIcon} /></button>}
                                    {this.state.rateConversationModal.rating2 && this.state.rateConversationModal.rating2 >= 5 ? <button onClick={() => { let rateConversationModal = this.state.rateConversationModal; rateConversationModal.rating2 = 5; this.setState({ rateConversationModal }) }}><Isvg src={fullStarIcon} /></button> : <button onClick={() => { let rateConversationModal = this.state.rateConversationModal; rateConversationModal.rating2 = 5; this.setState({ rateConversationModal }) }}><Isvg src={starIcon} /></button>}
                                </div>
                                <p>{'How did you experience the treatment?'.translate(this.props.lang)}</p>
                                <div className="rating">
                                    {this.state.rateConversationModal.rating3 && this.state.rateConversationModal.rating3 >= 1 ? <button onClick={() => { let rateConversationModal = this.state.rateConversationModal; rateConversationModal.rating3 = 1; this.setState({ rateConversationModal }) }}><Isvg src={fullStarIcon} /></button> : <button onClick={() => { let rateConversationModal = this.state.rateConversationModal; rateConversationModal.rating3 = 1; this.setState({ rateConversationModal }) }}><Isvg src={starIcon} /></button>}
                                    {this.state.rateConversationModal.rating3 && this.state.rateConversationModal.rating3 >= 2 ? <button onClick={() => { let rateConversationModal = this.state.rateConversationModal; rateConversationModal.rating3 = 2; this.setState({ rateConversationModal }) }}><Isvg src={fullStarIcon} /></button> : <button onClick={() => { let rateConversationModal = this.state.rateConversationModal; rateConversationModal.rating3 = 2; this.setState({ rateConversationModal }) }}><Isvg src={starIcon} /></button>}
                                    {this.state.rateConversationModal.rating3 && this.state.rateConversationModal.rating3 >= 3 ? <button onClick={() => { let rateConversationModal = this.state.rateConversationModal; rateConversationModal.rating3 = 3; this.setState({ rateConversationModal }) }}><Isvg src={fullStarIcon} /></button> : <button onClick={() => { let rateConversationModal = this.state.rateConversationModal; rateConversationModal.rating3 = 3; this.setState({ rateConversationModal }) }}><Isvg src={starIcon} /></button>}
                                    {this.state.rateConversationModal.rating3 && this.state.rateConversationModal.rating3 >= 4 ? <button onClick={() => { let rateConversationModal = this.state.rateConversationModal; rateConversationModal.rating3 = 4; this.setState({ rateConversationModal }) }}><Isvg src={fullStarIcon} /></button> : <button onClick={() => { let rateConversationModal = this.state.rateConversationModal; rateConversationModal.rating3 = 4; this.setState({ rateConversationModal }) }}><Isvg src={starIcon} /></button>}
                                    {this.state.rateConversationModal.rating3 && this.state.rateConversationModal.rating3 >= 5 ? <button onClick={() => { let rateConversationModal = this.state.rateConversationModal; rateConversationModal.rating3 = 5; this.setState({ rateConversationModal }) }}><Isvg src={fullStarIcon} /></button> : <button onClick={() => { let rateConversationModal = this.state.rateConversationModal; rateConversationModal.rating3 = 5; this.setState({ rateConversationModal }) }}><Isvg src={starIcon} /></button>}
                                </div>
                                <FormGroup>
                                    <Input type="textarea" placeholder={'Comment'.translate(this.props.lang)} value={this.state.rateConversationModal.comment} onChange={(e) => { let rateConversationModal = this.state.rateConversationModal; rateConversationModal.comment = e.target.value; this.setState({ rateConversationModal }) }} />
                                </FormGroup>

                            </ModalBody>
                            <ModalFooter>
                                <Button onClick={() => {

                                    this.setState({
                                        rateConversationModal: null
                                    }, () => {
                                        if (typeof window !== 'undefined') {
                                            window.location.href = '/chat'
                                        }
                                    })
                                }

                                } color="primary">{this.state.rateConversationModal.rating ? 'Rate and close'.translate(this.props.lang) : 'Close'.translate(this.props.lang)}</Button>
                                <Button disabled={this.state.rateConversationModal.rating1 === 0 || this.state.rateConversationModal.rating2 === 0 || this.state.rateConversationModal.rating3 === 0} color="primary" onClick={() => {
                                    fetch(API_ENDPOINT + '/data/conversationRatings/new', {
                                        method: 'POST',
                                        headers: {
                                            'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage?.getItem('authToken')}` : null,
                                            'content-type': 'application/json'
                                        },
                                        body: JSON.stringify(this.state.rateConversationModal)
                                    }).then(res => res.json()).then((result) => {

                                        this.setState({
                                            rateConversationModal: null,
                                        }, () => {
                                            if (typeof window !== 'undefined') {
                                                window.location.href = '/chat'
                                            }
                                        })

                                    }).catch((err) => {
                                    })
                                }}>{'Rate'.translate(this.props.lang)}</Button>
                            </ModalFooter>

                        </Modal>
                        :
                        null
                }
                {
                    this.state.rateConversationInfoModal ?
                        <InfoModal
                            isOpen={this.state.rateConversationInfoModal}

                            toggle={() => this.setState({ rateConversationInfoModal: null })}
                            header={'Info'.translate(this.props.lang)}
                            info={'You rated the conversation'.translate(this.props.lang)}
                            buttons={[
                                <Button color='primary' onClick={() => {
                                    this.setState({
                                        rateConversationInfoModal: null,
                                        loading: null
                                    }, () => {
                                        if (typeof window !== 'undefined') {
                                            window.location.href = '/chat'
                                        }

                                    })


                                }}>{'Ok'.translate(this.props.lang)}</Button>
                            ]}
                        />

                        :

                        null
                }

            </div >
        );
    }
}

const selector = formValueSelector('modalForm');

export default connect(state => {
    return { group: selector(state, 'group') }

}, {
    changeSubgroup: value => change("modalForm", "subgroup", value),

})(Page(Chat));
