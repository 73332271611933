import React, { Component } from 'react';
import Page from '../../containers/page';

import {
    Container,
    Row,
    Col,
} from 'reactstrap';

import Isvg from 'react-inlinesvg';

import { getSearchParams } from '../../helpers/linkHelper';
import { Pie, Bar } from 'react-chartjs-2';
import downloadIcon from '../../assets/svg/download.svg';


import CanvasJSReact from '../../assets/canvasjs.react';
var CanvasJS = CanvasJSReact.CanvasJS;
var CanvasJSChart = CanvasJSReact.CanvasJSChart;

class DataUsage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            ...props.initialData,
            form: false,
            useState: false,
            params: {
                entries: 10,
                page: 0
            },
            items: [],
            groups: [],
            subgroups: [],
            total: 0,
            loading: true
        };
    }


    get = () => {
        this.props.updateMeta(this.props.generateSeoTags(this.state, this.props.lang));

        for (let i = 0; i < this.props.loadData.length; i++) {
            this.props.loadData[i](window.fetch, this.props.lang, this.props[0].match, this.state.useState ? this.state.params : getSearchParams(this.props[0].location.search, { entries: 10, page: 0, group: this.props.selectedGroup })).then((data) => {
                this.setState({
                    ...data,
                    loading: null
                }, () => {
                    this.props.updateMeta(this.props.generateSeoTags(this.state, this.props.lang));
                })
            })
        }

        for (let i = 0; i < this.props.loadDataWithQuery.length; i++) {
            this.props.loadDataWithQuery[i](window.fetch, this.props.lang, this.props[0].match, this.state.useState ? this.state.params : getSearchParams(this.props[0].location.search, { entries: 10, page: 0, group: this.props.selectedGroup })).then((data) => {
                this.setState({
                    ...data,
                    loading: null
                }, () => {
                    this.props.updateMeta(this.props.generateSeoTags(this.state, this.props.lang));
                })
            })
        }

    }

    componentDidMount() {
        this.get()
    }

    componentDidUpdate(prevProps) {
        if (this.props.selectedGroup && prevProps.selectedGroup != this.props.selectedGroup) {
            this.get()
        }
    }


    render() {


        return (
            <div className={this.props.autoHideSidebar ? "dashboard dashboard-auto-hide" : "dashboard"}>

                <Container fluid>
                    <Row>
                        <Col lg="12">
                            <div className="panel">
                                <div className="chartjs-wrapper">
                                    <button onClick={() => {
                                        console.log(this.dataUsageChart)
                                        const url = this.dataUsageChart.toBase64Image();
                                        console.log(url)
                                        var link = document.createElement("a");
                                        link.download = `download.png`;
                                        link.target = "_blank";

                                        // Construct the URI
                                        link.href = url;
                                        document.body.appendChild(link);
                                        link.click();

                                    }} className="chartjs-download-button"><Isvg src={downloadIcon} />
                                        <div className='chartjs-download-button-tooltip'>{'Download'.translate(this.props.lang)}</div>
                                    </button>
                                    <Pie
                                        ref={(node) => this.dataUsageChart = node}
                                        options={{
                                            animation: {
                                                duration: 0
                                            },
                                            resposive: true,
                                            maintainAspectRatio: false,
                                            plugins: {
                                                tooltip: {
                                                    callbacks: {
                                                        label: function (tooltipItem, data) {
                                                            return tooltipItem.label
                                                        }
                                                    }
                                                },

                                                title: {
                                                    display: true,
                                                    padding: 20,
                                                    font: {
                                                        size: 24,
                                                    },
                                                    text: 'Data used'.translate(this.props.lang) + ' ( ' + this.state.items.size + ' )'
                                                },

                                                legend: {
                                                    position: 'bottom',
                                                    labels: {
                                                        usePointStyle: true,
                                                    },
                                                }
                                            }
                                        }}
                                        data={{
                                            labels: [
                                                'Cases'.translate(this.props.lang) + ' ' + this.state.items.clinicCaseData,
                                                'Chat'.translate(this.props.lang) + ' ' + this.state.items.clinicChat,
                                                'Clinic data'.translate(this.props.lang) + ' ' + this.state.items.clinicData,
                                                'Support'.translate(this.props.lang) + ' ' + this.state.items.clinicSupport,
                                                'Patient Documents'.translate(this.props.lang) + ' ' + this.state.dataUsedModal.clinicPatientDocuments,
                                            ],
                                            datasets: [
                                                {
                                                    data: [this.state.items.clinicCaseDataBytes, this.state.items.clinicChatBytes, this.state.items.clinicDataBytes, this.state.items.clinicSupportBytes, this.state.dataUsedModal.clinicPatientDocumentsBytes],
                                                    backgroundColor: [
                                                        '#4f81bc',
                                                        '#c0504e',
                                                        '#9bbb58',
                                                        '#23bfaa',
                                                        '#8064a2',
                                                    ],
                                                    borderColor: [
                                                        '#4f81bc',
                                                        '#c0504e',
                                                        '#9bbb58',
                                                        '#23bfaa',
                                                        '#8064a2',
                                                    ],
                                                    borderWidth: 1,
                                                }]

                                        }}
                                    ></Pie>
                                </div>
                                {/*
                                <CanvasJSChart options={{
                                    animationEnabled: true, exportEnabled: true,
                                    title: {
                                        text: 'Data used'.translate(this.props.lang) + ' ( ' + this.state.items.size + ' )'
                                    },

                                    toolTip: {
                                        shared: true
                                    },
                                    legend: {
                                        cursor: "pointer",
                                        verticalAlign: 'bottom',
                                        horizontalAlign: 'center',
                                        itemclick: () => { }
                                    },
                                    data: [
                                        {
                                            type: "pie",
                                            showInLegend: true,
                                            dataPoints: [
                                                {
                                                    name: 'Cases'.translate(this.props.lang), label: this.state.items.clinicCaseData, y: this.state.items.clinicCaseDataBytes
                                                },
                                                {
                                                    name: 'Chat'.translate(this.props.lang), label: this.state.items.clinicChat, y: this.state.items.clinicChatBytes
                                                },
                                                {
                                                    name: 'Clinic data'.translate(this.props.lang), label: this.state.items.clinicData, y: this.state.items.clinicDataBytes
                                                },
                                                {
                                                    name: 'Support'.translate(this.props.lang), label: this.state.items.clinicSupport, y: this.state.items.clinicSupportBytes
                                                }
                                            ]
                                        }
                                    ]

                                }}
                                />
                            */}

                            </div>
                        </Col>
                    </Row>

                </Container>


            </div>
        );
    }
}

export default Page(DataUsage);