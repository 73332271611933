import React from 'react';

import {
    Modal, ModalHeader, ModalBody, ModalFooter, Button, Label
} from 'reactstrap';

import FormBuilder from './forms/formBuilder';
import { required, emailAddress, dateValidate, duplicateEmail } from './forms/validation'

import { formValueSelector, change } from "redux-form"; // ES6
import { connect } from "react-redux";


import { API_ENDPOINT } from '../constants'
function parseJSON(response) {
    return response.json().then(json => {
        return {
            result: json,
            status: response.status
        }
    })
}



/**
* A model for entering patient data is displayed if not all required data is available
* @author Milan Stanojevic
*/
class PatientAccountModal extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            groupObj: null
        }
    }

    componentDidMount() {
        fetch(API_ENDPOINT + '/languages', {
            method: 'POST',
            headers: {
                'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage.getItem('authToken')}` : null,
                'content-type': 'application/json'
            },
            body: JSON.stringify({
                sortType: 1,
                sortField: 'key',
                query: {}
            })
        }).then(parseJSON).then(({ result, status }) => {
            if (status >= 200 && status < 300)
                this.setState({
                    languages: result
                })


        })

        this.getGroupId()

    }

    componentDidUpdate(prevProps) {
        if (prevProps.sex != this.props.sex) {
            if (this.props.sex == 0) {
                this.props.changeSex(null)
            }
        }

        if (prevProps.socialSecurityNumber !== this.props.socialSecurityNumber) {
            if ((this.props.country === 'denmark' && this.props.socialSecurityNumber?.length === 10) || this.props.socialSecurityNumber?.length === 12) {
                let dateOfBirth = this.props.getDateOfBirthFromSSN(this.props.socialSecurityNumber);
                if (dateOfBirth && this.props.changeDateOfBirth) {
                    this.props.changeDateOfBirth(dateOfBirth)
                    if (!this.state.disabledDateOfBirth) { this.setState({ disabledDateOfBirth: true }) }
                } else {
                    if (this.state.disabledDateOfBirth) { this.setState({ disabledDateOfBirth: false }) }
                }
            } else {
                if (this.state.disabledDateOfBirth) { this.setState({ disabledDateOfBirth: false }) }
            }
        }

        if (this.props.email && prevProps.email && this.props.email !== prevProps.email) {
            this.setState({
                emailError: false
            })
        }

    }

    /**
    * Update patient's profile
    * @author Milan Stanojevic
    */
    update = (data) => {
        // form is not updated
        data.language = this.props.uData.language;

        fetch(API_ENDPOINT + '/users/account/update', {
            method: 'POST',
            headers: {
                'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage.getItem('authToken')}` : null,
                'content-type': 'application/json'
            },
            body: JSON.stringify(data)
        }).then(parseJSON).then(({ result, status }) => {
            if (result.error) {
                this.setState({ error: result.error.translate(this.props.lang) })
            } else if (result.emailError) {
                this.setState({
                    emailError: result.emailError.translate(this.props.lang)
                })
            } else {
                this.setState({
                    message: result.message
                })
                this.props.verifyUser();
                // window.location.reload()
                //this.props.toggle();
            }

        })


    }

    updateLanguage = (data) => {
        this.setState(
            {
                loadLang: true
            },
            () => {
                fetch(API_ENDPOINT + "/users/account/update-userdata", {
                    method: "POST",
                    headers: {
                        Authorization:
                            typeof localStorage !== "undefined"
                                ? `Bearer ${localStorage.getItem("authToken")}`
                                : null,
                        "content-type": "application/json",
                    },
                    body: JSON.stringify(data),
                })
                    .then(parseJSON)
                    .then(({ result, status }) => {
                        if (result) {
                            this.props.verifyUser()


                        }
                    });
            }
        );
    };
    getGroupId = () => {
        let groupAlias;
        if (typeof window != 'undefined' && localStorage.groupAlias) {
            groupAlias = localStorage.groupAlias;
        } else if (this.props[0].match.params.alias) {
            groupAlias = this.props[0].match.params.alias;
        }

        if (groupAlias) {


            fetch(API_ENDPOINT + "/data/clinic-group/info", {
                method: "POST",
                headers: {
                    Authorization:
                        typeof localStorage !== "undefined"
                            ? `Bearer ${localStorage.getItem("authToken")}`
                            : null,
                    "content-type": "application/json",
                },
                body: JSON.stringify({ alias: groupAlias }),
            }).then(parseJSON).then(({ result, status }) => {
                if (result && result._id) {
                    this.setState({ groupObj: result })
                }
            });
        }

    }

    render() {
        const { groupObj } = this.state;
        let showPrivacyPolicyCheckbox = false;
        if (this.state.groupObj && this.state.groupObj._id && this.props.uData && (this.props.uData.userLevel === 1 || this.props.uData.userLevel === 2 || (this.props.uData.userLevel === 20 && typeof window != 'undefined' && localStorage?.getItem?.('profile') === 'patient')) && (!this.props.uData.privacyPolicy || !this.props.uData.privacyPolicy[String(this.state.groupObj?._id)])) {
            showPrivacyPolicyCheckbox = true
        }
        let label2;

        if (showPrivacyPolicyCheckbox) {
            let privacyPolicy = groupObj.privacyPolicy && groupObj.privacyPolicy.file ? `${API_ENDPOINT}${groupObj.privacyPolicy.file}` : null;

            label2 =
                <div className='privacy-policy-field-label-wrap' style={{ marginTop: 20 }}>
                    <p style={{ fontSize: 14 }}> {'You can read about how we handle your personal data:'.translate(this.props.lang)} {privacyPolicy ? <a href={privacyPolicy} target="_blank">{'Privacy Policy'.translate(this.props.lang)}</a> : 'Privacy Policy'.translate(this.props.lang)}.</p>

                </div>
        }
        if (typeof window !== 'undefined' && localStorage?.hidePatientModal) {
            return null;
        }

        return (
            <Modal isOpen={this.props.patientAccountModal} centered zIndex={1060}>

                <ModalHeader style={{ display: 'block', width: '100%' }}>
                    <div style={{ display: 'flex', width: '100%', justifyContent: 'space-between', alignItems: 'center' }}>
                        <p style={{ width: '50%', marginBottom: 0 }}>{'My profile'.translate(this.props.lang)}</p>
                        {!this.props.secondaryLanguageDisabled ? <select className='select-lang-patient-account-modal'
                            value={this.props.uData && this.props.uData.language}
                            onChange={(e) => {
                                this.updateLanguage({ language: e.target.value });

                            }}
                        >

                            {this.state.languages &&
                                this.state.languages.map((item, idx) => {
                                    return (
                                        <option key={idx} value={item?._id}>
                                            {item.name?.translate(this.props.lang)}
                                        </option>
                                    );
                                })}

                        </select> : null}
                    </div>



                </ModalHeader>
                <ModalBody className="bankid-modal">
                    {
                        this.props.uData ?
                            <FormBuilder selectedGroup={this.props.selectedGroup} selectedClinic={this.props.selectedClinic} countryTwoLetter={this.props.countryTwoLetter} country={this.props.country} dateFormat={this.props.dateFormat}
                                getStringDateTs={this.props.getStringDateTs}
                                lang={this.props.lang}
                                onSubmit={(data) => {
                                    if (showPrivacyPolicyCheckbox) {
                                        if (!data.privacyPolicy) {
                                            data.privacyPolicy = {}
                                        }
                                        if (groupObj && groupObj._id)
                                            data.privacyPolicy[groupObj._id] = true;
                                    }
                                    this.update(data)
                                }}
                                initialValues={this.props.uData}
                                toggle={() => this.setState({ form: null })}
                                invalidMessage={"Please fill in required fields".translate(this.props.lang)}
                                buttonText={showPrivacyPolicyCheckbox ? 'Agree to Terms of Service and continue'.translate(this.props.lang) : 'Save'.translate(this.props.lang)}
                                buttonsWrapClassName={'empty'}
                                afterTextComponent={label2}
                                additionalAdditionalBeforeButton={
                                    <div style={{ width: '100%' }}>
                                        {
                                            this.state.emailError || this.state.error ? (
                                                <p style={{ color: 'red' }}>{this.state.emailError || this.state.error}</p>
                                            )
                                                :
                                                null
                                        }
                                    </div>
                                }
                                additionalButton={
                                    this.props.uData && this.props.uData.userLevel == 20 ?
                                        <Button color="danger" onClick={() => {
                                            let urlSplit = this.props[0].location.pathname.split('/');
                                            let url = '/' + urlSplit[1];
                                            this.props[0].history.push(url)
                                        }}>{'Cancel'.translate(this.props.lang)}</Button>
                                        :
                                        <Button color="danger" onClick={() => this.props.signOut()}>{'Logout'.translate(this.props.lang)}</Button>
                                }
                                fields={[
                                    {
                                        type: 'row',
                                        children: [
                                            {
                                                type: 'col',
                                                width: {
                                                    lg: 12,
                                                    sm: 12,
                                                    xs: 12
                                                },
                                                children: [
                                                    {
                                                        type: 'socialSecurityNumber',
                                                        disabled: true,
                                                        name: 'socialSecurityNumber',
                                                        label: this.props.country === 'uk' ? "Date of birth".translate(this.props.lang) : 'Social security number'.translate(this.props.lang),
                                                        validate: [required('Social security number is required!'.translate(this.props.lang))]

                                                    },
                                                ]
                                            },

                                            /*{
                                                type: 'col',
                                                width: {
                                                    lg: 3,
                                                    sm: 12,
                                                    xs: 12
                                                },
                                                children: [
                                                    {
                                                        type: 'text',
                                                        name: 'userData.title',
                                                        label: 'Title'.translate(this.props.lang),
                                                        disabled: this.props.alias
                                                    },
                                                ]
                                            },*/
                                            {
                                                type: 'col',
                                                width: {
                                                    lg: 12,
                                                    sm: 12,
                                                    xs: 12
                                                },
                                                children: [
                                                    {
                                                        type: 'text',
                                                        name: 'userData.name',
                                                        label: 'First and last name'.translate(this.props.lang),
                                                        // disabled: this.props.alias,
                                                        disabled: this.props.uData && this.props.uData.userData && this.props.uData.userData.name ? true : false,
                                                        validate: [required('First and last name is required!'.translate(this.props.lang))]

                                                    },
                                                ]
                                            },
                                            {
                                                type: 'col',
                                                width: {
                                                    lg: 12,
                                                    sm: 12,
                                                    xs: 12
                                                },
                                                children: [
                                                    {
                                                        type: 'select',
                                                        name: 'userData.sex',
                                                        labelInfo: "Your biological sex is needed for proper treatment".translate(this.props.lang),
                                                        label: 'Sex'.translate(this.props.lang),
                                                        values: [{ value: 0, name: 'Select'.translate(this.props.lang) }, { value: 'male', name: 'Male'.translate(this.props.lang) }, { value: 'female', name: 'Female'.translate(this.props.lang) }],
                                                        // validate: [required('Sex is required!'.translate(this.props.lang))]
                                                    },
                                                ]
                                            },
                                            this.props.showDateOfBirth ?
                                                {
                                                    type: 'col',
                                                    width: {
                                                        lg: 12,
                                                        sm: 12,
                                                        xs: 12
                                                    },
                                                    children: [
                                                        {
                                                            type: 'date-of-birth',
                                                            dateFormat: this.props.dateFormat,
                                                            name: 'dateOfBirth.date',
                                                            disabled: this.state.disabledDateOfBirth,
                                                            label: 'Date of birth'.translate(this.props.lang),
                                                            validate: [required('Date of birth is required!'.translate(this.props.lang)), dateValidate('Invalid date!'.translate(this.props.lang))]

                                                        },
                                                    ]
                                                } : null,
                                            {
                                                type: 'col',
                                                width: {
                                                    lg: 12,
                                                    sm: 12,
                                                    xs: 12
                                                },
                                                children: [
                                                    {
                                                        type: 'email',
                                                        name: 'email',
                                                        label: 'Email address'.translate(this.props.lang),
                                                        validate: [required('Email address is required!'.translate(this.props.lang)), emailAddress('Invalid email address!'.translate(this.props.lang)), duplicateEmail(this.state.emailError, this.state.emailError)]
                                                    },
                                                ]
                                            },
                                            {
                                                type: 'col',
                                                width: {
                                                    lg: 12,
                                                    sm: 12,
                                                    xs: 12
                                                },
                                                children: [
                                                    {
                                                        type: 'phone',
                                                        name: 'phone',
                                                        label: 'Phone number'.translate(this.props.lang),
                                                        validate: [required('Phone number is required!'.translate(this.props.lang))]
                                                    },
                                                ]
                                            },
                                            {
                                                type: 'col',
                                                width: {
                                                    lg: 12,
                                                    sm: 12,
                                                    xs: 12
                                                },
                                                children: [
                                                    {
                                                        type: 'text',
                                                        name: 'street',
                                                        label: 'Street'.translate(this.props.lang),
                                                        validate: [required('Street is required!'.translate(this.props.lang))]
                                                    },
                                                ]
                                            },
                                            {
                                                type: 'col',
                                                width: {
                                                    lg: 3,
                                                    sm: 12,
                                                    xs: 12
                                                },
                                                children: [
                                                    {
                                                        type: 'text',
                                                        name: 'zip',
                                                        // pattern: new RegExp(/[0-9 ]+/i),
                                                        label: 'Postal code'.translate(this.props.lang),
                                                        validate: [required('Postal code is required!'.translate(this.props.lang))]
                                                    },
                                                ]
                                            },
                                            {
                                                type: 'col',
                                                width: {
                                                    lg: 9,
                                                    sm: 12,
                                                    xs: 12
                                                },
                                                children: [
                                                    {
                                                        type: 'text',
                                                        name: 'city',
                                                        // pattern: new RegExp(/^[a-zA-Z \u00c0-\u017e]$/i),
                                                        pattern: new RegExp(/^[a-zA-Z \u00c0-\u017e\u0370-\u03FF ]$/i),
                                                        label: 'City'.translate(this.props.lang),
                                                        validate: [required('City is required!'.translate(this.props.lang))]
                                                    },
                                                ]
                                            },
                                            // {
                                            //     type: "col",
                                            //     width: {
                                            //         lg: 12,
                                            //         sm: 12,
                                            //         xs: 12,
                                            //     },
                                            //     style: { padding: 0, marginTop: 15 },
                                            //     children: [
                                            //         {
                                            //             type: "col",
                                            //             width: {
                                            //                 lg: 12,
                                            //                 sm: 12,
                                            //                 xs: 12,
                                            //             },
                                            //             children: [
                                            //                 {
                                            //                     type: "h6",
                                            //                     text: 'Related parties login'.translate(this.props.lang)
                                            //                 },
                                            //             ],
                                            //         },
                                            //         {
                                            //             type: "col",
                                            //             width: {
                                            //                 lg: 12,
                                            //                 sm: 12,
                                            //                 xs: 12,
                                            //             },

                                            //             children: [
                                            //                 {
                                            //                     type: "hr",
                                            //                 },
                                            //             ],
                                            //         },
                                            //     ],
                                            // },

                                            // {
                                            //     type: "col",
                                            //     width: {
                                            //         lg: 6,
                                            //         sm: 12,
                                            //         xs: 12,
                                            //     },
                                            //     children: [
                                            //         {
                                            //             type: "text",
                                            //             name: "userData.relatedParties.name",
                                            //             label: "Name".translate(
                                            //                 this.props.lang
                                            //             ),
                                            //         },
                                            //     ],
                                            // },
                                            // {
                                            //     type: "col",
                                            //     width: {
                                            //         lg: 6,
                                            //         sm: 12,
                                            //         xs: 12,
                                            //     },
                                            //     children: [
                                            //         {
                                            //             type: "text",
                                            //             name: "userData.relatedParties.relations",
                                            //             label: "Relations".translate(
                                            //                 this.props.lang
                                            //             ),
                                            //         },
                                            //     ],
                                            // },

                                            // {
                                            //     type: "col",
                                            //     width: {
                                            //         lg: 6,
                                            //         sm: 12,
                                            //         xs: 12,
                                            //     },
                                            //     style: { padding: 0 },
                                            //     children: [
                                            //         {
                                            //             type: "col",
                                            //             width: {
                                            //                 lg: 12,
                                            //                 sm: 12,
                                            //                 xs: 12,
                                            //             },
                                            //             children: [
                                            //                 {
                                            //                     type: "phone",
                                            //                     name: "userData.relatedParties.phone",
                                            //                     label: "Phone 1".translate(
                                            //                         this.props.lang
                                            //                     ),
                                            //                 },
                                            //             ],
                                            //         },
                                            //         {
                                            //             type: "col",
                                            //             width: {
                                            //                 lg: 12,
                                            //                 sm: 12,
                                            //                 xs: 12,
                                            //             },

                                            //             children: [
                                            //                 {
                                            //                     type: "phone",
                                            //                     name: "userData.relatedParties.phone2",
                                            //                     label: "Phone 2".translate(
                                            //                         this.props.lang
                                            //                     ),
                                            //                 },
                                            //             ],
                                            //         },
                                            //     ],
                                            // },


                                            // {
                                            //     type: "col",
                                            //     width: {
                                            //         lg: 6,
                                            //         sm: 12,
                                            //         xs: 12,
                                            //     },
                                            //     children: [
                                            //         {
                                            //             type: "textarea",
                                            //             name: "userData.otherInformations",
                                            //             height: 152,
                                            //             label: "Other information".translate(
                                            //                 this.props.lang
                                            //             ),
                                            //         },
                                            //     ],
                                            // },
                                            showPrivacyPolicyCheckbox ?
                                                {
                                                    type: "col",
                                                    width: {
                                                        lg: 12,
                                                        sm: 12,
                                                        xs: 12,
                                                    },
                                                    children: [
                                                        {
                                                            type: "privacy-policy-checkbox",
                                                            name: `privacyPolicy.${groupObj?._id}`,
                                                            groupData: groupObj,
                                                            lang: this.props.lang,
                                                            // validate: [required('Privacy Policy is required!'.translate(this.props.lang))],
                                                            firstLogin: true,
                                                        },
                                                    ],
                                                }
                                                :
                                                {
                                                    type: 'empty'
                                                },


                                            // {
                                            //     type: 'col',
                                            //     width: {
                                            //         lg: 6,
                                            //         sm: 12,
                                            //         xs: 12
                                            //     },
                                            //     children: [
                                            //         {
                                            //             type: 'select',
                                            //             name: 'language',
                                            //             label: 'Language'.translate(this.props.lang),
                                            //             values: this.state.languages.map(item => { return { name: item.name, value: item._id } }),
                                            //         },
                                            //     ]
                                            // },


                                            // {
                                            //     type: 'col',
                                            //     width: {
                                            //         lg: 6,
                                            //         sm: 12,
                                            //         xs: 12
                                            //     },
                                            //     children: [
                                            //         {
                                            //             type: 'checkbox',
                                            //             name: 'notifications.email',
                                            //             afterText: 'E-mail notification'.translate(this.props.lang),
                                            //         },
                                            //         {
                                            //             type: 'checkbox',
                                            //             name: 'notifications.sms',
                                            //             afterText: 'SMS notification'.translate(this.props.lang),
                                            //         },

                                            //     ]
                                            // },




                                        ]
                                    }
                                ]}
                            ></FormBuilder>
                            :
                            null

                    }
                    <p className="error-message">{this.state.error}</p>
                </ModalBody>
            </Modal>
        )
    }
}

// export default PatientAccountModal;

const selector2 = formValueSelector("form");


export default connect(
    (state) => {
        return {
            sex: selector2(state, 'userData.sex'),
            socialSecurityNumber: selector2(state, 'socialSecurityNumber'),
            dateOfBirth: selector2(state, 'dateOfBirth.date'),
            email: selector2(state, 'email')
        };
    },
    {
        changeSex: (value) => change("form", "userData.sex", value),
        changeDateOfBirth: value => change("form", "dateOfBirth.date", value),

    }
)(PatientAccountModal);