import React, { Component } from "react";
import Page from "../../containers/page";
import xIcon from '../../assets/svg/x.svg';

import {
    Container,
    Row,
    Col,
    Modal,
    ModalHeader,
    ModalBody,
    ModalFooter,
    Table,
    Input,
    FormGroup,
    Label,
    Button,
} from "reactstrap";

import Isvg from "react-inlinesvg";
import ErrorModal from '../../components/errorModal';
import ListBuilder from "../../components/listBuilder";
import {
    getSearchParams,
    generateSearchLink,
    generateSearchLinkMultiple,
} from "../../helpers/linkHelper";
import { API_ENDPOINT } from "../../constants";
import { formValueSelector, change } from "redux-form"; // ES6
import { connect } from "react-redux";
import addIcon from "../../assets/svg/add-icon.svg";
import Usage from "../../assets/svg/usage.svg";
import moment from 'moment';
import DatePicker from '../../components/forms/fields/datePicker';
import { Pie, Bar } from 'react-chartjs-2';
import downloadIcon from '../../assets/svg/download.svg';
import SelectMainClinicGroupForTransferr from '../../components/selectMainClinicGroupForTransfer'


function parseJSON(response) {
    return response.json().then(json => {
        return {
            result: json,
            status: response.status
        }
    })
}

function parseText(response) {
    return response.text().then(text => {
        return {
            result: text,
            status: response.status
        }
    })
}

class MainClinicGroupsExport extends Component {
    constructor(props) {
        super(props);

        this.state = {
            ...props.initialData,
            useState: false,
            params: {
                entries: 10,
                page: 0,
            },
            items: [],
            groups: [],
            total: 0,
            loading: true,
            startDateSms: moment.unix(Math.floor(new Date().getTime() / 1000)).format('YYYY-MM-DD'),
            endDateSms: moment.unix(Math.floor(new Date().getTime() / 1000)).format('YYYY-MM-DD'),
            showAllUsersModal: null,
            mainClinicGroups: []


        };
    }

    get = () => {
        this.props.updateMeta(
            this.props.generateSeoTags(this.state, this.props.lang)
        );

        for (let i = 0; i < this.props.loadData.length; i++) {
            this.props.loadData[i](
                window.fetch,
                this.props.lang,
                this.props[0].match,
                this.state.useState
                    ? this.state.params
                    : getSearchParams(this.props[0].location.search, {
                        entries: 10,
                        page: 0,
                    })
            ).then((data) => {
                this.setState(
                    {
                        ...data,
                        loading: null,
                    },
                    () => {
                        this.props.updateMeta(
                            this.props.generateSeoTags(this.state, this.props.lang)
                        );
                    }
                );
            });
        }

        for (let i = 0; i < this.props.loadDataWithQuery.length; i++) {
            this.props.loadDataWithQuery[i](
                window.fetch,
                this.props.lang,
                this.props[0].match,
                this.state.useState
                    ? this.state.params
                    : getSearchParams(this.props[0].location.search, {
                        entries: 10,
                        page: 0,
                        startDateSms: this.props.getStringDateTs(Math.floor(new Date(this.state.startDateSms).getTime() / 1000), 'YYYY-MM-DD 00:00:01')/*moment.unix(Math.floor(new Date(this.state.startDateSms).getTime() / 1000)).format('YYYY-MM-DD 00:00:01')*/,
                        endDateSms: this.props.getStringDateTs(Math.floor(new Date(this.state.endDateSms).getTime() / 1000), 'YYYY-MM-DD 23:59:59')/*moment.unix(Math.floor(new Date(this.state.endDateSms).getTime() / 1000)).format('YYYY-MM-DD 23:59:59')*/,
                        group: this.state.group,
                        selectedMainClinicGroups: this.state.selectedMainClinicGroups
                    })
            ).then((data) => {
                let dataToSet = { ...data }
                if (dataToSet.page || dataToSet.items || dataToSet.total) {
                    dataToSet.loading = null;
                    // console.log(dataToSet.page || dataToSet.items || dataToSet.total)
                    let checkFilter = '';
                    if (dataToSet.resultFilter) {
                        checkFilter = String(dataToSet.resultFilter)
                        delete dataToSet.resultFilter;
                    }
                    let search = this.props[0].location.search;
                    let serachParams = getSearchParams(search);
                    let searchParamsFilter = '';
                    if (serachParams.filter) {
                        searchParamsFilter = serachParams.filter;
                    }
                    if (searchParamsFilter != checkFilter) {
                        delete dataToSet.page;
                        delete dataToSet.items;
                        delete dataToSet.total;
                        delete dataToSet.loading;
                    }
                }

                this.setState(
                    {
                        ...dataToSet,
                        // loading: null,
                    },
                    () => {
                        this.props.updateMeta(
                            this.props.generateSeoTags(this.state, this.props.lang)
                        );
                    }
                );
            });
        }
    };


    componentDidMount() {
        if (typeof window !== "undefined") {
            window.scrollTo(0, 0);
        }

        this.get();
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevProps[0].location.search != this.props[0].location.search) {
            this.setState(
                {
                    loading: true,
                },
                () => {
                    this.get();
                }
            );
        }
    }

    updateSort = (field, type) => {
        if (this.state.useState) {
            let state = this.state.params;
            state.sortField = field;
            state.sortType = type;
            this.setState({
                params: state,
            });
        } else {
            this.props[0].history.push(
                generateSearchLinkMultiple(
                    this.props[0].location.search,
                    { entries: 10, page: 0 },
                    [
                        { name: "sortField", value: field },
                        { name: "sortType", value: type },
                    ],
                    false
                )
            );
        }
    };

    updateParams = (name, value, restart = false) => {
        if (this.state.useState) {
            if (restart) {
                let obj = {
                    entries: 10,
                    page: 0,
                };
                obj[name] = value;
                this.setState({ params: obj });
            } else {
                let obj = this.state.params;
                obj[name] = value;
                this.setState({ params: obj });
            }
        } else {
            this.props[0].history.push(
                generateSearchLink(
                    this.props[0].location.search,
                    { entries: 10, page: 0 },
                    name,
                    value,
                    restart
                )
            );
        }
    };
    updateMultipleParams = (fields, restart = false) => {
        if (this.state.useState) {
            if (restart) {
                let obj = {
                    entries: 10,
                    page: 0,
                };
                for (let i = 0; i < fields.length; i++) {
                    obj[fields[i].name] = fields[i].value;
                }
                this.setState({ params: obj });
            } else {
                let obj = this.state.params;
                for (let i = 0; i < fields.length; i++) {
                    obj[fields[i].name] = fields[i].value;
                }
                this.setState({ params: obj });
            }
        } else {
            this.props[0].history.push(
                generateSearchLinkMultiple(
                    this.props[0].location.search,
                    { entries: 10, page: 0 },
                    fields,
                    restart
                )
            );
        }
    };

    exportClinicsStatistic = (listOfGroups, dateFrom, dateTo, exportAll, email) => {
        console.log(listOfGroups, 'listOfGroups', this.state.clinicIds);
        fetch(API_ENDPOINT + "/data/main-clinic-groups/statistic/export", {
            method: "POST",
            headers: {
                Authorization:
                    typeof localStorage !== "undefined"
                        ? `Bearer ${localStorage.getItem("authToken")}`
                        : null,
                "content-type": "application/json",
            },
            body: JSON.stringify({ group: listOfGroups, dateFrom: dateFrom, dateTo: dateTo, exportAll: listOfGroups && listOfGroups.length > 1 ? true : exportAll, email: email, dataStorage: true, clinicIds: this.state.clinicIds }),
        }).then(res => res.json()).then((result) => {
            if (result) {
                console.log(result.link);
                window.open(API_ENDPOINT + result.link)
                this.setState({
                    exportClinicsModal: false,
                    questionaries: false,
                    listOfGroups: false,
                    emailSendStatistic: '',
                    startDateSms: this.props.getStringDateTs(Math.floor(new Date().getTime() / 1000), 'YYYY-MM-DD')/*moment.unix(Math.floor(new Date().getTime() / 1000)).format('YYYY-MM-DD')*/,
                    endDateSms: this.props.getStringDateTs(Math.floor(new Date().getTime() / 1000), 'YYYY-MM-DD')/*moment.unix(Math.floor(new Date().getTime() / 1000)).format('YYYY-MM-DD')*/
                })
            }


            this.get();

        })
    }

    statisticGrafic = (item) => {
        console.log(item, 'item');
        this.setState({
            selectedMainClinicGroupsGrafic: item
        })
        fetch(API_ENDPOINT + "/data/main-groups/statistic/clinic-groups", {
            method: "POST",
            headers: {
                Authorization:
                    typeof localStorage !== "undefined"
                        ? `Bearer ${localStorage.getItem("authToken")}`
                        : null,
                "content-type": "application/json",
            },
            body: JSON.stringify({ groups: item.clinicGroups }),
        }).then(res => res.json()).then((result) => {
            if (result) {
                this.statisticListGrafic({
                    startDateSms: this.state.startDateSms,
                    endDateSms: this.state.endDateSms,
                    group: item._id
                })

                this.setState({ dataUsedModal: { ...result, _id: item._id }, group: result._id },
                    () => this.get())

            }
        })

    }

    statisticListGrafic = (item) => {
        // console.log(item, 'item');
        fetch(API_ENDPOINT + '/data/main-clinic-groups/data-usage/time', {
            method: 'POST',
            headers: {
                'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage.getItem('authToken')}` : null,
                'content-type': 'application/json'
            },
            body: JSON.stringify(item)
        }).then(parseJSON).then(({ result, status }) => {
            if (status >= 200 && status < 300)
                this.setState({
                    timeData: result.obj,
                    timeDataInterval: result.timeObj
                })

        })
    }

    exportFromGraph = (data) => {
        fetch(API_ENDPOINT + "/data/main-clinic-groups/data-usage/time-export", {
            method: "POST",
            headers: {
                Authorization: typeof localStorage !== "undefined" ? `Bearer ${localStorage.getItem("authToken")}` : null,
                "content-type": "application/json",
            },
            body: JSON.stringify({
                startDateSms: data.startDateSms,
                endDateSms: data.endDateSms,
                group: data.group,
            }),
        }).then((res) => res.json()).then((result) => {
            if (result.link) {
                window.open(API_ENDPOINT + result.link)
            }
        });
    }


    render() {
        let params = {};
        if (this.state.useState) {
            params = this.state.params;
        } else {
            params = getSearchParams(this.props[0].location.search, {
                entries: 10,
                page: 0,
            });
        }

        console.log(this.state.items);

        return (
            <div className={this.props.autoHideSidebar ? "dashboard dashboard-auto-hide" : "dashboard"}>
                <Container fluid>
                    <Row>
                        <Col lg="12">
                            <div className="panel">
                                <div className="panel-header clinic-panel-header">
                                    <h5 className="header-component">
                                        {"Main clinic groups statistic".translate(this.props.lang)}
                                    </h5>
                                    <div className="clinic-group-header-buttons">



                                        <button
                                            className="btn add-button"
                                            onClick={() =>
                                                this.setState({
                                                    exportClinicsModal: true

                                                })
                                            }
                                        >
                                            <Isvg src={addIcon} /> {"Export".translate(this.props.lang)}
                                        </button>
                                    </div>

                                </div>
                                <ListBuilder
                                    lang={this.props.lang}
                                    loading={this.state.loading}
                                    total={this.state.total}
                                    showNumeration={false}
                                    fields={[
                                        { type: "text", name: "name", label: "Group name".translate(this.props.lang), allowSort: true, },

                                    ]}
                                    items={this.state.items.map((item) => {
                                        return {
                                            ...item,
                                            size: item.size != '0' ? <Button color='#fff' style={{ width: 150 }} className="patient-modal-button" onClick={() => this.setState({ dataUsedModal: item })}>{item.size} <Isvg src={Usage} className="pie-icon" /></Button> : item.size,

                                        };
                                    })}
                                    rawItems={this.state.items}
                                    actions={[
                                        {
                                            component: <Isvg src={Usage} className="edit-icon" />,
                                            onClick: (item) => this.statisticGrafic(item),
                                        },
                                        // {
                                        //   component: <Isvg src={exportIcon} className="export-icon" />,
                                        //   onClick: (item) => this.export(item),
                                        // },
                                    ]}
                                    params={params}
                                    sortField={params.sortField}
                                    sortType={params.sortType}
                                    updateSort={this.updateSort}
                                    updateParams={this.updateParams}
                                    updateMultipleParams={this.updateMultipleParams}
                                ></ListBuilder>


                            </div>
                        </Col>
                    </Row>
                </Container>




                {this.state.dataUsedModal ? (
                    <Modal centered isOpen={this.state.dataUsedModal} size="xl">
                        <ModalHeader close={
                            <button type="button" className="close" style={{ position: "absolute", top: "15px", right: "15px" }} onClick={() => this.setState({ dataUsedModal: !this.state.dataUsedModal })}>
                                <Isvg src={xIcon} />
                            </button>}>
                            {this.state.dataUsedModal.name}
                        </ModalHeader>
                        <ModalBody>
                            <Row>
                                <Col lg='12'>
                                    <div className="panel">
                                        <div className="chartjs-wrapper">
                                            <button onClick={() => {
                                                console.log(this.dataUsageChart)
                                                const url = this.dataUsageChart.toBase64Image();
                                                console.log(url)
                                                var link = document.createElement("a");
                                                link.download = `download.png`;
                                                link.target = "_blank";

                                                // Construct the URI
                                                link.href = url;
                                                document.body.appendChild(link);
                                                link.click();

                                            }} className="chartjs-download-button"><Isvg src={downloadIcon} /></button>
                                            <Pie
                                                ref={(node) => this.dataUsageChart = node}
                                                options={{

                                                    resposive: true, maintainAspectRatio: false,
                                                    plugins: {
                                                        tooltip: {
                                                            callbacks: {
                                                                label: function (tooltipItem, data) {
                                                                    return tooltipItem.label
                                                                }
                                                            }
                                                        },

                                                        title: {
                                                            display: true,
                                                            padding: 20,
                                                            font: {
                                                                size: 24,
                                                            },
                                                            text: 'Data used'.translate(this.props.lang) + ' ( ' + this.state.dataUsedModal.size + ' )'
                                                        },

                                                        legend: {
                                                            position: 'bottom',
                                                            labels: {
                                                                usePointStyle: true,
                                                            },
                                                        }
                                                    }
                                                }}
                                                data={{
                                                    labels: [
                                                        'Cases'.translate(this.props.lang) + ' ' + this.state.dataUsedModal.clinicCaseData,
                                                        'Chat'.translate(this.props.lang) + ' ' + this.state.dataUsedModal.clinicChat,
                                                        'Clinic data'.translate(this.props.lang) + ' ' + this.state.dataUsedModal.clinicData,
                                                        'Support'.translate(this.props.lang) + ' ' + this.state.dataUsedModal.clinicSupport,
                                                        'Patient Documents'.translate(this.props.lang) + ' ' + this.state.dataUsedModal.clinicPatientDocuments,
                                                    ],
                                                    datasets: [
                                                        {
                                                            data: [this.state.dataUsedModal.clinicCaseDataBytes, this.state.dataUsedModal.clinicChatBytes, this.state.dataUsedModal.clinicDataBytes, this.state.dataUsedModal.clinicSupportBytes, this.state.dataUsedModal.clinicPatientDocumentsBytes],
                                                            backgroundColor: [
                                                                '#4f81bc',
                                                                '#c0504e',
                                                                '#9bbb58',
                                                                '#23bfaa',
                                                                '#8064a2',
                                                            ],
                                                            borderColor: [
                                                                '#4f81bc',
                                                                '#c0504e',
                                                                '#9bbb58',
                                                                '#23bfaa',
                                                                '#8064a2',
                                                            ],
                                                            borderWidth: 1,
                                                        }]

                                                }}
                                            ></Pie>
                                        </div>

                                    </div>
                                </Col>
                                <Col lg='6'>
                                    <div className="panel">
                                        <div className="chartjs-wrapper">
                                            <button onClick={() => {
                                                const url = this.smsChart.toBase64Image();
                                                // console.log(url)
                                                var link = document.createElement("a");
                                                link.download = `download.png`;
                                                link.target = "_blank";

                                                // Construct the URI
                                                link.href = url;
                                                document.body.appendChild(link);
                                                link.click();

                                            }} className="chartjs-download-button"><Isvg src={downloadIcon} /></button>
                                            <Bar
                                                ref={(node) => this.smsChart = node}
                                                options={{

                                                    resposive: true, maintainAspectRatio: false,
                                                    plugins: {

                                                        title: {
                                                            display: true,
                                                            padding: 20,
                                                            font: {
                                                                size: 24,
                                                            },
                                                            text: 'All sms sent'.translate(this.props.lang) + ' ( ' + this.state.dataUsedModal.allSms + ' )'
                                                        },

                                                        legend: {
                                                            display: false,
                                                        }
                                                    }
                                                }}
                                                data={{
                                                    labels: [
                                                        'This month'.translate(this.props.lang),
                                                        'This quater'.translate(this.props.lang),
                                                        'Previous quarter'.translate(this.props.lang)
                                                    ],
                                                    datasets: [
                                                        {
                                                            data: [this.state.dataUsedModal.smsThisMonth, this.state.dataUsedModal.smsLast3Month, this.state.dataUsedModal.smsLast6Month],
                                                            backgroundColor: [
                                                                '#4f81bc',
                                                                '#c0504e',
                                                                '#9bbb58',
                                                                '#23bfaa',
                                                            ],
                                                            borderColor: [
                                                                '#4f81bc',
                                                                '#c0504e',
                                                                '#9bbb58',
                                                                '#23bfaa',
                                                            ],
                                                            borderWidth: 1,
                                                        }]

                                                }}
                                            ></Bar>
                                        </div>




                                    </div>

                                </Col>

                                <Col lg='6'>
                                    <div className="panel">
                                        <div className="chartjs-wrapper">
                                            <button onClick={() => {
                                                const url = this.loginChart.toBase64Image();
                                                // console.log(url)
                                                var link = document.createElement("a");
                                                link.download = `download.png`;
                                                link.target = "_blank";

                                                // Construct the URI
                                                link.href = url;
                                                document.body.appendChild(link);
                                                link.click();

                                            }} className="chartjs-download-button"><Isvg src={downloadIcon} /></button>
                                            <Bar
                                                ref={(node) => this.loginChart = node}
                                                options={{

                                                    resposive: true, maintainAspectRatio: false,
                                                    plugins: {

                                                        title: {
                                                            display: true,
                                                            padding: 20,
                                                            font: {
                                                                size: 24,
                                                            },
                                                            text: 'All logins'.translate(this.props.lang) + ' ( ' + this.state.dataUsedModal.allLogins + ' )'
                                                        },

                                                        legend: {
                                                            display: false,
                                                        }
                                                    }
                                                }}
                                                data={{
                                                    labels: [
                                                        'This month'.translate(this.props.lang),
                                                        'This quater'.translate(this.props.lang),
                                                        'Previous quarter'.translate(this.props.lang)
                                                    ],
                                                    datasets: [
                                                        {
                                                            data: [this.state.dataUsedModal.loginsThisMonth, this.state.dataUsedModal.loginsLast3Month, this.state.dataUsedModal.loginsLast6Month],
                                                            backgroundColor: [
                                                                '#4f81bc',
                                                                '#c0504e',
                                                                '#9bbb58',
                                                                '#23bfaa',
                                                            ],
                                                            borderColor: [
                                                                '#4f81bc',
                                                                '#c0504e',
                                                                '#9bbb58',
                                                                '#23bfaa',
                                                            ],
                                                            borderWidth: 1,
                                                        }]

                                                }}
                                            ></Bar>
                                        </div>



                                    </div>
                                </Col>
                                <Col lg='6'>
                                    <div className="panel">
                                        <div className="chartjs-wrapper">
                                            <button onClick={() => {
                                                const url = this.signaturesChart.toBase64Image();
                                                // console.log(url)
                                                var link = document.createElement("a");
                                                link.download = `download.png`;
                                                link.target = "_blank";

                                                // Construct the URI
                                                link.href = url;
                                                document.body.appendChild(link);
                                                link.click();

                                            }} className="chartjs-download-button"><Isvg src={downloadIcon} /></button>
                                            <Bar
                                                ref={(node) => this.signaturesChart = node}
                                                options={{

                                                    resposive: true, maintainAspectRatio: false,
                                                    plugins: {

                                                        title: {
                                                            display: true,
                                                            padding: 20,
                                                            font: {
                                                                size: 24,
                                                            },
                                                            text: 'Total BankID signatures'.translate(this.props.lang) + ' ( ' + this.state.dataUsedModal.allSignatures + ' )'
                                                        },

                                                        legend: {
                                                            display: false,
                                                        }
                                                    }
                                                }}
                                                data={{
                                                    labels: [
                                                        'This month'.translate(this.props.lang),
                                                        'This quater'.translate(this.props.lang),
                                                        'Previous quarter'.translate(this.props.lang)
                                                    ],
                                                    datasets: [
                                                        {
                                                            data: [this.state.dataUsedModal.signaturesThisMonth, this.state.dataUsedModal.signaturesLast3Month, this.state.dataUsedModal.signaturesLast6Month],
                                                            backgroundColor: [
                                                                '#4f81bc',
                                                                '#c0504e',
                                                                '#9bbb58',
                                                                '#23bfaa',
                                                            ],
                                                            borderColor: [
                                                                '#4f81bc',
                                                                '#c0504e',
                                                                '#9bbb58',
                                                                '#23bfaa',
                                                            ],
                                                            borderWidth: 1,
                                                        }]

                                                }}
                                            ></Bar>
                                        </div>



                                    </div>
                                </Col>
                                <Col lg='12'>
                                    <Row style={{ marginTop: 30 }}>
                                        <Col lg='3'>
                                            <DatePicker
                                                label={'Start date'.translate(this.props.lang)}
                                                value={this.state.startDateSms}
                                                onChange={(e) => {
                                                    this.setState({
                                                        startDateSms: e
                                                    }, () => {
                                                        this.get()
                                                        console.log(this.state.dataUsedModal);
                                                        this.statisticListGrafic({
                                                            startDateSms: this.state.startDateSms,
                                                            endDateSms: this.state.endDateSms,
                                                            group: this.state.dataUsedModal._id
                                                        })
                                                        if (new Date(this.state.startDateSms).getTime() > new Date(this.state.endDateSms).getTime()) {
                                                            this.setState({
                                                                endDateSms: e
                                                            })
                                                        }
                                                    })

                                                }} />
                                        </Col>
                                        <Col lg='3'>
                                            <DatePicker
                                                label={'End date'.translate(this.props.lang)}
                                                value={this.state.endDateSms}
                                                onChange={(e) => {
                                                    this.setState({
                                                        endDateSms: e
                                                    }, () => {
                                                        this.get()
                                                        this.statisticListGrafic({
                                                            startDateSms: this.state.startDateSms,
                                                            endDateSms: this.state.endDateSms,
                                                            group: this.state.dataUsedModal._id
                                                        })
                                                        if (new Date(this.state.startDateSms).getTime() > new Date(this.state.endDateSms).getTime()) {
                                                            this.setState({
                                                                startDateSms: e
                                                            })
                                                        }
                                                    })

                                                }} />
                                        </Col>
                                        <Col lg='3' style={{ display: 'flex', alignItems: 'end', marginBottom: 10 }}>
                                            <Button onClick={() => this.exportFromGraph({
                                                startDateSms: this.state.startDateSms,
                                                endDateSms: this.state.endDateSms,
                                                group: this.state.dataUsedModal._id
                                            })} color="primary">{'Export'.translate(this.props.lang)}</Button>
                                        </Col>
                                        <Col lg='12'>

                                            <div>
                                                <table style={{ width: '100%' }}>
                                                    <tr>
                                                        <th >{'Date'.translate(this.props.lang)}</th>
                                                        <th >{'Total sms'.translate(this.props.lang)}</th>
                                                        <th >{'Total bankid logins'.translate(this.props.lang)}</th>
                                                        <th >{'Total BankID signatures'.translate(this.props.lang)}</th>
                                                        <th >{'Total users'.translate(this.props.lang)}</th>
                                                    </tr>
                                                    {this.state.timeDataInterval && Object.keys(this.state.timeDataInterval).map(item => {
                                                        return (

                                                            <tr>
                                                                <td > {item} </td>
                                                                <td > {this.state.timeDataInterval[item].timeMessages} </td>
                                                                <td > {this.state.timeDataInterval[item].timeLogins} </td>
                                                                <td > {this.state.timeDataInterval[item].signatureTracking} </td>
                                                                <td > {this.state.timeDataInterval[item].users} </td>
                                                                <td><Button color='primary' onClick={() => this.setState({ showAllUsersModal: this.state.timeDataInterval[item].usersList })} >{'Show All Users'.translate(this.props.lang)}</Button></td>
                                                            </tr>

                                                        )
                                                    })}
                                                </table>

                                            </div>
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>


                        </ModalBody>
                        <ModalFooter>
                            <Button color="primary" size="sm" onClick={() => this.setState({ dataUsedModal: null })}>
                                {"Close".translate(this.props.lang)}
                            </Button>
                        </ModalFooter>
                    </Modal>
                ) : null}

                {this.state.showAllUsersModal ? <Modal centered isOpen={this.state.showAllUsersModal} toggle={() => this.setState({ showAllUsersModal: null })}>
                    <ModalHeader close={<button
                        type="button"
                        className="close"
                        style={{ position: "absolute", top: "15px", right: "15px" }}
                        onClick={() =>
                            this.setState({
                                showAllUsersModal: null,
                            })
                        }
                    >
                        <Isvg src={xIcon} />
                    </button>}>
                        {'All Users'.translate(this.props.lang)}
                    </ModalHeader>
                    <ModalBody>
                        <ul style={{ listStyle: 'none' }}>
                            {this.state.showAllUsersModal.map((user, i) => <li>{i + 1}. {user}</li>)}
                        </ul>
                    </ModalBody>
                    <ModalFooter>
                        <Button
                            color="primary"
                            size="sm"
                            onClick={() => this.setState({ showAllUsersModal: null })}
                        >
                            {"Close".translate(this.props.lang)}
                        </Button>
                    </ModalFooter>
                </Modal> : null}


                {this.state.error ?

                    <ErrorModal lang={this.props.lang}
                        isOpen={this.state.error}
                        toggle={() => this.setState({ error: null })}

                    >
                        {this.state.error.translate(this.props.lang)}
                    </ErrorModal>

                    : null}

                {this.state.exportClinicsModal ? (
                    <Modal centered isOpen={this.state.exportClinicsModal} toggle={() => this.setState({ exportClinicsModal: !this.state.exportClinicsModal, listOfGroups: null, questionaries: null, emailSendStatistic: null })} size="xl">
                        <ModalHeader
                            close={
                                <button
                                    type="button"
                                    className="close"
                                    style={{ position: "absolute", top: "15px", right: "15px" }}
                                    onClick={() =>
                                        this.setState({
                                            exportClinicsModal: !this.state.exportClinicsModal,
                                            listOfGroups: null,
                                            questionaries: null,
                                            emailSendStatistic: null
                                        })
                                    }
                                >
                                    <Isvg src={xIcon} />
                                </button>
                            }
                        >
                            {"Export main clinics statistic".translate(this.props.lang)}
                        </ModalHeader>
                        <ModalBody>
                            <Row>
                                <Col lg='6'>
                                    <DatePicker
                                        label={'Start date'.translate(this.props.lang)}
                                        value={this.state.startDateSms}
                                        onChange={(e) => {
                                            this.setState({
                                                startDateSms: e
                                            }, () => {
                                                this.get()
                                                if (new Date(this.state.startDateSms).getTime() > new Date(this.state.endDateSms).getTime()) {
                                                    this.setState({
                                                        endDateSms: e
                                                    }, () => {
                                                        this.get()
                                                    })
                                                }
                                            })

                                        }} />
                                </Col>
                                <Col lg='6'>
                                    <DatePicker
                                        label={'End date'.translate(this.props.lang)}
                                        value={this.state.endDateSms}
                                        onChange={(e) => {
                                            this.setState({
                                                endDateSms: e
                                            }, () => {
                                                this.get()
                                                if (new Date(this.state.startDateSms).getTime() > new Date(this.state.endDateSms).getTime()) {
                                                    this.setState({
                                                        startDateSms: e
                                                    }, () => {
                                                        this.get()
                                                    })
                                                }
                                            })

                                        }} />
                                </Col>
                            </Row>


                            <div style={{ marginTop: '10px' }}>

                                <SelectMainClinicGroupForTransferr {...this.props}
                                    value={this.state.questionaries}
                                    clinicIds={this.state.clinicIds ? this.state.clinicIds : []}
                                    onChange={(val) => {
                                        if (val) {
                                            let arr = []
                                            for (var key in val) {
                                                arr.push(key)
                                            }
                                            let questionaries = this.state.questionaries;
                                            questionaries = val;
                                            this.setState({ questionaries, listOfGroups: arr }, () => {
                                                // console.log(this.state.listOfGroups)
                                            });
                                        }
                                    }}
                                ></SelectMainClinicGroupForTransferr>
                            </div>
                        </ModalBody>
                        <ModalFooter>
                            <Button
                                color="primary"
                                size="sm"
                                style={{ borderColor: '#3A3F56' }}
                                onClick={() => this.setState({
                                    exportClinicsModal: null,
                                    listOfGroups: null,
                                    questionaries: null,
                                    emailSendStatistic: null
                                })}
                            >
                                {"Close".translate(this.props.lang)}
                            </Button>
                            <div className="buttons-right-blue">
                                <Button
                                    color="primary"
                                    size="sm"
                                    style={{ marginRight: 15 }}
                                    disabled={this.state.listOfGroups && this.state.listOfGroups.length > 0 ? false : true}
                                    onClick={() => this.exportClinicsStatistic(this.state.listOfGroups, this.state.startDateSms, this.state.endDateSms, false, this.state.emailSendStatistic)}
                                >
                                    {"Export selected".translate(this.props.lang)}
                                </Button>
                                <Button
                                    color="primary"
                                    size="sm"
                                    onClick={() => this.exportClinicsStatistic(this.state.listOfGroups, this.state.startDateSms, this.state.endDateSms, true, this.state.emailSendStatistic)}
                                >
                                    {"Export all".translate(this.props.lang)}
                                </Button>
                            </div>
                        </ModalFooter>
                    </Modal>
                ) : null}



            </div>
        );
    }
}

const selector = formValueSelector("modalForm");

export default connect(
    (state) => {
        return { group: selector(state, "group") };
    },
    {
        changeSubgroup: (value) => change("modalForm", "subgroup", value),
    }
)(Page(MainClinicGroupsExport));
