import React, { Component } from "react";
import Page from "../../containers/page";
import xIcon from '../../assets/svg/x.svg';

import {
    Container,
    Row,
    Col,
    Modal,
    ModalHeader,
    ModalBody,
    ModalFooter,
    Table,
    Input,
    FormGroup,
    Label,
    Button,
} from "reactstrap";

import Isvg from "react-inlinesvg";

import { Player, Controls } from '@lottiefiles/react-lottie-player';

import FormBuilder from "../../components/forms/modalFormBuilder";
import { required } from "../../components/forms/validation";

import ListBuilder from "../../components/listBuilder";
import DeleteModal from "../../components/deleteModal";
import {
    getSearchParams,
    generateSearchLink,
    generateSearchLinkMultiple,
} from "../../helpers/linkHelper";
import { API_ENDPOINT, DOMAINS } from "../../constants";
import { formValueSelector, change } from "redux-form"; // ES6
import { connect } from "react-redux";
import ClinicsFilter from "../../components/clinicGroupsFilter";

import UsersFilter from "../../components/usersFilter";
import Image from "../../components/forms/fields/image";

import edit from "../../assets/svg/edit.svg";
import garabage from "../../assets/svg/garbage.svg";
import importIcon from '../../assets/svg/import.svg'
import Usage from "../../assets/svg/usage.svg";
import downloadIcon from '../../assets/svg/download.svg';
import SelectGroupForTransfer from '../../components/selectMainClinicGroupForTransfer'

import { Pie, Bar } from 'react-chartjs-2';
import moment from 'moment';
import DatePicker from '../../components/forms/fields/datePicker';

import addIcon from "../../assets/svg/add-icon.svg";
import webdocImage from "../../assets/images/webdoc.png";
import Search from "../../components/search";
import profileUser from "../../assets/images/user.png";
import notification from "../../assets/svg/notification.svg";
import moreIcon from "../../assets/svg/more.svg";
import exportIcon from "../../assets/svg/export.svg";
import Checkbox from '../../components/forms/fields/checkbox';
import HTML from "../../components/forms/fields/html";

function parseJSON(response) {
    return response.json().then(json => {
        return {
            result: json,
            status: response.status
        }
    })
}

/**
* Group settings page
* @author   Milan Stanojevic
*/
class ClinicGroups extends Component {
    constructor(props) {
        super(props);

        this.state = {
            ...props.initialData,
            form: false,
            useState: false,
            params: {
                entries: 10,
                page: 0,
            },
            items: [],
            groups: [],
            subgroups: [],
            total: 0,
            loading: true,
            clinicModules: [],
            errors: {},
            licence: 10,
            videoLicence: 0,
            videoLink: 0,
            webDocModal: null,
            startDateSms: moment.unix(Math.floor(new Date().getTime() / 1000)).format('YYYY-MM-DD'),
            endDateSms: moment.unix(Math.floor(new Date().getTime() / 1000)).format('YYYY-MM-DD'),
            webDocSelectedClinics: [],
            integrationActive: 0
        };
    }

    get = () => {
        this.props.updateMeta(
            this.props.generateSeoTags(this.state, this.props.lang)
        );

        for (let i = 0; i < this.props.loadData.length; i++) {
            this.props.loadData[i](
                window.fetch,
                this.props.lang,
                this.props[0].match,
                this.state.useState
                    ? this.state.params
                    : getSearchParams(this.props[0].location.search, {
                        entries: 10,
                        page: 0,
                    })
            ).then((data) => {
                this.setState(
                    {
                        ...data,
                        loading: null,
                    },
                    () => {
                        this.props.updateMeta(
                            this.props.generateSeoTags(this.state, this.props.lang)
                        );
                    }
                );
            });
        }

        for (let i = 0; i < this.props.loadDataWithQuery.length; i++) {
            this.props.loadDataWithQuery[i](
                window.fetch,
                this.props.lang,
                this.props[0].match,
                this.state.useState
                    ? this.state.params
                    : getSearchParams(this.props[0].location.search, {
                        entries: 10,
                        page: 0,
                    })
            ).then((data) => {
                let dataToSet = { ...data }
                if (dataToSet.page || dataToSet.items || dataToSet.total) {
                    dataToSet.loading = null;
                    // console.log(dataToSet.page || dataToSet.items || dataToSet.total)
                    let checkFilter = '';
                    if (dataToSet.resultFilter) {
                        checkFilter = String(dataToSet.resultFilter)
                        delete dataToSet.resultFilter;
                    }
                    let search = this.props[0].location.search;
                    let serachParams = getSearchParams(search);
                    let searchParamsFilter = '';
                    if (serachParams.filter) {
                        searchParamsFilter = serachParams.filter;
                    }
                    if (searchParamsFilter != checkFilter) {
                        delete dataToSet.page;
                        delete dataToSet.items;
                        delete dataToSet.total;
                        delete dataToSet.loading;
                    }
                }
                this.setState(
                    {
                        ...dataToSet,
                        // loading: null,
                    },
                    () => {
                        this.props.updateMeta(
                            this.props.generateSeoTags(this.state, this.props.lang)
                        );
                    }
                );
            });
        }





    };



    componentDidMount() {
        if (typeof window !== "undefined") {
            window.scrollTo(0, 0);
        }

        this.get();
    }

    componentDidUpdate(prevProps) {
        if (prevProps[0].location.search != this.props[0].location.search) {
            this.setState(
                {
                    loading: true,
                },
                () => {
                    this.get();
                }
            );
        }
    }

    updateSort = (field, type) => {
        if (this.state.useState) {
            let state = this.state.params;
            state.sortField = field;
            state.sortType = type;
            this.setState({
                params: state,
            });
        } else {
            this.props[0].history.push(
                generateSearchLinkMultiple(
                    this.props[0].location.search,
                    { entries: 10, page: 0 },
                    [
                        { name: "sortField", value: field },
                        { name: "sortType", value: type },
                    ],
                    false
                )
            );
        }
    };

    updateParams = (name, value, restart = false) => {
        if (this.state.useState) {
            if (restart) {
                let obj = {
                    entries: 10,
                    page: 0,
                };
                obj[name] = value;
                this.setState({ params: obj });
            } else {
                let obj = this.state.params;
                obj[name] = value;
                this.setState({ params: obj });
            }
        } else {
            this.props[0].history.push(
                generateSearchLink(
                    this.props[0].location.search,
                    { entries: 10, page: 0 },
                    name,
                    value,
                    restart
                )
            );
        }
    };
    updateMultipleParams = (fields, restart = false) => {
        if (this.state.useState) {
            if (restart) {
                let obj = {
                    entries: 10,
                    page: 0,
                };
                for (let i = 0; i < fields.length; i++) {
                    obj[fields[i].name] = fields[i].value;
                }
                this.setState({ params: obj });
            } else {
                let obj = this.state.params;
                for (let i = 0; i < fields.length; i++) {
                    obj[fields[i].name] = fields[i].value;
                }
                this.setState({ params: obj });
            }
        } else {
            this.props[0].history.push(
                generateSearchLinkMultiple(
                    this.props[0].location.search,
                    { entries: 10, page: 0 },
                    fields,
                    restart
                )
            );
        }
    };




    /**
    * Insert new or update clinic group data
    * @author   Milan Stanojevic
    * @Objectparam    {Object} data     form data
    * @Objectparam    {String} id       clinic group id
    */
    insertOrUpdate = (data) => {
        let link = window.location.origin;
        data.link = link;
        this.setState(
            {
                loading: true,
                _saving: true
            },
            () => {
                if (!this.state.form._id) {
                    fetch(API_ENDPOINT + "/data/main-clinic-groups/new", {
                        method: "PUT",
                        headers: {
                            Authorization:
                                typeof localStorage !== "undefined"
                                    ? `Bearer ${localStorage.getItem("authToken")}`
                                    : null,
                            "content-type": "application/json",
                        },
                        body: JSON.stringify(data),
                    })
                        .then((res) => res.json())
                        .then((result) => {
                            if (result.error) {
                                this.setState({
                                    error: result.error.translate(this.props.lang),
                                    loading: null,
                                });
                            } else {
                                this.get();
                                this.setState({
                                    form: null,
                                    error: null,
                                    webDocClinics: null
                                });
                            }
                        });
                } else {
                    fetch(API_ENDPOINT + "/data/main-clinic-groups/" + this.state.form._id, {
                        method: "POST",
                        headers: {
                            Authorization:
                                typeof localStorage !== "undefined"
                                    ? `Bearer ${localStorage.getItem("authToken")}`
                                    : null,
                            "content-type": "application/json",
                        },
                        body: JSON.stringify(data),
                    })
                        .then((res) => res.json())
                        .then((result) => {
                            if (result.error) {
                                this.setState({
                                    error: result.error.translate(this.props.lang),
                                    loading: null,
                                });
                            } else {
                                this.get();
                                this.setState({
                                    _saving: false
                                }, () => {
                                    setTimeout(() => {
                                        this.setState({ _saving: false, form: null, error: null, webDocClinics: null })
                                    }, 1000)
                                })
                            }
                        });
                }
            }
        );
    };

    /**
    * Delete clinic group data
    * @author   Milan Stanojevic
    * @Objectparam    {String} id       clinic group id
    */
    delete = (id) => {
        this.setState(
            {
                loading: true,
            },
            () => {
                fetch(API_ENDPOINT + "/data/main-clinic-groups/" + id, {
                    method: "DELETE",
                    headers: {
                        Authorization:
                            typeof localStorage !== "undefined"
                                ? `Bearer ${localStorage.getItem("authToken")}`
                                : null,
                        "content-type": "application/json",
                    },
                })
                    .then((res) => res.json())
                    .then((result) => {

                        this.setState({ loading: false }, () => this.get())

                    });
            }
        );
    };

    transferClinicGroup = (data) => {

        if (this.state.domen && this.state.domen != -1) {
            data.domen = this.state.domen
            //   if (this.state.emails && this.state.emails.length > 0) {
            //     data.emails = this.state.emails
            //   }
            //   if(this.state.usersForTransfer){
            //     data.usersForTransfer = this.state.usersForTransfer
            //   }
            // console.log(data.usersForTransfer);
            this.setState({
                loading: true
            }, () => {

                // fetch(API_ENDPOINT + '/domen-transfer/user-email-check', {
                //   method: 'POST',
                //   headers: {
                //     'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage.getItem('authToken')}` : null,
                //     'content-type': 'application/json'
                //   },
                //   body: JSON.stringify(data)
                // }).then(res => res.json()).then((result) => {
                //   if (result) {
                //     if (result.usersArr && result.usersArr.length > 0) {
                //       this.setState({
                //         arrUsers: result.usersArr,
                //         loading: false
                //       })
                //     } else if (result.message == 'ok') {
                // transfer
                // console.log('idemoooo')
                fetch(API_ENDPOINT + '/domen-transfer/main-clinic-group', {
                    method: 'POST',
                    headers: {
                        'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage.getItem('authToken')}` : null,
                        'content-type': 'application/json'
                    },
                    body: JSON.stringify(data)
                }).then(res => res.json()).then((result) => {
                    if (result) {

                        this.setState({
                            transferModal: false,
                            questionaries: null,
                            domen: null,
                            // arrUsers: [],
                            // values: [],
                            // usersForTransfer: {},
                            // newUsernames: result.length ? result : []
                        })
                    }
                    this.get();
                })
                // }

                //   }
                // })
            })
        } else {
            this.setState({
                error: 'You have to select domen'.translate(this.props.lang)
            })
        }

    }
    saveLoginText = () => {
        if (this.state.editLoginTextModal) {
            let text = this.state.editLoginTextModal.text;

            fetch(API_ENDPOINT + '/data/main-clinic-groups/edit/login-text', {
                method: 'POST',
                headers: {
                    'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage.getItem('authToken')}` : null,
                    'content-type': 'application/json'
                },
                body: JSON.stringify({ text })
            }).then(res => res.json()).then((result) => {
                if (result) {
                    this.setState({ editLoginTextModal: null, landingLoginInfo: result })
                }
            })

        }
    }

    statisticGrafic = (item) => {
        console.log(item.clinicGroups);
        fetch(API_ENDPOINT + "/data/main-groups/statistic/clinic-groups", {
            method: "POST",
            headers: {
                Authorization:
                    typeof localStorage !== "undefined"
                        ? `Bearer ${localStorage.getItem("authToken")}`
                        : null,
                "content-type": "application/json",
            },
            body: JSON.stringify({ groups: Object.keys(item.clinicGroups) }),
        }).then(res => res.json()).then((result) => {
            // console.log(result);
            if (result) {
                this.statisticListGrafic({
                    startDateSms: this.state.startDateSms,
                    endDateSms: this.state.endDateSms,
                    group: item._id
                })

                this.setState({ dataUsedModal: { ...result, _id: item._id }, group: result._id },
                    () => this.get())
            }
        })

    }

    statisticListGrafic = (item) => {
        console.log(item, 'item');
        fetch(API_ENDPOINT + '/data/main-clinic-groups/data-usage/time', {
            method: 'POST',
            headers: {
                'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage.getItem('authToken')}` : null,
                'content-type': 'application/json'
            },
            body: JSON.stringify(item)
        }).then(parseJSON).then(({ result, status }) => {
            if (status >= 200 && status < 300)
                this.setState({
                    timeData: result.obj,
                    timeDataInterval: result.timeObj
                })

        })
    }

    exportFromGraph = (data) => {
        fetch(API_ENDPOINT + "/data/main-clinic-groups/data-usage/time-export", {
            method: "POST",
            headers: {
                Authorization: typeof localStorage !== "undefined" ? `Bearer ${localStorage.getItem("authToken")}` : null,
                "content-type": "application/json",
            },
            body: JSON.stringify({
                startDateSms: data.startDateSms,
                endDateSms: data.endDateSms,
                group: data.group,
            }),
        }).then((res) => res.json()).then((result) => {
            if (result.link) {
                window.open(API_ENDPOINT + result.link)
            }
        });
    }


    render() {
        let params = {};
        if (this.state.useState) {
            params = this.state.params;
        } else {
            params = getSearchParams(this.props[0].location.search, {
                entries: 10,
                page: 0,
            });
        }

        let subgroups = [{ name: "None", value: null }];

        if (this.props.group) {
            for (let i = 0; i < this.state.subgroups.length; i++) {
                if (
                    this.state.subgroups[i].groups &&
                    this.state.subgroups[i].groups.indexOf(this.props.group) !== -1
                ) {
                    subgroups.push(this.state.subgroups[i]);
                }
            }
        }
        return (
            <div className={this.props.autoHideSidebar ? "dashboard dashboard-auto-hide" : "dashboard"}>
                <Container fluid>
                    <Row>
                        <Col lg="12">
                            <div className="panel">
                                <div className="panel-header">
                                    <h5 className="header-component">
                                        {this.props.linksMeta &&
                                            this.props.linksMeta[this.props[0].location.pathname]
                                            ? this.props.linksMeta[this.props[0].location.pathname][
                                            this.props.lang
                                            ]
                                            : ""}{" "}
                                    </h5>{" "}
                                    <div style={{ display: 'flex', alignItems: 'center' }}>
                                        {/* <div style={{ marginRight: 10 }}>
                                            <button
                                                className="btn add-button button-flex-elements"
                                                onClick={() => {
                                                    let text = '';
                                                    if (this.state.landingLoginInfo && this.state.landingLoginInfo.loginText) {
                                                        text = String(this.state.landingLoginInfo.loginText);
                                                    }
                                                    this.setState({
                                                        editLoginTextModal: {
                                                            text: text
                                                        },
                                                    })
                                                }
                                                }
                                            >
                                                <div className="button-edit-icon">
                                                    <Isvg src={edit} />
                                                </div>
                                                {"Edit login text".translate(this.props.lang)}
                                            </button>
                                        </div> */}

                                        <div style={{ marginRight: 10 }}>
                                            <button
                                                className="btn add-button"
                                                onClick={() => {
                                                    this.setState({
                                                        transferModal: true,
                                                    })
                                                }
                                                }
                                            >
                                                <Isvg src={importIcon} /> {"Transfer".translate(this.props.lang)}
                                            </button>
                                        </div>
                                        <button
                                            className="btn add-button"
                                            onClick={() =>
                                                this.setState({
                                                    form: {},
                                                })
                                            }
                                        >
                                            <Isvg src={addIcon} /> {"Add".translate(this.props.lang)}
                                        </button>
                                    </div>
                                </div>
                                <ListBuilder
                                    lang={this.props.lang}
                                    loading={this.state.loading}
                                    total={this.state.total}
                                    showNumeration={false}
                                    fields={[
                                        { type: "text", name: "name", label: "Group name".translate(this.props.lang), allowSort: true, },

                                    ]}
                                    items={this.state.items.map((item) => {
                                        return {
                                            ...item,
                                            createReferrals: (
                                                <div className="">
                                                    <Checkbox
                                                        checked={item.createReferrals}
                                                        key={item._id}
                                                    />
                                                </div>
                                            ),
                                            receiveReferrals: (
                                                <div className="">
                                                    <Checkbox
                                                        checked={item.receiveReferrals}
                                                        key={item._id}
                                                    />
                                                </div>
                                            ),
                                            personalReferrals: (
                                                <div className="">
                                                    <Checkbox
                                                        checked={item.personalReferrals}
                                                        key={item._id}
                                                    />
                                                </div>
                                            ),

                                        };
                                    })}
                                    rawItems={this.state.items}
                                    actions={[
                                        {
                                            component: <Isvg src={Usage} className="edit-icon" />,
                                            onClick: (item) => this.statisticGrafic(item),
                                        },

                                        {
                                            component: <Isvg src={edit} className="edit-icon" />,
                                            onClick: (item) => this.setState({ form: item }),
                                        },
                                        {
                                            component: (
                                                <Isvg src={garabage} className="delete-icon" />
                                            ),
                                            onClick: (item) => this.setState({ deleteModal: item }),
                                        },

                                    ]}
                                    params={params}
                                    sortField={params.sortField}
                                    sortType={params.sortType}
                                    updateSort={this.updateSort}
                                    updateParams={this.updateParams}
                                    updateMultipleParams={this.updateMultipleParams}
                                ></ListBuilder>
                                <DeleteModal
                                    lang={this.props.lang}
                                    isOpen={this.state.deleteModal}
                                    toggle={() => this.setState({ deleteModal: null })}
                                    handler={() => {
                                        this.delete(this.state.deleteModal._id);
                                        this.setState({ deleteModal: null });
                                    }}
                                >
                                    {"Delete group".translate(this.props.lang)}{" "}
                                    <strong>
                                        {this.state.deleteModal && this.state.deleteModal.name}
                                    </strong>
                                    ?
                                </DeleteModal>
                            </div>
                        </Col>
                    </Row>
                </Container>
                {this.state.form ? (
                    <Modal centered
                        isOpen={this.state.form}
                        toggle={() =>
                            this.setState({ form: !this.state.form, error: null, webDocClinics: null })
                        }
                        size="xl"
                        className="clinic-groups-modal"
                    >
                        {/* {this.state.loadingImage ?
                            <div className="loader-wrap" >
                                <Player
                                    autoplay={true}
                                    loop={true}
                                    src="/lf30_editor_l5cxzdyf.json"
                                    style={{ height: "100%", width: "100%", position: 'absolute', top: "50%", left: '50%', transform: 'translate(-50%, -50%)', zIndex: '99999' }}
                                ></Player>

                            </div>
                            :
                            null} */}
                        <ModalHeader
                            close={
                                <button
                                    type="button"
                                    className="close"
                                    style={{ position: "absolute", top: "15px", right: "15px" }}
                                    onClick={() =>
                                        this.setState({ form: !this.state.form, error: null, webDocClinics: null })
                                    }
                                >
                                    <Isvg src={xIcon} />
                                </button>
                            }
                        >
                            {"Create main group".translate(this.props.lang)}
                        </ModalHeader>
                        <ModalBody>

                            <Row>
                                <Col lg="6" className="clinic-groups-left">
                                    <Row>
                                        <Col lg="12">
                                            <FormGroup>
                                                <Label size="sm">
                                                    {"Group name".translate(this.props.lang)}
                                                </Label>
                                                <Input
                                                    invalid={this.state.errors.name}
                                                    size="sm"
                                                    type="text"
                                                    onChange={(e) => {
                                                        let form = this.state.form;
                                                        form.name = e.target.value;
                                                        this.setState({
                                                            form,
                                                        });
                                                    }}
                                                    value={this.state.form.name}
                                                />
                                            </FormGroup>

                                            <FormGroup>
                                                <Label size="sm">
                                                    {"Landing page link".translate(this.props.lang)}
                                                </Label>
                                                <div
                                                    style={{
                                                        display: "flex",
                                                        fontSize: 14,
                                                        alignItems: "center",
                                                    }}
                                                >
                                                    {" "}
                                                    {window.location.origin + '/'}
                                                    <Input
                                                        invalid={this.state.errors.alias}
                                                        style={{ marginLeft: 5 }}
                                                        size="sm"
                                                        type="text"
                                                        onChange={(e) => {
                                                            let form = this.state.form;
                                                            form.alias = e.target.value;
                                                            this.setState({
                                                                form,
                                                            });
                                                        }}
                                                        value={this.state.form.alias}
                                                    />
                                                </div>
                                            </FormGroup>
                                            <FormGroup>

                                                <Checkbox
                                                    onChange={(e) => {
                                                        let form = this.state.form;


                                                        form.showHeader = e.target.checked;
                                                        this.setState({ form })
                                                    }}
                                                    checked={this.state.form.showHeader}
                                                    label={"Show landing header".translate(this.props.lang)}
                                                />
                                            </FormGroup>


                                            {this.state.form.admin ? (
                                                <FormGroup className="group-admin">
                                                    <p>
                                                        <strong>
                                                            {"Main admin:".translate(this.props.lang)}
                                                        </strong>{" "}
                                                        {this.props.formatName(this.state.form.admin.userData.title, this.state.form.admin.userData.name)} -{" "}
                                                        {this.state.form.admin.username}
                                                    </p>
                                                </FormGroup>
                                            ) : null}
                                            <FormGroup className="group-admin-buttons" style={{ marginTop: 20 }}>
                                                <Button
                                                    style={{ marginRight: 20 }}
                                                    size="sm"
                                                    onClick={() =>
                                                        this.setState({ selectClinicsModal: true })
                                                    }
                                                >
                                                    {"Select clinic groups".translate(this.props.lang)}
                                                </Button>
                                                <Button
                                                    style={{ marginRight: 20 }}
                                                    color="primary"
                                                    size="sm"
                                                    onClick={() =>
                                                        this.setState({ selectAdminsModal: true })
                                                    }
                                                >
                                                    {"Select main admin".translate(this.props.lang)}
                                                </Button>


                                            </FormGroup>

                                            <p className="error" style={{ color: "red" }}>
                                                {this.state.error}
                                            </p>
                                        </Col>
                                    </Row>
                                </Col>
                                <Col lg="6" className="clinic-groups-users-table">
                                    <Row>
                                        <Col lg="6">
                                            <Image
                                                onLoadImage={(loading) => {
                                                    this.setState({
                                                        loadingImage: loading
                                                    })
                                                }}
                                                lang={this.props.lang}
                                                onChange={(logo) => {
                                                    let form = this.state.form;
                                                    form.logo = logo;
                                                    this.setState({
                                                        form,
                                                    });
                                                }}
                                                value={this.state.form.logo}
                                                label={"Clinic logo".translate(this.props.lang)}
                                            />
                                        </Col>
                                        <Col lg="6">
                                            <Image
                                                onLoadImage={(loading) => {
                                                    this.setState({
                                                        loadingImage: loading
                                                    })
                                                }}
                                                lang={this.props.lang}
                                                cropModal={true}
                                                onChange={(favicon) => {
                                                    let form = this.state.form;
                                                    form.favicon = favicon;
                                                    this.setState({
                                                        form,
                                                    });
                                                }}
                                                value={this.state.form.favicon}
                                                label={"Favicon".translate(this.props.lang)}
                                            />
                                        </Col>
                                        <div className="clinic-groups-clinics-table">
                                            <Table responsive hover className={"cursor-row "} >
                                                <thead>
                                                    <tr>
                                                        <th></th>
                                                        <th>{"Clinic name".translate(this.props.lang)}</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {this.state.form.clinicGroups &&
                                                        Object.values(this.state.form.clinicGroups).map(
                                                            (item, idx) => {
                                                                return (
                                                                    <tr>
                                                                        <td>
                                                                            <Checkbox
                                                                                checked={true}
                                                                                onChange={(e) => {
                                                                                    let form = this.state.form;
                                                                                    delete form.clinicGroups[item._id];
                                                                                    this.setState({ form });
                                                                                }}
                                                                            />
                                                                        </td>
                                                                        <td>{item.name}</td>
                                                                    </tr>
                                                                );
                                                            }
                                                        )}
                                                </tbody>
                                            </Table>
                                        </div>
                                    </Row>
                                </Col>
                            </Row>
                        </ModalBody>
                        <ModalFooter>
                            <Button
                                color="primary"
                                size="sm"
                                onClick={() => {
                                    let errors = {};
                                    let foundError = false;

                                    if (!this.state.form.name) {
                                        foundError = true;
                                        errors.name = true;
                                    }
                                    if (!this.state.form.alias) {
                                        foundError = true;
                                        errors.alias = true;
                                    }

                                    if (foundError) {
                                        this.setState({
                                            errors,
                                        });
                                        return;
                                    } else {
                                        this.setState({
                                            errors,
                                        });
                                    }

                                    let data = {

                                        name: this.state.form.name,
                                        alias: this.state.form.alias,
                                        showHeader: this.state.form.showHeader ? true : false,
                                        clinicGroups: this.state.form.clinicGroups
                                            ? Object.values(this.state.form.clinicGroups).map((clinic) => {
                                                return clinic._id;
                                            })
                                            : [],
                                        admin: this.state.form.admin
                                            ? this.state.form.admin._id
                                            : null,
                                        logo: this.state.form.logo,
                                        favicon: this.state.form.favicon
                                    };
                                    this.insertOrUpdate(data);
                                }}
                            >
                                {"Save".translate(this.props.lang)}
                            </Button>
                        </ModalFooter>
                    </Modal>
                ) : null}

                {this.state.selectClinicsModal ? (
                    <Modal centered
                        isOpen={this.state.selectClinicsModal}
                        toggle={() =>
                            this.setState({
                                selectClinicsModal: !this.state.selectClinicsModal,
                            })
                        }
                        size="xl"
                    >
                        <ModalHeader
                            close={
                                <button
                                    type="button"
                                    className="close"
                                    style={{ position: "absolute", top: "15px", right: "15px" }}
                                    onClick={() =>
                                        this.setState({
                                            selectClinicsModal: !this.state.selectClinicsModal,
                                        })
                                    }
                                >
                                    <Isvg src={xIcon} />
                                </button>
                            }
                        >
                            {"Select clinic groups".translate(this.props.lang)}
                        </ModalHeader>
                        <ModalBody>
                            <ClinicsFilter
                                {...this.props}
                                value={this.state.form.clinicGroups}
                                group={this.state.form._id}
                                onChange={(val) => {
                                    if (val) {
                                        let form = this.state.form;
                                        form.clinicGroups = val;
                                        this.setState({ form });
                                    }
                                }}
                            ></ClinicsFilter>
                        </ModalBody>
                        <ModalFooter>
                            <Button
                                color="primary"
                                size="sm"
                                onClick={() => this.setState({ selectClinicsModal: null })}
                            >
                                {"Close".translate(this.props.lang)}
                            </Button>
                        </ModalFooter>
                    </Modal>
                ) : null}
                {
                    this.state.transferModal ?
                        <Modal isOpen={this.state.transferModal} centered size="xl">
                            <ModalHeader toggle={() => this.setState({ transferModal: !this.state.transferModal, questionaries: null, domen: null })} close={<button type="button" className="close" style={{ position: 'absolute', top: '15px', right: '15px' }} onClick={() => this.setState({ transferModal: !this.state.transferModal, questionaries: null, domen: null })}>&times;</button>}>{'Select clinic groups for transfer'.translate(this.props.lang)}</ModalHeader>

                            <ModalBody>
                                <FormGroup>
                                    <Label>{'Select domen'.translate(this.props.lang)}</Label>
                                    <Input type='select'
                                        onChange={e => {

                                            this.setState({ domen: e.target.value == -1 ? null : e.target.value, }, () => { })
                                        }}
                                        value={this.state.domen}
                                    >
                                        <option value={-1}>
                                            {'None'.translate(this.props.lang)}
                                        </option>
                                        {
                                            DOMAINS?.filter(item => item.country === this.props.country)?.map((item, idx) => {
                                                if (window.location.origin !== item.domen)
                                                    return (
                                                        <option value={item.api}>
                                                            {item.name}
                                                        </option>
                                                    )
                                            })
                                        }
                                        {/* {
                                            this.props.country === 'denmark' ?
                                                <>
                                                    {window.location.origin != 'https://test.curoflow.dk' ?
                                                        <option value={'https://testapi.curoflow.dk'}>
                                                            {'TEST'.translate(this.props.lang)}
                                                        </option>
                                                        : null}
                                                    {window.location.origin != 'https://tryme.curoflow.dk' ?
                                                        <option value={'https://tryme-api.curoflow.dk'}>
                                                            {'TRY ME'.translate(this.props.lang)}
                                                        </option>
                                                        : null}
                                                    {window.location.origin != 'https://dashboard.curoflow.dk' ?
                                                        <option value={'https://dashboardproxy.curoflow.dk'}>
                                                            {'DASHBOARD'.translate(this.props.lang)}
                                                        </option>
                                                        : null}
                                                </>
                                                :
                                                <>
                                                    {window.location.origin != 'https://dev.curoflow.se' ?
                                                        <option value={'https://devapi.curoflow.se'}>
                                                            {'DEV'.translate(this.props.lang)}
                                                        </option>
                                                        : null}
                                                    {window.location.origin != 'https://tryme.curoflow.se' ?
                                                        <option value={'https://tryme-api.curoflow.se'}>
                                                            {'TRY ME'.translate(this.props.lang)}
                                                        </option>
                                                        : null}
                                                    {window.location.origin != 'https://test.curoflow.se' ?
                                                        <option value={'https://testapi.curoflow.se'}>
                                                            {'TEST'.translate(this.props.lang)}
                                                        </option>
                                                        : null}
                                                    {window.location.origin != 'https://dashboard.curoflow.se' ?
                                                        <option value={'https://dashboardapi.curoflow.se'}>
                                                            {'DASHBOARD'.translate(this.props.lang)}
                                                        </option>
                                                        : null}
                                                    {window.location.origin != 'https://sandbox.curoflow.se' ?
                                                        <option value={'https://sandboxapi.curoflow.se'}>
                                                            {'SANDBOX'.translate(this.props.lang)}
                                                        </option>
                                                        : null}
                                                </>
                                        } */}
                                    </Input>
                                </FormGroup>

                                <SelectGroupForTransfer {...this.props}
                                    value={this.state.questionaries}
                                    onChange={(val) => {
                                        if (val) {
                                            let questionaries = this.state.questionaries;
                                            questionaries = val;
                                            this.setState({ questionaries });
                                        }
                                    }}
                                ></SelectGroupForTransfer>

                            </ModalBody>
                            <ModalFooter>
                                <Button onClick={() => this.transferClinicGroup(this.state.questionaries)}>{'Transfer group'.translate(this.props.lang)}</Button>
                            </ModalFooter>

                        </Modal>
                        :
                        null
                }
                {
                    this.state.editLoginTextModal ?
                        <Modal isOpen={this.state.editLoginTextModal} centered size="lg">
                            <ModalHeader toggle={() => this.setState({ editLoginTextModal: null })} close={<button type="button" className="close" style={{ position: 'absolute', top: '15px', right: '15px' }} onClick={() => this.setState({ editLoginTextModal: null })}>&times;</button>}>{'Edit login text'.translate(this.props.lang)}</ModalHeader>

                            <ModalBody>
                                <FormGroup>
                                    <Label>{'Text'.translate(this.props.lang)}</Label>
                                    <HTML height={350} value={this.state.editLoginTextModal.text} onChange={(text) => {
                                        let editLoginTextModal = this.state.editLoginTextModal;
                                        editLoginTextModal.text = text;
                                        this.setState({
                                            editLoginTextModal
                                        })
                                    }} />
                                </FormGroup>


                            </ModalBody>
                            <ModalFooter>
                                <Button onClick={() => this.saveLoginText()}>{'Save'.translate(this.props.lang)}</Button>
                            </ModalFooter>

                        </Modal>
                        :
                        null
                }

                {this.state.selectAdminsModal ? (
                    <Modal centered
                        isOpen={this.state.selectAdminsModal}
                        toggle={() =>
                            this.setState({
                                selectAdminsModal: !this.state.selectAdminsModal,
                            })
                        }
                        size="xl"
                    >
                        <ModalHeader
                            close={
                                <button
                                    type="button"
                                    className="close"
                                    style={{ position: "absolute", top: "15px", right: "15px" }}
                                    onClick={() =>
                                        this.setState({
                                            selectAdminsModal: !this.state.selectAdminsModal,
                                        })
                                    }
                                >
                                    <Isvg src={xIcon} />
                                </button>
                            }
                        >
                            {"Select users"}
                        </ModalHeader>
                        <ModalBody>
                            <UsersFilter
                                {...this.props}
                                clinicModules={this.state.clinicModules}
                                value={this.state.form.admin}
                                onChange={(val) => {
                                    let form = this.state.form;
                                    form.admin = val;
                                    this.setState({ form, selectAdminsModal: null });
                                }}
                            ></UsersFilter>
                        </ModalBody>
                        <ModalFooter>
                            <Button
                                color="primary"
                                size="sm"
                                onClick={() => this.setState({ selectAdminsModal: null })}
                            >
                                {"Close".translate(this.props.lang)}
                            </Button>
                        </ModalFooter>
                    </Modal>
                ) : null}
                {this.state.loadingImage ?
                    <div className="file-progress-loader">
                        <div>
                            <Player
                                onEvent={(event) => {
                                    if (event === "load" && this.player && this.player.current) this.player.current.play();
                                }}
                                ref={this.player} // set the ref to your class instance
                                autoplay={true}
                                loop={true}
                                controls={true}
                                src="/lf30_editor_l5cxzdyf.json"
                                renderer={'svg'}
                                style={{ height: "128px", width: "128px", }}
                            ></Player>
                            <h6>{'Processing file...'.translate(this.props.lang)}</h6>
                        </div>
                    </div>
                    : null}

                {this.state.dataUsedModal ? (
                    <Modal centered isOpen={this.state.dataUsedModal} size="xl">
                        <ModalHeader close={
                            <button type="button" className="close" style={{ position: "absolute", top: "15px", right: "15px" }} onClick={() => this.setState({ dataUsedModal: !this.state.dataUsedModal })}>
                                <Isvg src={xIcon} />
                            </button>}>
                            {this.state.dataUsedModal.name}
                        </ModalHeader>
                        <ModalBody>
                            <Row>
                                <Col lg='12'>
                                    <div className="panel">
                                        <div className="chartjs-wrapper">
                                            <button onClick={() => {
                                                // console.log(this.dataUsageChart)
                                                const url = this.dataUsageChart.toBase64Image();
                                                console.log(url)
                                                var link = document.createElement("a");
                                                link.download = `download.png`;
                                                link.target = "_blank";

                                                // Construct the URI
                                                link.href = url;
                                                document.body.appendChild(link);
                                                link.click();

                                            }} className="chartjs-download-button"><Isvg src={downloadIcon} /></button>
                                            <Pie
                                                ref={(node) => this.dataUsageChart = node}
                                                options={{

                                                    resposive: true, maintainAspectRatio: false,
                                                    plugins: {
                                                        tooltip: {
                                                            callbacks: {
                                                                label: function (tooltipItem, data) {
                                                                    return tooltipItem.label
                                                                }
                                                            }
                                                        },

                                                        title: {
                                                            display: true,
                                                            padding: 20,
                                                            font: {
                                                                size: 24,
                                                            },
                                                            text: 'Data used'.translate(this.props.lang) + ' ( ' + this.state.dataUsedModal.size + ' )'
                                                        },

                                                        legend: {
                                                            position: 'bottom',
                                                            labels: {
                                                                usePointStyle: true,
                                                            },
                                                        }
                                                    }
                                                }}
                                                data={{
                                                    labels: [
                                                        'Cases'.translate(this.props.lang) + ' ' + this.state.dataUsedModal.clinicCaseData,
                                                        'Chat'.translate(this.props.lang) + ' ' + this.state.dataUsedModal.clinicChat,
                                                        'Clinic data'.translate(this.props.lang) + ' ' + this.state.dataUsedModal.clinicData,
                                                        'Support'.translate(this.props.lang) + ' ' + this.state.dataUsedModal.clinicSupport,
                                                        'Patient Documents'.translate(this.props.lang) + ' ' + this.state.dataUsedModal.clinicPatientDocuments,
                                                    ],
                                                    datasets: [
                                                        {
                                                            data: [this.state.dataUsedModal.clinicCaseDataBytes, this.state.dataUsedModal.clinicChatBytes, this.state.dataUsedModal.clinicDataBytes, this.state.dataUsedModal.clinicSupportBytes, this.state.dataUsedModal.clinicPatientDocumentsBytes],
                                                            backgroundColor: [
                                                                '#4f81bc',
                                                                '#c0504e',
                                                                '#9bbb58',
                                                                '#23bfaa',
                                                                '#8064a2',
                                                            ],
                                                            borderColor: [
                                                                '#4f81bc',
                                                                '#c0504e',
                                                                '#9bbb58',
                                                                '#23bfaa',
                                                                '#8064a2',
                                                            ],
                                                            borderWidth: 1,
                                                        }]

                                                }}
                                            ></Pie>
                                        </div>
                                    </div>
                                </Col>
                                <Col lg='6'>
                                    <div className="panel">
                                        <div className="chartjs-wrapper">
                                            <button onClick={() => {
                                                const url = this.smsChart.toBase64Image();
                                                var link = document.createElement("a");
                                                link.download = `download.png`;
                                                link.target = "_blank";

                                                // Construct the URI
                                                link.href = url;
                                                document.body.appendChild(link);
                                                link.click();

                                            }} className="chartjs-download-button"><Isvg src={downloadIcon} /></button>
                                            <Bar
                                                ref={(node) => this.smsChart = node}
                                                options={{

                                                    resposive: true, maintainAspectRatio: false,
                                                    plugins: {

                                                        title: {
                                                            display: true,
                                                            padding: 20,
                                                            font: {
                                                                size: 24,
                                                            },
                                                            text: 'All sms sent'.translate(this.props.lang) + ' ( ' + this.state.dataUsedModal.allSms + ' )'
                                                        },

                                                        legend: {
                                                            display: false,
                                                        }
                                                    }
                                                }}
                                                data={{
                                                    labels: [
                                                        'This month'.translate(this.props.lang),
                                                        'This quater'.translate(this.props.lang),
                                                        'Previous quarter'.translate(this.props.lang)
                                                    ],
                                                    datasets: [
                                                        {
                                                            data: [this.state.dataUsedModal.smsThisMonth, this.state.dataUsedModal.smsLast3Month, this.state.dataUsedModal.smsLast6Month],
                                                            backgroundColor: [
                                                                '#4f81bc',
                                                                '#c0504e',
                                                                '#9bbb58',
                                                                '#23bfaa',
                                                            ],
                                                            borderColor: [
                                                                '#4f81bc',
                                                                '#c0504e',
                                                                '#9bbb58',
                                                                '#23bfaa',
                                                            ],
                                                            borderWidth: 1,
                                                        }]

                                                }}
                                            ></Bar>
                                        </div>
                                    </div>

                                </Col>

                                <Col lg='6'>
                                    <div className="panel">
                                        <div className="chartjs-wrapper">
                                            <button onClick={() => {
                                                const url = this.loginChart.toBase64Image();
                                                // console.log(url)
                                                var link = document.createElement("a");
                                                link.download = `download.png`;
                                                link.target = "_blank";

                                                // Construct the URI
                                                link.href = url;
                                                document.body.appendChild(link);
                                                link.click();

                                            }} className="chartjs-download-button"><Isvg src={downloadIcon} /></button>
                                            <Bar
                                                ref={(node) => this.loginChart = node}
                                                options={{

                                                    resposive: true, maintainAspectRatio: false,
                                                    plugins: {

                                                        title: {
                                                            display: true,
                                                            padding: 20,
                                                            font: {
                                                                size: 24,
                                                            },
                                                            text: 'All logins'.translate(this.props.lang) + ' ( ' + this.state.dataUsedModal.allLogins + ' )'
                                                        },

                                                        legend: {
                                                            display: false,
                                                        }
                                                    }
                                                }}
                                                data={{
                                                    labels: [
                                                        'This month'.translate(this.props.lang),
                                                        'This quater'.translate(this.props.lang),
                                                        'Previous quarter'.translate(this.props.lang)
                                                    ],
                                                    datasets: [
                                                        {
                                                            data: [this.state.dataUsedModal.loginsThisMonth, this.state.dataUsedModal.loginsLast3Month, this.state.dataUsedModal.loginsLast6Month],
                                                            backgroundColor: [
                                                                '#4f81bc',
                                                                '#c0504e',
                                                                '#9bbb58',
                                                                '#23bfaa',
                                                            ],
                                                            borderColor: [
                                                                '#4f81bc',
                                                                '#c0504e',
                                                                '#9bbb58',
                                                                '#23bfaa',
                                                            ],
                                                            borderWidth: 1,
                                                        }]

                                                }}
                                            ></Bar>
                                        </div>
                                    </div>
                                </Col>
                                <Col lg='6'>
                                    <div className="panel">
                                        <div className="chartjs-wrapper">
                                            <button onClick={() => {
                                                const url = this.signaturesChart.toBase64Image();
                                                // console.log(url)
                                                var link = document.createElement("a");
                                                link.download = `download.png`;
                                                link.target = "_blank";

                                                // Construct the URI
                                                link.href = url;
                                                document.body.appendChild(link);
                                                link.click();

                                            }} className="chartjs-download-button"><Isvg src={downloadIcon} /></button>
                                            <Bar
                                                ref={(node) => this.signaturesChart = node}
                                                options={{

                                                    resposive: true, maintainAspectRatio: false,
                                                    plugins: {

                                                        title: {
                                                            display: true,
                                                            padding: 20,
                                                            font: {
                                                                size: 24,
                                                            },
                                                            text: 'Total BankID signatures'.translate(this.props.lang) + ' ( ' + this.state.dataUsedModal.allSignatures + ' )'
                                                        },

                                                        legend: {
                                                            display: false,
                                                        }
                                                    }
                                                }}
                                                data={{
                                                    labels: [
                                                        'This month'.translate(this.props.lang),
                                                        'This quater'.translate(this.props.lang),
                                                        'Previous quarter'.translate(this.props.lang)
                                                    ],
                                                    datasets: [
                                                        {
                                                            data: [this.state.dataUsedModal.signaturesThisMonth, this.state.dataUsedModal.signaturesLast3Month, this.state.dataUsedModal.signaturesLast6Month],
                                                            backgroundColor: [
                                                                '#4f81bc',
                                                                '#c0504e',
                                                                '#9bbb58',
                                                                '#23bfaa',
                                                            ],
                                                            borderColor: [
                                                                '#4f81bc',
                                                                '#c0504e',
                                                                '#9bbb58',
                                                                '#23bfaa',
                                                            ],
                                                            borderWidth: 1,
                                                        }]

                                                }}
                                            ></Bar>
                                        </div>



                                    </div>
                                </Col>
                                <Col lg='12'>
                                    <Row style={{ marginTop: 30 }}>
                                        <Col lg='3'>
                                            <DatePicker
                                                label={'Start date'.translate(this.props.lang)}
                                                value={this.state.startDateSms}
                                                onChange={(e) => {
                                                    this.setState({
                                                        startDateSms: e
                                                    }, () => {
                                                        this.get()
                                                        this.statisticListGrafic({
                                                            startDateSms: this.state.startDateSms,
                                                            endDateSms: this.state.endDateSms,
                                                            group: this.state.dataUsedModal._id
                                                        })
                                                        if (new Date(this.state.startDateSms).getTime() > new Date(this.state.endDateSms).getTime()) {
                                                            this.setState({
                                                                endDateSms: e
                                                            }, () => {
                                                                this.get()
                                                            })
                                                        }
                                                    })

                                                }} />
                                        </Col>
                                        <Col lg='3'>
                                            <DatePicker
                                                label={'End date'.translate(this.props.lang)}
                                                value={this.state.endDateSms}
                                                onChange={(e) => {
                                                    this.setState({
                                                        endDateSms: e
                                                    }, () => {
                                                        this.get()
                                                        this.statisticListGrafic({
                                                            startDateSms: this.state.startDateSms,
                                                            endDateSms: this.state.endDateSms,
                                                            group: this.state.dataUsedModal._id
                                                        })
                                                        if (new Date(this.state.startDateSms).getTime() > new Date(this.state.endDateSms).getTime()) {
                                                            this.setState({
                                                                startDateSms: e
                                                            }, () => {
                                                                this.get()
                                                            })
                                                        }
                                                    })

                                                }} />
                                        </Col>
                                        <Col lg='3' style={{ display: 'flex', alignItems: 'end', marginBottom: 10 }}>
                                            <Button onClick={() => this.exportFromGraph({
                                                startDateSms: this.state.startDateSms,
                                                endDateSms: this.state.endDateSms,
                                                group: this.state.dataUsedModal._id
                                            })} color="primary">{'Export'.translate(this.props.lang)}</Button>
                                        </Col>
                                        <Col lg='12'>
                                            <div>
                                                <table style={{ width: '100%' }}>
                                                    <tr>
                                                        <th >{'Date'.translate(this.props.lang)}</th>
                                                        <th >{'Total sms'.translate(this.props.lang)}</th>
                                                        <th >{'Total bankid logins'.translate(this.props.lang)}</th>
                                                        <th >{'Total BankID signatures'.translate(this.props.lang)}</th>
                                                        <th >{'Total users'.translate(this.props.lang)}</th>
                                                        {/* <th >{'Number of additional users'.translate(this.props.lang)}</th> */}
                                                    </tr>
                                                    {this.state.timeDataInterval && Object.keys(this.state.timeDataInterval).map(item => {
                                                        return (
                                                            <tr>
                                                                <td > {item} </td>
                                                                <td > {this.state.timeDataInterval[item].timeMessages} </td>
                                                                <td > {this.state.timeDataInterval[item].timeLogins} </td>
                                                                <td > {this.state.timeDataInterval[item].signatureTracking} </td>
                                                                <td > {this.state.timeDataInterval[item]?.usersList?.length} </td>
                                                                {/* <td > {this.state.timeDataInterval[item].users} </td> */}
                                                                <td><Button color='primary' onClick={() => this.setState({ showAllUsersModal: this.state.timeDataInterval[item].usersList })} >{'Show All Users'.translate(this.props.lang)}</Button></td>
                                                            </tr>

                                                        )
                                                    })}
                                                </table>
                                            </div>
                                        </Col>
                                    </Row>
                                </Col>
                            </Row>


                        </ModalBody>
                        <ModalFooter>
                            <Button color="primary" size="sm" onClick={() => this.setState({ dataUsedModal: null })}>
                                {"Close".translate(this.props.lang)}
                            </Button>
                        </ModalFooter>
                    </Modal>
                ) : null}

                {this.state.showAllUsersModal ? <Modal centered isOpen={this.state.showAllUsersModal} toggle={() => this.setState({ showAllUsersModal: null })}>
                    <ModalHeader close={<button
                        type="button"
                        className="close"
                        style={{ position: "absolute", top: "15px", right: "15px" }}
                        onClick={() =>
                            this.setState({
                                showAllUsersModal: null,
                            })
                        }
                    >
                        <Isvg src={xIcon} />
                    </button>}>
                        {'All Users'.translate(this.props.lang)}
                    </ModalHeader>
                    <ModalBody>
                        <ul style={{ listStyle: 'none' }}>
                            {this.state.showAllUsersModal.map((user, i) => <li>{i + 1}. {user}</li>)}
                        </ul>
                    </ModalBody>
                    <ModalFooter>
                        <Button
                            color="primary"
                            size="sm"
                            onClick={() => this.setState({ showAllUsersModal: null })}
                        >
                            {"Close".translate(this.props.lang)}
                        </Button>
                    </ModalFooter>
                </Modal> : null}
            </div>
        );
    }
}

const selector = formValueSelector("modalForm");

export default connect(
    (state) => {
        return { group: selector(state, "group") };
    },
    {
        changeSubgroup: (value) => change("modalForm", "subgroup", value),
    }
)(Page(ClinicGroups));
