import React, { Component } from 'react';
import Page from '../../containers/page';

import {
    Container,
    Row,
    Col, Modal, ModalBody, ModalFooter, ModalHeader, Button, FormGroup, Label, Input
} from 'reactstrap';

import Isvg from 'react-inlinesvg'
import ListBuilder from '../../components/listBuilder';
import FormBuilder from '../../components/forms/modalFormBuilder';
import { required } from '../../components/forms/validation';

import { getSearchParams, generateSearchLink, generateSearchLinkMultiple } from '../../helpers/linkHelper';
import moment from 'moment';
import view from '../../assets/svg/eye.svg'
import exportIcon from '../../assets/svg/export.svg';

import waitingRoom1 from '../../assets/images/waiting-room.png';
import waitingRoom2 from '../../assets/svg/waiting-room2.svg';
import waitingRoom3 from '../../assets/images/waitingRoom.png';
import { API_ENDPOINT } from '../../constants';
import ErrorModal from '../../components/errorModal';
import ChatIcon from '../../assets/svg/chat-icon.svg'
import CalendarDays from '../../assets/svg/calendar-waiting-room.svg';
import VideoCallPopUp from './videoCallPopUp';
import profilePicture from '../../assets/images/user.png';
import incommingCallIcon from '../../assets/svg/incomming-call-icon.svg';
import RescheduleWebBookingCalendar from '../egenRemiss/patient/rescheduleWebBookingCalendar_v2';
import xIcon from '../../assets/svg/x.svg';
import arrowLeftIcon from '../../assets/svg/arrow_left.svg';


var browserInfo = require('browser-info');

function parseJSON(response) {
    return response.json().then(json => {
        return {
            result: json,
            status: response.status
        }
    })
}

function isWindowFocused() {
    if (typeof window !== 'undefined' && document?.hasFocus()) return true;
    return false;
}

class WaitingRoom extends Component {
    constructor(props) {
        super(props);

        this.state = {
            ...props.initialData,
            _registerEvents: false,
            callInProgress: false,
            callInProgressDoctorName: '',
            dots: ['.', '.', '.'],
            events: [],
            dropins: [],
            eventsReceived: false,
        };
    }

    get = () => {


    }
    getGroupAlias = () => {
        const alias = typeof window !== 'undefined' && localStorage?.groupAlias ? localStorage?.groupAlias : null;
        return alias;
    }

    checkIfGroupIsSelected = () => {
        const alias = this.getGroupAlias();

        if (!alias) {
            this.setState({
                groupError: true
            })
        }
    }

    checkIfPatientLoggedIn = () => {
        let patientLoggedIn = false;
        if (this.props.uData?.userLevel === 1 || this.props.uData?.userLevel === 2) {
            patientLoggedIn = true;
        } else if (this.props.uData?.userLevel === 20 && typeof window !== 'undefined' && localStorage.profile === 'patient') {
            patientLoggedIn = true;
        }

        return patientLoggedIn;
    }
    redirectToLadning = () => {
        const alias = this.getGroupAlias();
        let link = '/';
        if (alias) {
            link = `/${alias}`;
        }
        this.props[0].history.push(link)
    }
    registerSocket = () => {
        if (this.props.uData && this.props.socket && !this.state._registerEvents) {
            this.setState({
                _registerEvents: true
            }, () => {
                this.registerEvents();
                this.enterWaitingRoom()
            })
        } else {
            this.socketInterval = setInterval(() => {
                if (this.props.uData && this.props.socket && !this.state._registerEvents) {
                    if (this.socketInterval) {
                        clearInterval(this.socketInterval)
                    }

                    this.setState({
                        _registerEvents: true
                    }, () => {
                        this.registerEvents();
                        this.enterWaitingRoom()
                    })
                }

            }, 150);
        }
    }
    tryOpenApp = (data) => {
        const uid = this.props.uData?._id;
        let bundleId;
        const conversationId = data?.conversation;
        const initiator = data?.initiator;
        if (conversationId) {
            fetch(API_ENDPOINT + '/clinic-groups/check/bundleId', {
                method: 'POST',
                headers: {
                    'content-type': 'application/json'
                },
                body: JSON.stringify({ cid: conversationId })
            }).then(res => res.json()).then((result) => {
                if (result.bundleId) {
                    bundleId = result.bundleId;
                    if (bundleId && conversationId && initiator && uid) {
                        if (bundleId) {
                            try {
                                window.location.replace(`${bundleId}://?conversation=${conversationId}&initiator=${initiator}&uid=${uid}`);
                            } catch (err) { console.log(err) }
                        }
                    }
                }
            });
        }
    }

    answerCall = async (data) => {
        if (this.state.callInProgress) return;
        this.tryOpenApp(data)

        if (!data?.initiator || !data?.conversation) return;

        if (typeof window === 'undefined') return;

        const initiator = data.initiator;
        const conversationId = data.conversation;


        var strWindowFeatures = `height=${window.innerHeight - 1},width=${window.innerWidth - 1},resizable=yes,scrollbars=yes,toolbar=yes,menubar=no,location=no,directories=no, status=yes`;
        let link = `/video-call/${conversationId}?initiator=${initiator}`;
        this.videoCallWindow = window.open(link, "_blank", strWindowFeatures);


        if (this.videoCallWindow) {
            this.videoCallWindow?.addEventListener?.('message',
                (e) => {
                    if (e?.data == 'end-video-call') {
                        this.videoCallWindow?.close?.();
                    }

                }
                , false)
        }


        // localStorage?.setItem('rejectedCalls', conversationId)



    }

    onCall = (data) => {
        // console.log('socket on call data', data)

        if (!this.state.answerData) {
            this.setState({ answerData: data })
        }

    }

    onNewMessage = (data) => {
        if (this.state.events.length > 0) {
            let found = false;
            for (let i = 0; i < this.state.events.length; i++) {
                if (this.state.events[i]?.conversation == data.conversation && this.props.uData?._id != data.uid) {
                    this.state.events[i]._newMessage = true;
                    this.setState({ ...this.state.events })
                    found = true;
                    break;
                }

            }

            if (!found) {
                this.getEvents()

            }
        }
    }

    onActiveVideoCalls = (data) => {
        let foundVideoCall = false;
        // let rejectedCalls = typeof window !== 'undefined' && localStorage?.rejectedCalls ? JSON.parse(JSON.stringify(localStorage?.rejectedCalls)) : ''
        // for (var key in data.myVideoCallsDisconnected) {
        //     if (rejectedCalls.indexOf(key) === -1 && data.myVideoCallsDisconnected[key] && data.myVideoCallsDisconnected[key].conversation && this.props.uData && String(data.myVideoCallsDisconnected[key].initiatorUid) !== String(this.props.uData?._id)) {
        if (data.myVideoCallsDisconnected && Object.keys(data.myVideoCallsDisconnected).length > 0) {
            this.setState({
                // offerData: { ...data.myVideoCallsDisconnected[key] }
                offerData: { ...data.myVideoCallsDisconnected }
            }, () => {
                this.props.setActiveVideoCallInWaitingRoom(true)
            })
        }


        foundVideoCall = true;
        // break;
        // }
        // }

        if (!foundVideoCall) {
            this.setState({
                offerData: null
            }, () => {
                this.props.setActiveVideoCallInWaitingRoom(false)

            })
        }
    }

    onCheckActiveCall = async (data) => {
        let activeCall = data?.activeCall ? true : false;
        if (activeCall !== this.state.callInProgress) {
            this.setState({ callInProgress: activeCall, callInProgressDoctorName: activeCall ? data?.doctorName : '' }, () => {
                if (!this.state.callInProgress && this.state.checkAFKAnswered) {
                    this.setState({ checkAFKAnswered: null })
                }
            })

        }
    }

    registerEvents = () => {
        // this.props.socket.on('call', async (data) => {
        //     console.log('socket on call data', data)

        //     if (!this.state.answerData) {
        //         this.setState({ answerData: data })
        //     }


        // })

        this.props.socket.on('call', (data) => this.onCall(data));

        this.props.socket.on('endCall', async (data) => {
            if (data?.conversation === this.state.answerData?.conversation) {
                this.setState({ answerData: null, checkAFK: null, checkAFKAnswered: false })
            }
            console.log('socket on endCall data', data, this.state.answerData)
            this.checkActiveCall()

        });
        this.props.socket.on('checkActiveCall', async (data) => this.onCheckActiveCall(data)
            //     {
            //     let activeCall = data?.activeCall ? true : false;
            //     if (activeCall !== this.state.callInProgress) {
            //         this.setState({ callInProgress: activeCall, callInProgressDoctorName: activeCall ? data?.doctorName : '' }, () => {
            //             if (!this.state.callInProgress && this.state.checkAFKAnswered) {
            //                 this.setState({ checkAFKAnswered: null })
            //             }
            //         })

            //     }
            // }
        );

        this.props.socket.on('newMessage', (data) => this.onNewMessage(data));

        this.props.socket.on('activeVideoCalls', (data) => this.onActiveVideoCalls(data)
            //     {
            //     let foundVideoCall = false;
            //     // let rejectedCalls = typeof window !== 'undefined' && localStorage?.rejectedCalls ? JSON.parse(JSON.stringify(localStorage?.rejectedCalls)) : ''
            //     // for (var key in data.myVideoCallsDisconnected) {
            //     //     if (rejectedCalls.indexOf(key) === -1 && data.myVideoCallsDisconnected[key] && data.myVideoCallsDisconnected[key].conversation && this.props.uData && String(data.myVideoCallsDisconnected[key].initiatorUid) !== String(this.props.uData?._id)) {
            //     if (data.myVideoCallsDisconnected && Object.keys(data.myVideoCallsDisconnected).length > 0) {
            //         this.setState({
            //             // offerData: { ...data.myVideoCallsDisconnected[key] }
            //             offerData: { ...data.myVideoCallsDisconnected }
            //         }, () => {
            //             this.props.setActiveVideoCallInWaitingRoom(true)
            //         })
            //     }


            //     foundVideoCall = true;
            //     // break;
            //     // }
            //     // }

            //     if (!foundVideoCall) {
            //         this.setState({
            //             offerData: null
            //         }, () => {
            //             this.props.setActiveVideoCallInWaitingRoom(false)

            //         })
            //     }


            // }
        );

        this.props.socket.on('hostEndedCall', (data) => this.onHostEndedCall(data));

        this.props.socket.emit('activeVideoCalls', {})
    }

    onHostEndedCall = (data) => {
        this.props.setActiveVideoCallInWaitingRoom(false)
    }

    rescheduleAppointment = (item) => {
        if (item.groupAlias) {
            fetch(API_ENDPOINT + '/data/without-token/professions/all/' + item.groupAlias, {
                method: 'POST',
                headers: {
                    'content-type': 'application/json'
                },
                body: JSON.stringify({
                    // isDropIn: this.state.isDropIn,
                    uid: this.props.uData && this.props.uData._id ? this.props.uData._id : null
                })
            }).then(res => res.json()).then((result) => {
                if (result) {
                    let availableProfessions = [];
                    for (let i = 0; i < result.length; i++) {
                        availableProfessions.push({
                            name: this.props.lang == 'en' ? result[i].professionEn : result[i].professionSv,
                            value: result[i]._id
                        })
                    }

                    this.setState({ availableProfessions }, () => {
                        this.setState({ rescheduleAppointment: item, unRebookModal: false })
                    })
                    this.getEvents();
                }



            })
        }




    }

    backFromCalendar = () => {
        this.setState({
            rescheduleAppointment: null,
            selectedTime: null,
            availableProfessions: []
        });
    }

    unRebookBBCRCase = (item) => {
        if (item?._id) {
            let obj = {
                _id: item._id,
                requestNewAppointment: item.requestNewAppointment,
                noNewAppointment: item.noNewAppointment,
                reason: item.reason
            }

            fetch(API_ENDPOINT + '/referrals/un-rebook/case', {
                method: 'POST',
                headers: {
                    'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage.getItem('authToken')}` : null,
                    'content-type': 'application/json'
                },
                body: JSON.stringify(obj)
            }).then(res => res.json()).then((result) => {
                if (result.error) {
                    this.setState({
                        error: result.error,
                    })
                } else {
                    this.setState({ unRebookBBCRModal: null })

                }

                this.getEvents();
            })
        }



    }

    rejectWebBookingReferral = (data) => {
        this.setState({
            loading: true
        }, () => {
            fetch(API_ENDPOINT + '/referrals/patient/reject/' + this.state.rejectWebBooking._id, {
                method: 'POST',
                headers: {
                    'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage.getItem('authToken')}` : null,
                    'content-type': 'application/json'
                },
                body: JSON.stringify(data)
            }).then(res => res.json()).then((result) => {

                if (result?.error) {
                    this.setState({
                        error: result.error?.translate(this.props.lang),
                        loading: false
                    })
                } else {
                    this.setState({
                        rejectWebBooking: null,
                    })
                }

                setTimeout(() => {
                    // rejectWebBooking need more time to finish updateing case so this is a workaround
                    this.getEvents();
                }, 300)

            })
        })
    }

    checkPayment = (refId) => {
        if (refId) {
            fetch(API_ENDPOINT + '/referrals/' + refId, {
                method: 'GET',
                headers: {
                    'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage.getItem('authToken')}` : null,
                    'content-type': 'application/json'
                }
            }).then(res => res.json()).then((result) => {
                if (result) {

                    // because of another notification for requested invoice doctor can not see notification that patient rescheduled webbooking we delete userNotifications for same case so just one show
                    // if (result.referralIsPaid) {
                    let selectedTime = `${result.patientReservedTimestamp ? this.props.getStringDateTs(result.patientReservedTimestamp, this.props.dateFormat)/*moment.unix(result.patientReservedTimestamp).format(this.props.dateFormat)*/ : null}${this.props.lang == 'se' ? ' kl. ' : ' '}${result.patientReservedTimestamp ? this.props.getStringDateTs(result.patientReservedTimestamp, 'HH:mm')/*moment.unix(result.patientReservedTimestamp).format('HH:mm')*/ : null}`;

                    let successText = `Thanks! We will remind you via notifications before your meeting that takes place`.translate(this.props.lang) + ` ${selectedTime}`;
                    if (!result?.onlineVisit) {
                        let arr = [];
                        if (result?.clinic?.name) { arr.push(result?.clinic?.name) }
                        if (result?.clinic?.street) { arr.push(result?.clinic?.street) }
                        if (result?.clinic?.zip) { arr.push(result?.clinic?.zip) }
                        if (result?.clinic?.city) { arr.push(result?.clinic?.city) }

                        if (!arr.length) { successText += '.'; }
                        else {
                            successText += `, ${arr.join(', ')}.`;
                        }
                        // successText += `, ${result?.clinic?.name}, ${result?.clinic?.street ? result.clinic.street : ''}, ${result?.clinic?.zip ? result.clinic.zip : ''} ${result?.clinic?.city ? result.clinic.city : ''}.`;
                    } else {
                        successText += '.'
                    }
                    this.setState({ successModal: successText })

                    // } else {
                    //     this.setState({ payNowReferralModal: result })
                    // }

                }
            })
        }

    }

    componentDidMount() {
        if (typeof window !== 'undefined') { window.scrollTo(0, 0); }


        let patientLoggedIn = this.checkIfPatientLoggedIn();
        if (!patientLoggedIn) {
            this.props[0].history.push('/chat')
        }

        this.checkIfGroupIsSelected();

        this.setDots()


        this.get();

        this.registerSocket();

        this.checkActiveCallInterval = setInterval(() => {
            this.checkActiveCall()
        }, 1000 * 5);

        this.getEvents();
    }

    getEvents = () => {

        const alias = this.getGroupAlias();
        if (!alias) {
            this.setState({
                groupError: true
            })
        }

        this.setState({ eventsReceived: false })
        fetch(API_ENDPOINT + '/waiting-room/events/' + this.props.uData?._id + '/' + alias, {
            method: 'GET',
            headers: {
                'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage.getItem('authToken')}` : null,
                'content-type': 'application/json'
            }
        }).then(res => res.json()).then((result) => {
            if (result?.events) {
                this.setState({ events: result.events, dropins: result.dropins })
            } else if (result?.error) {
                // this.setState({ error: result.error })
            }

            this.setState({ eventsReceived: true })
        });
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevProps[0].location.pathname != this.props[0].location.pathname) {
            this.enterWaitingRoom()
        }

        if (this.state.answerData?.initiator && this.state.answerData?.conversation && !this.state.checkAFK && !this.state.checkAFKAnswered) {
            let focus = isWindowFocused();
            if (focus && false) {
                let data = JSON.parse(JSON.stringify(this.state.answerData))
                this.setState({ answerData: null }, () => {
                    this.answerCall(data)
                })
            } else if (!this.state.checkAFK) {
                this.setState({ checkAFK: true })
            }
        }
    }
    componentWillUnmount() {
        this.leaveWaitingRoom()

        if (this.props.socket) {
            // this.props.socket.removeListener('call');
            this.props.socket.removeListener('endCall');
            this.props.socket.removeListener('enterWaitingRoom');
            this.props.socket.removeListener('leaveWaitingRoom');
            // this.props.socket.removeListener('checkActiveCall');
            this.props.socket.removeListener('checkActiveCall', this.onCheckActiveCall);
            // this.props.socket.removeListener('activeVideoCalls');
            this.props.socket.removeListener('activeVideoCalls', this.onActiveVideoCalls);
            // this.props.socket.removeListener('newMessage');

            this.props.socket.removeListener('call', this.onCall);
            this.props.socket.removeListener('hostEndCall', this.onHostEndedCall)
            this.props.socket.removeListener('newMessage', this.onNewMessage);

        }
        if (this.dotsInterval) {
            clearInterval(this.dotsInterval)
        }
        if (this.checkActiveCallInterval) {
            clearInterval(this.checkActiveCallInterval)
        }



    }

    setDots = () => {
        this.dotsInterval = setInterval(() => {
            let dotsLength = this.state.dots?.length ?? 3;
            let newDotsLength = 1;
            if (dotsLength < 3) {
                newDotsLength = dotsLength + 1;
            }
            let dots = [];
            for (let i = 0; i < newDotsLength; i++) {
                dots.push('.')
            }
            this.setState({ dots })
        }, 500);
    }

    enterWaitingRoom = () => {
        const alias = this.getGroupAlias();
        this.checkActiveCall()
        if (alias) {
            this.props.socket.emit('enterWaitingRoom', { alias: alias })
        }

    }
    leaveWaitingRoom = () => {
        const alias = this.getGroupAlias();
        if (this.props.socket && alias)
            this.props.socket.emit('leaveWaitingRoom', { alias: alias })

    }
    checkActiveCall = () => {
        if (this.props.socket) {
            this.props.socket.emit('checkActiveCall', {})
        }
    }
    pauseAudioIncommingCall = () => {
        // if (this.audioIncomingCall) {
        //   this.audioIncomingCall.pause();
        // }
    }

    makePatientName = () => {

        if (this.props.uData && this.props.uData.userData && this.props.uData.userData.name) {
            return this.props.uData.userData.name.split(' ')[0][0].toUpperCase() + this.props.uData.userData.name.split(' ')[0].slice(1).toLowerCase();
        }
    }

    render() {
        return (
            <div className={this.props.autoHideSidebar ? "dashboard dashboard-auto-hide" : "dashboard"}>
                {/* {
                    this.state.checkAFK && !this.state.callInProgress ? (
                        // <Modal isOpen={this.state.checkAFK} centered>
                        //     <ModalBody className="delete-modal">
                        //         <p className="message">{'Incoming call from'.translate(this.props.lang)} {this.state.answerData?.user?.name}</p>
                        //     </ModalBody>

                        //     <ModalFooter className='buttons-right-blue'>
                        //         <Button style={{ minWidth: 100 }} onClick={() => {
                        //             this.setState({ checkAFK: null, checkAFKAnswered: true }, () => {
                        //                 let data = JSON.parse(JSON.stringify(this.state.answerData))
                        //                 this.setState({ answerData: null }, () => {
                        //                     this.answerCall(data)
                        //                 })
                        //             })
                        //         }}>{'Accept'.translate(this.props.lang)}</Button>
                        //     </ModalFooter>
                        // </Modal>
                        <VideoCallPopUp
                            lang={this.props.lang}
                            profilePicture={this.state.answerData?.user?.profilePicture ? API_ENDPOINT + this.state.answerData.user.profilePicture : profilePicture}
                            name={this.state.answerData?.user?.name ?? ''}
                            answer={() => {
                                this.setState({ checkAFK: null, checkAFKAnswered: true }, () => {
                                    let data = JSON.parse(JSON.stringify(this.state.answerData))
                                    this.setState({ answerData: null }, () => {
                                        this.answerCall(data)
                                    })
                                })

                            }}
                            style={{ position: 'none', zIndex: 2 }}
                            uData={this.props.uData}
                            socket={this.props.socket}
                            pauseAudioIncommingCall={this.pauseAudioIncommingCall}
                        />
                    ) : null
                } */}
                {
                    this.state.groupError ? (
                        <Modal isOpen={this.state.groupError} centered>
                            <ModalBody className="delete-modal">
                                <p className="message">{'You have to select the group!'.translate(this.props.lang)}</p>
                            </ModalBody>

                            <ModalFooter className='buttons-right-blue'>
                                <Button style={{ minWidth: 100 }} onClick={() => {
                                    this.redirectToLadning()
                                }}>{'Close'.translate(this.props.lang)}</Button>
                            </ModalFooter>
                        </Modal>
                    ) : null
                }
                {
                    this.state.error ?
                        <ErrorModal lang={this.props.lang}
                            isOpen={this.state.error}
                            toggle={() => this.setState({ error: null }, () => {

                            })}
                        >
                            {this.state.error.translate(this.props.lang)}
                        </ErrorModal>

                        :
                        null
                }
                {/* {
                    !this.state.checkAFK ? */}
                <Container fluid>
                    <Row>
                        <Col lg="12">
                            <div className="panel pading-20">

                                {
                                    this.props.uData && this.props.uData.userLevel === 1 ?
                                        <div className='waiting-room-lvl1-buttons'>
                                            <h5 className="component-header mb-35">{"Video waiting room".translate(this.props.lang)}</h5>
                                            <Button color='primary' className='event-reschedule-waitiing-room cursor-pointer border-none' onClick={() => {
                                                const alias = this.getGroupAlias();
                                                this.props[0].history.push(`/${alias}`)
                                            }}>{"Return to Homepage".translate(this.props.lang)}</Button>
                                        </div>

                                        :
                                        <h5 className="component-header mb-35">{"Video waiting room".translate(this.props.lang)}</h5>
                                }


                                <h5>{"Hi ".translate(this.props.lang)}{this.makePatientName()},</h5>



                                {
                                    this.state.eventsReceived ?
                                        this.state.events.length === 0 && this.state.dropins.length === 0 ?
                                            <p>{"Welcome to our video waiting room. Please wait for us to call you.".translate(this.props.lang)}</p>
                                            :
                                            <p>{"Welcome to our video waiting room. You can see your scheduled appointements below. Please wait for us to call you.".translate(this.props.lang)}</p>
                                        :
                                        null
                                }

                                {
                                    this.state.dropins.length > 0 ? this.state.dropins.map((dropin, index) => {
                                        return (
                                            <div className='waiting-room-event-box'>
                                                <div className='drop-in-waiting-room'>
                                                    {/* <div className='waiting-room-event'>
                                                            <div>
                                                                <div className='event-date'>{"Drop-in".translate(this.props.lang)} {dropin?.patient?.referralNumber}</div>
                                                                <div className='event-title'><strong>{dropin?.questionaryName}</strong></div>
                                                            </div>
                                                            <div className='waiting-room-chat-icon-box' onClick={() => {
                                                                if (dropin?.conversation && dropin?.groupAlias) {
                                                                    localStorage.setItem('groupAlias', dropin?.groupAlias)
                                                                    this.props[0].history.push(`/chat?conversation=${dropin?.conversation}`)
                                                                }

                                                            }}>
                                                                {
                                                                    dropin.conversation ? <div className='waiting-room-chat-icon'>
                                                                        <div className={`${dropin._newMessage ? 'waiting-room-red-dot' : ''}`}></div>
                                                                        <Isvg src={ChatIcon} />
                                                                    </div> : null
                                                                }
                                                            </div>
                                                        </div> */}

                                                    <div className='calendar-days-img-waiting-room'>
                                                        <Isvg src={CalendarDays} />
                                                    </div>
                                                    <div>
                                                        <div ><strong>{dropin.doctorName}</strong></div>
                                                        <div >{"Drop-in".translate(this.props.lang)} {dropin?.patient?.referralNumber}</div>
                                                        <div className='visit-reson-box-waiting-room'>
                                                            <div className='visit-reson-waiting-room'>
                                                                {'Visit reason:'.translate(this.props.lang)}
                                                            </div>
                                                            <div > <strong>{dropin?.questionaryName}</strong></div>
                                                        </div>


                                                    </div>
                                                </div>
                                                {/* {
                                                    dropin.conversation ?
                                                        <div className='event-reschedule-waitiing-room cursor-pointer' onClick={() => {
                                                            if (dropin?.conversation && dropin?.groupAlias) {
                                                                localStorage.setItem('groupAlias', dropin?.groupAlias)
                                                                this.props[0].history.push(`/chat?conversation=${dropin?.conversation}`)
                                                            }

                                                        }}>
                                                            <div>
                                                                {"Open conversation".translate(this.props.lang)}
                                                            </div>
                                                            <div className={`${dropin._newMessage ? 'waiting-room-red-dot' : ''}`}></div>

                                                        </div>
                                                        :
                                                        null
                                                } */}
                                            </div>

                                        )
                                    })


                                        :
                                        null
                                }

                                {
                                    this.state.events.sort((a, b) => a.startTime - b.startTime).map((event, index) => {
                                        return (
                                            // <div key={index} className='waiting-room-event'>
                                            //     <div>
                                            //         <div className='event-date'>{moment(event?.startTime * 1000).format('DD/MM/YYYY HH:mm')}</div>
                                            //         <div className='event-title'><strong>{event?.title}</strong></div>
                                            //     </div>
                                            //     <div className='waiting-room-chat-icon-box' onClick={() => {
                                            //         if (event?.conversation && event?.groupAlias) {
                                            //             localStorage.setItem('groupAlias', event?.groupAlias)
                                            //             this.props[0].history.push(`/chat?conversation=${event?.conversation}`)
                                            //         }

                                            //     }}>


                                            //         {
                                            //             event.conversation ? <div className='waiting-room-chat-icon'>
                                            //                 <div className={`${event._newMessage ? 'waiting-room-red-dot' : ''}`}></div>
                                            //                 <Isvg src={ChatIcon} />
                                            //             </div> : null
                                            //         }

                                            //     </div>

                                            // </div>
                                            <div className='waiting-room-event-box'>
                                                <div className='event-waiting-room'>

                                                    <div className='calendar-days-img-waiting-room'>
                                                        <Isvg src={CalendarDays} />
                                                    </div>
                                                    <div>
                                                        <div ><strong>{event.doctorName ? event.doctorName : event.case && event.case.doctorName ? event.case.doctorName : ''}</strong></div>
                                                        <div className='visit-reson-box-waiting-room'>
                                                            <div className='visit-reson-waiting-room'>
                                                                {'Visit reason:'.translate(this.props.lang)}
                                                            </div>
                                                            <div ><strong>{event?.visitReasonName ? event.visitReasonName : event.case && event.case.questionaryName ? event.case.questionaryName : ''}</strong></div>
                                                        </div>
                                                        <div className='visit-reson-box-waiting-room'>
                                                            <div className='visit-reson-waiting-room'>
                                                                {'Scheduled:'.translate(this.props.lang)}
                                                            </div>
                                                            <div ><strong>{moment(event?.startTime * 1000).format("YYYY-MM-DD HH:mm")}</strong></div>
                                                        </div>


                                                    </div>
                                                </div>
                                                <div className='waiting-room-buttons'>
                                                    {
                                                        event.case && (((event.case.status === 'scheduled' || event.case.status === 'visit-rescheduled') && event.case.wayOfScheduling === 'webBooking') || ((event.case.status === 'scheduled' || event.case.status === 'visit-rescheduled') && event.case.wayOfScheduling === 'bookingByClinic') || (event.case.status === 'revisit-scheduled' || event.case.status === 'visit-rescheduled')) && event.case.patientReservedTimestamp - 15 * 60 > new Date().getTime() / 1000 ?
                                                            <div className='event-reschedule-waitiing-room cursor-pointer green' onClick={() => {
                                                                if ((event.case.status === 'scheduled' || event.case.status === 'visit-rescheduled') && event.case.wayOfScheduling === 'webBooking') {
                                                                    this.setState({ unRebookModal: event.case })
                                                                } else {
                                                                    this.setState({ unRebookBBCRModal: { ...event.case, requestNewAppointment: false, noNewAppointment: false, reason: '' } })
                                                                }
                                                            }} >
                                                                {/* <div className='x-reschedule-waiting-room'> X </div> */}
                                                                <div> {"Reschedule/cancel".translate(this.props.lang)} </div>
                                                            </div>
                                                            : null
                                                    }
                                                    {/* <div className='event-reschedule-waitiing-room cursor-pointer'>
                                                            <div className='x-reschedule-waiting-room'> X </div>
                                                            <div> {"Reschedule/cancel".translate(this.props.lang)} </div>
                                                        </div> */}
                                                    {/* {
                                                        event.conversation ?
                                                            <div className='event-reschedule-waitiing-room cursor-pointer' onClick={() => {
                                                                if (event?.conversation && event?.groupAlias) {
                                                                    localStorage.setItem('groupAlias', event?.groupAlias)
                                                                    this.props[0].history.push(`/chat?conversation=${event?.conversation}`)
                                                                }

                                                            }}>
                                                                <div>
                                                                    {"Open conversation".translate(this.props.lang)}
                                                                </div>
                                                                <div className={`${event._newMessage ? 'waiting-room-red-dot' : ''}`}></div>

                                                            </div>
                                                            :
                                                            null
                                                    } */}
                                                </div>

                                            </div>

                                        )
                                    })
                                }
                                {
                                    this.state.eventsReceived && this.state.events.length === 0 && this.state.dropins.length === 0 ?
                                        <div className='waiting-room-wrap'>
                                            <p className='no-events-message-waiting-room'>{"You have no scheduled appointements".translate(this.props.lang)}</p>

                                            <div className='image-1'>
                                                <img src={waitingRoom3} />
                                            </div>

                                        </div>
                                        : null
                                }


                                <div>
                                    <div className='call-button-waiting-room'>
                                        {
                                            this.state.offerData && this.props.activeVideoCallInWaitingRoom ?
                                                Object.keys(this.state.offerData).map((key, index) => {
                                                    return (
                                                        <div className='waiting-room-offer' onClick={() => {
                                                            this.answerCall(this.state.offerData[key])
                                                        }}>
                                                            <>{"Click to join the call with ".translate(this.props.lang)} {this.state.offerData[key]?.user?.name}</>
                                                            <Isvg src={incommingCallIcon} />
                                                        </div>
                                                    )
                                                })
                                                :
                                                null
                                        }
                                    </div>
                                    {/* {
                                                this.state.events.length > 0 ? <h6 className="mb-35">{"Doctor can call you at the time of the event, but that there is a possibility that the doctor will use another form of communication for scheduled events.".translate(this.props.lang)}</h6> : null
                                            } */}


                                </div>
                            </div>
                        </Col>

                    </Row>
                </Container>
                {/* //         : null
                // } */}

                {
                    this.state.unRebookModal ?
                        <Modal isOpen={this.state.unRebookModal} centered >

                            <ModalFooter className="buttons-right-blue column-buttons">
                                {
                                    this.state.unRebookModal.activeWebBooking ?
                                        <Button color="primary" style={{ margin: 10 }} onClick={() => {
                                            let item = { ...this.state.unRebookModal }
                                            this.rescheduleAppointment(item)

                                        }}>{'Reschedule appointment'.translate(this.props.lang)}</Button>
                                        :
                                        null
                                }

                                <Button color="primary" style={{ margin: 10 }} onClick={() => {
                                    let item = { ...this.state.unRebookModal }
                                    this.setState({ rejectWebBooking: item, unRebookModal: false })
                                }}>{'Cancel appointment'.translate(this.props.lang)}</Button>
                                <Button color="primary" style={{ margin: 10 }} onClick={() => this.setState({ unRebookModal: false })}>{'Cancel'.translate(this.props.lang)}</Button>
                            </ModalFooter>
                        </Modal>
                        : null
                }

                {
                    this.state.unRebookBBCRModal ?
                        <Modal isOpen={this.state.unRebookBBCRModal} centered >
                            <ModalHeader>
                                <h5>{'Un/rebook'.translate(this.props.lang)}</h5>
                            </ModalHeader>
                            <ModalBody className='un-rebook-case-modal-body-wrap'>
                                <div className='radio-button-field-wrap' onClick={() => {
                                    let unRebookBBCRModal = this.state.unRebookBBCRModal;
                                    unRebookBBCRModal.requestNewAppointment = true;
                                    unRebookBBCRModal.noNewAppointment = false;
                                    unRebookBBCRModal.reason = '';
                                    this.setState({ unRebookBBCRModal })
                                }}>
                                    <div className="radio-button-field-round" >{this.state.unRebookBBCRModal.requestNewAppointment ? <div className='radio-button-field-round-checked'></div> : null}</div>
                                    <h6>{"Request new appointment".translate(this.props.lang)}</h6>
                                </div>

                                <div className='radio-button-field-wrap' onClick={() => {
                                    let unRebookBBCRModal = this.state.unRebookBBCRModal;
                                    unRebookBBCRModal.requestNewAppointment = false;
                                    unRebookBBCRModal.noNewAppointment = true;
                                    unRebookBBCRModal.reason = '';
                                    this.setState({ unRebookBBCRModal })
                                }}>
                                    <div className="radio-button-field-round" >{this.state.unRebookBBCRModal.noNewAppointment ? <div className='radio-button-field-round-checked'></div> : null}</div>
                                    <h6>{"No new appointment".translate(this.props.lang)}</h6>
                                </div>
                                {
                                    this.state.unRebookBBCRModal.requestNewAppointment || this.state.unRebookBBCRModal.noNewAppointment ?
                                        <FormGroup className='reason-wrap'>
                                            <Label>{this.state.unRebookBBCRModal.requestNewAppointment ? 'What time would best suit you?'.translate(this.props.lang) : "State reason:".translate(this.props.lang)}</Label>
                                            <Input type='textarea' value={this.state.unRebookBBCRModal.reason} onChange={(e) => {
                                                let unRebookBBCRModal = this.state.unRebookBBCRModal;
                                                unRebookBBCRModal.reason = e.target.value;
                                                this.setState({ unRebookBBCRModal })
                                            }} />
                                        </FormGroup>
                                        :
                                        null
                                }


                            </ModalBody>

                            <ModalFooter className="buttons-right-blue column-buttons">
                                <Button
                                    color="primary"
                                    style={{ margin: 10 }}
                                    disabled={(this.state.unRebookBBCRModal.requestNewAppointment || this.state.unRebookBBCRModal.noNewAppointment) && this.state.unRebookBBCRModal.reason?.trim() ? false : true}
                                    onClick={() => {
                                        let item = { ...this.state.unRebookBBCRModal }
                                        this.unRebookBBCRCase(item)
                                    }}
                                >
                                    {'Submit'.translate(this.props.lang)}
                                </Button>

                                <Button color="primary" style={{ margin: 10 }} onClick={() => this.setState({ unRebookBBCRModal: null })}>{'Cancel'.translate(this.props.lang)}</Button>
                            </ModalFooter>
                        </Modal>
                        : null
                }

                {
                    this.state.rejectWebBooking ?
                        <FormBuilder selectedGroup={this.props.selectedGroup} selectedClinic={this.props.selectedClinic} countryTwoLetter={this.props.countryTwoLetter} country={this.props.country} dateFormat={this.props.dateFormat}
                            getStringDateTs={this.props.getStringDateTs}
                            lang={this.props.lang}
                            onSubmit={(data) => this.rejectWebBookingReferral(data)}
                            isOpen={this.state.rejectWebBooking}
                            toggle={() => this.setState({ rejectWebBooking: null })}
                            title={'Cancel appointment'.translate(this.props.lang)}
                            buttonText={'Confirm cancellation'.translate(this.props.lang)}
                            additionalButton={<Button color="primary" onClick={() => this.setState({ rejectWebBooking: !this.state.rejectWebBooking })}>{'Close'.translate(this.props.lang)}</Button>}
                            modalFooterClassname={'buttons-right-blue'}
                            fields={[
                                {
                                    type: 'row',
                                    children: [
                                        {
                                            type: 'col',
                                            width: {
                                                lg: 12,
                                                sm: 12,
                                                xs: 12
                                            },
                                            children: [
                                                {
                                                    type: 'textarea',
                                                    name: 'reason',
                                                    height: 100,
                                                    label: 'Reason for cancellation'.translate(this.props.lang),
                                                    validate: [required('Reason for cancellation is required!'.translate(this.props.lang))]
                                                },
                                            ]
                                        },

                                    ]
                                }
                            ]}
                        ></FormBuilder>
                        :
                        null
                }

                {
                    this.state.rescheduleAppointment ?
                        <Modal isOpen={this.state.rescheduleAppointment} centered size="xxl" className="calendar-modal webbooking-calendar-patient">
                            <ModalHeader>

                                <ModalHeader
                                    toggle={() => this.setState({ rescheduleAppointment: !this.state.rescheduleAppointment })}
                                    close={<button type="button" className="close" style={{ position: 'absolute', top: '15px', right: '15px' }} onClick={() => this.setState({ rescheduleAppointment: !this.state.rescheduleAppointment })}><Isvg src={xIcon} /></button>}
                                    className='webbooking-calendar-patient-header'
                                >
                                    <div className='back-button-wrap' onClick={() => this.backFromCalendar()}>
                                        <Isvg src={arrowLeftIcon} />
                                        <div className='back-button-text'>{'Back'.translate(this.props.lang)}</div>
                                    </div>
                                    <div className='webbooking-calendar-patient-title'>{'Reschedule appointment'.translate(this.props.lang)}</div>
                                    <div className='empty-div'></div>
                                </ModalHeader>

                            </ModalHeader>
                            <ModalBody>
                                <RescheduleWebBookingCalendar
                                    {...this.props}
                                    referral={this.state.rescheduleAppointment}
                                    closeCalendarModal={(item) => this.setState({ rescheduleAppointment: item })}
                                    personalQuestionary={this.state.rescheduleAppointment.questionaryData}
                                    selectedReferral={this.state.rescheduleAppointment.questionaryData}
                                    backFromCalendar={() => this.backFromCalendar()}
                                    setSelectedTime={(selectedTime) => this.setState({ selectedTime })}
                                    price={this.state.rescheduleAppointment.questionaryData?.price}
                                    availableProfessions={this.state.availableProfessions}
                                    changeSelectedTime={this.state.rescheduleAppointment._id}
                                    displayPaymentInfoText={this.state.rescheduleAppointment.displayPaymentInfoText ? true : false}
                                    // createdReferral={(item) => this.setState({ createdReferral: item }, () => this.sendReferral())}
                                    createdReferral={(item) => {
                                        this.checkPayment(item)

                                        this.setState({ rescheduleAppointment: null }, () => {
                                            this.getEvents()
                                        })
                                    }}
                                />


                            </ModalBody>

                        </Modal>

                        :
                        null
                }

                {this.state.successModal ?
                    <Modal isOpen={this.state.successModal} centered zIndex={99999}>
                        {/* <ModalHeader style={{ margin: 'auto' }}>{'Notification'.translate(this.props.lang)}</ModalHeader> */}
                        <ModalBody className="delete-modal">
                            {/* <div className="check-icon"> */}
                            {/* <Isvg src={info} /> */}
                            <p className='message'>
                                {this.state.successModal}
                            </p>
                            {/* </div> */}
                        </ModalBody>
                        <ModalFooter>
                            <Button color="primary" style={{ margin: 'auto', minWidth: 100 }} onClick={() => this.setState({ successModal: null })}>{'Ok'.translate(this.props.lang)}</Button>
                        </ModalFooter>
                    </Modal>
                    : null}

            </div>
        );
    }
}

export default Page(WaitingRoom);