import React, { Component } from 'react';
import Page from '../containers/page';

import {
    Container,
    Row,
    Col,
    Modal, ModalHeader, ModalBody, ModalFooter, Button, Input, UncontrolledDropdown, DropdownToggle, DropdownMenu, DropdownItem, FormGroup, Label
} from 'reactstrap';
import CreateReferralModal from '../components/createHealthCareModal';
import CreateReferralIntelligentModal from '../components/createHealthCareIntelligent';



import FormBuilder from '../components/forms/modalFormBuilder';
import { required } from '../components/forms/validation';
import InfoModal from '../components/infoModal';

import ErrorModal from '../components/errorModal';
import ListBuilder from '../components/listBuilder';
import DeleteModal from '../components/deleteModal';
import { getSearchParams, generateSearchLink, generateSearchLinkMultiple } from '../helpers/linkHelper';
import { API_ENDPOINT } from '../constants';
import { formValueSelector, change } from 'redux-form';  // ES6
import { connect } from 'react-redux';
import ReferralClinics from '../components/referralClinics';
import moment from 'moment';
import socketIO from 'socket.io-client';
import profilePicture from '../assets/images/user.png';
import Isvg from 'react-inlinesvg';
import send from '../assets/svg/send.svg';
import attach from '../assets/svg/attach.svg';
import image from '../assets/svg/image.svg';
import profileUser from '../assets/images/user.png';
import notification from '../assets/svg/notification.svg';
import moreIcon from '../assets/svg/more.svg';
import docIcon from '../assets/svg/doc.svg';
import newMessage from '../assets/svg/new-message.svg'
import Search from '../components/search'
import call from '../assets/svg/call.svg';
import ringSound from '../assets/sound/ring.mp3';
import incomingCall from '../assets/sound/incoming_call.mp3';
import addIcon from '../assets/svg/add-icon.svg';

import chatComponentIcon from '../assets/svg/chat-component-icon.svg';


import micIcon from '../assets/svg/mic.svg';
import micMutedIcon from '../assets/svg/mic_muted.svg';
import cameraIcon from '../assets/svg/camera.svg';
import cameraOffIcon from '../assets/svg/camera_off.svg';
import screenShareIcon from '../assets/svg/screen_share.svg';
import translateIcon from '../assets/svg/tr.svg';
import handIcon from '../assets/svg/hand.svg';
import handSound from '../assets/sound/raise.mp3';
import moveIcon from '../assets/svg/move.svg';
import speakerIcon from '../assets/svg/speaker.svg';
import shareIcon from '../assets/svg/share.svg';
import settingsIcon from '../assets/svg/settings.svg';
import secureIcon from '../assets/svg/secure.svg';
import videoChatIcon from '../assets/svg/video.svg';
import userChatIcon from '../assets/svg/user.svg';
import resizeMessageBox from '../assets/svg/resizeMessageBox.svg';

import { Player, Controls } from "@lottiefiles/react-lottie-player";
import GridLayout from 'react-grid-layout';
import garabage from '../assets/svg/garbage.svg'
import flip from '../assets/svg/flip.svg'

import 'react-grid-layout/css/styles.css'
import 'react-resizable/css/styles.css'
import warrning from '../assets/svg/warrning.svg';
import peopleIcon from '../assets/svg/people.svg';
import camIcon from '../assets/svg/cam.svg';

import starIcon from '../assets/svg/star.svg';
import fullStarIcon from '../assets/svg/full-star.svg';
import photoIcon from '../assets/svg/photo.svg';
import galleryIcon from '../assets/svg/gallery.svg';
import newTab from '../assets/svg/newtab.svg';
import phraseIcon from '../assets/svg/frase_chat.svg';
import info from '../assets/svg/info.svg'
import formIcon from '../assets/svg/attach-form.svg';
import journalIcon from '../assets/svg/journal-icon.svg';
import videoIcon from '../assets/svg/video-icon.svg';

import PdfSaveV2 from '../components/pdfSaveV2';
import PrintHelper from '../components/printHelperV2';
import PdfSave from '../components/saveAsPdf';

import striptags from 'striptags'

import { v4 as uuidv4 } from 'uuid';
import { Rnd } from 'react-rnd';
import WebRTCTest from '../components/webRTCTest';
import SoundMeter from '../helpers/soundMeter';
import BankdIDIdentity from '../components/bankIDIdentity';

import { Waypoint } from 'react-waypoint';
import TagsInput from 'react-tagsinput';


import axios from 'axios'

import { UPLOAD_EXTENSIONS } from '../views/chat/uploadExtensions';

function parseJSONUpload(response) {
    if (response?.status === 500) {
        return { error: 'Wrong file type!' }
    } else {
        return response.json()
    }
}
function parseTextUpload(response) {
    if (response?.status === 500) {
        return { error: 'Wrong file type!' }
    } else {
        return response.text()
    }
}



var browserInfo = require('browser-info');


function iosDevice() {
    //return true;
    var userAgent = window.navigator.userAgent;
    if (userAgent.match(/iPad/i) || userAgent.match(/iPhone/i)) {
        return true;
    }
    else {
        return false;
    }
}


function detectMob() {
    //return true;
    const toMatch = [
        /Android/i,
        /webOS/i,
        /iPhone/i,
        /iPad/i,
        /iPod/i,
        /BlackBerry/i,
        /Windows Phone/i
    ];

    return toMatch.some((toMatchItem) => {
        return navigator.userAgent.match(toMatchItem);
    });
}

function getOffset(el) {
    var _x = 0;
    var _y = 0;
    while (el && !isNaN(el.offsetLeft) && !isNaN(el.offsetTop)) {
        _x += el.offsetLeft - el.scrollLeft;
        _y += el.offsetTop - el.scrollTop;
        el = el.offsetParent;
    }
    return { top: _y, left: _x };
}


function getObjectFitSize(contains /* true = contain, false = cover */, containerWidth, containerHeight, width, height) {
    var doRatio = width / height;
    var cRatio = containerWidth / containerHeight;
    var targetWidth = 0;
    var targetHeight = 0;
    var test = contains ? (doRatio > cRatio) : (doRatio < cRatio);

    if (test) {
        targetWidth = containerWidth;
        targetHeight = targetWidth / doRatio;
    } else {
        targetHeight = containerHeight;
        targetWidth = targetHeight * doRatio;
    }

    return {
        width: targetWidth,
        height: targetHeight,
        x: (containerWidth - targetWidth) / 2,
        y: (containerHeight - targetHeight) / 2
    };
}


// Find the line in sdpLines that starts with |prefix|, and, if specified,
// contains |substr| (case-insensitive search).
function findLine(sdpLines, prefix, substr) {
    return findLineInRange(sdpLines, 0, -1, prefix, substr);
}

// Find the line in sdpLines[startLine...endLine - 1] that starts with |prefix|
// and, if specified, contains |substr| (case-insensitive search).
function findLineInRange(
    sdpLines,
    startLine,
    endLine,
    prefix,
    substr,
    direction
) {
    if (direction === undefined) {
        direction = 'asc';
    }

    direction = direction || 'asc';

    if (direction === 'asc') {
        // Search beginning to end
        var realEndLine = endLine !== -1 ? endLine : sdpLines.length;
        for (var i = startLine; i < realEndLine; ++i) {
            if (sdpLines[i].indexOf(prefix) === 0) {
                if (!substr ||
                    sdpLines[i].toLowerCase().indexOf(substr.toLowerCase()) !== -1) {
                    return i;
                }
            }
        }
    } else {
        // Search end to beginning
        var realStartLine = startLine !== -1 ? startLine : sdpLines.length - 1;
        for (var j = realStartLine; j >= 0; --j) {
            if (sdpLines[j].indexOf(prefix) === 0) {
                if (!substr ||
                    sdpLines[j].toLowerCase().indexOf(substr.toLowerCase()) !== -1) {
                    return j;
                }
            }
        }
    }
    return null;
}

function CodecSwitch() {
    this.codecs = null;
}

/**
 * Extracts video and audio codecs from an SDP
 * @param  {string} sdp An SDP as string
 * @return {object}     Returns an object with video and audio codecs
 */
CodecSwitch.prototype.extractCodecs = function (sdp) {
    this.codecs = {
        video: {
            num: [],
            name: [],
            lines: []
        },
        audio: {
            num: [],
            name: [],
            lines: []
        }
    };

    var sdpLines = sdp.split('\r\n');
    for (var i = 0; i < sdpLines.length; i++) {
        if (sdpLines[i].search('m=audio') !== -1) {
            var mAudioLineIndex = i;
            break;
        }
    }

    for (var i = 0; i < sdpLines.length; i++) {
        if (sdpLines[i].search('m=video') !== -1) {
            var mVideoLineIndex = i;
            break;
        }
    }

    // get codec numbers from the mline
    this.codecs.audio.num = sdpLines[mAudioLineIndex].split(" ").slice(3);
    this.codecs.audio.name = new Array(this.codecs.audio.num.length);

    this.codecs.video.num = sdpLines[mVideoLineIndex].split(" ").slice(3);
    this.codecs.video.name = new Array(this.codecs.video.num.length);

    for (var i = 0; i < this.codecs.audio.num.length; i++) {
        console.log("Extracting:" + this.codecs.audio.num[i]);
        for (var j = 0; j < sdpLines.length; j++) {
            var temp = sdpLines[j].split(new RegExp("a=(\\w+):" + this.codecs.audio.num[i] + " ", 'i'));

            if (temp.length > 2) {
                if (this.codecs.audio.lines[i]) {
                    this.codecs.audio.lines[i].push(j)
                } else {
                    this.codecs.audio.lines[i] = [j];
                }
                console.log(temp);
                if (temp[1] == "rtpmap") {
                    console.log(temp);
                    this.codecs.audio.name[i] = temp[2];
                }
            }
        }
    }

    for (var i = 0; i < this.codecs.video.num.length; i++) {
        console.log("Extracting:" + this.codecs.video.num[i]);
        for (var j = 0; j < sdpLines.length; j++) {
            var temp = sdpLines[j].split(new RegExp("a=(\\w+):" + this.codecs.video.num[i] + " ", 'i'));

            if (temp.length > 2) {
                if (this.codecs.video.lines[i]) {
                    this.codecs.video.lines[i].push(j)
                } else {
                    this.codecs.video.lines[i] = [j];
                }
                if (temp[1] == "rtpmap") {
                    console.log(temp);
                    this.codecs.video.name[i] = temp[2];
                }
            }
        }
    }

    return this.codecs;
}

/**
 * Modifies an SDP to prefer the codec given in numA (audio) and numV (video)
 * @param  {int} numA number of preferred audio codec
 * @param  {int} numV number of preferred video codec
 * @param  {string} sdp  a session description
 * @return {string}      Return sdp with preferred codecs
 */
CodecSwitch.prototype.preferCodec = function (numA, numV, sdp) {
    if (this.codecs === null) {
        this.codecs = this.extractCodecs(sdp);
    }

    numA = numA.toString();
    numV = numV.toString();

    var index = this.codecs.audio.num.indexOf(numA);

    var sdpLines = sdp.split('\r\n');

    if (index >= 0) {
        // get the mLine again
        for (var i = 0; i < sdpLines.length; i++) {
            if (sdpLines[i].search('m=audio') !== -1) {
                var mAudioLineIndex = i;
                break;
            }
        }
        var line = sdpLines[mAudioLineIndex].split(" ");

        // splitting the array in half... line has the beginning of the line and
        // nums has the codec numbers
        var nums = line.splice(3);

        // remove codec number first and then bring it to first position
        nums.splice(index, 1);
        nums.unshift(numA);

        // [HOTFIX][DEBUG][TEST] just set it as the only codec
        // nums = [numA];  // the problem is, the offer doesn't get processed... so no answer 

        // SOLUTION 1: Reorder RTP maps
        // --> get selected codec in front
        var aLines = sdpLines.splice(this.codecs.audio.lines[index][0], this.codecs.audio.lines[index].length);

        for (var i = aLines.length - 1; i >= 0; i--) {
            // should be the first position in a lines, 
            // TODO: but would be better to search again
            var first = this.codecs.audio.lines[0][0];
            sdpLines.splice(first, 0, aLines[i]);
        }

        // SOLUTION 2: Remove all codecs rtpmaps above the specified codec

        // Bring the line back together
        line = line.concat(nums);

        // put it back into SDP
        sdpLines[mAudioLineIndex] = line.toString().replace(/,/g, " ");

    }

    index = this.codecs.video.num.indexOf(numV);
    if (index >= 0) {
        // get the mLine
        for (var i = 0; i < sdpLines.length; i++) {
            if (sdpLines[i].search('m=video') !== -1) {
                var mVideoLineIndex = i;
                break;
            }
        }

        var line = sdpLines[mVideoLineIndex].split(" ");

        // splitting the array in half... line has the beginning of the line and
        // nums has the codec numbers
        var nums = line.splice(3);

        // remove codec number first and then bring it to first position
        nums.splice(index, 1);
        nums.unshift(numV);

        // [HOTFIX][DEBUG][TEST] just set it as the only codec
        // nums = [numV]; // the problem is, the offer doesn't get processed... so no answer 

        // Bring the line back together
        line = line.concat(nums);

        // put it back into SDP
        sdpLines[mVideoLineIndex] = line.toString().replace(/,/g, " ");
    }

    // store everything to sdp and convert to string
    sdp = sdpLines.toString().replace(/,/g, "\r\n");

    return sdp;
}



function generateAlias(str) {
    str = str.toLowerCase();
    str = str.replace(/ä/g, 'a');
    str = str.replace(/ö/g, 'o');
    str = str.replace(/ü/g, 'u');
    str = str.replace(/ß/g, 'b');

    str = str.replace(/[^a-zA-Z0-9]/gi, '-').toLowerCase()
    str = str.replace(/-+/g, '-');

    return str;
}

export class ChatComponent extends Component {
    constructor(props) {
        super(props);

        this.peers = {};
        this.candidates = {};
        this.remoteVideo = {};
        this.packetLoss = {};
        this.peerTrackers = {};
        this.initiatorPeers = {};
        this.peerStreams = {};
        this.tmpStreams = [];
        this.peerStatus = {};
        this.peersBandwidth = {};
        this.reconnectingPeer = {};
        this.peerCandidates = {};
        this._checkingConnection = {};

        this.newTabWindows = [];

        this.state = {
            ...props.initialData,
            form: false,
            useState: false,
            params: {
                entries: 10,
                page: 0
            },
            items: [],
            groups: [],
            subgroups: [],
            total: 0,
            loading: true,
            conversations: [],
            chat: {},
            messages: {},
            numberOfPatients: {},
            message: {},
            page: {},
            showSeen: {},
            conversationsType: 0,
            videoElements: [],
            remoteUids: {},
            tooltip: 0,
            localVideoX: 0,
            localVideoY: 0,
            hands: {},
            layout: [],
            linkCopied: false,
            _connecting: {},
            _checking: {},
            guestNames: {},
            speaking: {},
            cameraStatus: {},
            micStatus: {},
            chatHelpIcons: false,
            mainVideoElement: 'local-video',
            otherVideoElements: [],
            videoCallClients: [],
            timer: null,
            mobileBtnsShow: false,
            searchHealthCare: '',
            questionaryModal: null,
            settedRef: false,
            activeQuestionary: null,
            questionaryAnswers: {},
            questionarySave: false,
            journalNote: false,
            openChatList: false,
            clickedChats: [],
            openedChats: [],
            countOFSections: 0,
            textClipboard: '',
            htmlJournal: '',
            openedJournalNotes: [],
            openedJournalNotesObjects: [],
            openedReferralPreview: [],
            draftMessages: [],
            referralSections: { header: '', footer: '', body: '' },
            journalNoteForSave: null,
            canShowChats: 0,
            showOtherClickedChats: false,
            activeMobChatList: false,
            chatType: 'chat-component',
            journalNoteObj: {
                status: '',
                anamnes: '',
                bedomning: '',
                atgard: ''

            },
            membersHoverState: {},
            searchPhrases: '',
            chatUsers: []
        };
        this.chatWrapRef = React.createRef();
    }



    get = () => {

        // this.props.updateMeta(this.props.generateSeoTags(this.state, this.props.lang));

        // for (let i = 0; i < this.props.loadData.length; i++) {
        //     this.props.loadData[i](window.fetch, this.props.lang, this.props[0].match, this.state.useState ? this.state.params : getSearchParams(this.props[0].location.search, { groupId: this.props.selectedGroup, clinic: this.props.selectedClinic })).then((data) => {
        //         this.setState({
        //             ...data,
        //             loading: null
        //         }, () => {
        //             this.props.updateMeta(this.props.generateSeoTags(this.state, this.props.lang));
        //         })
        //     })
        // }

        // for (let i = 0; i < this.props.loadDataWithQuery.length; i++) {
        //     this.props.loadDataWithQuery[i](window.fetch, this.props.lang, this.props[0].match, this.state.useState ? this.state.params : getSearchParams(this.props[0].location.search, { groupId: this.props.selectedGroup, clinic: this.props.selectedClinic, filter: this.state.healthCareModal ? this.state.searchHealthCare : null, filterPhrases: this.state.quickPhraseModal ? this.state.searchPhrases : null })).then((data) => {
        //         this.setState({
        //             ...data,
        //             loading: null
        //         }, () => {
        //             this.props.updateMeta(this.props.generateSeoTags(this.state, this.props.lang));
        //         })
        //     })
        // }
        fetch(API_ENDPOINT + '/users/account/clinic-phrase', {
            method: 'POST',
            headers: {
                'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage.getItem('authToken')}` : null,
                'content-type': 'application/json'
            },
            body: JSON.stringify({

                group: this.props.selectedGroup,
                clinic: this.props.selectedClinic,
                filter: this.state.quickPhraseModal ? this.state.searchPhrases : null,

            })

        }).then(res => res.json()).then((result) => {
            if (result) {
                this.setState({
                    phrases: result
                })
            }
        })
        fetch(API_ENDPOINT + '/data/general-health-care', {
            method: 'POST',
            headers: {
                'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage.getItem('authToken')}` : null,
                'content-type': 'application/json'
            },
            body: JSON.stringify({
                groupId: this.props.selectedGroup,
                filter: this.state.healthCareModal ? this.state.searchHealthCare : null,
                query: {}
            })

        }).then(res => res.json()).then((result) => {
            if (result) {
                this.setState({
                    generalHealthCare: result
                })
            }

        })





    }
    indexOfIndexOf(arr, str) {
        for (let i = 0; i < arr.length; i++) {
            let idx = arr[i].indexOf(str);
            if (idx !== -1) {
                return i;
            }

        }

        return -1;
    }


    toggleHelpIcons = () => {

        this.setState({
            chatHelpIcons: !this.state.chatHelpIcons
        })
    }

    /* fullSizeHandler = () => {
         try {
             console.log(this.state.fullScreen)
             if (!this.state.fullScreen) {
                 this.setState({
                     fullScreen: true
                 })
             } else {
                 this.setState({
                     fullScreen: false
                 })
 
             }
         } catch (e) { alert(e) }
     }
 */
    handleResize = () => {

        let pageWidth = document.body.offsetWidth;
        let pageChatClickedWidth = pageWidth - 300;

        if (pageWidth > 1199) {
            pageChatClickedWidth -= 450;
        } else {
            pageChatClickedWidth -= 100;

        }


        let canShowChats = 0;
        if (pageChatClickedWidth > 324) {
            canShowChats = Math.floor(pageChatClickedWidth / 324);

        }
        let changeSizeToMobile = false;
        if (pageWidth < 760) {
            changeSizeToMobile = true;
        }
        if (changeSizeToMobile != this.state.isMobileLayout) {
            this.setState({ isMobileLayout: changeSizeToMobile })
        }
        if (canShowChats != this.state.canShowChats) {
            this.setState({ canShowChats })
        }



    }

    componentDidMount() {
        console.log('chatComponent componentDidMount')
        document.body.style.overflowX = 'hidden';

        window.addEventListener('resize', this.resizeWindow);
        /*if (document.addEventListener) {
            document.addEventListener('fullscreenchange', this.fullSizeHandler, false);
            document.addEventListener('mozfullscreenchange', this.fullSizeHandler, false);
            document.addEventListener('MSFullscreenChange', this.fullSizeHandler, false);
            document.addEventListener('webkitfullscreenchange', this.fullSizeHandler, false);
        }*/
        let pageWidth = document.body.offsetWidth;
        let pageChatClickedWidth = pageWidth - 300;
        pageChatClickedWidth -= 450;

        let canShowChats = 0;
        if (pageChatClickedWidth > 324) {
            canShowChats = Math.floor(pageChatClickedWidth / 324);
            this.setState({ canShowChats })

        }
        let changeSizeToMobile = false;
        if (pageWidth < 760) {
            changeSizeToMobile = true;
        }
        this.setState({ isMobileLayout: changeSizeToMobile })

        window.addEventListener('resize', this.handleResize)

        if (typeof window !== 'undefined') { window.scrollTo(0, 0); }


        this.get();

        /*
        // if (this.props.uData && !localStorage.getItem('groupAlias') && (this.props.uData.userLevel == 1 || this.props.uData.userLevel == 2 || (this.props.uData.userLevel == 20 && localStorage?.getItem?.('profile') == 'patient'))) {
        //     this.setState({
        //         groupError: true
        //     })
        // }
        */

        if (this.props.chat) {

            this.setState({
                openChatList: this.props.chat.openChatList,
                clickedChats: this.props.chat.clickedChats,
                openedChats: this.props.chat.openedChats,
                chat: this.props.chat.chat
            })
        }
        if (typeof window != 'undefined' && localStorage.groupAlias && localStorage.groupAlias != 'account') {
            this.setState({ patientGroupAlias: localStorage.groupAlias })
        }

        let params = getSearchParams(this.props[0].location.search);
        if (params.roomId) {

            this.props.initSocketForVideoRoom(params.roomId, () => {
                this.setState({
                    _registerEvents: true
                }, () => {
                    this.registerEvents();
                    this.props.socket.emit('conversations', { profile: typeof window != 'undefined' ? localStorage?.getItem?.('profile') : null, excludeClosed: true, groupDoctors: true, group: this.props.selectedGroup });

                    this.props.socket.emit('groupDoctors', { group: this.props.selectedGroup });


                });
            });
        } else {


            if (this.props.uData && this.props.socket && !this.state._registerEvents) {

                this.setState({
                    _registerEvents: true
                }, () => {
                    this.registerEvents();

                    this.props.socket.emit('conversations', { profile: typeof window != 'undefined' ? localStorage?.getItem?.('profile') : null, excludeClosed: true, groupDoctors: true, group: this.props.selectedGroup });

                    this.props.socket.emit('groupDoctors', { group: this.props.selectedGroup });


                })
            } else {

                this.socketInterval = setInterval(() => {
                    if (this.props.uData && this.props.socket && !this.state._registerEvents) {
                        if (this.socketInterval) {
                            clearInterval(this.socketInterval)
                        }

                        this.setState({
                            _registerEvents: true
                        }, () => {
                            this.registerEvents();

                            this.props.socket.emit('conversations', { profile: typeof window != 'undefined' ? localStorage?.getItem?.('profile') : null, excludeClosed: true, groupDoctors: true, group: this.props.selectedGroup });

                            this.props.socket.emit('groupDoctors', { group: this.props.selectedGroup });

                        })
                    }

                }, 150);
            }

        }



        let textClipboard = `Kontaktorsak:
Status
Anamnes
Bedömning
Åtgärd
`;
        let html = `
                    <div>
                        <div>
                            <h1>Journal note</h1>
                        </div>
                        <div style='margin-bottom: 10px'>
                            <h5 style='font-size: 24px; font-weight: 600'>Kontaktorsak:</h5>
                        </div>
                        <div style='margin-bottom: 10px'>
                            <h5 style='font-size: 24px; font-weight: 600'>Status:</h5>
                        </div>
                        <div style='margin-bottom: 10px'>
                            <h5 style='font-size: 24px; font-weight: 600'>Anamnes:</h5>
                        </div>
                        <div style='margin-bottom: 10px'>
                            <h5 style='font-size: 24px; font-weight: 600'>Bedömning:</h5>
                        </div>
                        <div style='margin-bottom: 10px'>
                            <h5 style='font-size: 24px; font-weight: 600'>Åtgärd:</h5>
                        </div>               
                    </div>
                `;

        this.setState({ htmlJournal: html, textClipboard })



        this.videoCallConnectionInterval = setInterval(async () => {
            if (this.peers && this.state.call) {
                for (var uuid in this.peers) {
                    if (this.initiatorPeers[uuid] && this.reconnectingPeer[uuid] && ((this.state.useStandardDelay && new Date().getTime() / 1000 - this.reconnectingPeer[uuid] >= 15) || (!this.state.useStandardDelay && new Date().getTime() / 1000 - this.reconnectingPeer[uuid] >= 3))) {
                        if (this.peers[uuid].iceConnectionState === 'failed' || this.peers[uuid].iceConnectionState === 'disconnected' || this.peers[uuid].iceConnectionState === 'closed') {
                            this.reconnectingPeer[uuid] = null;
                            this.hardReconnect(uuid);
                        }
                    }

                    if (this.initiatorPeers[uuid] && this._checkingConnection[uuid] && !this.state.useStandardDelay && new Date().getTime() / 1000 - this._checkingConnection[uuid] >= 5.5) {
                        if (this.peers[uuid].iceConnectionState === 'checking') {
                            this._checkingConnection[uuid] = null;
                            this.hardReconnect(uuid);
                        }
                    }
                }
            }
        }, 1000);
    }
    generateSections = () => {
        let sections = {};
        if (this.state.activeQuestionary) {
            let items = [{ type: 0 }, ...this.state.activeQuestionary.items];
            let lastSectionId = -1;
            for (let i = 0; i < items.length; i++) {
                if (items[i].type == 0) {
                    lastSectionId++;
                    sections[lastSectionId] = {
                        name: items[i].name,
                        displayOnReferral: false,
                        questions: [],
                        conditions: items[i].conditions ? items[i].conditions : [],
                        displayOnReferral: items[i].displayOnReferral,
                        fontStyle: items[i].fontStyle,
                        fontSize: items[i].fontSize,
                        fontWeight: items[i].fontWeight,

                    }
                }
                if (lastSectionId != -1 && items[i].type != 0) {


                    let wikiLinks = [];

                    items[i].wikiLinks = wikiLinks;


                    if (items[i].type == 1) {
                        let displayOnReferral = false;
                        if (items[i].answers) {
                            for (let j = 0; j < items[i].answers.length; j++) {
                                if (items[i].answers[j].text) {
                                    displayOnReferral = true;
                                }
                            }
                        }

                        sections[lastSectionId].questions.push({
                            id: items[i].id,
                            type: items[i].type,
                            name: items[i].name,
                            answers: items[i].answers,
                            conditions: items[i].conditions ? items[i].conditions : [],
                            displayOnReferral: displayOnReferral,
                            wikiLinks: items[i].wikiLinks,
                            fontStyle: items[i].fontStyle,
                            fontSize: items[i].fontSize,
                            fontWeight: items[i].fontWeight,
                        });

                    } else if (items[i].type == 2) {
                        let displayOnReferral = false;
                        if (items[i].answers) {
                            for (let j = 0; j < items[i].answers.length; j++) {

                                if (items[i].answers[j].text) {
                                    displayOnReferral = true;
                                }
                            }
                        }
                        sections[lastSectionId].questions.push({
                            id: items[i].id,
                            type: items[i].type,
                            name: items[i].name,
                            answers: items[i].answers,
                            conditions: items[i].conditions ? items[i].conditions : [],
                            answerPrefix: items[i].answerPrefix,
                            userInput: items[i].userInput,
                            displayOnReferral: displayOnReferral || items[i].answerPrefix ? true : false,
                            wikiLinks: items[i].wikiLinks,
                            fontStyle: items[i].fontStyle,
                            fontSize: items[i].fontSize,
                            fontWeight: items[i].fontWeight,

                        })
                    } else if (items[i].type == 3) {
                        sections[lastSectionId].questions.push({
                            id: items[i].id,
                            type: items[i].type,
                            name: items[i].name,
                            conditions: items[i].conditions ? items[i].conditions : [],
                            answerPrefix: items[i].answerPrefix,
                            wikiLinks: items[i].wikiLinks,
                            displayOnReferral: true,
                            fontStyle: items[i].fontStyle,
                            fontSize: items[i].fontSize,
                            fontWeight: items[i].fontWeight,
                        })

                    } else if (items[i].type == 4) {
                        sections[lastSectionId].questions.push({
                            id: items[i].id,
                            type: items[i].type,
                            name: items[i].name,
                            text: items[i].text,
                            conditions: items[i].conditions ? items[i].conditions : [],
                            wikiLinks: items[i].wikiLinks,
                            fontStyle: items[i].fontStyle,
                            fontSize: items[i].fontSize,
                            fontWeight: items[i].fontWeight,
                        })

                    } else if (items[i].type == 5) {
                        sections[lastSectionId].questions.push({
                            id: items[i].id,
                            type: items[i].type,
                            name: items[i].name,
                            conditions: items[i].conditions ? items[i].conditions : [],
                            wikiLinks: items[i].wikiLinks,
                            displayOnReferral: true,
                            fontStyle: items[i].fontStyle,
                            fontSize: items[i].fontSize,
                            fontWeight: items[i].fontWeight,
                        })

                    } else if (items[i].type == 6) {
                        sections[lastSectionId].questions.push({
                            id: items[i].id,
                            type: items[i].type,
                            name: items[i].name,
                            conditions: items[i].conditions ? items[i].conditions : [],
                            wikiLinks: items[i].wikiLinks,
                            displayOnReferral: true,
                            fontStyle: items[i].fontStyle,
                            fontSize: items[i].fontSize,
                            fontWeight: items[i].fontWeight,
                        })

                    } else if (items[i].type == 7) {
                        sections[lastSectionId].questions.push({
                            id: items[i].id,
                            type: items[i].type,
                            name: items[i].name,
                            conditions: items[i].conditions ? items[i].conditions : [],
                            wikiLinks: items[i].wikiLinks,
                            displayOnReferral: true,
                            fontStyle: items[i].fontStyle,
                            fontSize: items[i].fontSize,
                            fontWeight: items[i].fontWeight,
                        })

                    } else if (items[i].type == 8) {
                        sections[lastSectionId].questions.push({
                            id: items[i].id,
                            type: items[i].type,
                            name: items[i].name,
                            conditions: items[i].conditions ? items[i].conditions : [],
                            wikiLinks: items[i].wikiLinks,
                            displayOnReferral: true,
                            fontStyle: items[i].fontStyle,
                            fontSize: items[i].fontSize,
                            fontWeight: items[i].fontWeight,
                        })

                    } else if (items[i].type == 9) {
                        sections[lastSectionId].questions.push({
                            id: items[i].id,
                            type: items[i].type,
                            name: items[i].name,
                            conditions: items[i].conditions ? items[i].conditions : [],
                            wikiLinks: items[i].wikiLinks,
                            displayOnReferral: true,
                            fontStyle: items[i].fontStyle,
                            fontSize: items[i].fontSize,
                            fontWeight: items[i].fontWeight,
                        })

                    } else if (items[i].type == 10) {
                        sections[lastSectionId].questions.push({
                            id: items[i].id,
                            type: items[i].type,
                            name: items[i].name,
                            userInput: items[i].userInput,
                            conditions: items[i].conditions ? items[i].conditions : [],
                            wikiLinks: items[i].wikiLinks,
                            displayOnReferral: true,
                            fontStyle: items[i].fontStyle,
                            fontSize: items[i].fontSize,
                            fontWeight: items[i].fontWeight,
                        })
                    } else if (items[i].type == 12) {
                        sections[lastSectionId].questions.push({
                            id: items[i].id,
                            type: items[i].type,
                            name: items[i].name,
                            conditions: items[i].conditions ? items[i].conditions : [],
                            wikiLinks: items[i].wikiLinks,
                            displayOnReferral: true,
                            fontStyle: items[i].fontStyle,
                            fontSize: items[i].fontSize,
                            fontWeight: items[i].fontWeight,
                        })

                    } else if (items[i].type == 13) {
                        sections[lastSectionId].questions.push({
                            id: items[i].id,
                            type: items[i].type,
                            name: items[i].name,
                            conditions: items[i].conditions ? items[i].conditions : [],
                            wikiLinks: items[i].wikiLinks,
                            displayOnReferral: true,
                            fontStyle: items[i].fontStyle,
                            fontSize: items[i].fontSize,
                            fontWeight: items[i].fontWeight,
                        })

                    }
                }
            }
            return Object.values(sections);
        }

    }
    getStream = (reopen = false) => {
        if (reopen) {
            if (this.audioVideoStream) {
                if (this.localVideo) {
                    this.localVideo.srcObject = null;
                }

                this.audioVideoStream.getAudioTracks()[0].stop();
                this.audioVideoStream.getVideoTracks()[0].stop();


                for (let i = 0; i < this.tmpStreams.length; i++) {
                    try {
                        if (this.tmpStreams[i]) {
                            this.tmpStreams[i].getTracks().forEach(track => track.stop());
                            this.tmpStreams[i] = null;
                        }
                    } catch (e) { }
                }


                this.audioVideoStream = null;

            }
        }

        return new Promise((resolve, reject) => {
            if (this.audioVideoStream) {
                resolve(this.audioVideoStream);
            } else {

                try {
                    if (!this.audioContext) {
                        window.AudioContext = window.AudioContext || window.webkitAudioContext;
                        this.audioContext = new AudioContext();
                    }
                }
                catch (e) {
                    alert('Web Audio API not supported.');
                }


                navigator?.mediaDevices?.getUserMedia?.(
                    { video: this.state.videoInputDevice ? { deviceId: this.state.videoInputDevice } : this.state.camera === true ? { facingMode: { exact: "environment" } } : this.state.camera === false ? { facingMode: "user" } : true, audio: this.state.audioInputDevice ? { deviceId: this.state.audioInputDevice } : true }).then(
                        stream => {

                            if (this.state.cameraOff) {
                                stream.getVideoTracks().forEach(track => track.enabled = false);

                            }

                            if (this.state.micOff) {
                                stream.getAudioTracks().forEach(track => track.enabled = false);
                            }

                            this.tmpStreams.push(stream);

                            this.audioVideoStream = stream;


                            if (this.peers && this.state.call) {
                                for (var uuid in this.peers) {

                                    this.peers[uuid].getSenders().forEach(
                                        (rtpSender) => {

                                            if (!this.state.shareOn && rtpSender.track && rtpSender.track.kind == 'video') {
                                                rtpSender.replaceTrack(this.audioVideoStream.getVideoTracks()[0]).then(function () {
                                                    console.log("Replaced video track from camera to screen");
                                                }).catch(function (error) {
                                                    console.log("Could not replace video track: " + error);
                                                });
                                            } else if (this.state.shareOn && rtpSender.track && rtpSender.track.kind == 'video') {
                                                rtpSender.replaceTrack(this.screenStream.getVideoTracks()[0]).then(function () {
                                                    console.log("Replaced video track from camera to screen");
                                                }).catch(function (error) {
                                                    console.log("Could not replace video track: " + error);
                                                });
                                            }

                                            if (rtpSender.track && rtpSender.track.kind == 'audio') {
                                                rtpSender.replaceTrack(this.audioVideoStream.getAudioTracks()[0]).then(function () {
                                                    console.log("Replaced audio track");
                                                }).catch(function (error) {
                                                    console.log("Could not replace audio track: " + error);
                                                });
                                            }

                                        }
                                    );

                                }
                            }


                            try {
                                this.soundMeter = new SoundMeter(this.audioContext);
                                this.soundMeter.connectToSource(stream, (e) => {
                                    if (e) {
                                        alert(e);
                                        return;
                                    }
                                    this.meterRefresh = setInterval(() => {
                                        if (this.soundMeter.instant > this.micMeterValue && this.soundMeter.instant >= 0.07) {

                                            if (this.userSpeakingTimer) {
                                                clearTimeout(this.userSpeakingTimer);
                                            }

                                            this.userSpeakingTimer = setTimeout(() => {
                                                this.props.socket.emit('userSpeak', { value: false, conversation: this.state.conversation._id });
                                            }, 1000);

                                            this.props.socket.emit('userSpeak', { value: true, conversation: this.state.conversation._id });


                                        }

                                        /*if (this.soundMeter.instant < 0.07) {
                                            /*if (this.userSpeaking == true)
                                                this.props.socket.emit('userSpeak', { value: false, conversation: this.state.conversation._id });*/

                                        /* this.userSpeaking = false;
                                     }*/

                                        if (this.state.settings) {
                                            this.setState({
                                                micMeterValue: this.soundMeter.instant
                                            })
                                        }
                                        this.micMeterValue = this.soundMeter.instant;
                                    }, 200);
                                });
                            } catch (e) {

                            }

                            const localVideo = this.localVideo;
                            if (localVideo) {
                                localVideo.srcObject = stream;
                                localVideo.autoplay = true;
                                localVideo.playsInline = true;
                                localVideo.muted = true;
                            }
                            const previewVideo = this.previewVideo;
                            if (previewVideo) {
                                previewVideo.srcObject = stream;
                                previewVideo.autoplay = true;
                                previewVideo.playsInline = true;
                                previewVideo.muted = true;
                            }





                            resolve(stream);
                        }
                    ).catch((err) => { console.log(err) });
            }




        })
    }
    getScreenStream = async () => {
        if (this.screenStream) {
            return this.screenStream;
        } else {
            this.screenStream = await navigator?.mediaDevices?.getDisplayMedia?.();
            const localVideo = this.localVideo;
            if (localVideo) {
                localVideo.srcObject = this.screenStream;
                localVideo.autoplay = true;
                localVideo.playsInline = true;
                localVideo.muted = true;
            }
            return this.screenStream;

        }

    }


    shareScreen = async () => {

        this.setState({
            shareOn: !this.state.shareOn,
            screenSharing: !this.state.shareOn
        }, async () => {
            if (this.state.shareOn) {


                await this.getScreenStream();
                this.screenStream.getVideoTracks()[0].onended = () => {
                    this.shareScreen();

                }

                try {
                    if (screen.availWidth - window.innerWidth === 0 || !window.opener) {
                        this.setState({
                            resizeWindowAfter: true
                        })
                    } else {
                        window.resizeTo(200, window.innerHeight / 2);
                        this.setState({
                            showScreenShareMode: true,
                            windowDimensions: {
                                width: 200,
                                height: window.innerHeight
                            }
                        })

                    }
                } catch (e) {
                    console.log(e);
                }


                for (var uuid in this.peers) {

                    this.peers[uuid].getSenders().forEach(
                        (rtpSender) => {
                            if (rtpSender.track && rtpSender.track.kind == 'video') {
                                rtpSender.replaceTrack(this.screenStream.getVideoTracks()[0]).then(function () {
                                    console.log("Replaced video track from camera to screen");
                                }).catch(function (error) {
                                    console.log("Could not replace video track: " + error);
                                });
                            }
                        }
                    );

                }

                this.props.socket.emit('shareScreen', { conversation: this.state.conversation._id, value: true });

            } else {

                if (this.state.windowDimensions) {
                    window.resizeTo(this.state.windowDimensions.width < 400 ? 800 : this.state.windowDimensions.width, this.state.windowDimensions.height < 600 ? 600 : this.state.windowDimensions.height);
                    this.setState({
                        windowDimensions: null
                    })
                }

                this.setState({
                    showScreenShareMode: false
                })

                for (var uuid in this.peers) {

                    this.peers[uuid].getSenders().forEach(
                        (rtpSender) => {
                            if (rtpSender.track && rtpSender.track.kind == 'video') {
                                rtpSender.replaceTrack(this.audioVideoStream.getVideoTracks()[0]).then(function () {
                                    console.log("Replaced screen track with video");
                                }).catch(function (error) {
                                    console.log("Could not replace video track: " + error);
                                });
                            }
                        }
                    );

                }
                if (this.screenStream) {
                    this.screenStream.getTracks().forEach(track => track.stop());
                    this.screenStream = null;
                }

                const localVideo = this.localVideo;
                if (localVideo) {
                    localVideo.srcObject = this.audioVideoStream;
                    localVideo.autoplay = true;
                    localVideo.playsInline = true;
                    localVideo.muted = true;
                }



                this.props.socket.emit('shareScreen', { conversation: this.state.conversation._id, value: false });

            }
        })




    }
    addToReferralPreview = () => {

        if (this.state.conversation && this.state.conversation._id) {
            let openedReferralPreview = this.state.openedReferralPreview;
            if (openedReferralPreview.indexOf(this.state.conversation._id) == -1) {
                openedReferralPreview.push(this.state.conversation._id);
                this.setState({ openedReferralPreview })
            }

        }
    }
    removeFromReferralPreview = () => {
        if (this.state.conversation && this.state.conversation._id) {
            let openedReferralPreview = this.state.openedReferralPreview;
            let index = openedReferralPreview.indexOf(this.state.conversation._id);

            if (index != -1) {
                openedReferralPreview.splice(index, 1);
                this.setState({ openedReferralPreview })
            }
            // if (this.state.journalNoteForSave) {
            //     this.setState({ journalNoteForSaveModal: true })
            // }
        }
    }
    addToJorunalNotes = () => {
        // if (this.state.conversation && this.state.conversation._id) {
        //     let openedJournalNotes = this.state.openedJournalNotes;
        //     if (openedJournalNotes.indexOf(this.state.conversation._id) == -1) {
        //         openedJournalNotes.push(this.state.conversation._id);
        //         this.setState({ openedJournalNotes })
        //     }

        // }
    }
    removeFromJorunalNotes = () => {
        // if (this.state.conversation && this.state.conversation._id) {
        //     let openedJournalNotes = this.state.openedJournalNotes;
        //     let index = openedJournalNotes.indexOf(this.state.conversation._id);

        //     if (index != -1) {
        //         openedJournalNotes.splice(index, 1);
        //         this.setState({ openedJournalNotes })
        //     }
        //     // if (this.state.journalNoteForSave) {
        //     //     this.setState({ journalNoteForSaveModal: true })
        //     // }
        // }
    }


    getQuestionary = () => {


        fetch(API_ENDPOINT + '/data/general-health-care/' + this.state.questionaryModal, {
            method: 'GET',
            headers: {
                'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage.getItem('authToken')}` : null,
                'content-type': 'application/json'
            }
        }).then(res => res.json()).then((result) => {
            console.log(result)
            this.setState({
                activeQuestionary: result,
                sections: result.sections,
                settedRef: false
            })
        })
    }

    flipCamera = () => {
        this.setState({
            camera: !this.state.camera
        }, async () => {
            if (this.state.camera) {
                this.setState({ videoInputDevice: null });
            }

            let stream = await this.getStream(true);

            /*for (var uuid in this.peers) {
                this.peers[uuid].removeStream(this.peers[uuid].getLocalStreams()[0]);
                stream.getTracks().forEach(track => this.peers[uuid].addTrack(track, stream));
            }*/

            /*for (var uuid in this.peers) {

                this.peers[uuid].getSenders().forEach(
                    (rtpSender) => {
                        if (rtpSender.track && rtpSender.track.kind == 'video') {
                            rtpSender.replaceTrack(this.audioVideoStream.getVideoTracks()[0]).then(function () {
                                console.log("Replaced video track from camera to screen");
                            }).catch(function (error) {
                                console.log("Could not replace video track: " + error);
                            });
                        }
                        if (rtpSender.track && rtpSender.track.kind == 'audio') {
                            rtpSender.replaceTrack(this.audioVideoStream.getAudioTracks()[0]).then(function () {
                                console.log("Replaced audio track");
                            }).catch(function (error) {
                                console.log("Could not replace audio track: " + error);
                            });
                        }

                    }
                );

            }*/


        })


    }

    toggleCamera = () => {
        this.setState({
            cameraOff: !this.state.cameraOff
        }, async () => {

            this.props.socket.emit('cameraStatus', { conversation: this.state.conversation._id, value: !this.state.cameraOff })

            if (this.audioVideoStream) {
                this.audioVideoStream.getVideoTracks().forEach(track => track.enabled = !this.state.cameraOff);
            }
        })
    }

    toggleMic = () => {
        this.setState({
            micOff: !this.state.micOff
        }, async () => {
            this.props.socket.emit('micStatus', { conversation: this.state.conversation._id, value: !this.state.micOff })

            if (this.audioVideoStream) {
                this.audioVideoStream.getAudioTracks().forEach(track => track.enabled = !this.state.micOff);
            }
        })
    }

    allowIOSSound = (e) => {
        for (var uuid in this.peers) {
            let remoteVideo = this.remoteVideo[uuid]
            if (remoteVideo) {
                remoteVideo.play();
            }
        }

        this.setState({
            soundAllowed: true
        })
    }

    initPeer = (uuid, createVideoElement = true, remoteUid, initiator = false, guestNames, forceTcp) => {
        if (forceTcp) {
            this.setState({
                useStandardDelay: true
            })
        }

        console.log('forceTcp', forceTcp);
        if (!this.timerHandler) {
            this.setState({
                startTime: Math.floor(new Date().getTime() / 1000)
            })

            this.timerHandler = setInterval(() => {

                let diff = Math.floor(new Date().getTime() / 1000) - this.state.startTime;
                var hrs = ~~(diff / 3600);
                var mins = ~~((diff % 3600) / 60);
                var secs = ~~diff % 60;



                this.setState({
                    timer: `${hrs <= 9 ? '0' : ''}${hrs}:${mins <= 9 ? '0' : ''}${mins}:${secs <= 9 ? '0' : ''}${secs}`
                })
            }, 1000);
        }

        console.log('Init Peer UUID', uuid)
        let videoElements = this.state.videoElements;
        let remoteUids = this.state.remoteUids;
        remoteUids[uuid] = remoteUid;
        if (createVideoElement) {
            videoElements.push(uuid);
        }

        let cameraStatus = this.state.cameraStatus;
        cameraStatus[remoteUid] = true;
        let micStatus = this.state.micStatus;
        micStatus[remoteUid] = true;

        let _connecting = this.state._connecting;
        _connecting[uuid] = true;
        this.initiatorPeers[uuid] = initiator;
        this.peersBandwidth[uuid] = 'unlimited';
        this.reconnectingPeer[uuid] = null;
        this.peerCandidates[uuid] = [];

        let localStoragePeers = localStorage.getItem('videoCallPeers') ? JSON.parse(localStorage.getItem('videoCallPeers')) : [];
        localStoragePeers.push(uuid);
        localStorage.setItem('videoCallPeers', JSON.stringify(localStoragePeers));
        localStorage.setItem('oldSocketId', this.props.socket.id);


        this.setState({
            videoElements,
            remoteUids,
            cameraStatus,
            micStatus,
            _connecting
        }, () => {
            this.generateLayout();

            const configuration = {
                iceServers: [
                    {
                        urls: 'stun:stun.curoflow.se:443',
                    },
                    {
                        urls: `turn:turn.curoflow.se:443${forceTcp ? '?transport=tcp' : ''}`,
                        username: 'jkZhfAU2LQRTtyMrRgZH',
                        credential: 'MZJMdbQu8QcLwAaGsCxj'
                    }
                ]

            }
            /*const configuration = {
                iceServers: [
                    {
                        urls: 'stun:46.21.99.221:3478',
                    },
                    {
                        urls: 'turn:46.21.99.221:3478',
                        username: 'jkZhfAU2LQRTtyMrRgZH',
                        credential: 'MZJMdbQu8QcLwAaGsCxj'
                    }
                ]

            }*/
            // const configuration = {
            //     iceServers: [
            //         {
            //             urls: 'stun:94.130.217.122:3478',
            //         },
            //         {
            //             urls: 'turn:94.130.217.122:3478',
            //             username: 'milan',
            //             credential: '123456'
            //         }
            //     ]

            // }

            this.peers[uuid] = new RTCPeerConnection(configuration);

            this.peers[uuid].onicecandidate = (event) => {
                console.log('onicecandidate', uuid, event);

                /*if (this.reconnectingPeer[uuid]) {

                    this.peerCandidates[uuid].push(event.candidate);

                    if (!event.candidate && this.initiatorPeers[uuid]) {
                        this.props.socket.emit('reconnectPeer', { uuid: uuid, offer: this.peers[uuid].localDescription, candidates: this.peerCandidates[uuid], conversation: this.state.conversation._id });
                        this.peerCandidates[uuid] = [];
                    } else if (!event.candidate && !this.initiatorPeers[uuid]) {
                        this.props.socket.emit('reconnectPeer', { uuid: uuid, answer: this.peers[uuid].localDescription, conversation: this.state.conversation._id })
                        this.peerCandidates[uuid] = [];
                    }
                } else {*/
                this.props.socket.emit('icecandidate', { conversation: this.state.conversation._id, candidate: event.candidate, uuid: uuid });
                //}
            }


            this.peers[uuid].onnegotiationneeded = async () => {
                /*try {
                    if (this.peerStatus[uuid]) {

                        console.log('onnegotiationneeded', uuid);


                        const offer = await this.peers[uuid].createOffer();
                        await this.peers[uuid].setLocalDescription(offer);

                        this.props.socket.emit('reconnectPeer', { uuid: uuid, offer: offer, conversation: this.state.conversation._id });
                    }
                } catch (err) {
                    console.log(err)
                } finally {
                }*/
            }

            this.peers[uuid].oniceconnectionstatechange = async () => {
                let _connecting = this.state._connecting;
                let _checking = this.state._checking;

                console.log('oniceconnectionstatechange', uuid, this.peers[uuid] && this.peers[uuid].iceConnectionState);

                if (initiator && !_connecting[uuid] && !this.reconnectingPeer[uuid]) {
                    if (this.peers[uuid] && this.peers[uuid].iceConnectionState === 'failed' || this.peers[uuid].iceConnectionState === 'disconnected' || this.peers[uuid].iceConnectionState === 'closed') {
                        this.reconnectingPeer[uuid] = new Date().getTime() / 1000;
                        this.setState({
                            forceTcp: true
                        })

                        /*setTimeout(async () => {
                            if (this.peers[uuid] && this.peers[uuid].iceConnectionState === 'failed' || this.peers[uuid].iceConnectionState === 'disconnected' || this.peers[uuid].iceConnectionState === 'closed') {
                                this.peerCandidates[uuid] = [];
                                this.reconnectingPeer[uuid] = new Date().getTime() / 1000;
                                const offer = await this.peers[uuid].createOffer({ iceRestart: true });
                                await this.peers[uuid].setLocalDescription(offer);

                                this.candidates[uuid] = [];

                            } else {
                                this.reconnectingPeer[uuid] = null;
                            }
                        }, 1000);*/


                    }
                }

                if (this.peers[uuid] && this.peers[uuid].iceConnectionState === 'failed' || this.peers[uuid].iceConnectionState === 'disconnected' || this.peers[uuid].iceConnectionState === 'closed') {
                    _connecting[uuid] = true;
                } else {
                    _connecting[uuid] = false;

                }

                if (this.peers[uuid] && this.peers[uuid].iceConnectionState === 'connected') {
                    this.reconnectingPeer[uuid] = null;
                }

                if (this.peers[uuid] && this.peers[uuid].iceConnectionState === 'checking') {
                    _checking[uuid] = true;
                    this._checkingConnection[uuid] = new Date().getTime() / 1000;
                } else {
                    _checking[uuid] = false;
                    this._checkingConnection[uuid] = null;
                }

                this.setState({
                    _connecting,
                    _checking
                })
            }


            this.peers[uuid].ontrack = ({ streams: [stream] }) => {
                //alert("track")
                console.log('ontrack', uuid, stream);
                this.setState({
                    haveRemoteTrack: true
                });


                this.peerStreams[uuid] = stream;

                let remoteVideo = this.remoteVideo[uuid]
                if (remoteVideo) {
                    remoteVideo.srcObject = stream;
                    remoteVideo.playsInline = true;
                    remoteVideo.playsinline = true;


                    remoteVideo.muted = false;
                    remoteVideo.autoplay = true;


                    if (this.state.audioOutputDevice)
                        this.attachSinkId(remoteVideo, this.state.audioOutputDevice);
                }


                setTimeout(() => {
                    this.generateLayout();
                }, 1000);
            };



            setTimeout(() => {
                this.forceUpdate();
            }, 100);

        })



    }



    changeBandwidth = async (uuid, bandwidth) => {
        /*if (iosDevice()) {
            return;
        }*/

        if (!this.peers[uuid]) {
            return;
        }
        if (this.peers[uuid].iceConnectionState === 'failed' || this.peers[uuid].iceConnectionState === 'disconnected' || this.peers[uuid].iceConnectionState === 'closed') {
            return;
        }

        if (!this.peers[uuid].getSenders().length) {
            return;
        }

        if (this.peersBandwidth[uuid] == bandwidth) {
            return;
        }


        console.log('Changing bandwidth', uuid, bandwidth);

        this.peersBandwidth[uuid] = bandwidth;



        // In Chrome, use RTCRtpSender.setParameters to change bandwidth without
        // (local) renegotiation. Note that this will be within the envelope of
        // the initial maximum bandwidth negotiated via SDP.
        if ((adapter.browserDetails.browser === 'chrome' ||
            adapter.browserDetails.browser === 'safari' ||
            (adapter.browserDetails.browser === 'firefox' &&
                adapter.browserDetails.version >= 64)) &&
            'RTCRtpSender' in window &&
            'setParameters' in window.RTCRtpSender.prototype) {

            const sender = this.peers[uuid].getSenders().filter((sender => sender.track && sender.track.kind == 'video'))[0];
            const parameters = sender.getParameters();
            console.log(parameters);
            if (!parameters.encodings) {
                parameters.encodings = [{}];
            }
            if (bandwidth === 'unlimited') {
                delete parameters.encodings[0].maxBitrate;
                delete parameters.encodings[0].scaleResolutionDownBy;

                let width = this.audioVideoStream.getVideoTracks()[0].getSettings().width;
                let maxWidth = null;

                if (Object.keys(this.peers).length + 1 < 4) {
                    delete parameters.encodings[0].scaleResolutionDownBy;
                } else if (Object.keys(this.peers).length + 1 >= 4 && Object.keys(this.peers).length + 1 < 6) {
                    maxWidth = 1000;
                } else if (Object.keys(this.peers).length + 1 >= 6 && Object.keys(this.peers).length + 1 < 8) {
                    maxWidth = 800;
                } else if (Object.keys(this.peers).length1 + 1 >= 8) {
                    maxWidth = 728;
                }

                console.log(width);

                if (maxWidth != null) {
                    let scale = width / maxWidth;
                    console.log(scale);
                    if (scale >= 1)
                        parameters.encodings[0].scaleResolutionDownBy = scale;
                }


            } else {
                let width = this.audioVideoStream.getVideoTracks()[0].getSettings().width;
                let scale = width / 256;
                console.log(scale);

                parameters.encodings[0].maxBitrate = bandwidth * 1000;

                if (scale >= 1)
                    parameters.encodings[0].scaleResolutionDownBy = scale;
                else
                    delete parameters.encodings[0].scaleResolutionDownBy;

            }
            sender.setParameters(parameters)
                .then(() => {

                })
                .catch(e => console.error(e));
            return;
        }
    }


    updateBandwidthRestriction = (sdp, bandwidth) => {
        let modifier = 'AS';
        if (adapter.browserDetails.browser === 'firefox') {
            bandwidth = (bandwidth >>> 0) * 1000;
            modifier = 'TIAS';
        }
        if (sdp.indexOf('b=' + modifier + ':') === -1) {
            // insert b= after c= line.
            sdp = sdp.replace(/c=IN (.*)\r\n/, 'c=IN $1\r\nb=' + modifier + ':' + bandwidth + '\r\n');
        } else {
            sdp = sdp.replace(new RegExp('b=' + modifier + ':.*\r\n'), 'b=' + modifier + ':' + bandwidth + '\r\n');
        }
        return sdp;
    }

    removeBandwidthRestriction = (sdp) => {
        return sdp.replace(/b=AS:.*\r\n/, '').replace(/b=TIAS:.*\r\n/, '');
    }



    // Add a b=AS:bitrate line to the m=mediaType section.
    preferBitRate = (sdp, bitrate, mediaType) => {
        var sdpLines = sdp.split('\r\n');

        // Find m line for the given mediaType.
        var mLineIndex = findLine(sdpLines, 'm=', mediaType);
        if (mLineIndex === null) {
            trace('Failed to add bandwidth line to sdp, as no m-line found');
            return sdp;
        }

        // Find next m-line if any.
        var nextMLineIndex = findLineInRange(sdpLines, mLineIndex + 1, -1, 'm=');
        if (nextMLineIndex === null) {
            nextMLineIndex = sdpLines.length;
        }

        // Find c-line corresponding to the m-line.
        var cLineIndex = findLineInRange(sdpLines, mLineIndex + 1,
            nextMLineIndex, 'c=');
        if (cLineIndex === null) {
            trace('Failed to add bandwidth line to sdp, as no c-line found');
            return sdp;
        }

        // Check if bandwidth line already exists between c-line and next m-line.
        var bLineIndex = findLineInRange(sdpLines, cLineIndex + 1,
            nextMLineIndex, 'b=AS');
        if (bLineIndex) {
            sdpLines.splice(bLineIndex, 1);
        }

        // Create the b (bandwidth) sdp line.
        var bwLine = 'b=AS:' + bitrate;
        // As per RFC 4566, the b line should follow after c-line.
        sdpLines.splice(cLineIndex + 1, 0, bwLine);
        sdp = sdpLines.join('\r\n');
        return sdp;
    }


    removePeer = (uuid) => {

        console.log('removePeer', uuid)

        if (this.peers[uuid]) {
            this.peers[uuid].ontrack = null;
            this.peers[uuid].onremovetrack = null;
            this.peers[uuid].onremovestream = null;
            this.peers[uuid].onicecandidate = null;
            this.peers[uuid].oniceconnectionstatechange = null;
            this.peers[uuid].onsignalingstatechange = null;
            this.peers[uuid].onicegatheringstatechange = null;
            this.peers[uuid].onnegotiationneeded = null;

            if (this.remoteVideo && this.remoteVideo[uuid] && this.remoteVideo[uuid].srcObject) {
                this.remoteVideo[uuid].srcObject.getTracks().forEach(track => track.stop());
                this.remoteVideo[uuid].srcObject = null;

            }

            if (this.peerTrackers[uuid]) {
                clearInterval(this.peerTrackers[uuid]);
            }

            this.peers[uuid].close();
            this.peers[uuid] = null;


        }

        delete this.peers[uuid];

        let remoteUids = this.state.remoteUids;
        delete remoteUids[uuid];

        let videoElements = this.state.videoElements;
        if (videoElements.indexOf(uuid) !== -1)
            videoElements.splice(videoElements.indexOf(uuid), 1);

        this.setState({
            remoteUids, videoElements
        }, () => {
            this.generateLayout();

            setTimeout(() => {
                this.forceUpdate();
            }, 500)
        })
    }

    call = async () => {

        this.setState({
            call: true
        }, async () => {

            if (!this.logoutResetInterval) {
                this.logoutResetInterval = setInterval(() => {
                    this.props.resetLogoutInterval();
                }, 10000);
            }



            this.props.socket.emit('call', {
                conversation: this.state.conversation._id,
                oldPeers: !Object.keys(this.peers).length && localStorage.getItem('videoCallPeers') ? JSON.parse(localStorage.getItem('videoCallPeers')) : [],
                oldSocketId: !Object.keys(this.peers).length && localStorage.getItem('oldSocketId') ? localStorage.getItem('oldSocketId') : null,
                browser: browserInfo(),
            })

        })


    }



    hardReconnect = (uuid) => {
        console.log('hard reconnect')
        this.setState({
            forceTcp: true
        })

        if (this.peers[uuid]) {
            this.peers[uuid].ontrack = null;
            this.peers[uuid].onremovetrack = null;
            this.peers[uuid].onremovestream = null;
            this.peers[uuid].onicecandidate = null;
            this.peers[uuid].oniceconnectionstatechange = null;
            this.peers[uuid].onsignalingstatechange = null;
            this.peers[uuid].onicegatheringstatechange = null;
            this.peers[uuid].onnegotiationneeded = null;

            if (this.remoteVideo && this.remoteVideo[uuid] && this.remoteVideo[uuid].srcObject) {
                this.remoteVideo[uuid].srcObject.getTracks().forEach(track => track.stop());
                this.remoteVideo[uuid].srcObject = null;
            }

            if (this.peerTrackers[uuid]) {
                clearInterval(this.peerTrackers[uuid]);
            }

            this.peers[uuid].close();
            this.peers[uuid] = null;
            delete this.peers[uuid];

        }


        this.props.socket.emit('hardReconnect', {
            conversation: this.state.conversation._id,
            uuids: [uuid],
        })


        /*let remoteUids = this.state.remoteUids;
        delete remoteUids[uuid];
        let videoElements = this.state.videoElements;
        videoElements.splice(videoElements.indexOf(uuid), 1);
        this.setState({
            videoElements: videoElements,
            remoteUids: remoteUids
        }, () => {
            this.props.socket.emit('hardReconnect', {
                conversation: this.state.conversation._id,
                uuids: [uuid],
            })
        })*/

    }



    endCall = (emit = true, disableClientCountCheck = false) => {
        // console.log("testttttttttttttt")
        if (this.props.uData && this.state.conversation && this.state.conversation.members && this.state.conversation.members[0] == this.props.uData._id) {
            if (!emit && this.state.call) {
                this.setState({
                    _hostEndCall: true
                })
            } else {
                try {
                    setTimeout(() => {
                        window.open('', '_self').close();

                    }, 1000);
                } catch (e) {

                }


                if (window && window.top)
                    window.top.postMessage('end-video-call', '*')

            }
        } else {
            if (this.state.call) {
                this.setState({
                    _endCall: {}
                })
            }
        }

        if (this.timerHandler)
            clearInterval(this.timerHandler);

        if (this.logoutResetInterval)
            clearInterval(this.logoutResetInterval);


        this.setState({
            timer: null,
            startTime: null,
            offerData: null,
            hands: {},
            hand: false

        },
            () => {


                // if (this.audioIncomingCall) {
                //     this.audioIncomingCall.pause();
                //     this.audioIncomingCall = null;
                // }

                /*if (this.audioCalling) {
                    this.audioCalling.pause();
                    this.audioCalling = null;
                }*/

                if (emit) {
                    this.props.socket.emit('endCall', {
                        conversation: this.state.conversation._id,
                        uuids: Object.keys(this.peers),
                        disableClientCountCheck: disableClientCountCheck
                    })
                }


                for (var uuid in this.peers) {
                    if (this.peers[uuid]) {
                        this.peers[uuid].ontrack = null;
                        this.peers[uuid].onremovetrack = null;
                        this.peers[uuid].onremovestream = null;
                        this.peers[uuid].onicecandidate = null;
                        this.peers[uuid].oniceconnectionstatechange = null;
                        this.peers[uuid].onsignalingstatechange = null;
                        this.peers[uuid].onicegatheringstatechange = null;
                        this.peers[uuid].onnegotiationneeded = null;

                        if (this.remoteVideo && this.remoteVideo[uuid] && this.remoteVideo[uuid].srcObject) {
                            this.remoteVideo[uuid].srcObject.getTracks().forEach(track => track.stop());
                            this.remoteVideo[uuid].srcObject = null;
                        }

                        if (this.peerTrackers[uuid]) {
                            clearInterval(this.peerTrackers[uuid]);
                        }

                        this.peers[uuid].close();
                        this.peers[uuid] = null;
                        delete this.peers[uuid];

                    }
                }

                if (this.audioVideoStream) {
                    if (this.localVideo) {
                        this.localVideo.srcObject = null;
                    }

                    this.audioVideoStream.getAudioTracks()[0].stop();
                    this.audioVideoStream.getVideoTracks()[0].stop();

                    this.audioVideoStream = null;

                    for (let i = 0; i < this.tmpStreams.length; i++) {
                        try {
                            if (this.tmpStreams[i]) {
                                this.tmpStreams[i].getTracks().forEach(track => track.stop());
                                this.tmpStreams[i] = null;
                            }
                        } catch (e) { }
                    }


                }


                this.peers = {};

                this.setState({
                    videoElements: [],
                    remoteUids: {},
                    call: null,
                    soundAllowed: null
                })


            })

        localStorage.removeItem('videoCallPeers');
        localStorage.removeItem('oldSocketId');
    }

    answer = async () => {
        let initiator = this.state.offerData.initiator;
        // if (this.audioIncomingCall) {
        //     this.audioIncomingCall.pause();
        //     this.audioIncomingCall = null;
        // }


        this.setState({
            call: true,
            offerData: null
        }, async () => {
            if (!this.logoutResetInterval) {
                this.logoutResetInterval = setInterval(() => {
                    this.props.resetLogoutInterval();
                }, 10000);
            }



            this.props.socket.emit('answer', {
                conversation: this.state.conversation._id,
                initiator: initiator,
                oldPeers: !Object.keys(this.peers).length && localStorage.getItem('videoCallPeers') ? JSON.parse(localStorage.getItem('videoCallPeers')) : [],
                oldSocketId: !Object.keys(this.peers).length && localStorage.getItem('oldSocketId') ? localStorage.getItem('oldSocketId') : null,
                browser: browserInfo(),
            })
        });
    }


    configureStreamAndAnswer = (videoInputDevice, audioInputDevice, audioOutputDevice, cameraOff, micOff) => {

        let params = getSearchParams(this.props[0].location.search, {});

        let videoCallConfig = this.state.prepareVideoCall;

        this.setState({
            videoInputDevice,
            audioInputDevice,
            audioOutputDevice,
            cameraOff,
            micOff,
            prepareVideoCall: null,
            _devicesPrepared: videoCallConfig
        }, async () => {


            await this.getStream();


            if (!videoCallConfig.initiator && (this.state.conversation && !this.state.conversation.activeCall)) {
                this.call();
            } else {
                this.setState({
                    call: true,
                    offerData: null,
                    peers: {},
                    videoElements: [],
                    remoteUids: {},
                    hands: {},
                    hand: false,
                }, async () => {
                    if (!this.logoutResetInterval) {
                        this.logoutResetInterval = setInterval(() => {
                            this.props.resetLogoutInterval();
                        }, 10000);
                    }


                    this.props.socket.emit('answer', {
                        conversation: videoCallConfig.conversation,
                        initiator: videoCallConfig.initiator,
                        initiatorRejoin: this.props.uData && this.state.conversation && this.state.conversation.activeCall && this.state.conversation.members[0] == this.props.uData._id,
                        guestName: params.roomId ? this.state.guestName : null,
                        oldPeers: !Object.keys(this.peers).length && localStorage.getItem('videoCallPeers') ? JSON.parse(localStorage.getItem('videoCallPeers')) : [],
                        oldSocketId: !Object.keys(this.peers).length && localStorage.getItem('oldSocketId') ? localStorage.getItem('oldSocketId') : null,
                        browser: browserInfo()
                    })
                });
            }
        })

    }

    registerEvents = () => {

        //this.props.socket.on

        this.props.socket.on('linkForNewMessage', async ({ conversationId, messageId, links }) => {
            if (!conversationId || !messageId || !links) return;
            if (this.state.chat && typeof this.state.chat === 'object' && Object.keys(this.state.chat).length > 0) {
                for (let uid in this.state.chat) {
                    if (this.state.chat[uid] && this.state.chat[uid].conversation && this.state.chat[uid].conversation._id && String(this.state.chat[uid].conversation._id) === conversationId && this.state.chat[uid].messages && this.state.chat[uid].messages.find(msg => String(msg._id) === messageId)) {
                        this.setState(prev => ({
                            ...prev,
                            chat: {
                                ...prev.chat,
                                [uid]: {
                                    ...prev.chat[uid],
                                    messages: prev.chat[uid].messages.map(msg => String(msg._id) === messageId ? { ...msg, links } : msg)
                                }
                            }
                        }))
                    }
                }
            } else {
                this.linkForMessageTimer = setTimeout(() => {
                    if (this.state.chat && typeof this.state.chat === 'object' && Object.keys(this.state.chat).length > 0) {
                        for (let uid in this.state.chat) {
                            if (this.state.chat[uid] && this.state.chat[uid].conversation && this.state.chat[uid].conversation._id && String(this.state.chat[uid].conversation._id) === conversationId && this.state.chat[uid].messages && this.state.chat[uid].messages.find(msg => String(msg._id) === messageId)) {
                                this.setState(prev => ({
                                    ...prev,
                                    chat: {
                                        ...prev.chat,
                                        [uid]: {
                                            ...prev.chat[uid],
                                            messages: prev.chat[uid].messages.map(msg => String(msg._id) === messageId ? { ...msg, links } : msg)
                                        }
                                    }
                                }))
                            }
                        }
                    }
                }, 1500);
            }
        });

        this.props.socket.on('bankIdGenerateRedirectURL', (data) => {
            this.setState({
                _bankIdRedirectUrl: data.redirectUrl
            })
        })
        this.props.socket.on('bankIdIdentificate', (data) => {
            this.setState({
                guestName: data.name
            })
        })

        this.props.socket.on('initPeer', async (data) => {
            console.log('initPeer', data);
            if (data.initiator == true && !data.offer && !data.answer) {
                console.log('Create offer');
                await this.initPeer(data.uuid, data.hardReconnect ? false : true, data.remoteUid, true, data.guestName, data.forceTcp ? true : false);
                let stream = await this.getStream();

                if (this.state.shareOn) {
                    this.screenStream.getTracks().forEach(track => this.peers[data.uuid].addTrack(track, stream));
                    stream.getTracks().forEach(track => { if (track && track.kind == 'audio') this.peers[data.uuid].addTrack(track, stream) });
                    this.props.socket.emit('shareScreen', { conversation: this.state.conversation._id, value: true });
                } else {
                    stream.getTracks().forEach(track => this.peers[data.uuid].addTrack(track, stream));
                }

                const offer = await this.peers[data.uuid].createOffer();
                let params = getSearchParams(this.props[0].location.search, {});

                if (params.preferCodec) {
                    let codecSwitch = new CodecSwitch();

                    let codecs = codecSwitch.extractCodecs(offer.sdp);
                    if (codecs.video) {
                        for (let i = 0; i < codecs.video.name.length; i++) {
                            if (codecs.video.name[i].indexOf(params.preferCodec) !== -1) {
                                offer.sdp = codecSwitch.preferCodec(codecs.audio.num[0], codecs.video.num[i], offer.sdp);
                                break;
                            }
                        }
                    }

                }

                await this.peers[data.uuid].setLocalDescription(offer);
                this.props.socket.emit('initPeer', { uuid: data.uuid, offer: offer, conversation: data.conversation, peers: data.peers, guestName: data.guestName, browser: browserInfo(), forceTcp: data.forceTcp ? true : false })
                return;
            } else if (data.initiator == false && data.offer && !data.answer && !this.state.offerData) {
                console.log('Create answer');

                await this.initPeer(data.uuid, data.hardReconnect ? false : true, data.remoteUid, false, data.guestName, data.forceTcp ? true : false);
                let stream = await this.getStream();
                if (this.state.shareOn) {
                    this.screenStream.getTracks().forEach(track => this.peers[data.uuid].addTrack(track, stream));
                    stream.getTracks().forEach(track => { if (track && track.kind == 'audio') this.peers[data.uuid].addTrack(track, stream) });
                    this.props.socket.emit('shareScreen', { conversation: this.state.conversation._id, value: true });
                } else {
                    stream.getTracks().forEach(track => this.peers[data.uuid].addTrack(track, stream));
                }


                //console.log(data.offer);
                //data.offer.sdp = this.preferBitRate(data.offer.sdp, 10, 'video');

                await this.peers[data.uuid].setRemoteDescription(
                    new RTCSessionDescription(data.offer)
                );


                //this.changeBandwidth(data.uuid, 10);


                if (this.candidates && this.candidates[data.uuid]) {
                    for (let i = 0; i < this.candidates[data.uuid].length; i++)
                        await this.peers[data.uuid].addIceCandidate(new RTCIceCandidate(this.candidates[data.uuid][i]));
                    this.candidates[data.uuid] = [];
                }

                const answer = await this.peers[data.uuid].createAnswer();
                //answer.sdp = this.preferBitRate(answer.sdp, 10, 'video');
                let params = getSearchParams(this.props[0].location.search, {});

                if (params.preferCodec) {
                    let codecSwitch = new CodecSwitch();
                    let codecs = codecSwitch.extractCodecs(answer.sdp);
                    if (codecs.video) {
                        for (let i = 0; i < codecs.video.name.length; i++) {
                            if (codecs.video.name[i].indexOf(params.preferCodec) !== -1) {
                                answer.sdp = codecSwitch.preferCodec(codecs.audio.num[0], codecs.video.num[i], answer.sdp);
                                break;
                            }
                        }
                    }

                }

                await this.peers[data.uuid].setLocalDescription(new RTCSessionDescription(answer));

                this.peerStatus[data.uuid] = true;

                this.props.socket.emit('initPeer', { uuid: data.uuid, answer: answer, conversation: data.conversation, peers: data.peers, guestName: data.guestName, browser: browserInfo(), forceTcp: data.forceTcp ? true : false })

                //this.changeBandwidth(data.uuid, 10);

                return;
            } else if (data.answer && !this.state.offerData && this.peers[data.uuid]) {
                console.log('Set answer');


                //console.log(data.answer);
                //data.answer.sdp = this.preferBitRate(data.answer.sdp, 10, 'video');

                await this.peers[data.uuid].setRemoteDescription(
                    new RTCSessionDescription(data.answer)
                );

                //this.changeBandwidth(data.uuid, 10);

                if (this.candidates && this.candidates[data.uuid]) {
                    for (let i = 0; i < this.candidates[data.uuid].length; i++)
                        await this.peers[data.uuid].addIceCandidate(new RTCIceCandidate(this.candidates[data.uuid][i]));

                    this.candidates[data.uuid] = [];
                }

                this.peerStatus[data.uuid] = true;
                //this.changeBandwidth(data.uuid, 1);
                return;
            }


        })



        this.props.socket.on('reconnectPeer', async (data) => {
            console.log('recconnectPeer', data.uuid, data);

            if (data.offer) {

                this.reconnectingPeer[data.uuid] = new Date().getTime() / 1000;

                console.log('set reconnect offer & create answer', data.uuid)


                await this.peers[data.uuid].setRemoteDescription(
                    new RTCSessionDescription(data.offer)
                );

                /*if (this.candidates && this.candidates[data.uuid]) {
                    for (let i = 0; i < this.candidates[data.uuid].length; i++) {
                        console.log(`adding icecandidate`, data.uuid, this.candidates[data.uuid][i]);
                        await this.peers[data.uuid].addIceCandidate(new RTCIceCandidate(this.candidates[data.uuid][i]));
                    }

                    this.candidates[data.uuid] = [];
                }*/

                if (data.candidates && data.candidates.length) {
                    for (let i = 0; i < data.candidates.length; i++) {
                        if (data.candidates[i]) {
                            await this.peers[data.uuid].addIceCandidate(new RTCIceCandidate(data.candidates[i]));
                        }
                    }
                }

                const answer = await this.peers[data.uuid].createAnswer();
                await this.peers[data.uuid].setLocalDescription(new RTCSessionDescription(answer));



            } else if (data.answer) {
                this.reconnectingPeer[data.uuid] = new Date().getTime() / 1000;

                console.log('set reconnect answer', data.uuid)

                await this.peers[data.uuid].setRemoteDescription(
                    new RTCSessionDescription(data.answer)
                );

                if (data.candidates && data.candidates.length) {
                    for (let i = 0; i < data.candidates.length; i++) {
                        if (data.candidates[i]) {
                            await this.peers[data.uuid].addIceCandidate(new RTCIceCandidate(data.candidates[i]));
                        }
                    }
                }


                /*if (this.candidates && this.candidates[data.uuid]) {
                    for (let i = 0; i < this.candidates[data.uuid].length; i++) {
                        console.log(`adding icecandidate`, data.uuid, this.candidates[data.uuid][i]);
                        await this.peers[data.uuid].addIceCandidate(new RTCIceCandidate(this.candidates[data.uuid][i]));
                    }
                    this.candidates[data.uuid] = [];
                }*/

            }

        })


        this.props.socket.on('call', async (data) => {
            let params = getSearchParams(this.props[0].location.search, {});

            if (this.props[0].match.params.conversation && params.roomId) {


                if (this.state._devicesPrepared && this.state._devicesPrepared.initiator) {
                    if (this.state.call == true) {

                        this.setState({
                            call: true,
                            /*offerData: null,
                            peers: {},
                            videoElements: [],
                            remoteUids: {},
                            hands: {},
                            hand: false*/
                        }, async () => {
                            if (!this.logoutResetInterval) {
                                this.logoutResetInterval = setInterval(() => {
                                    this.props.resetLogoutInterval();
                                }, 10000);
                            }


                            this.props.socket.emit('answer', {
                                conversation: this.state._devicesPrepared.conversation,
                                initiator: this.state._devicesPrepared.initiator,
                                guestName: params.roomId ? this.state.guestName : null,
                                oldPeers: !Object.keys(this.peers).length && localStorage.getItem('videoCallPeers') ? JSON.parse(localStorage.getItem('videoCallPeers')) : [],
                                oldSocketId: !Object.keys(this.peers).length && localStorage.getItem('oldSocketId') ? localStorage.getItem('oldSocketId') : null,
                                browser: browserInfo(),
                            })
                        });
                    } else {

                        this.setState({
                            call: true,
                            offerData: null,
                            peers: {},
                            videoElements: [],
                            remoteUids: {},
                            hands: {},
                            hand: false
                        }, async () => {
                            if (!this.logoutResetInterval) {
                                this.logoutResetInterval = setInterval(() => {
                                    this.props.resetLogoutInterval();
                                }, 10000);
                            }


                            this.props.socket.emit('answer', {
                                conversation: this.state._devicesPrepared.conversation,
                                initiator: this.state._devicesPrepared.initiator,
                                guestName: params.roomId ? this.state.guestName : null,
                                oldPeers: !Object.keys(this.peers).length && localStorage.getItem('videoCallPeers') ? JSON.parse(localStorage.getItem('videoCallPeers')) : [],
                                oldSocketId: !Object.keys(this.peers).length && localStorage.getItem('oldSocketId') ? localStorage.getItem('oldSocketId') : null,
                                browser: browserInfo(),

                            })
                        });
                    }

                } else {
                    this.setState({
                        prepareVideoCall: {
                            conversation: data.conversation,
                            initiator: data.initiator
                        }
                    });
                }


            }

            let conversations = this.state.conversations;

            for (let i = 0; i < conversations.length; i++) {
                if (conversations[i]._id == data.conversation) {
                    if (this.props.uData && data.initiatorUid != this.props.uData._id)
                        conversations[i].currentCall = data;
                }
            }

            this.setState({
                conversations
            })
        })

        this.props.socket.on('endCall', async (data) => {
            if (this.state.conversation && this.state.conversation._id == data.conversation) {

                if (data.logUUID) {
                    this.setState({
                        logUUID: data.logUUID
                    })
                }
                this.endCall(false);
            }
        });

        this.props.socket.on('logUUID', async (data) => {
            if (data.logUUID) {
                this.setState({
                    logUUID: data.logUUID
                })
            }
        });

        // this.props.socket.on('removePeers', async (data) => {
        //     for (let i = 0; i < data.uuids.length; i++)
        //         this.removePeer(data.uuids[i]);
        // });


        this.props.socket.on('answer', async (data) => {

        })


        this.props.socket.on('icecandidate', async (data) => {
            console.log('received icecandidate', data)
            if (data.candidate) {
                try {
                    if (this.peers[data.uuid] && this.peers[data.uuid].remoteDescription) {
                        console.log('adding directly icecandidate');
                        await this.peers[data.uuid].addIceCandidate(new RTCIceCandidate(data.candidate));
                    } else {
                        let candidates = this.candidates && this.candidates[data.uuid] ? this.candidates[data.uuid] : [];
                        candidates.push(data.candidate);
                        this.candidates[data.uuid] = candidates;
                    }
                } catch (e) {
                    console.error('Error adding received ice candidate', e);
                }
            }
        })
        this.props.socket.on('groupDoctors', (data) => {
            if (data && data.users) {
                this.setState({ chatUsers: data.users }, () => {
                    // this.checkConversations()
                })
            }
            // let chat = this.state.chat;
            // chat[data.conversation].searchUsers = data.users;
            // this.setState({
            //     chat
            // })
        })



        this.props.socket.on('conversations', (data) => {

            this.setState({ conversations: data }, () => {
                // this.checkConversations()
                if (this.state._userToOpen) {
                    this.openChat(this.state._userToOpen);
                    this.setState({
                        _userToOpen: null
                    })


                }
            });
            if (!this.state.conversation && data.length) {

                let params = getSearchParams(this.props[0].location.search, {});


                if (this.props[0].match.params.windowConversation) {
                    let idx = 0;
                    for (let i = 0; i < data.length; i++) {
                        if (data[i]._id == this.props[0].match.params.windowConversation) {
                            idx = i;
                            break;
                        }
                    }

                    //this.updateParams('conversation', null);
                    this.openConversation(data[idx], () => {
                    });
                    return;

                }

                if (this.props[0].match.params.conversation && params.roomId) {

                    let idx = 0;
                    for (let i = 0; i < data.length; i++) {
                        if (data[i]._id == this.props[0].match.params.conversation) {
                            idx = i;
                            break;
                        }
                    }

                    this.updateParams('conversation', null);
                    this.openConversation(data[idx], () => {
                    });
                    return;

                }

                if (this.props[0].match.params.conversation && !params.initiator) {
                    let idx = 0;
                    for (let i = 0; i < data.length; i++) {
                        if (data[i]._id == this.props[0].match.params.conversation) {
                            idx = i;
                            break;
                        }
                    }

                    this.updateParams('conversation', null);
                    this.updateParams('startVideoCall', null);
                    this.openConversation(data[idx], () => {
                        this.setState({
                            prepareVideoCall: {
                                conversation: data[idx]._id,
                                initiator: null
                            }
                        });
                    });
                    return;
                }

                if (params.conversation) {
                    let idx = 0;
                    for (let i = 0; i < data.length; i++) {
                        if (data[i]._id == params.conversation) {
                            idx = i;
                            break;
                        }
                    }
                    this.openConversation(data[idx]);
                    this.updateParams('conversation', null);
                    return;
                }

                if (this.props[0].match.params.conversation && params.initiator) {
                    let idx = 0;
                    for (let i = 0; i < data.length; i++) {
                        if (data[i]._id == this.props[0].match.params.conversation) {
                            idx = i;
                            break;
                        }
                    }

                    this.openConversation(data[idx]);
                    //this.updateParams('initiator', null);
                    if (!this.state.call) {
                        this.setState({
                            prepareVideoCall: {
                                conversation: data[idx]._id,
                                initiator: params.initiator
                            }
                        });
                    }





                } else {
                    //this.openConversation(data[0])
                }
            }
        })




        this.props.socket.on('changeBandwidth', (data) => {
            if (this.state.conversation && data.conversation == this.state.conversation._id) {
                this.changeBandwidth(data.uuid, data.bandwidth);
            }
        })


        this.props.socket.on('guestNames', (data) => {
            if (this.state.conversation && data.conversation == this.state.conversation._id) {
                let conversation = this.state.conversation;
                for (var uid in data.guestNames) {
                    let found = false;
                    for (let i = 0; i < conversation.users.length; i++) {
                        if (conversation.users[i].uid == uid) {
                            found = true;
                        }
                    }
                    if (!found) {
                        conversation.users.push({
                            uid: uid,
                            name: data.guestNames[uid],
                            bankIdVerified: data.guestsVerificationStatus ? data.guestsVerificationStatus[uid] : null
                        })
                    }
                }

                this.setState({
                    conversation
                })


            }

            this.props.socket.emit('conversations', { profile: typeof window != 'undefined' ? localStorage?.getItem?.('profile') : null, excludeClosed: true, groupDoctors: true, group: this.props.selectedGroup });
        })


        this.props.socket.on('closeConversation', (data) => {
            if (data.conversation && this.state.openedChats && this.state.openedChats.length) {
                let chat = this.state.chat;
                if (chat && chat[data.conversation] && chat[data.conversation].conversation) {
                    chat[data.conversation].conversation.closed = true;
                    let openedChats = this.state.openedChats;
                    let clickedChats = this.state.clickedChats;

                    let newOpenedChats = []
                    for (let i = 0; i < openedChats.length; i++) {
                        if (openedChats[i]._id != data.conversation) {
                            newOpenedChats.push(openedChats[i])
                        }
                    }
                    let newClickedChats = [];
                    for (let i = 0; i < clickedChats.length; i++) {
                        if (clickedChats[i]._id != data.conversation) {
                            newClickedChats.push(clickedChats[i])
                        }
                    }



                    this.setState({
                        chat,
                        openedChats: newOpenedChats,
                        clickedChats: newClickedChats,
                    })
                }


            }

            this.props.socket.emit('conversations', { profile: typeof window != 'undefined' ? localStorage?.getItem?.('profile') : null, excludeClosed: true, groupDoctors: true, group: this.props.selectedGroup });
        })
        this.props.socket.on('openConversation', (data) => {
            if (this.state.conversation && data.conversation == this.state.conversation._id) {
                let conversation = this.state.conversation;
                conversation.closed = false;
                if (conversation && conversation.referral && conversation.referral.status == 'closed') {
                    conversation.referral.status = 'in-progress';
                }
                this.setState({
                    conversation
                })
            }

            this.props.socket.emit('conversations', { profile: typeof window != 'undefined' ? localStorage?.getItem?.('profile') : null, excludeClosed: true, groupDoctors: true, group: this.props.selectedGroup });
        })
        this.props.socket.on('deleteMessage', (data) => {
            if (data.conversation && this.state.openedChats && this.state.openedChats.length) {
                // let conversation = this.state.conversation;
                // this.setState({
                //     conversation
                // }, () => {
                // })

            }

            this.props.socket.emit('conversations', { profile: typeof window != 'undefined' ? localStorage?.getItem?.('profile') : null, excludeClosed: true, groupDoctors: true, group: this.props.selectedGroup });
        })

        this.props.socket.on('deleteConversation', (data) => {
            if (this.state.conversation && data.conversation == this.state.conversation._id) {
                this.setState({
                    conversation: null
                })
            }

            this.props.socket.emit('conversations', { profile: typeof window != 'undefined' ? localStorage?.getItem?.('profile') : null, excludeClosed: true, groupDoctors: true, group: this.props.selectedGroup });
        })


        this.props.socket.on('createNewConversation', (data) => {
            this.setState({
                _saving: null,

            }, () => {

                if (data && data.user) {
                    // let conversations = this.state.conversations;
                    // conversations.push(data.conversation)
                    //                    this.setState({ conversations: data.conversations }, () => this.openChat(data.user))
                    this.setState({
                        _userToOpen: data.user
                    }, () => {
                        this.props.socket.emit('conversations', { profile: typeof window != 'undefined' ? localStorage?.getItem?.('profile') : null, excludeClosed: true, groupDoctors: true, group: this.props.selectedGroup });
                    })
                }


            })

        })




        this.props.socket.on('messages', (data) => {
            if (data.conversation && this.state.openedChats && this.state.openedChats.length) {

                let chat = this.state.chat;

                if (this.state.chat && this.state.chat[data.conversation] && this.state.chat[data.conversation].page == 0) {
                    chat[data.conversation].messages = [...data.messages];
                } else if (this.state.chat && this.state.chat[data.conversation] && this.state.chat[data.conversation].page > 0) {
                    chat[data.conversation].messages = [...data.messages, ...chat[data.conversation].messages]
                    console.log(chat[data.conversation].messages.length)
                }

                let oldScrollHeight = 0;
                if (this.messagesRef && this.messagesRef[data.conversation]) {
                    oldScrollHeight = this.messagesRef[data.conversation].scrollHeight;
                }
                chat[data.conversation].numberOfPatients = 0;

                this.setState({ chat }, () => {
                    if (this.messagesRef && this.state.chat[data.conversation] && this.state.chat[data.conversation].page && this.messagesRef[data.conversation] && this.state.chat[data.conversation].page == 0) {
                        this.messagesRef[data.conversation].scrollTop = this.messagesRef[data.conversation].scrollHeight;
                    } else if (oldScrollHeight) {
                        this.messagesRef[data.conversation].scrollTop = this.messagesRef[data.conversation].scrollTop + (this.messagesRef[data.conversation].scrollHeight - oldScrollHeight);
                    }
                });
            }
        })
        this.props.socket.on('newMessage', (data) => {
            if (data.conversation && this.state.openedChats && this.state.openedChats.length) {
                let chat = this.state.chat;
                chat[data.conversation].page = 0;
                chat[data.conversation]._newMessage = true;
                // chat[data.conversation].conversation = data.conversation;
                this.setState({
                    chat
                }, () => {
                    this.props.socket.emit('messages', { conversation: data.conversation, page: this.state.chat[data.conversation].page })
                })

                if (this.state.call && !this.state.showChat)
                    this.setState({ videoCallNewMessage: true });
            } else {
                let conversations = this.state.conversations;
                for (let i = 0; i < conversations.length; i++) {
                    if (conversations[i]._id == data.conversation) {
                        conversations[i]._newMessage = true;
                    }
                }
                this.setState({
                    conversations
                })
            }
        })
        this.props.socket.on('setTranslationLanguage', (data) => {
            console.log(data)
            if (data.conversation && this.state.openedChats && this.state.openedChats.length) {
                // let conversation = this.state.conversation;
                // conversation.memberLanguages = data.memberLanguages;
                // this.setState({
                //     conversation
                // })
                let chat = this.state.chat;
                if (chat?.[data.conversation]?.conversation)
                    chat[data.conversation].conversation.memberLanguages = data.memberLanguages;

                chat[data.conversation].page = 0;
                this.setState({
                    chat
                }, () => {
                    this.props.socket.emit('messages', { conversation: data.conversation, page: this.state.chat[data.conversation].page })
                })
            }
        })



        this.props.socket.on('seenUpdate', (data) => {
            if (data.conversation && this.state.openedChats && this.state.openedChats.length) {
                let chat = this.state.chat;
                let chat2 = chat[data.conversation];


                if (chat[data.conversation] && chat[data.conversation].messages) {

                    for (let i = 0; i < chat[data.conversation].messages.length; i++) {
                        if (chat[data.conversation].messages[i]._id == data.message) {
                            chat[data.conversation].messages[i].seen = data.seen;
                        }
                    }
                    this.setState({
                        chat
                    })
                }

            }
        })


        this.props.socket.on('searchMembers', (data) => {
            if (data.conversation && this.state.openedChats && this.state.openedChats.length) {
                let chat = this.state.chat;
                chat[data.conversation].searchUsers = data.users;
                this.setState({
                    chat
                })
            }
        })


        this.props.socket.on('addMember', (data) => {
            if (data.conversation && this.state.openedChats && this.state.openedChats.length) {
                let chat = this.state.chat;
                if (chat?.[data.conversation]?.conversation) {
                    chat[data.conversation].conversation.members = data.members;
                    chat[data.conversation].conversation.users = data.users;
                    chat[data.conversation].numberOfPatients = data.numberOfPatients;
                }



                this.setState({
                    chat

                }, this.forceUpdate)
            }
            this.props.socket.emit('conversations', { profile: typeof window != 'undefined' ? localStorage?.getItem?.('profile') : null, excludeClosed: true, groupDoctors: true, group: this.props.selectedGroup });
        })


        this.props.socket.on('removeMember', (data) => {
            if (data.conversation && this.state.openedChats && this.state.openedChats.length) {
                let chat = this.state.chat;
                if (chat?.[data.conversation]?.conversation) {
                    chat[data.conversation].conversation.members = data.members;
                    chat[data.conversation].conversation.users = data.users;
                    chat[data.conversation].numberOfPatients = data.numberOfPatients;
                }



                this.setState({
                    chat

                }, this.forceUpdate)
            }
            this.props.socket.emit('conversations', { profile: typeof window != 'undefined' ? localStorage?.getItem?.('profile') : null, excludeClosed: true, groupDoctors: true, group: this.props.selectedGroup });
        })

        // this.props.socket.on('updateJournalNote', (data) => {
        //     if (this.state.conversation && data.conversation == this.state.conversation._id) {
        //         let conversation = this.state.conversation;
        //         conversation.members = data.members;
        //         conversation.users = data.users;
        //         conversation.journalNote = data.journalNote;

        //         this.setState({
        //             conversation: conversation,
        //             numberOfPatients: data.numberOfPatients,
        //         }, this.forceUpdate)
        //     } else if (this.state.conversation && this.state.openedJournalNotes.filter(item => item == this.state.conversation._id).length > 0) {
        //         console.log('sssss')
        //         this.setState({ journalNote: null }, () => {
        //             this.findHealthCareFormular()
        //         })

        //     }

        //     // this.props.socket.emit('conversations', { profile: typeof window != 'undefined' ? localStorage?.getItem?.('profile') : null, excludeClosed: true, groupDoctors: true, group: this.props.selectedGroup });
        // })


        this.props.socket.on('raiseHand', (data) => {
            if (this.state.conversation && data.conversation == this.state.conversation._id) {
                let hands = this.state.hands;
                hands[data.uid] = true;

                this.setState({
                    //activeClient: data.uid,
                    activeClient: !this.state.screenSharing ? data.uid : this.state.screenSharing && this.state.activeClient ? this.state.activeClient : null,

                }, this.generateLayout);

                let raiseHandSound = new Audio(handSound);
                /*raiseHandSound.addEventListener('ended', () => {
 
                 }, false);*/

                raiseHandSound.play();


                this.setState({
                    hands: hands
                })
            }
        });

        this.props.socket.on('shareScreen', (data) => {
            if (this.state.conversation && data.conversation == this.state.conversation._id) {
                this.setState({
                    screenSharing: data.value,
                    activeClient: data.uid
                }, this.generateLayout)
            }
        });


        this.props.socket.on('handDown', (data) => {
            if (this.state.conversation && data.conversation == this.state.conversation._id) {
                let hands = this.state.hands;
                hands[data.uid] = false;
                this.setState({
                    hands: hands
                })
            }
        });

        this.props.socket.on('callEnded', (data) => {
            if (this.state.conversation && data.conversation == this.state.conversation._id) {
                let conversation = this.state.conversation;
                conversation.currentCall = null;

                this.setState({
                    conversation
                })
            }

            let conversations = this.state.conversations;

            for (let i = 0; i < conversations.length; i++) {
                if (conversations[i]._id == data.conversation) {
                    conversations[i].currentCall = null;
                }
            }

            this.setState({
                conversations
            })


        });


        this.props.socket.on('rejoinData', (data) => {
            console.log('rejoinData');
            if (this.state.conversation && data.conversation == this.state.conversation._id) {
                let conversation = this.state.conversation;
                conversation.currentCall = data;

                this.setState({
                    conversation
                })
            }

            let conversations = this.state.conversations;

            for (let i = 0; i < conversations.length; i++) {
                if (conversations[i]._id == data.conversation) {
                    conversations[i].currentCall = data;
                }
            }

            this.setState({
                conversations
            })

        })


        // this.props.socket.on('answerCallback', (data) => {
        //     //alert('answerCallback')
        //     if (this.state.conversation && data.conversation == this.state.conversation._id) {
        //         let conversation = this.state.conversation;
        //         conversation.currentCall = null;

        //         this.setState({
        //             conversation
        //         })
        //     }

        //     let conversations = this.state.conversations;

        //     for (let i = 0; i < conversations.length; i++) {
        //         if (conversations[i]._id == data.conversation) {
        //             conversations[i].currentCall = null;
        //         }
        //     }

        //     this.setState({
        //         conversations
        //     })
        // })



        this.props.socket.on('userSpeak', (data) => {
            if (this.state.conversation && data.conversation == this.state.conversation._id) {
                let speaking = this.state.speaking;

                let check = false;
                for (var uid in speaking) {
                    if (speaking[uid]) {
                        check = true;
                        break;
                    }
                }

                speaking[data.uid] = data.value;



                this.setState({
                    speaking: speaking,
                    activeClient: (!this.state.screenSharing || this.state.fullScreen) && data.value && !check ? data.uid : this.state.screenSharing && this.state.activeClient ? this.state.activeClient : this.state.activeClient ? this.state.activeClient : null,
                }, this.generateLayout);
            }
        });
        this.props.socket.on('cameraStatus', (data) => {
            if (this.state.conversation && data.conversation == this.state.conversation._id) {
                let cameraStatus = this.state.cameraStatus;
                cameraStatus[data.uid] = data.value;
                this.setState({
                    cameraStatus: cameraStatus
                })
            }
        });
        this.props.socket.on('micStatus', (data) => {
            if (this.state.conversation && data.conversation == this.state.conversation._id) {
                let micStatus = this.state.micStatus;
                micStatus[data.uid] = data.value;
                this.setState({
                    micStatus: micStatus
                })
            }
        });



        this.props.socket.on('userTyping', (data) => {
            if (data.conversation && this.state.openedChats && this.state.openedChats.length) {
                let chat = this.state.chat;
                if (chat && chat[data.conversation]) {
                    chat[data.conversation].userTyping = data.user;
                    this.setState({
                        chat
                    })
                }

            }
        });

        this.props.socket.on('userEndTyping', (data) => {
            if (data.conversation && this.state.openedChats && this.state.openedChats.length) {
                let chat = this.state.chat;
                if (chat && chat[data.conversation]) {
                    chat[data.conversation].userTyping = null;
                    this.setState({
                        chat
                    })
                }

            }
        });
        this.props.socket.on('userTyping2', (data) => {
            if (data.conversation && this.state.openedChats && this.state.openedChats.length) {
                let userTyping = this.state.userTyping;
                if (!userTyping) { userTyping = {} }
                if (!userTyping[data.conversation]) userTyping[data.conversation] = {};
                if (!userTyping[data.conversation][data.chatType]) userTyping[data.conversation][data.chatType] = {};

                userTyping[data.conversation][data.chatType] = { ...userTyping[data.conversation][data.chatType], ...data.user }
                this.setState({ userTyping })

            }
        });
        this.props.socket.on('userEndTyping2', (data) => {
            if (data.conversation && this.state.openedChats && this.state.openedChats.length) {
                let userTyping = this.state.userTyping;
                if (userTyping && userTyping[data.conversation] && userTyping[data.conversation][data.chatType]) {
                    delete userTyping[data.conversation][data.chatType];

                    this.setState({
                        userTyping
                    })
                }

            }
        });


        this.props.socket.on('userOnline', (data) => {
            if (this.state.conversation) {
                let conversation = this.state.conversation;
                if (conversation.users) {
                    for (let i = 0; i < conversation.users.length; i++) {
                        if (conversation.users[i].uid == data.uid) {
                            conversation.users[i].online = true;
                        }
                    }
                }
                this.setState({
                    conversation
                })
            }
        });
        this.props.socket.on('userOffline', (data) => {
            if (this.state.conversation) {
                let conversation = this.state.conversation;
                if (conversation.users) {
                    for (let i = 0; i < conversation.users.length; i++) {
                        if (conversation.users[i].uid == data.uid) {
                            conversation.users[i].online = false;
                        }
                    }
                }
                this.setState({
                    conversation
                })
            }
        });

        this.props.socket.on('videoCallLimit', (data) => {
            if (this.state.conversation && this.state.conversation._id == data.conversation) {
                let videoCallClients = this.state.videoCallClients;
                for (let i = 0; i < this.state.videoCallClients.length; i++) {
                    if (this.state.videoCallClients[i].socketId == data.socketId) {
                        this.state.videoCallClients[i].allowed = false;
                    }
                }

                this.setState({
                    videoCallLimit: true,
                    videoCallClients: videoCallClients
                })
            }
        });

        this.props.socket.on('newClientConnected', (data) => {
            if (this.state.conversation && this.state.conversation._id == data.conversation) {
                let videoCallClients = this.state.videoCallClients;

                let found = false;
                for (let i = 0; i < videoCallClients.length; i++) {
                    if (videoCallClients[i].uid == data.uid) {
                        videoCallClients[i] = data;
                        videoCallClients[i].allowed = false;
                        found = true;
                    }
                }

                if (!found)
                    videoCallClients.push(data);

                this.setState({
                    videoCallClients: videoCallClients,
                    newClientConnectedNotification: true
                })
            }
        });

        this.props.socket.on('updateConnectedClient', (data) => {
            if (this.state.conversation && this.state.conversation._id == data.conversation) {
                let videoCallClients = this.state.videoCallClients;
                for (let i = 0; i < this.state.videoCallClients.length; i++) {
                    if (this.state.videoCallClients[i].socketId == data.oldSocketId) {
                        this.state.videoCallClients[i].socketId = data.socketId;
                    }
                }

                this.setState({
                    videoCallClients: videoCallClients
                })
            }
        });

        this.props.socket.on('removeConnectedClient', (data) => {
            if (this.state.conversation && this.state.conversation._id == data.conversation) {
                let index = -1;
                let videoCallClients = this.state.videoCallClients;
                for (let i = 0; i < this.state.videoCallClients.length; i++) {
                    if (this.state.videoCallClients[i].socketId == data.socketId) {
                        index = i;
                    }
                }

                if (index != -1) {
                    videoCallClients.splice(index, 1);
                }

                this.setState({
                    videoCallClients: videoCallClients
                })
            }
        });


        this.props.socket.on('removeClient', (data) => {
            if (this.state.conversation && data.conversation == this.state.conversation._id) {
                this.endCall(true, true);
            }
        })





        this.props.socket.on('connect', this.socketConnect);


    }

    socketConnect = () => {

        let params = getSearchParams(this.props[0].location.search, {});
        if (params.roomId && !this.state.call) {
            this.props.socket.emit('guestConnected', {});
        }
    }


    resizeWindow = () => {
        this.generateLayout();


        var maxHeight = window.screen.height,
            maxWidth = window.screen.width,
            curHeight = window.innerHeight,
            curWidth = window.innerWidth;

        let maximised = screen.availWidth - window.innerWidth === 0;
        if (this.state.shareOn && this.state.resizeWindowAfter && !maximised && window.opener) {
            try {

                window.resizeTo(200, window.innerHeight / 2);
                this.setState({
                    resizeWindowAfter: false,
                    showScreenShareMode: true,
                    windowDimensions: {
                        width: 200,
                        height: window.innerHeight
                    }
                })


            } catch (e) {
                console.log(e);
            }




        } else {

            if (this.state.showScreenShareMode) {
                if (curWidth > 200) {
                    this.setState({
                        showScreenShareMode: false,
                        windowDimensions: null
                    })
                }
            }

            if (!this.state.showScreenShareMode && this.state.shareOn && curWidth < 300) {
                this.setState({
                    showScreenShareMode: true,
                    windowDimensions: {
                        width: curWidth,
                        height: curHeight
                    }
                }, this.generateLayout)
            }
        }


        if (window.webkitCurrentFullScreenElement !== 'undefined') {
            if (window.document && window.document.webkitCurrentFullScreenElement) {
                this.setState({
                    fullScreen: true
                }, this.generateLayout)

            } else {
                this.setState({
                    fullScreen: false
                }, this.generateLayout)

            }
        } else {
            if (maxWidth == curWidth && maxHeight == curHeight) {
                this.setState({ fullScreen: true }, this.generateLayout)
                // do something
            } else {
                this.setState({ fullScreen: false }, this.generateLayout);
            }
        }

    }

    componentWillUnmount() {
        document.body.style.overflowX = 'auto';

        if (this.linkForMessageTimer) clearTimeout(this.linkForMessageTimer);

        if (this.logoutResetInterval) {
            clearInterval(this.logoutResetInterval);
        }

        window.removeEventListener('resize', this.resizeWindow);

        if (this.props.socket) {

            if (this.state.call) {
                this.props.socket.emit('hangUp', { conversation: this.state.conversation._id, uuids: Object.keys(this.peers) })
            }

            this.props.socket.removeListener('linkForNewMessage');
            this.props.socket.removeListener('bankIdGenerateRedirectURL');
            this.props.socket.removeListener('bankIdIdentificate');
            this.props.socket.removeListener('initPeer');
            this.props.socket.removeListener('endCall');
            this.props.socket.removeListener('answer');
            this.props.socket.removeListener('icecandidate');
            this.props.socket.removeListener('conversations');
            this.props.socket.removeListener('messages');
            this.props.socket.removeListener('newMessage');
            this.props.socket.removeListener('setTranslationLanguage');
            this.props.socket.removeListener('seenUpdate');
            this.props.socket.removeListener('searchMembers');
            this.props.socket.removeListener('raiseHand');
            this.props.socket.removeListener('handDown');
            this.props.socket.removeListener('userTyping');
            this.props.socket.removeListener('userEndTyping');
            this.props.socket.removeListener('userTyping2');
            this.props.socket.removeListener('userEndTyping2');
            this.props.socket.removeListener('userOnline');
            this.props.socket.removeListener('userOffline');
            this.props.socket.removeListener('createConversation');
            this.props.socket.removeListener('createNewConversation');
            this.props.socket.removeListener('removePeers');
            this.props.socket.removeListener('shareScreen');
            this.props.socket.removeListener('reconnectPeer');
            this.props.socket.removeListener('guestNames');
            this.props.socket.removeListener('userSpeak');
            this.props.socket.removeListener('cameraStatus');
            this.props.socket.removeListener('micStatus');
            this.props.socket.removeListener('changeBandwidth');
            this.props.socket.removeListener('callEnded');
            this.props.socket.removeListener('videoCallLimit');
            this.props.socket.removeListener('newClientConnected');
            this.props.socket.removeListener('removeClient');
            this.props.socket.removeListener('updateConnectedClient');
            this.props.socket.removeListener('removeConnectedClient');
            this.props.socket.removeListener('logUUID');
            this.props.socket.removeListener('answerCallback');
            this.props.socket.removeListener('rejoinData');

            this.props.socket.removeListener('connect', this.socketConnect);

            //this.props.socket.disconnect();
        }

        if (this.videoCallConnectionInterval) {
            clearInterval(this.videoCallConnectionInterval);
        }
    }

    componentDidUpdate(prevProps, prevState) {
        if (!this.state.call && this.logoutResetInterval) {
            clearInterval(this.logoutResetInterval);
            this.logoutResetInterval = null;
        }
        if (typeof window != 'undefined' && localStorage.groupAlias && localStorage.groupAlias != 'account' && this.state.patientGroupAlias && prevProps[0].location.pathname != this.props[0].location.pathname) {
            this.setState({ patientGroupAlias: localStorage.groupAlias })
        }


        if (prevState.openChatList != this.state.openChatList || (JSON.stringify(prevState.clickedChats) != JSON.stringify(this.state.clickedChats)) || (JSON.stringify(prevState.openedChats) != JSON.stringify(this.state.openedChats)) || (JSON.stringify(prevState.chat) != JSON.stringify(this.state.chat))) {

            let chat = {
                openChatList: this.state.openChatList,
                clickedChats: this.state.clickedChats,
                openedChats: this.state.openedChats,
                chat: this.state.chat
            }
            this.props.updateChat(chat)
        }
        if (prevProps[0].location.pathname != this.props[0].location.pathname && this.props.chat) {
            this.setState({
                openChatList: this.props.chat.openChatList,
                clickedChats: this.props.chat.clickedChats,
                openedChats: this.props.chat.openedChats,
                chat: this.props.chat.chat
            })
        }
        if (prevState.conversations != this.state.conversations && this.state.conversations.length) {
            let conversations = this.state.conversations;
            let clickedChats = this.state.clickedChats;
            let newClickedChats = [];

            // conversations[0]._newMessage = true;
            // this.setState({ conversations })
            for (let i = 0; i < conversations.length; i++) {
                for (let j = 0; j < clickedChats.length; j++) {
                    if (clickedChats && clickedChats[j]._id == conversations[i]._id) {
                        newClickedChats.push(conversations[i])
                    }
                }



            }
            this.setState({ clickedChats: newClickedChats })

        }

        if (prevState.isMobileLayout != this.state.isMobileLayout) {
            this.setState({ openChatList: false, clickedChats: [], openedChats: [], activeMobChatList: false, chat: {} })

        }


        if (prevProps[0].location.search != this.props[0].location.search) {
            this.setState({
                loading: true
            }, () => {
                this.get();

            })

        }
        if (prevProps.selectedGroup != this.props.selectedGroup || prevState.patientGroupAlias != this.state.patientGroupAlias) {
            if ((!this.state.conversation || (this.state.conversation && this.state.conversation.length == 0))) {

                this.setState({ openChatList: false, clickedChats: [], openedChats: [] }, () => {

                })
            }
        }


        if (prevProps.selectedClinic != this.props.selectedClinic || prevProps.selectedGroup != this.props.selectedGroup) {
            this.get()

        }
        if (prevProps.selectedGroup && prevProps.selectedGroup != this.props.selectedGroup && this.props.socket) {
            this.props.socket.emit('groupDoctors', { group: this.props.selectedGroup });

            this.setState({ conversation: null })
        }


        if (prevState.mainVideoElement != this.state.mainVideoElement) {
            if (this.state.mainVideoElement == 'local-video') {

                for (var uuid in this.peers) {
                    //if (this.initiatorPeers[uuid]) {
                    //   this.changeBandwidth(uuid, 1);
                    //} else {
                    if (this.peerStatus[uuid])
                        this.props.socket.emit('changeBandwidth', { uuid: uuid, conversation: this.state.conversation._id, bandwidth: 100 });
                    //}
                }

                return;
            }


            //if (this.initiatorPeers[this.state.mainVideoElement]) {
            //    this.changeBandwidth(this.state.mainVideoElement, 'unlimited');
            //} else {
            if (this.peerStatus[this.state.mainVideoElement])
                this.props.socket.emit('changeBandwidth', { uuid: this.state.mainVideoElement, conversation: this.state.conversation._id, bandwidth: 'unlimited' });
            //}

            for (var uuid in this.peers) {
                if (uuid == this.state.mainVideoElement) {
                    continue;
                }

                //if (this.initiatorPeers[uuid]) {
                //   this.changeBandwidth(uuid, 1);
                //} else {
                if (this.peerStatus[uuid])
                    this.props.socket.emit('changeBandwidth', { uuid: uuid, conversation: this.state.conversation._id, bandwidth: 100 });
                //}
            }

        }



    }


    updateSort = (field, type) => {
        if (this.state.useState) {
            let state = this.state.params;
            state.sortField = field;
            state.sortType = type;
            this.setState({
                params: state
            })
        } else {
            this.props[0].history.push(generateSearchLinkMultiple(this.props[0].location.search, {}, [{ name: 'sortField', value: field }, { name: 'sortType', value: type }], false));

        }
    }

    updateParams = (name, value, restart = false) => {
        if (this.state.useState) {
            if (restart) {
                let obj = {
                    entries: 10,
                    page: 0
                };
                obj[name] = value;
                this.setState({ params: obj });
            } else {
                let obj = this.state.params;
                obj[name] = value;
                this.setState({ params: obj })
            }
        } else {
            this.props[0].history.push(generateSearchLink(this.props[0].location.search, {}, name, value, restart));
        }
    }
    updateMultipleParams = (fields, restart = false) => {
        if (this.state.useState) {
            if (restart) {
                let obj = {
                    entries: 10,
                    page: 0
                };
                for (let i = 0; i < fields.length; i++) {
                    obj[fields[i].name] = fields[i].value;
                }
                this.setState({ params: obj });

            } else {
                let obj = this.state.params;
                for (let i = 0; i < fields.length; i++) {
                    obj[fields[i].name] = fields[i].value;
                }
                this.setState({ params: obj })
            }
        } else {
            this.props[0].history.push(generateSearchLinkMultiple(this.props[0].location.search, {}, fields, restart));
        }
    }



    openConversation = (conversation, callback) => {

        fetch(API_ENDPOINT + '/notification/seen/update-type', {
            method: 'POST',
            headers: {
                'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage.getItem('authToken')}` : null,
                'content-type': 'application/json'
            },
            body: JSON.stringify({ type: conversation._id, user: this.props.uData ? this.props.uData._id : null })
        }).then(res => res.json()).then((result) => {
            this.props.verifyUser()
        })

        let message = ''
        if (this.state.draftMessages && this.state.draftMessages.length && this.state.draftMessages.filter(item => item.conversation == conversation._id).length) {
            let draftMessages = this.state.draftMessages;
            for (let i = 0; i < draftMessages.length; i++) {
                if (draftMessages[i].conversation == conversation._id && draftMessages[i].message) {
                    message = draftMessages[i].message;
                    draftMessages.splice(i, 1);
                }
            }
            this.setState({ draftMessages })
        }
        this.setState({
            conversation: conversation,
            journalNote: false,
            referral: null,
            journalNoteIntelligentSections: null,
            loseUploadedFiles: null,
            message


        }, () => {
            if (this.state.conversation && this.state.conversation._id && this.state.openedJournalNotes.indexOf(this.state.conversation._id) != -1) {
                this.findHealthCareFormular()
            }


            if (this.state.conversation && this.state.conversation._id && this.state.openedReferralPreview.indexOf(this.state.conversation._id) != -1) {
                this.setState({ referral: this.state.conversation.referral })
            }

        });
        let conversations = this.state.conversations;
        for (let i = 0; i < conversations.length; i++) {
            if (conversations[i]._id == conversation) {
                conversations[i]._newMessage = null;
            }
        }
        this.setState({
            conversations
        }, callback)
        let page = this.state.page;
        page[data.conversation] = 0;
        this.setState({
            page
        }, () => {
            this.props.socket.emit('messages', { conversation: this.state.conversation._id, page: this.state.page })
        })


    }
    sendHealthCare = (item) => {
        let chat = this.state.chat;
        let conversation = this.state.healthCareModal;
        if (conversation) {
            chat[conversation].message = item.name;
            chat[conversation].healthCareId = item._id;
            this.setState({ chat, healthCareModal: null }, () => this.sendMessage(conversation))

        }
    }
    checkUploadedFiles = (item) => {
        if (this.state.document || this.state.image || this.state.video) {
            this.setState({ loseUploadedFiles: item })
        } else {
            if (this.state.message) {
                let obj = {
                    conversation: this.state.conversation._id,
                    message: this.state.message
                };
                let draftMessages = this.state.draftMessages;
                draftMessages.push(obj);
                this.setState({
                    draftMessages,
                    message: ''
                }, () => {
                    this.openConversation(item)
                })

            } else {
                this.openConversation(item)

            }
        }

    }

    updateJournalNote = (journalNote) => {
        // let changesExist = false;
        // if (JSON.stringify(journalNote) != JSON.stringify(this.state.conversation.journalNote)) {
        //     changesExist = true;
        // }

        // let obj = {
        //     conversation: this.state.conversation._id,
        //     journalNote: journalNote
        // }
        // if (changesExist)
        //     this.setState({ journalNoteForSave: obj })

        // let obj = {
        //     conversation: this.state.conversation._id,
        //     journalNote: journalNote
        // }


        // this.props.socket.emit('updateJournalNote', obj);
        this.setState({ journalNoteForSave: journalNote })
    }
    updateJournalNoteOnExit = (callback) => {
        let obj = {
            conversation: this.state.conversation._id,
            journalNote: this.state.journalNoteForSave
        }

        // this.props.socket.emit('updateJournalNote', obj);

        if (this.state.openedJournalNotes && this.state.openedJournalNotes.length && this.state.openedJournalNotes.filter(item => item == this.state.conversation._id).length) {
            let openedJournalNotesObjects = this.state.openedJournalNotesObjects;
            if (openedJournalNotesObjects && openedJournalNotesObjects.length) {
                for (let i = 0; i < openedJournalNotesObjects.length; i++) {
                    if (openedJournalNotesObjects[i].conversation == this.state.conversation._id) {
                        openedJournalNotesObjects.splice(i, 1);
                    }
                }
            }

            openedJournalNotesObjects.push(obj);
            this.setState({ openedJournalNotesObjects })
        }

        if (callback) {
            callback()
        }
    }
    sendMessage = (conversation) => {
        if (!this.state.chat || (this.state.chat && !this.state.chat[conversation])) {
            return;
        }
        if (this.state.chat && this.state.chat[conversation] && (!this.state.chat[conversation].message || this.state.chat[conversation].message.trim() == '') && !this.state.chat[conversation].image && !this.state.chat[conversation].video && !this.state.chat[conversation].document) {
            return;
        }




        // if (!this.state.message.trim() && !this.state.document && !this.state.image && !this.state.video) {
        //     return;
        // }


        // if (!this.state.conversation) {
        //     return;
        // }

        let obj = { conversation: conversation, message: this.state.chat[conversation].message };
        // if (this.state.document) {
        //     obj.document = this.state.document;
        // }
        if (this.state.chat[conversation].image) {
            obj.image = this.state.chat[conversation].image;
        }
        if (this.state.chat[conversation].video) {
            obj.video = this.state.chat[conversation].video;
        }
        if (this.state.chat[conversation].document) {
            obj.document = this.state.chat[conversation].document;
        }
        // if (this.state.video) {
        //     obj.video = this.state.video;
        // }
        if (this.state.chat[conversation].healthCareId) {
            obj.healthCareId = this.state.chat[conversation].healthCareId;
        }

        this.props.socket.emit('sendMessage', obj);
        let chat = this.state.chat;
        chat[conversation].message = '';
        chat[conversation].image = null;
        chat[conversation].document = null;
        chat[conversation].video = null;
        chat[conversation].healthCareId = null;
        this.setState({
            chat
        })
    }

    setTranslationLanguage = (language, conversation) => {
        this.props.socket.emit('setTranslationLanguage', { conversation: conversation, language: language });
    }

    emitUserTyping = (conversation) => {
        if (!conversation || !conversation?._id) {
            return;
        }

        if (this.typingTimer) {
            clearTimeout(this.typingTimer);
        }
        let userTyping = this.state.userTyping;
        if (!userTyping) {
            userTyping = {}
        };

        if (userTyping && userTyping[conversation._id] && userTyping[conversation._id][this.state.chatType] && Math.floor(new Date().getTime() / 1000) - userTyping[conversation._id][this.state.chatType].time < 400) {
            userTyping[conversation._id][this.state.chatType].time = Math.floor(new Date().getTime() / 1000)
            this.setState({ userTyping }, () => {
                this.typingTimer = setTimeout(() => {
                    if (conversation && conversation?._id) {
                        this.props.socket.emit('userEndTyping2', { conversation: conversation?._id, chatType: this.state.chatType })
                        let userTypeUpdate = { ...this.state.userTyping };
                        delete userTypeUpdate[conversation._id][this.state.chatType]
                        this.setState({ userTyping: userTypeUpdate })
                    }
                }, 500);
            })
        } else {
            let obj = {
                conversation: conversation?._id,
                chatType: this.state.chatType,
                patients: []
            }
            let users = {};
            if (conversation?.users?.length) {
                for (let i = 0; i < conversation.users.length; i++) {
                    if (conversation.users[i]?.uid) {
                        users[conversation.users[i].uid] = conversation.users[i].name;
                        if (conversation.users[i]?.patientInThisGroup) {
                            obj.patients.push(conversation.users[i].uid)
                        }
                    }

                }
            }
            if (conversation?.patientId && obj.patients.indexOf(conversation.patientId) === -1) {
                obj.patients.push(conversation.patientId);
            }
            obj.users = users;
            this.props.socket.emit('userTyping2', obj)

            if (conversation?._id && this.props.uData._id && this.state.chatType) {
                if (!userTyping[conversation._id]) userTyping[conversation._id] = {};
                if (!userTyping[conversation._id][this.state.chatType]) userTyping[conversation._id][this.state.chatType] = {};
                userTyping[conversation._id][this.state.chatType].time = Math.floor(new Date().getTime() / 1000);
                this.setState({ userTyping }, () => {
                    this.typingTimer = setTimeout(() => {
                        if (conversation && conversation?._id) {
                            this.props.socket.emit('userEndTyping2', { conversation: conversation?._id, chatType: this.state.chatType })
                            let userTypeUpdate = { ...this.state.userTyping };
                            delete userTypeUpdate[conversation._id][this.state.chatType]
                            this.setState({ userTyping: userTypeUpdate })
                        }
                    }, 500);
                })
            }



        }

        // if (!conversation) {
        //     return;
        // }
        // if (this.typingTimer) {
        //     clearTimeout(this.typingTimer);
        // }

        // this.typingTimer = setTimeout(() => {
        //     this.props.socket.emit('userEndTyping', { conversation: conversation })
        // }, 500);

        // this.props.socket.emit('userTyping', { conversation: conversation })
    }


    close = (conversation) => {
        this.props.socket.emit('closeConversation', { conversation: conversation });
        this.setState({ conversation: null })
    }
    openCase = (conversation) => {

        this.props.socket.emit('openConversation', { conversation: conversation._id });

        if (conversation.referral && conversation.referral._id) {
            this.setState({
                loading: true
            }, () => {
                fetch(API_ENDPOINT + '/referrals/open/' + conversation.referral._id, {
                    method: 'POST',
                    headers: {
                        'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage.getItem('authToken')}` : null,
                        'content-type': 'application/json'
                    }
                }).then(res => res.json()).then((result) => {


                    this.get();
                    this.setState({
                        loading: false
                    })
                })
            })
        }
    }
    closeCase = (conversation) => {

        this.props.socket.emit('closeConversation', { conversation: conversation._id });

        if (conversation.referral && conversation.referral._id) {
            this.setState({
                loading: true
            }, () => {
                fetch(API_ENDPOINT + '/referrals/close/' + conversation.referral._id, {
                    method: 'POST',
                    headers: {
                        'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage.getItem('authToken')}` : null,
                        'content-type': 'application/json'
                    }
                }).then(res => res.json()).then((result) => {


                    this.get();
                    this.setState({
                        loading: false
                    })
                })
            })
        }
        this.setState({ conversation: null, referral: null })

    }
    open = (conversation) => {
        this.props.socket.emit('openConversation', { conversation: conversation });
    }

    search = () => {
        this.props.socket.emit('conversations', { search: this.state.search });
    }



    uploadDocument = (e, returnUrl = false) => new Promise((resolve, reject) => {
        let input = e.target;
        if (input.files && input.files[0]) {

            let fsize = input.files[0].size;
            let file = Math.round((fsize / 1024))
            this.setState({
                uploadTimestampDoc: Math.floor(new Date().getTime() / 1000)
            })
            if (file > 5120) {
                this.setState({ error: "File size limit has been reached! (max 5 MB)".translate(this.props.lang) })
            } else {
                let conversationId = this.state.uploadDocumentChat ? this.state.uploadDocumentChat : this.state.uploadVideoChat ? this.state.uploadVideoChat : this.state.uploadImageChat ? this.state.uploadImageChat : null;


                if (!returnUrl) this.setState({
                    _documentUploading: conversationId
                })

                let formData = new FormData();
                formData.append('file', input.files[0]);
                formData.append('group', this.props.selectedGroup)
                formData.append('fileType', 'clinicChat')

                fetch(API_ENDPOINT + '/chat/upload/document', {
                    method: 'POST',
                    headers: {
                        Accept: 'application/json',
                        'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage.getItem('authToken') ? localStorage.getItem("authToken") : localStorage.getItem('guestToken')}` : null,

                    },
                    body: formData
                }).then(parseJSONUpload).then((img) => {
                    if (returnUrl) {
                        if (img) return resolve(img);
                        return reject({ error: 'Error while uploading document!' })
                    }
                    if (img?.error) {
                        this.setState({ error: img.error, _documentUploading: null })
                    } else {
                        this.setState({
                            document: img
                        })
                        if (this.state._documentUploading != conversationId) {
                            this.setState({ succesUpload: 1 })
                        }
                        let chat = this.state.chat;
                        if (chat && conversationId && chat[conversationId]) {
                            chat[conversationId].document = img;
                            this.setState({ chat: chat }, () => {
                                this.sendMessage(conversationId)

                            })
                        }
                        this.setState({ conversation: this.state._documentUploading }, () => {
                            this.setState({
                                _documentUploading: null
                            })
                        })

                        return resolve(true);
                    }


                });
            }
        }
    })




    uploadImage = (e, returnUrl = false) => new Promise((resolve, reject) => {
        let input = e?.target;
        if (input?.files && input?.files[0]) {

            let fsize = input.files[0].size;
            let file = Math.round((fsize / 1024))
            this.setState({
                uploadTimestamp: Math.floor(new Date().getTime() / 1000)
            })
            if (file > 10240) {
                this.setState({ error: "File size limit has been reached! (max 10 MB)".translate(this.props.lang) })
            } else {
                let conversationId = this.state.uploadImageChat ? this.state.uploadImageChat : this.state.uploadDocumentChat ? this.state.uploadDocumentChat : this.state.uploadVideoChat ? this.state.uploadVideoChat : null;

                if (!returnUrl) this.setState({
                    _imageUploading: conversationId
                })

                let formData = new FormData();
                formData.append('file', input.files[0]);
                formData.append('group', this.props.selectedGroup)
                formData.append('fileType', 'clinicChat')

                fetch(API_ENDPOINT + '/chat/upload/image', {
                    method: 'POST',
                    headers: {
                        Accept: 'application/json',
                        'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage.getItem('authToken') ? localStorage.getItem("authToken") : localStorage.getItem('guestToken')}` : null,

                    },
                    body: formData
                }).then(parseTextUpload).then((img) => {
                    if (returnUrl) {
                        if (img) return resolve(img);
                        return reject({ error: 'Error while uploading image!' });
                    }
                    if (img?.error) {
                        this.setState({ error: img.error, _imageUploading: null })
                    } else {
                        this.setState({
                            image: img
                        })
                        if (this.state._imageUploading != conversationId) {
                            this.setState({ succesUpload: 2 })
                        }
                        let chat = this.state.chat;
                        if (chat && conversationId && chat[conversationId]) {
                            chat[conversationId].image = img;
                            this.setState({ chat: chat }, () => {
                                this.sendMessage(conversationId)

                            })
                        }
                        this.setState({ conversation: this.state._imageUploading }, () => {
                            this.setState({
                                _imageUploading: null,

                            })
                        })

                        return resolve(true);
                    }

                });
            }
        }
    })


    uploadVideo = (e, returnUrl = false) => new Promise((resolve, reject) => {
        let input = e.target;
        if (input.files && input.files[0]) {

            let fsize = input.files[0].size;
            let file = Math.round((fsize / 1024))
            this.setState({
                uploadTimestampVideo: Math.floor(new Date().getTime() / 1000)
            })
            if (file > 524288) {
                this.setState({ error: "File size limit has been reached! (max 512 MB)".translate(this.props.lang) })
            } else {
                let conversationId = this.state.uploadVideoChat ? this.state.uploadVideoChat : this.state.uploadImageChat ? this.state.uploadImageChat : this.state.uploadDocumentChat ? this.state.uploadDocumentChat : null;

                if (!returnUrl) this.setState({
                    _videoUploading: conversationId

                })

                let formData = new FormData();
                formData.append('file', input.files[0]);
                formData.append('group', this.props.selectedGroup)
                formData.append('fileType', 'clinicChat')
                //video progres
                this.props.handleUploadFileProgress('Processing file...'.translate(this.props.lang));


                axios.post(API_ENDPOINT + '/chat/upload/video', formData, {
                    headers: {
                        Accept: 'application/json',
                        'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage.getItem('authToken')}` : null,
                    },
                    onUploadProgress: (progressEvent) => {
                        this.props.handleUploadFileProgress('Uploading file...'.translate(this.props.lang), Math.round((progressEvent.loaded * 100) / progressEvent.total));

                    }
                }).then((response) => {
                    if (returnUrl) {
                        if (response?.data) return resolve(response.data);
                        return reject({ error: 'Error while uploading video!' })
                    }
                    this.setState({
                        video: response.data
                    })
                    if (this.state._videoUploading != conversationId) {
                        this.setState({ succesUpload: 3 })
                    }
                    let chat = this.state.chat;
                    if (chat && conversationId && chat[conversationId]) {
                        chat[conversationId].video = response.data;
                        this.setState({ chat: chat }, () => {
                            this.sendMessage(conversationId)

                        })
                    }
                    this.setState({ conversation: this.state._videoUploading }, () => {
                        this.setState({
                            _videoUploading: null
                        })
                    });

                    return resolve(true);
                }).catch((error) => {
                    console.log('error', error)
                    if (error) {
                        let error = 'Wrong file type!';
                        if (error.response && error.response.status === 500) {
                            if (error.response && error.response.data) {
                                error = error.response.data;
                            }
                        }
                        this.setState({ error: error, _videoUploading: null })
                    }

                })

            }
        }
    })


    searchMembers = (conversation) => {
        if (!this.state.chat[conversation].searchMembersText || !this.state.chat[conversation].searchMembersText.trim()) {
            let chat = this.state.chat;
            chat[conversation].searchUsers = [];
            this.setState({ chat });
            return;
        }


        this.props.socket.emit('searchMembers', { group: this.props.selectedGroup, conversation: conversation, search: this.state.chat[conversation].searchMembersText });

    }


    addMember = (user, conversation) => {
        let doctorAsPatient = false;
        if (conversation && conversation.referral) {
            if (conversation.referral && conversation.referral.patient && conversation.referral.patient.socialSecurityNumber == user.socialSecurityNumber)
                doctorAsPatient = true
        }
        if (conversation && conversation.control && conversation.socialSecurityNumber && conversation.socialSecurityNumber == user.socialSecurityNumber) {
            doctorAsPatient = true
        }


        if (user.userLevel == 1 || user.userLevel == 2 || doctorAsPatient) {
            if (this.state.chat && this.state.chat[conversation._id] && this.state.chat[conversation._id].numberOfPatients == 0) {
                this.props.socket.emit('addMember', { conversation: conversation._id, member: user._id });
                let chat = this.state.chat;
                chat[conversation._id].errorTooManyPatients = false;
                this.setState({ chat })
            } else {
                let chat = this.state.chat;
                chat[conversation._id].errorTooManyPatients = 'There can be only one patient in the conversation';
                this.setState({ chat })
            }

        } else {
            this.props.socket.emit('addMember', { conversation: conversation._id, member: user._id });
        }
    }

    removeMember = (user, conversation) => {
        this.props.socket.emit('removeMember', { conversation: conversation._id, member: user });
        let chat = this.state.chat;
        chat[conversation._id].errorTooManyPatients = false;
        this.setState({ chat })
    }

    raiseHand = () => {
        this.setState({
            hand: true
        }, () => {
            this.props.socket.emit('raiseHand', { conversation: this.state.conversation._id });

        })
    }




    handDown = () => {
        this.setState({
            hand: false
        }, () => {
            this.props.socket.emit('handDown', { conversation: this.state.conversation._id });
        })
    }


    attachSinkId = (element, deviceId) => {

        if (typeof element.sinkId !== 'undefined') {

            element.setSinkId(deviceId).then(() => {
                console.log(`Success, audio output device attached: ${deviceId}`);
            })
                .catch(error => {
                    let errorMessage = error;
                    if (error.name === 'SecurityError') {
                        errorMessage = `You need to use HTTPS for selecting audio output device: ${error}`;
                    }
                    console.error(errorMessage);
                    // Jump back to first output device in the list as it's the default.
                    //this.setState({ selectedAudioOutputIndex: 0 })
                });

        } else {
            console.warn('Browser does not support output device selection.');
        }


    }


    createConversation = (item) => {
        let data = {};
        data.group = this.props.selectedGroup;
        data.chatOneOnOne = true;
        data.user = item;

        this.props.socket.emit('createNewConversation', data);

    }



    generateLayout = () => {
        let mainVideoElement = 'local-video';

        if (this.state.videoElements.length) {
            if (this.state.activeClient && !this.state.shareOn) {
                let uuid = null;

                for (var key in this.state.remoteUids) {
                    if (this.state.remoteUids[key] && this.state.remoteUids[key] == this.state.activeClient) {
                        uuid = key;
                        break;
                    }
                }

                if (uuid) {
                    let index = this.state.videoElements.indexOf(uuid);
                    mainVideoElement = this.state.videoElements[index];
                } else {
                    mainVideoElement = 'local-video';
                }


            } else {
                mainVideoElement = this.state.videoElements[0];
            }
        }



        this.setState({
            mainVideoElement,
        }, () => {
            for (var uuid in this.peerStreams) {
                let remoteVideo = this.remoteVideo[uuid]
                if (remoteVideo && !remoteVideo.srcObject) {
                    remoteVideo.srcObject = this.peerStreams[uuid];
                    remoteVideo.autoplay = true;
                    remoteVideo.playsInline = true;
                    remoteVideo.playsinline = true;
                    remoteVideo.muted = false;
                    if (this.state.audioOutputDevice)
                        this.attachSinkId(remoteVideo, this.state.audioOutputDevice);
                }

                let localVideo = this.localVideo;
                if (localVideo && !localVideo.srcObject) {

                    if (this.state.shareOn)
                        localVideo.srcObject = this.screenStream;
                    else
                        localVideo.srcObject = this.audioVideoStream;

                    localVideo.autoplay = true;
                    localVideo.playsInline = true;
                    localVideo.muted = true;

                }



            }
        });


        /*if (this.state.videoElements.length > 1) {
            for (var uuid in this.peers) {

                if (mainVideoElement != uuid) {
                    this.changeBandwidth(uuid, 125);
                } else {
                    this.changeBandwidth(uuid, 'unlimited');
                }
            }
        }*/


    }


    showCopiedLink = () => {
        this.setState({
            linkCopied: true
        })
        setTimeout(() => {
            this.setState({
                linkCopied: false
            })
        }, 1000)
    }


    renderOtherVideoElements = (renderMainVideoElement = true, renderOtherElements = true) => {
        let numberOfColumns = this.state.videoElements.length;
        let numberOfRows = 1;
        let numberOfColumnsOnScreen = typeof window != 'undefined' && window.innerWidth < 768 ? 6 : 14;

        let videoElementHeight = (this.callArea && this.callArea.offsetWidth) / numberOfColumnsOnScreen;
        let videoElementWidth = videoElementHeight;
        let otherVideosContainerHeight = (100 * videoElementHeight * numberOfRows) / (this.callArea && this.callArea.offsetHeight - 80);


        let localVideoPosition = null;

        if (this.localVideo && this.callArea) {
            localVideoPosition = getObjectFitSize(true, this.callArea.offsetWidth, (this.callArea.offsetHeight - 80 - videoElementHeight), this.localVideo.videoWidth, this.localVideo.videoHeight);
        }


        return (
            <>
                {
                    this.state.videoElements.map((item, idx) => {
                        if (!renderMainVideoElement && this.state.mainVideoElement == item) {
                            return null;
                        }
                        if (!renderOtherElements && this.state.mainVideoElement != item) {
                            return null;
                        }

                        let user = this.state.conversation.users.filter(member => member.uid == this.state.remoteUids[item] && this.state.remoteUids[item]);
                        if (user.length) {
                            user = user[0];
                        } else {
                            user = null;
                        }


                        let videoPosition = null;

                        if (this.remoteVideo[item] && this.callArea) {
                            videoPosition = getObjectFitSize(true, this.callArea.offsetWidth, (this.callArea.offsetHeight - 80 - videoElementHeight), this.remoteVideo[item].videoWidth, this.remoteVideo[item].videoHeight);
                        }


                        return (
                            <div key={item} style={
                                this.state.speaking &&
                                    this.state.speaking[this.state.remoteUids[item]] &&
                                    this.state.activeClient != this.state.remoteUids[item] ?
                                    { opacity: this.state.fullScreen ? this.state.mainVideoElement == item ? 1 : 0 : 1, border: this.state.mainVideoElement == item ? '4px solid transparent' : '4px solid #60BEBB', width: this.state.mainVideoElement == item ? this.state.fullScreen ? '100vw' : '100%' : `${videoElementWidth}px`, height: this.state.mainVideoElement == item ? this.state.fullScreen ? '100vh' : `${100 - otherVideosContainerHeight}%` : `${videoElementHeight}px`, }
                                    :
                                    { opacity: this.state.fullScreen ? this.state.mainVideoElement == item ? 1 : 0 : 1, border: '4px solid transparent', width: this.state.mainVideoElement == item ? this.state.fullScreen ? '100vw' : '100%' : `${videoElementWidth}px`, height: this.state.mainVideoElement == item ? this.state.fullScreen ? '100vh' : `${100 - otherVideosContainerHeight}%` : `${videoElementHeight}px`, objectFit: this.state.mainVideoElement == item ? 'contain' : 'cover' }}

                                className={!this.state.cameraStatus[this.state.remoteUids[item]] ? `video-container camera-off ${this.state.mainVideoElement == item ? 'active-element' : ''}` : `video-container ${this.state.mainVideoElement == item ? 'active-element' : ''}`} onClick={() => this.setState({ activeClient: this.state.remoteUids[item] }, this.generateLayout)}>
                                <video /*controls={typeof window != 'undefined' && iosDevice() ? true : false}*/ style={{ objectFit: this.state.mainVideoElement == item ? 'contain' : 'cover' }} class="remote-video" ref={(node) => this.remoteVideo[item] = node}></video>
                                {user ?
                                    <div className="user-info"
                                    >
                                        <img src={user.profilePicture ? API_ENDPOINT + user.profilePicture : profilePicture} />
                                        {this.state.mainVideoElement == item ?
                                            <p style={{ display: 'flex', alignItems: 'center' }}>{user.bankIdVerified === true ? <Isvg src={secureIcon} style={{ width: 32, minWidth: 32 }} /> : ''} {user ? user.name : ''} {user.bankIdVerified === true ? <>({'BankID verified'.translate(this.props.lang)})</> : ''}</p>
                                            :
                                            <p onMouseEnter={(e) => {
                                                if (this.state.mainVideoElement == item) {
                                                    return;
                                                }

                                                this.setState({
                                                    memberTooltip: {
                                                        client: item,
                                                        top: e.clientY - 50,
                                                        left: getOffset(e.target).left - (e.target.offsetWidth / 2) + 20,
                                                        name: user ? user.name : '',

                                                    }
                                                })

                                            }}

                                                onMouseLeave={(e) => {
                                                    if (this.state.mainVideoElement == 'local-video') {
                                                        return;
                                                    }

                                                    this.setState({
                                                        memberTooltip: null
                                                    })

                                                }}
                                                style={{ display: 'flex', alignItems: 'center' }}
                                            >
                                                {
                                                    user.bankIdVerified === true ?
                                                        <Isvg src={secureIcon} style={{ width: 16, minWidth: 16 }} />
                                                        :
                                                        ''
                                                }
                                                {
                                                    user.name ?
                                                        (user.name + (user.bankIdVerified === true ? ('(' + 'BankID verified'.translate(this.props.lang) + ')') : '')).length > 7 ?
                                                            (user.name + (user.bankIdVerified === true ? ('(' + 'BankID verified'.translate(this.props.lang) + ')') : '')).substring(0, 7) + '...'
                                                            : (user.name + (user.bankIdVerified === true ? ('(' + 'BankID verified'.translate(this.props.lang) + ')') : ''))
                                                        : ''}

                                            </p>
                                        }
                                    </div>
                                    :
                                    null
                                }
                                {
                                    this.state.hands[this.state.remoteUids[item]] ?

                                        <div className="hand">
                                            <Player
                                                onEvent={(event) => {
                                                    if (event === "load" && this.player && this.player.current) this.player.current.play();
                                                }}
                                                ref={this.player} // set the ref to your class instance
                                                autoplay={true}
                                                loop={true}
                                                controls={true}
                                                src="/12738-wave.json"
                                                renderer={'svg'}
                                                style={this.state.mainVideoElement == item ? { height: "256px", width: "256px" } : { height: "48px", width: "48px" }}
                                            ></Player>
                                        </div>

                                        :

                                        null
                                }

                                {
                                    this.state._connecting[item] || this.state._checking[item] ?
                                        <div className="connecting" style={{ marginLeft: `-${(videoElementWidth - 20) / 2}px`, marginTop: `-${(videoElementWidth - 20) / 2}px` }}>
                                            <Player
                                                autoplay={true}
                                                loop={true}
                                                src="/lf30_editor_l5cxzdyf.json"
                                                renderer={'svg'}
                                                style={{ height: `${videoElementWidth - 20}px`, width: `${videoElementWidth - 20}px` }}
                                            ></Player>
                                        </div>
                                        :
                                        null
                                }

                                {
                                    !this.state.micStatus[this.state.remoteUids[item]] ?
                                        <div className="microphone-muted" style={this.remoteVideo[item] && this.state.mainVideoElement == item ? { top: videoPosition.y + videoPosition.height - 40, left: videoPosition.x + videoPosition.width - 40 } : {}}>
                                            <Isvg src={micMutedIcon} />
                                        </div>
                                        :
                                        null
                                }

                                {
                                    this.remoteVideo[item] && this.state.mainVideoElement == item && this.state.cameraStatus[this.state.remoteUids[item]] && this.props.uData ?
                                        <button className="take-picture" style={{ top: this.state.fullScreen ? '95vh' : videoPosition.y + videoPosition.height - 40, left: videoPosition.x + 5 }} onClick={() => {

                                            var canvas = document.createElement('canvas');
                                            canvas.width = this.remoteVideo[item].videoWidth;
                                            canvas.height = this.remoteVideo[item].videoHeight;
                                            document.body.appendChild(canvas);

                                            var context = canvas.getContext('2d');
                                            context.drawImage(this.remoteVideo[item], 0, 0, this.remoteVideo[item].videoWidth, this.remoteVideo[item].videoHeight);

                                            //var data = canvas.toDataURL('image/png');
                                            //console.log(data);

                                            canvas.toBlob((blob) => {
                                                let file = new File([blob], "screenshot.png", { type: "image/png" })
                                                this.setState({
                                                    takePhotoModal: {
                                                        message: '',
                                                        previewImage: canvas.toDataURL('image/png'),
                                                        image: file
                                                    }
                                                })
                                                canvas.remove();

                                            }, 'image/png');



                                        }}>
                                            <Isvg src={photoIcon} /><div className="chat-icons-tooltip">{'Take photo'.translate(this.props.lang)}</div>
                                        </button>
                                        :

                                        null
                                }

                                {
                                    this.remoteVideo[item] && this.state.mainVideoElement == item ?
                                        <div className="timer" style={{ top: this.state.fullScreen ? '90vh' : (videoPosition.y + videoPosition.height - 40), right: videoPosition.x + 10 }}>{this.state.timer}</div>
                                        :
                                        null

                                }

                            </div>
                        )
                    })
                }

                {(renderOtherElements && this.state.mainVideoElement != 'local-video') || (renderMainVideoElement && this.state.mainVideoElement == 'local-video') ?

                    <div className={this.state.cameraOff || this.state.shareOn ? `video-container camera-off ${this.state.mainVideoElement == 'local-video' ? 'active-element' : ''}` : `video-container ${this.state.mainVideoElement == 'local-video' ? 'active-element' : ''}`}
                        style={{ opacity: this.state.fullScreen ? this.state.mainVideoElement == 'local-video' ? 1 : 0 : 1, border: '4px solid transparent', width: this.state.mainVideoElement == 'local-video' ? this.state.fullScreen ? '100vw' : '100%' : `${videoElementWidth}px`, height: this.state.mainVideoElement == 'local-video' ? this.state.fullScreen ? '100vh' : `${100 - otherVideosContainerHeight}%` : `${videoElementHeight}px` }} key="local-video" onClick={() => this.setState({ activeClient: 'local-video' }, this.generateLayout)}>
                        <video controls={false} style={{ objectFit: this.state.mainVideoElement == 'local-video' ? 'contain' : 'cover' }} class="remote-video" ref={(node) => this.localVideo = node}></video>

                        <div className="user-info"

                        >
                            <img src={this.props.uData && this.props.uData.profilePicture ? API_ENDPOINT + this.props.uData.profilePicture : profilePicture} />
                            {this.state.mainVideoElement == 'local-video' ?

                                <p>{this.props.uData ? this.props.uData.name : this.state.guestName}</p>
                                :
                                <p onMouseEnter={(e) => {
                                    if (this.state.mainVideoElement == 'local-video') {
                                        return;
                                    }

                                    this.setState({
                                        memberTooltip: {
                                            client: 'local-video',
                                            top: e.clientY - 50,
                                            left: getOffset(e.target).left - (e.target.offsetWidth / 2) + 20,
                                            name: this.props.uData ? this.props.uData.name : this.state.guestName
                                        }
                                    })

                                }}

                                    onMouseLeave={(e) => {
                                        if (this.state.mainVideoElement == 'local-video') {
                                            return;
                                        }

                                        this.setState({
                                            memberTooltip: null
                                        })

                                    }}
                                >{this.props.uData ? this.props.uData.name && this.props.uData.name.length > 7 ? this.props.uData.name.substring(0, 7) + '...' : this.props.uData.name : this.state.guestName && this.state.guestName.length > 7 ? this.state.guestName.substring(0, 7) + '...' : this.state.guestName}</p>
                            }
                        </div>
                        {
                            this.state.micOff ?
                                <div className="microphone-muted" style={this.localVideo && this.state.mainVideoElement == 'local-video' ? { top: localVideoPosition.y + localVideoPosition.height - 40, left: localVideoPosition.x + localVideoPosition.width - 40 } : {}}>
                                    <Isvg src={micMutedIcon} />
                                </div>
                                :
                                null
                        }

                        {
                            this.localVideo && this.state.mainVideoElement == 'local-video' ?
                                <div className="timer" style={{ top: this.state.fullScreen ? '90vh' : localVideoPosition.y + localVideoPosition.height - 40, right: localVideoPosition.x + 10 }}>{this.state.timer}</div>
                                :
                                null

                        }



                    </div>
                    :
                    null

                }

            </>
        )
    }


    getDevices = () => {
        if (navigator) {
            navigator?.mediaDevices?.enumerateDevices?.().then?.((devices) => {

                let videoInput = [];
                let audioInput = [];
                let audioOutput = [];

                for (let i = 0; i < devices.length; i++) {
                    console.log(devices[i]);
                    if (devices[i].kind == "audioinput") {
                        audioInput.push(devices[i]);
                    } else if (devices[i].kind == "audiooutput") {
                        audioOutput.push(devices[i]);
                    } else if (devices[i].kind == "videoinput") {
                        videoInput.push(devices[i]);
                    }
                }



                this.setState({
                    videoInput,
                    audioOutput,
                    audioInput
                })
            })
        }
    }



    checkQuestionCondition(questionId) {
        let question = this.getQuestion(questionId);
        if (!question) {
            return;
        }

        if (!question.conditions) {
            return true;
        }

        if (!question.conditions.length) {
            return true;
        }


        for (let i = 0; i < question.conditions.length; i++) {
            if (!question.conditions[i].conditionQuestion) {
                continue;
            }

            let conditionQuestion = this.getQuestion(question.conditions[i].conditionQuestion);
            if (!conditionQuestion) {
                continue;
            }

            if (conditionQuestion.type == 1) {
                if (this.state.questionaryAnswers[conditionQuestion.id] == question.conditions[i].conditionAnswer) {
                    return true;
                }
            } else if (conditionQuestion.type == 2) {
                if (this.state.questionaryAnswers[conditionQuestion.id] && (this.state.questionaryAnswers[conditionQuestion.id].indexOf(question.conditions[i].conditionAnswer) !== -1 || this.state.questionaryAnswers[conditionQuestion.id].filter(ans => ans.match(question.conditions[i].conditionAnswer)).length)) {
                    return true;
                }

            } else if (conditionQuestion.type == 6) {
                if (this.state.questionaryAnswers[conditionQuestion.id] && this.state.questionaryAnswers[conditionQuestion.id][0] && this.state.questionaryAnswers[conditionQuestion.id][1]) {
                    return true;
                }


            } else if (conditionQuestion.type == 7) {
                if (this.state.questionaryAnswers[conditionQuestion.id] && this.state.questionaryAnswers[conditionQuestion.id][0] && this.state.questionaryAnswers[conditionQuestion.id][1] && this.state.questionaryAnswers[conditionQuestion.id][2] && this.state.questionaryAnswers[conditionQuestion.id][3]) {
                    return true;
                }


            } else if (conditionQuestion.type == 3 || conditionQuestion.type == 5 || conditionQuestion.type == 8 || conditionQuestion.type == 9) {
                if (this.state.questionaryAnswers[conditionQuestion.id]) {
                    return true;
                }

            } else if (conditionQuestion.type == 10) {
                return true;
            } else if (conditionQuestion.type == 12) {
                if (this.state.questionaryAnswers[conditionQuestion.id] && this.state.questionaryAnswers[conditionQuestion.id][0] && this.state.questionaryAnswers[conditionQuestion.id][1] && this.state.questionaryAnswers[conditionQuestion.id][2]) {
                    return true;
                }


            } else if (conditionQuestion.type == 13) {
                if (this.state.questionaryAnswers[conditionQuestion.id] && this.state.questionaryAnswers[conditionQuestion.id][0]) {
                    return true;
                }


            }
        }
        return false;
    }
    checkSectionAnswers = (sectionIdx) => {
        let lastQuestionType = -1;
        let i = sectionIdx;
        for (let j = 0; j < this.state.sections[i].questions.length; j++) {
            if (this.checkQuestionCondition(this.state.sections[i].questions[j].id) && (this.state.sections[i].questions[j].type == 1 || this.state.sections[i].questions[j].type == 2 || this.state.sections[i].questions[j].type == 3 || this.state.sections[i].questions[j].type == 5 || this.state.sections[i].questions[j].type == 6 || this.state.sections[i].questions[j].type == 7 || this.state.sections[i].questions[j].type == 8 || this.state.sections[i].questions[j].type == 9 || this.state.sections[i].questions[j].type == 10 || this.state.sections[i].questions[j].type == 12 || this.state.sections[i].questions[j].type == 13)) {
                lastQuestionType = this.state.sections[i].questions[j].type;

                if (this.state.sections[i].questions[j].type == 10) {
                    continue;
                }


                if (!this.state.questionaryAnswers[this.state.sections[i].questions[j].id] /*&& !this.state.skippedQuestions[this.state.sections[i].questions[j].id] */) {
                    return false;
                }
            }

            if (this.checkQuestionCondition(this.state.sections[i].questions[j].id) && this.state.sections[i].questions[j].type == 4) {
                lastQuestionType = this.state.sections[i].questions[j].type;
            }
        }



        return lastQuestionType != 4 ? true : false;
    }
    getQuestion = (id) => {
        for (let i = 0; i < this.state.sections.length; i++) {
            if (this.state.sections[i].questions) {
                for (let j = 0; j < this.state.sections[i].questions.length; j++) {
                    if (this.state.sections[i].questions[j].id == id) {
                        return this.state.sections[i].questions[j];
                    }
                }
            }
        }


        return {};
    }
    checkSectionCondition = (sectionIdx) => {
        let section = this.state.sections[sectionIdx];
        if (!section) {
            return;
        }

        if (!section.conditions) {
            if (sectionIdx == 0) {
                return true;
            } else {
                if (this.checkSectionAnswers(sectionIdx - 1)) {
                    return true;
                } else {

                    return false;

                }
            }

            return true;
        }
        if (!section.conditions.length) {
            if (sectionIdx == 0) {
                return true;
            } else {
                if (this.checkSectionAnswers(sectionIdx - 1)) {
                    return true;
                } else {

                    return false;
                }
            }
        }

        for (let i = 0; i < section.conditions.length; i++) {
            if (!section.conditions[i].conditionQuestion) {
                continue;
            }

            let question = this.getQuestion(section.conditions[i].conditionQuestion);
            if (!question) {
                continue;
            }

            if (question.type == 1) {
                if (this.state.questionaryAnswers[question.id] == section.conditions[i].conditionAnswer) {
                    return true;
                }
            } else if (question.type == 2) {
                if (this.state.questionaryAnswers[question.id] && this.state.questionaryAnswers[question.id].indexOf(section.conditions[i].conditionAnswer) !== -1) {

                    return true;
                }

            } else if (question.type == 3) {
                if (this.state.questionaryAnswers[question.id]) {
                    return true;
                }

            } else if (question.type == 5) {
                if (this.state.questionaryAnswers[question.id]) {
                    return true;
                }

            } else if (question.type == 6) {
                if (this.state.questionaryAnswers[question.id]) {
                    return true;
                }

            } else if (question.type == 7) {
                if (this.state.questionaryAnswers[question.id]) {
                    return true;
                }
            } else if (question.type == 8) {
                if (this.state.questionaryAnswers[question.id]) {
                    return true;
                }
            } else if (question.type == 9) {
                if (this.state.questionaryAnswers[question.id]) {
                    return true;
                }

            } else if (question.type == 10) {
                return true;
            } else if (question.type == 12) {
                if (this.state.questionaryAnswers[question.id]) {
                    return true;
                }

            } else if (question.type == 13) {
                if (this.state.questionaryAnswers[question.id]) {
                    return true;
                }

            }
        }

        return false;
    }



    getAnswer = (id) => {
        for (let i = 0; i < this.state.sections.length; i++) {
            if (this.state.sections[i].questions) {
                for (let j = 0; j < this.state.sections[i].questions.length; j++) {
                    if (this.state.sections[i].questions[j].answers) {
                        for (let k = 0; k < this.state.sections[i].questions[j].answers.length; k++) {
                            if (this.state.sections[i].questions[j].answers[k].id == id) {
                                return this.state.sections[i].questions[j].answers[k];
                            }
                        }
                    }
                }
            }
        }


        return {};
    }





    generateReferral = () => {




        let selectedClinic = {};
        let selectedGroup = this.state.activeGroup ? this.state.activeGroup : {};

        let patient = {
            referralNumber: '111',
            name: this.props.uData.userData && this.props.uData.userData.name,
            socialSecurityNumber: this.props.uData.socialSecurityNumber,
            street: this.props.uData.street,
            zip: this.props.uData.zip,
            city: this.props.uData.city,
            phone: this.props.uData.phone,
            email: this.props.uData.email
        }



        let clinicData = {
            ...selectedClinic,
            logo: selectedGroup ? selectedGroup.logo : null
        }


        let referralData = '';


        if (!this.state.activeQuestionary.intelligentReferral) {
            for (let i = 0; i < this.state.sections.length; i++) {
                if (this.checkSectionCondition(i)) {
                    if (this.state.sections[i].displayOnReferral) {
                        if (i > 0) {
                            referralData += '<br/><br/>'
                        }
                        referralData += `<p><strong>${this.state.sections[i].name}</strong></p>\n`;
                    }
                    referralData += '<p>\n';
                    for (let j = 0; j < this.state.sections[i].questions.length; j++) {
                        if (this.checkQuestionCondition(this.state.sections[i].questions[j].id) && (this.state.sections[i].questions[j].type == 10 || this.state.questionaryAnswers[this.state.sections[i].questions[j].id])) {
                            if (this.state.sections[i].questions[j].displayOnReferral || true) {
                                if (this.state.sections[i].questions[j].type == 1) {
                                    if (this.getAnswer(this.state.questionaryAnswers[this.state.sections[i].questions[j].id]).name)
                                        referralData += this.state.sections[i].questions[j].name + ' - <strong>' + this.getAnswer(this.state.questionaryAnswers[this.state.sections[i].questions[j].id]).name + '</strong> <br/>';
                                } else if (this.state.sections[i].questions[j].type == 2) {
                                    if (this.state.questionaryAnswers[this.state.sections[i].questions[j].id]) {

                                        let arr = [];

                                        //for (let k = 0; k < this.state.questionaryAnswers[this.state.sections[i].questions[j].id].length; k++) {
                                        for (let k = 0; k < this.state.sections[i].questions[j].answers.length; k++) {
                                            let check = -1;
                                            for (let l = 0; l < this.state.questionaryAnswers[this.state.sections[i].questions[j].id].length; l++) {
                                                if (this.state.sections[i].questions[j].answers[k] && this.state.questionaryAnswers[this.state.sections[i].questions[j].id][l] == this.state.sections[i].questions[j].answers[k].id) {
                                                    check = l;
                                                    break;
                                                }
                                            }
                                            if (check != -1) {
                                                //if (this.state.sections[i].questions[j].answerPrefix) {
                                                /*if (this.state.questionaryAnswers[this.state.sections[i].questions[j].id][k].indexOf('__user-input:') == 0){
                                                    arr.push(this.state.questionaryAnswers[this.state.sections[i].questions[j].id][k].replace('__user-input:', ''));
                                                }else{*/
                                                if (this.getAnswer(this.state.questionaryAnswers[this.state.sections[i].questions[j].id][check]).name)
                                                    arr.push(this.getAnswer(this.state.questionaryAnswers[this.state.sections[i].questions[j].id][check]).name);
                                                // }
                                                /* } else {
                                                     if (this.getAnswer(this.state.questionaryAnswers[this.state.sections[i].questions[j].id][check]).text)
                                                         referralData += this.getAnswer(this.state.questionaryAnswers[this.state.sections[i].questions[j].id][check]).text + ' ';
                                                 }*/
                                            }
                                        }

                                        if (this.state.sections[i].questions[j].userInput && this.indexOfIndexOf(this.state.questionaryAnswers[this.state.sections[i].questions[j].id], '__user-input:') !== -1) {
                                            arr.push(this.state.questionaryAnswers[this.state.sections[i].questions[j].id][this.indexOfIndexOf(this.state.questionaryAnswers[this.state.sections[i].questions[j].id], '__user-input:')].replace('__user-input:', ''))
                                        }


                                        //if (arr.length && this.state.sections[i].questions[j].answerPrefix) {
                                        if (arr.length > 1) {
                                            let last = arr.pop();
                                            referralData += this.state.sections[i].questions[j].name + ' - <strong>' + arr.join(', ') + ' och ' + last + '. </strong><br/>';
                                        } else {
                                            referralData += this.state.sections[i].questions[j].name + ' - <strong>' + arr.join(', ') + '.</strong><br/> ';
                                        }
                                        //}
                                    }
                                } else if (this.state.sections[i].questions[j].type == 3) {
                                    referralData += this.state.sections[i].questions[j].name + ' - <strong>' + this.state.questionaryAnswers[this.state.sections[i].questions[j].id] + '.</strong><br/> ';
                                } else if (this.state.sections[i].questions[j].type == 5) {
                                    //referralData += this.state.questionaryAnswers[this.state.sections[i].questions[j].id] + '. ';
                                    let newArr = JSON.parse(JSON.stringify(this.state.questionaryAnswers[this.state.sections[i].questions[j].id]));
                                    let arr = [];
                                    for (let k = 0; k < newArr.length; k++) {
                                        if (newArr[k]) {
                                            arr.push(newArr[k]);
                                        }
                                    }
                                    if (arr.length > 1) {
                                        let last = arr.pop();
                                        referralData += arr.join(', ') + ' och ' + last + '. ';
                                    } else {
                                        referralData += arr.join(', ') + '. ';
                                    }

                                } else if (this.state.sections[i].questions[j].type == 6) {

                                    if (this.state.questionaryAnswers[this.state.sections[i].questions[j].id])
                                        referralData += 'Patientens längd: ' + this.state.questionaryAnswers[this.state.sections[i].questions[j].id][1] + ', ' + 'vikt: ' + this.state.questionaryAnswers[this.state.sections[i].questions[j].id][0] + ', ' + 'BMI: ' + parseFloat(this.state.questionaryAnswers[this.state.sections[i].questions[j].id] && this.state.questionaryAnswers[this.state.sections[i].questions[j].id].length ? (this.state.questionaryAnswers[this.state.sections[i].questions[j].id][0] / ((this.state.questionaryAnswers[this.state.sections[i].questions[j].id][1] / 100) * (this.state.questionaryAnswers[this.state.sections[i].questions[j].id][1] / 100))) : 0).toFixed(1) + '. ';
                                } else if (this.state.sections[i].questions[j].type == 7) {
                                    referralData += `<br/><br/>
                                Frikortsnummer: ${this.state.questionaryAnswers[this.state.sections[i].questions[j].id] ? this.state.questionaryAnswers[this.state.sections[i].questions[j].id][0] : ''}<br/>
                                Giltig från: ${this.state.questionaryAnswers[this.state.sections[i].questions[j].id] ? this.state.questionaryAnswers[this.state.sections[i].questions[j].id][1] : ''}<br/>
                                Giltig till: ${this.state.questionaryAnswers[this.state.sections[i].questions[j].id] ? this.state.questionaryAnswers[this.state.sections[i].questions[j].id][2] : ''}<br/>
                                Utfärdad av: ${this.state.questionaryAnswers[this.state.sections[i].questions[j].id] ? this.state.questionaryAnswers[this.state.sections[i].questions[j].id][3] : ''}<br/><br/>
                                `
                                } else if (this.state.sections[i].questions[j].type == 8) {
                                    referralData += `<a href='${API_ENDPOINT + this.state.questionaryAnswers[this.state.sections[i].questions[j].id]}' target="_blank"> ${this.state.sections[i].questions[j].name}</a><br/>` + ' ';
                                } else if (this.state.sections[i].questions[j].type == 9) {
                                    referralData += `<a href='${API_ENDPOINT + this.state.questionaryAnswers[this.state.sections[i].questions[j].id].file}' target="_blank">File/photo</a><br/>` + ' ';
                                } else if (this.state.sections[i].questions[j].type == 10) {
                                    if (this.state.sections[i].questions[j].userInput) {
                                        referralData += "<br/><br/>"
                                    } else {
                                        referralData += "<br/>"
                                    }

                                } else if (this.state.sections[i].questions[j].type == 12) {
                                    referralData += 'Patientens blodtryck uppmätt till: <br/>';
                                    referralData += this.state.questionaryAnswers[this.state.sections[i].questions[j].id] && this.state.questionaryAnswers[this.state.sections[i].questions[j].id][0] ? this.state.questionaryAnswers[this.state.sections[i].questions[j].id][0] + '/' : '';
                                    referralData += this.state.questionaryAnswers[this.state.sections[i].questions[j].id] && this.state.questionaryAnswers[this.state.sections[i].questions[j].id][1] ? this.state.questionaryAnswers[this.state.sections[i].questions[j].id][1] + '<br/>' : '';
                                    referralData += this.state.questionaryAnswers[this.state.sections[i].questions[j].id] && this.state.questionaryAnswers[this.state.sections[i].questions[j].id][2] ? 'Puls: ' + this.state.questionaryAnswers[this.state.sections[i].questions[j].id][2] + '<br/>' : '';
                                } else if (this.state.sections[i].questions[j].type == 13) {
                                    referralData += 'Patientens VAS-skalvärde är:';
                                    referralData += this.state.questionaryAnswers[this.state.sections[i].questions[j].id] && this.state.questionaryAnswers[this.state.sections[i].questions[j].id][0] ? this.state.questionaryAnswers[this.state.sections[i].questions[j].id][0] + '<br/>' : '';
                                }
                            }
                        }
                    }

                    referralData += '</p>\n';
                }
            }
        } else {
            for (let i = 0; i < this.state.sections.length; i++) {

                if (this.checkSectionCondition(i)) {
                    if (this.state.sections[i].displayOnReferral) {
                        referralData += `<p><strong>Patient svar</strong></p>\n\n`
                        if (i > 0) {
                            referralData += '<br/><br/>'
                        }
                        referralData += `<p><strong>${this.state.sections[i].name}</strong></p>\n`;
                    }
                    referralData += '<p>\n';
                    for (let j = 0; j < this.state.sections[i].questions.length; j++) {
                        // if (this.checkQuestionCondition(this.state.sections[i].questions[j].id) && this.state.questionaryAnswers[this.state.sections[i].questions[j].id]) {
                        if (this.checkQuestionCondition(this.state.sections[i].questions[j].id) && (this.state.sections[i].questions[j].type == 10 || this.state.questionaryAnswers[this.state.sections[i].questions[j].id])) {

                            if (this.state.sections[i].questions[j].displayOnReferral) {
                                if (this.state.sections[i].questions[j].type == 1) {
                                    if (this.getAnswer(this.state.questionaryAnswers[this.state.sections[i].questions[j].id]).text)
                                        referralData += this.getAnswer(this.state.questionaryAnswers[this.state.sections[i].questions[j].id]).text + ' ';
                                } else if (this.state.sections[i].questions[j].type == 2) {
                                    if (this.state.questionaryAnswers[this.state.sections[i].questions[j].id]) {

                                        let arr = [];

                                        //for (let k = 0; k < this.state.questionaryAnswers[this.state.sections[i].questions[j].id].length; k++) {
                                        for (let k = 0; k < this.state.sections[i].questions[j].answers.length; k++) {
                                            let check = -1;
                                            for (let l = 0; l < this.state.questionaryAnswers[this.state.sections[i].questions[j].id].length; l++) {
                                                if (this.state.sections[i].questions[j].answers[k] && this.state.questionaryAnswers[this.state.sections[i].questions[j].id][l] == this.state.sections[i].questions[j].answers[k].id) {
                                                    check = l;
                                                    break;
                                                }
                                            }
                                            if (check != -1) {
                                                if (this.state.sections[i].questions[j].answerPrefix) {
                                                    /*if (this.state.questionaryAnswers[this.state.sections[i].questions[j].id][k].indexOf('__user-input:') == 0){
                                                        arr.push(this.state.questionaryAnswers[this.state.sections[i].questions[j].id][k].replace('__user-input:', ''));
                                                    }else{*/
                                                    if (this.getAnswer(this.state.questionaryAnswers[this.state.sections[i].questions[j].id][check]).text)
                                                        arr.push(this.getAnswer(this.state.questionaryAnswers[this.state.sections[i].questions[j].id][check]).text);
                                                    // }
                                                } else {
                                                    if (this.getAnswer(this.state.questionaryAnswers[this.state.sections[i].questions[j].id][check]).text)
                                                        referralData += this.getAnswer(this.state.questionaryAnswers[this.state.sections[i].questions[j].id][check]).text + ' ';
                                                }
                                            }
                                        }

                                        if (this.state.sections[i].questions[j].userInput && this.indexOfIndexOf(this.state.questionaryAnswers[this.state.sections[i].questions[j].id], '__user-input:') !== -1) {
                                            arr.push(this.state.questionaryAnswers[this.state.sections[i].questions[j].id][this.indexOfIndexOf(this.state.questionaryAnswers[this.state.sections[i].questions[j].id], '__user-input:')].replace('__user-input:', ''))
                                        }


                                        if (arr.length && this.state.sections[i].quesitons[j].answerPrefix) {
                                            if (arr.length > 1) {
                                                let last = arr.pop();
                                                referralData += this.state.sections[i].questions[j].answerPrefix + ' ' + arr.join(', ') + ' och '.translate(this.props.lang) + last + '. ';
                                            } else {
                                                referralData += this.state.sections[i].questions[j].answerPrefix + ' ' + arr.join(', ') + '. ';
                                            }
                                        }
                                    }
                                } else if (this.state.sections[i].questions[j].type == 3) {
                                    referralData += this.state.questionaryAnswers[this.state.sections[i].questions[j].id] + '. ';
                                } else if (this.state.sections[i].questions[j].type == 5) {
                                    //referralData += this.state.questionaryAnswers[this.state.sections[i].questions[j].id] + '. ';
                                    let newArr = JSON.parse(JSON.stringify(this.state.questionaryAnswers[this.state.sections[i].questions[j].id]));
                                    let arr = [];
                                    for (let k = 0; k < newArr.length; k++) {
                                        if (newArr[k]) {
                                            arr.push(newArr[k]);
                                        }
                                    }
                                    if (arr.length > 1) {
                                        let last = arr.pop();
                                        referralData += arr.join(', ') + ' och '.translate(this.props.lang) + last + '. ';
                                    } else {
                                        referralData += arr.join(', ') + '. ';
                                    }

                                } else if (this.state.sections[i].questions[j].type == 6) {
                                    if (this.state.questionaryAnswers[this.state.sections[i].questions[j].id])
                                        referralData += 'Patientens längd: ' + this.state.questionaryAnswers[this.state.sections[i].questions[j].id][1] + ', ' + 'vikt: ' + this.state.questionaryAnswers[this.state.sections[i].questions[j].id][0] + ', ' + 'BMI: ' + parseFloat(this.state.questionaryAnswers[this.state.sections[i].questions[j].id] && this.state.questionaryAnswers[this.state.sections[i].questions[j].id].length ? (this.state.questionaryAnswers[this.state.sections[i].questions[j].id][0] / ((this.state.questionaryAnswers[this.state.sections[i].questions[j].id][1] / 100) * (this.state.questionaryAnswers[this.state.sections[i].questions[j].id][1] / 100))) : 0).toFixed(1) + '. ';
                                } else if (this.state.sections[i].questions[j].type == 7) {
                                    referralData += `<br/><br/>
                                    Frikortsnummer: ${this.state.questionaryAnswers[this.state.sections[i].questions[j].id] ? this.state.questionaryAnswers[this.state.sections[i].questions[j].id][0] : ''}<br/>
                                Giltig från: ${this.state.questionaryAnswers[this.state.sections[i].questions[j].id] ? this.state.questionaryAnswers[this.state.sections[i].questions[j].id][1] : ''}<br/>
                                Giltig till: ${this.state.questionaryAnswers[this.state.sections[i].questions[j].id] ? this.state.questionaryAnswers[this.state.sections[i].questions[j].id][2] : ''}<br/>
                                Utfärdad av: ${this.state.questionaryAnswers[this.state.sections[i].questions[j].id] ? this.state.questionaryAnswers[this.state.sections[i].questions[j].id][3] : ''}<br/><br/>
                                `
                                } else if (this.state.sections[i].questions[j].type == 8) {
                                    referralData += `<a href='${API_ENDPOINT + this.state.questionaryAnswers[this.state.sections[i].questions[j].id]}' target="_blank"> ${this.state.sections[i].questions[j].name}</a><br/>` + ' ';
                                } else if (this.state.sections[i].questions[j].type == 9) {
                                    referralData += `<a href='${API_ENDPOINT + this.state.questionaryAnswers[this.state.sections[i].questions[j].id].file}' target="_blank">Bild bifogad</a><br/>` + ' ';
                                } else if (this.state.sections[i].questions[j].type == 10) {
                                    if (this.state.sections[i].questions[j].userInput) {
                                        referralData += "<br/><br/><br/><br/>"
                                    } else {
                                        referralData += "<br/><br/>"
                                    }

                                } else if (this.state.sections[i].questions[j].type == 12) {
                                    referralData += 'Patientens blodtryck uppmätt till: <br/>';
                                    referralData += this.state.questionaryAnswers[this.state.sections[i].questions[j].id] && this.state.questionaryAnswers[this.state.sections[i].questions[j].id][0] ? this.state.questionaryAnswers[this.state.sections[i].questions[j].id][0] + '/' : '';
                                    referralData += this.state.questionaryAnswers[this.state.sections[i].questions[j].id] && this.state.questionaryAnswers[this.state.sections[i].questions[j].id][1] ? this.state.questionaryAnswers[this.state.sections[i].questions[j].id][1] + '<br/>' : '';
                                    referralData += this.state.questionaryAnswers[this.state.sections[i].questions[j].id] && this.state.questionaryAnswers[this.state.sections[i].questions[j].id][2] ? 'Puls: ' + this.state.questionaryAnswers[this.state.sections[i].questions[j].id][2] + '<br/>' : '';
                                } else if (this.state.sections[i].questions[j].type == 13) {
                                    referralData += 'Patientens VAS-skalvärde är:';
                                    referralData += this.state.questionaryAnswers[this.state.sections[i].questions[j].id] && this.state.questionaryAnswers[this.state.sections[i].questions[j].id][0] ? this.state.questionaryAnswers[this.state.sections[i].questions[j].id][0] + '<br/>' : '';
                                }
                            }
                        }
                    }

                    referralData += '</p>\n';
                }
            }

        }

        let html = `
        <div style="padding: 0 30px;">
        <div style="display: flex; justify-content: space-between; align-items: center;">
        ${clinicData.logo ? `<p><img style="float: left; max-height: 80px; object-fit: contain; max-width: 220px;" src="${API_ENDPOINT + clinicData.logo}"  /></p>` : ''}
        <p style="text-align: right; float: right;"><strong>Svarsdatum:</strong> ${moment().format(this.props.dateFormat)}</p>
        </div>
<div style="clear: both; margin-top: 10px;">
<!--
    <div>${clinicData.name ? clinicData.name : ''}</div>
    <div>${clinicData.street ? clinicData.street : ''}, ${clinicData.zip ? clinicData.zip : ''} ${clinicData.city ? clinicData.city : ''}</div>
    <div>${clinicData.phone ? clinicData.phone : ''} ${clinicData.email ? clinicData.email : ''}</div>
    -->
</div>
<hr />

<div>
    <p><strong>Personuppgifter</strong></p>
</div>
<div>
    <p>${patient.socialSecurityNumber ? patient.socialSecurityNumber : ''}<br />${patient.name ? patient.name : ''}<br />${patient.street ? patient.street : ''}<br />${patient.zip ? patient.zip : ''}
        ${patient.city ? patient.city : ''}<br />${patient.phone ? patient.phone : ''}<br />${patient.email ? patient.email : ''}</p>
</div>


        </div>
        `

        // ${ this.state.referralSections && this.state.referralSections.header ? this.state.referralSections.header : '' }
        // <hr />
        // ${ referralData }
        // ${ this.state.referralSections && this.state.referralSections.body ? this.state.referralSections.body + '<br/>' : '' }

        // <hr />



        // ${ this.state.referralSections && this.state.referralSections.footer ? '<hr/>' + this.state.referralSections.footer : '' }
        return html;

    }


    sendHealthCareQuestionary = () => {


        let validationQuestionary = false;
        if (this.state.activeQuestionary && this.state.activeQuestionary.items && !this.state.activeQuestionary.intelligentReferral) {
            for (let i = 0; i < this.state.activeQuestionary.items.length; i++) {
                if (!this.state.questionaryAnswers[this.state.activeQuestionary.items[i].id]) {
                    validationQuestionary = true;
                }
            }
        } else if (this.state.activeQuestionary && this.state.activeQuestionary.items && this.state.activeQuestionary.intelligentReferral) {
            validationQuestionary = this.state.validationIntelligentQuestionary;
        }
        this.setState({ validationQuestionary })

        if (!validationQuestionary) {
            if (typeof window != 'undefined' && localStorage.groupAlias) {
                fetch(API_ENDPOINT + '/clinic/group/information', {
                    method: 'POST',
                    headers: {
                        'content-type': 'application/json'
                    },
                    body: JSON.stringify({ alias: localStorage.groupAlias })
                }).then(res => res.json()).then((result) => {
                    if (!result.error) {
                        this.setState({
                            activeGroup: result
                        }, () => {
                            let obj = {
                                messageId: this.state.questionaryMessageActive,
                                answers: this.state.questionaryAnswers,
                                healthCareId: this.state.questionaryModal,
                                referralSections: this.state.referralSections,
                                groupAlias: typeof window != 'undefined' && localStorage.groupAlias ? localStorage.groupAlias : null,
                                healthCareContent: this.generateReferral(),

                            }
                            console.log(obj)

                            this.setState({ loading: true }, () => {
                                fetch(API_ENDPOINT + '/data/general-health-care/referral/new', {
                                    method: 'POST',
                                    headers: {
                                        'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage.getItem('authToken')}` : null,
                                        'content-type': 'application/json'
                                    },
                                    body: JSON.stringify(obj)
                                }).then(res => res.json()).then((result) => {

                                    this.setState({ questionaryModal: null, settedRef: false, activeQuestionary: null, questionaryMessageActive: null, activeReferralContent: null, sections: null })
                                    this.props.socket.emit('emitNewMessage', { conversation: this.state.conversation._id })
                                })
                            })

                        })
                    }

                });
            }



        }

    }

    findHealthCareFormular = (conversation) => {
        let chat = this.state.chat;
        chat[conversation].journalNote = !chat[conversation].journalNote;
        this.setState({ chat }, () => {
            // if (this.state.journalNote) {
            //     this.addToJorunalNotes()
            // } else {
            //     this.removeFromJorunalNotes()
            // }

            if (this.state.chat[conversation].journalNote) {
                if (this.state.conversation && this.state.openedJournalNotesObjects && this.state.openedJournalNotesObjects.length && this.state.openedJournalNotesObjects.filter(item => item.conversation == this.state.conversation._id).length) {
                    let journalNoteObj;
                    for (let i = 0; i < this.state.openedJournalNotesObjects.length; i++) {
                        if (this.state.openedJournalNotesObjects[i].conversation == this.state.conversation._id) {
                            journalNoteObj = this.state.openedJournalNotesObjects[i].journalNote;
                            this.setState({ journalNoteObj })
                        }
                    }
                } else if (this.state.conversation && this.state.conversation.journalNote && this.state.conversation.referral && !this.state.conversation.referral.intelligentReferral) {
                    this.setState({
                        journalNoteObj: {
                            status: this.state.conversation.journalNote.status,
                            anamnes: this.state.conversation.journalNote.anamnes,
                            bedomning: this.state.conversation.journalNote.bedomning,
                            atgard: this.state.conversation.journalNote.atgard,
                            kontaktorsak: this.state.conversation.journalNote.kontaktorsak

                        }
                    }, () => {
                        this.generatePdf()
                    })
                } else if (this.state.conversation && this.state.conversation.referral && !this.state.conversation.referral.intelligentReferral) {
                    this.setState({
                        journalNoteObj: {
                            status: '',
                            anamnes: '',
                            bedomning: '',
                            atgard: '',
                            kontaktorsak: ''

                        }
                    }, () => {
                        this.generatePdf()
                    })
                }
                else if (this.state.conversation && this.state.conversation.journalNote && !this.state.conversation.referral) {
                    this.setState({
                        journalNoteObj: {
                            status: this.state.conversation.journalNote.status,
                            anamnes: this.state.conversation.journalNote.anamnes,
                            bedomning: this.state.conversation.journalNote.bedomning,
                            atgard: this.state.conversation.journalNote.atgard,
                            kontaktorsak: this.state.conversation.journalNote.kontaktorsak

                        }
                    }, () => {
                        this.generatePdf()
                    })
                }

                if (this.state.conversation && this.state.conversation.referral && this.state.conversation.referral.intelligentReferral) {
                    if (this.state.conversation.journalNote) {
                        let arraySections = [];
                        let journalNote = this.state.conversation.journalNote;
                        let conversation = this.state.conversation;
                        Object.keys(journalNote).map(function (key, index) {
                            if (key != 'Kontaktorsak') {
                                let obj = {
                                    name: key,
                                    answer: journalNote[key]
                                }
                                arraySections.push(obj)
                            } else {

                                if (conversation && conversation.referral) {
                                    conversation.referral.questionaryName = journalNote[key];
                                }
                            }

                        });

                        let countOFSections = arraySections.length;
                        this.setState({ journalNoteIntelligentSections: arraySections, countOFSections, conversation }, () => {
                            if (this.state.countOFSections > 0) {
                                this.generateIntelligentPdf()
                            } else {
                                this.generatePdf()
                            }
                        })
                    } else {
                        fetch(API_ENDPOINT + '/data/personal-questionary/' + this.state.conversation.referral.questionaryId, {
                            method: 'GET',
                            headers: {
                                'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage.getItem('authToken')}` : null,
                                'content-type': 'application/json'
                            }
                        }).then(res => res.json()).then((result) => {

                            this.setState({
                                personalQuestionary: result,
                                sections: this.state.conversation && this.state.conversation.referral && this.state.conversation.referral.questionarySections,
                                questionaryAnswers: this.state.conversation && this.state.conversation.referral && this.state.conversation.referral.answers
                            }, () => {

                                let arraySections = [];
                                if (this.state.sections && this.state.questionaryAnswers) {
                                    for (let i = 0; i < this.state.sections.length; i++) {
                                        let referralData = '';
                                        let obj = {};
                                        obj.name = this.state.sections[i].name;
                                        let skip = false;
                                        if (obj.name.indexOf('Referral for ') != -1 && i == 0) {
                                            skip = true;
                                        }
                                        if (this.checkSectionCondition(i) && !skip) {

                                            referralData += '<p>\n';
                                            for (let j = 0; j < this.state.sections[i].questions.length; j++) {

                                                if (this.checkQuestionCondition(this.state.sections[i].questions[j].id) && this.state.questionaryAnswers[this.state.sections[i].questions[j].id]) {

                                                    // if (this.state.sections[i].questions[j].displayOnReferral) {
                                                    if (this.state.sections[i].questions[j].type == 1) {
                                                        if (this.getAnswer(this.state.questionaryAnswers[this.state.sections[i].questions[j].id]).text) {
                                                            referralData += this.getAnswer(this.state.questionaryAnswers[this.state.sections[i].questions[j].id]).text + ' ';
                                                        } else if (this.getQuestion(this.state.sections[i].questions[j].id).name && this.getAnswer(this.state.questionaryAnswers[this.state.sections[i].questions[j].id]).name) {
                                                            referralData += this.getQuestion(this.state.sections[i].questions[j].id).name + ' - ' + this.getAnswer(this.state.questionaryAnswers[this.state.sections[i].questions[j].id]).name + '\n';
                                                        }

                                                    } else if (this.state.sections[i].questions[j].type == 2) {
                                                        if (this.state.questionaryAnswers[this.state.sections[i].questions[j].id]) {

                                                            let arr = [];
                                                            let arr2 = [];

                                                            //for (let k = 0; k < this.state.questionaryAnswers[this.state.sections[i].questions[j].id].length; k++) {
                                                            for (let k = 0; k < this.state.sections[i].questions[j].answers.length; k++) {
                                                                let check = -1;
                                                                for (let l = 0; l < this.state.questionaryAnswers[this.state.sections[i].questions[j].id].length; l++) {
                                                                    if (this.state.sections[i].questions[j].answers[k] && this.state.questionaryAnswers[this.state.sections[i].questions[j].id][l] == this.state.sections[i].questions[j].answers[k].id) {
                                                                        check = l;
                                                                        break;
                                                                    }
                                                                }
                                                                if (check != -1) {
                                                                    if (this.state.sections[i].questions[j].answerPrefix) {
                                                                        /*if (this.state.questionaryAnswers[this.state.sections[i].questions[j].id][k].indexOf('__user-input:') == 0){
                                                                            arr.push(this.state.questionaryAnswers[this.state.sections[i].questions[j].id][k].replace('__user-input:', ''));
                                                                        }else{*/
                                                                        if (this.getAnswer(this.state.questionaryAnswers[this.state.sections[i].questions[j].id][check]).text) {
                                                                            arr.push(this.getAnswer(this.state.questionaryAnswers[this.state.sections[i].questions[j].id][check]).text);

                                                                        } else if (this.getQuestion(this.state.sections[i].questions[j].id).name && this.getAnswer(this.state.questionaryAnswers[this.state.sections[i].questions[j].id][check]).name) {
                                                                            arr2.push(this.getAnswer(this.state.questionaryAnswers[this.state.sections[i].questions[j].id][check]).name);
                                                                            console.log(this.getQuestion(this.state.sections[i].questions[j].id).name + ' - ' + this.getAnswer(this.state.questionaryAnswers[this.state.sections[i].questions[j].id][check]).name);
                                                                            // referralData += this.getQuestion(this.state.sections[i].questions[j].id).name + ' - ' + this.getAnswer(this.state.questionaryAnswers[this.state.sections[i].questions[j].id]).name + '\n';
                                                                        }
                                                                        // }
                                                                    } else {
                                                                        if (this.getAnswer(this.state.questionaryAnswers[this.state.sections[i].questions[j].id][check]).text) {
                                                                            referralData += this.getAnswer(this.state.questionaryAnswers[this.state.sections[i].questions[j].id][check]).text + ' ';
                                                                        } else if (this.getQuestion(this.state.sections[i].questions[j].id).name && this.getAnswer(this.state.questionaryAnswers[this.state.sections[i].questions[j].id][check]).name) {
                                                                            referralData += this.getQuestion(this.state.sections[i].questions[j].id).name + ' - ' + this.getAnswer(this.state.questionaryAnswers[this.state.sections[i].questions[j].id][check]).name + '\n'
                                                                        }

                                                                    }
                                                                }
                                                            }

                                                            if (this.state.sections[i].questions[j].userInput && this.indexOfIndexOf(this.state.questionaryAnswers[this.state.sections[i].questions[j].id], '__user-input:') !== -1) {
                                                                arr.push(this.state.questionaryAnswers[this.state.sections[i].questions[j].id][this.indexOfIndexOf(this.state.questionaryAnswers[this.state.sections[i].questions[j].id], '__user-input:')].replace('__user-input:', ''))
                                                            }


                                                            if (arr.length && this.state.sections && this.state.sections[i] && this.state.sections[i].questions && this.state.sections[i].questions[j] && this.state.sections[i].questions[j].answerPrefix) {
                                                                if (arr.length > 1) {
                                                                    let last = arr.pop();
                                                                    referralData += this.state.sections[i].questions[j].answerPrefix + ' ' + arr.join(', ') + ' och '.translate(this.props.lang) + last + '. ';
                                                                } else {
                                                                    referralData += this.state.sections[i].questions[j].answerPrefix + ' ' + arr.join(', ') + '. ';
                                                                }
                                                            }

                                                            if (arr2.length && this.state.sections && this.state.sections[i] && this.state.sections[i].questions && this.state.sections[i].questions[j] && this.state.sections[i].questions[j].answerPrefix) {
                                                                if (this.getQuestion(this.state.sections[i].questions[j].id).name) {
                                                                    referralData += this.getQuestion(this.state.sections[i].questions[j].id).name + ' - ';
                                                                }
                                                                if (arr2.length > 1) {
                                                                    let last = arr2.pop();
                                                                    referralData += this.state.sections[i].questions[j].answerPrefix + ' ' + arr2.join(', ') + ' och '.translate(this.props.lang) + last + '. ';
                                                                } else {
                                                                    referralData += this.state.sections[i].questions[j].answerPrefix + ' ' + arr2.join(', ') + '. ';
                                                                }
                                                            }
                                                        }
                                                    } else if (this.state.sections[i].questions[j].type == 3) {
                                                        referralData += this.state.questionaryAnswers[this.state.sections[i].questions[j].id] + '. ';
                                                    } else if (this.state.sections[i].questions[j].type == 5) {
                                                        //referralData += this.state.questionaryAnswers[this.state.sections[i].questions[j].id] + '. ';
                                                        let newArr = JSON.parse(JSON.stringify(this.state.questionaryAnswers[this.state.sections[i].questions[j].id]));
                                                        let arr = [];
                                                        for (let k = 0; k < newArr.length; k++) {
                                                            if (newArr[k]) {
                                                                arr.push(newArr[k]);
                                                            }
                                                        }
                                                        if (arr.length > 1) {
                                                            let last = arr.pop();
                                                            referralData += arr.join(', ') + ' och '.translate(this.props.lang) + last + '. ';
                                                        } else {
                                                            referralData += arr.join(', ') + '. ';
                                                        }

                                                    } else if (this.state.sections[i].questions[j].type == 6) {
                                                        if (this.state.questionaryAnswers[this.state.sections[i].questions[j].id])
                                                            referralData += 'Patientens längd: ' + this.state.questionaryAnswers[this.state.sections[i].questions[j].id][1] + ', ' + 'vikt: ' + this.state.questionaryAnswers[this.state.sections[i].questions[j].id][0] + ', ' + 'BMI: ' + parseFloat(this.state.questionaryAnswers[this.state.sections[i].questions[j].id] && this.state.questionaryAnswers[this.state.sections[i].questions[j].id].length ? (this.state.questionaryAnswers[this.state.sections[i].questions[j].id][0] / ((this.state.questionaryAnswers[this.state.sections[i].questions[j].id][1] / 100) * (this.state.questionaryAnswers[this.state.sections[i].questions[j].id][1] / 100))) : 0).toFixed(1) + '. ';
                                                    } else if (this.state.sections[i].questions[j].type == 7) {
                                                        referralData += `<br/><br/>
                                                            Frikortsnummer: ${this.state.questionaryAnswers[this.state.sections[i].questions[j].id] ? this.state.questionaryAnswers[this.state.sections[i].questions[j].id][0] : ''}<br/>
                                                        Giltig från: ${this.state.questionaryAnswers[this.state.sections[i].questions[j].id] ? this.state.questionaryAnswers[this.state.sections[i].questions[j].id][1] : ''}<br/>
                                                        Giltig till: ${this.state.questionaryAnswers[this.state.sections[i].questions[j].id] ? this.state.questionaryAnswers[this.state.sections[i].questions[j].id][2] : ''}<br/>
                                                        Utfärdad av: ${this.state.questionaryAnswers[this.state.sections[i].questions[j].id] ? this.state.questionaryAnswers[this.state.sections[i].questions[j].id][3] : ''}<br/><br/>
                                                        `
                                                    } else if (this.state.sections[i].questions[j].type == 8) {
                                                        referralData += `<a href='${API_ENDPOINT + this.state.questionaryAnswers[this.state.sections[i].questions[j].id]}' target="_blank"> ${this.state.sections[i].questions[j].name}</a><br/>` + ' ';
                                                    } else if (this.state.sections[i].questions[j].type == 9) {
                                                        referralData += `<a href='${API_ENDPOINT + this.state.questionaryAnswers[this.state.sections[i].questions[j].id].file}' target="_blank">Bild bifogad</a><br/>` + ' ';
                                                    } else if (this.state.sections[i].questions[j].type == 12) {
                                                        referralData += 'Patientens blodtryck uppmätt till: <br/>';
                                                        referralData += this.state.questionaryAnswers[this.state.sections[i].questions[j].id] && this.state.questionaryAnswers[this.state.sections[i].questions[j].id][0] ? this.state.questionaryAnswers[this.state.sections[i].questions[j].id][0] + '/' : '';
                                                        referralData += this.state.questionaryAnswers[this.state.sections[i].questions[j].id] && this.state.questionaryAnswers[this.state.sections[i].questions[j].id][1] ? this.state.questionaryAnswers[this.state.sections[i].questions[j].id][1] + '<br/>' : '';
                                                        referralData += this.state.questionaryAnswers[this.state.sections[i].questions[j].id] && this.state.questionaryAnswers[this.state.sections[i].questions[j].id][2] ? 'Puls: ' + this.state.questionaryAnswers[this.state.sections[i].questions[j].id][2] + '<br/>' : '';
                                                    } else if (this.state.sections[i].questions[j].type == 13) {
                                                        referralData += 'Patientens VAS-skalvärde är:';
                                                        referralData += this.state.questionaryAnswers[this.state.sections[i].questions[j].id] && this.state.questionaryAnswers[this.state.sections[i].questions[j].id][0] ? this.state.questionaryAnswers[this.state.sections[i].questions[j].id][0] + '<br/>' : '';
                                                    }
                                                    // }
                                                }
                                            }

                                            referralData += '</p>\n';
                                        }
                                        obj.answer = referralData;
                                        if (!skip) {
                                            arraySections.push(obj)
                                        }

                                    }


                                }
                                let countOFSections = arraySections.length;
                                let bedomningExists = false;
                                let atgardExists = false;
                                for (let i = 0; i < arraySections.length; i++) {
                                    if (arraySections[i] && arraySections[i].name && arraySections[i].name.toLowerCase() == 'Åtgärd'.toLowerCase()) {
                                        atgardExists = true;
                                    }
                                    if (arraySections[i] && arraySections[i].name && arraySections[i].name.toLowerCase() == 'Bedömning'.toLowerCase()) {
                                        bedomningExists = true;
                                    }
                                }
                                if (!bedomningExists) {
                                    let obj = {
                                        name: 'Bedömning',
                                        answer: ''
                                    }
                                    arraySections.push(obj);
                                }
                                if (!atgardExists) {
                                    let obj = {
                                        name: 'Åtgärd',
                                        answer: ''
                                    }
                                    arraySections.push(obj);
                                }
                                this.setState({ journalNoteIntelligentSections: arraySections, countOFSections }, () => {
                                    if (this.state.countOFSections > 0) {
                                        this.generateIntelligentPdf()
                                    } else {

                                        this.generatePdf()
                                    }
                                })
                            })
                        })
                    }


                }

            }
        })

    }
    generateIntelligentPdf = () => {
        let journalNote = {
            Kontaktorsak: this.state.conversation && this.state.conversation.referral && this.state.conversation.referral.questionaryName,
        }
        let textClipboard = `Kontaktorsak:\n`
        textClipboard += `${this.state.conversation && this.state.conversation.referral && this.state.conversation.referral.questionaryName}\n`;

        for (let i = 0; i < this.state.journalNoteIntelligentSections.length; i++) {
            if (this.state.journalNoteIntelligentSections[i].name) {
                textClipboard += `${this.state.journalNoteIntelligentSections[i].name}:`;
            }
            if (this.state.journalNoteIntelligentSections[i].answer) {
                textClipboard += `${striptags(this.state.journalNoteIntelligentSections[i].answer)}`;
            }
            if (this.state.journalNoteIntelligentSections[i].name) {
                journalNote[this.state.journalNoteIntelligentSections[i].name] = '';
                if (this.state.journalNoteIntelligentSections[i].answer) {
                    journalNote[this.state.journalNoteIntelligentSections[i].name] = striptags(this.state.journalNoteIntelligentSections[i].answer);
                }
            }


        }

        let html = `
                    <div>
                        <div>
                            <h1>Journal note</h1>
                        </div>
                        <div style='margin-bottom: 10px'>
                            <h5 style='font-size: 24px; font-weight: 600'>Kontaktorsak:</h5>
                            <h6 style='font-size: 20px; font-weight: 400'>${this.state.conversation && this.state.conversation.referral && this.state.conversation.referral.questionaryName}</h6>
                        </div>
                       
    `;

        for (let i = 0; i < this.state.journalNoteIntelligentSections.length; i++) {
            if (this.state.journalNoteIntelligentSections[i].name) {
                html += `<div style='margin-bottom: 10px'><h5 style='font-size: 24px; font-weight: 600'>${this.state.journalNoteIntelligentSections[i].name}:</h5>`;
            }
            if (this.state.journalNoteIntelligentSections[i].answer) {
                html += `<h6 style='font-size: 20px; font-weight: 400'>${striptags(this.state.journalNoteIntelligentSections[i].answer)}</h6></div>`;
            }

        }
        html += `</div>`;



        this.updateJournalNote(journalNote)

        this.setState({ htmlJournal: html, textClipboard })
    }
    generatePdf = () => {
        let textClipboard = `Kontaktorsak:
${this.state.journalNoteObj && this.state.journalNoteObj.kontaktorsak ? this.state.journalNoteObj.kontaktorsak : this.state.conversation && this.state.conversation.referral && this.state.conversation.referral.questionaryName ? this.state.conversation.referral.questionaryName : ''}
Status
${this.state.journalNoteObj && this.state.journalNoteObj.status}
Anamnes
${this.state.journalNoteObj && this.state.journalNoteObj.anamnes}
Bedömning
${this.state.journalNoteObj && this.state.journalNoteObj.bedomning}
Åtgärd
${this.state.journalNoteObj && this.state.journalNoteObj.atgard}
`;
        let html = `
            <div>
                <div>
                    <h1>Journal note</h1>
                </div>
                <div style='margin-bottom: 10px'>
                    <h5 style='font-size: 24px; font-weight: 600'>Kontaktorsak:</h5>
                    <h6 style='font-size: 20px; font-weight: 400'>${this.state.journalNoteObj && this.state.journalNoteObj.kontaktorsak ? this.state.journalNoteObj.kontaktorsak : this.state.conversation && this.state.conversation.referral && this.state.conversation.referral.questionaryName ? this.state.conversation.referral.questionaryName : ''}</h6>
                </div>
                <div style='margin-bottom: 10px'>
                    <h5 style='font-size: 24px; font-weight: 600'>Status:</h5>
                    <h6 style='font-size: 20px; font-weight: 400'>${this.state.journalNoteObj && this.state.journalNoteObj.status}</h6>
                </div>
                <div style='margin-bottom: 10px'>
                    <h5 style='font-size: 24px; font-weight: 600'>Anamnes:</h5>
                    <h6 style='font-size: 20px; font-weight: 400'>${this.state.journalNoteObj && this.state.journalNoteObj.anamnes}</h6>
                </div>
                <div style='margin-bottom: 10px'>
                    <h5 style='font-size: 24px; font-weight: 600'>Bedömning:</h5>
                    <h6 style='font-size: 20px; font-weight: 400'>${this.state.journalNoteObj && this.state.journalNoteObj.bedomning}</h6>
                </div>
                <div style='margin-bottom: 10px'>
                    <h5 style='font-size: 24px; font-weight: 600'>Åtgärd:</h5>
                    <h6 style='font-size: 20px; font-weight: 400'>${this.state.journalNoteObj && this.state.journalNoteObj.atgard}</h6>
                </div>               
            </div>
        `;

        let journalNote = {
            kontaktorsak: this.state.journalNoteObj && this.state.journalNoteObj.kontaktorsak ? this.state.journalNoteObj.kontaktorsak : this.state.conversation && this.state.conversation.referral && this.state.conversation.referral.questionaryName ? this.state.conversation.referral.questionaryName : '',
            status: this.state.journalNoteObj && this.state.journalNoteObj.status,
            anamnes: this.state.journalNoteObj && this.state.journalNoteObj.anamnes,
            bedomning: this.state.journalNoteObj && this.state.journalNoteObj.bedomning,
            atgard: this.state.journalNoteObj && this.state.journalNoteObj.atgard
        }
        if (this.state.conversation && this.state.conversation.referral && this.state.conversation.referral.intelligentReferral) {
            journalNote = {
                Kontaktorsak: this.state.journalNoteObj && this.state.journalNoteObj.kontaktorsak ? this.state.journalNoteObj.kontaktorsak : this.state.conversation && this.state.conversation.referral && this.state.conversation.referral.questionaryName ? this.state.conversation.referral.questionaryName : '',
                Status: this.state.journalNoteObj && this.state.journalNoteObj.status,
                Anamnes: this.state.journalNoteObj && this.state.journalNoteObj.anamnes,
                Bedömning: this.state.journalNoteObj && this.state.journalNoteObj.bedomning,
                Åtgärd: this.state.journalNoteObj && this.state.journalNoteObj.atgard
            }
        }
        // console.log(journalNote)

        this.updateJournalNote(journalNote)



        this.setState({ htmlJournal: html, textClipboard })
    }
    generatePdfName = () => {
        let name = 'journal_note';

        if (this.state.conversation && this.state.conversation.referral && this.state.conversation.referral.questionaryName) {
            name += `_${this.state.conversation.referral.questionaryName.toLowerCase()}`;
        }
        if (this.state.conversation && this.state.conversation.referral && this.state.conversation.referral.patient && this.state.conversation.referral.patient.name) {
            let patientName = this.state.conversation.referral.patient.name.toLowerCase().replace(' ', '_');
            name += `_${patientName}`;
        }
        name += '.pdf';

        return name;
    }


    leaveChat = (conversation) => {

        this.props.socket.emit('removeMember', { conversation: conversation._id, member: this.props.uData._id });
        this.setState({ conversation: null, referral: null })

    }
    deleteMessage = (message) => {


        this.props.socket.emit('deleteMessage', { id: message.message._id, conversation: message.conversation });

        this.setState({ deleteMessageModal: null })
    }
    deleteConversation = (conversation) => {
        this.props.socket.emit('deleteConversation', { conversation: conversation._id });
        this.setState({ conversation: null, referral: null })
    }


    openChat = (item) => {


        if (this.state.conversations.filter(conversation => conversation && !conversation.closed && (this.props.selectedGroup && conversation.groupIds && conversation.groupIds.indexOf(this.props.selectedGroup) !== -1) && conversation.chatOneOnOne && !conversation.patient && conversation.members && conversation.members.length && conversation.members.filter(conv => conv == item).length).length) {
            let conversation;
            let checkedConversations = [];
            for (let i = 0; i < this.state.conversations.length; i++) {
                if (this.state.conversations[i] && !this.state.conversations[i].closed && this.props.selectedGroup && this.state.conversations[i].groupIds && this.state.conversations[i].groupIds.indexOf(this.props.selectedGroup) !== -1 && this.state.conversations[i].chatOneOnOne && !this.state.conversations[i].patient && this.state.conversations[i].members && this.state.conversations[i].members.length && this.state.conversations[i].members.filter(conv => conv == item).length) {
                    let convObj = {
                        id: this.state.conversations[i]._id
                    }
                    if (this.state.conversations[i].tsModified) {
                        convObj.lastModified = this.state.conversations[i].tsModified;
                    } else if (this.state.conversations[i].tsCreated) {
                        convObj.lastModified = this.state.conversations[i].tsCreated;
                    }
                    if (convObj.lastModified) {
                        checkedConversations.push(convObj)
                    }

                    conversation = this.state.conversations[i];
                }
            }
            if (checkedConversations && checkedConversations.length) {
                checkedConversations.sort((a, b) => a.lastModified < b.lastModified ? 1 : -1);

            }

            if (checkedConversations && checkedConversations[0] && checkedConversations[0].id) {
                let check = this.state.conversations.filter(el => el._id == checkedConversations[0].id)
                if (check && check[0] && check[0]._id) {
                    conversation = check[0];
                }
            }


            this.props.registerClinicGroupLogs({
                type: 'openConversation',
                conversation: conversation._id
            })


            fetch(API_ENDPOINT + '/notification/seen/update-type', {
                method: 'POST',
                headers: {
                    'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage.getItem('authToken')}` : null,
                    'content-type': 'application/json'
                },
                body: JSON.stringify({ type: conversation._id, user: this.props.uData ? this.props.uData._id : null })
            }).then(res => res.json()).then((result) => {
                this.props.verifyUser()
            })


            let openedChats = this.state.openedChats;
            let clickedChats = this.state.clickedChats;
            if (this.state.activeMobChatList) {
                openedChats = [];
                clickedChats = [];
            }



            let chat = this.state.chat;

            if (openedChats.filter(chat => chat._id == conversation._id).length == 0) {
                openedChats.push(conversation)
            }
            if (clickedChats.filter(chat => chat._id == conversation._id).length == 0) {
                clickedChats.push(conversation)
            }
            for (let i = 0; i < clickedChats.length; i++) {
                if (clickedChats[i]._id == conversation._id) {
                    clickedChats[i]._newMessage = null;
                }
            }
            let conversations = this.state.conversations;
            for (let i = 0; i < conversations.length; i++) {
                if (conversations[i]._id == conversation._id) {
                    conversations[i]._newMessage = false;
                }
            }



            chat[conversation._id] = {
                messages: [],
                page: 0,
                message: '',
                document: null,
                image: null,
                video: null,
                mobileBtnsShow: false,
                chatHelpIcons: false,
                showGallery: false,
                journalNote: false,
                searchMembers: false,
                searchMembersText: '',
                errorTooManyPatients: false,
                searchUsers: [],
                conversation: conversation,
                userTyping: null,
                conversations
            }


            this.setState({
                openedChats,
                clickedChats,
                activeMobChatList: false,
                chat

            }, () => {

                this.props.socket.emit('messages', { conversation: conversation._id, page: 0 })
            })
        } else {
            this.createConversation(item)
        }


    }

    checkConversations = () => {
        // let conversations = this.state.conversations;
        // let chatUsers = this.state.chatUsers;
        // if (chatUsers && chatUsers.length) {
        //     chatUsers = chatUsers;
        //     let chatUsersIds = chatUsers.map((el, idx) => {
        //         if (el._id != this.props.uData._id)
        //             return el._id;
        //     })
        //     let newConversations = [];
        //     for (let i = 0; i < conversations.length; i++) {
        //         if (conversations[i].members && conversations[i].members.length) {
        //             let check = false;
        //             for (let j = 0; j < conversations[i].members.length; j++) {
        //                 if (!check && chatUsersIds.filter(el => el == conversations[i].members[j]).length) {
        //                     check = true;
        //                 }
        //             }
        //             if (check) {
        //                 newConversations.push(conversations[i])
        //             }
        //         }
        //     }
        //     if (JSON.stringify(conversations) != JSON.stringify(newConversations))
        //         this.setState({ conversations: newConversations })

        // }



    }

    handleDrop = async (e, id) => {
        e?.preventDefault?.();
        this.setState({ isDragging: null }, () => {
            let oldScrollHeight = 0;
            if (this.messagesRef && this.messagesRef[id]) {
                oldScrollHeight = this.messagesRef[id].scrollHeight;
            }

            if (this.messagesRef && this.state.chat[id] && this.state.chat[id].page && this.messagesRef[id] && this.state.chat[id].page == 0) {
                this.messagesRef[id].scrollTop = this.messagesRef[id].scrollHeight;
            } else if (oldScrollHeight) {
                this.messagesRef[id].scrollTop = this.messagesRef[id].scrollTop + (this.messagesRef[id].scrollHeight - oldScrollHeight);
            }
        });

        console.log('chat : ', this.state.chat[id]);

        if (!this.state.chat || !id || !this.state.chat[id]) return;

        console.log('passed if check, event: ', e?.dataTransfer)

        const droppedFiles = e?.dataTransfer?.files;
        console.log('dorpped files: ', droppedFiles)
        if (!droppedFiles) return;

        console.log('passed if dropped files')

        if (Array.from(droppedFiles) && Array.from(droppedFiles)?.length) {
            const fileArray = Array.from(droppedFiles);

            if (fileArray.length > 10) return this.setState({ error: 'You can only select up to 10 files to upload, at a time.' });

            this.setState({ _dragNDropUpload: true }, async () => {
                console.log('drag n drop start: ', this.state._dragNDropUpload);

                for (let i = 0; i < fileArray.length; i++) {
                    let file = fileArray[i];

                    const obj = {
                        target: {
                            files: [file]
                        }
                    }

                    if (file && file.type && ['image/png', 'image/jpeg', 'image/jpg', 'image/gif', 'image/svg'].includes(file.type)) {
                        const url = await this.uploadImage(obj, true);
                        console.log('url" , ', url);
                        if (url) await this.sendMessageFromDragAndDrop(id, 'image', url);
                    } else if (file && file.type && ['application/msword', 'application/vnd.openxmlformats-officedocument.wordprocessingml.document', 'application/pdf'].includes(file.type)) {
                        const url = await this.uploadDocument(obj, true);
                        if (url) await this.sendMessageFromDragAndDrop(id, 'document', url);
                    } else if (file && file.type && ['video/mp4', 'video/x-msvideo'].includes(file.type)) {
                        const url = await this.uploadVideo(obj, true);
                        if (url) await this.sendMessageFromDragAndDrop(id, 'video', url);
                    }
                }

                this.setState({ _dragNDropUpload: false })

                // fileArray.forEach(async (file, i) => {
                //     const obj = {
                //         target: {
                //             files: [file]
                //         }
                //     }

                //     if (i >= 10) return this.setState({ _dragNDropUpload: false });

                //     if (file && file.type && ['image/png', 'image/jpeg', 'image/jpg', 'image/gif', 'image/svg'].includes(file.type)) {
                //         const url = await this.uploadImage(obj, true);
                //         console.log('url" , ', url);
                //         if (url) await this.sendMessageFromDragAndDrop(this.state.conversation?._id, 'image', url);
                //     } else if (file && file.type && ['application/msword', 'application/vnd.openxmlformats-officedocument.wordprocessingml.document', 'application/pdf'].includes(file.type)) {
                //         const url = await this.uploadDocument(obj, true);
                //         if (url) await this.sendMessageFromDragAndDrop(this.state.conversation?._id, 'document', url);
                //     } else if (file && file.type && ['video/mp4', 'video/x-msvideo'].includes(file.type)) {
                //         const url = await this.uploadVideo(obj, true);
                //         if (url) await this.sendMessageFromDragAndDrop(this.state.conversation?._id, 'video', url);
                //     }
                // });


            })
        }
    }

    sendMessageFromDragAndDrop = async (conversationId, type, url) => new Promise((resolve, reject) => {
        console.log('test : ', this.state.chat?.[conversationId])
        if (!conversationId || !type || !url) return reject({ error: 'asdf' });
        if (!this.state.chat?.[conversationId]) return reject({ error: 'asdf' });
        let obj = {
            conversation: conversationId,
            message: this.state.chat[conversationId]?.message || '',
        }

        switch (type) {
            case 'document': { obj.document = url; break; }
            case 'image': { obj.image = url; break; }
            case 'video': { obj.video = url; break; }
        }

        this.props.socket.emit('sendMessage', obj);

        let chat = this.state.chat;
        chat[conversationId].message = '';
        chat[conversationId].image = null;
        chat[conversationId].document = null;
        chat[conversationId].video = null;
        chat[conversationId].healthCareId = null;
        this.setState({
            chat
        }, () => {
            return resolve(true);
        })
    })


    render() {
        let params = {};
        if (this.state.useState) {
            params = this.state.params;
        } else {
            params = getSearchParams(this.props[0].location.search, {})
        }

        let memebersDict = {};

        if (this.state.conversation && this.state.conversation.users) {
            for (let i = 0; i < this.state.conversation.users.length; i++)
                memebersDict[this.state.conversation.users[i].uid] = this.state.conversation.users[i]
        }
        const humanReadable = {
            'waiting-for-approval': 'Waiting for approval',
            'waiting': 'Waiting',
            'in-progress': 'In progress',
            'disallowed': 'Disallowed',
            'allowed': 'Allowed',
            'return-for-update': 'Return for update',
            'patient-requested-another-time': 'Patient requested anoother time',
            'patient-rejected': 'Rejected by patient',
            'patient-accepted': 'Patient accepted',
            'clinic-accepted-requested-time': 'Clinic accepted requested time',
            'clinic-rejected-requested-time': 'Clinic rejected requested time',
            'approved-by-region-manager': 'Approved by region manager',
            'rejected-by-region-manager': 'Rejected by region manager',
            'scheduled': 'Scheduled',
            'not-booked': 'Not booked',
            'closed': 'Closed',
            'requested-new-appointment': 'Requested new appointment'.translate(this.props.lang),
            'visit-rescheduled': 'Visit rescheduled'.translate(this.props.lang),

        }
        let selectedGroupIdx = -1;
        if (this.props.uData && this.props.selectedGroup && this.props.uData?.clinicGroups?.length) {
            for (let i = 0; i < this.props.uData.clinicGroups.length; i++) {
                if (this.props.uData.clinicGroups[i]._id == this.props.selectedGroup) {
                    selectedGroupIdx = i;
                    break;
                }
            }
        }
        let isMobile = false;
        if (typeof window != 'undefined' && window.innerWidth < 760) {
            isMobile = true;
        }
        if (isMobile) {
            return (
                <div className="chat-component-wrap-mob">
                    <div className={this.state.activeMobChatList || this.state.openedChats.length ? "chat-list-mob chat-list-mob-hidden" : "chat-list-mob"} onClick={() => this.setState({ activeMobChatList: !this.state.activeMobChatList })}>
                        <Isvg src={chatComponentIcon} />
                        {
                            this.state.conversations && this.state.conversations.filter(conversation => !conversation.closed && conversation.chatOneOnOne && !conversation.patient && conversation.members && conversation._newMessage && conversation.groupIds && conversation.groupIds.indexOf(this.props.selectedGroup) != -1).length ?
                                <div style={{ width: 13, height: 13, borderRadius: '50%', backgroundColor: '#dc3545', position: 'absolute', top: 0, right: 0 }}></div>
                                :
                                null
                        }
                    </div>
                    {
                        this.state.activeMobChatList && this.state.chatUsers && this.state.chatUsers.length && this.state.chatUsers.filter(item => item._id != this.props.uData._id).length ?
                            <div className="opened-chat-list">
                                <div className="opened-chat-list-header">
                                    <h4 className="noselect">{'Chat'.translate(this.props.lang)}</h4>
                                    <div className="close-mob-chat" onClick={() => this.setState({ activeMobChatList: !this.state.activeMobChatList })}>&times;</div>
                                </div>
                                <div style={{ overflowY: 'scroll', height: '100%', paddingBottom: 60 }}>
                                    {
                                        this.state.chatUsers.map((item, idx) => {

                                            if (item._id != this.props.uData._id && (!this.state.searchChatUsers || (this.state.searchChatUsers && item.name.toLowerCase().indexOf(this.state.searchChatUsers.toLowerCase()) !== -1))) {
                                                return (
                                                    <div className="opened-chat-list-item-wrap" onClick={() => {
                                                        this.openChat(item._id)

                                                    }}>
                                                        <div className="opened-chat-list-item">

                                                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                                                <img src={item.profilePicture ? (API_ENDPOINT + item.profilePicture) : profilePicture} />

                                                                <div style={{ paddingRight: 15, lineHeight: '21px' }}>
                                                                    {item.name}

                                                                    {
                                                                        this.state.conversations && this.state.conversations.filter(conversation => !conversation.closed && conversation.chatOneOnOne && !conversation.patient && conversation.members && (conversation.members[0] == item._id || conversation.members[1] == item._id) && conversation._newMessage && conversation.groupIds && conversation.groupIds.indexOf(this.props.selectedGroup) != -1).length ?
                                                                            <Isvg src={newMessage} className="new-message-icon" style={{ marginTop: -8, marginLeft: 5 }} />
                                                                            :
                                                                            null
                                                                    }

                                                                </div>
                                                            </div>
                                                            <div className={item.online ? 'user-online' : 'user-offline'}></div>

                                                        </div>
                                                    </div>

                                                )
                                            }
                                        })
                                    }
                                </div>
                                <div className="opened-chat-list-search-wrap">
                                    <Input type='text' value={this.state.searchChatUsers} placeholder={"Search...".translate(this.props.lang)} onChange={(e) => {
                                        this.setState({ searchChatUsers: e.target.value })
                                    }}
                                    />
                                </div>
                            </div>
                            :
                            null
                    }
                    {
                        this.state.clickedChats && this.state.clickedChats.length ?
                            <div className="clicked-chats-wrap">
                                {
                                    this.state.clickedChats.map((item, idx) => {
                                        return (
                                            <div className="clicked-chat-item opened-chat">

                                                <div className={`chat-wrap ${this.state.call ? 'video-call' : ''} ${this.state.showChat ? 'show-chat' : ''} ${this.state.offerData ? 'incoming-offer' : ''} ${this.state.chat[item._id].searchMembers || (this.state.chat && this.state.chat[item._id] && this.state.chat[item._id].showGallery) ? 'search-members-active' : ''}`} ref={(node) => this.chatWrapRef = node}>
                                                    <div className={this.state.chat && this.state.chat[item._id] && this.state.chat[item._id].chatHelpIcons ? 'chat-layer chat-layer-show' : 'chat-layer'} onClick={() => {
                                                        let chat = this.state.chat;
                                                        chat[item._id].chatHelpIcons = false;
                                                        this.setState({ chat })
                                                    }}></div>

                                                    <div className="head" onClick={() => {


                                                        let openedChats = this.state.openedChats;
                                                        let newOpenedChats = []
                                                        for (let i = 0; i < openedChats.length; i++) {
                                                            if (openedChats[i]._id != item._id) {
                                                                newOpenedChats.push(openedChats[i])
                                                            }
                                                        }

                                                        // let messages = this.state.messages;
                                                        // let page = this.state.page;

                                                        // messages[item._id] = [];

                                                        // page[item._id] = 0;
                                                        // this.setState({
                                                        //     openedChats: newOpenedChats,
                                                        //     messages,
                                                        //     page
                                                        // }, () => {

                                                        //     this.props.socket.emit('messages', { conversation: item._id, page: 0 })
                                                        // })
                                                        if (item && item._id) {
                                                            this.props.registerClinicGroupLogs({
                                                                type: 'closeConversation',
                                                                conversation: item._id
                                                            })
                                                        }

                                                        this.setState({
                                                            openedChats: newOpenedChats,
                                                        }, () => {
                                                            // this.props.socket.emit('messages', { conversation: item._id, page: 0 })

                                                        })

                                                    }}>
                                                        <h6>

                                                            {item && item.referral ?
                                                                <div className="referral">
                                                                    <button className="chat-header-icon" onClick={(e) => {
                                                                        e.stopPropagation();
                                                                        this.setState({ referral: item })
                                                                    }}>
                                                                        {/* {'Patient responses'.translate(this.props.lang)} */}
                                                                        <Isvg src={docIcon} />
                                                                    </button>
                                                                </div>
                                                                :
                                                                null
                                                            }


                                                            <p>{
                                                                item && item.name && item.name.length < 25 ?
                                                                    this.props.lang == 'se' ? item.name.replace('Direct', 'Direkt') : item.name
                                                                    :
                                                                    this.props.lang == 'se' ? (item.name.replace('Direct', 'Direkt').substring(0, 22) + '...') : (item.name.substring(0, 22) + '...')
                                                            }</p> &nbsp;
                                                            {
                                                                this.props.uData && this.props.uData.userLevel >= 20 && localStorage.getItem('profile') == 'doctor' && item && item.members && item.members[0] == this.props.uData._id && !item.closed ?
                                                                    <>
                                                                        {
                                                                            item.referral && item.referral.dropIn ?
                                                                                <Button className="close-conversation-button" onClick={() => this.setState({ closeCaseModal: item })} color="danger" size="sm">{'Close case'.translate(this.props.lang)} </Button>
                                                                                :
                                                                                <Button className="close-conversation-button" onClick={() => this.setState({ closeModal: item })} color="danger" size="sm">{'Close conversation'.translate(this.props.lang)} </Button>
                                                                        }
                                                                    </>
                                                                    :
                                                                    this.props.uData && this.props.uData.userLevel >= 20 && localStorage.getItem('profile') == 'doctor' && item && item.members && item.members[0] != this.props.uData._id && !item.closed ?
                                                                        <Button className="close-conversation-button" onClick={() => this.setState({ leaveChatModal: item })} color="danger" size="sm">{'Leave chat'.translate(this.props.lang)} </Button>

                                                                        :
                                                                        null
                                                            }
                                                            {
                                                                this.props.uData && this.props.uData.userLevel >= 20 && localStorage.getItem('profile') == 'doctor' && item && item.members && item.members[0] == this.props.uData._id && item.closed ?
                                                                    <Button onClick={() => this.setState({ openModal: item })} color="primary" size="sm">{'Open conversation'.translate(this.props.lang)} </Button>
                                                                    :
                                                                    null
                                                            }
                                                        </h6>
                                                        <div className="members-container">
                                                            {
                                                                item && item.members && item.members.length >= 2 ?
                                                                    <div className="overlay"></div>
                                                                    :
                                                                    null
                                                            }
                                                            {item && item.users.map((user, idx) => {
                                                                return (
                                                                    this.props.uData && this.props.uData.userLevel >= 20 && localStorage.getItem('profile') == 'doctor' ?
                                                                        <div className={user.level < 20 ? "chat-icons-tooltip" : ''}>{user.level < 20 ? 'Click on patient to see his profile'.translate(this.props.lang) : ''}</div>
                                                                        :
                                                                        null

                                                                )
                                                            })}
                                                            <div ref={(node) => this.membersScrollArea = node}
                                                                onMouseDown={(e) => {
                                                                    if (this.membersScrollArea) {
                                                                        this.membersScrollArea.style.cursor = 'grabbing';
                                                                        //this.scrollArea.style.userSelect = 'none';


                                                                        this.setState({
                                                                            _membersDragging: {
                                                                                x: e.clientX,
                                                                                y: e.clientY,
                                                                                left: this.membersScrollArea.scrollLeft,
                                                                            }
                                                                        })
                                                                    }
                                                                }}
                                                                onMouseMove={(e) => {
                                                                    if (this.state._membersDragging && this.membersScrollArea) {
                                                                        // How far the mouse has been moved
                                                                        const dx = e.clientX - this.state._membersDragging.x;

                                                                        this.membersScrollArea.scrollLeft = this.state._membersDragging.left - dx;

                                                                    }
                                                                }}
                                                                onMouseUp={(e) => {
                                                                    this.membersScrollArea.style.cursor = 'grab';
                                                                    //this.scrollArea.style.userSelect = 'none';

                                                                    this.setState({
                                                                        _membersDragging: null
                                                                    })
                                                                }}
                                                                className={
                                                                    item && item.members.length >= 2 && window.innerWidth > 768 && typeof window !== 'undefined' ? 'members members-scroll' : ((item && item.members.length >= 4 && window.innerWidth < 768 && typeof window !== 'undefined' ? 'members members-scroll-mobile' : 'members') || (item && item.members.length > 2 && typeof window != 'undefined' && window.innerWidth > 768 ? 'members members-scroll' : 'members'))}>
                                                                {
                                                                    item && item.users.map((user, idx) => {

                                                                        if (user.uid && user.uid.indexOf('guest') == -1)
                                                                            return (
                                                                                <div
                                                                                    onMouseEnter={(e) => {
                                                                                        e.stopPropagation();
                                                                                        this.setState({
                                                                                            memeberHoverState: user.uid
                                                                                        })
                                                                                    }}

                                                                                    onMouseLeave={(e) => {
                                                                                        e.stopPropagation();

                                                                                        this.setState({
                                                                                            memeberHoverState: null
                                                                                        })
                                                                                    }}

                                                                                    onClick={(e) => {
                                                                                        e.stopPropagation();
                                                                                        if (this.state.tooltip == 0 || this.state.tooltip != user.uid) {
                                                                                            this.setState({
                                                                                                tooltip: user.uid
                                                                                            })
                                                                                        } else if (this.state.tooltip == user.uid) {
                                                                                            this.setState({
                                                                                                tooltip: 0
                                                                                            })
                                                                                        }
                                                                                        if (this.state.memeberHoverState == user.uid) {

                                                                                            if ((this.props.uData.userLevel == 20 && typeof window != 'undefined' && localStorage?.getItem?.('profile') != 'patient') && (user.level && user.level < 20 || (user.socialSecurityNumber && item && item.referral && item.referral.patient && item.referral.patient.socialSecurityNumber && item.referral.patient.name && item.referral.patient.socialSecurityNumber == user.socialSecurityNumber) || (user.socialSecurityNumber && item && item.control && item.socialSecurityNumber && item.socialSecurityNumber == user.socialSecurityNumber))) {
                                                                                                fetch(API_ENDPOINT + '/users/users/' + user.uid.toString(), {
                                                                                                    method: 'GET',
                                                                                                    headers: {
                                                                                                        'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage.getItem('authToken')}` : null,
                                                                                                        'content-type': 'application/json'
                                                                                                    },
                                                                                                }).then(res => res.json()).then((result) => {
                                                                                                    this.setState({
                                                                                                        profileModal: {
                                                                                                            conversation: item._id,
                                                                                                            ...result
                                                                                                        }
                                                                                                    })
                                                                                                })
                                                                                            }
                                                                                            this.setState({
                                                                                                memeberHoverState: null
                                                                                            })

                                                                                        } else {
                                                                                            this.setState({
                                                                                                memeberHoverState: user.uid
                                                                                            })
                                                                                        }
                                                                                    }}>
                                                                                    <img onClick={() => { }} src={user.profilePicture ? API_ENDPOINT + user.profilePicture : profilePicture} />
                                                                                    <div className={user.online ? 'status online' : 'status offline'}></div>
                                                                                    <p onClick={() => { }} className={typeof window != 'undefined' && window.innerWidth > 768 ? this.state.tooltip != 0 && this.state.tooltip == user.uid ? 'name-visible' : 'name-none' : ''}>
                                                                                        {
                                                                                            user.socialSecurityNumber && item && item.referral && item.referral.patient && item.referral.patient.socialSecurityNumber && item.referral.patient.name && item.referral.patient.socialSecurityNumber == user.socialSecurityNumber ?
                                                                                                item.referral.patient.name
                                                                                                :
                                                                                                (user.socialSecurityNumber && item && item.control && item.socialSecurityNumber && item.socialSecurityNumber == user.socialSecurityNumber) ?
                                                                                                    item.name
                                                                                                    :
                                                                                                    user.name
                                                                                        }
                                                                                        {
                                                                                            user.socialSecurityNumber ?
                                                                                                <><br />{user.socialSecurityNumber} </>
                                                                                                :
                                                                                                null
                                                                                        }
                                                                                    </p>

                                                                                </div>
                                                                            )
                                                                    })
                                                                }
                                                            </div>
                                                        </div>

                                                        <div className="chat-more-icon-wrap" >
                                                            <Isvg src={moreIcon} onClick={(e) => {
                                                                e.stopPropagation();
                                                                let chat = this.state.chat;
                                                                chat[item._id].chatHelpIcons = !chat[item._id].chatHelpIcons;
                                                                this.setState({ chat })
                                                            }} className="chat-more-icon" />
                                                        </div>

                                                        <div className={this.state.chat && this.state.chat[item._id] && this.state.chat[item._id].chatHelpIcons ? 'chat-helper chat-helper-show' : 'chat-helper'}>
                                                            <input ref={(node) => this.roomIdInput = node} style={{ width: 10, height: 10, background: 'transparent', border: 0, opacity: 0 }} value={item && typeof window != 'undefined' ? `${window.location.origin}/video-call/${item._id}?roomId=${item.roomId}` : null} />


                                                            <button onClick={(e) => {
                                                                e.stopPropagation()
                                                                let chat = this.state.chat;
                                                                chat[item._id].chatHelpIcons = false;
                                                                chat[item._id].showGallery = true;
                                                                this.setState({ chat })
                                                            }} className="search-members-button"><Isvg src={galleryIcon} /> <div className="chat-icons-tooltip">{'Media'.translate(this.props.lang)}</div></button>



                                                            {this.props.uData && this.props.uData.userLevel >= 20 && localStorage.getItem('profile') == 'doctor' && item && item.members && item.members[0] == this.props.uData._id ?
                                                                <button onClick={(e) => {
                                                                    e.stopPropagation()
                                                                    let chat = this.state.chat;
                                                                    chat[item._id].chatHelpIcons = false;
                                                                    this.setState({ chat })
                                                                    this.showCopiedLink();
                                                                    const copyText = this.roomIdInput
                                                                    copyText.select();
                                                                    document.execCommand('copy');


                                                                }} className={this.state.linkCopied ? "share-button link-copy-progress" : "share-button"}><Isvg src={shareIcon} /> <div className={this.state.linkCopied ? 'link-copied yes' : 'link-copied'}>{'The link is copied to clipboard'.translate(this.props.lang)}</div> <div className="chat-icons-tooltip">{'Copy link'.translate(this.props.lang)}</div> </button>
                                                                :
                                                                null
                                                            }

                                                            {/* {this.props.uData && this.props.uData.userLevel >= 20 && localStorage.getItem('profile') == 'doctor' && item && item.members && item.members[0] == this.props.uData._id ?
                                                                    <button onClick={(e) => {
                                                                        e.stopPropagation()
                                                                        let chat = this.state.chat;
                                                                        chat[item._id].chatHelpIcons = false;
                                                                        chat[item._id].searchMembers = true;
                                                                        this.setState({ chat })
                                                                    }} className="search-members-button"><Isvg src={addIcon} style={{ position: 'absolute' }} /> <div className="chat-icons-tooltip">{'Add participant'.translate(this.props.lang)}</div></button>
                                                                    :
                                                                    null
                                                                } */}
                                                            {!this.props.videoCallUrl && this.props.uData && this.props.uData.userLevel >= 20 && localStorage.getItem('profile') == 'doctor' && selectedGroupIdx != -1 && item.groupIds && item.groupIds.indexOf(this.props.selectedGroup) != -1 && item && item.members && item.members[0] == this.props.uData._id ?
                                                                <button onClick={/*this.call*/(e) => {
                                                                    e.stopPropagation()
                                                                    let chat = this.state.chat;
                                                                    chat[item._id].chatHelpIcons = false;
                                                                    this.setState({ chat })
                                                                    this.props.startVideoCall(item._id);
                                                                }} className="call-button" disabled={this.props.uData.clinicGroups[selectedGroupIdx].videoLicence <= 0} style={{ margin: '6px 0 0 0' }}><Isvg src={call} /> <div className="chat-icons-tooltip">{'Start video chat'.translate(this.props.lang)}</div> </button>
                                                                :
                                                                null
                                                            }
                                                            {typeof window !== 'undefined' && true && this.props.uData && this.props.uData.userLevel >= 20 && localStorage.getItem('profile') == 'doctor' && item && item.members && item.members[0] == this.props.uData._id && !item.closed ?
                                                                <>
                                                                    {
                                                                        item.referral && item.referral.dropIn ?
                                                                            <Button className="close-conversation-button-mobile" onClick={(e) => {
                                                                                e.stopPropagation()
                                                                                let chat = this.state.chat;
                                                                                chat[item._id].chatHelpIcons = false;
                                                                                this.setState({ closeCaseModal: item, chat })
                                                                            }} color="danger" size="sm">&times; <div class="chat-icons-tooltip">{'Close case'.translate(this.props.lang)}</div> </Button>
                                                                            :
                                                                            <Button className="close-conversation-button-mobile" onClick={(e) => {
                                                                                e.stopPropagation()
                                                                                let chat = this.state.chat;
                                                                                chat[item._id].chatHelpIcons = false;
                                                                                this.setState({ closeModal: item, chat })
                                                                            }} color="danger" size="sm"> &times; <div class="chat-icons-tooltip">{'Close conversation'.translate(this.props.lang)}</div> </Button>
                                                                    }
                                                                </>
                                                                :
                                                                typeof window !== 'undefined' && true && this.props.uData && this.props.uData.userLevel >= 20 && localStorage.getItem('profile') == 'doctor' && item && item.members && item.members[0] != this.props.uData._id && !item.closed ?
                                                                    // <Button className="close-conversation-button" onClick={() => this.setState({ leaveChatModal: item })} color="danger" size="sm">{'Leave chat'.translate(this.props.lang)} </Button>
                                                                    <Button className="close-conversation-button-mobile" onClick={(e) => {
                                                                        e.stopPropagation()
                                                                        let chat = this.state.chat;
                                                                        chat[item._id].chatHelpIcons = false;
                                                                        this.setState({ leaveChatModal: item, chat })
                                                                    }} color="danger" size="sm"> &times; <div class="chat-icons-tooltip">{'Leave chat'.translate(this.props.lang)}</div> </Button>

                                                                    :
                                                                    null
                                                            }
                                                        </div>
                                                        <div className='close-chat-head' onClick={(e) => {
                                                            e.stopPropagation()
                                                            let openedChats = this.state.openedChats;
                                                            let clickedChats = this.state.clickedChats;

                                                            let newOpenedChats = []
                                                            for (let i = 0; i < openedChats.length; i++) {
                                                                if (openedChats[i]._id != item._id) {
                                                                    newOpenedChats.push(openedChats[i])
                                                                }
                                                            }
                                                            let newClickedChats = [];
                                                            for (let i = 0; i < clickedChats.length; i++) {
                                                                if (clickedChats[i]._id != item._id) {
                                                                    newClickedChats.push(clickedChats[i])
                                                                }
                                                            }

                                                            if (item && item._id) {
                                                                this.props.registerClinicGroupLogs({
                                                                    type: 'closeConversation',
                                                                    conversation: item._id
                                                                })
                                                            }

                                                            this.setState({
                                                                openedChats: newOpenedChats,
                                                                clickedChats: newClickedChats,
                                                            })
                                                        }}>&times;</div>
                                                    </div>

                                                    {
                                                        this.state.chat[item._id].searchMembers && item ?
                                                            <div className="search-members">

                                                                <div className="search-area">
                                                                    <div className="search-header-wrap">
                                                                        <h4>{'Search patients'.translate(this.props.lang)}</h4>
                                                                        <button className="close" onClick={() => {
                                                                            let chat = this.state.chat;
                                                                            chat[item._id].searchMembers = null;
                                                                            chat[item._id].searchMembersText = '';
                                                                            chat[item._id].errorTooManyPatients = false;

                                                                            this.setState({ chat })
                                                                        }}>&times;</button>
                                                                    </div>
                                                                    <Input type="text" placeholder={'Search'.translate(this.props.lang)} value={this.state.chat && this.state.chat[item._id] && this.state.chat[item._id].searchMembersText} onChange={(e) => {
                                                                        let chat = this.state.chat;
                                                                        chat[item._id].searchMembersText = striptags(e.target.value);
                                                                        chat[item._id].errorTooManyPatients = false;

                                                                        this.setState({
                                                                            chat
                                                                        }, () => this.searchMembers(item._id))
                                                                    }} />


                                                                    <ul>
                                                                        {
                                                                            this.state.chat && this.state.chat[item._id] && this.state.chat[item._id].searchUsers && this.state.chat[item._id].searchUsers.map((user, uidx) => {

                                                                                if (user._id != this.props.uData._id)
                                                                                    return (
                                                                                        <li key={user._id + uidx + '-' + item.members.indexOf(user._id)}>
                                                                                            <div className="user-image">
                                                                                                <img src={user.profilePicture ? API_ENDPOINT + user.profilePicture : profilePicture} />
                                                                                                <div className={user.online ? 'status online' : 'status offline'}></div>
                                                                                            </div>
                                                                                            <p>
                                                                                                {
                                                                                                    user.userData && user.userData.name && (user.userLevel == 1 || user.userLevel == 2 || (user.userLevel == 20 && ((user.socialSecurityNumber && item && item.referral && item.referral.patient && item.referral.patient.socialSecurityNumber && item.referral.patient.name && item.referral.patient.socialSecurityNumber == user.socialSecurityNumber) || (user.socialSecurityNumber && item && item.control && item.socialSecurityNumber && item.socialSecurityNumber == user.socialSecurityNumber)))) ?
                                                                                                        user.userData.name
                                                                                                        :
                                                                                                        user.name
                                                                                                }
                                                                                                {
                                                                                                    user.userData && user.socialSecurityNumber && (user.userLevel == 1 || user.userLevel == 2 || (user.userLevel == 20 && ((user.socialSecurityNumber && item && item.referral && item.referral.patient && item.referral.patient.socialSecurityNumber && item.referral.patient.name && item.referral.patient.socialSecurityNumber == user.socialSecurityNumber) || (user.socialSecurityNumber && item && item.control && item.socialSecurityNumber && item.socialSecurityNumber == user.socialSecurityNumber)))) ?
                                                                                                        <><br />{user.socialSecurityNumber}</>
                                                                                                        :
                                                                                                        null
                                                                                                }
                                                                                            </p>
                                                                                            {item.members.indexOf(user._id) !== -1 ?
                                                                                                <button onClick={() => this.removeMember(user._id, item)} className="delete-member"><Isvg src={garabage} /></button>

                                                                                                :
                                                                                                <Button color="primary" onClick={() => this.addMember(user, item)}>{'Add'.translate(this.props.lang)}</Button>
                                                                                            }
                                                                                        </li>
                                                                                    )
                                                                            })
                                                                        }

                                                                    </ul>
                                                                    {this.state.chat[item._id].errorTooManyPatients ?

                                                                        <ErrorModal lang={this.props.lang}
                                                                            isOpen={this.state.chat[item._id].errorTooManyPatients}
                                                                            toggle={() => {
                                                                                let chat = this.state.chat;
                                                                                chat[item._id].errorTooManyPatients = null;
                                                                                this.setState({ chat })
                                                                            }}
                                                                            zIndex={99999999999}
                                                                        >
                                                                            {this.state.chat[item._id].errorTooManyPatients.translate(this.props.lang)}
                                                                        </ErrorModal>

                                                                        :
                                                                        null
                                                                    }

                                                                </div>
                                                            </div>

                                                            :

                                                            null
                                                    }

                                                    {this.state.chat && this.state.chat[item._id] && this.state.chat[item._id].showGallery ?
                                                        <div className="conversation-media">
                                                            <button className="close" onClick={() => {
                                                                let chat = this.state.chat;
                                                                chat[item._id].showGallery = null;
                                                                this.setState({ chat })
                                                            }}>&times;</button>
                                                            <h6>{'Conversation media'.translate(this.props.lang)}</h6>
                                                            <div className="items">
                                                                {
                                                                    this.state.chat && this.state.chat[item._id] && this.state.chat[item._id].messages.filter(message => message.image || (message.healthCareReferral && !message.hideIcon) || message.video).map((message) => {
                                                                        return (
                                                                            <div className="item">
                                                                                {
                                                                                    message.uid == this.props.uData._id ?
                                                                                        <div className="delete-message-icon" onClick={() => this.setState({
                                                                                            deleteMessageModal: {
                                                                                                conversation: item._id,
                                                                                                message
                                                                                            }
                                                                                        })}><Isvg src={garabage} /></div>
                                                                                        :
                                                                                        null
                                                                                }
                                                                                {
                                                                                    message.image ?
                                                                                        <a href={`/media-gateway?path=${message.image}&cid=${message.conversation}&mid=${message._id}`} target="_blank">
                                                                                            <img src={API_ENDPOINT + `${message.image}?mt=${this.props.uData ? this.props.uData.mediaToken : ''}&cid=${message.conversation}&mid=${message._id}`} />
                                                                                        </a>
                                                                                        :
                                                                                        message.healthCareReferral && !message.hideIcon ?
                                                                                            <div className="conversation-media-questionary"
                                                                                                onClick={() => this.setState({
                                                                                                    questionaryModal: message.healthCareId,
                                                                                                    questionaryMessageActive: message._id,
                                                                                                    questionarySave: true,
                                                                                                    activeReferralContent: message.healthCareReferral && message.healthCareReferralObject && message.healthCareReferralObject.healthCareContent ? message.healthCareReferralObject.healthCareContent : null,
                                                                                                    questionaryAnswers: message.healthCareReferral && message.healthCareReferralObject ? message.healthCareReferralObject.answers : {}
                                                                                                }, () => this.getQuestionary())}>
                                                                                                <div className="conversation-media-questionary-svg">
                                                                                                    <Isvg src={formIcon} />
                                                                                                </div>
                                                                                                <h6>{message.message}</h6>
                                                                                            </div>
                                                                                            :
                                                                                            message.video ?
                                                                                                <div className="conversation-media-video">
                                                                                                    <video width="320" height="240" controls>
                                                                                                        <source src={API_ENDPOINT + `${message.video}?mt=${this.props.uData ? this.props.uData.mediaToken : ''}&cid=${message.conversation}&mid=${message._id}`} type="video/mp4" />
                                                                                                        <source src={API_ENDPOINT + `${message.video}?mt=${this.props.uData ? this.props.uData.mediaToken : ''}&cid=${message.conversation}&mid=${message._id}`} type="video/ogg" />
                                                                                                    </video>
                                                                                                </div>

                                                                                                :

                                                                                                null
                                                                                }

                                                                            </div>
                                                                        )
                                                                    })
                                                                }

                                                            </div>
                                                        </div>
                                                        :
                                                        null}




                                                    {this.state.offerData ?
                                                        <div className="call-offer">
                                                            <div class="caller">
                                                                <img src={this.state.offerData.user && this.state.offerData.user.profilePicture ? API_ENDPOINT + this.state.offerData.user.profilePicture : profilePicture} />
                                                                <p>{this.state.offerData && this.state.offerData.user ? this.state.offerData.user.name : ''}</p>
                                                            </div>
                                                            <div className="buttons">
                                                                <button onClick={this.answer} className="answer-button"><Isvg src={call} /></button>
                                                                <button onClick={this.endCall} className="decline-button"><Isvg src={call} /></button>
                                                            </div>

                                                        </div>
                                                        :
                                                        null
                                                    }


                                                    <div className={this.state.chat[item._id].journalNote ? 'messages-wrapper journal-note-active-modal' : 'messages-wrapper'}
                                                        onMouseMove={(e) => {
                                                            if (this.state.messageRisaze) {
                                                                const element = document.getElementById('message')
                                                                if (element) {

                                                                    element.style.height = Number(element.style.height.replace('px', '')) - e.movementY + 'px'
                                                                }
                                                            }
                                                        }} onMouseUp={() => {
                                                            this.setState({ messageRisaze: false })
                                                        }}>
                                                        {
                                                            this.state.showChat ?
                                                                <button className="hide-chat" onClick={() => this.setState({ showChat: null })}>&times;</button>
                                                                :

                                                                null
                                                        }

                                                        {
                                                            item && item.currentCall && (this.props.uData && this.props.uData._id != item.members[0]) ?
                                                                <button onClick={() => {
                                                                    let chat = this.state.chat;
                                                                    chat[item._id].chatHelpIcons = false;
                                                                    this.setState({ chat }, () => {
                                                                        var strWindowFeatures = `height=${window.innerHeight - 1},width=${window.innerWidth - 1},resizable=yes,scrollbars=yes,toolbar=yes,menubar=no,location=no,directories=no, status=yes`;
                                                                        this.videoCallWindow = window.open(`/video-call/${item._id}?initiator=${item.currentCall.initiator}`, "_blank", strWindowFeatures);
                                                                        this.videoCallWindow.addEventListener('message',
                                                                            (e) => {
                                                                                if (e.data == 'end-video-call') {
                                                                                    this.videoCallWindow.close();
                                                                                }

                                                                            }

                                                                            , false)
                                                                    })
                                                                }} className="join-call-button"><Isvg src={call} /> <span>{'Rejoin call'.translate(this.props.lang)}</span></button>

                                                                :
                                                                null

                                                        }

                                                        <div className="messages" ref={(node) => {
                                                            if (this.messagesRef && this.messagesRef[item._id]) {
                                                                this.messagesRef[item._id] = node
                                                            } else {
                                                                if (!this.messagesRef) {
                                                                    this.messagesRef = {}
                                                                }
                                                                this.messagesRef[item._id] = node;
                                                            }

                                                        }} style={{ paddingBottom: this.state.messageHeight && this.messagesRef && this.state.messageHeight[item._id] && this.messagesRef[item._id] ? (this.state.messageHeight[item._id] + 66) : 130 }}>


                                                            {
                                                                this.state.chat && this.state.chat[item._id] && this.state.chat[item._id].messages.map((message, midx) => {

                                                                    if (message.type == 'special') {
                                                                        return (
                                                                            <>
                                                                                {message.videoStarted ? <h6 className='video-chat-notify'>
                                                                                    <Isvg src={videoChatIcon} />
                                                                                    {/* <span className='time-started'>{moment.unix(message.tsCreated).format(this.props.dateFormat + ' HH:mm')}</span> */}
                                                                                    <span className='time-started'>{this.props.getStringDateTs(message.tsCreated, this.props.dateFormat + ' HH:mm')}</span>
                                                                                    <span className='user-name'>{message.user.name}</span>
                                                                                    <span className='notify'> {'Started video meeting'.translate(this.props.lang)}</span>
                                                                                </h6> : null}

                                                                                {message.videoJoined ? <h6 className='video-chat-notify'>
                                                                                    <Isvg src={userChatIcon} />
                                                                                    {/* <span className='time-started'>{moment.unix(message.tsCreated).format(this.props.dateFormat + ' HH:mm')}</span> */}
                                                                                    <span className='time-started'>{this.props.getStringDateTs(message.tsCreated, this.props.dateFormat + ' HH:mm')}</span>
                                                                                    <span className='user-name'>{message.user.name}</span>
                                                                                    <span className='notify'> {'Joined video meeting'.translate(this.props.lang)}</span>
                                                                                </h6> : null}

                                                                                {message.videoLeft ? <h6 className='video-chat-notify'>
                                                                                    <Isvg src={userChatIcon} />
                                                                                    {/* <span className='time-started'>{moment.unix(message.tsCreated).format(this.props.dateFormat + ' HH:mm')}</span> */}
                                                                                    <span className='time-started'>{this.props.getStringDateTs(message.tsCreated, this.props.dateFormat + ' HH:mm')}</span>
                                                                                    <span className='user-name'>{message.user.name}</span>
                                                                                    <span className='notify'> {'Left video meeting'.translate(this.props.lang)}</span>
                                                                                </h6> : null}

                                                                                {message.videoEnded ? <h6 className='video-chat-notify'>
                                                                                    <Isvg src={userChatIcon} />
                                                                                    {/* <span className='time-started'>{moment.unix(message.tsCreated).format(this.props.dateFormat + ' HH:mm')}</span> */}
                                                                                    <span className='time-started'>{this.props.getStringDateTs(message.tsCreated, this.props.dateFormat + ' HH:mm')}</span>
                                                                                    <span className='user-name'>{message.user.name}</span>
                                                                                    <span className='notify'> {'Ended video meeting'.translate(this.props.lang)}</span>
                                                                                </h6> : null}
                                                                            </>
                                                                        )

                                                                    } else {
                                                                        return (
                                                                            <div className={message.uid == this.props.uData._id ? 'right' : ''} style={{ wordBreak: 'break-all' }}>

                                                                                {this.state.chat[item._id].messages.length > 15 && midx == 1 ?
                                                                                    <div ><Waypoint
                                                                                        onEnter={() => {
                                                                                            let chat = this.state.chat;
                                                                                            chat[item._id].page = chat[item._id].page + 1
                                                                                            this.setState({
                                                                                                chat
                                                                                            }, () => {
                                                                                                this.props.socket.emit('messages', { conversation: item._id, page: this.state.chat[item._id].page })
                                                                                            })
                                                                                        }}
                                                                                    />
                                                                                    </div>
                                                                                    :
                                                                                    null
                                                                                }

                                                                                {
                                                                                    message.uid != this.props.uData._id ?
                                                                                        <img src={message.user && message.user.profilePicture ? API_ENDPOINT + message.user.profilePicture : profilePicture} />
                                                                                        :
                                                                                        null
                                                                                }
                                                                                <div>

                                                                                    <div className='break-word' onClick={() => {
                                                                                        let showSeen = this.state.showSeen;
                                                                                        showSeen[message._id] = !showSeen[message._id];
                                                                                        this.setState({
                                                                                            showSeen
                                                                                        })
                                                                                    }}>
                                                                                        {
                                                                                            message.uid == this.props.uData._id && !message.deletedMessage ?
                                                                                                <div className="delete-message-icon" onClick={() => this.setState({
                                                                                                    deleteMessageModal: {
                                                                                                        conversation: item._id,
                                                                                                        message
                                                                                                    }
                                                                                                })}>&times;</div>
                                                                                                :
                                                                                                null
                                                                                        }
                                                                                        <div className="username">
                                                                                            <p>{message.user && message.user.name}</p>
                                                                                        </div>
                                                                                        {
                                                                                            message.deletedMessage && (message.uid == this.props.uData._id || (message && (!message.memberLanguages || (message.memberLanguages && !message.memberLanguages[this.props.uData._id])))) ?
                                                                                                <p style={{ marginBottom: 0, fontStyle: 'italic', fontWeight: 400, fontSize: 14 }}>{message.message + ' ' + 'deleted a message'.translate(this.props.lang)}</p>
                                                                                                :
                                                                                                message.deletedMessage && (message.uid != this.props.uData._id && message && message.memberLanguages && message.memberLanguages[this.props.uData._id]) ?
                                                                                                    <p style={{ marginBottom: 0, fontStyle: 'italic', fontWeight: 400, fontSize: 14 }}>{message.message}</p>
                                                                                                    :
                                                                                                    null
                                                                                        }
                                                                                        {message.image ? <a target='_blank' href={`/media-gateway?path=${message.image}&cid=${message.conversation}&mid=${message._id}`}><img className="message-image" src={API_ENDPOINT + `${message.image}?mt=${this.props.uData ? this.props.uData.mediaToken : ''}&cid=${message.conversation}&mid=${message._id}`} /></a> : null}
                                                                                        {
                                                                                            message.video ?
                                                                                                <div className="video-in-chat-wrap">
                                                                                                    <video width="260" height="200" controls>
                                                                                                        <source src={API_ENDPOINT + `${message.video}?mt=${this.props.uData ? this.props.uData.mediaToken : ''}&cid=${message.conversation}&mid=${message._id}`} type="video/mp4" />
                                                                                                        <source src={API_ENDPOINT + `${message.video}?mt=${this.props.uData ? this.props.uData.mediaToken : ''}&cid=${message.conversation}&mid=${message._id}`} type="video/ogg" />
                                                                                                    </video>
                                                                                                </div>
                                                                                                :
                                                                                                null
                                                                                        }

                                                                                        {
                                                                                            message.healthCareId ?
                                                                                                <div className="health-care-form-message" onClick={() => this.setState({
                                                                                                    questionaryModal: message.healthCareId,
                                                                                                    questionaryMessageActive: message._id,
                                                                                                    questionarySave: message.healthCareReferral ? true : false,
                                                                                                    activeReferralContent: message.healthCareReferral && message.healthCareReferralObject && message.healthCareReferralObject.healthCareContent ? message.healthCareReferralObject.healthCareContent : null,
                                                                                                    questionaryAnswers: message.healthCareReferral && message.healthCareReferralObject ? message.healthCareReferralObject.answers : {}
                                                                                                },
                                                                                                    () => {
                                                                                                        this.getQuestionary()
                                                                                                    })}>
                                                                                                    {
                                                                                                        !message.hideIcon ?
                                                                                                            <Isvg src={formIcon} />
                                                                                                            :
                                                                                                            null
                                                                                                    }


                                                                                                    {
                                                                                                        message.message.indexOf('The patient filled out the form successfully') > -1 ?
                                                                                                            <>
                                                                                                                {'The patient filled out the form successfully'.translate(this.props.lang) + ' - '} &nbsp;
                                                                                                                <Isvg src={formIcon} />
                                                                                                                {message.message.split('The patient filled out the form successfully - ')[1]}

                                                                                                            </>

                                                                                                            :
                                                                                                            <>
                                                                                                                {
                                                                                                                    message?.message?.split('\n').length ?
                                                                                                                        message.message.split('\n').map((text, t) => {
                                                                                                                            if (t != message.message.split('\n').length - 1)
                                                                                                                                return (
                                                                                                                                    <>{text}<br /></>
                                                                                                                                )
                                                                                                                            else
                                                                                                                                return (
                                                                                                                                    text
                                                                                                                                )
                                                                                                                        })
                                                                                                                        :
                                                                                                                        message.message
                                                                                                                }
                                                                                                            </>


                                                                                                    }

                                                                                                </div>
                                                                                                : null}
                                                                                        {
                                                                                            !message.healthCareId && !message.deletedMessage ?
                                                                                                message.message == 'The patient filled out the form successfully' ?
                                                                                                    message.message.translate(this.props.lang)
                                                                                                    :
                                                                                                    <>
                                                                                                        {
                                                                                                            message.message.split('\n').length ?
                                                                                                                message.message.split('\n').map((text, t) => {
                                                                                                                    if (t != message.message.split('\n').length - 1)
                                                                                                                        return (
                                                                                                                            <>{text}<br /></>
                                                                                                                        )
                                                                                                                    else
                                                                                                                        return (
                                                                                                                            text
                                                                                                                        )
                                                                                                                })
                                                                                                                :
                                                                                                                message.message
                                                                                                        }
                                                                                                    </>
                                                                                                :
                                                                                                null
                                                                                        }
                                                                                        {
                                                                                            message.document ?
                                                                                                <div className={message.uid != this.props.uData._id ? "document incomming-document" : "document"}><a target="_blank" download href={API_ENDPOINT + `${message.document.file}?mt=${this.props.uData ? this.props.uData.mediaToken : ''}&cid=${message.conversation}&mid=${message._id}`}><Isvg src={attach} />{message.document.name}</a></div>
                                                                                                :
                                                                                                null
                                                                                        }

                                                                                        {message.links && message.links.length ? message.links.map(link => <a href={link.link} target='_blank' className={`message-link-container ${this.props.uData?._id === message.uid ? 'message-link-container-right' : ''} ${message.links.length > 1 ? 'message-link-adjust-width-mini' : ''}`} onClick={(e) => e.stopPropagation()}>
                                                                                            <div className='message-link-image'>
                                                                                                <img src={link.image} />
                                                                                            </div>
                                                                                            <div className='message-link-title-and-link'>
                                                                                                <h6>{link.title}</h6>
                                                                                                <p>{link.link}</p>
                                                                                            </div>
                                                                                        </a>) : null}

                                                                                        {message.link ?
                                                                                            <a href={message.link.link} target='_blank' className={`message-link-container message-link-container-mini ${this.props.uData?._id === message.uid ? 'message-link-container-right' : ''}`} onClick={(e) => e.stopPropagation()}>
                                                                                                <div className='message-link-image'>
                                                                                                    <img src={message.link.image} />
                                                                                                </div>
                                                                                                <div className='message-link-title-and-link'>
                                                                                                    <h6>{message.link.title}</h6>
                                                                                                    <p>{message.link.link}</p>
                                                                                                </div>
                                                                                            </a>
                                                                                            : null}

                                                                                    </div>
                                                                                    <div className="date date-out">{this.props.getStringDateTs(message.timestamp, this.props.dateFormat + ' HH:mm')/*moment.unix(message.timestamp).format(`${this.props.dateFormat} HH:mm`)*/}</div>

                                                                                    {
                                                                                        (message.seen && message.seen.filter(seenMember => seenMember.uid != this.props.uData._id).length) && (midx == this.state.chat[item._id].messages.length - 1 || this.state.showSeen[message._id]) ?
                                                                                            <p>
                                                                                                {'Seen by: '.translate(this.props.lang)} {message.seen && message.seen.filter(seenMember => seenMember.uid != this.props.uData._id && seenMember.uid).map((seenMember) => {

                                                                                                    return item && item.users && item.users.filter(user => user.uid == seenMember.uid).length ? item.users.filter(user => user.uid == seenMember.uid)[0].name : ''
                                                                                                }).join(', ')}
                                                                                            </p>
                                                                                            :
                                                                                            null
                                                                                    }

                                                                                </div>
                                                                            </div>
                                                                        )
                                                                    }


                                                                })
                                                            }

                                                            {/* {
                                                                this.state.chat && this.state.chat[item._id] && this.state.chat[item._id].userTyping ?
                                                                    <span className="user-typing">{this.state.chat[item._id].userTyping.name} {'is typing...'.translate(this.props.lang)}</span>
                                                                    :
                                                                    null
                                                            } */}
                                                            {
                                                                this.state.userTyping && this.state.chat && this.state.chat[item._id] && this.state.userTyping[item._id] && this.state.userTyping[item._id][this.state.chatType] && this.state.userTyping[item._id][this.state.chatType].name && this.state.userTyping[item._id][this.state.chatType].id !== this.props.uData._id ?
                                                                    <span className="user-typing">{this.state.userTyping[item._id][this.state.chatType].name} {'is typing...'.translate(this.props.lang)}</span>
                                                                    :
                                                                    null
                                                            }


                                                            {/* / */}
                                                            <div className={this.state.chat[item._id].journalNote ? 'journal-note-wrap show-journal-note' : 'journal-note-wrap'}>

                                                                <div className="journal-note-header" >
                                                                    <div className='title'>
                                                                        <h4>{'Journal notes'.translate(this.props.lang)}</h4>
                                                                    </div>
                                                                    <div className="exit-icon" onClick={() => {
                                                                        let chat = this.state.chat;
                                                                        chat[item._id].journalNote = false;

                                                                        this.setState({ chat }, this.removeFromJorunalNotes)
                                                                    }}>&times;</div>
                                                                </div>
                                                                {
                                                                    item && item.referral && item.referral.intelligentReferral && this.state.countOFSections ?
                                                                        <div className="journal-note-content">
                                                                            <FormGroup>
                                                                                <Label>{'Kontaktorsak'.translate(this.props.lang)}</Label>
                                                                                <Input type='text'
                                                                                    onBlur={() => this.updateJournalNoteOnExit()}
                                                                                    value={item.referral.questionaryName}
                                                                                    onChange={(e) => {
                                                                                        let conversation = item;
                                                                                        conversation.referral.questionaryName = striptags(e.target.value);
                                                                                        this.setState({ conversation }, () => this.generateIntelligentPdf())
                                                                                    }}
                                                                                />
                                                                            </FormGroup>
                                                                            {
                                                                                this.state.journalNoteIntelligentSections && this.state.journalNoteIntelligentSections.map((questionary, i) => {
                                                                                    return (
                                                                                        <FormGroup>
                                                                                            <Label>{questionary.name}</Label>
                                                                                            <Input type='textarea'
                                                                                                value={striptags(questionary.answer)}
                                                                                                onChange={(e) => {
                                                                                                    let journalNoteIntelligentSections = this.state.journalNoteIntelligentSections;
                                                                                                    journalNoteIntelligentSections[i].answer = striptags(e.target.value);
                                                                                                    this.setState({ journalNoteIntelligentSections }, () => this.generateIntelligentPdf())
                                                                                                }}
                                                                                                onBlur={() => this.updateJournalNoteOnExit()}
                                                                                                style={{ height: 150 }} />
                                                                                        </FormGroup>
                                                                                    )
                                                                                })

                                                                            }


                                                                            <div className="journal-note-buttons">

                                                                                {
                                                                                    this.state.htmlJournal ?
                                                                                        <PdfSave lang={this.props.lang} html={this.state.htmlJournal} pdfName={this.generatePdfName()} />

                                                                                        : null
                                                                                }
                                                                                <Button color='primary' onClick={() => {
                                                                                    navigator.clipboard.writeText(this.state.textClipboard)
                                                                                }}>{'Copy text'.translate(this.props.lang)}</Button>
                                                                                <Button color='primary' disabled={true}>{'Export to journal system'.translate(this.props.lang)}</Button>
                                                                            </div>

                                                                        </div>
                                                                        :
                                                                        item && item.referral && (!item.referral.intelligentReferral || (item.referral.intelligentReferral && !this.state.countOFSections)) ?
                                                                            <div className="journal-note-content">
                                                                                <FormGroup>
                                                                                    <Label>{'Kontaktorsak'.translate(this.props.lang)}</Label>
                                                                                    <Input type='text' value={this.state.journalNoteObj && this.state.journalNoteObj.kontaktorsak ? this.state.journalNoteObj.kontaktorsak : item.referral.questionaryName}
                                                                                        onChange={(e) => {
                                                                                            let journalNoteObj = this.state.journalNoteObj;
                                                                                            journalNoteObj.kontaktorsak = striptags(e.target.value);
                                                                                            this.setState({ journalNoteObj }, () => this.generatePdf())
                                                                                        }}
                                                                                        onBlur={() => this.updateJournalNoteOnExit()}
                                                                                    />
                                                                                </FormGroup>
                                                                                <FormGroup>
                                                                                    <Label>{'Status'.translate(this.props.lang)}</Label>
                                                                                    <Input type='textarea' value={this.state.journalNoteObj.status} style={{ height: 100 }} onChange={(e) => {
                                                                                        let journalNoteObj = this.state.journalNoteObj;
                                                                                        journalNoteObj.status = striptags(e.target.value);
                                                                                        this.setState({ journalNoteObj }, () => this.generatePdf())
                                                                                    }}
                                                                                        onBlur={() => this.updateJournalNoteOnExit()}
                                                                                    />
                                                                                </FormGroup>
                                                                                <FormGroup>
                                                                                    <Label>{'Anamnes'.translate(this.props.lang)}</Label>
                                                                                    <Input type='textarea' value={this.state.journalNoteObj.anamnes} style={{ height: 100 }} onChange={(e) => {
                                                                                        let journalNoteObj = this.state.journalNoteObj;
                                                                                        journalNoteObj.anamnes = striptags(e.target.value);
                                                                                        this.setState({ journalNoteObj }, () => this.generatePdf())
                                                                                    }}
                                                                                        onBlur={() => this.updateJournalNoteOnExit()}
                                                                                    />
                                                                                </FormGroup>
                                                                                <FormGroup>
                                                                                    <Label>{'Bedömning'.translate(this.props.lang)}</Label>
                                                                                    <Input type='textarea' value={this.state.journalNoteObj.bedomning} style={{ height: 100 }} onChange={(e) => {
                                                                                        let journalNoteObj = this.state.journalNoteObj;
                                                                                        journalNoteObj.bedomning = striptags(e.target.value);
                                                                                        this.setState({ journalNoteObj }, () => this.generatePdf())
                                                                                    }}
                                                                                        onBlur={() => this.updateJournalNoteOnExit()}
                                                                                    />
                                                                                </FormGroup>
                                                                                <FormGroup>
                                                                                    <Label>{'Åtgärd'.translate(this.props.lang)}</Label>
                                                                                    <Input type='textarea' value={this.state.journalNoteObj.atgard} style={{ height: 100 }} onChange={(e) => {
                                                                                        let journalNoteObj = this.state.journalNoteObj;
                                                                                        journalNoteObj.atgard = striptags(e.target.value);
                                                                                        this.setState({ journalNoteObj }, () => this.generatePdf())
                                                                                    }}
                                                                                        onBlur={() => this.updateJournalNoteOnExit()}
                                                                                    />
                                                                                </FormGroup>

                                                                                <div className="journal-note-buttons">

                                                                                    {
                                                                                        this.state.htmlJournal ?
                                                                                            <PdfSave lang={this.props.lang} html={this.state.htmlJournal} pdfName={this.generatePdfName()} />

                                                                                            : null
                                                                                    }
                                                                                    <Button color='primary' onClick={() => {
                                                                                        navigator.clipboard.writeText(this.state.textClipboard)
                                                                                    }}>{'Copy text'.translate(this.props.lang)}</Button>
                                                                                    <Button color='primary' disabled={true}>{'Export to journal system'.translate(this.props.lang)}</Button>
                                                                                </div>


                                                                            </div>
                                                                            :
                                                                            <div className="journal-note-content">
                                                                                <FormGroup>
                                                                                    <Label>{'Kontaktorsak'.translate(this.props.lang)}</Label>
                                                                                    <Input type='text' value={this.state.journalNoteObj.kontaktorsak}
                                                                                        onChange={(e) => {
                                                                                            let journalNoteObj = this.state.journalNoteObj;
                                                                                            journalNoteObj.kontaktorsak = striptags(e.target.value);
                                                                                            this.setState({ journalNoteObj }, () => this.generatePdf())
                                                                                        }}
                                                                                        onBlur={() => this.updateJournalNoteOnExit()}
                                                                                    />
                                                                                </FormGroup>
                                                                                <FormGroup>
                                                                                    <Label>{'Status'.translate(this.props.lang)}</Label>
                                                                                    <Input type='textarea' value={this.state.journalNoteObj.status} style={{ height: 100 }} onChange={(e) => {
                                                                                        let journalNoteObj = this.state.journalNoteObj;
                                                                                        journalNoteObj.status = striptags(e.target.value);
                                                                                        this.setState({ journalNoteObj }, () => this.generatePdf())
                                                                                    }}
                                                                                        onBlur={() => this.updateJournalNoteOnExit()}
                                                                                    />
                                                                                </FormGroup>
                                                                                <FormGroup>
                                                                                    <Label>{'Anamnes'.translate(this.props.lang)}</Label>
                                                                                    <Input type='textarea' value={this.state.journalNoteObj.anamnes} style={{ height: 100 }} onChange={(e) => {
                                                                                        let journalNoteObj = this.state.journalNoteObj;
                                                                                        journalNoteObj.anamnes = striptags(e.target.value);
                                                                                        this.setState({ journalNoteObj }, () => this.generatePdf())
                                                                                    }}
                                                                                        onBlur={() => this.updateJournalNoteOnExit()}
                                                                                    />
                                                                                </FormGroup>
                                                                                <FormGroup>
                                                                                    <Label>{'Bedömning'.translate(this.props.lang)}</Label>
                                                                                    <Input type='textarea' value={this.state.journalNoteObj.bedomning} style={{ height: 100 }} onChange={(e) => {
                                                                                        let journalNoteObj = this.state.journalNoteObj;
                                                                                        journalNoteObj.bedomning = striptags(e.target.value);
                                                                                        this.setState({ journalNoteObj }, () => this.generatePdf())
                                                                                    }}
                                                                                        onBlur={() => this.updateJournalNoteOnExit()}
                                                                                    />
                                                                                </FormGroup>
                                                                                <FormGroup>
                                                                                    <Label>{'Åtgärd'.translate(this.props.lang)}</Label>
                                                                                    <Input type='textarea' value={this.state.journalNoteObj.atgard} style={{ height: 100 }} onChange={(e) => {
                                                                                        let journalNoteObj = this.state.journalNoteObj;
                                                                                        journalNoteObj.atgard = striptags(e.target.value);
                                                                                        this.setState({ journalNoteObj }, () => this.generatePdf())
                                                                                    }}
                                                                                        onBlur={() => this.updateJournalNoteOnExit()}
                                                                                    />
                                                                                </FormGroup>

                                                                                <div className="journal-note-buttons">

                                                                                    {
                                                                                        this.state.htmlJournal ?
                                                                                            <PdfSave lang={this.props.lang} html={this.state.htmlJournal} pdfName={this.generatePdfName()} />

                                                                                            : null
                                                                                    }
                                                                                    <Button color='primary' onClick={() => {
                                                                                        navigator.clipboard.writeText(this.state.textClipboard)
                                                                                    }}>{'Copy text'.translate(this.props.lang)}</Button>
                                                                                    <Button color='primary' disabled={true}>{'Export to journal system'.translate(this.props.lang)}</Button>
                                                                                </div>


                                                                            </div>
                                                                }
                                                            </div>
                                                        </div>
                                                        {item && !item.closed ?

                                                            <div
                                                                className={
                                                                    this.props.uData && this.props.uData.userLevel == 20 && localStorage.getItem('profile') != 'patient' && item /*&& item.referral*/ && !this.state.chat[item._id].journalNote && (item.users.filter(item => item.level < 20).length || item.referral || item.journalNote) && this.chatWrapRef && false ?
                                                                        "send-message with-phrase with-journal"
                                                                        :
                                                                        this.props.uData && this.props.uData.userLevel == 20 && localStorage.getItem('profile') != 'patient' && item /*&& item.referral*/ && this.state.chat[item._id].journalNote ?
                                                                            "send-message with-phrase with-journal with-journal-open"
                                                                            :
                                                                            this.props.uData && this.props.uData.userLevel == 20 && localStorage.getItem('profile') != 'patient' && item /*&& item.referral*/ && (this.chatWrapRef && true) && (item.users.filter(item => item.level < 20).length || item.referral) ?
                                                                                "send-message with-phrase with-journal chat-wrap-small"
                                                                                :
                                                                                this.props.uData && this.props.uData.userLevel == 20 && localStorage.getItem('profile') != 'patient' ?
                                                                                    "send-message with-phrase" :
                                                                                    "send-message"
                                                                }
                                                            >

                                                                <div className="chat-attach-menu-wrap">

                                                                    <button className='more-icon' onClick={() => {
                                                                        let chat = this.state.chat;
                                                                        chat[item._id].mobileBtnsShow = !chat[item._id].mobileBtnsShow;

                                                                        this.setState({ chat })
                                                                    }}><Isvg src={moreIcon} style={{ position: 'absolute', width: 13, height: 13 }} /></button>
                                                                    <div className={this.state.chat[item._id].mobileBtnsShow ? 'mobile-btns-upload-phrase show-mobile-btns-upload-phrase' : 'mobile-btns-upload-phrase'}>
                                                                        <div style={{ position: 'relative', width: '100%', height: '100%' }}>

                                                                            <div className="overlay-test" onClick={() => {
                                                                                let chat = this.state.chat;
                                                                                chat[item._id].mobileBtnsShow = !chat[item._id].mobileBtnsShow;

                                                                                this.setState({ chat })
                                                                            }} />
                                                                            <button className={this.state._documentUploading == item._id ? 'attach-menu uploading' : this.state.chat && this.state.chat[item._id] && this.state.chat[item._id].document ? 'attach-menu uploaded' : "attach-menu"} onClick={() => {
                                                                                let chat = this.state.chat;
                                                                                chat[item._id].mobileBtnsShow = !chat[item._id].mobileBtnsShow;

                                                                                this.setState({ chat, uploadDocumentChat: item._id })
                                                                                if (this.documentInput) this.documentInput.click()
                                                                            }}>
                                                                                <div className="button-menu-icon-wrap button-menu-icon-wrap-important">
                                                                                    <Isvg src={attach} style={{ position: 'absolute', width: 17, height: 17 }} />
                                                                                </div>
                                                                                <div className='chat-icons-text'>
                                                                                    {'Attach file'.translate(this.props.lang)}
                                                                                </div>
                                                                            </button>


                                                                            <button className={this.state._imageUploading == item._id ? 'image-menu uploading' : this.state.chat && this.state.chat[item._id] && this.state.chat[item._id].image ? 'image-menu uploaded' : "image-menu"} onClick={() => {
                                                                                let chat = this.state.chat;
                                                                                chat[item._id].mobileBtnsShow = !chat[item._id].mobileBtnsShow;

                                                                                this.setState({ chat, uploadImageChat: item._id })
                                                                                if (this.imageInput) this.imageInput.click()
                                                                            }}>
                                                                                <div className="button-menu-icon-wrap button-menu-icon-wrap-important">
                                                                                    <Isvg src={image} style={{ position: 'absolute', width: 17, height: 17 }} />
                                                                                </div>
                                                                                <div className='chat-icons-text'>
                                                                                    {'Attach image'.translate(this.props.lang)}
                                                                                </div>
                                                                            </button>
                                                                            <button className={this.state._videoUploading == item._id ? 'video-menu uploading' : this.state.chat && this.state.chat[item._id] && this.state.chat[item._id].video ? 'video-menu uploaded' : "video-menu"} onClick={() => {
                                                                                let chat = this.state.chat;
                                                                                chat[item._id].mobileBtnsShow = !chat[item._id].mobileBtnsShow;

                                                                                this.setState({ chat, uploadVideoChat: item._id })
                                                                                if (this.videoContentInput) this.videoContentInput.click()
                                                                            }}>
                                                                                <div className="button-menu-icon-wrap button-menu-icon-wrap-important">
                                                                                    <Isvg src={videoIcon} style={{ position: 'absolute', width: 17, height: 17 }} />
                                                                                </div>
                                                                                <div className='chat-icons-text'>
                                                                                    {'Attach video'.translate(this.props.lang)}
                                                                                </div>
                                                                            </button>

                                                                            {
                                                                                this.props.uData && this.props.uData.userLevel == 20 && localStorage.getItem('profile') != 'patient' ?
                                                                                    <button className={"phrase-menu"} onClick={() => {
                                                                                        let chat = this.state.chat;
                                                                                        chat[item._id].mobileBtnsShow = !chat[item._id].mobileBtnsShow;

                                                                                        this.setState({ quickPhraseModal: item._id, chat }, this.get)
                                                                                    }}>
                                                                                        <div className="button-menu-icon-wrap button-menu-icon-wrap-important"><Isvg src={phraseIcon} style={{ position: 'absolute', width: 17, height: 17 }} /></div>

                                                                                        <div className='chat-icons-text'>
                                                                                            {'Quick phrase'.translate(this.props.lang)}
                                                                                        </div>
                                                                                    </button>
                                                                                    :
                                                                                    null
                                                                            }
                                                                            {/* {
                                                                                    this.props.uData && this.props.uData.userLevel == 20 && localStorage.getItem('profile') != 'patient' ?
                                                                                        <button className={"health-care-forms-menu"} onClick={() => {
                                                                                            let chat = this.state.chat;
                                                                                            chat[item._id].mobileBtnsShow = !chat[item._id].mobileBtnsShow;

                                                                                            this.setState({ healthCareModal: item._id, chat })
                                                                                        }}>
                                                                                            <div className="button-menu-icon-wrap"><Isvg src={formIcon} style={{ position: 'absolute', width: 17, height: 17 }} /></div>

                                                                                            <div className='chat-icons-text'>
                                                                                                {'Attach form'.translate(this.props.lang)}
                                                                                            </div>
                                                                                        </button>
                                                                                        :
                                                                                        null
                                                                                } */}
                                                                            {/* {
                                                                                this.props.uData && this.props.uData.userLevel == 20 && item && (item.users.filter(item => item.level < 20).length || item.referral || item.journalNote) && localStorage.getItem('profile') != 'patient' ?
                                                                                    <button className={"journal-menu"} onClick={() => {
                                                                                        let chat = this.state.chat;
                                                                                        chat[item._id].mobileBtnsShow = !chat[item._id].mobileBtnsShow;

                                                                                        this.setState({ chat })
                                                                                        this.findHealthCareFormular(item._id)
                                                                                    }}>
                                                                                        <div className="button-menu-icon-wrap">
                                                                                            <Isvg src={journalIcon} style={{ position: 'absolute', width: 17, height: 17 }} />
                                                                                        </div>
                                                                                        <div className='chat-icons-text'>
                                                                                            {'Journal note'.translate(this.props.lang)}
                                                                                        </div>
                                                                                    </button>
                                                                                    :
                                                                                    null
                                                                            } */}
                                                                        </div>
                                                                    </div>
                                                                </div>



                                                                <Input className='send-message-textarea' onKeyDown={(e) => {
                                                                    if (e.keyCode == 13 && !e.shiftKey) {
                                                                        e.preventDefault();
                                                                        this.sendMessage(item._id);
                                                                        return false;
                                                                    }
                                                                    this.emitUserTyping(item)
                                                                }} placeholder={"Write a message...".translate(this.props.lang)}
                                                                    type="textarea"
                                                                    id="message"
                                                                    onChange={(e) => {
                                                                        let element = e.target;
                                                                        let chat = this.state.chat;
                                                                        chat[item._id].message = e.target.value;

                                                                        let messageHeight = this.state.messageHeight;
                                                                        if (!messageHeight) {
                                                                            messageHeight = {}
                                                                        }
                                                                        messageHeight[item._id] = 64;
                                                                        messageHeight[item._id] = element.scrollHeight < 220 ? element.scrollHeight : 220;

                                                                        this.setState({ messageHeight, chat }, () => {
                                                                            // let messageHeight = this.state.messageHeight;
                                                                            // messageHeight[item._id] = element.scrollHeight < 220 ? element.scrollHeight : 220;
                                                                            // this.setState({ messageHeight })

                                                                        })

                                                                    }}
                                                                    value={this.state.chat && this.state.chat[item._id] && this.state.chat[item._id].message}
                                                                    style={{ maxHeight: 220, minHeight: 64, height: this.state.messageHeight && this.state.chat && this.state.messageHeight[item._id] && this.state.chat[item._id] && this.state.chat[item._id].message ? this.state.messageHeight[item._id] : 64 }}
                                                                />
                                                                {item && item.supportedLanguages && item.supportedLanguages.length && this.props.uData.clinicGroups[selectedGroupIdx] && this.props.uData.clinicGroups[selectedGroupIdx].haveTranslation ?

                                                                    <UncontrolledDropdown className={this.state.chat && this.state.chat[item._id] && this.state.chat[item._id].conversation && this.state.chat[item._id].conversation.memberLanguages && this.state.chat[item._id].conversation.memberLanguages[this.props.uData._id] ? "translate translate-active" : "translate"}>
                                                                        <DropdownToggle>
                                                                            <Isvg src={translateIcon} />
                                                                            <div className='chat-icons-tooltip'>
                                                                                {'Translation is done by Google and may not work properly!'.translate(this.props.lang)}
                                                                            </div>
                                                                        </DropdownToggle>
                                                                        <DropdownMenu>
                                                                            <DropdownItem className={item && item.memberLanguages && !item.memberLanguages[this.props.uData._id] ? 'active' : ''} onClick={() => this.setTranslationLanguage(null, item._id)} >{'No translate'.translate(this.props.lang)}</DropdownItem>

                                                                            {


                                                                                item.supportedLanguages.map((lang, lidx) => {

                                                                                    return (

                                                                                        <DropdownItem className={item && item.memberLanguages && item.memberLanguages[this.props.uData._id] == lang.code ? 'active' : ''} onClick={() => this.setTranslationLanguage(lang.code, item._id)} key={lidx}>{lang.name}</DropdownItem>

                                                                                    )
                                                                                })

                                                                            }
                                                                        </DropdownMenu>
                                                                    </UncontrolledDropdown>
                                                                    :
                                                                    null
                                                                }
                                                                <div style={{ position: 'absolute', top: 23, right: 58, width: 18, rotate: '-90deg' }} onMouseDown={() => {
                                                                    this.setState({
                                                                        messageRisaze: true
                                                                    })
                                                                }} onMouseUp={() => {
                                                                    this.setState({
                                                                        messageRisaze: false
                                                                    })
                                                                }}>
                                                                    <Isvg src={resizeMessageBox} />
                                                                </div>
                                                                <button disabled={this.state._imageUploading} className="send" onClick={() => this.sendMessage(item._id)}>
                                                                    <Isvg src={send} />
                                                                    <div className='chat-icons-tooltip'>
                                                                        {'Send'.translate(this.props.lang)}
                                                                    </div>
                                                                </button>
                                                            </div>
                                                            :
                                                            null
                                                        }
                                                        {item && item.closed && this.props.uData && this.props.uData.userLevel >= 20 && localStorage.getItem('profile') == 'doctor' && item && item.members && item.members[0] == this.props.uData._id ?
                                                            <div className='delete-conversation-wrap'>
                                                                <Button color='danger' onClick={() => this.setState({ deleteConversationModal: item })}>{'Delete conversation'.translate(this.props.lang)}</Button>
                                                            </div>
                                                            :
                                                            null
                                                        }
                                                    </div>
                                                </div>
                                                {this.state.profileModal && this.state.profileModal.conversation == item._id ?
                                                    <Modal isOpen={this.state.profileModal} centered zIndex={999999999}>
                                                        <ModalHeader toggle={() => this.setState({ profileModal: !this.state.profileModal })} close={<button type="button" className="close" style={{ position: 'absolute', top: '15px', right: '15px' }} onClick={() => this.setState({ profileModal: !this.state.profileModal })}>&times;</button>}>
                                                            <h4>{'Patient profile'.translate(this.props.lang)}</h4>
                                                        </ModalHeader>
                                                        <ModalBody>
                                                            <FormGroup>
                                                                <Label>{'Social security number'.translate(this.props.lang)}</Label>
                                                                <Input type='text' disabled
                                                                    value={this.state.profileModal.socialSecurityNumber} />
                                                            </FormGroup>
                                                            <FormGroup>
                                                                <Label>{'Name'.translate(this.props.lang)}</Label>
                                                                <Input type='text' disabled
                                                                    value={this.state.profileModal.userData && this.state.profileModal.userData.name} />
                                                            </FormGroup>
                                                            <FormGroup>
                                                                <Label>{'Email'.translate(this.props.lang)}</Label>
                                                                <Input type='text' disabled
                                                                    value={this.state.profileModal.parents ? this.state.profileModal.parents[0].email : this.state.profileModal.email} />
                                                            </FormGroup>
                                                            <FormGroup>
                                                                <Label>{'Phone'.translate(this.props.lang)}</Label>
                                                                <Input type='text' disabled
                                                                    value={this.state.profileModal.parents ? this.state.profileModal.parents[0].phone : this.state.profileModal.phone} />
                                                            </FormGroup>
                                                            <FormGroup>
                                                                <Label>{'Address'.translate(this.props.lang)}</Label>
                                                                <Input type='text' disabled
                                                                    value={this.state.profileModal.parents ? this.state.profileModal.parents[0].address : this.state.profileModal.street + ',' + ' ' + this.state.profileModal.zip + ' ' + this.state.profileModal.city} />
                                                            </FormGroup>


                                                        </ModalBody>
                                                        <ModalFooter className='buttons-right-blue'>
                                                            <Button color="primary" onClick={() => this.setState({ profileModal: !this.state.profileModal })}>{'Close'.translate(this.props.lang)}</Button>

                                                        </ModalFooter>
                                                    </Modal>

                                                    : null}
                                                {/* <input ref={(node) => this.documentInput = node} type="file" accept=".doc, .docx, .pdf" key={this.state.uploadTimestampDoc} onChange={this.uploadDocument} style={{ pointerEvents: 'none', opacity: 0 }} />
                                                <input ref={(node) => this.imageInput = node} type="file" accept="image/png, image/jpeg, image/jpg, image/gif, image/svg" key={this.state.uploadTimestamp} onChange={this.uploadImage} style={{ pointerEvents: 'none', opacity: 0 }} />
                                                <input ref={(node) => this.videoContentInput = node} type="file" accept=".mp4,.mov" key={this.state.uploadTimestampVideo} onChange={this.uploadVideo} style={{ pointerEvents: 'none', opacity: 0 }} /> */}
                                                <input
                                                    ref={(node) => this.documentInput = node}
                                                    type="file" accept=".doc, .docx, .pdf"
                                                    key={this.state.uploadTimestampDoc}
                                                    onChange={(e) => {
                                                        let input = e?.target;
                                                        let fileName = input?.files && input?.files[0] && input?.files[0].name ? input?.files[0].name : null;
                                                        let extension = fileName && fileName.indexOf('.') !== -1 && fileName.split('.')[1] ? fileName.split('.')[1] : null;
                                                        extension = extension?.toLowerCase();
                                                        let type = 'document';
                                                        for (var key in UPLOAD_EXTENSIONS) {
                                                            if (UPLOAD_EXTENSIONS[key] && UPLOAD_EXTENSIONS[key].indexOf(extension) !== -1) {
                                                                type = key;
                                                            }
                                                        }
                                                        if (type === 'video') this.uploadVideo(e);
                                                        else if (type === 'image') this.uploadImage(e);
                                                        else this.uploadDocument(e)

                                                    }}
                                                    style={{ pointerEvents: 'none', opacity: 0 }}
                                                />
                                                <input
                                                    ref={(node) => this.imageInput = node}
                                                    type="file"
                                                    accept="image/png, image/jpeg, image/jpg, image/gif, image/svg"
                                                    key={this.state.uploadTimestamp}
                                                    onChange={(e) => {
                                                        let input = e?.target;
                                                        let fileName = input?.files && input?.files[0] && input?.files[0].name ? input?.files[0].name : null;
                                                        let extension = fileName && fileName.indexOf('.') !== -1 && fileName.split('.')[1] ? fileName.split('.')[1] : null;
                                                        extension = extension?.toLowerCase();
                                                        let type = 'image';
                                                        for (var key in UPLOAD_EXTENSIONS) {
                                                            if (UPLOAD_EXTENSIONS[key] && UPLOAD_EXTENSIONS[key].indexOf(extension) !== -1) {
                                                                type = key;
                                                            }
                                                        }
                                                        if (type === 'video') this.uploadVideo(e);
                                                        else if (type === 'document') this.uploadDocument(e);
                                                        else this.uploadImage(e)

                                                    }}
                                                    style={{ pointerEvents: 'none', opacity: 0 }}
                                                />
                                                <input
                                                    ref={(node) => this.videoContentInput = node}
                                                    type="file"
                                                    accept=".mp4, .avi, .mov"
                                                    key={this.state.uploadTimestampVideo}
                                                    onChange={(e) => {
                                                        let input = e?.target;
                                                        let fileName = input?.files && input?.files[0] && input?.files[0].name ? input?.files[0].name : null;
                                                        let extension = fileName && fileName.indexOf('.') !== -1 && fileName.split('.')[1] ? fileName.split('.')[1] : null;
                                                        extension = extension?.toLowerCase();
                                                        let type = 'video';
                                                        for (var key in UPLOAD_EXTENSIONS) {
                                                            if (UPLOAD_EXTENSIONS[key] && UPLOAD_EXTENSIONS[key].indexOf(extension) !== -1) {
                                                                type = key;
                                                            }
                                                        }
                                                        if (type === 'image') this.uploadImage(e);
                                                        else if (type === 'document') this.uploadDocument(e);
                                                        else this.uploadVideo(e)

                                                    }}
                                                    style={{ pointerEvents: 'none', opacity: 0 }}
                                                />
                                                {
                                                    this.state.quickPhraseModal && this.state.quickPhraseModal == item._id ?
                                                        <Modal isOpen={this.state.quickPhraseModal} size="lg" centered zIndex={99999999}>
                                                            <ModalHeader toggle={() => this.setState({ quickPhraseModal: !this.state.quickPhraseModal, searchPhrases: '' })} close={<button type="button" className="close" style={{ position: 'absolute', top: '15px', right: '15px' }} onClick={() => this.setState({ quickPhraseModal: !this.state.quickPhraseModal, searchPhrases: '' })}>&times;</button>}>{'Quick phrases'.translate(this.props.lang)}</ModalHeader>

                                                            <ModalBody>

                                                                <div className="search-box-wrap">
                                                                    <FormGroup>
                                                                        <Label>{'Search'.translate(this.props.lang)}</Label>
                                                                        <Input value={this.state.searchPhrases}
                                                                            onChange={(e) => {
                                                                                this.setState({ searchPhrases: striptags(e.target.value) }, () => this.get())
                                                                            }}
                                                                        />
                                                                    </FormGroup>
                                                                </div>

                                                                {
                                                                    this.state.phrases && this.state.phrases.map((phrase, idx) => {
                                                                        return (
                                                                            <div className="quick-phrase-wrap">
                                                                                <div className="content">
                                                                                    <div className="title">{phrase.title}</div>
                                                                                    <div className="phrase">
                                                                                        {
                                                                                            phrase.phrase.split('\n').length ?
                                                                                                phrase.phrase.split('\n').map((text, t) => {
                                                                                                    if (t != phrase.phrase.split('\n').length - 1)
                                                                                                        return (
                                                                                                            <>{text}<br /></>
                                                                                                        )
                                                                                                    else
                                                                                                        return (
                                                                                                            text
                                                                                                        )
                                                                                                })
                                                                                                :
                                                                                                phrase.phrase
                                                                                        }
                                                                                    </div>
                                                                                </div>
                                                                                <Button color='primary' onClick={() => {
                                                                                    let chat = this.state.chat;
                                                                                    chat[this.state.quickPhraseModal].message = phrase.phrase;
                                                                                    this.setState({ chat, quickPhraseModal: false, searchPhrases: '' })
                                                                                }}>{'Use'.translate(this.props.lang)}</Button>

                                                                            </div>
                                                                        )
                                                                    })
                                                                }

                                                            </ModalBody>

                                                            <ModalFooter style={{ display: 'flex', justifyContent: 'flex-end' }}>

                                                                <Button color="primary" onClick={() => this.setState({ quickPhraseModal: null, searchPhrases: '' })}> {'Close'.translate(this.props.lang)} </Button>

                                                            </ModalFooter>



                                                        </Modal>
                                                        :
                                                        null
                                                }
                                                {
                                                    this.state.healthCareModal && this.state.healthCareModal == item._id ?
                                                        <Modal isOpen={this.state.healthCareModal} size="lg" centered zIndex={99999999}>
                                                            <ModalHeader toggle={() => this.setState({ healthCareModal: !this.state.healthCareModal })} close={<button type="button" className="close" style={{ position: 'absolute', top: '15px', right: '15px' }} onClick={() => this.setState({ healthCareModal: !this.state.healthCareModal })}>&times;</button>}>{'General health-care forms'.translate(this.props.lang)}</ModalHeader>

                                                            <ModalBody>
                                                                <div className="search-box-wrap">
                                                                    <FormGroup>
                                                                        <Label>{'Search'.translate(this.props.lang)}</Label>
                                                                        <Input value={this.state.searchHealthCare}
                                                                            onChange={(e) => {
                                                                                this.setState({ searchHealthCare: striptags(e.target.value) }, () => this.get())
                                                                            }}
                                                                        />
                                                                    </FormGroup>
                                                                </div>
                                                                <div className="general-health-care-wrap">
                                                                    {
                                                                        this.state.generalHealthCare && this.state.generalHealthCare.items && this.state.generalHealthCare.items.map((healthCare, idx) => {
                                                                            return (
                                                                                <div className="health-care-wrap">
                                                                                    <div className="content">
                                                                                        <div className="title">
                                                                                            {healthCare.name}
                                                                                            <span>
                                                                                                &nbsp;&nbsp;/&nbsp;&nbsp;
                                                                                                {healthCare.description}</span>
                                                                                        </div>

                                                                                    </div>
                                                                                    <Button color='primary' onClick={() => {
                                                                                        this.sendHealthCare(healthCare)
                                                                                    }}>{'Send'.translate(this.props.lang)}</Button>

                                                                                </div>
                                                                            )
                                                                        })
                                                                    }
                                                                </div>


                                                            </ModalBody>

                                                            <ModalFooter style={{ display: 'flex', justifyContent: 'flex-end' }}>

                                                                <Button color="primary" onClick={() => this.setState({ healthCareModal: null })}> {'Close'.translate(this.props.lang)} </Button>

                                                            </ModalFooter>



                                                        </Modal>
                                                        :
                                                        null
                                                }
                                                {
                                                    this.state.questionaryModal ?
                                                        <Modal isOpen={this.state.questionaryModal} size="lg" centered zIndex={99999999}>
                                                            <ModalHeader toggle={() => this.setState({ questionaryModal: null, settedRef: false, referralContentRef: null, activeQuestionary: null, questionaryMessageActive: null, activeReferralContent: null, sections: null, validationQuestionary: false, validationIntelligentQuestionary: false })} close={<button type="button" className="close" style={{ position: 'absolute', top: '15px', right: '15px' }} onClick={() => this.setState({ questionaryModal: null, settedRef: false, activeQuestionary: null, questionaryMessageActive: null, activeReferralContent: null, sections: null, validationQuestionary: false, validationIntelligentQuestionary: false })}>&times;</button>}>{this.state.activeReferralContent ? ''.translate(this.props.lang) : 'Run questionary'.translate(this.props.lang)}</ModalHeader>

                                                            <ModalBody>


                                                                {
                                                                    // this.state.activeReferralContent ?
                                                                    <div ref={(node) => { if (!this.state.referralContentRef) this.setState({ referralContentRef: node }, this.forceUpdate) }}>
                                                                        <div dangerouslySetInnerHTML={{ __html: this.state.activeReferralContent }} onChange={() => { }}></div>

                                                                        <br />
                                                                    </div>

                                                                    // :
                                                                    // null
                                                                }
                                                                {
                                                                    this.state.activeQuestionary ?
                                                                        <>
                                                                            {
                                                                                !this.state.activeQuestionary.intelligentReferral ?
                                                                                    // this.state.activeReferralContent && this.state.referralContentRef ?
                                                                                    <CreateReferralModal {...this.props} answer={(item) => {
                                                                                        this.setState({ questionaryAnswers: item })
                                                                                    }} referral={{ name: this.state.activeQuestionary.name, description: this.state.activeQuestionary.description }}
                                                                                        referralContentRef={this.state.referralContentRef}

                                                                                        disabledForm={typeof window != 'undefined' && localStorage?.getItem?.('profile') == 'doctor' && this.props.uData && this.props.uData.userLevel == 20 ? true : false}
                                                                                        answers={this.state.questionaryAnswers}
                                                                                        forPrint={this.state.activeReferralContent ? true : false}
                                                                                        sections={this.generateSections()}></CreateReferralModal>
                                                                                    // :
                                                                                    // null
                                                                                    :
                                                                                    // this.state.referralContentRef ?
                                                                                    <CreateReferralIntelligentModal {...this.props}
                                                                                        referral={{ name: this.state.activeQuestionary.name, description: this.state.activeQuestionary.description }}
                                                                                        answer={(item, isEnd) => {
                                                                                            this.setState({ questionaryAnswers: item, validationIntelligentQuestionary: !isEnd, validationQuestionary: false })
                                                                                        }}
                                                                                        referralContentRef={this.state.referralContentRef}

                                                                                        disabledForm={typeof window != 'undefined' && localStorage?.getItem?.('profile') == 'doctor' && this.props.uData && this.props.uData.userLevel == 20 ? true : false}
                                                                                        answers={this.state.questionaryAnswers}
                                                                                        forPrint={this.state.activeReferralContent ? true : false}
                                                                                        sections={this.generateSections()}></CreateReferralIntelligentModal>
                                                                                // :
                                                                                // null
                                                                            }
                                                                        </>

                                                                        :
                                                                        null
                                                                }

                                                                {
                                                                    this.state.validationQuestionary ?
                                                                        <p style={{ color: 'red' }}>{'Please fill in all fields'.translate(this.props.lang)}</p>
                                                                        :
                                                                        null
                                                                }
                                                                {
                                                                    this.state.activeQuestionary && this.state.activeReferralContent && this.state.questionaryAnswers ?

                                                                        <div style={{ position: 'fixed', right: '100%' }}>
                                                                            <div ref={(node) => { this.referralRef = node; if (!this.state.settedRef) this.setState({ settedRef: true }) }} className="print-referral-ref">


                                                                                {
                                                                                    this.state.activeReferralContent ?
                                                                                        <div ref={(node) => { if (!this.state.referralContentRef) this.setState({ referralContentRef: node }, this.forceUpdate) }}>

                                                                                            <div dangerouslySetInnerHTML={{ __html: this.state.activeReferralContent }} onChange={() => { }}></div>

                                                                                            <br />

                                                                                        </div>

                                                                                        :
                                                                                        null
                                                                                }
                                                                                {
                                                                                    !this.state.activeQuestionary.intelligentReferral ?

                                                                                        this.state.activeReferralContent && this.state.referralContentRef ?
                                                                                            <CreateReferralModal
                                                                                                {...this.props}
                                                                                                answer={(item) => {
                                                                                                    this.setState({ questionaryAnswers: item })
                                                                                                }}
                                                                                                referralContentRef={this.state.referralContentRef}
                                                                                                referral={{ name: this.state.activeQuestionary.name, description: this.state.activeQuestionary.description }}
                                                                                                disabledForm={typeof window != 'undefined' && localStorage?.getItem?.('profile') == 'doctor' && this.props.uData && this.props.uData.userLevel == 20 ? true : false}
                                                                                                answers={this.state.questionaryAnswers}
                                                                                                forPrint={this.state.activeReferralContent ? true : false}
                                                                                                sections={this.generateSections()}></CreateReferralModal>
                                                                                            :
                                                                                            null

                                                                                        :
                                                                                        this.state.referralContentRef ?
                                                                                            <CreateReferralIntelligentModal
                                                                                                {...this.props}
                                                                                                answer={(item, isEnd) => {
                                                                                                    this.setState({ questionaryAnswers: item, validationIntelligentQuestionary: !isEnd, validationQuestionary: false })
                                                                                                }}
                                                                                                referralContentRef={this.state.referralContentRef}

                                                                                                referral={{ name: this.state.activeQuestionary.name, description: this.state.activeQuestionary.description }}
                                                                                                disabledForm={true}
                                                                                                answers={this.state.questionaryAnswers}
                                                                                                forPrint={true}
                                                                                                sections={this.generateSections()}></CreateReferralIntelligentModal>
                                                                                            :
                                                                                            null

                                                                                }

                                                                            </div>

                                                                        </div>
                                                                        :
                                                                        null
                                                                }
                                                            </ModalBody>

                                                            <ModalFooter style={{ display: 'flex', justifyContent: 'flex-end' }} >
                                                                {
                                                                    !this.state.questionarySave && ((this.props.uData && this.props.uData.userLevel == 1) || (this.props.uData && this.props.uData.userLevel == 2) || ((typeof window != 'undefined' && localStorage?.getItem?.('profile') == 'patient') && (this.props.uData && this.props.uData.userLevel == 20))) ?
                                                                        <Button color="primary" onClick={() => this.sendHealthCareQuestionary()}> {'Send'.translate(this.props.lang)} </Button>
                                                                        :
                                                                        null
                                                                }
                                                                {
                                                                    this.state.activeReferralContent && this.referralRef && this.state.settedRef ?
                                                                        <PrintHelper lang={this.props.lang} element={this.referralRef} />
                                                                        :
                                                                        null
                                                                }
                                                                {
                                                                    this.state.activeReferralContent && this.referralRef && this.state.settedRef ?
                                                                        <PdfSaveV2 lang={this.props.lang} element={this.referralRef} pdfName={this.state.activeQuestionary && this.state.activeQuestionary.name && generateAlias(this.state.activeQuestionary.name) + '_' + moment.unix(Math.floor(new Date().getTime() / 1000)).format(this.props.dateFormat)} />
                                                                        :
                                                                        null
                                                                }
                                                                <Button color="primary" onClick={() => this.setState({ questionaryModal: null, settedRef: false, referralContentRef: null, activeQuestionary: null, questionaryMessageActive: null, activeReferralContent: null, sections: null, validationQuestionary: false, validationIntelligentQuestionary: false })}> {'Close'.translate(this.props.lang)} </Button>

                                                            </ModalFooter>



                                                        </Modal>
                                                        :
                                                        null
                                                }
                                                {this.state.referral && this.state.referral._id == item._id && this.state.referral.referral ?
                                                    <Modal isOpen={this.state.referral} size="lg" centered zIndex={99999999}>
                                                        <ModalHeader toggle={() => this.setState({ referral: !this.state.referral })} close={<button type="button" className="close" style={{ position: 'absolute', top: '15px', right: '15px' }} onClick={() => this.setState({ referral: !this.state.referral })}>&times;</button>}>{'Referral preview'.translate(this.props.lang)}</ModalHeader>

                                                        <ModalBody>
                                                            <div dangerouslySetInnerHTML={{ __html: this.state.referral.referral.referralContent }} onChange={() => { }}></div>
                                                            <div>
                                                                <p> <strong>{'Status:'.translate(this.props.lang)}</strong> {humanReadable[this.state.referral.referral.status].translate(this.props.lang)}</p>
                                                                {this.state.referral.referral.reason ?
                                                                    <p> <strong>{'Reason:'.translate(this.props.lang)}</strong> {this.state.referral.referral.reason ? this.state.referral.referral.reason : ''}</p>
                                                                    :
                                                                    null}
                                                                {this.state.referral.referral.patientReservedTimestamp ?
                                                                    <p> <strong>{'Scheduled time:'.translate(this.props.lang)}</strong> {moment.unix(this.state.referral.referral.patientReservedTimestamp).format(`${this.props.dateFormat} HH:mm`)}</p>
                                                                    :
                                                                    null}

                                                                {
                                                                    this.state.referral.referral.status == 'patient-requested-another-time' ?
                                                                        <p> <strong>{'Requested time:'.translate(this.props.lang)}</strong> {moment.unix(this.state.referral.referral.patientRequestedTimestamp).format(`${this.props.dateFormat} HH:mm`)}</p>

                                                                        :
                                                                        null
                                                                }

                                                            </div>

                                                        </ModalBody>

                                                        <ModalFooter>

                                                            <Button color="primary" onClick={() => this.setState({ referral: null })}> {'Close'.translate(this.props.lang)} </Button>

                                                        </ModalFooter>



                                                    </Modal>
                                                    :
                                                    null
                                                }
                                                <DeleteModal
                                                    lang={this.props.lang}
                                                    zIndex={99999999999}
                                                    isOpen={this.state.deleteMessageModal}
                                                    toggle={() => this.setState({ deleteMessageModal: null })}
                                                    handler={() => { this.deleteMessage(this.state.deleteMessageModal); this.setState({ deleteMessageModal: null }) }}
                                                >
                                                    {'Are you sure you want to delete the selected message?'.translate(this.props.lang)}
                                                </DeleteModal>
                                                <DeleteModal
                                                    lang={this.props.lang}
                                                    zIndex={99999999999}
                                                    isOpen={this.state.closeCaseModal && this.state.closeCaseModal._id == item._id}
                                                    toggle={() => this.setState({ closeCaseModal: null })}
                                                    handler={() => { this.closeCase(this.state.closeCaseModal); this.setState({ closeCaseModal: null }) }}
                                                >
                                                    {'Do you want to close the case and conversation'.translate(this.props.lang)} <strong>{this.state.closeCaseModal && this.state.closeCaseModal.name}</strong>?
                                                </DeleteModal>
                                                <DeleteModal
                                                    lang={this.props.lang}
                                                    zIndex={99999999999}
                                                    isOpen={this.state.closeModal && this.state.closeModal._id == item._id}
                                                    toggle={() => this.setState({ closeModal: null })}
                                                    handler={() => { this.close(this.state.closeModal._id); this.setState({ closeModal: null }) }}
                                                >
                                                    {'Close conversation'.translate(this.props.lang)} <strong>{this.state.closeModal && this.state.closeModal.name}</strong>?
                                                </DeleteModal>

                                            </div>

                                        )

                                    })
                                }

                            </div>
                            :
                            null
                    }
                </div>
            )
        } else if (!isMobile)
            return (
                <div className="chat-component-wrap">
                    {this.state.error ?

                        <ErrorModal lang={this.props.lang}
                            isOpen={this.state.error}
                            toggle={() => this.setState({ error: null })}
                            zIndex={99999999999}
                        >
                            {this.state.error.translate(this.props.lang)}
                        </ErrorModal>

                        :
                        null
                    }
                    <div className={this.state.conversations && this.state.conversations.filter(conversation => !conversation.closed && conversation.chatOneOnOne && !conversation.patient && conversation.members && this.state.chatUsers && this.state.chatUsers.filter(user => user._id != this.props.uData._id && conversation.members.filter(member => member == user._id).length).length && conversation._newMessage && conversation.groupIds && conversation.groupIds.indexOf(this.props.selectedGroup) != -1).length ? "chat-list chat-list-new-message" : "chat-list"} onClick={() => {
                        if (this.state.chatUsers && this.state.chatUsers.length)
                            this.setState({ openChatList: !this.state.openChatList })
                    }}>
                        {/* <h6> {'Chat'.translate(this.props.lang)} ({this.state.conversations && this.state.conversations.length && this.state.conversations.filter(item => (this.props.selectedGroup && item.groupIds && item.groupIds.indexOf(this.props.selectedGroup) !== -1 && !item.closed) || (!item.closed && item.groupAlias && localStorage.groupAlias && item.groupAlias.indexOf(localStorage.getItem('groupAlias')) != -1)).length})</h6> */}
                        <h6 className="noselect"> {'Chat'.translate(this.props.lang)} ({this.state.chatUsers && this.state.chatUsers.length && this.state.chatUsers.filter(item => item._id != this.props.uData._id).length})</h6>
                        {/* {
                        this.state.conversations && this.state.conversations.filter(conversation => !conversation.closed && conversation.chatOneOnOne && conversation.members && conversation._newMessage).length ?
                            <div style={{ width: 12, height: 12, borderRadius: '50%', backgroundColor: '#dc3545', marginLeft: 10 }}></div>
                            // <Isvg src={newMessage} className="new-message-icon" style={{  marginLeft: 10, marginTop: -5 }} />

                            :
                            null
                    } */}
                    </div>
                    {
                        this.state.openChatList && this.state.chatUsers && this.state.chatUsers.length && this.state.chatUsers.filter(item => item._id != this.props.uData._id).length ?
                            <div className="opened-chat-list" style={{ top: typeof window != 'undefined' && window.innerWidth > 1199 ? 0 : 70, borderTop: typeof window != 'undefined' && window.innerWidth > 1199 ? 'none' : '1px solid #fff' }}>
                                <div className="opened-chat-list-header">
                                    <h4 className="noselect">{'Chat'.translate(this.props.lang)}</h4>

                                </div>
                                {/* <div className="opened-chat-list-section">
                                <h5>{'Active'.translate(this.props.lang)}</h5>
                            </div> */}
                                <div style={{ overflowY: 'scroll', height: '100%', paddingBottom: 42 }}>
                                    {
                                        this.state.chatUsers.map((item, idx) => {

                                            if (item._id != this.props.uData._id && (!this.state.searchChatUsers || (this.state.searchChatUsers && item.name.toLowerCase().indexOf(this.state.searchChatUsers.toLowerCase()) !== -1))) {
                                                return (
                                                    <div className="opened-chat-list-item-wrap" onClick={() => {
                                                        this.openChat(item._id)

                                                    }}>
                                                        <div className="opened-chat-list-item">
                                                            {/* {item.name}<br />
                                                        <span> {item.referral && item.referral.patient ? item.referral.patient.socialSecurityNumber : item.socialSecurityNumber ? item.socialSecurityNumber : null} </span>
                                                        <h5 className="chat-members">
                                                            {item.users && item.users.map((user, idx) => {
                                                                return (

                                                                    <img src={user.profilePicture ? (API_ENDPOINT + user.profilePicture) : profilePicture} />

                                                                )
                                                            })}
                                                        </h5>

                                                        {
                                                            item._newMessage ?

                                                                <Isvg src={newMessage} className="new-message-icon" />

                                                                :

                                                                null

                                                        }
                                                          if (!this.state.conversations[i].closed && this.state.conversations[i].chatOneOnOne && this.state.conversations[i].members && this.state.conversations[i].members.length && this.state.conversations[i].members.filter(conv => conv == item).length) {
                                      conversation = this.state.conversations[i];
                                        }
                                                        
                                            */}

                                                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                                                <img src={item.profilePicture ? (API_ENDPOINT + item.profilePicture) : profilePicture} />

                                                                <div style={{ paddingRight: 15, lineHeight: '21px' }}>
                                                                    {item.name}

                                                                    {
                                                                        this.state.conversations && this.state.conversations.filter(conversation => !conversation.closed && conversation.chatOneOnOne && !conversation.patient && conversation.members && (conversation.members[0] == item._id || conversation.members[1] == item._id) && conversation._newMessage && conversation.groupIds && conversation.groupIds.indexOf(this.props.selectedGroup) != -1).length ?
                                                                            <Isvg src={newMessage} className="new-message-icon" style={{ marginTop: -8, marginLeft: 5 }} />
                                                                            :
                                                                            null
                                                                    }

                                                                </div>
                                                            </div>
                                                            <div className={item.online ? 'user-online' : 'user-offline'}></div>

                                                        </div>
                                                    </div>

                                                )
                                            }
                                        })
                                    }
                                </div>

                                {/* <div className="opened-chat-list-section">
                                <h5>{'History'.translate(this.props.lang)}</h5>
                            </div>
                            {
                                this.state.conversations.map((item, idx) => {
                                    if (this.props.selectedGroup && item.groupIds && item.groupIds.indexOf(this.props.selectedGroup) !== -1 && item.closed) {
                                        return (
                                            <div className="opened-chat-list-item-wrap">

                                                <div className="opened-chat-list-item">
                                                    {item.name}<br />
                                                    <span> {item.referral && item.referral.patient ? item.referral.patient.socialSecurityNumber : item.socialSecurityNumber ? item.socialSecurityNumber : null} </span>
                                                  
                                                    {
                                                        item._newMessage ?

                                                            <Isvg src={newMessage} className="new-message-icon" />

                                                            :

                                                            null

                                                    }


                                                </div>
                                            </div>
                                        )
                                    }
                                })
                            } */}
                                <div className="opened-chat-list-search-wrap">
                                    <Input type='text' value={this.state.searchChatUsers} placeholder={"Search...".translate(this.props.lang)} onChange={(e) => {
                                        this.setState({ searchChatUsers: e.target.value })
                                    }}
                                    />
                                </div>
                            </div>
                            :
                            null
                    }
                    {
                        this.state.clickedChats && this.state.clickedChats.length ?
                            <div className="clicked-chats-wrap">
                                {
                                    this.state.clickedChats.map((item, idx) => {
                                        if (idx < this.state.canShowChats)
                                            return (
                                                this.state.openedChats && this.state.openedChats.filter(chat => chat._id == item._id).length ?
                                                    <div className="clicked-chat-item opened-chat"
                                                        onDrop={e => this.handleDrop(e, item?._id)}

                                                        onDragEnter={(e) => {

                                                            if (!e.dataTransfer.types.includes('Files')) {
                                                                e?.preventDefault();
                                                                e?.stopPropagation();
                                                                return
                                                            }

                                                            e?.preventDefault();
                                                            e?.stopPropagation();
                                                            this.setState({ isDragging: item?._id })
                                                        }}

                                                        onDragLeave={(e) => {
                                                            e?.preventDefault();
                                                            e?.stopPropagation();
                                                            this.setState({ isDragging: null }, () => {
                                                                let oldScrollHeight = 0
                                                                if (this.messagesRef && this.messagesRef[item?._id]) {
                                                                    oldScrollHeight = this.messagesRef[item?._id].scrollHeight;
                                                                }

                                                                if (this.messagesRef && this.state.chat[item?._id] && this.state.chat[item?._id].page && this.messagesRef[item?._id] && this.state.chat[item?._id].page == 0) {
                                                                    this.messagesRef[item?._id].scrollTop = this.messagesRef[item?._id].scrollHeight;
                                                                } else if (oldScrollHeight) {
                                                                    this.messagesRef[item?._id].scrollTop = this.messagesRef[item?._id].scrollTop + (this.messagesRef[item?._id].scrollHeight - oldScrollHeight);
                                                                }
                                                            })
                                                        }}

                                                        onDragOver={e => {
                                                            e?.preventDefault();
                                                            e?.stopPropagation();
                                                        }}
                                                        key={item?._id || idx}
                                                    >

                                                        <div className={`chat-wrap ${this.state.call ? 'video-call' : ''} ${this.state.showChat ? 'show-chat' : ''} ${this.state.offerData ? 'incoming-offer' : ''} ${this.state.chat[item._id].searchMembers || (this.state.chat && this.state.chat[item._id] && this.state.chat[item._id].showGallery) ? 'search-members-active' : ''}`} ref={(node) => this.chatWrapRef = node}



                                                        >{this.state.isDragging && this.state.isDragging == item?._id ? <div className='drag-drop-container'>
                                                            {'Drop to send'.translate?.(this.props.lang)}</div> :

                                                            <><div className={this.state.chat && this.state.chat[item._id] && this.state.chat[item._id].chatHelpIcons ? 'chat-layer chat-layer-show' : 'chat-layer'} onClick={() => {
                                                                let chat = this.state.chat;
                                                                chat[item._id].chatHelpIcons = false;
                                                                this.setState({ chat })
                                                            }}></div>

                                                                <div className="head" onClick={() => {


                                                                    let openedChats = this.state.openedChats;
                                                                    let newOpenedChats = []
                                                                    for (let i = 0; i < openedChats.length; i++) {
                                                                        if (openedChats[i]._id != item._id) {
                                                                            newOpenedChats.push(openedChats[i])
                                                                        }
                                                                    }

                                                                    // let messages = this.state.messages;
                                                                    // let page = this.state.page;

                                                                    // messages[item._id] = [];

                                                                    // page[item._id] = 0;
                                                                    // this.setState({
                                                                    //     openedChats: newOpenedChats,
                                                                    //     messages,
                                                                    //     page
                                                                    // }, () => {

                                                                    //     this.props.socket.emit('messages', { conversation: item._id, page: 0 })
                                                                    // })
                                                                    if (item && item._id) {
                                                                        this.props.registerClinicGroupLogs({
                                                                            type: 'closeConversation',
                                                                            conversation: item._id
                                                                        })
                                                                    }

                                                                    this.setState({
                                                                        openedChats: newOpenedChats,
                                                                    }, () => {
                                                                        // this.props.socket.emit('messages', { conversation: item._id, page: 0 })

                                                                    })

                                                                }}>
                                                                    <h6>

                                                                        {item && item.referral ?
                                                                            <div className="referral">
                                                                                <button className="chat-header-icon" onClick={(e) => {
                                                                                    e.stopPropagation();
                                                                                    this.setState({ referral: item })
                                                                                }}>
                                                                                    {/* {'Patient responses'.translate(this.props.lang)} */}
                                                                                    <Isvg src={docIcon} />
                                                                                </button>
                                                                            </div>
                                                                            :
                                                                            null
                                                                        }


                                                                        <p>{
                                                                            item && item.name && item.name.length < 25 ?
                                                                                this.props.lang == 'se' ? item.name.replace('Direct', 'Direkt') : item.name
                                                                                :
                                                                                this.props.lang == 'se' ? (item.name.replace('Direct', 'Direkt').substring(0, 22) + '...') : (item.name.substring(0, 22) + '...')
                                                                        }</p> &nbsp;
                                                                        {
                                                                            this.props.uData && this.props.uData.userLevel >= 20 && localStorage.getItem('profile') == 'doctor' && item && item.members && item.members[0] == this.props.uData._id && !item.closed ?
                                                                                <>
                                                                                    {
                                                                                        item.referral && item.referral.dropIn ?
                                                                                            <Button className="close-conversation-button" onClick={() => this.setState({ closeCaseModal: item })} color="danger" size="sm">{'Close case'.translate(this.props.lang)} </Button>
                                                                                            :
                                                                                            <Button className="close-conversation-button" onClick={() => this.setState({ closeModal: item })} color="danger" size="sm">{'Close conversation'.translate(this.props.lang)} </Button>
                                                                                    }
                                                                                </>
                                                                                :
                                                                                this.props.uData && this.props.uData.userLevel >= 20 && localStorage.getItem('profile') == 'doctor' && item && item.members && item.members[0] != this.props.uData._id && !item.closed ?
                                                                                    <Button className="close-conversation-button" onClick={() => this.setState({ leaveChatModal: item })} color="danger" size="sm">{'Leave chat'.translate(this.props.lang)} </Button>

                                                                                    :
                                                                                    null
                                                                        }
                                                                        {
                                                                            this.props.uData && this.props.uData.userLevel >= 20 && localStorage.getItem('profile') == 'doctor' && item && item.members && item.members[0] == this.props.uData._id && item.closed ?
                                                                                <Button onClick={() => this.setState({ openModal: item })} color="primary" size="sm">{'Open conversation'.translate(this.props.lang)} </Button>
                                                                                :
                                                                                null
                                                                        }
                                                                    </h6>
                                                                    <div className="members-container">
                                                                        {
                                                                            item && item.members && item.members.length >= 2 ?
                                                                                <div className="overlay"></div>
                                                                                :
                                                                                null
                                                                        }
                                                                        {item && item.users.map((user, idx) => {
                                                                            return (
                                                                                this.props.uData && this.props.uData.userLevel >= 20 && localStorage.getItem('profile') == 'doctor' ?
                                                                                    <div className={user.level < 20 ? "chat-icons-tooltip" : ''}>{user.level < 20 ? 'Click on patient to see his profile'.translate(this.props.lang) : ''}</div>
                                                                                    :
                                                                                    null

                                                                            )
                                                                        })}
                                                                        <div ref={(node) => this.membersScrollArea = node}
                                                                            onMouseDown={(e) => {
                                                                                if (this.membersScrollArea) {
                                                                                    this.membersScrollArea.style.cursor = 'grabbing';
                                                                                    //this.scrollArea.style.userSelect = 'none';


                                                                                    this.setState({
                                                                                        _membersDragging: {
                                                                                            x: e.clientX,
                                                                                            y: e.clientY,
                                                                                            left: this.membersScrollArea.scrollLeft,
                                                                                        }
                                                                                    })
                                                                                }
                                                                            }}
                                                                            onMouseMove={(e) => {
                                                                                if (this.state._membersDragging && this.membersScrollArea) {
                                                                                    // How far the mouse has been moved
                                                                                    const dx = e.clientX - this.state._membersDragging.x;

                                                                                    this.membersScrollArea.scrollLeft = this.state._membersDragging.left - dx;

                                                                                }
                                                                            }}
                                                                            onMouseUp={(e) => {
                                                                                this.membersScrollArea.style.cursor = 'grab';
                                                                                //this.scrollArea.style.userSelect = 'none';

                                                                                this.setState({
                                                                                    _membersDragging: null
                                                                                })
                                                                            }}
                                                                            className={
                                                                                item && item.members.length >= 2 && window.innerWidth > 768 && typeof window !== 'undefined' ? 'members members-scroll' : ((item && item.members.length >= 4 && window.innerWidth < 768 && typeof window !== 'undefined' ? 'members members-scroll-mobile' : 'members') || (item && item.members.length > 2 && typeof window != 'undefined' && window.innerWidth > 768 ? 'members members-scroll' : 'members'))}>
                                                                            {
                                                                                item && item.users.map((user, idx) => {

                                                                                    if (user.uid && user.uid.indexOf('guest') == -1)
                                                                                        return (
                                                                                            <div
                                                                                                onMouseEnter={(e) => {
                                                                                                    e.stopPropagation();
                                                                                                    this.setState({
                                                                                                        memeberHoverState: user.uid
                                                                                                    })
                                                                                                }}

                                                                                                onMouseLeave={(e) => {
                                                                                                    e.stopPropagation();

                                                                                                    this.setState({
                                                                                                        memeberHoverState: null
                                                                                                    })
                                                                                                }}

                                                                                                onClick={(e) => {
                                                                                                    e.stopPropagation();
                                                                                                    if (this.state.tooltip == 0 || this.state.tooltip != user.uid) {
                                                                                                        this.setState({
                                                                                                            tooltip: user.uid
                                                                                                        })
                                                                                                    } else if (this.state.tooltip == user.uid) {
                                                                                                        this.setState({
                                                                                                            tooltip: 0
                                                                                                        })
                                                                                                    }
                                                                                                    if (this.state.memeberHoverState == user.uid) {

                                                                                                        if ((this.props.uData.userLevel == 20 && typeof window != 'undefined' && localStorage?.getItem?.('profile') != 'patient') && (user.level && user.level < 20 || (user.socialSecurityNumber && item && item.referral && item.referral.patient && item.referral.patient.socialSecurityNumber && item.referral.patient.name && item.referral.patient.socialSecurityNumber == user.socialSecurityNumber) || (user.socialSecurityNumber && item && item.control && item.socialSecurityNumber && item.socialSecurityNumber == user.socialSecurityNumber))) {
                                                                                                            fetch(API_ENDPOINT + '/users/users/' + user.uid.toString(), {
                                                                                                                method: 'GET',
                                                                                                                headers: {
                                                                                                                    'Authorization': typeof localStorage !== 'undefined' ? `Bearer ${localStorage.getItem('authToken')}` : null,
                                                                                                                    'content-type': 'application/json'
                                                                                                                },
                                                                                                            }).then(res => res.json()).then((result) => {
                                                                                                                this.setState({
                                                                                                                    profileModal: {
                                                                                                                        conversation: item._id,
                                                                                                                        ...result
                                                                                                                    }
                                                                                                                })
                                                                                                            })
                                                                                                        }
                                                                                                        this.setState({
                                                                                                            memeberHoverState: null
                                                                                                        })

                                                                                                    } else {
                                                                                                        this.setState({
                                                                                                            memeberHoverState: user.uid
                                                                                                        })
                                                                                                    }
                                                                                                }}>
                                                                                                <img onClick={() => { }} src={user.profilePicture ? API_ENDPOINT + user.profilePicture : profilePicture} />
                                                                                                <div className={user.online ? 'status online' : 'status offline'}></div>
                                                                                                <p onClick={() => { }} className={typeof window != 'undefined' && window.innerWidth > 768 ? this.state.tooltip != 0 && this.state.tooltip == user.uid ? 'name-visible' : 'name-none' : ''}>
                                                                                                    {
                                                                                                        user.socialSecurityNumber && item && item.referral && item.referral.patient && item.referral.patient.socialSecurityNumber && item.referral.patient.name && item.referral.patient.socialSecurityNumber == user.socialSecurityNumber ?
                                                                                                            item.referral.patient.name
                                                                                                            :
                                                                                                            (user.socialSecurityNumber && item && item.control && item.socialSecurityNumber && item.socialSecurityNumber == user.socialSecurityNumber) ?
                                                                                                                item.name
                                                                                                                :
                                                                                                                user.name
                                                                                                    }
                                                                                                    {
                                                                                                        user.socialSecurityNumber ?
                                                                                                            <><br />{user.socialSecurityNumber} </>
                                                                                                            :
                                                                                                            null
                                                                                                    }
                                                                                                </p>

                                                                                            </div>
                                                                                        )
                                                                                })
                                                                            }
                                                                        </div>
                                                                    </div>

                                                                    <div className="chat-more-icon-wrap" >
                                                                        <Isvg src={moreIcon} onClick={(e) => {
                                                                            e.stopPropagation();
                                                                            let chat = this.state.chat;
                                                                            chat[item._id].chatHelpIcons = !chat[item._id].chatHelpIcons;
                                                                            this.setState({ chat })
                                                                        }} className="chat-more-icon" />
                                                                    </div>

                                                                    <div className={this.state.chat && this.state.chat[item._id] && this.state.chat[item._id].chatHelpIcons ? 'chat-helper chat-helper-show' : 'chat-helper'}>
                                                                        <input ref={(node) => this.roomIdInput = node} style={{ width: 10, height: 10, background: 'transparent', border: 0, opacity: 0 }} value={item && typeof window != 'undefined' ? `${window.location.origin}/video-call/${item._id}?roomId=${item.roomId}` : null} />


                                                                        <button onClick={(e) => {
                                                                            e.stopPropagation()
                                                                            let chat = this.state.chat;
                                                                            chat[item._id].chatHelpIcons = false;
                                                                            chat[item._id].showGallery = true;
                                                                            this.setState({ chat })
                                                                        }} className="search-members-button"><Isvg src={galleryIcon} /> <div className="chat-icons-tooltip">{'Media'.translate(this.props.lang)}</div></button>



                                                                        {this.props.uData && this.props.uData.userLevel >= 20 && localStorage.getItem('profile') == 'doctor' && item && item.members && item.members[0] == this.props.uData._id ?
                                                                            <button onClick={(e) => {
                                                                                e.stopPropagation()
                                                                                let chat = this.state.chat;
                                                                                chat[item._id].chatHelpIcons = false;
                                                                                this.setState({ chat })
                                                                                this.showCopiedLink();
                                                                                const copyText = this.roomIdInput
                                                                                copyText.select();
                                                                                document.execCommand('copy');


                                                                            }} className={this.state.linkCopied ? "share-button link-copy-progress" : "share-button"}><Isvg src={shareIcon} /> <div className={this.state.linkCopied ? 'link-copied yes' : 'link-copied'}>{'The link is copied to clipboard'.translate(this.props.lang)}</div> <div className="chat-icons-tooltip">{'Copy link'.translate(this.props.lang)}</div> </button>
                                                                            :
                                                                            null
                                                                        }

                                                                        {/* {this.props.uData && this.props.uData.userLevel >= 20 && localStorage.getItem('profile') == 'doctor' && item && item.members && item.members[0] == this.props.uData._id ?
                                                                    <button onClick={(e) => {
                                                                        e.stopPropagation()
                                                                        let chat = this.state.chat;
                                                                        chat[item._id].chatHelpIcons = false;
                                                                        chat[item._id].searchMembers = true;
                                                                        this.setState({ chat })
                                                                    }} className="search-members-button"><Isvg src={addIcon} style={{ position: 'absolute' }} /> <div className="chat-icons-tooltip">{'Add participant'.translate(this.props.lang)}</div></button>
                                                                    :
                                                                    null
                                                                } */}
                                                                        {!this.props.videoCallUrl && this.props.uData && this.props.uData.userLevel >= 20 && localStorage.getItem('profile') == 'doctor' && selectedGroupIdx != -1 && item.groupIds && item.groupIds.indexOf(this.props.selectedGroup) != -1 && item && item.members && item.members[0] == this.props.uData._id ?
                                                                            <button onClick={/*this.call*/(e) => {
                                                                                e.stopPropagation()
                                                                                let chat = this.state.chat;
                                                                                chat[item._id].chatHelpIcons = false;
                                                                                this.setState({ chat })
                                                                                this.props.startVideoCall(item._id);
                                                                            }} className="call-button" disabled={this.props.uData.clinicGroups[selectedGroupIdx].videoLicence <= 0} style={{ margin: '6px 0 0 0' }}><Isvg src={call} /> <div className="chat-icons-tooltip">{'Start video chat'.translate(this.props.lang)}</div> </button>
                                                                            :
                                                                            null
                                                                        }
                                                                        {typeof window !== 'undefined' && true && this.props.uData && this.props.uData.userLevel >= 20 && localStorage.getItem('profile') == 'doctor' && item && item.members && item.members[0] == this.props.uData._id && !item.closed ?
                                                                            <>
                                                                                {
                                                                                    item.referral && item.referral.dropIn ?
                                                                                        <Button className="close-conversation-button-mobile" onClick={(e) => {
                                                                                            e.stopPropagation()
                                                                                            let chat = this.state.chat;
                                                                                            chat[item._id].chatHelpIcons = false;
                                                                                            this.setState({ closeCaseModal: item, chat })
                                                                                        }} color="danger" size="sm">&times; <div class="chat-icons-tooltip">{'Close case'.translate(this.props.lang)}</div> </Button>
                                                                                        :
                                                                                        <Button className="close-conversation-button-mobile" onClick={(e) => {
                                                                                            e.stopPropagation()
                                                                                            let chat = this.state.chat;
                                                                                            chat[item._id].chatHelpIcons = false;
                                                                                            this.setState({ closeModal: item, chat })
                                                                                        }} color="danger" size="sm"> &times; <div class="chat-icons-tooltip">{'Close conversation'.translate(this.props.lang)}</div> </Button>
                                                                                }
                                                                            </>
                                                                            :
                                                                            typeof window !== 'undefined' && true && this.props.uData && this.props.uData.userLevel >= 20 && localStorage.getItem('profile') == 'doctor' && item && item.members && item.members[0] != this.props.uData._id && !item.closed ?
                                                                                // <Button className="close-conversation-button" onClick={() => this.setState({ leaveChatModal: item })} color="danger" size="sm">{'Leave chat'.translate(this.props.lang)} </Button>
                                                                                <Button className="close-conversation-button-mobile" onClick={(e) => {
                                                                                    e.stopPropagation()
                                                                                    let chat = this.state.chat;
                                                                                    chat[item._id].chatHelpIcons = false;
                                                                                    this.setState({ leaveChatModal: item, chat })
                                                                                }} color="danger" size="sm"> &times; <div class="chat-icons-tooltip">{'Leave chat'.translate(this.props.lang)}</div> </Button>

                                                                                :
                                                                                null
                                                                        }
                                                                    </div>
                                                                    <div className='close-chat-head' onClick={(e) => {
                                                                        e.stopPropagation()
                                                                        let openedChats = this.state.openedChats;
                                                                        let clickedChats = this.state.clickedChats;

                                                                        let newOpenedChats = []
                                                                        for (let i = 0; i < openedChats.length; i++) {
                                                                            if (openedChats[i]._id != item._id) {
                                                                                newOpenedChats.push(openedChats[i])
                                                                            }
                                                                        }
                                                                        let newClickedChats = [];
                                                                        for (let i = 0; i < clickedChats.length; i++) {
                                                                            if (clickedChats[i]._id != item._id) {
                                                                                newClickedChats.push(clickedChats[i])
                                                                            }
                                                                        }

                                                                        if (item && item._id) {
                                                                            this.props.registerClinicGroupLogs({
                                                                                type: 'closeConversation',
                                                                                conversation: item._id
                                                                            })
                                                                        }

                                                                        this.setState({
                                                                            openedChats: newOpenedChats,
                                                                            clickedChats: newClickedChats,
                                                                        })
                                                                    }}>&times;</div>
                                                                </div>

                                                                {
                                                                    this.state.chat[item._id].searchMembers && item ?
                                                                        <div className="search-members">

                                                                            <div className="search-area">
                                                                                <div className="search-header-wrap">
                                                                                    <h4>{'Search patients'.translate(this.props.lang)}</h4>
                                                                                    <button className="close" onClick={() => {
                                                                                        let chat = this.state.chat;
                                                                                        chat[item._id].searchMembers = null;
                                                                                        chat[item._id].searchMembersText = '';
                                                                                        chat[item._id].errorTooManyPatients = false;

                                                                                        this.setState({ chat })
                                                                                    }}>&times;</button>
                                                                                </div>
                                                                                <Input type="text" placeholder={'Search'.translate(this.props.lang)} value={this.state.chat && this.state.chat[item._id] && this.state.chat[item._id].searchMembersText} onChange={(e) => {
                                                                                    let chat = this.state.chat;
                                                                                    chat[item._id].searchMembersText = striptags(e.target.value);
                                                                                    chat[item._id].errorTooManyPatients = false;

                                                                                    this.setState({
                                                                                        chat
                                                                                    }, () => this.searchMembers(item._id))
                                                                                }} />


                                                                                <ul>
                                                                                    {
                                                                                        this.state.chat && this.state.chat[item._id] && this.state.chat[item._id].searchUsers && this.state.chat[item._id].searchUsers.map((user, uidx) => {

                                                                                            if (user._id != this.props.uData._id)
                                                                                                return (
                                                                                                    <li key={user._id + uidx + '-' + item.members.indexOf(user._id)}>
                                                                                                        <div className="user-image">
                                                                                                            <img src={user.profilePicture ? API_ENDPOINT + user.profilePicture : profilePicture} />
                                                                                                            <div className={user.online ? 'status online' : 'status offline'}></div>
                                                                                                        </div>
                                                                                                        <p>
                                                                                                            {
                                                                                                                user.userData && user.userData.name && (user.userLevel == 1 || user.userLevel == 2 || (user.userLevel == 20 && ((user.socialSecurityNumber && item && item.referral && item.referral.patient && item.referral.patient.socialSecurityNumber && item.referral.patient.name && item.referral.patient.socialSecurityNumber == user.socialSecurityNumber) || (user.socialSecurityNumber && item && item.control && item.socialSecurityNumber && item.socialSecurityNumber == user.socialSecurityNumber)))) ?
                                                                                                                    user.userData.name
                                                                                                                    :
                                                                                                                    user.name
                                                                                                            }
                                                                                                            {
                                                                                                                user.userData && user.socialSecurityNumber && (user.userLevel == 1 || user.userLevel == 2 || (user.userLevel == 20 && ((user.socialSecurityNumber && item && item.referral && item.referral.patient && item.referral.patient.socialSecurityNumber && item.referral.patient.name && item.referral.patient.socialSecurityNumber == user.socialSecurityNumber) || (user.socialSecurityNumber && item && item.control && item.socialSecurityNumber && item.socialSecurityNumber == user.socialSecurityNumber)))) ?
                                                                                                                    <><br />{user.socialSecurityNumber}</>
                                                                                                                    :
                                                                                                                    null
                                                                                                            }
                                                                                                        </p>
                                                                                                        {item.members.indexOf(user._id) !== -1 ?
                                                                                                            <button onClick={() => this.removeMember(user._id, item)} className="delete-member"><Isvg src={garabage} /></button>

                                                                                                            :
                                                                                                            <Button color="primary" onClick={() => this.addMember(user, item)}>{'Add'.translate(this.props.lang)}</Button>
                                                                                                        }
                                                                                                    </li>
                                                                                                )
                                                                                        })
                                                                                    }

                                                                                </ul>
                                                                                {this.state.chat[item._id].errorTooManyPatients ?

                                                                                    <ErrorModal lang={this.props.lang}
                                                                                        isOpen={this.state.chat[item._id].errorTooManyPatients}
                                                                                        toggle={() => {
                                                                                            let chat = this.state.chat;
                                                                                            chat[item._id].errorTooManyPatients = null;
                                                                                            this.setState({ chat })
                                                                                        }}
                                                                                        zIndex={99999999999}
                                                                                    >
                                                                                        {this.state.chat[item._id].errorTooManyPatients.translate(this.props.lang)}
                                                                                    </ErrorModal>

                                                                                    :
                                                                                    null
                                                                                }

                                                                            </div>
                                                                        </div>

                                                                        :

                                                                        null
                                                                }

                                                                {this.state.chat && this.state.chat[item._id] && this.state.chat[item._id].showGallery ?
                                                                    <div className="conversation-media">
                                                                        <button className="close" onClick={() => {
                                                                            let chat = this.state.chat;
                                                                            chat[item._id].showGallery = null;
                                                                            this.setState({ chat })
                                                                        }}>&times;</button>
                                                                        <h6>{'Conversation media'.translate(this.props.lang)}</h6>
                                                                        <div className="items">
                                                                            {
                                                                                this.state.chat && this.state.chat[item._id] && this.state.chat[item._id].messages.filter(message => message.image || (message.healthCareReferral && !message.hideIcon) || message.video).map((message) => {
                                                                                    return (
                                                                                        <div className="item">
                                                                                            {
                                                                                                message.uid == this.props.uData._id ?
                                                                                                    <div className="delete-message-icon" onClick={() => this.setState({
                                                                                                        deleteMessageModal: {
                                                                                                            conversation: item._id,
                                                                                                            message
                                                                                                        }
                                                                                                    })}><Isvg src={garabage} /></div>
                                                                                                    :
                                                                                                    null
                                                                                            }
                                                                                            {
                                                                                                message.image ?
                                                                                                    <a href={`/media-gateway?path=${message.image}&cid=${message.conversation}&mid=${message._id}`} target="_blank">
                                                                                                        <img src={API_ENDPOINT + `${message.image}?mt=${this.props.uData ? this.props.uData.mediaToken : ''}&cid=${message.conversation}&mid=${message._id}`} />
                                                                                                    </a>
                                                                                                    :
                                                                                                    message.healthCareReferral && !message.hideIcon ?
                                                                                                        <div className="conversation-media-questionary"
                                                                                                            onClick={() => this.setState({
                                                                                                                questionaryModal: message.healthCareId,
                                                                                                                questionaryMessageActive: message._id,
                                                                                                                questionarySave: true,
                                                                                                                activeReferralContent: message.healthCareReferral && message.healthCareReferralObject && message.healthCareReferralObject.healthCareContent ? message.healthCareReferralObject.healthCareContent : null,
                                                                                                                questionaryAnswers: message.healthCareReferral && message.healthCareReferralObject ? message.healthCareReferralObject.answers : {}
                                                                                                            }, () => this.getQuestionary())}>
                                                                                                            <div className="conversation-media-questionary-svg">
                                                                                                                <Isvg src={formIcon} />
                                                                                                            </div>
                                                                                                            <h6>{message.message}</h6>
                                                                                                        </div>
                                                                                                        :
                                                                                                        message.video ?
                                                                                                            <div className="conversation-media-video">
                                                                                                                <video width="320" height="240" controls>
                                                                                                                    <source src={API_ENDPOINT + `${message.video}?mt=${this.props.uData ? this.props.uData.mediaToken : ''}&cid=${message.conversation}&mid=${message._id}`} type="video/mp4" />
                                                                                                                    <source src={API_ENDPOINT + `${message.video}?mt=${this.props.uData ? this.props.uData.mediaToken : ''}&cid=${message.conversation}&mid=${message._id}`} type="video/ogg" />
                                                                                                                </video>
                                                                                                            </div>

                                                                                                            :

                                                                                                            null
                                                                                            }

                                                                                        </div>
                                                                                    )
                                                                                })
                                                                            }

                                                                        </div>
                                                                    </div>
                                                                    :
                                                                    null}




                                                                {this.state.offerData ?
                                                                    <div className="call-offer">
                                                                        <div class="caller">
                                                                            <img src={this.state.offerData.user && this.state.offerData.user.profilePicture ? API_ENDPOINT + this.state.offerData.user.profilePicture : profilePicture} />
                                                                            <p>{this.state.offerData && this.state.offerData.user ? this.state.offerData.user.name : ''}</p>
                                                                        </div>
                                                                        <div className="buttons">
                                                                            <button onClick={this.answer} className="answer-button"><Isvg src={call} /></button>
                                                                            <button onClick={this.endCall} className="decline-button"><Isvg src={call} /></button>
                                                                        </div>

                                                                    </div>
                                                                    :
                                                                    null
                                                                }


                                                                <div className={this.state.chat[item._id].journalNote ? 'messages-wrapper journal-note-active-modal' : 'messages-wrapper'}
                                                                    onMouseMove={(e) => {
                                                                        if (this.state.messageRisaze) {
                                                                            const element = document.getElementById('message')
                                                                            if (element) {

                                                                                element.style.height = Number(element.style.height.replace('px', '')) - e.movementY + 'px'
                                                                            }
                                                                        }
                                                                    }} onMouseUp={() => {
                                                                        this.setState({ messageRisaze: false })
                                                                    }}
                                                                >
                                                                    {
                                                                        this.state.showChat ?
                                                                            <button className="hide-chat" onClick={() => this.setState({ showChat: null })}>&times;</button>
                                                                            :

                                                                            null
                                                                    }

                                                                    {
                                                                        item && item.currentCall && (this.props.uData && this.props.uData._id != item.members[0]) ?
                                                                            <button onClick={() => {
                                                                                let chat = this.state.chat;
                                                                                chat[item._id].chatHelpIcons = false;
                                                                                this.setState({ chat }, () => {
                                                                                    var strWindowFeatures = `height=${window.innerHeight - 1},width=${window.innerWidth - 1},resizable=yes,scrollbars=yes,toolbar=yes,menubar=no,location=no,directories=no, status=yes`;
                                                                                    this.videoCallWindow = window.open(`/video-call/${item._id}?initiator=${item.currentCall.initiator}`, "_blank", strWindowFeatures);
                                                                                    this.videoCallWindow.addEventListener('message',
                                                                                        (e) => {
                                                                                            if (e.data == 'end-video-call') {
                                                                                                this.videoCallWindow.close();
                                                                                            }

                                                                                        }

                                                                                        , false)
                                                                                })
                                                                            }} className="join-call-button"><Isvg src={call} /> <span>{'Rejoin call'.translate(this.props.lang)}</span></button>

                                                                            :
                                                                            null

                                                                    }

                                                                    <div className="messages" ref={(node) => {
                                                                        if (this.messagesRef && this.messagesRef[item._id]) {
                                                                            this.messagesRef[item._id] = node
                                                                        } else {
                                                                            if (!this.messagesRef) {
                                                                                this.messagesRef = {}
                                                                            }
                                                                            this.messagesRef[item._id] = node;
                                                                        }

                                                                    }} style={{ paddingBottom: this.state.messageHeight && this.messagesRef && this.state.messageHeight[item._id] && this.messagesRef[item._id] ? (this.state.messageHeight[item._id] + 66) : 130 }}>

                                                                        {
                                                                            this.state.chat && this.state.chat[item._id] && this.state.chat[item._id].messages.map((message, midx) => {
                                                                                if (message.type == 'special') {
                                                                                    return (
                                                                                        <>
                                                                                            {message.videoStarted ? <h6 className='video-chat-notify'>
                                                                                                <Isvg src={videoChatIcon} />
                                                                                                {/* <span className='time-started'>{moment.unix(message.tsCreated).format(this.props.dateFormat + ' HH:mm')}</span> */}
                                                                                                <span className='time-started'>{this.props.getStringDateTs(message.tsCreated, this.props.dateFormat + ' HH:mm')}</span>
                                                                                                <span className='user-name'>{message.user.name}</span>
                                                                                                <span className='notify'> {'Started video meeting'.translate(this.props.lang)}</span>
                                                                                            </h6> : null}

                                                                                            {message.videoJoined ? <h6 className='video-chat-notify'>
                                                                                                <Isvg src={userChatIcon} />
                                                                                                {/* <span className='time-started'>{moment.unix(message.tsCreated).format(this.props.dateFormat + ' HH:mm')}</span> */}
                                                                                                <span className='time-started'>{this.props.getStringDateTs(message.tsCreated, this.props.dateFormat + ' HH:mm')}</span>
                                                                                                <span className='user-name'>{message.user.name}</span>
                                                                                                <span className='notify'> {'Joined video meeting'.translate(this.props.lang)}</span>
                                                                                            </h6> : null}

                                                                                            {message.videoLeft ? <h6 className='video-chat-notify'>
                                                                                                <Isvg src={userChatIcon} />
                                                                                                {/* <span className='time-started'>{moment.unix(message.tsCreated).format(this.props.dateFormat + ' HH:mm')}</span> */}
                                                                                                <span className='time-started'>{this.props.getStringDateTs(message.tsCreated, this.props.dateFormat + ' HH:mm')}</span>
                                                                                                <span className='user-name'>{message.user.name}</span>
                                                                                                <span className='notify'> {'Left video meeting'.translate(this.props.lang)}</span>
                                                                                            </h6> : null}

                                                                                            {message.videoEnded ? <h6 className='video-chat-notify'>
                                                                                                <Isvg src={userChatIcon} />
                                                                                                {/* <span className='time-started'>{moment.unix(message.tsCreated).format(this.props.dateFormat + ' HH:mm')}</span> */}
                                                                                                <span className='time-started'>{this.props.getStringDateTs(message.tsCreated, this.props.dateFormat + ' HH:mm')}</span>
                                                                                                <span className='user-name'>{message.user.name}</span>
                                                                                                <span className='notify'> {'Ended video meeting'.translate(this.props.lang)}</span>
                                                                                            </h6> : null}
                                                                                        </>
                                                                                    )

                                                                                } else {
                                                                                    return (
                                                                                        <div className={message.uid == this.props.uData._id ? 'right' : ''}>



                                                                                            {this.state.chat[item._id].messages.length > 15 && midx == 1 ?
                                                                                                <div ><Waypoint
                                                                                                    onEnter={() => {
                                                                                                        let chat = this.state.chat;
                                                                                                        chat[item._id].page = chat[item._id].page + 1
                                                                                                        this.setState({
                                                                                                            chat
                                                                                                        }, () => {
                                                                                                            this.props.socket.emit('messages', { conversation: item._id, page: this.state.chat[item._id].page })
                                                                                                        })
                                                                                                    }}
                                                                                                />
                                                                                                </div>
                                                                                                :
                                                                                                null
                                                                                            }

                                                                                            {
                                                                                                message.uid != this.props.uData._id ?
                                                                                                    <img src={message.user && message.user.profilePicture ? API_ENDPOINT + message.user.profilePicture : profilePicture} />
                                                                                                    :
                                                                                                    null
                                                                                            }
                                                                                            <div>

                                                                                                <div className='break-word' onClick={() => {
                                                                                                    let showSeen = this.state.showSeen;
                                                                                                    showSeen[message._id] = !showSeen[message._id];
                                                                                                    this.setState({
                                                                                                        showSeen
                                                                                                    })
                                                                                                }}>
                                                                                                    {
                                                                                                        message.uid == this.props.uData._id && !message.deletedMessage ?
                                                                                                            <div className="delete-message-icon" onClick={() => this.setState({
                                                                                                                deleteMessageModal: {
                                                                                                                    conversation: item._id,
                                                                                                                    message
                                                                                                                }
                                                                                                            })}>&times;</div>
                                                                                                            :
                                                                                                            null
                                                                                                    }
                                                                                                    <div className="username">
                                                                                                        <p>{message.user && message.user.name}</p>
                                                                                                    </div>
                                                                                                    {
                                                                                                        message.deletedMessage && (message.uid == this.props.uData._id || (message && (!message.memberLanguages || (message.memberLanguages && !message.memberLanguages[this.props.uData._id])))) ?
                                                                                                            <p style={{ marginBottom: 0, fontStyle: 'italic', fontWeight: 400, fontSize: 14 }}>{message.message + ' ' + 'deleted a message'.translate(this.props.lang)}</p>
                                                                                                            :
                                                                                                            message.deletedMessage && (message.uid != this.props.uData._id && message && message.memberLanguages && message.memberLanguages[this.props.uData._id]) ?
                                                                                                                <p style={{ marginBottom: 0, fontStyle: 'italic', fontWeight: 400, fontSize: 14 }}>{message.message}</p>
                                                                                                                :
                                                                                                                null
                                                                                                    }
                                                                                                    {message.image ? <a target='_blank' href={`/media-gateway?path=${message.image}&cid=${message.conversation}&mid=${message._id}`}><img className="message-image" src={API_ENDPOINT + `${message.image}?mt=${this.props.uData ? this.props.uData.mediaToken : ''}&cid=${message.conversation}&mid=${message._id}`} /></a> : null}
                                                                                                    {
                                                                                                        message.video ?
                                                                                                            <div className="video-in-chat-wrap">
                                                                                                                <video width="260" height="200" controls>
                                                                                                                    <source src={API_ENDPOINT + `${message.video}?mt=${this.props.uData ? this.props.uData.mediaToken : ''}&cid=${message.conversation}&mid=${message._id}`} type="video/mp4" />
                                                                                                                    <source src={API_ENDPOINT + `${message.video}?mt=${this.props.uData ? this.props.uData.mediaToken : ''}&cid=${message.conversation}&mid=${message._id}`} type="video/ogg" />
                                                                                                                </video>
                                                                                                            </div>
                                                                                                            :
                                                                                                            null
                                                                                                    }

                                                                                                    {
                                                                                                        message.healthCareId ?
                                                                                                            <div className="health-care-form-message" onClick={() => this.setState({
                                                                                                                questionaryModal: message.healthCareId,
                                                                                                                questionaryMessageActive: message._id,
                                                                                                                questionarySave: message.healthCareReferral ? true : false,
                                                                                                                activeReferralContent: message.healthCareReferral && message.healthCareReferralObject && message.healthCareReferralObject.healthCareContent ? message.healthCareReferralObject.healthCareContent : null,
                                                                                                                questionaryAnswers: message.healthCareReferral && message.healthCareReferralObject ? message.healthCareReferralObject.answers : {}
                                                                                                            },
                                                                                                                () => {
                                                                                                                    this.getQuestionary()
                                                                                                                })}>
                                                                                                                {
                                                                                                                    !message.hideIcon ?
                                                                                                                        <Isvg src={formIcon} />
                                                                                                                        :
                                                                                                                        null
                                                                                                                }


                                                                                                                {
                                                                                                                    message.message.indexOf('The patient filled out the form successfully') > -1 ?
                                                                                                                        <>
                                                                                                                            {'The patient filled out the form successfully'.translate(this.props.lang) + ' - '} &nbsp;
                                                                                                                            <Isvg src={formIcon} />
                                                                                                                            {message.message.split('The patient filled out the form successfully - ')[1]}

                                                                                                                        </>

                                                                                                                        :
                                                                                                                        <>
                                                                                                                            {
                                                                                                                                message?.message?.split('\n').length ?
                                                                                                                                    message.message.split('\n').map((text, t) => {
                                                                                                                                        if (t != message.message.split('\n').length - 1)
                                                                                                                                            return (
                                                                                                                                                <>{text}<br /></>
                                                                                                                                            )
                                                                                                                                        else
                                                                                                                                            return (
                                                                                                                                                text
                                                                                                                                            )
                                                                                                                                    })
                                                                                                                                    :
                                                                                                                                    message?.message
                                                                                                                            }
                                                                                                                        </>


                                                                                                                }

                                                                                                            </div>
                                                                                                            : null}
                                                                                                    {
                                                                                                        !message.healthCareId && !message.deletedMessage ?
                                                                                                            message.message == 'The patient filled out the form successfully' ?
                                                                                                                message.message.translate(this.props.lang)
                                                                                                                :
                                                                                                                <>
                                                                                                                    {
                                                                                                                        message.message?.split('\n').length ?
                                                                                                                            message.message?.split('\n').map((text, t) => {
                                                                                                                                if (t != message.message.split('\n').length - 1)
                                                                                                                                    return (
                                                                                                                                        <>{text}<br /></>
                                                                                                                                    )
                                                                                                                                else
                                                                                                                                    return (
                                                                                                                                        text
                                                                                                                                    )
                                                                                                                            })
                                                                                                                            :
                                                                                                                            message.message
                                                                                                                    }
                                                                                                                </>
                                                                                                            :
                                                                                                            null
                                                                                                    }
                                                                                                    {
                                                                                                        message.document ?
                                                                                                            <div className={message.uid != this.props.uData._id ? "document incomming-document" : "document"}><a target="_blank" download href={API_ENDPOINT + `${message.document.file}?mt=${this.props.uData ? this.props.uData.mediaToken : ''}&cid=${message.conversation}&mid=${message._id}`}><Isvg src={attach} />{message.document.name}</a></div>
                                                                                                            :
                                                                                                            null
                                                                                                    }

                                                                                                    {message.links && message.links.length ? message.links.map(link => <a href={link.link} target='_blank' className={`message-link-container ${this.props.uData?._id === message.uid ? 'message-link-container-right' : ''} ${message.links.length > 1 ? 'message-link-adjust-width-mini' : ''}`} onClick={(e) => e.stopPropagation()}>
                                                                                                        <div className='message-link-image'>
                                                                                                            <img src={link.image} />
                                                                                                        </div>
                                                                                                        <div className='message-link-title-and-link'>
                                                                                                            <h6>{link.title}</h6>
                                                                                                            <p>{link.link}</p>
                                                                                                        </div>
                                                                                                    </a>) : null}

                                                                                                    {message.link ?
                                                                                                        <a href={message.link.link} target='_blank' className={`message-link-container message-link-container-mini ${this.props.uData?._id === message.uid ? 'message-link-container-right' : ''}`} onClick={(e) => e.stopPropagation()}>
                                                                                                            <div className='message-link-image'>
                                                                                                                <img src={message.link.image} />
                                                                                                            </div>
                                                                                                            <div className='message-link-title-and-link'>
                                                                                                                <h6>{message.link.title}</h6>
                                                                                                                <p>{message.link.link}</p>
                                                                                                            </div>
                                                                                                        </a>
                                                                                                        : null}

                                                                                                </div>
                                                                                                <div className="date date-out">{moment.unix(message.timestamp).format(`${this.props.dateFormat} HH:mm`)}</div>
                                                                                                {
                                                                                                    (message.seen && message.seen.filter(seenMember => seenMember.uid != this.props.uData._id).length) && (midx == this.state.chat[item._id].messages.length - 1 || this.state.showSeen[message._id]) ?
                                                                                                        <p>
                                                                                                            {'Seen by: '.translate(this.props.lang)} {message.seen && message.seen.filter(seenMember => seenMember.uid != this.props.uData._id && seenMember.uid).map((seenMember) => {

                                                                                                                return item && item.users && item.users.filter(user => user.uid == seenMember.uid).length ? item.users.filter(user => user.uid == seenMember.uid)[0].name : ''
                                                                                                            }).join(', ')}
                                                                                                        </p>
                                                                                                        :
                                                                                                        null
                                                                                                }

                                                                                            </div>
                                                                                        </div>
                                                                                    )
                                                                                }


                                                                            })
                                                                        }

                                                                        {
                                                                            this.state.chat && this.state.chat[item._id] && this.state.chat[item._id].userTyping ?
                                                                                <span className="user-typing">{this.state.chat[item._id].userTyping.name} {'is typing...'.translate(this.props.lang)}</span>
                                                                                :
                                                                                null
                                                                        }


                                                                        {/* / */}
                                                                        <div className={this.state.chat[item._id].journalNote ? 'journal-note-wrap show-journal-note' : 'journal-note-wrap'}>

                                                                            <div className="journal-note-header" >
                                                                                <div className='title'>
                                                                                    <h4>{'Journal notes'.translate(this.props.lang)}</h4>
                                                                                </div>
                                                                                <div className="exit-icon" onClick={() => {
                                                                                    let chat = this.state.chat;
                                                                                    chat[item._id].journalNote = false;

                                                                                    this.setState({ chat }, this.removeFromJorunalNotes)
                                                                                }}>&times;</div>
                                                                            </div>
                                                                            {
                                                                                item && item.referral && item.referral.intelligentReferral && this.state.countOFSections ?
                                                                                    <div className="journal-note-content">
                                                                                        <FormGroup>
                                                                                            <Label>{'Kontaktorsak'.translate(this.props.lang)}</Label>
                                                                                            <Input type='text'
                                                                                                onBlur={() => this.updateJournalNoteOnExit()}
                                                                                                value={item.referral.questionaryName}
                                                                                                onChange={(e) => {
                                                                                                    let conversation = item;
                                                                                                    conversation.referral.questionaryName = striptags(e.target.value);
                                                                                                    this.setState({ conversation }, () => this.generateIntelligentPdf())
                                                                                                }}
                                                                                            />
                                                                                        </FormGroup>
                                                                                        {
                                                                                            this.state.journalNoteIntelligentSections && this.state.journalNoteIntelligentSections.map((questionary, i) => {
                                                                                                return (
                                                                                                    <FormGroup>
                                                                                                        <Label>{questionary.name}</Label>
                                                                                                        <Input type='textarea'
                                                                                                            value={striptags(questionary.answer)}
                                                                                                            onChange={(e) => {
                                                                                                                let journalNoteIntelligentSections = this.state.journalNoteIntelligentSections;
                                                                                                                journalNoteIntelligentSections[i].answer = striptags(e.target.value);
                                                                                                                this.setState({ journalNoteIntelligentSections }, () => this.generateIntelligentPdf())
                                                                                                            }}
                                                                                                            onBlur={() => this.updateJournalNoteOnExit()}
                                                                                                            style={{ height: 150 }} />
                                                                                                    </FormGroup>
                                                                                                )
                                                                                            })

                                                                                        }


                                                                                        <div className="journal-note-buttons">

                                                                                            {
                                                                                                this.state.htmlJournal ?
                                                                                                    <PdfSave lang={this.props.lang} html={this.state.htmlJournal} pdfName={this.generatePdfName()} />

                                                                                                    : null
                                                                                            }
                                                                                            <Button color='primary' onClick={() => {
                                                                                                navigator.clipboard.writeText(this.state.textClipboard)
                                                                                            }}>{'Copy text'.translate(this.props.lang)}</Button>
                                                                                            <Button color='primary' disabled={true}>{'Export to journal system'.translate(this.props.lang)}</Button>
                                                                                        </div>

                                                                                    </div>
                                                                                    :
                                                                                    item && item.referral && (!item.referral.intelligentReferral || (item.referral.intelligentReferral && !this.state.countOFSections)) ?
                                                                                        <div className="journal-note-content">
                                                                                            <FormGroup>
                                                                                                <Label>{'Kontaktorsak'.translate(this.props.lang)}</Label>
                                                                                                <Input type='text' value={this.state.journalNoteObj && this.state.journalNoteObj.kontaktorsak ? this.state.journalNoteObj.kontaktorsak : item.referral.questionaryName}
                                                                                                    onChange={(e) => {
                                                                                                        let journalNoteObj = this.state.journalNoteObj;
                                                                                                        journalNoteObj.kontaktorsak = striptags(e.target.value);
                                                                                                        this.setState({ journalNoteObj }, () => this.generatePdf())
                                                                                                    }}
                                                                                                    onBlur={() => this.updateJournalNoteOnExit()}
                                                                                                />
                                                                                            </FormGroup>
                                                                                            <FormGroup>
                                                                                                <Label>{'Status'.translate(this.props.lang)}</Label>
                                                                                                <Input type='textarea' value={this.state.journalNoteObj.status} style={{ height: 100 }} onChange={(e) => {
                                                                                                    let journalNoteObj = this.state.journalNoteObj;
                                                                                                    journalNoteObj.status = striptags(e.target.value);
                                                                                                    this.setState({ journalNoteObj }, () => this.generatePdf())
                                                                                                }}
                                                                                                    onBlur={() => this.updateJournalNoteOnExit()}
                                                                                                />
                                                                                            </FormGroup>
                                                                                            <FormGroup>
                                                                                                <Label>{'Anamnes'.translate(this.props.lang)}</Label>
                                                                                                <Input type='textarea' value={this.state.journalNoteObj.anamnes} style={{ height: 100 }} onChange={(e) => {
                                                                                                    let journalNoteObj = this.state.journalNoteObj;
                                                                                                    journalNoteObj.anamnes = striptags(e.target.value);
                                                                                                    this.setState({ journalNoteObj }, () => this.generatePdf())
                                                                                                }}
                                                                                                    onBlur={() => this.updateJournalNoteOnExit()}
                                                                                                />
                                                                                            </FormGroup>
                                                                                            <FormGroup>
                                                                                                <Label>{'Bedömning'.translate(this.props.lang)}</Label>
                                                                                                <Input type='textarea' value={this.state.journalNoteObj.bedomning} style={{ height: 100 }} onChange={(e) => {
                                                                                                    let journalNoteObj = this.state.journalNoteObj;
                                                                                                    journalNoteObj.bedomning = striptags(e.target.value);
                                                                                                    this.setState({ journalNoteObj }, () => this.generatePdf())
                                                                                                }}
                                                                                                    onBlur={() => this.updateJournalNoteOnExit()}
                                                                                                />
                                                                                            </FormGroup>
                                                                                            <FormGroup>
                                                                                                <Label>{'Åtgärd'.translate(this.props.lang)}</Label>
                                                                                                <Input type='textarea' value={this.state.journalNoteObj.atgard} style={{ height: 100 }} onChange={(e) => {
                                                                                                    let journalNoteObj = this.state.journalNoteObj;
                                                                                                    journalNoteObj.atgard = striptags(e.target.value);
                                                                                                    this.setState({ journalNoteObj }, () => this.generatePdf())
                                                                                                }}
                                                                                                    onBlur={() => this.updateJournalNoteOnExit()}
                                                                                                />
                                                                                            </FormGroup>

                                                                                            <div className="journal-note-buttons">

                                                                                                {
                                                                                                    this.state.htmlJournal ?
                                                                                                        <PdfSave lang={this.props.lang} html={this.state.htmlJournal} pdfName={this.generatePdfName()} />

                                                                                                        : null
                                                                                                }
                                                                                                <Button color='primary' onClick={() => {
                                                                                                    navigator.clipboard.writeText(this.state.textClipboard)
                                                                                                }}>{'Copy text'.translate(this.props.lang)}</Button>
                                                                                                <Button color='primary' disabled={true}>{'Export to journal system'.translate(this.props.lang)}</Button>
                                                                                            </div>


                                                                                        </div>
                                                                                        :
                                                                                        <div className="journal-note-content">
                                                                                            <FormGroup>
                                                                                                <Label>{'Kontaktorsak'.translate(this.props.lang)}</Label>
                                                                                                <Input type='text' value={this.state.journalNoteObj.kontaktorsak}
                                                                                                    onChange={(e) => {
                                                                                                        let journalNoteObj = this.state.journalNoteObj;
                                                                                                        journalNoteObj.kontaktorsak = striptags(e.target.value);
                                                                                                        this.setState({ journalNoteObj }, () => this.generatePdf())
                                                                                                    }}
                                                                                                    onBlur={() => this.updateJournalNoteOnExit()}
                                                                                                />
                                                                                            </FormGroup>
                                                                                            <FormGroup>
                                                                                                <Label>{'Status'.translate(this.props.lang)}</Label>
                                                                                                <Input type='textarea' value={this.state.journalNoteObj.status} style={{ height: 100 }} onChange={(e) => {
                                                                                                    let journalNoteObj = this.state.journalNoteObj;
                                                                                                    journalNoteObj.status = striptags(e.target.value);
                                                                                                    this.setState({ journalNoteObj }, () => this.generatePdf())
                                                                                                }}
                                                                                                    onBlur={() => this.updateJournalNoteOnExit()}
                                                                                                />
                                                                                            </FormGroup>
                                                                                            <FormGroup>
                                                                                                <Label>{'Anamnes'.translate(this.props.lang)}</Label>
                                                                                                <Input type='textarea' value={this.state.journalNoteObj.anamnes} style={{ height: 100 }} onChange={(e) => {
                                                                                                    let journalNoteObj = this.state.journalNoteObj;
                                                                                                    journalNoteObj.anamnes = striptags(e.target.value);
                                                                                                    this.setState({ journalNoteObj }, () => this.generatePdf())
                                                                                                }}
                                                                                                    onBlur={() => this.updateJournalNoteOnExit()}
                                                                                                />
                                                                                            </FormGroup>
                                                                                            <FormGroup>
                                                                                                <Label>{'Bedömning'.translate(this.props.lang)}</Label>
                                                                                                <Input type='textarea' value={this.state.journalNoteObj.bedomning} style={{ height: 100 }} onChange={(e) => {
                                                                                                    let journalNoteObj = this.state.journalNoteObj;
                                                                                                    journalNoteObj.bedomning = striptags(e.target.value);
                                                                                                    this.setState({ journalNoteObj }, () => this.generatePdf())
                                                                                                }}
                                                                                                    onBlur={() => this.updateJournalNoteOnExit()}
                                                                                                />
                                                                                            </FormGroup>
                                                                                            <FormGroup>
                                                                                                <Label>{'Åtgärd'.translate(this.props.lang)}</Label>
                                                                                                <Input type='textarea' value={this.state.journalNoteObj.atgard} style={{ height: 100 }} onChange={(e) => {
                                                                                                    let journalNoteObj = this.state.journalNoteObj;
                                                                                                    journalNoteObj.atgard = striptags(e.target.value);
                                                                                                    this.setState({ journalNoteObj }, () => this.generatePdf())
                                                                                                }}
                                                                                                    onBlur={() => this.updateJournalNoteOnExit()}
                                                                                                />
                                                                                            </FormGroup>

                                                                                            <div className="journal-note-buttons">

                                                                                                {
                                                                                                    this.state.htmlJournal ?
                                                                                                        <PdfSave lang={this.props.lang} html={this.state.htmlJournal} pdfName={this.generatePdfName()} />

                                                                                                        : null
                                                                                                }
                                                                                                <Button color='primary' onClick={() => {
                                                                                                    navigator.clipboard.writeText(this.state.textClipboard)
                                                                                                }}>{'Copy text'.translate(this.props.lang)}</Button>
                                                                                                <Button color='primary' disabled={true}>{'Export to journal system'.translate(this.props.lang)}</Button>
                                                                                            </div>


                                                                                        </div>
                                                                            }
                                                                        </div>
                                                                    </div>
                                                                    {item && !item.closed ?

                                                                        <div
                                                                            className={
                                                                                this.props.uData && this.props.uData.userLevel == 20 && localStorage.getItem('profile') != 'patient' && item /*&& item.referral*/ && !this.state.chat[item._id].journalNote && (item.users.filter(item => item.level < 20).length || item.referral || item.journalNote) && this.chatWrapRef && false ?
                                                                                    "send-message with-phrase with-journal"
                                                                                    :
                                                                                    this.props.uData && this.props.uData.userLevel == 20 && localStorage.getItem('profile') != 'patient' && item /*&& item.referral*/ && this.state.chat[item._id].journalNote ?
                                                                                        "send-message with-phrase with-journal with-journal-open"
                                                                                        :
                                                                                        this.props.uData && this.props.uData.userLevel == 20 && localStorage.getItem('profile') != 'patient' && item /*&& item.referral*/ && (this.chatWrapRef && true) && (item.users.filter(item => item.level < 20).length || item.referral) ?
                                                                                            "send-message with-phrase with-journal chat-wrap-small"
                                                                                            :
                                                                                            this.props.uData && this.props.uData.userLevel == 20 && localStorage.getItem('profile') != 'patient' ?
                                                                                                "send-message with-phrase" :
                                                                                                "send-message"
                                                                            }
                                                                        >

                                                                            <div className="chat-attach-menu-wrap">

                                                                                <button className='more-icon' onClick={() => {
                                                                                    let chat = this.state.chat;
                                                                                    chat[item._id].mobileBtnsShow = !chat[item._id].mobileBtnsShow;

                                                                                    this.setState({ chat })
                                                                                }}><Isvg src={moreIcon} style={{ position: 'absolute', width: 13, height: 13 }} /></button>
                                                                                <div className={this.state.chat[item._id].mobileBtnsShow ? 'mobile-btns-upload-phrase show-mobile-btns-upload-phrase' : 'mobile-btns-upload-phrase'}>
                                                                                    <div style={{ position: 'relative', width: '100%', height: '100%' }}>

                                                                                        <div className="overlay-test" onClick={() => {
                                                                                            let chat = this.state.chat;
                                                                                            chat[item._id].mobileBtnsShow = !chat[item._id].mobileBtnsShow;

                                                                                            this.setState({ chat })
                                                                                        }} />
                                                                                        <button className={this.state._documentUploading == item._id ? 'attach-menu uploading' : this.state.chat && this.state.chat[item._id] && this.state.chat[item._id].document ? 'attach-menu uploaded' : "attach-menu"} onClick={() => {
                                                                                            let chat = this.state.chat;
                                                                                            chat[item._id].mobileBtnsShow = !chat[item._id].mobileBtnsShow;

                                                                                            this.setState({ chat, uploadDocumentChat: item._id })
                                                                                            if (this.documentInput) this.documentInput.click()
                                                                                        }}>
                                                                                            <div className="button-menu-icon-wrap">
                                                                                                <Isvg src={attach} style={{ position: 'absolute', width: 17, height: 17 }} />
                                                                                            </div>
                                                                                            <div className='chat-icons-text'>
                                                                                                {'Attach file'.translate(this.props.lang)}
                                                                                            </div>
                                                                                        </button>


                                                                                        <button className={this.state._imageUploading == item._id ? 'image-menu uploading' : this.state.chat && this.state.chat[item._id] && this.state.chat[item._id].image ? 'image-menu uploaded' : "image-menu"} onClick={() => {
                                                                                            let chat = this.state.chat;
                                                                                            chat[item._id].mobileBtnsShow = !chat[item._id].mobileBtnsShow;

                                                                                            this.setState({ chat, uploadImageChat: item._id })
                                                                                            if (this.imageInput) this.imageInput.click()
                                                                                        }}>
                                                                                            <div className="button-menu-icon-wrap">
                                                                                                <Isvg src={image} style={{ position: 'absolute', width: 17, height: 17 }} />
                                                                                            </div>
                                                                                            <div className='chat-icons-text'>
                                                                                                {'Attach image'.translate(this.props.lang)}
                                                                                            </div>
                                                                                        </button>
                                                                                        <button className={this.state._videoUploading == item._id ? 'video-menu uploading' : this.state.chat && this.state.chat[item._id] && this.state.chat[item._id].video ? 'video-menu uploaded' : "video-menu"} onClick={() => {
                                                                                            let chat = this.state.chat;
                                                                                            chat[item._id].mobileBtnsShow = !chat[item._id].mobileBtnsShow;

                                                                                            this.setState({ chat, uploadVideoChat: item._id })
                                                                                            if (this.videoContentInput) this.videoContentInput.click()
                                                                                        }}>
                                                                                            <div className="button-menu-icon-wrap">
                                                                                                <Isvg src={videoIcon} style={{ position: 'absolute', width: 17, height: 17 }} />
                                                                                            </div>
                                                                                            <div className='chat-icons-text'>
                                                                                                {'Attach video'.translate(this.props.lang)}
                                                                                            </div>
                                                                                        </button>

                                                                                        {
                                                                                            this.props.uData && this.props.uData.userLevel == 20 && localStorage.getItem('profile') != 'patient' ?
                                                                                                <button className={"phrase-menu"} onClick={() => {
                                                                                                    let chat = this.state.chat;
                                                                                                    chat[item._id].mobileBtnsShow = !chat[item._id].mobileBtnsShow;

                                                                                                    this.setState({ quickPhraseModal: item._id, chat }, this.get)
                                                                                                }}>
                                                                                                    <div className="button-menu-icon-wrap"><Isvg src={phraseIcon} style={{ position: 'absolute', width: 17, height: 17 }} /></div>

                                                                                                    <div className='chat-icons-text'>
                                                                                                        {'Quick phrase'.translate(this.props.lang)}
                                                                                                    </div>
                                                                                                </button>
                                                                                                :
                                                                                                null
                                                                                        }
                                                                                        {/* {
                                                                                    this.props.uData && this.props.uData.userLevel == 20 && localStorage.getItem('profile') != 'patient' ?
                                                                                        <button className={"health-care-forms-menu"} onClick={() => {
                                                                                            let chat = this.state.chat;
                                                                                            chat[item._id].mobileBtnsShow = !chat[item._id].mobileBtnsShow;

                                                                                            this.setState({ healthCareModal: item._id, chat })
                                                                                        }}>
                                                                                            <div className="button-menu-icon-wrap"><Isvg src={formIcon} style={{ position: 'absolute', width: 17, height: 17 }} /></div>

                                                                                            <div className='chat-icons-text'>
                                                                                                {'Attach form'.translate(this.props.lang)}
                                                                                            </div>
                                                                                        </button>
                                                                                        :
                                                                                        null
                                                                                } */}
                                                                                        {/* {
                                                                                this.props.uData && this.props.uData.userLevel == 20 && item && (item.users.filter(item => item.level < 20).length || item.referral || item.journalNote) && localStorage.getItem('profile') != 'patient' ?
                                                                                    <button className={"journal-menu"} onClick={() => {
                                                                                        let chat = this.state.chat;
                                                                                        chat[item._id].mobileBtnsShow = !chat[item._id].mobileBtnsShow;

                                                                                        this.setState({ chat })
                                                                                        this.findHealthCareFormular(item._id)
                                                                                    }}>
                                                                                        <div className="button-menu-icon-wrap">
                                                                                            <Isvg src={journalIcon} style={{ position: 'absolute', width: 17, height: 17 }} />
                                                                                        </div>
                                                                                        <div className='chat-icons-text'>
                                                                                            {'Journal note'.translate(this.props.lang)}
                                                                                        </div>
                                                                                    </button>
                                                                                    :
                                                                                    null
                                                                            } */}
                                                                                    </div>
                                                                                </div>
                                                                            </div>



                                                                            <Input className='send-message-textarea' onKeyDown={(e) => {
                                                                                if (e.keyCode == 13 && !e.shiftKey) {
                                                                                    e.preventDefault();
                                                                                    this.sendMessage(item._id);
                                                                                    return false;
                                                                                }
                                                                                this.emitUserTyping(item._id)
                                                                            }} placeholder={"Write a message...".translate(this.props.lang)}
                                                                                type="textarea"
                                                                                id="message"
                                                                                onChange={(e) => {
                                                                                    let element = e.target;
                                                                                    let chat = this.state.chat;
                                                                                    chat[item._id].message = e.target.value;

                                                                                    let messageHeight = this.state.messageHeight;
                                                                                    if (!messageHeight) {
                                                                                        messageHeight = {}
                                                                                    }
                                                                                    messageHeight[item._id] = 64;

                                                                                    messageHeight[item._id] = element.scrollHeight < 220 ? element.scrollHeight : 220;
                                                                                    this.setState({ messageHeight, chat }, () => {
                                                                                        // let messageHeight = this.state.messageHeight;
                                                                                        // messageHeight[item._id] = element.scrollHeight < 220 ? element.scrollHeight : 220;
                                                                                        // this.setState({ messageHeight })

                                                                                    })

                                                                                }}
                                                                                value={this.state.chat && this.state.chat[item._id] && this.state.chat[item._id].message}
                                                                                style={{ maxHeight: 220, minHeight: 64, height: this.state.messageHeight && this.state.chat && this.state.messageHeight[item._id] && this.state.chat[item._id] && this.state.chat[item._id].message ? this.state.messageHeight[item._id] : 64 }}
                                                                            />
                                                                            {item && item.supportedLanguages && item.supportedLanguages.length && this.props.uData.clinicGroups[selectedGroupIdx] && this.props.uData.clinicGroups[selectedGroupIdx].haveTranslation ?

                                                                                <UncontrolledDropdown className={this.state.chat && this.state.chat[item._id] && this.state.chat[item._id].conversation && this.state.chat[item._id].conversation.memberLanguages && this.state.chat[item._id].conversation.memberLanguages[this.props.uData._id] ? "translate translate-active" : "translate"}>

                                                                                    <DropdownToggle>
                                                                                        <Isvg src={translateIcon} />
                                                                                        <div className='chat-icons-tooltip'>
                                                                                            {'Translation is done by Google and may not work properly!'.translate(this.props.lang)}
                                                                                        </div>
                                                                                    </DropdownToggle>
                                                                                    <DropdownMenu>
                                                                                        <DropdownItem className={item && item.memberLanguages && !item.memberLanguages[this.props.uData._id] ? 'active' : ''} onClick={() => this.setTranslationLanguage(null, item._id)} >{'No translate'.translate(this.props.lang)}</DropdownItem>

                                                                                        {


                                                                                            item.supportedLanguages.map((lang, lidx) => {

                                                                                                return (

                                                                                                    <DropdownItem className={item && item.memberLanguages && item.memberLanguages[this.props.uData._id] == lang.code ? 'active' : ''} onClick={() => this.setTranslationLanguage(lang.code, item._id)} key={lidx}>{lang.name}</DropdownItem>

                                                                                                )
                                                                                            })

                                                                                        }
                                                                                    </DropdownMenu>
                                                                                </UncontrolledDropdown>
                                                                                :
                                                                                null
                                                                            }
                                                                            <div className='fb-like-chat-resize-icon' onMouseDown={() => {
                                                                                this.setState({
                                                                                    messageRisaze: true
                                                                                })
                                                                            }} onMouseUp={() => {
                                                                                this.setState({
                                                                                    messageRisaze: false
                                                                                })
                                                                            }}>
                                                                                <Isvg src={resizeMessageBox} />
                                                                            </div>
                                                                            <button disabled={this.state._imageUploading} className="send" onClick={() => this.sendMessage(item._id)}>
                                                                                <Isvg src={send} />
                                                                                <div className='chat-icons-tooltip'>
                                                                                    {'Send'.translate(this.props.lang)}
                                                                                </div>
                                                                            </button>
                                                                        </div>
                                                                        :
                                                                        null
                                                                    }
                                                                    {item && item.closed && this.props.uData && this.props.uData.userLevel >= 20 && localStorage.getItem('profile') == 'doctor' && item && item.members && item.members[0] == this.props.uData._id ?
                                                                        <div className='delete-conversation-wrap'>
                                                                            <Button color='danger' onClick={() => this.setState({ deleteConversationModal: item })}>{'Delete conversation'.translate(this.props.lang)}</Button>
                                                                        </div>
                                                                        :
                                                                        null
                                                                    }
                                                                </div></>}
                                                        </div>
                                                        {this.state.profileModal && this.state.profileModal.conversation == item._id ?
                                                            <Modal isOpen={this.state.profileModal} centered zIndex={999999999}>
                                                                <ModalHeader toggle={() => this.setState({ profileModal: !this.state.profileModal })} close={<button type="button" className="close" style={{ position: 'absolute', top: '15px', right: '15px' }} onClick={() => this.setState({ profileModal: !this.state.profileModal })}>&times;</button>}>
                                                                    <h4>{'Patient profile'.translate(this.props.lang)}</h4>
                                                                </ModalHeader>
                                                                <ModalBody>
                                                                    <FormGroup>
                                                                        <Label>{'Social security number'.translate(this.props.lang)}</Label>
                                                                        <Input type='text' disabled
                                                                            value={this.state.profileModal.socialSecurityNumber} />
                                                                    </FormGroup>
                                                                    <FormGroup>
                                                                        <Label>{'Name'.translate(this.props.lang)}</Label>
                                                                        <Input type='text' disabled
                                                                            value={this.state.profileModal.userData && this.state.profileModal.userData.name} />
                                                                    </FormGroup>
                                                                    <FormGroup>
                                                                        <Label>{'Email'.translate(this.props.lang)}</Label>
                                                                        <Input type='text' disabled
                                                                            value={this.state.profileModal.parents ? this.state.profileModal.parents[0].email : this.state.profileModal.email} />
                                                                    </FormGroup>
                                                                    <FormGroup>
                                                                        <Label>{'Phone'.translate(this.props.lang)}</Label>
                                                                        <Input type='text' disabled
                                                                            value={this.state.profileModal.parents ? this.state.profileModal.parents[0].phone : this.state.profileModal.phone} />
                                                                    </FormGroup>
                                                                    <FormGroup>
                                                                        <Label>{'Address'.translate(this.props.lang)}</Label>
                                                                        <Input type='text' disabled
                                                                            value={this.state.profileModal.parents ? this.state.profileModal.parents[0].address : this.state.profileModal.street + ',' + ' ' + this.state.profileModal.zip + ' ' + this.state.profileModal.city} />
                                                                    </FormGroup>


                                                                </ModalBody>
                                                                <ModalFooter className='buttons-right-blue'>
                                                                    <Button color="primary" onClick={() => this.setState({ profileModal: !this.state.profileModal })}>{'Close'.translate(this.props.lang)}</Button>

                                                                </ModalFooter>
                                                            </Modal>

                                                            : null}
                                                        {/* <input ref={(node) => this.documentInput = node} type="file" accept=".doc, .docx, .pdf" key={this.state.uploadTimestampDoc} onChange={this.uploadDocument} style={{ pointerEvents: 'none', opacity: 0 }} />
                                                        <input ref={(node) => this.imageInput = node} type="file" accept="image/png, image/jpeg, image/jpg, image/gif, image/svg" key={this.state.uploadTimestamp} onChange={this.uploadImage} style={{ pointerEvents: 'none', opacity: 0 }} />
                                                        <input ref={(node) => this.videoContentInput = node} type="file" accept=".mp4,.mov" key={this.state.uploadTimestampVideo} onChange={this.uploadVideo} style={{ pointerEvents: 'none', opacity: 0 }} /> */}
                                                        <input
                                                            ref={(node) => this.documentInput = node}
                                                            type="file" accept=".doc, .docx, .pdf"
                                                            key={this.state.uploadTimestampDoc}
                                                            onChange={(e) => {
                                                                let input = e?.target;
                                                                let fileName = input?.files && input?.files[0] && input?.files[0].name ? input?.files[0].name : null;
                                                                let extension = fileName && fileName.indexOf('.') !== -1 && fileName.split('.')[1] ? fileName.split('.')[1] : null;
                                                                extension = extension?.toLowerCase();
                                                                let type = 'document';
                                                                for (var key in UPLOAD_EXTENSIONS) {
                                                                    if (UPLOAD_EXTENSIONS[key] && UPLOAD_EXTENSIONS[key].indexOf(extension) !== -1) {
                                                                        type = key;
                                                                    }
                                                                }
                                                                if (type === 'video') this.uploadVideo(e);
                                                                else if (type === 'image') this.uploadImage(e);
                                                                else this.uploadDocument(e)

                                                            }}
                                                            style={{ pointerEvents: 'none', opacity: 0 }}
                                                        />
                                                        <input
                                                            ref={(node) => this.imageInput = node}
                                                            type="file"
                                                            accept="image/png, image/jpeg, image/jpg, image/gif, image/svg"
                                                            key={this.state.uploadTimestamp}
                                                            onChange={(e) => {
                                                                let input = e?.target;
                                                                let fileName = input?.files && input?.files[0] && input?.files[0].name ? input?.files[0].name : null;
                                                                let extension = fileName && fileName.indexOf('.') !== -1 && fileName.split('.')[1] ? fileName.split('.')[1] : null;
                                                                extension = extension?.toLowerCase();
                                                                let type = 'image';
                                                                for (var key in UPLOAD_EXTENSIONS) {
                                                                    if (UPLOAD_EXTENSIONS[key] && UPLOAD_EXTENSIONS[key].indexOf(extension) !== -1) {
                                                                        type = key;
                                                                    }
                                                                }
                                                                if (type === 'video') this.uploadVideo(e);
                                                                else if (type === 'document') this.uploadDocument(e);
                                                                else this.uploadImage(e)

                                                            }}
                                                            style={{ pointerEvents: 'none', opacity: 0 }}
                                                        />
                                                        <input
                                                            ref={(node) => this.videoContentInput = node}
                                                            type="file"
                                                            accept=".mp4, .avi, .mov"
                                                            key={this.state.uploadTimestampVideo}
                                                            onChange={(e) => {
                                                                let input = e?.target;
                                                                let fileName = input?.files && input?.files[0] && input?.files[0].name ? input?.files[0].name : null;
                                                                let extension = fileName && fileName.indexOf('.') !== -1 && fileName.split('.')[1] ? fileName.split('.')[1] : null;
                                                                extension = extension?.toLowerCase();
                                                                let type = 'video';
                                                                for (var key in UPLOAD_EXTENSIONS) {
                                                                    if (UPLOAD_EXTENSIONS[key] && UPLOAD_EXTENSIONS[key].indexOf(extension) !== -1) {
                                                                        type = key;
                                                                    }
                                                                }
                                                                if (type === 'image') this.uploadImage(e);
                                                                else if (type === 'document') this.uploadDocument(e);
                                                                else this.uploadVideo(e)

                                                            }}
                                                            style={{ pointerEvents: 'none', opacity: 0 }}
                                                        />
                                                        {
                                                            this.state.quickPhraseModal && this.state.quickPhraseModal == item._id ?
                                                                <Modal isOpen={this.state.quickPhraseModal} size="lg" centered zIndex={99999999}>
                                                                    <ModalHeader toggle={() => this.setState({ quickPhraseModal: !this.state.quickPhraseModal, searchPhrases: '' })} close={<button type="button" className="close" style={{ position: 'absolute', top: '15px', right: '15px' }} onClick={() => this.setState({ quickPhraseModal: !this.state.quickPhraseModal, searchPhrases: '' })}>&times;</button>}>{'Quick phrases'.translate(this.props.lang)}</ModalHeader>

                                                                    <ModalBody>

                                                                        <div className="search-box-wrap">
                                                                            <FormGroup>
                                                                                <Label>{'Search'.translate(this.props.lang)}</Label>
                                                                                <Input value={this.state.searchPhrases}
                                                                                    onChange={(e) => {
                                                                                        this.setState({ searchPhrases: striptags(e.target.value) }, () => this.get())
                                                                                    }}
                                                                                />
                                                                            </FormGroup>
                                                                        </div>

                                                                        {
                                                                            this.state.phrases && this.state.phrases.map((phrase, idx) => {
                                                                                return (
                                                                                    <div className="quick-phrase-wrap">
                                                                                        <div className="content">
                                                                                            <div className="title">{phrase.title}</div>
                                                                                            <div className="phrase">
                                                                                                {
                                                                                                    phrase.phrase.split('\n').length ?
                                                                                                        phrase.phrase.split('\n').map((text, t) => {
                                                                                                            if (t != phrase.phrase.split('\n').length - 1)
                                                                                                                return (
                                                                                                                    <>{text}<br /></>
                                                                                                                )
                                                                                                            else
                                                                                                                return (
                                                                                                                    text
                                                                                                                )
                                                                                                        })
                                                                                                        :
                                                                                                        phrase.phrase
                                                                                                }
                                                                                            </div>
                                                                                        </div>
                                                                                        <Button color='primary' onClick={() => {
                                                                                            let chat = this.state.chat;
                                                                                            chat[this.state.quickPhraseModal].message = phrase.phrase;
                                                                                            this.setState({ chat, quickPhraseModal: false, searchPhrases: '' })
                                                                                        }}>{'Use'.translate(this.props.lang)}</Button>

                                                                                    </div>
                                                                                )
                                                                            })
                                                                        }

                                                                    </ModalBody>

                                                                    <ModalFooter style={{ display: 'flex', justifyContent: 'flex-end' }}>

                                                                        <Button color="primary" onClick={() => this.setState({ quickPhraseModal: null, searchPhrases: '' })}> {'Close'.translate(this.props.lang)} </Button>

                                                                    </ModalFooter>



                                                                </Modal>
                                                                :
                                                                null
                                                        }
                                                        {
                                                            this.state.healthCareModal && this.state.healthCareModal == item._id ?
                                                                <Modal isOpen={this.state.healthCareModal} size="lg" centered zIndex={99999999}>
                                                                    <ModalHeader toggle={() => this.setState({ healthCareModal: !this.state.healthCareModal })} close={<button type="button" className="close" style={{ position: 'absolute', top: '15px', right: '15px' }} onClick={() => this.setState({ healthCareModal: !this.state.healthCareModal })}>&times;</button>}>{'General health-care forms'.translate(this.props.lang)}</ModalHeader>

                                                                    <ModalBody>
                                                                        <div className="search-box-wrap">
                                                                            <FormGroup>
                                                                                <Label>{'Search'.translate(this.props.lang)}</Label>
                                                                                <Input value={this.state.searchHealthCare}
                                                                                    onChange={(e) => {
                                                                                        this.setState({ searchHealthCare: striptags(e.target.value) }, () => this.get())
                                                                                    }}
                                                                                />
                                                                            </FormGroup>
                                                                        </div>
                                                                        <div className="general-health-care-wrap">
                                                                            {
                                                                                this.state.generalHealthCare && this.state.generalHealthCare.items && this.state.generalHealthCare.items.map((healthCare, idx) => {
                                                                                    return (
                                                                                        <div className="health-care-wrap">
                                                                                            <div className="content">
                                                                                                <div className="title">
                                                                                                    {healthCare.name}
                                                                                                    <span>
                                                                                                        &nbsp;&nbsp;/&nbsp;&nbsp;
                                                                                                        {healthCare.description}</span>
                                                                                                </div>

                                                                                            </div>
                                                                                            <Button color='primary' onClick={() => {
                                                                                                this.sendHealthCare(healthCare)
                                                                                            }}>{'Send'.translate(this.props.lang)}</Button>

                                                                                        </div>
                                                                                    )
                                                                                })
                                                                            }
                                                                        </div>


                                                                    </ModalBody>

                                                                    <ModalFooter style={{ display: 'flex', justifyContent: 'flex-end' }}>

                                                                        <Button color="primary" onClick={() => this.setState({ healthCareModal: null })}> {'Close'.translate(this.props.lang)} </Button>

                                                                    </ModalFooter>



                                                                </Modal>
                                                                :
                                                                null
                                                        }
                                                        {
                                                            this.state.questionaryModal ?
                                                                <Modal isOpen={this.state.questionaryModal} size="lg" centered zIndex={99999999}>
                                                                    <ModalHeader toggle={() => this.setState({ questionaryModal: null, settedRef: false, referralContentRef: null, activeQuestionary: null, questionaryMessageActive: null, activeReferralContent: null, sections: null, validationQuestionary: false, validationIntelligentQuestionary: false })} close={<button type="button" className="close" style={{ position: 'absolute', top: '15px', right: '15px' }} onClick={() => this.setState({ questionaryModal: null, settedRef: false, activeQuestionary: null, questionaryMessageActive: null, activeReferralContent: null, sections: null, validationQuestionary: false, validationIntelligentQuestionary: false })}>&times;</button>}>{this.state.activeReferralContent ? ''.translate(this.props.lang) : 'Run questionary'.translate(this.props.lang)}</ModalHeader>

                                                                    <ModalBody>


                                                                        {
                                                                            // this.state.activeReferralContent ?
                                                                            <div ref={(node) => { if (!this.state.referralContentRef) this.setState({ referralContentRef: node }, this.forceUpdate) }}>
                                                                                <div dangerouslySetInnerHTML={{ __html: this.state.activeReferralContent }} onChange={() => { }}></div>

                                                                                <br />
                                                                            </div>

                                                                            // :
                                                                            // null
                                                                        }
                                                                        {
                                                                            this.state.activeQuestionary ?
                                                                                <>
                                                                                    {
                                                                                        !this.state.activeQuestionary.intelligentReferral ?
                                                                                            // this.state.activeReferralContent && this.state.referralContentRef ?
                                                                                            <CreateReferralModal {...this.props} answer={(item) => {
                                                                                                this.setState({ questionaryAnswers: item })
                                                                                            }} referral={{ name: this.state.activeQuestionary.name, description: this.state.activeQuestionary.description }}
                                                                                                referralContentRef={this.state.referralContentRef}

                                                                                                disabledForm={typeof window != 'undefined' && localStorage?.getItem?.('profile') == 'doctor' && this.props.uData && this.props.uData.userLevel == 20 ? true : false}
                                                                                                answers={this.state.questionaryAnswers}
                                                                                                forPrint={this.state.activeReferralContent ? true : false}
                                                                                                sections={this.generateSections()}></CreateReferralModal>
                                                                                            // :
                                                                                            // null
                                                                                            :
                                                                                            // this.state.referralContentRef ?
                                                                                            <CreateReferralIntelligentModal {...this.props}
                                                                                                referral={{ name: this.state.activeQuestionary.name, description: this.state.activeQuestionary.description }}
                                                                                                answer={(item, isEnd) => {
                                                                                                    this.setState({ questionaryAnswers: item, validationIntelligentQuestionary: !isEnd, validationQuestionary: false })
                                                                                                }}
                                                                                                referralContentRef={this.state.referralContentRef}

                                                                                                disabledForm={typeof window != 'undefined' && localStorage?.getItem?.('profile') == 'doctor' && this.props.uData && this.props.uData.userLevel == 20 ? true : false}
                                                                                                answers={this.state.questionaryAnswers}
                                                                                                forPrint={this.state.activeReferralContent ? true : false}
                                                                                                sections={this.generateSections()}></CreateReferralIntelligentModal>
                                                                                        // :
                                                                                        // null
                                                                                    }
                                                                                </>

                                                                                :
                                                                                null
                                                                        }

                                                                        {
                                                                            this.state.validationQuestionary ?
                                                                                <p style={{ color: 'red' }}>{'Please fill in all fields'.translate(this.props.lang)}</p>
                                                                                :
                                                                                null
                                                                        }
                                                                        {
                                                                            this.state.activeQuestionary && this.state.activeReferralContent && this.state.questionaryAnswers ?

                                                                                <div style={{ position: 'fixed', right: '100%' }}>
                                                                                    <div ref={(node) => { this.referralRef = node; if (!this.state.settedRef) this.setState({ settedRef: true }) }} className="print-referral-ref">


                                                                                        {
                                                                                            this.state.activeReferralContent ?
                                                                                                <div ref={(node) => { if (!this.state.referralContentRef) this.setState({ referralContentRef: node }, this.forceUpdate) }}>

                                                                                                    <div dangerouslySetInnerHTML={{ __html: this.state.activeReferralContent }} onChange={() => { }}></div>

                                                                                                    <br />

                                                                                                </div>

                                                                                                :
                                                                                                null
                                                                                        }
                                                                                        {
                                                                                            !this.state.activeQuestionary.intelligentReferral ?

                                                                                                this.state.activeReferralContent && this.state.referralContentRef ?
                                                                                                    <CreateReferralModal
                                                                                                        {...this.props}
                                                                                                        answer={(item) => {
                                                                                                            this.setState({ questionaryAnswers: item })
                                                                                                        }}
                                                                                                        referralContentRef={this.state.referralContentRef}
                                                                                                        referral={{ name: this.state.activeQuestionary.name, description: this.state.activeQuestionary.description }}
                                                                                                        disabledForm={typeof window != 'undefined' && localStorage?.getItem?.('profile') == 'doctor' && this.props.uData && this.props.uData.userLevel == 20 ? true : false}
                                                                                                        answers={this.state.questionaryAnswers}
                                                                                                        forPrint={this.state.activeReferralContent ? true : false}
                                                                                                        sections={this.generateSections()}></CreateReferralModal>
                                                                                                    :
                                                                                                    null

                                                                                                :
                                                                                                this.state.referralContentRef ?
                                                                                                    <CreateReferralIntelligentModal
                                                                                                        {...this.props}
                                                                                                        answer={(item, isEnd) => {
                                                                                                            this.setState({ questionaryAnswers: item, validationIntelligentQuestionary: !isEnd, validationQuestionary: false })
                                                                                                        }}
                                                                                                        referralContentRef={this.state.referralContentRef}

                                                                                                        referral={{ name: this.state.activeQuestionary.name, description: this.state.activeQuestionary.description }}
                                                                                                        disabledForm={true}
                                                                                                        answers={this.state.questionaryAnswers}
                                                                                                        forPrint={true}
                                                                                                        sections={this.generateSections()}></CreateReferralIntelligentModal>
                                                                                                    :
                                                                                                    null

                                                                                        }

                                                                                    </div>

                                                                                </div>
                                                                                :
                                                                                null
                                                                        }
                                                                    </ModalBody>

                                                                    <ModalFooter style={{ display: 'flex', justifyContent: 'flex-end' }} >
                                                                        {
                                                                            !this.state.questionarySave && ((this.props.uData && this.props.uData.userLevel == 1) || (this.props.uData && this.props.uData.userLevel == 2) || ((typeof window != 'undefined' && localStorage?.getItem?.('profile') == 'patient') && (this.props.uData && this.props.uData.userLevel == 20))) ?
                                                                                <Button color="primary" onClick={() => this.sendHealthCareQuestionary()}> {'Send'.translate(this.props.lang)} </Button>
                                                                                :
                                                                                null
                                                                        }
                                                                        {
                                                                            this.state.activeReferralContent && this.referralRef && this.state.settedRef ?
                                                                                <PrintHelper lang={this.props.lang} element={this.referralRef} />
                                                                                :
                                                                                null
                                                                        }
                                                                        {
                                                                            this.state.activeReferralContent && this.referralRef && this.state.settedRef ?
                                                                                <PdfSaveV2 lang={this.props.lang} element={this.referralRef} pdfName={this.state.activeQuestionary && this.state.activeQuestionary.name && generateAlias(this.state.activeQuestionary.name) + '_' + moment.unix(Math.floor(new Date().getTime() / 1000)).format(this.props.dateFormat)} />
                                                                                :
                                                                                null
                                                                        }
                                                                        <Button color="primary" onClick={() => this.setState({ questionaryModal: null, settedRef: false, referralContentRef: null, activeQuestionary: null, questionaryMessageActive: null, activeReferralContent: null, sections: null, validationQuestionary: false, validationIntelligentQuestionary: false })}> {'Close'.translate(this.props.lang)} </Button>

                                                                    </ModalFooter>



                                                                </Modal>
                                                                :
                                                                null
                                                        }
                                                        {this.state.referral && this.state.referral._id == item._id && this.state.referral.referral ?
                                                            <Modal isOpen={this.state.referral} size="lg" centered zIndex={99999999}>
                                                                <ModalHeader toggle={() => this.setState({ referral: !this.state.referral })} close={<button type="button" className="close" style={{ position: 'absolute', top: '15px', right: '15px' }} onClick={() => this.setState({ referral: !this.state.referral })}>&times;</button>}>{'Referral preview'.translate(this.props.lang)}</ModalHeader>

                                                                <ModalBody>
                                                                    <div dangerouslySetInnerHTML={{ __html: this.state.referral.referral.referralContent }} onChange={() => { }}></div>
                                                                    <div>
                                                                        <p> <strong>{'Status:'.translate(this.props.lang)}</strong> {humanReadable[this.state.referral.referral.status].translate(this.props.lang)}</p>
                                                                        {this.state.referral.referral.reason ?
                                                                            <p> <strong>{'Reason:'.translate(this.props.lang)}</strong> {this.state.referral.referral.reason ? this.state.referral.referral.reason : ''}</p>
                                                                            :
                                                                            null}
                                                                        {this.state.referral.referral.patientReservedTimestamp ?
                                                                            <p> <strong>{'Scheduled time:'.translate(this.props.lang)}</strong> {moment.unix(this.state.referral.referral.patientReservedTimestamp).format(`${this.props.dateFormat} HH:mm`)}</p>
                                                                            :
                                                                            null}

                                                                        {
                                                                            this.state.referral.referral.status == 'patient-requested-another-time' ?
                                                                                <p> <strong>{'Requested time:'.translate(this.props.lang)}</strong> {moment.unix(this.state.referral.referral.patientRequestedTimestamp).format(`${this.props.dateFormat} HH:mm`)}</p>

                                                                                :
                                                                                null
                                                                        }

                                                                    </div>

                                                                </ModalBody>

                                                                <ModalFooter>

                                                                    <Button color="primary" onClick={() => this.setState({ referral: null })}> {'Close'.translate(this.props.lang)} </Button>

                                                                </ModalFooter>



                                                            </Modal>
                                                            :
                                                            null
                                                        }
                                                        <DeleteModal
                                                            lang={this.props.lang}
                                                            zIndex={99999999999}
                                                            isOpen={this.state.deleteMessageModal}
                                                            toggle={() => this.setState({ deleteMessageModal: null })}
                                                            handler={() => { this.deleteMessage(this.state.deleteMessageModal); this.setState({ deleteMessageModal: null }) }}
                                                        >
                                                            {'Are you sure you want to delete the selected message?'.translate(this.props.lang)}
                                                        </DeleteModal>
                                                        <DeleteModal
                                                            lang={this.props.lang}
                                                            zIndex={99999999999}
                                                            isOpen={this.state.closeCaseModal && this.state.closeCaseModal._id == item._id}
                                                            toggle={() => this.setState({ closeCaseModal: null })}
                                                            handler={() => { this.closeCase(this.state.closeCaseModal); this.setState({ closeCaseModal: null }) }}
                                                        >
                                                            {'Do you want to close the case and conversation'.translate(this.props.lang)} <strong>{this.state.closeCaseModal && this.state.closeCaseModal.name}</strong>?
                                                        </DeleteModal>
                                                        <DeleteModal
                                                            lang={this.props.lang}
                                                            zIndex={99999999999}
                                                            isOpen={this.state.closeModal && this.state.closeModal._id == item._id}
                                                            toggle={() => this.setState({ closeModal: null })}
                                                            handler={() => { this.close(this.state.closeModal._id); this.setState({ closeModal: null }) }}
                                                        >
                                                            {'Close conversation'.translate(this.props.lang)} <strong>{this.state.closeModal && this.state.closeModal.name}</strong>?
                                                        </DeleteModal>

                                                    </div>
                                                    :
                                                    <div className="clicked-chat-item"
                                                        onClick={() => {
                                                            // let openedChats = this.state.openedChats;

                                                            // if (openedChats.filter(chat => chat._id == item._id).length == 0) {
                                                            //     openedChats.push(item)
                                                            // }

                                                            // this.setState({
                                                            //     openedChats,
                                                            // })
                                                            if (item && item.members && item.members.length) {
                                                                if (item.members[0] == this.props.uData._id) {
                                                                    this.openChat(item.members[1])
                                                                } else {
                                                                    this.openChat(item.members[0])

                                                                }
                                                            }

                                                        }}
                                                    >

                                                        <div style={{ display: 'flex', alignItems: 'center' }}>
                                                            {
                                                                item._newMessage ?

                                                                    <Isvg src={newMessage} className="new-message-icon" style={{ marginRight: 5 }} />
                                                                    :

                                                                    null

                                                            }
                                                            {
                                                                item.name && item.name.length < 28 ?
                                                                    this.props.lang == 'se' ? item.name.replace('Direct', 'Direkt') : item.name
                                                                    :
                                                                    this.props.lang == 'se' ? (item.name.replace('Direct', 'Direkt').substring(0, 25) + '...') : (item.name.substring(0, 25) + '...')
                                                            }
                                                        </div>
                                                        <div className="close-chat" onClick={(e) => {
                                                            e.stopPropagation()
                                                            let openedChats = this.state.openedChats;
                                                            let clickedChats = this.state.clickedChats;
                                                            let message = this.state.message;

                                                            let newOpenedChats = []
                                                            for (let i = 0; i < openedChats.length; i++) {
                                                                if (openedChats[i]._id != item._id) {
                                                                    newOpenedChats.push(openedChats[i])
                                                                }
                                                            }
                                                            let newClickedChats = [];
                                                            for (let i = 0; i < clickedChats.length; i++) {
                                                                if (clickedChats[i]._id != item._id) {
                                                                    newClickedChats.push(clickedChats[i])
                                                                }
                                                            }
                                                            for (var key in message) {
                                                                if (key == item._id) {
                                                                    delete message[key]
                                                                }
                                                            }
                                                            if (item && item._id) {
                                                                this.props.registerClinicGroupLogs({
                                                                    type: 'closeConversation',
                                                                    conversation: item._id
                                                                })
                                                            }


                                                            this.setState({
                                                                openedChats: newOpenedChats,
                                                                clickedChats: newClickedChats,
                                                                message
                                                            })

                                                        }}>&times;</div>

                                                    </div>
                                            )

                                    })
                                }
                                {
                                    this.state.clickedChats && (this.state.clickedChats.length) > this.state.canShowChats ?
                                        <div className="clicked-chats-over" onClick={() => this.setState({ showOtherClickedChats: !this.state.showOtherClickedChats })}>
                                            {this.state.clickedChats.length - this.state.canShowChats}
                                            <div className={this.state.showOtherClickedChats ? "clicked-chats-dropdown show-clicked-chats-dropdown" : "clicked-chats-dropdown"}>
                                                <div className='clicked-chats-dropdown-items'>
                                                    {
                                                        this.state.clickedChats.map((chat, cidx) => {
                                                            if (cidx >= this.state.canShowChats)
                                                                return (
                                                                    <div className='clicked-chats-dropdown-item'
                                                                        onClick={(e) => {
                                                                            e.stopPropagation();
                                                                            let clickedChats = this.state.clickedChats;
                                                                            let valueClicked = chat;
                                                                            let replaceValue = clickedChats[this.state.canShowChats - 1];
                                                                            let newClickedChats = [];
                                                                            for (let i = 0; i < clickedChats.length; i++) {
                                                                                if (clickedChats[i]._id == replaceValue._id) {
                                                                                    newClickedChats.push(valueClicked)
                                                                                    newClickedChats.push(replaceValue)
                                                                                } else if (clickedChats[i]._id != valueClicked._id) {
                                                                                    newClickedChats.push(clickedChats[i])

                                                                                }
                                                                            }
                                                                            let openedChats = this.state.openedChats;
                                                                            if (openedChats.filter(openedChat => openedChat._id == valueClicked._id).length == 0) {
                                                                                openedChats.push(valueClicked)
                                                                            }

                                                                            this.setState({
                                                                                clickedChats: newClickedChats,
                                                                                openedChats,
                                                                                showOtherClickedChats: false
                                                                            })

                                                                        }}
                                                                    >
                                                                        {chat.name}
                                                                    </div>
                                                                )
                                                        })
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                        :
                                        null
                                }
                            </div>
                            :
                            null
                    }


                    {this.state._dragNDropUpload || this.state._imageUploading || this.state._videoUploading || this.state._documentUploading ? (
                        <div className="file-progress-loader">
                            <div>
                                <Player
                                    onEvent={(event) => {
                                        if (event === "load" && this.player && this.player.current) this.player.current.play();
                                    }}
                                    ref={this.player} // set the ref to your class instance
                                    autoplay={true}
                                    loop={true}
                                    controls={true}
                                    src="/lf30_editor_l5cxzdyf.json"
                                    renderer={'svg'}
                                    style={{ height: "128px", width: "128px", }}
                                ></Player>
                                <h6>{this.props.uploadingFileProgress ? ('Uploading file...'.translate(this.props.lang) + this.props.uploadingFileProgress + '%') : 'Processing file...'.translate(this.props.lang)}</h6>
                            </div>

                        </div>
                    ) : null}

                </div>
            )
    }
}

export default ChatComponent;